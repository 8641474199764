export const imgurAPIUpload = (imageData, onProgress) => {
	return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append('image', imageData);

		const xhr = new XMLHttpRequest();
		// xhr.withCredentials = true;

		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === this.DONE) {
				let res = JSON.parse(this.responseText);
				resolve(res);
			}
		});

		xhr.upload.addEventListener(
			"progress",
			function (e) {
				if (e.lengthComputable) {
					onProgress(e.loaded / e.total);
				}
			},
			false
		);

		xhr.open("POST", "https://imgur-apiv3.p.rapidapi.com/3/image");
		xhr.setRequestHeader("authorization", "Client-ID d240f8477a69cf7");
		xhr.setRequestHeader("x-rapidapi-key", "1949405422mshf1ee3d174ec9850p148e4cjsn181e158793d5");
		xhr.setRequestHeader("x-rapidapi-host", "imgur-apiv3.p.rapidapi.com");

		try {
			xhr.send(data);
		} catch (e) {
			console.log("error:");
			console.log(e);
		}
	});
};
