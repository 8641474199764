import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { TextInput, View, Button, Text } from "react-native";
import { navReplace } from "../tools/webnavigation";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { FlatList } from "react-native";
import { useDimensions } from "@react-native-community/hooks";
import AppText from "../components/generic/AppText";

const DrawerA = createDrawerNavigator();
const DrawerB = createDrawerNavigator();
const Stack = createStackNavigator();

function ScreenA({ navigation }) {
	return (
		<View>
			<Text>SCREEN A</Text>
			<Button title="Go to screen B" onPress={() => navigation.replace("ScreenB")} />
		</View>
	);
}

function ScreenB({ navigation }) {
	return (
		<View>
			<Text>SCREEN B</Text>
			<Button title="Go to screen A" onPress={() => navigation.replace("ScreenA")} />
		</View>
	);
}

function NavigatorB({ navigation }) {
	return (
		<DrawerB.Navigator drawerType="front" style={{ width: 250 }}>
			<DrawerB.Screen component={ScreenA} name="ScreenA" />
			<DrawerB.Screen component={ScreenB} name="ScreenB" />
		</DrawerB.Navigator>
	);
}

function NavigatorA({ navigation }) {
	const [initRender, setInitRender] = React.useState(true);

	React.useEffect(() => {
		setInitRender(false);
	}, []);

	return (
		<DrawerA.Navigator drawerType="front" drawerStyle={initRender ? { width: null } : null} drawerPosition="right">
			<DrawerA.Screen component={undefined} children={(props) => <NavigatorB {...props} />} name="DrawerB" />
		</DrawerA.Navigator>
	);
}

function TestComp() {
	return (
		<AppText color="danger">0</AppText>
	);
}

export default TestComp;
