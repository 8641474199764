import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchGame } from "../../store/slices/gamesSlice";
import { clearLines } from "../../store/slices/linesSlice";
import { fullScreenContentWidth } from "../../styles/dynamicStyles";
import { globalStyles } from "../../styles/global";
import { getDefaultCharacter } from "../../tools/characters";
import { ws } from "../../tools/generic";
import { navReplace } from "../../tools/webnavigation";
import AppScreenView from "../AppScreenView";
import CharacterAvatar from "../avatar/CharacterAvatar";
import ErrorLoading from "../errors/ErrorLoading";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import FullScreenLoader from "../generic/FullScreenLoader";
import InputField from "../generic/InputField";

function JoinGameScreen({ route, navigation, dispatch, user, game }) {
	if (!game) {
		return <ErrorLoading />;
	}
	const { avatarData } = route.params;

	const [characterName, setcharacterName] = React.useState("");
	const [character, setcharacter] = React.useState(getDefaultCharacter());
	const [joining, setjoining] = React.useState(false);

	React.useEffect(() => {
		if (!avatarData) return () => null;

		setcharacter(avatarData);
	}, [avatarData]);

	const createAndJoin = React.useCallback(async () => {
		setjoining(true);
		await apiFetch(`games/${game.id}/characters`, "POST", {
			...character,
			"player-id": user.id,
			npc: false,
			"character-name": characterName,
		});
		await dispatch(fetchGame(game.id)).catch(() => null);
		dispatch(clearLines(game.id));
		setjoining(false);
		navReplace(navigation, "GameStack", { screen: "Game", gameslug: game.slug, params: {joining: true} });
	}, [characterName, character, game.id, user.id]);

	return (
		<>
			<AppScreenView style={[styles.container, ws({ width: fullScreenContentWidth() })]} scroll>
				<View style={{ marginTop: 16 }}>
					<AppText centered>{_("Joining", "'joining' followed by name of the game being joined")}</AppText>
					<AppText centered bold>
						{game.name}
					</AppText>
				</View>

				<View>
					<AppText bold centered>
						{_("Create your character", "join game screen")}
					</AppText>
					<View style={globalStyles.jac}>
						<CharacterAvatar character={character} size={128} style={{ marginVertical: 16 }} />
					</View>

					<AppButton
						centered
						hide={global.appleStoreReview}
						title={_("Change Avatar")}
						onPress={() =>
							navigation.navigate("ChangeAvatarScreen", { character, previousScreenName: route.name })
						}
					/>
				</View>

				<View>
					<InputField
						label={_("Character name", "join game screen")}
						value={characterName}
						onChangeText={setcharacterName}
					/>
					<AppText centered>{_("You can change this information at any point", "join game screen")}</AppText>
				</View>

				<View style={{ marginBottom: 32 }}>
					<AppButton title={_("Skip", "join game screen")} onPress={createAndJoin} />
					<AppText centered color="hint">
						{_("Skipping will create a character with no name", "join game screen")}
					</AppText>
				</View>

				<FullScreenLoader visible={joining} />
			</AppScreenView>
			<ButtonFooter
				narrow={false}
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.goBack() },
					{
						title: _("Create and join", "create character and join game"),
						disabled: !characterName.trim(),
						onPress: createAndJoin,
					},
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameslug } = ownProps.route.params;
	const game = state.games[gameslug];
	return {
		user: state.user,
		game,
	};
};

export default connect(mapStateToProps)(JoinGameScreen);

const styles = StyleSheet.create({
	container: {
		justifyContent: "space-between",
		// alignItems: "center"
	},
});
