import * as React from "react";
import { connect } from "react-redux";
import AppScreenView from "../AppScreenView";
import TagSelector from "./TagSelector";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import { _ } from "../../i18n/i18n";
import { fetchGameTags, fetchNextGameTags, clearGameTags, searchTags } from "../../store/slices/gameTagsSlice";
import { ws } from "../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { setLanguage } from "../../store/slices/gamesUISlice";

function CreateTagScreen({ route, navigation, dispatch, tags, activeCharacterTags }) {
	const { character, activeTags: activeTagsReceived, sendToScreen } = route.params;

	const [currentSearch, setcurrentSearch] = React.useState();
	const [nextURL, setnextURL] = React.useState(null);
	const [activeTags, setactiveTags] = React.useState(activeTagsReceived);

	React.useEffect(() => {
		updateSearch(null, false);
	}, []);

	const fetchNext = React.useCallback(async () => {
		if (!nextURL) return null;
		const next = await dispatch(fetchNextGameTags(nextURL));
		setnextURL(next);
	}, [nextURL]);

	React.useEffect(() => {
		let typingTimeout = setTimeout(() => {
			updateSearch(currentSearch, true);
		}, 200);

		return () => {
			clearTimeout(typingTimeout);
		};
	}, [currentSearch]);

	const updateSearch = React.useCallback(async (searchTerm, clearFirst) => {
		if (clearFirst) {
			await dispatch(clearGameTags());
		}
		const params = searchTerm ? { search: searchTerm } : null;
		const next = await dispatch(fetchGameTags(params));
		setnextURL(next);
		setcurrentSearch(searchTerm);
	}, []);

	return (
		<>
			<AppScreenView borderless borderTop style={ws({ paddingLeft: fullScreenLeftPadding() })}>
				<TagSelector
					limit={character ? null : 10}
					style={ws({ maxWidth: fullScreenContentWidth() })}
					tags={tags}
					activeTags={character ? activeCharacterTags : activeTags}
					autoFocusSearch={!!character}
					character={character}
					onEndReached={fetchNext}
					onTagAdded={(tag) => setactiveTags(activeTags.rg_pushUniquePure(tag, (t) => t.id === tag.id))}
					onTagRemoved={(tag) => setactiveTags(activeTags.rg_removeElementPure((t) => t.id === tag.id))}
					onSearchUpdated={setcurrentSearch}
				/>
			</AppScreenView>
			{!character && (
				<ButtonFooter
					narrow={false}
					style={ws({ maxWidth: fullScreenContentWidth() })}
					buttonProps={[
						{ title: _("Cancel"), onPress: () => navigation.goBack() },
						{
							title: _("Done"),
							onPress: () =>
								navigation.navigate({
									name: sendToScreen,
									params: { selectedTags: activeTags },
									merge: true,
								}),
						},
					]}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	let { character } = ownProps.route.params;
	let tags = [];
	let activeCharacterTags = [];
	if (character) {
		tags = state.characterTags[character.game] || Array.rg_empty;
		activeCharacterTags = tags.filter((tag) => tag.characters.includes(character.id));
	} else {
		tags = state.gameTags;
	}
	return {
		tags,
		activeCharacterTags,
	};
};

export default connect(mapStateToProps)(CreateTagScreen);
