import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Image, StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { useDimensions } from "react-native-web-hooks";
import { _ } from "../../i18n/i18n";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import HorizontalScroller from "../generic/HorizontalScroller";

function Feature({ feature }) {
	const maxHeight = 512;
	let maxWidth = 512;

	const { width: windowWidth } = useDimensions().window;

	maxWidth = Math.min(maxWidth, windowWidth * 0.8);

	let ratio = 1;
	if (feature.imageSize.height > maxHeight) ratio = maxHeight / feature.imageSize.height;
	if (feature.imageSize.width > maxWidth) ratio = maxWidth / feature.imageSize.width;

	return (
		<View style={{ maxWidth }}>
			<View style={{ marginBottom: 32 }}>
				<AppText size="large" bold style={{ marginBottom: 32 }}>
					{feature.title}
				</AppText>
				<AppText color="secondary">{feature.description}</AppText>
			</View>
			<View style={styles.imageShadow}>
				<Zoom
					overlayBgColorStart={global.colors.cardBackground}
					overlayBgColorEnd={global.colors.cardBackground}
					zoomMargin={64}
					closeText={_("Click to unzoom", "zoom on picture")}
					openText={_("Click to zoom", "unzoom from picture")}
				>
					<Image
						resizeMode="contain"
						style={{
							height: feature.imageSize.height * ratio,
							width: feature.imageSize.width * ratio,
							marginRight: 32,
						}}
						source={feature.source}
					/>
				</Zoom>
			</View>
		</View>
	);
}

function HomeScreenLoggedOff() {
	const size = 320;

	const features = [
		{
			key: "ooc",
			source: require("../../../assets/images/landing/OOC.jpg"),
			imageSize: { height: 655, width: 611 },
			title: _("Separated In character/Out of character chat", "landing page, features"),
			description: _(
				"Easily separate the story from the chat around the table. Display both or only one type of text at once.",
				"landing page, features"
			),
		},
		{
			key: "sheets",
			source: require("../../../assets/images/landing/CharacterSheets.jpg"),
			imageSize: { height: 655, width: 1001 },
			title: _("Play D&D or any other system", "landing page, features"),
			description: _(
				"The simple and powerful character sheet editor let's you play any system you want. Load any pre-made sheet and edit it as you see fit.",
				"landing page, features"
			),
		},
		{
			key: "helpers",
			source: require("../../../assets/images/landing/Interactive helpers.jpg"),
			imageSize: { height: 719, width: 955 },
			title: _("Interactive Helpers", "landing page, features"),
			description: _(
				"Create and use Helpers from the community to help you run and play all type of games",
				"landing page, features"
			),
		},
		{
			key: "parties",
			source: require("../../../assets/images/landing/Party split.jpg"),
			imageSize: { height: 149, width: 553 },
			title: _("Party splitting", "landing page, features"),
			description: _(
				"More than tabletops: split your parties and tell a different story to each player",
				"landing page, features"
			),
		},
		{
			key: "beginners",
			source: require("../../../assets/images/landing/Beginner friendly.jpg"),
			imageSize: { height: 259, width: 789 },
			title: _("Beginner Friendly community", "landing page, features"),
			description: _(
				'No experience with Role Playing? No problem. Find a public game with the "Beginner Friendly" tag and hop in!',
				"landing page, features"
			),
		},
	];

	const { width: windowWidth } = useDimensions().window;
	const maxImageWidth = windowWidth * 0.4;
	const imageWidth = 613;
	let ratio = 1;
	if (imageWidth > maxImageWidth) ratio = maxImageWidth / imageWidth;

	const buttonSize = windowWidth > 900 ? "large" : "average";

	return (
		<AppScreenView style={styles.container} borderless scroll>
			<Image
				style={{
					width: "100%",
					height: "100%",
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: -1,
				}}
				source={require("../../../assets/images/splash_screen_background.png")}
			/>
			<View style={{ marginBottom: 32, flexDirection: "row", width: "100%", justifyContent: "center" }}>
				<View style={[styles.centeredContainer]}>
					<View style={{ alignItems: "center", flex: 1 }}>
						<Image
							style={{ width: size, height: size, marginBottom: 32 }}
							source={require("../../../assets/images/Logo.png")}
						/>

						<AppText centered size="header" bold>
							{_("Tabletop RPGs", "app welcome message")}
						</AppText>
						<AppText centered size="large" style={{ marginBottom: 64 }} color="secondary">
							{_("Anytime, anywhere", "app welcome message")}
						</AppText>
					</View>

					<View style={{ justifyContent: "center", flex: 1 }}>
						<AppButton size={buttonSize} callToAction title={_("Login").toUpperCase()} navigateTo="Login" />
						<AppButton
							size={buttonSize}
							title={_("Browse games").toUpperCase()}
							navigateTo="Explore"
						/>
						<AppButton
							size={buttonSize}
							transparent
							title={_("Create account").toUpperCase()}
							navigateTo="CreateAccount"
						/>
					</View>
				</View>
			</View>

			<View
				style={{
					backgroundColor: global.colors.translucidBackground,
					width: "100%",
					paddingVertical: 128,
					alignItems: "center",
				}}
			>
				<View style={styles.centeredContainer}>
					<View style={[styles.imageShadow]}>
						<Zoom overlayBgColorStart={global.colors.cardBackground} overlayBgColorEnd={global.colors.cardBackground}>
							<Image
								resizeMode="contain"
								style={[{ height: 651 * ratio, width: 613 * ratio }]}
								source={require("../../../assets/images/landing/DefaultGame.jpg")}
							/>
						</Zoom>
					</View>

					<View style={{ maxWidth: 512, marginBottom: 32, marginTop: 32, flex: 1 }}>
						<AppText bold size="header" style={{ marginBottom: 32 }}>
							{_("What is Role Gate?", "landing page")}
						</AppText>
						<AppText style={{ marginBottom: 16 }} color="secondary">
							{_("Role Gate is a platform to play any tabletop RPG by chat.", "landing page")}
						</AppText>
						<AppText style={{ marginBottom: 16 }} color="secondary">
							{_(
								"It can be very difficult to find time to dive into a session of your favorite tabletop RPG. Role Gate is a solution that lets you play at your own pace.",
								"landing page"
							)}
						</AppText>
						<AppText color="attention">
							{_(
								"Join a game with other players and write as little as one short message per day. Progress the story when you have time, wherever you are!",
								"landing page"
							)}
						</AppText>
					</View>
				</View>
			</View>

			<View style={{ marginBottom: 32, width: "100%", paddingVertical: 64 }}>
				<Image
					source={require("../../../assets/images/landing/game_preview_background.png")}
					style={{ height: "100%", width: "100%", position: "absolute", top: 0, opacity: 0.1 }}
					resizeMode="cover"
				/>
				<AppText bold size="header" centered>
					{_("Immersive features", "landing page")}
				</AppText>

				<Divider theme={{ dark: true }} style={{ marginVertical: 32 }} />

				<HorizontalScroller
					containerStyle={{ paddingHorizontal: 32 }}
					data={features}
					renderItem={({ item }) => <Feature feature={item} />}
					ItemSeparatorComponent={() => <View style={{ marginRight: Math.min(256, windowWidth * 0.1) }} />}
				/>
			</View>

			<View style={{ marginBottom: 32 }}>
				<AppButton
					title={_("Join us on Discord")}
					icon={{ type: MaterialCommunityIcons, name: "discord" }}
					to="https://discord.com/invite/3E37sQ7"
				/>
			</View>

			<AppText size="small" color="hint" style={{ marginBottom: 16 }}>
				{_("Role Gate is developed by Tabletop Mirror, LLC.")}
			</AppText>
		</AppScreenView>
	);
}

export default HomeScreenLoggedOff;
const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
	},

	image: {},
	imageShadow: {
		shadowColor: "black",
		shadowOffset: {
			width: 10,
			height: 3,
		},
		shadowOpacity: 0.3,
		shadowRadius: 10,
	},
	centeredContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		maxWidth: 1200,
		width: "100%",
	},
});
