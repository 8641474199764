import * as React from "react";
import { Animated, Easing, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import { isPlayer } from "../../tools/games";
import { socket } from "../../network/websocket";

import { BuildStyleMethod } from "../../styles/theming";
import { isOwn } from "../../tools/lines";

function TypingFeedback({ dispatch, user, game, currentParty, characters }) {
	const styles = stylesMethod(global.theme);
	const [usersTyping, setusersTyping] = React.useState([]);
	const [animatedValues, setanimatedValues] = React.useState([]);

	const clearTypingTimeout = React.useRef();

	React.useEffect(() => {
		return () => {
			clearTimeout(clearTypingTimeout.current);
		};
	}, []);

	const addStoryTypingUser = React.useCallback(
		(userId) => {
			if (userId === user.id) return;

			// Don't show users typing for characters that are not in my party
			if (currentParty && isPlayer(game, user)) {
				// As I cannot tell what party the game master is typing for, we just skip the feedback entirely
				if (userId === game.game_master.id) return;
				const character = characters.find((c) => c.player?.id === userId);
				if (character && currentParty.characters.indexOf(character.id) < 0) return;
			}

			setusersTyping((curr) => curr.rg_pushUniquePure(userId, (el) => el === userId));

			clearTimeout(clearTypingTimeout.current);
			// safety in case the "user_stop_typing_story" even is not received
			clearTypingTimeout.current = setTimeout(() => setusersTyping([]), 10000);
		},
		[user, currentParty, characters, game]
	);

	const removeStoryTypingUser = React.useCallback((userId) => {
		setusersTyping((current) => current.filter((u) => u !== userId));
	}, []);

	const onLineReceived = React.useCallback((line)=>{
		if (!isOwn(line)) {
			// ignore it, we are waiting for a response from the server with the saved line
			// (this if is easier to read than with double !)
			setusersTyping([]);
		}
	}, []);

	React.useEffect(() => {
		socket.on("user_start_typing_story", addStoryTypingUser);
		socket.on("user_stop_typing_story", removeStoryTypingUser);
		socket.on("new_line", onLineReceived);
		return () => {
			socket.off("user_start_typing_story", addStoryTypingUser);
			socket.off("user_stop_typing_story", removeStoryTypingUser);
			socket.off("new_line", onLineReceived);
		};
	}, [addStoryTypingUser, removeStoryTypingUser, onLineReceived]);


	React.useEffect(() => {
		const arr = [];
		for (let i = 0; i < usersTyping.length; i++) {
			arr.push(new Animated.Value(0));
		}
		setanimatedValues(arr);
	}, [usersTyping.length]);

	React.useEffect(() => {
		if (!animatedValues.length) return () => null;
		const animations = [];
		for (let i = 0; i < animatedValues.length; i++) {
			animations.push(
				Animated.sequence([
					Animated.timing(animatedValues[i], {
						toValue: -2,
						duration: 250,
						easing: Easing.linear,
						useNativeDriver: true,
						isInteraction: false,
					}),
					Animated.timing(animatedValues[i], {
						toValue: 0,
						duration: 250,
						easing: Easing.bounce,
						useNativeDriver: true,
						isInteraction: false,
					}),
				])
			);
		}
		Animated.loop(Animated.stagger(250, animations), { iterations: -1 }).start();
	}, [animatedValues]);

	if (!animatedValues.length) return null;
	return (
		<View style={{ flexDirection: "row" }}>
			<View style={styles.container}>
				{animatedValues.map((value, index) => (
					<Animated.View key={String(index)} style={[styles.dot, { transform: [{ translateY: value }] }]} />
				))}
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	return {
		user: state.user,
		game,
		currentParty: state.parties[game?.id]?.current,
		characters: state.charactersByGame[game?.id] || Array.rg_empty,
		openedParties: state.parties[game?.id]?.openedParties,
	};
};

export default connect(mapStateToProps)(TypingFeedback);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.backgroundSecondary,
		flexDirection: "row",
		padding: 4,
		minWidth: 32,
		marginHorizontal: 8,
		marginBottom: 8,
		borderRadius: 16,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		flex: 0,
		flexGrow: 0,
		flexShrink: 1,
	},
	dot: {
		height: 8,
		width: 8,
		backgroundColor: "white",
		borderRadius: 12,
		marginRight: 4,
	},
}));
