import * as React from "react";
import { View, StyleSheet } from "react-native";
import Modal from "../../reimports/Modal";
import InputField from "../generic/InputField";
import { _ } from "../../i18n/i18n";
import AppButton from "../generic/buttons/AppButton";
import AppText from "../generic/AppText";
import { Divider, Portal } from "react-native-paper";
import BoxModal from "../generic/modal/BoxModal";

class GamePasswordModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			password: "",
			wrong: false,
			visible: false,
		};

		this.modalRef = React.createRef();
	}

	show() {
		this.modalRef.current.show();
	}

	hide = () => {
		this.modalRef.current.hide();
	};

	render() {
        const { game, navigation } = this.props;
        
		return (
			<BoxModal
                ref={this.modalRef}
                addCancel
                keepOpen
				options={[
					{
                        disabled: !this.state.password || this.state.wrong,
						title: _("Join", "join game"),
						onPress: () => {
							if (this.state.password === game.password) {
                                this.hide();
								navigation.navigate("GameStack", { gameslug: game.slug, screen: "JoinGameScreen" });
							} else {
                                this.setState({ wrong: true });
							}
						},
					},
				]}
			>
				<AppText hide={!this.state.wrong} color="danger">
					{_("Incorrect password", "wrong game password")}
				</AppText>
				<InputField
					label={_("Password")}
					value={this.state.password}
					onChangeText={(password) => this.setState({ password, wrong: false })}
				/>
			</BoxModal>
		);
	}
}

export default GamePasswordModal;


