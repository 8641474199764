import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppButton from "../generic/buttons/AppButton";
import { connect } from "react-redux";
import { apiFetch } from "../../network/apiFetch";
import { _, _n } from "../../i18n/i18n";
import { getUsageLeft } from "../../tools/oaiTokens";
import OAITokenBar from "./OAITokenBar";
import AppText from "../generic/AppText";
import CondView from "../meta/CondView";
import IconButton from "../generic/buttons/IconButton";
import { MaterialIcons } from "@expo/vector-icons";
import { isIos, ws } from "../../tools/generic";
import BoxModal from "../generic/modal/BoxModal";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import { useNavigation } from "@react-navigation/core";

function OAIRequestButton({
	endpoint,
	requestType,
	requestParams,
	setoutput,
	onWaitingForResponseChanged,
	disabled,
	title,
	navigation,
	buttonStyle,
	dispatch,
	user,
	size,
}) {
	const [waitingForResponse, setwaitingForResponse] = React.useState(false);
	const [costEstimation, setcostEstimation] = React.useState(null);
	const [estimatingCost, setestimatingCost] = React.useState(false);

	const modalRef = React.useRef();

	navigation = navigation || useNavigation();

	React.useEffect(() => {
		setestimatingCost(true);

		const getEstimate = () => {
			apiFetch(`users/${user.id}/openairequest/cost-estimate`, "GET", {
				request_type: requestType,
				...requestParams,
			})
				.then(setcostEstimation)
				.then(() => setestimatingCost(false));
		};

		const timeout = setTimeout(getEstimate, 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, [requestParams, requestType]);

	React.useEffect(() => {
		onWaitingForResponseChanged(waitingForResponse);
	}, [waitingForResponse]);

	const sendRequest = React.useCallback(() => {
		const query = async () => {
			setwaitingForResponse(true);
			apiFetch(`users/${user.id}/openairequest/${endpoint}`, "POST", requestParams).then((response) => {
				if (!response) {
					setoutput({ error: true });
				} else {
					setoutput(response);
				}
				setwaitingForResponse(false);
			});
		};

		query();
	}, [user, setoutput, requestParams, endpoint]);

	const usageLeft = getUsageLeft(user);
	const cannotAfford = usageLeft == 0 || usageLeft < costEstimation + 100;


	if(global.appleStoreReview) return null;

	return (
		<View style={{ marginVertical: 16 }}>
			<View>
				<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
					<AppButton
						size={size}
						danger={cannotAfford}
						title={title || _("Generate", "request the AI to generate content")}
						info={
							costEstimation >0 ? (
								_n("≈%(cost)s token", "≈%(cost)s tokens", "cost to use an AI feature", costEstimation, {
									cost: costEstimation,
								})
							) : costEstimation <0 ? (
								<View style={{ flexDirection: "row" }}>
									<AppText size="small" color="hint">{_("AI unavailable", "AI feature is not available")}</AppText>
								</View>
							) : (
								<View style={{ flexDirection: "row" }}>
									<AppActivityIndicator size={isIos() ? "small":8} style={{marginRight: 4}}/>
									<AppText size="small" color="hint">{_("Calculating cost...", "loading of Ai cost estimation")}</AppText>
								</View>
							)
						}
						onPress={sendRequest}
						disabled={!costEstimation || costEstimation < 0 || disabled || waitingForResponse || cannotAfford}
						style={buttonStyle}
						requiredLevel={3}

					/>
					<IconButton
						style={{ marginLeft: 8 }}
						transparent
						icon={{
							type: MaterialIcons,
							name: "info-outline",
							size: 24,
							color: cannotAfford ? global.colors.danger : null,
						}}
						onPress={() => modalRef.current.show()}
					/>
				</View>
				<BoxModal ref={modalRef} options={[{ title: _("Got it!", "close info box") }]}>
					<OAITokenBar navigation={navigation} projectedUsage={costEstimation} estimatingCost={estimatingCost} />
				</BoxModal>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(OAIRequestButton);

const styles = StyleSheet.create({
	container: {},
});
