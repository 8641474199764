import { FontAwesome } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { TouchableOpacity, View } from "react-native";
import { _ } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { colors } from "../../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import { isGM } from "../../../tools/games";
import { isWeb, ws } from "../../../tools/generic";
import SendAlertButton from "../../gamealerts/SendAlertButton";
import GameTagHorizontalList from "../../gametags/GameTagHorizontalList";
import AppText from "../../generic/AppText";
import CondView from "../../meta/CondView";

function GameSettingTags({ game, style, onTagsUpdated }) {
	const navigation = useNavigation();
	const route = useRoute();

	const selectedTags = route.params?.selectedTags;

	React.useEffect(() => {
		if (!selectedTags) return () => null;
		navigation.setParams({ selectedTags: null });
		onTagsUpdated && onTagsUpdated(selectedTags);
		if (game.id) {
			apiFetch(`games/${game.id}`, "PATCH", { tags: selectedTags.map((t) => t.name) });
		}
	}, [selectedTags, game.id, onTagsUpdated]);

	const canEdit = !game.id || (isGM(game) && !game.archived);
	const ContainerTag = canEdit ? TouchableOpacity : View;

	return (
		<ContainerTag
			style={[
				globalStylesMethod(global.theme).floatingSection,
				{ paddingBottom: 12 },
				ws({ alignItems: "center" }),
				style,
			]}
			onPress={() =>
				navigation.navigate({
					name: "CreateGameTagsScreen",
					params: { sendToScreen: route.name, activeTags: game.tags },
					merge: true,
				})
			}
		>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<View style={globalStyles.rcsb}>
					<AppText color="primary" style={{ marginBottom: 8 }}>
						{_("Tags", "list game's tags")}
					</AppText>
					{canEdit && (
						<FontAwesome
							name="plus"
							color={global.colors.primary}
							size={24}
							style={ws(null, { marginRight: 15 })}
						/>
					)}
				</View>
				<GameTagHorizontalList game={game} large />
				<CondView show={game && !game.tags.length} style={{ marginVertical: 8 }}>
					<AppText color="hint" italic centered={!isWeb()}>
						{_("This game has no tags")}
					</AppText>
					<AppText hide={!canEdit} color="hint" italic centered={!isWeb()}>
						{_("Tags will help you find players and/or readers")}
					</AppText>
				</CondView>
				{!!game.tags.length && game.players && <SendAlertButton game={game} />}
			</View>
		</ContainerTag>
	);
}

export default GameSettingTags;
