import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import LoggedNavigationScreens from "./LoggedNavigationScreens";
import ModalNavigationScreens from "./ModalNavigationScreens";
import PublicNavigationScreens from "./PublicNavigationScreens";

const Stack = createStackNavigator();

function MainStack({ user }) {
	
	return (
		<Stack.Navigator screenOptions={{ headerTintColor: global.colors.textDefault, headerMode: "screen" }}>
			{LoggedNavigationScreens(Stack, user)}
			{PublicNavigationScreens(Stack)}
			{ModalNavigationScreens(Stack)}
		</Stack.Navigator>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(MainStack);
