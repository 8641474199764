import { Entypo, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { sheetPadding } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import { moveNodeUp, moveNodeDown, resizeNode, resizeNodeHeight } from "../../tools/sheets";
import IconButton from "../generic/buttons/IconButton";
import CondView from "../meta/CondView";

function SheetNodeOptionsBar({ show, node, parent, top, index, horizontal, dispatch }) {
	if (!show) return null;

	const navigation = useNavigation();
	return (
		<View
			style={[
				styles.optionBar,
				ws({ right: sheetPadding() }),
				{ top: top + ws(global.sheetContentOffsetY || 0, 0) },
			]}
		>
			<IconButton
				hide={index === 0}
				style={{ backgroundColor: global.colors.secondary }}
				icon={{ type: Entypo, name: horizontal ? "chevron-left" : "chevron-up" }}
				onPress={() => moveNodeUp(node, parent, dispatch)}
			/>
			<IconButton
				hide={index >= parent.items.length - 1}
				style={{ backgroundColor: global.colors.secondary }}
				icon={{ type: Entypo, name: horizontal ? "chevron-right" : "chevron-down" }}
				onPress={() => moveNodeDown(node, parent, dispatch)}
			/>
			<IconButton
				hide={!horizontal || parent.items.length < 2}
				style={{ backgroundColor: global.colors.secondary }}
				icon={{ type: FontAwesome, name: "expand", style: { transform: [{ rotate: "45deg" }] } }}
				onPress={() => resizeNode(node, node.size > 1 ? node.size + 1 : node.size * 2, dispatch)}
				onLongPress={() => resizeNode(node, 1, dispatch)}
			/>
			<IconButton
				hide={!horizontal || parent.items.length < 2}
				style={{ backgroundColor: global.colors.secondary }}
				icon={{ type: FontAwesome, name: "compress", style: { transform: [{ rotate: "45deg" }] } }}
				onPress={() => resizeNode(node, node.size > 1 ? node.size - 1 : node.size * 0.5, dispatch)}
				onLongPress={() => resizeNode(node, 1, dispatch)}
			/>

			<CondView show={node.type === "picture"}>
				<IconButton
					style={{ backgroundColor: global.colors.secondary }}
					icon={{ type: FontAwesome, name: "expand", style: { transform: [{ rotate: "-45deg" }] } }}
					onPress={() => resizeNodeHeight(node, node.height > 1 ? node.height +1 : node.height * 2, dispatch)}
					onLongPress={() => resizeNodeHeight(node, 1, dispatch)}
				/>
				<IconButton
					style={{ backgroundColor: global.colors.secondary }}
					icon={{ type: FontAwesome, name: "compress", style: { transform: [{ rotate: "-45deg" }] } }}
					onPress={() => resizeNodeHeight(node, node.height > 1 ? node.height - 1 : node.height * 0.5, dispatch)}
					onLongPress={() => resizeNodeHeight(node, 1, dispatch)}
				/>
			</CondView>

			<IconButton
				style={{ backgroundColor: global.colors.secondary }}
				icon={{ type: MaterialIcons, name: "more-vert" }}
				onPress={() =>
					navigation.navigate({
						name: "SheetNodeOptionsModal",
						params: { node, parent },
					})
				}
			/>
		</View>
	);
}

export default SheetNodeOptionsBar;

const styles = StyleSheet.create({
	optionBar: {
		flexDirection: "row",
		position: "absolute",
		// height: 48,
		right: 8,
	},
});
