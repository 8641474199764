import * as React from "react";
import { View, StyleSheet } from "react-native";
import { colors } from "../../styles/colors";

import { BuildStyleMethod } from "../../styles/theming";

function VerticalDivier() {
	const styles = stylesMethod(global.theme);
	return <View style={styles.container} />;
}

export default VerticalDivier;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		height: "100%",
		borderLeftWidth: 1,
		marginHorizontal: 4,
		borderLeftColor: colors.lightBorder,
	},
}));
