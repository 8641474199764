import * as React from "react";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import ModalScreen from "../generic/modal/ModalScreen";
import { receiveLines } from "../../store/slices/linesSlice";
import { apiFetch } from "../../network/apiFetch";
import { connect } from 'react-redux';
import { getCurrentCharacter } from "../../store/slices/charactersSlice";

function LineChangeTypeModal({ navigation, route, dispatch, currentCharacter }) {
	const { editingLine, backToScreen } = route.params;

	const changeTo = React.useCallback(
		(newType) => {

			const params = { type: newType };
			if(newType != "chat" && currentCharacter){
				params.author = currentCharacter.id;
			}

			apiFetch(`games/${editingLine.game}/lines/${editingLine.id}`, "PATCH", params).then(
				(lineReceived) => {
					dispatch(receiveLines({ lines: [lineReceived], gameId: editingLine.game }));
				}
			);

			if (backToScreen) {
				navigation.navigate(backToScreen);
			} else {
				navigation.goBack();
			}
		},
		[editingLine, currentCharacter]
	);

	const options = [];

	(editingLine.is_chat || editingLine.is_description) &&
		options.push({
			title: _("Make dialogue", "change line type"),
			onPress: () => changeTo("dialogue"),
		});

	!editingLine.is_description &&
		options.push({
			title: _("Make description", "edit line"),
			onPress: () => changeTo("description"),
		});

	!editingLine.is_chat &&
		options.push({
			title: _("Make out of character chat", "edit line"),
			onPress: () => changeTo("chat"),
		});

	return <ModalScreen options={options} />;
}

const mapStateToProps = (state, ownProps) => {
	
	return {
		currentCharacter: getCurrentCharacter(state),
	};
};

export default connect(mapStateToProps)(LineChangeTypeModal);
