import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import AppScreenView from "../AppScreenView";
import { idKeyExtractor } from "../../tools/generic";
import CharacterLanguageItem from "./CharacterLanguageItem";
import { Divider } from "react-native-paper";
import { isGM } from "../../tools/games";
import { receiveLanguages } from "../../store/slices/languagesSlice";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import HelpButton from "../tutorials/HelpButton";
import { apiFetch } from "../../network/apiFetch";

function CharacterLanguagesList({ route, navigation, dispatch, languages }) {
	const { character } = route.params;

	const setProficiency = React.useCallback(
		(language, previousLevel, newLevel) => {
			const cid = character.id;
			const patchData = {
				beginners:
					previousLevel === 1
						? language.beginners.rg_removeSimple(cid)
						: newLevel === 1
						? language.beginners.concat(cid)
						: language.beginners,
				intermediates:
					previousLevel === 2
						? language.intermediates.rg_removeSimple(cid)
						: newLevel === 2
						? language.intermediates.concat(cid)
						: language.intermediates,
				fluents:
					previousLevel === 3
						? language.fluents.rg_removeSimple(cid)
						: newLevel === 3
						? language.fluents.concat(cid)
						: language.fluents,
			};

			apiFetch(`games/${language.game}/languages/${language.id}`, "PATCH", patchData);
			dispatch(receiveLanguages({ gameId: language.game, languages: [{ ...language, ...patchData }] }));
		},
		[dispatch]
	);

	const gm = isGM();

	return (
		<AppScreenView borderless>
			<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginHorizontal: 16 }}>
				<AppText bold style={{ marginBottom: 8 }}>
					{_("Language proficiencies")}
				</AppText>
				<HelpButton
					transparent
					title={_("Language proficiencies")}
					message={
						<>
							<AppText>
								{_(
									"Some messages can be set to use a fictional language. If your character is not fluent in that language, you will only be able to read parts of those messages."
								)}
							</AppText>
							<AppText style={{marginTop: 8}}>
								{_(
									"The higher the character proficiency, the more of the message will be understandable."
								)}
							</AppText>
							<AppText style={{marginTop: 8}}>
								{_(
									"Proficiency levels are defined by the Game Master."
								)}
							</AppText>
						</>
					}
				/>
			</View>
			<FlatList
				contentContainerStyle={{ paddingBottom: 16 }}
				data={languages}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => (
					<CharacterLanguageItem
						language={item}
						character={character}
						setProficienyLevel={
							gm ? (previousLevel, newLevel) => setProficiency(item, previousLevel, newLevel) : null
						}
					/>
				)}
				ItemSeparatorComponent={() => <Divider style={{ marginVertical: 16 }} />}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.route.params.character.game;

	return {
		languages: state.languages[gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(CharacterLanguagesList);


