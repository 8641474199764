import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Divider, FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { saveMacroGroupChanges } from "../../store/slices/macroGroupsSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import MacroItem from "./MacroItem";

function MacroGroupScreen({ route, navigation, dispatch, user, allMacros, macroGroups }) {
	// on web, pressing back will try to display this component without route.params
	if(!route.params) return null;
	let { macroGroup, sheetId } = route.params;
	macroGroup = macroGroups[macroGroup.id];

	const [name, setname] = React.useState(macroGroup.name);
	const [description, setdescription] = React.useState(macroGroup.description);
	const confirmCreateModalRef = React.useRef();
	const macros = React.useMemo(() => Object.values(allMacros).filter((m) => m.group === macroGroup.id), [allMacros]);

	const owns = user.id === macroGroup.user;

	React.useEffect(() => {
		navigation.setParams({ macroGroup });
	}, [macroGroup, navigation]);

	const save = React.useCallback(() => {
		const object = {
			name: name || _("My macros", "default macros group name"),
			description,
		};
		if (!owns) {
			return;
		}

		dispatch(saveMacroGroupChanges(user.id, macroGroup.id, object));
	}, [dispatch, name, description, user.id, macroGroup.id, owns]);

	React.useEffect(() => {
		if (macroGroup.name != name || macroGroup.description != description) {
			save();
		}
	}, [macroGroup.name, macroGroup.description, name, description]);

	const createMacro = React.useCallback(() => {
		const newMacro = {
			name: _("New macro", "default macro name"),
			description: "",
			// command: "attacks for [[1d20+Dex>AC]] and [[1d10+Big Strength+Agility>AC]]",
			command: "",
			icon: "tk_d20",
			color: 1,
			group: macroGroup.id,
		};
		navigation.navigate("MacroScreen", { macro: newMacro, sheetId });
	}, [user.id, macroGroup.id, dispatch, sheetId]);

	const isMine = !!user && user.id === macroGroup.user;

	return (
		<AppScreenView borderless borderTop style={ws({ paddingLeft: fullScreenLeftPadding() })}>
			<View style={[styles.container, ws({maxWidth: fullScreenContentWidth()})]}>
				<AppText bold editable={owns} size="large" onTextChanged={setname}>
					{name}
				</AppText>
				<AppText editable={owns} placeholder={_("No description")} onTextChanged={setdescription}>
					{description}
				</AppText>
				<Divider style={{ marginVertical: 16 }} />
			</View>
			<View style={styles.container}>
				<AppText size="large" bold>
					{_("Macros")}
				</AppText>
			</View>

			<FlatList
				keyExtractor={idKeyExtractor}
				data={macros}
				contentContainerStyle={ws({maxWidth: fullScreenContentWidth()})}
				renderItem={({ item, index }) => (
					<MacroItem
						macroGroup={macroGroup}
						macro={item}
						index={index}
						onSelected={(macro) => navigation.navigate("MacroScreen", { macro, sheetId })}
					/>
				)}
				ListEmptyComponent={
					<AppText color="hint" centered style={{ marginTop: 16 }}>
						{isMine ? _("Press + to add a macro to this group.") : _("This group has no macros.")}
					</AppText>
				}
			/>

			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create macro")}
				message={_("Add a new macro to this group?")}
				onConfirm={createMacro}
			/>

			{isMine && (
				<FAB icon="plus" style={globalStylesMethod(global.theme).fab} onPress={() => confirmCreateModalRef.current.show()} />
			)}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		allMacros: state.macros,
		macroGroups: state.macroGroups,
	};
};

export default connect(mapStateToProps)(MacroGroupScreen);

const styles = StyleSheet.create({
	container: { marginHorizontal: 16 },
});
