import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { groupIsRead } from "../../tools/directMessages";
import { colors } from "../../styles/colors";
import UserAvatar from "../users/UserAvatar";
import AppText from "../generic/AppText";
import { getTimestamp } from "../../tools/time";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { _ } from "../../i18n/i18n";
import { useHover } from "react-native-web-hooks";
import { ws } from "../../tools/generic";

import { BuildStyleMethod } from "../../styles/theming";

function MessageGroupItem({ group, dispatch, user }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const interlocutor = group.members.find((other) => other.id !== user.id);
	const isRead = groupIsRead(group, user);
	const usernameColor = isRead ? "secondary" : "textDefault";
	const contentColor = isRead ? "secondary" : "textDefault";

	const previewLine = React.useMemo(() => {
		if (group.last_message) {
			return group.last_message.content;
		}
		return getTimestamp(group.created);
	}, [group]);

	const timeStamp = group.last_message?.created || group.created;

	const ref = React.useRef(null);
	const isHovered = useHover(ref);

	return (
		<TouchableOpacity
			ref={ref}
			style={[
				styles.container,
				ws([
					{ borderWidth: 1, padding: 4 },
					isHovered && { borderColor: global.colors.lightBorder, backgroundColor: global.colors.hoverItemLightBackground },
				]),
			]}
			onPress={() => navigation.navigate("MessageGroupScreen", { groupId: group.id })}
		>
			<UserAvatar user={interlocutor} size={48} style={{ marginRight: 16 }} />

			<View style={styles.contentContainer}>
				<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
					<AppText bold>{interlocutor?.username || _("[Deleted]", "deleted user")}</AppText>
					<AppText color={usernameColor} size="small">
						{getTimestamp(timeStamp)}
					</AppText>
				</View>
				<View style={{ flexDirection: "row" }}>
					<AppText color={contentColor} style={{ flex: 1 }} numberOfLines={1}>
						{previewLine}
					</AppText>
					{isRead && <MaterialIcons name="check" color={global.colors.secondary} />}
					{!isRead && <MaterialCommunityIcons name="alert-circle" color={global.colors.attention} size={24} />}
				</View>
			</View>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(MessageGroupItem);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		paddingHorizontal: 8,
		borderBottomWidth: 1,
		borderColor: "transparent",
		borderBottomColor: colors.lightBorder,
	},
	contentContainer: {
		flex: 1,
		paddingVertical: 16,
	},
}));
