import { createSlice } from "@reduxjs/toolkit";

// Returns a method that will take params { gameId, value } and save it in gameUI[keyName]
const setValueForGame = (keyName) => {
	return (state, { payload: { gameId, value } }) => {
		if (!gameId) {
			console.error(`set${keyName} parameters should use the format {gameId, value}. Received ${gameId}`);
		}
		state[gameId] = state[gameId] || {};
		state[gameId][keyName] = value;

		return state;
	};
};

export const gamesUISlice = createSlice({
	name: "gamesUI",
	initialState: {},
	reducers: {
		setGameReady: setValueForGame("gameReady"),
		setLinesDisplayed: setValueForGame("linesDisplayed"),
		setFocusedType: setValueForGame("focusedType"),
		setCurrentStoryMarker: setValueForGame("currentStoryMarker"),
		setCurrentCharacterId: setValueForGame("currentCharacterId"),
		setMessage: setValueForGame("message"),
		setEditMessage: setValueForGame("editMessage"),
		setEnrichOutput: setValueForGame("enrichOutput"),
		setCommentMessage: setValueForGame("commentMessage"),
		setTone: setValueForGame("tone"),
		setToneColor: setValueForGame("toneColorOverride"),
		setCurrentParty: setValueForGame("currentParty"),
		setLanguage: setValueForGame("language"),
		setWhisperingTo: setValueForGame("whisperingTo"),
		setEditingLine: setValueForGame("editingLine"),
		setHasPartiesOnScreen: setValueForGame("hasPartiesOnScreen"),
		setMacrosHistory: setValueForGame("macrosHistory"),
		setLoadingMoreLines: setValueForGame("loadingMoreLines"),
		setWhisperToCharacterIds: setValueForGame("whisperToCharacterIds"),
		setTalkToCharacterIds: setValueForGame("talkToCharacterIds"),
		setWhisperToPlayerIds: setValueForGame("whisperToPlayerIds"),
		setTalkToPlayerIds: setValueForGame("talkToPlayerIds"),
		setAverageIntervalSeconds: setValueForGame("averageIntervalSeconds"),
		setNotes: setValueForGame("notes"),
	},
});

export function getCurrentGameUI(state) {
	const gameId = state.games.currentId;
	if (!gameId) {
		return {};
	}
	return state.gamesUI[gameId];
}

export const {
	setGameReady,
	setLinesDisplayed,
	setFocusedType,
	setCurrentStoryMarker,
	setCurrentCharacterId,
	setMessage,
	setEditMessage,
	setEnrichOutput,
	setCommentMessage,
	setTone,
	setToneColor,
	setCurrentParty,
	setLanguage,
	setWhisperingTo,
	setEditingLine,
	setHasPartiesOnScreen,
	setMacrosHistory,
	setLoadingMoreLines,
	setWhisperToCharacterIds,
	setTalkToCharacterIds,
	setWhisperToPlayerIds,
	setTalkToPlayerIds,
	setAverageIntervalSeconds,
	setNotes
} = gamesUISlice.actions;

export default gamesUISlice.reducer;
