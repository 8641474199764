import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { Divider } from "react-native-paper";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { getRuleGroup, idsToSelectedDie } from "../../tools/diceRoll";
import { isIos, ws } from "../../tools/generic";
import { cStyle, usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import IconButton from "../generic/buttons/IconButton";
import ListSwitch from "../generic/ListSwitch";
import CondView from "../meta/CondView";
import DiceGroup from "./DiceGroup";
import DieIcon from "./DieIcon";
import PoolOption from "./PoolOption";

const hasSomeActiveRule = (group) => {
	const values = Object.values(group);

	return values.includes(true);
};

import { BuildStyleMethod } from "../../styles/theming";

function DiceRollPoolOptionsScreen({ route, navigation }) {
	const styles = stylesMethod(global.theme);
	const {
		pool,
		comparedPool,
		isSecondPool,
		comparedPoolRules,
		comparedPoolModifier,
		presetGroups,
		selectedGroupIndex: selectedGroupIndexReceived,
		compareType: compareTypeReceived,
	} = route.params;

	const [selectedIds, setselectedIds] = React.useState([]);
	const [comparingToPool, setcomparingToPool] = React.useState(!!comparedPool);
	const [compareType, setcompareType] = React.useState(compareTypeReceived || ">");

	const [groups, setgroups] = React.useState(presetGroups || []);
	const [selectedGroupIndex, setselectedGroupIndex] = React.useState(selectedGroupIndexReceived);

	const scrollViewRef = React.useRef();

	const poolListRef = React.useRef();

	const getGroup = React.useCallback(
		(id) => {
			return groups.find((g) => g.dice.includes(id));
		},
		[groups]
	);

	const unassignedDice = pool.filter((die) => !getGroup(die.id));
	const previousUnassignedDice = usePrevious(unassignedDice);

	let allSimilar = !pool.some((sd) => sd.die.id !== pool[0].die.id);
	let wholePoolSelected = !selectedIds.length && !groups.length && allSimilar;
	let allowOptions = selectedGroupIndex !== null || selectedIds.length || wholePoolSelected;
	const diceSelectedCount = wholePoolSelected
		? pool.length
		: selectedIds.length || groups[selectedGroupIndex]?.dice.length || 0;
	const firstSelectedDie = selectedIds.length ? idsToSelectedDie(selectedIds, pool)[0] : null;

	const saveChanges = React.useCallback(() => {
		navigation.navigate(isSecondPool ? "CompareToPoolModal" : "SimpleRollModal", {
			ruleGroupsSet: groups,
			comparedPool: comparingToPool ? comparedPool : null,
			compareType,
			comparedPoolRules: comparingToPool ? comparedPoolRules : null,
			comparedPoolModifier: comparingToPool ? comparedPoolModifier : null,
		});
	}, [groups, comparedPool, comparedPoolRules, isSecondPool, comparingToPool, compareType]);

	const selectDieId = React.useCallback(
		(id) => {
			if (selectedGroupIndex != null) {
				setselectedGroupIndex(null);
			}
			if (selectedIds.includes(id)) {
				setselectedIds(selectedIds.rg_removeSimple(id));
			} else {
				setselectedIds(selectedIds.rg_pushUniquePure(id));
			}
		},
		[selectedIds, selectedGroupIndex, groups]
	);

	const getCurrentGroup = React.useCallback(() => {
		if (selectedGroupIndex === null) {
			return null;
		}
		return { group: groups[selectedGroupIndex], newGroups: groups.slice(), groupIndex: selectedGroupIndex };
	}, [groups, selectedGroupIndex]);

	const getCurrentGroupOrCreateNew = React.useCallback(() => {
		const currentGroup = getCurrentGroup();
		if (currentGroup) {
			return currentGroup;
		} else {
			const group = { dice: wholePoolSelected ? pool.map((d) => d.id) : selectedIds.slice() };
			const newGroups = groups.concat([group]);
			return { group, newGroups, groupIndex: newGroups.length - 1 };
		}
	}, [groups, selectedGroupIndex, selectedIds, wholePoolSelected, pool]);

	const deleteGroup = React.useCallback(
		(index) => {
			if (selectedGroupIndex === index) {
				setselectedGroupIndex(null);
				setselectedIds(groups[index].dice);
			}
			const result = groups.rg_removeAtPure(index);
			setgroups(result);
		},
		[groups, selectedGroupIndex]
	);

	React.useEffect(() => {
		if (selectedGroupIndex !== null) {
			setselectedIds([]);
			setcomparingToPool(false);
		}
	}, [selectedGroupIndex]);

	React.useEffect(() => {
		if (!isIos() && previousUnassignedDice?.length !== unassignedDice.length) {
			poolListRef.current?.scrollToOffset(0);
		}
	}, [unassignedDice]);

	React.useEffect(() => {
		if (comparingToPool) {
			scrollViewRef.current.scrollToEnd();
		}
	}, [comparingToPool]);

	const togglePool = React.useCallback(() => {
		if (selectedIds.length === unassignedDice.length) {
			setselectedIds([]);
		} else {
			setselectedIds(unassignedDice.map((d) => d.id));
		}
	}, [selectedIds, unassignedDice]);

	const setOptionEnabled = React.useCallback(
		(optionKey, enabled) => {
			const { group, newGroups, groupIndex } = getCurrentGroupOrCreateNew();
			group[optionKey] = enabled;
			group[optionKey + "_value_useSheet"] = false;
			if (!hasSomeActiveRule(group)) {
				deleteGroup(groupIndex);
				return;
			}

			newGroups[groupIndex] = group;

			setgroups(newGroups);
			if (groupIndex !== selectedGroupIndex) {
				setselectedGroupIndex(groupIndex);
			}
		},
		[groups, selectedIds, selectedGroupIndex]
	);

	const updateOptionValue = React.useCallback(
		(optionValueKey, value, _sheetValue) => {
			if (selectedGroupIndex === null) return;
			const { group, newGroups, groupIndex } = getCurrentGroup();
			group[optionValueKey] = value;
			group[optionValueKey + "_sheetValue"] = _sheetValue;
			newGroups[groupIndex] = group;
			setgroups(newGroups);
		},
		[groups, selectedGroupIndex]
	);

	let sortedDice = comparedPool ? comparedPool.slice() : [];

	if (comparedPoolRules) {
		sortedDice = sortedDice.filter((d) => {
			const rules = comparedPoolRules.find((o) => o.dice.includes(d.id));
			return !rules || rules.dice[0] === d.id;
		});
	}

	const genericOptions = {
		disabled: !allowOptions,
		group: groups[selectedGroupIndex],
		setIsEnabled: setOptionEnabled,
		updateOptionValue: updateOptionValue,
	};

	return (
		<>
			<AppScreenView scroll scrollViewRef={scrollViewRef}>
				<CondView show={groups.length} style={{ marginTop: 8 }}>
					{groups.map((group, groupIndex) => (
						<View key={groupIndex} style={{ flex: 1 }}>
							<FlatList
								style={cStyle([
									styles.poolContainer,
									styles.groupContainer,
									[selectedGroupIndex === groupIndex, styles.groupHighlighed],
								])}
								onResponderTerminationRequest={(env) => false}
								horizontal
								data={group.dice}
								keyExtractor={(item, index) => String(index)}
								renderItem={({ item: selectedDieId }) => {
									const dieSelected = pool.rg_findById(selectedDieId);
									return (
										<DieIcon
											die={dieSelected.die}
											negative={dieSelected.negative}
											highlighted={selectedGroupIndex === groupIndex}
											onPress={() => {
												setselectedGroupIndex(
													selectedGroupIndex === groupIndex && unassignedDice.length
														? null
														: groupIndex
												);
											}}
											activeOpacity={1}
										/>
									);
								}}
								contentContainerStyle={[styles.poolContainerContent, styles.groupContainerContent]}
							/>
							<IconButton
								// transparent
								icon={{ type: MaterialIcons, name: "clear", size: 24 }}
								style={{ position: "absolute", left: -12, top: -12 }}
								onPress={() => deleteGroup(groupIndex)}
							/>
						</View>
					))}
				</CondView>

				<AppText hide={!unassignedDice.length} color="hint">
					{_("Select dice to apply rules to (hold to toggle all)")}
				</AppText>

				<CondView show={!!unassignedDice.length}>
					<TouchableOpacity
						style={styles.poolContainer}
						onPress={() => {
							if (selectedGroupIndex !== null) setselectedGroupIndex(null);
						}}
						onLongPress={togglePool}
						delayLongPress={global.delayLongPress}
					>
						<FlatList
							ref={poolListRef}
							horizontal
							data={unassignedDice}
							// maintainVisibleContentPosition
							keyExtractor={(item, index) => String(index)}
							renderItem={({ item }) => {
								return (
									<DieIcon
										disabled={
											firstSelectedDie &&
											(item.die.id !== firstSelectedDie.die.id ||
												item.negative !== firstSelectedDie.negative)
										}
										die={item.die}
										highlighted={selectedIds.includes(item.id)}
										onPress={() => selectDieId(item.id)}
										onLongPress={togglePool}
										delayLongPress={global.delayLongPress}
										negative={item.negative}
									/>
								);
							}}
							contentContainerStyle={styles.poolContainerContent}
						/>
					</TouchableOpacity>
				</CondView>

				<PoolOption
					{...genericOptions}
					disabled={diceSelectedCount < 2}
					title={_("Keep X highest", "dice pool option")}
					optionKey="keepHighest"
					parameterLabel={_("Amount to keep", "keep x option")}
					keyboardType="numeric"
					defaultValue={1}
				/>

				<PoolOption
					{...genericOptions}
					disabled={diceSelectedCount < 2}
					title={_("Keep X lowest", "dice pool option")}
					optionKey="keepLowest"
					parameterLabel={_("Amount to keep", "keep x option")}
					keyboardType="numeric"
					defaultValue={1}
				/>

				<PoolOption
					{...genericOptions}
					disabled={diceSelectedCount < 2}
					title={_("Drop X highest", "dice pool option")}
					optionKey="dropHighest"
					parameterLabel={_("Amount to drop", "drop x option")}
					keyboardType="numeric"
					defaultValue={1}
				/>

				<PoolOption
					{...genericOptions}
					disabled={diceSelectedCount < 2}
					title={_("Drop X lowest", "dice pool option")}
					optionKey="dropLowest"
					parameterLabel={_("Amount to drop", "drop x option")}
					keyboardType="numeric"
					defaultValue={1}
				/>

				<PoolOption {...genericOptions} title={_("Explode", "dice pool option")} optionKey="explode" />

				<PoolOption
					{...genericOptions}
					title={_("Count successes above", "dice pool option")}
					optionKey="successAbove"
					parameterLabel={_("Number", "count successes option")}
					keyboardType="numeric"
				/>
				<PoolOption
					{...genericOptions}
					title={_("Count successes below", "dice pool option")}
					optionKey="successBelow"
					parameterLabel={_("Number", "count successes option")}
					keyboardType="numeric"
				/>

				<PoolOption
					{...genericOptions}
					title={_("Reroll X", "dice pool option")}
					optionKey="reroll"
					parameterLabel={_("Number to reroll", "reroll option")}
					keyboardType="numeric"
				/>

				<PoolOption
					{...genericOptions}
					title={_("Reroll X (once)", "dice pool option")}
					optionKey="rerollOnce"
					parameterLabel={_("Number to reroll once", "reroll option")}
					keyboardType="numeric"
				/>

				<PoolOption
					{...genericOptions}
					title={_("Reroll above", "dice pool option")}
					optionKey="rerollAbove"
					parameterLabel={_("Number", "reroll option")}
					keyboardType="numeric"
				/>

				<PoolOption
					{...genericOptions}
					title={_("Reroll above (once)", "dice pool option")}
					optionKey="rerollAboveOnce"
					parameterLabel={_("Number", "reroll option")}
					keyboardType="numeric"
				/>

				<PoolOption
					{...genericOptions}
					title={_("Reroll below", "dice pool option")}
					optionKey="rerollBelow"
					parameterLabel={_("Number", "reroll option")}
					keyboardType="numeric"
				/>

				<PoolOption
					{...genericOptions}
					title={_("Reroll below (once)", "dice pool option")}
					optionKey="rerollBelowOnce"
					parameterLabel={_("Number", "reroll option")}
					keyboardType="numeric"
				/>

				<Divider style={{ marginVertical: 16 }} />

				{!isSecondPool && (
					<ListSwitch
						disabled={diceSelectedCount > 0 && diceSelectedCount < pool.length}
						title={_("Success compared to pool", "dice pool option")}
						isEnabled={comparingToPool}
						setIsEnabled={setcomparingToPool}
					/>
				)}

				<CondView show={!comparingToPool} style={{ height: 16 }} />

				<CondView show={comparingToPool} style={{ marginTop: 16, marginBottom: 8 }}>
					{/* prettier-ignore */}
					<ButtonsRow>
						<IconButton icon={{ text: ">" }} square toggled={compareType === ">"} onPress={()=> setcompareType(">")}/>
						<IconButton icon={{ text: "<" }} square toggled={compareType === "<"} onPress={()=> setcompareType("<")}/>
						<IconButton icon={{ text: ">=" }} square toggled={compareType === ">="} onPress={()=> setcompareType(">=")}/>
						<IconButton icon={{ text: "<=" }} square toggled={compareType === "<="} onPress={()=> setcompareType("<=")}/>
						<IconButton icon={{ text: "=" }} square toggled={compareType === "="} onPress={()=> setcompareType("=")}/>
					</ButtonsRow>

					<AppText color="hint" style={{ marginTop: 16 }}>
						{ws(_("Click to edit"), _("Tap to edit"))}
					</AppText>
					<TouchableOpacity
						style={[styles.poolContainer, { marginTop: 4 }]}
						onPress={() => {
							navigation.navigate("CompareToPoolModal", {
								isSecondPool: true,
								selectedDice: comparedPool,
								ruleGroupsSet: comparedPoolRules,
								modifier: comparedPoolModifier,
							});
						}}
					>
						<FlatList
							horizontal
							data={sortedDice}
							keyExtractor={(item, index) => String(index)}
							// renderItem={({ item }) => <DieIcon die={item.die} negative={item.negative} />}
							renderItem={({ item }) => {
								const rules = getRuleGroup(item.id, comparedPoolRules, comparedPool);
								if (rules) {
									return <DiceGroup appliedRules={rules} />;
								}
								return <DieIcon die={item.die} negative={item.negative} />;
							}}
							contentContainerStyle={styles.poolContainerContent}
						/>
					</TouchableOpacity>
				</CondView>
			</AppScreenView>
			<ButtonFooter
				buttonProps={[
					{ title: _("Cancel"), onPress: () => navigation.goBack() },
					{ title: _("Save"), onPress: saveChanges },
				]}
			/>
		</>
	);
}

export default DiceRollPoolOptionsScreen;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	poolContainer: {
		borderWidth: 1,
		borderColor: "black",
		marginTop: 8,
		marginBottom: 16,
		minHeight: 64,
	},
	poolContainerContent: {
		alignItems: "center",
		paddingHorizontal: 8,
		paddingTop: 8,
		paddingBottom: 8,
	},
	groupContainer: {
		alignSelf: "flex-start",
		flex: 1,
		borderRadius: 16,
	},
	groupContainerContent: {
		paddingVertical: 0,
		borderRadius: 16,
		overflow: "hidden",
	},
	groupHighlighed: {
		backgroundColor: colors.primary,
	},
}));
