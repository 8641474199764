import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import AppText from "../generic/AppText";
import InputField from "../generic/InputField";
import CondView from "../meta/CondView";
import OAIOutput from "./OAIOutput";
import OAIRequestButton from "./OAIRequestButton";
import OAIUsePregenerated from "./OAIUsePregenerated";

function OAIQuestStarters({ dispatch, user }) {
	const [theme, settheme] = React.useState();
	const [waitingForResponse, setwaitingForResponse] = React.useState(false);
	const [output, setoutput] = React.useState();
	const [costEstimation, setcostEstimation] = React.useState(0);
	const [estimatingCost, setestimatingCost] = React.useState(false);

	// React.useEffect(() => {
	// 	apiFetch(`users/${user.id}/openairequest/53`).then(setoutput)
	// }, []);

	React.useEffect(() => {
		setestimatingCost(false);
	}, [costEstimation]);

	React.useEffect(() => {
		if (!theme) {
			setcostEstimation(0);
			return () => null;
		}

		setestimatingCost(true);

		const getEstimate = () => {
			apiFetch(`users/${user.id}/openairequest/cost-estimate`, "GET", {
				request_type: "QUEST_STARTERS",
				theme: theme,
			})
				.then(setcostEstimation)
				.then(() => setestimatingCost(false));
		};

		const timeout = setTimeout(getEstimate, 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, [theme]);

	return (
		<View style={styles.container}>
			<AppText bold size="large" style={{ marginBottom: 8 }}>
				{_("Generate 5 quest starters")}
			</AppText>

			<AppText color="secondary" style={{ marginBottom: 8 }}>
				{_("Please provide the following information for the AI")}
			</AppText>
			<InputField
				label={_("Theme", "theme of the content generated by the AI")}
				value={theme}
				onChangeText={settheme}
				style={{ marginBottom: 16 }}
			/>
			<Divider theme={{ dark: true }} style={{ marginVertical: 16 }} />

			<OAIOutput oairequest={output} loading={waitingForResponse} />

			<Divider theme={{ dark: true }} style={{ marginVertical: 16 }} />
			<CondView show={theme}>
				<OAIUsePregenerated
					disabled={!theme}
					searchQuery={theme}
					requestType="QUEST_STARTERS"
					onPregeneratedSet={setoutput}
					setwaitingForResponse={setwaitingForResponse}
				/>

				<OAIRequestButton
					endpoint="quest-starters"
					requestParams={{ theme }}
					setoutput={setoutput}
					onWaitingForResponseChanged={setwaitingForResponse}
					disabled={!theme}
					costEstimation={costEstimation}
					estimatingCost={estimatingCost}
					size="large"
				/>
			</CondView>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(OAIQuestStarters);

const styles = StyleSheet.create({
	container: {},
});
