import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { apiFetch } from "../../network/apiFetch";
import { _ } from "../../i18n/i18n";
import AppButton from "../generic/buttons/AppButton";
import { useNavigation } from "@react-navigation/native";
import { ws } from "../../tools/generic";
import { fullScreenContentWidth } from "../../styles/dynamicStyles";

function ReferralUpgradeTracker({ dispatch, user }) {
	const [referralCount, setreferralCount] = React.useState(0);

	const navigation = useNavigation();

	React.useEffect(() => {
		apiFetch(`users/${user.id}/profiles-referring-count`).then((value) => setreferralCount(value));
	}, [user]);

	const progressToTemp = Math.max(0, referralCount - user.profile.last_temporary_upgrade);

	return (
		<View style={styles.container}>
			<AppButton
				size="large"
				title={_("Free referral upgrade (%(referal_count)s)", "menu title", {
					referal_count: progressToTemp + "/10",
				})}
				style={{ marginBottom: 8 }}
				info={ws(_("Click for more info"), _("Tap for more info"))}
				onPress={() => navigation.navigate("ReferralUpgradeScreen")}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(ReferralUpgradeTracker);

const styles = StyleSheet.create({
	container: ws({ maxWidth: fullScreenContentWidth() }),
});
