import * as React from "react";
import { FlatList, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fadeLayout } from "../../tools/animation";
import { isWeb, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import ModalScreen from "../generic/modal/ModalScreen";
import PartyEditor from "./PartyEditor";

function ManagePartiesScreen({ gameId, navigation, characters, parties }) {
	const [movingCharacter, setmovingCharacter] = React.useState();

	parties = parties.filter((p) => p.on_going);
	const noPartyCharacters = characters.filter(
		(c) => !c.deleted && !c.is_npc && !parties.some((p) => p.characters.includes(c.id))
	);

	if (noPartyCharacters.length) {
		parties.splice(0, 0, {
			characters: noPartyCharacters.map((c) => c.id),
		});
	}

	const [partiesToSave, setcreatedParties] = React.useState(parties);

	const moveToParty = React.useCallback(
		(character, party) => {
			const newParties = partiesToSave.slice();
			const index = partiesToSave.indexOf(party);

			for (let i = 0; i < newParties.length; i++) {
				const p = newParties[i];
				newParties[i] = { ...p, characters: p.characters.rg_removeSimple(character.id) };
			}

			if (party) {
				newParties[index] = { ...newParties[index], characters: party.characters.concat([character.id]) };
			} else {
				newParties.push({ characters: [character.id] });
			}

			for (let i = newParties.length - 1; i >= 0; i--) {
				if (!newParties[i].characters.length) {
					newParties.splice(i, 1);
				}
			}

			setcreatedParties(newParties);
			setmovingCharacter(null);
		},
		[partiesToSave]
	);

	const selectCharacter = React.useCallback(
		(character) => {
			if (!movingCharacter) {
				fadeLayout();
			}

			if (partiesToSave.length === 1) {
				moveToParty(character);
			} else {
				setmovingCharacter(character);
			}
		},
		[movingCharacter, partiesToSave]
	);

	const updatePartyName = React.useCallback(
		(party, name) => {
			const newParties = partiesToSave.slice();
			const index = partiesToSave.indexOf(party);
			newParties[index] = { ...newParties[index], name };
			setcreatedParties(newParties);
		},
		[partiesToSave]
	);

	const createParties = React.useCallback(async () => {
		const parties_data = partiesToSave
			.filter((p) => p.characters.length > 0)
			.map((p) => {
				return {
					id: p?.id,
					is_new: !p.id,
					name: p.name || _("New party", "splitting party"),
					characters: p.characters,
				};
			});
		await apiFetch(`games/${gameId}/parties`, "POST", {
			parties_data: JSON.stringify(parties_data),
		});
		navigation.navigate("Game");
	}, [partiesToSave, gameId, navigation]);

	const regroupAll = React.useCallback(() => {
		apiFetch(`games/${gameId}/parties`, "POST");
		navigation.navigate("Game");
	}, [gameId]);

	let hint = ws(
		_("Click on a character to move it to another party."),
		_("Tap a character to move it to another party.")
	);

	const options = [
		{
			title: _("Cancel"),
			onPress: () => navigation.goBack(),
		},
	];

	options.push({
		title: parties.length > 1 ? _("Save") : _("Split", "split party option"),
		disabled: partiesToSave.length <= 1,
		onPress: createParties,
	});

	const movingCharacterParty = movingCharacter
		? partiesToSave.find((p) => p.characters.includes(movingCharacter.id))
		: null;

	const component = (
		<>
			<AppText color="hint" style={{ marginBottom: 16 }}>
				{hint}
			</AppText>
			<FlatList
				data={partiesToSave}
				keyExtractor={(item, index) => String(index)}
				renderItem={({ item }) => (
					<PartyEditor
						movingCharacter={movingCharacter}
						party={item}
						selectCharacter={selectCharacter}
						moveToParty={moveToParty}
						hideNoName={partiesToSave.length === 1 && !movingCharacter}
						saveNameChange={updatePartyName}
					/>
				)}
				ListFooterComponent={() =>
					!!movingCharacter &&
					movingCharacterParty.characters.length > 1 && (
						<PartyEditor
							movingCharacter={movingCharacter}
							selectCharacter={selectCharacter}
							moveToParty={moveToParty}
							saveNameChange={updatePartyName}
						/>
					)
				}
			/>

			{parties.length > 1 && <AppButton title={_("Regroup all", "regroup all parties")} onPress={regroupAll} />}
		</>
	);

	if (isWeb()) {
		return (
			<ModalScreen wide title={_("Manage parties")}>
				{component}
				<ButtonFooter buttonProps={options} />
			</ModalScreen>
		);
	}

	return (
		<>
			<AppScreenView>{component}</AppScreenView>
			<ButtonFooter buttonProps={options} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		characters: state.charactersByGame[gameId],
		parties: state.parties[gameId]?.all || Array.rg_empty,
		gameId,
	};
};

export default connect(mapStateToProps)(ManagePartiesScreen);


