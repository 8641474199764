import AsyncStorage from "@react-native-async-storage/async-storage";
import { createDrawerNavigator } from "@react-navigation/drawer";
import React from "react";
import { View } from "react-native";
import { useDimensions } from "react-native-web-hooks";
import { useDispatch } from "react-redux";
import { fetchHomeScreenHelpers } from "../../store/slices/interactiveHelpersSlice";
import { isWeb, isWide, ws } from "../../tools/generic";
import InteractiveHelperDrawer from "../interactivehelpers/InteractiveHelperDrawer";
import MainDrawerNavigator from "../navigation/MainDrawerNavigator";
import WhatsNewModal from "../news/WhatsNewModal";
import InteractiveHelperSwitch from "./InteractiveHelperSwitch";

const Drawer = createDrawerNavigator();

function InteractiveHelperNavigator({ route }) {
	const dispatch = useDispatch();
	const [initRender, setInitRender] = React.useState(true);
	const [folded, setfolded] = React.useState(false);
	const [onGameScreen, setonGameScreen] = React.useState(false);

	React.useEffect(() => {
		dispatch(fetchHomeScreenHelpers());

		// There is a bug on android where the panel will sometime flicker open when the app is opened.
		// The only known workaround is to not display something in the drawer immediately
		// https://github.com/react-navigation/react-navigation/issues/7561
		setInitRender(false);

		const foldIfNeeded = async () => {
			const shouldFold = (await AsyncStorage.getItem("helperFolded")) || false;
			setfolded(JSON.parse(shouldFold));
		};

		foldIfNeeded();
	}, []);

	React.useEffect(() => {
		AsyncStorage.setItem("helperFolded", JSON.stringify(folded));
	}, [folded]);

	// This call will force the redraw of this component when the window is resized,
	// Without it, the behavior of the right panel doesn't update when the window is resized
	// Also good to keep it on mobile for a triggerring an update of the default width earlier
	useDimensions();

	let style = null;
	if (isWeb()) {
		if (onGameScreen && folded) {
			style = { width: 0 };
		} else {
			style = folded ? { width: 20 } : null;
		}
	} else {
		style = initRender ? { width: null } : null;
	}

	let drawerType = "front";
	if (isWeb()) {
		drawerType = "permanent";
	}

	return (
		<>
			<Drawer.Navigator
				drawerContent={(props) => (
					<InteractiveHelperDrawer
						{...props}
						folded={folded}
						setfolded={setfolded}
						onGameScreen={onGameScreen}
					/>
				)}
				screenOptions={{
					headerShown: false,
					drawerType: drawerType,
					drawerPosition: "right",
					swipeEdgeWidth: 60,
					drawerStyle: style,
				}}
			>
				<Drawer.Screen
					name="MainDrawerNavigator"
					component={ws(undefined, MainDrawerNavigator)}
					children={ws(
						(props) => (
							<MainDrawerNavigator {...props} setonGameScreen={setonGameScreen} />
						),
						undefined
					)}
				/>
			</Drawer.Navigator>

			<WhatsNewModal />
		</>
	);
}
export default InteractiveHelperNavigator;
