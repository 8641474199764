import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
//import Clipboard from "@react-native-community/clipboard";
// import { Clipboard } from "react-native"
import * as Clipboard from 'expo-clipboard';
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchCurrentUser } from "../../store/slices/usersSlice";
import { globalStyles } from "../../styles/global";
import { isWeb } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import IconButton from "../generic/buttons/IconButton";
import ModalScreen from "../generic/modal/ModalScreen";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function ReferralUpgradeScreen({ dispatch, user }) {
	const [redeeming, setredeeming] = React.useState(false);
	const [referralCount, setreferralCount] = React.useState(0);

	const redeemTemp = React.useCallback(async () => {
		setredeeming(true);
		await apiFetch(`profiles/${user.profile.id}/redeem-temporary`, "POST").then(() => dispatch(fetchCurrentUser()));
		setredeeming(false);
	}, [user]);

	const redeemPerm = React.useCallback(async () => {
		setredeeming(true);
		await apiFetch(`profiles/${user.profile.id}/redeem-permanent`, "POST").then(() => dispatch(fetchCurrentUser()));
		setredeeming(false);
	}, [user]);

	React.useEffect(() => {
		apiFetch(`users/${user.id}/profiles-referring-count`).then((value) => setreferralCount(value));
	}, [user]);

	const progressToTemp = Math.max(0, referralCount - user.profile.last_temporary_upgrade);
	const progressToPerm = Math.max(0, referralCount - user.profile.last_permanent_upgrade);
	const copiedModalRef = React.useRef();

	const url = "https://www.rolegate.com/ref/" + user.username;

	const ContainerTag = isWeb() ? ModalScreen : AppScreenView

	return (
		<ContainerTag scroll wide title={_("Free referral upgrade")}>
			<TinyFeedbackModal ref={copiedModalRef} message={_("Copied to clipboard!")} />
			<AppText>{_("Every person joining Role Gate through this link will count towards your rewards")}</AppText>
			<View style={[globalStyles.jac, { marginVertical: 16 }]}>
				<AppText>{url}</AppText>
				<IconButton
					icon={{ type: MaterialIcons, name: "content-copy" }}
					transparent
					onPress={() => {
						Clipboard.setStringAsync(url);
						copiedModalRef.current.show();
					}}
				/>
			</View>

			<AppText bold>{_("About referrals", "more info about referrals")}</AppText>

			<AppText list style={styles.listText}>
				{_(
					"You can get a referral link to any game from the game's Share option. It doesn't even need to be a game you are a part of!"
				)}
			</AppText>
			<AppText list style={styles.listText}>
				{_("Redeeming a reward does not take away your progress towards a permanent upgrade.")}
			</AppText>
			<AppText list style={styles.listText}>
				{_(
					"Redeeming a reward if you already have an active one automatically adds 30 days to the current reward."
				)}
			</AppText>
			<AppText list style={styles.listText} color="danger">
				{_(
					"Suspicious activities will be investigated, so refrain from creating fake accounts if you do not want to see your account suspended."
				)}
			</AppText>

			<AppButton
				style={{ marginTop: 16 }}
				size="large"
				title={_("One month account upgrade", "profile referral bonus")}
				info={"(" + progressToTemp + "/10)"}
				disabled={progressToTemp < 10 || redeeming}
				onPress={redeemTemp}
			/>
			<AppButton
				size="large"
				title={_("Permanent account upgrade", "profile referral bonus")}
				info={"(" + progressToPerm + "/100)"}
				disabled={progressToPerm < 100 || redeeming}
				onPress={redeemPerm}
			/>
		</ContainerTag>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(ReferralUpgradeScreen);

const styles = StyleSheet.create({
	listText: {
		marginBottom: 8,
	},
});
