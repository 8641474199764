import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { getPlanPaddleId } from "../../tools/account";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import BoxModal from "../generic/modal/BoxModal";
import AccountLevelText from "./AccountLevelText";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { fetchSubscriptionInfo } from "../../store/slices/subscriptionSlice";
import { Linking } from "react-native";
import { isIos, isWeb } from "../../tools/generic";
import PaddleWebView from "./PaddleWebView";
import { fetchCurrentUser, fetchUser } from "../../store/slices/usersSlice";

function SubscribeButton({ levelSelected, dispatch, user, levelPricesInfo, renewedPaid }) {
	const confirmDowngradeModal = React.useRef();
	const confirmUpgradeModal = React.useRef();
	const confirmCancelRef = React.useRef();
	const paddleWebViewRef = React.useRef();

	const isCurrent = levelSelected === renewedPaid;
	const isDowngrade = renewedPaid > levelSelected;
	const isUpgrade = renewedPaid && renewedPaid < levelSelected;
	const [price, currency] = levelPricesInfo[levelSelected] || [];

	const planId = getPlanPaddleId(levelSelected);

	const createSubscription = React.useCallback(() => {
		const paymentSuccess = () => {
			window.location = "/m/upgrade";
		};

		const checkoutParams = {
			product: planId,
			locale: user.profile.preferred_language,
			email: user.email,
			allowQuantity: false,
			passthrough: `{"user_id": ${user.id} , "username": "${user.username}"}`,
			displayModeTheme: "dark",
		};

		if (isWeb()) {
			const endpoint = window.debug ? "http://127.0.0.1:8000/" : "https://www.rolegate.com/";
			checkoutParams.success = endpoint;
			checkoutParams.successCallback = paymentSuccess;
			window.Paddle.Checkout.open(checkoutParams);
		} else {
			paddleWebViewRef.current.openCheckout(checkoutParams);
		}
	}, [user, planId]);

	const updateSubscription = React.useCallback(() => {
		apiFetch(`users/${user.id}/change-subscription`, "POST", { "new-level": levelSelected }).then(() => {
			dispatch(fetchSubscriptionInfo());
		});
	}, [levelSelected, user.id]);

	const priceComponent = (
		<AppText size="large" centered>
			{_("%(currency)s %(price)s/month", "upgrade account price", {
				price: (
					<AppText key="p" style={{ fontSize: 32 }} bold>
						{price}
					</AppText>
				),
				currency: (
					<AppText key="c" size="average">
						{currency}
					</AppText>
				),
			})}
		</AppText>
	);

	return (
		<View>
			{!isIos() && (
				<AppButton
					hide={!!renewedPaid}
					title={_("Subscribe")}
					style={{ minWidth: 192, minHeight: 64 }}
					onPress={isIos() ? null : createSubscription}
				/>
			)}

			<AppButton
				hide={!isUpgrade}
				title={_("Upgrade", "upgrade subscription")}
				style={{ minWidth: 192, minHeight: 64 }}
				onPress={() => confirmUpgradeModal.current.show()}
			/>
			<AppButton
				hide={!isDowngrade}
				title={_("Downgrade", "downgrade subscription")}
				style={{ minWidth: 192, minHeight: 64 }}
				onPress={() => confirmDowngradeModal.current.show()}
			/>
			<AppButton
				hide={!isCurrent}
				title={_("Cancel subscription")}
				style={{ minWidth: 192, minHeight: 64 }}
				danger
				onPress={() => confirmCancelRef.current.show()}
			/>
			<CancelSubscriptionModal reference={confirmCancelRef} />
			<BoxModal
				ref={confirmDowngradeModal}
				title={_("Downgrade", "downgrade subscription")}
				onConfirm={updateSubscription}
			>
				<AppText>
					{_("Downgrade your subscription to %(new_level)s?", "", {
						new_level: <AccountLevelText key="ac" level={levelSelected} />,
					})}
				</AppText>
				{priceComponent}
				<AppText style={{ marginTop: 8 }} color="hint">
					{_("You will maintain your current subscription level until the end of the paid period.")}
				</AppText>
			</BoxModal>
			<BoxModal
				ref={confirmUpgradeModal}
				title={_("Upgrade", "Upgrade subscription")}
				onConfirm={updateSubscription}
			>
				<AppText>
					{_("Upgrade your subscription to %(new_level)s?", "", {
						new_level: <AccountLevelText key="ac" level={levelSelected} />,
					})}
				</AppText>
				{priceComponent}
			</BoxModal>
			{!isWeb() && <PaddleWebView ref={paddleWebViewRef} dispatch={dispatch} />}
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		levelPricesInfo: state.subscriptionInfo.levelPricesInfo,
		renewedPaid: state.subscriptionInfo.renewedPaid,
	};
};

export default connect(mapStateToProps)(SubscribeButton);


