import { AntDesign } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import CharacterIconButton from "../characters/CharacterIconButton";
import IconButton from "../generic/buttons/IconButton";
import InputField from "../generic/InputField";

import { BuildStyleMethod } from "../../styles/theming";

function PartyEditor({ party, characters, movingCharacter, selectCharacter, hideNoName, moveToParty, saveNameChange }) {
	const styles = stylesMethod(global.theme);
	const [partyName, setpartyName] = React.useState(party?.name);

	const potentialMoveDestination = movingCharacter && (!party || !party.characters.includes(movingCharacter.id));

	return (
		<View style={styles.container}>
			{(!!party?.name || !hideNoName) && (
				<InputField
					value={partyName}
					onChangeText={(t) => {
						setpartyName(t);
						saveNameChange(party, t);
					}}
					label={party ? _("Party name", "part name input placeholder") : _("New party", "splitting party")}
				/>
			)}
			<FlatList
				extraData={movingCharacter?.id}
				horizontal
				style={{ minHeight: 64, marginVertical: 8 }}
				data={party?.characters || []}
				keyExtractor={(item, index) => String(index)}
				renderItem={({ item }) => {
					const selected = movingCharacter?.id === item;
					return (
						<CharacterIconButton
							character={characters[item]}
							onPress={selected ? () => selectCharacter(null) : selectCharacter}
							selected={selected}
						/>
					);
				}}
				ListFooterComponent={() =>
					potentialMoveDestination ? (
						<IconButton
							style={{ marginLeft: 16, height: null, width: 48 }}
							iconContainerStyle={styles.addCharacterIcon}
							icon={{
								type: AntDesign,
								name: "login",
								style: { transform: [{ rotate: "90deg" }] },
								size: 24,
							}}
							square
							transparent
							title={_("Move", "move character to a different party")}
							onPress={() => moveToParty(movingCharacter, party)}
						/>
					) : null
				}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		characters: state.characters,
	};
};

export default connect(mapStateToProps)(PartyEditor);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		marginBottom: 8,
		borderBottomWidth: 1,
		borderBottomColor: colors.lightBorder,
	},
	addCharacterIcon: {
		height: 48,
		width: 48,
		justifyContent: "center",
		alignItems: "center",
	},
}));
