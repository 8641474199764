import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useDimensions } from "react-native-web-hooks";
import { _ } from "../../i18n/i18n";
import CreateAccountScreen from "../authentication/CreateAccountScreen";
import ForgotPasswordScreen from "../authentication/ForgotPasswordScreen";
import LoginScreen from "../authentication/LoginScreen";
import HomeScreenLoggedOff from "../home/HomeScreenLoggedOff";
import PublicNavigationScreens from "./PublicNavigationScreens";

const Stack = createStackNavigator();

function LoggedOffStack() {
	global.navigatorWidth = useDimensions().window.width;
	global.windowWidth = useDimensions().window.width;

	return (
			<Stack.Navigator screenOptions={{ headerTintColor: global.colors.textDefault }}>
				<Stack.Screen
					name="LoggedOff"
					component={HomeScreenLoggedOff}
					options={{ headerShown: false, headerTitle: _("Home"), title: "Role Gate" }}
				/>
				<Stack.Screen
					name="Login"
					component={LoginScreen}
					options={{ headerTitle: _("Login"), title: _("Login | Role Gate") }}
				/>
				<Stack.Screen
					name="CreateAccount"
					component={CreateAccountScreen}
					options={{ headerTitle: _("Create account"), title: _("Create account | Role Gate") }}
				/>
				<Stack.Screen
					name="ForgotPassword"
					component={ForgotPasswordScreen}
					options={{ headerTitle: _("Reset password"), title: _("Reset password | Role Gate") }}
				/>
				{PublicNavigationScreens(Stack)}
			</Stack.Navigator>
		
	);
}

export default LoggedOffStack;
