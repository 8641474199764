import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import ModalScreen from "../generic/modal/ModalScreen";
import Cond from "../meta/Cond";
import CoinCounter from "./CoinCounter";
import ConfirmGiveCoinBoxModal from "./ConfirmGiveCoinBoxModal";

function GiveCoinModal({ route, navigation, user, onTransactionPerformed }) {
	const { subjectGetter, previousScreenName } = route.params;
	const purse = user.profile.purse;
	const [givingCoinLevel, setGivingCoinLevel] = React.useState(null);
	const modalRef = React.useRef(null);

	const [userTransactions, setUserTransactions] = React.useState(null);

	React.useEffect(() => {
		apiFetch(`users/${user.id}/coin-transactions`, "GET", {
			history: true,
			"page-size": 10000,
			sender_id: user.id,
			...subjectGetter,
		}).then(({ results }) => {
			setUserTransactions(results);
		});
	}, []);

	const modalConfirm = (level) => {
		setGivingCoinLevel(level);
		modalRef.current.show();
	};

	const alreadyRewarded = React.useCallback(
		(level) => {
			if (!userTransactions) {
				return true;
			}
			return userTransactions.some((ct) => ct.coin_level === level);
		},
		[userTransactions]
	);

	return (
		<ModalScreen title={_("Select a coin from your purse")}>
			<View style={styles.container}>
				<CoinCounter
					awarded={alreadyRewarded(0)}
					disabled={purse.wood <= 0}
					onCoinPressed={modalConfirm}
					level={0}
					amount={purse.wood}
				/>
				<Cond show={!global.appleStoreReview}>
					<CoinCounter
						awarded={alreadyRewarded(1)}
						disabled={purse.copper <= 0}
						onCoinPressed={modalConfirm}
						level={1}
						amount={purse.copper}
					/>
					<CoinCounter
						awarded={alreadyRewarded(2)}
						disabled={purse.silver <= 0}
						onCoinPressed={modalConfirm}
						level={2}
						amount={purse.silver}
					/>
					<CoinCounter
						awarded={alreadyRewarded(3)}
						disabled={purse.gold <= 0}
						onCoinPressed={modalConfirm}
						level={3}
						amount={purse.gold}
					/>
					<CoinCounter
						awarded={alreadyRewarded(4)}
						disabled={purse.platinum <= 0}
						onCoinPressed={modalConfirm}
						level={4}
						amount={purse.platinum}
					/>
					<CoinCounter
						awarded={alreadyRewarded(5)}
						disabled={purse.legendary <= 0}
						onCoinPressed={modalConfirm}
						level={5}
						amount={purse.legendary}
					/>
				</Cond>
			</View>
			<ConfirmGiveCoinBoxModal
				modalRef={modalRef}
				level={givingCoinLevel}
				subjectGetter={subjectGetter}
				onConfirm={() => {
					if (previousScreenName) {
						navigation.navigate({
							name: previousScreenName,
							params: { coinGivenLevel: givingCoinLevel, subjectGetter },
							merge: true,
						});
					} else {
						navigation.goBack();
					}
				}}
				onTransactionPerformed={onTransactionPerformed}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(GiveCoinModal);

export const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 32,
	},
});
