import { _ } from "../i18n/i18n";
import { Clamp } from "./generic";

export const getLanguageProficiency = (character, language) => {
	if (!character || !language || character.is_npc) return 3;
	if (language.fluents.indexOf(character.id) > -1) return 3;
	if (language.intermediates.indexOf(character.id) > -1) return 2;
	if (language.beginners.indexOf(character.id) > -1) return 1;

	return 0;
};

export const getLineUnderstanding = (character, language) => {
	if (!character || !language || character.is_npc) return 3;
	// TODO: investigate why sometime this method is called with language object not having any of the _speakers array
	if (language.fluent_speakers?.indexOf(character.id) > -1) return 3;
	if (language.intermediate_speakers?.indexOf(character.id) > -1) return 2;
	if (language.beginner_speakers?.indexOf(character.id) > -1) return 1;

	return 0;
};

export const proficiencyLevelToText = (level) => {
	switch (level) {
		case 1:
			return _("Beginner", "proficiency level");
		case 2:
			return _("Intermediate", "proficiency level");
		case 3:
			return _("Fluent", "proficiency level");
		default:
			return _("None", "proficiency level");
	}
};
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export const getRandomLetter = () => {
	const random = Clamp(Math.floor(Math.random() * alphabet.length), 0, alphabet.length - 1);
	return alphabet[random];
};
