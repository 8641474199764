import * as React from "react";
import { _ } from "../../i18n/i18n";
import { isWeb } from "../../tools/generic";
import { modalScreenOptions } from "../../tools/navigationOptions";
import PrivacyPolicyScreen from "../about/PrivacyPolicyScreen";
import TermsScreen from "../about/TermsScreen";

import BrowseGamesScreen from "../games/BrowseGamesScreen";
import ExploreScreen from "../games/ExploreScreen";
import GameDetailsPanelScreen from "../games/GameDetailsPanelScreen";
import GameLoader from "../games/GameLoader";
import ProfilePage from "../profile/ProfilePage";
import CharacterSheetStack from "../sheets/CharacterSheetStack";
import GameStack from "./GameStack";

function PublicNavigationScreens(Stack) {
	return (
		<>
			<Stack.Screen
				name="Explore"
				component={ExploreScreen}
				options={{ headerTitle: _("Explore"), title: _("Explore | Role Gate") }}
			/>
			<Stack.Screen
				name="GameStack"
				component={GameStack}
				options={() => ({
					headerShown: false,
					headerTitle: _("Game", "arrow to go back to opened game"),
					unmountOnBlur: isWeb(),
					title: _("Role Gate"),
				})}
			/>
			<Stack.Screen
				name="GameLoader"
				component={GameLoader}
				options={() => ({
					headerShown: false,
					unmountOnBlur: isWeb(),
					title: _("Role Gate"),
				})}
			/>

			<Stack.Screen
				name="GameDetailsPanelScreen"
				component={GameDetailsPanelScreen}
				options={({ route, navigation }) => ({ headerTitle: "", title: "Role Gate" })}
			/>

			<Stack.Screen
				name="ProfilePage"
				component={ProfilePage}
				options={{ headerTitle: _("Profile", "screen title"), title: "Role Gate" }}
			/>

			<Stack.Screen
				name="CharacterSheetStack"
				component={CharacterSheetStack}
				options={() => ({
					headerShown: false,
					title: _("Role Gate"),
				})}
			/>

			<Stack.Screen
				name="BrowseGamesScreen"
				component={BrowseGamesScreen}
				options={({ route, navigation }) => ({
					headerTitle: _("Browse games"),
					title: _("Browse games | Role Gate"),
				})}
			/>

			<Stack.Screen
				name="TermsScreen"
				component={TermsScreen}
				options={({ route, navigation }) => ({
					headerTitle: _("Terms of service", "about the app page"),
					title: _("Terms of service | Role Gate"),
				})}
			/>

			<Stack.Screen
				name="PrivacyPolicyScreen"
				component={PrivacyPolicyScreen}
				options={({ route, navigation }) => ({
					headerTitle: _("Privacy policy", "about the app page"),
					title: _("Privacy policy | Role Gate"),
				})}
			/>
		</>
	);
}

export default PublicNavigationScreens;
