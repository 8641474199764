import * as React from "react";
import { StyleSheet, View } from "react-native";

function ButtonsRow({ children, style }) {
	if (!children) return null;

	return <View style={[styles.default, style]}>{children}</View>;
}

export default ButtonsRow;

const styles = StyleSheet.create({
	default: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
	},
});
