import { MaterialIcons } from "@expo/vector-icons";
//import Clipboard from "@react-native-community/clipboard";
// import { Clipboard } from "react-native";
import * as Clipboard from 'expo-clipboard';
import * as ImagePicker from "expo-image-picker";
import * as React from "react";
import { ActivityIndicator, Image, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { imgurAPIUpload } from "../../network/imgurapi";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { sendLine } from "../../store/slices/linesSlice";
import { BuildStyleMethod } from "../../styles/theming";
import { isWeb, ws } from "../../tools/generic";
import { GenerateDefaultLine } from "../../tools/lines";
import UnlockedByShareInfo from "../account/UnlockedByShareInfo";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import InputField from "../generic/InputField";
import ModalScreen from "../generic/modal/ModalScreen";
import CondView from "../meta/CondView";
import { editNodeValue } from "../../store/slices/sheetNodesSlice";
import Cond from "../meta/Cond";

let fakeProgress;

function UploadImageModal({ navigation, route, dispatch, gameId, currentCharacter, partyId, user }) {
	const styles = stylesMethod(global.theme);

	const [url, seturl] = React.useState();
	const [imageuri, setimageuri] = React.useState();
	const [imgurImageData, setimgurImageData] = React.useState();
	const [uploadingImage, setuploadingImage] = React.useState(false);
	const [progress, setprogress] = React.useState(0);
	const [imgurURL, setimgurURL] = React.useState();
	const [error, seterror] = React.useState();
	const [finishing, setfinishing] = React.useState();
	const [title, settitle] = React.useState();

	const finishingRef = React.useRef(finishing);
	finishingRef.current = finishing;

	const uploadFromPhone = React.useCallback(async () => {
		if (!isWeb()) {
			const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
			if (status !== "granted") {
				alert("Sorry, we need camera roll permissions to make this work!");
			}
		}

		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			allowsMultipleSelection: false,
			quality: 1,
			base64: true,
		});

		if (!result.cancelled) {
			result = result.assets[0];
			setimageuri(result.uri);
			if (isWeb()) {
				const uri = result.uri.slice(result.uri.indexOf(",") + 1);
				setimgurImageData(uri);
			} else {
				setimgurImageData(result.base64);
			}
		}
	}, []);

	const uploadFromURL = React.useCallback(() => {
		setimgurImageData(`image=${url}`);
	}, [url]);

	const resetProgress = React.useCallback(() => {
		setfinishing(false);
		setprogress(0);
		clearInterval(fakeProgress);
	}, []);

	React.useEffect(() => {
		let timeout;
		seterror(null);
		if (url) {
			timeout = setTimeout(() => {
				setimageuri(url);
			}, 1000);
		}
		return () => {
			clearTimeout(timeout);
			clearInterval(fakeProgress);
		};
	}, [url]);

	React.useEffect(() => {
		if (progress === 100) {
			setfinishing(true);
			setprogress(0);
			clearInterval(fakeProgress);
			fakeProgress = setInterval(() => {
				setprogress((cur) => cur + 1);
			}, 100);
		}
	}, [progress]);

	React.useEffect(() => {
		if (!uploadingImage) resetProgress();
	}, [uploadingImage]);

	React.useEffect(() => {
		if (!imgurImageData) return () => {};

		const uploadImage = async () => {
			setuploadingImage(true);

			imgurAPIUpload(imgurImageData, (progress) => {
				if (!finishingRef.current) {
					progress = Math.round(progress * 100);
					setprogress(progress);
				}
			}).then((response) => {
				setuploadingImage(false);

				if (response.success) {
					setimgurURL(response.data.link);
				} else {
					seterror(response?.data?.error || "error");
				}
			});
		};

		resetProgress();

		uploadImage();
	}, [imgurImageData]);

	React.useEffect(() => {
		if (!uploadingImage) clearInterval(fakeProgress);
	}, [uploadingImage]);

	const saveImageToNode = React.useCallback(() => {
		dispatch(editNodeValue({ nodeId: route.params?.nodeId, newValue: imgurURL }));
		navigation.goBack();
	}, [route.params?.nodeId, imgurURL]);

	const sendImage = React.useCallback(() => {
		const action = JSON.stringify({
			name: title,
			type: "image",
			author: currentCharacter?.id,
			url: imgurURL,
		});

		const line = {
			...GenerateDefaultLine(gameId),
			content: title ? `${title}: ${imgurURL}` : imgurURL,
			author: currentCharacter?.id,
			party: partyId,
			// this field is not sent to the backend and only used locally until the db line is sent back. The server will take care of generating a "proper/clean" action for the line before saving it to the db
			action,
			// this field is sent to the backend. It has a different name to not have django serializer automatically convert the string above into the "action" field of the DiceRoll model
			action_json: action,
		};

		dispatch(sendLine(line));

		navigation.goBack();
	}, [imgurURL, currentCharacter?.id, partyId, title]);

	const pasteFromClipboard = React.useCallback(async () => {
		const content = await Clipboard.getStringAsync();
		seturl(content);
	}, []);

	let options = [
		{
			title: ws(_("Browse computer", "upload image"), _("Upload from phone", "upload image")),
			size: ws("fullWidth"),
			onPress: uploadFromPhone,
		},
	];
	if (imageuri) {
		options = [
			{
				title: _("Back"),
				onPress: () => {
					seterror(null);
					setimageuri(null);
					setimgurImageData(null);
					resetProgress();
				},
			},
			{
				title: _("Send", "send image to chat"),
				disabled: uploadingImage || !imgurURL,
				onPress: route.params?.nodeId ? saveImageToNode : sendImage,
			},
		];
	}

	return (
		<ModalScreen style={styles.container} title={_("Upload an image")} useButtonFooter options={options}>
			{!imageuri && <UnlockedByShareInfo style={{ marginBottom: 8 }} />}
			<AppText hide={!error} color="danger">
				{_("Unknown image format", "error uploading image")}
			</AppText>

			<CondView show={!imageuri} style={{ flexDirection: "row", alignItems: "center" }}>
				<InputField label="url" value={url} onChangeText={seturl} style={{ flex: 1 }} />
				{!isWeb() && (
					<IconButton
						icon={{ type: MaterialIcons, name: "content-paste" }}
						transparent
						onPress={pasteFromClipboard}
					/>
				)}
			</CondView>
			{imageuri && !error && (
				<View>
					<Cond show={!route.params?.noTitle}>
						<InputField
							label={_("Title (optional)", "upload image title")}
							value={title}
							onChangeText={settitle}
						/>
					</Cond>
					<View style={{ alignItems: "center" }}>
						<Image
							source={{ uri: imageuri }}
							style={{ width: 200, height: 200, marginVertical: 16 }}
							onError={({ nativeEvent: { error: errorReceived } }) => {
								if (!error) {
									seterror(errorReceived);
								}
								setimageuri(null);
							}}
							onLoad={() => {
								if (url) uploadFromURL(url);
							}}
							resizeMode="contain"
						/>
					</View>
				</View>
			)}

			<CondView show={uploadingImage} style={styles.loadingOverlay}>
				<AppText>
					{finishing
						? _("Processing...", "last stage of uploading an image")
						: _("Uploading...", "uploading an image")}
				</AppText>
				<AppText>{progress}%</AppText>
				<ActivityIndicator color={global.colors.hint} />
			</CondView>
		</ModalScreen>
	);
	BuildStyleMethod;
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		gameId,
		partyId: state.parties[gameId]?.current?.id,
		currentCharacter: getCurrentCharacter(state),
		user: state.user,
	};
};

export default connect(mapStateToProps)(UploadImageModal);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		loadingOverlay: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: colors.overlayBackground,
		},
	})
);
