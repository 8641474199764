import * as React from "react";
import { View } from "react-native";
import { _ } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStylesMethod } from "../../../styles/global";
import { ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";

function GameSettingsPace({ game, style, onChangePace, title, centered = true }) {
	const [activePace, setactivePace] = React.useState(game.expected_pace);
	const data = [
		{ value: 0, title: _("Slow"), info: _("A message once a day or less", "pace description") },
		{ value: 1, title: _("Average"), info: _("Chat a few times throughout the day", "pace description") },
		{ value: 2, title: _("Fast"), info: _("Frequent 1+ hours of continuous chatting", "pace description") },
	];

	const changePace = React.useCallback(
		(newPace) => {
			setactivePace(newPace);
			onChangePace && onChangePace(newPace);
			if (game.id) {
				apiFetch(`games/${game.id}`, "PATCH", { expected_pace: newPace });
			}
		},
		[game.id]
	);

	return (
		<View
			style={[
				globalStylesMethod(global.theme).floatingSection,
				centered && ws({ alignItems: "center" }),
				!centered && ws({ paddingLeft: fullScreenLeftPadding() }),
				style,
			]}
		>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<AppText color="primary">{title || _("Expected pace", "game pace setting")}</AppText>

				{data.map((item) => (
					<View
						key={item.title}
						style={{ flexDirection: "row", alignItems: "center", flex: 1, marginBottom: 16 }}
					>
						<AppButton
							style={{ flex: 1 }}
							title={item.title}
							isToggle
							toggled={activePace === item.value}
							style={{ marginRight: 8 }}
							onPress={() => changePace(item.value)}
						/>
						<AppText style={{ flex: 1 }}>{item.info}</AppText>
					</View>
				))}
			</View>
		</View>
	);
}

export default GameSettingsPace;
