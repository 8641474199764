import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppScreenView from "../../AppScreenView";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import { ws } from "../../../tools/generic";
import { fullScreenContentWidth } from "../../../styles/dynamicStyles";
function WizardAvatarRequestSummary({ route, navigation }) {
	const { requestInfo } = route.params;

	return (
		<>
			<AppScreenView scroll borderTop style={ws({ alignItems: "center" })}>
				<AppText bold>{_("Summary", "avatar request summary")}</AppText>
				<AppText style={{ marginTop: 8 }} color="hint">
					{_(
						"Make sure your are satisfied with your description. Once the request is sent, you will not be able to edit it."
					)}
				</AppText>

				<AppText style={{ marginTop: 16 }} centered color="hint">
					{_("Description and main features", "avatar request summary")}
				</AppText>

				<AppText style={[{ marginTop: 16 }, ws({maxWidth: fullScreenContentWidth()})]} color="secondary">
					{requestInfo.description}
				</AppText>
				<AppText hide={requestInfo.description} centered color="secondary" italic style={{ marginTop: 16 }}>
					{_("No description", "no avatar description for request")}
				</AppText>
				<AppText style={{ marginTop: 16 }} centered color="hint">
					{_("Image references", "avatar request summary")}
				</AppText>
				<AppText style={[{ marginTop: 16 }, ws({maxWidth: fullScreenContentWidth()})]} color="secondary">
					{requestInfo.references}
				</AppText>
				<AppText hide={requestInfo.references} centered color="secondary" italic style={{ marginTop: 16 }}>
					{_("No references", "no avatar visual references for request")}
				</AppText>
			</AppScreenView>
			<ButtonFooter
				buttonProps={[
					{
						title: _("Back"),
						onPress: () => navigation.navigate("WizardAvatarRequestDescription", { requestInfo }),
					},
					{
						title: _("Request", "send avatar request"),
						onPress: () => navigation.navigate("WizardAvatarRequestConfirmSendModal", { requestInfo }),
					},
				]}
			/>
		</>
	);
}

export default WizardAvatarRequestSummary;
