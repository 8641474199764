import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { _ } from "../../i18n/i18n";
import IconButton from "../generic/buttons/IconButton";
import Popover from "../generic/popover/Popover";
import ToneSelection from "./ToneSelection";

function ChangeToneButton({ selectTone, setToneColor, dark }) {
	const popoverRef = React.useRef();

	const internalSelectTone = React.useCallback(
		function (...args) {
			popoverRef.current?.hide();
			selectTone(...args);
		},
		[selectTone]
	);
	return (
		<Popover
			title={_("Select tone")}
			ref={popoverRef}
			arrowPosition="bottom"
			popoverComponent={<ToneSelection show selectTone={internalSelectTone} setToneColor={setToneColor} />}
		>
			<IconButton
				icon={{
					type: MaterialCommunityIcons,
					name: "pulse",
					color: dark ? global.colors.textDark : global.colors.textLight,
				}}
				transparent
				style={{ height: 24 }}
				onPress={() => popoverRef.current?.toggle()}
			/>
		</Popover>
	);
}

export default ChangeToneButton;


