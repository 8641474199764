import AsyncStorage from '@react-native-async-storage/async-storage';
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { isWeb } from "../../tools/generic";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";

function AccountFlaggedWarning({ dispatch, user }) {
	if (!user) return null;

	const [shouldShow, setshouldShow] = React.useState(false);

	React.useEffect(() => {
		const checkStorage = async () => {
			let aknowledged = await AsyncStorage.getItem("acknowledgedFlagged");
			aknowledged = JSON.parse(aknowledged);
			if (!aknowledged && user.profile.flagged) {
				setshouldShow(true);
			}
		};

		checkStorage();
	}, [user]);

	if (!shouldShow) return null;

	return (
		<View
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				padding: 24,
				paddingTop: 64,
				backgroundColor: "yellow",
			}}
		>
			<AppText color="textDark" centered={isWeb()}>
				{_(
					"Your account has been flagged. Please check your email address %(email_address)s for more details.",
					"",
					{
						email_address: (
							<AppText key="email" bold>
								{user.email}
							</AppText>
						),
					}
				)}
			</AppText>
			<AppButton
				style={{ marginTop: 32 }}
				title={_("Acknowledge", "users validate that they saw the warning about their account being flagged")}
				onPress={() => {
					setshouldShow(false);
					AsyncStorage.setItem("acknowledgedFlagged", "true");
				}}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(AccountFlaggedWarning);


