import * as React from "react";
import AppText from "../components/generic/AppText";
import DieFaceSymbol from "../components/lines/DieFaceSymbol";
import { _, _n } from "../i18n/i18n";
import { sendLine } from "../store/slices/linesSlice";
import store from "../store/store";
import { getCharacterSheetValuePairs, idToCharacter } from "./characters";
import { idToDieTemplate } from "./dice";
import { hasValue, idEqual } from "./generic";
import { GenerateDefaultLine } from "./lines";
import { compareSuccess } from "./math";

export const getPools = (command, targets, actor) => {
	const pools = [];
	const reg = RegExp(/\[\[[^\]]+]]/, "g");
	let poolMatch;
	while ((poolMatch = reg.exec(command)) !== null) {
		const index = poolMatch.index;
		const poolTargets = targets.filter((t) => t.label.poolIndex === index + 2);
		pools.push(...convertSingleCommandToPools(poolMatch[0], poolTargets, actor));
	}
	return pools;
};

export const convertSingleCommandToPools = (command, targets, actor, parentPool) => {
	const pool = { command_match: command, parent_pool: parentPool };

	command = command.replace("[[", "");
	command = command.replace("]]", "");
	command = command.trim();

	// Look for inner pools
	const innerPools = getComparisonInnerPools(pool, command, targets, actor);
	if (innerPools) {
		return [pool, ...innerPools];
	}

	// Find all modifiers (before handling sheet values so that they are treated separately from modifiers)
	const modifiers = [];
	const modifierPattern = RegExp(/(^|[+-])(\d+)(?!\w)/gm);
	let match;
	while ((match = modifierPattern.exec(command)) !== null) {
		modifiers.push(Number(match[0]));
	}

	// Replace references to character sheet values with their actual numbers
	let sheetValuePairsFound = [];
	command = replaceSheetLabelsWithValues(command, targets, (value) => (sheetValuePairsFound = value), actor);

	// if there are still some unused sheet values
	const found = command.match(/(^|[+-])([^+\d-]+)/);
	if (found) {
		return [
			{
				...pool,
				error: _("no character sheet value with label '%(label)s'", "roll error", { label: found[2] }),
			},
		];
	}

	// Get each roll results
	const rolls = extractRollInstances(command);
	const error = rolls.find((r) => typeof r === "string");
	if (error) return [{ ...pool, error }];

	let rollResults = rolls.reduce((all, roll) => {
		let resultsToAdd = roll.final_result;
		if (roll.is_subtract) {
			resultsToAdd = resultsToAdd.map((r) => (typeof r === "number" ? r * -1 : r));
		}
		return [...all, ...resultsToAdd];
	}, []);

	const sheetValuesSum = sheetValuePairsFound.reduce(
		(total, sv) => total + (sv.is_subtract ? sv.value * -1 : sv.value),
		0
	);
	const modifiersSum = modifiers.reduce((s, m) => s + m, 0);

	let pool_result = sheetValuePairsFound.length || modifiers.length ? [sheetValuesSum + modifiersSum] : [];
	pool_result = mergeNumberResults([...pool_result, ...rollResults]);
	pool_result = applyCancels(pool_result);

	return [
		{
			...pool,
			rolls,
			modifier: modifiersSum,
			modifiers,
			sheet_values: sheetValuePairsFound,
			final_result: pool_result,
		},
	];
};

const getComparisonInnerPools = (parentPool, command, targets, actor) => {
	const poolComparisonPattern = RegExp(/(^(?:[^><=]|ro?[<>=])+)([><=]{1,2})((?:[^><=]|ro?[<>=])+$)/, "gm");
	let comparisonMatch = poolComparisonPattern.exec(command);

	// 1dX>XX at the start of the string
	const simpleRollComparisonPattern = RegExp(/^\d+d[^><=+-]+[><=]([^d](?!d\d))*?(?=$|[+-])/);
	const simpleRollComparisonMatch = simpleRollComparisonPattern.exec(command);

	if (!comparisonMatch || simpleRollComparisonMatch) {
		return null;
	}

	const poolAMatch = comparisonMatch[1];
	const comparator = comparisonMatch[2];
	const poolBMatch = comparisonMatch[3];

	const poolATargets = targets.filter((t) => t.label.index < poolAMatch.length);
	const poolBTargets = targets
		.filter((t) => t.label.index > poolAMatch.length)
		.map((t) => {
			return { ...t, label: { ...t.label, index: t.label.index - poolAMatch.length - comparator.length } };
		});

	const poolsA = convertSingleCommandToPools(poolAMatch, poolATargets, actor, parentPool);
	const poolsB = convertSingleCommandToPools(poolBMatch, poolBTargets, actor, parentPool);

	const mainPoolA = poolsA[0];
	const mainPoolB = poolsB[0];

	const poolAResult = getResultAsNumber(mainPoolA.final_result);
	const poolBResult = getResultAsNumber(mainPoolB.final_result);

	if (poolAResult === null || poolAResult === undefined) {
		mainPoolA.error = mainPoolA.error || _("compared result must be a single number", "roll error");
	}
	if (poolBResult === null || poolBResult === undefined) {
		mainPoolB.error = mainPoolB.error || _("compared result must be a single number", "roll error");
	}

	if (!mainPoolA.error && !mainPoolB.error) {
		parentPool.final_result = [compareSuccess(poolAResult, comparator, poolBResult) ? "success" : "failure"];
	}

	return [...poolsA, ...poolsB];
};

// target = {label, character}
export const replaceSheetLabelsWithValues = (command, targets, setPairsFound, actor) => {
	const sheetValuePattern = RegExp(/(^|[+<>=-]+|\dd)(\D[^+<>=-]*)/gm);
	let match;
	const pairsFound = [];
	const stringsToReplace = [];

	while ((match = sheetValuePattern.exec(command)) !== null) {
		const operation = match[1];
		let label = match[2];
		let labelIndex = match.index + operation.length;

		const target = targets.find((t) => t.label.index === labelIndex);
		let character = target ? target.character : actor || store.getState().characters.current;
		if (typeof character === "number") {
			character = idToCharacter(character);
		}
		const sheetValuePairs = getCharacterSheetValuePairs(character);

		if (!sheetValuePairs) {
			return command;
		}

		let pair = null;
		let wasCut = false;
		for (let i = 0; !pair && i < label.length; ++i) {
			pair = sheetValuePairs.find((vp) => vp.label === label.substr(0, label.length - i));
			if (i > 0) {
				wasCut = true;
			}
		}

		if (pair) {
			// Only add to found if it's a + or - operation, because all pair found will be added as modifiers
			// Was cut is checked to not consider ACd20 as a sheet value modifier
			if (!wasCut && (operation === "+" || operation === "-" || !operation)) {
				pairsFound.push({
					label: pair.label,
					target_id: target ? target.label.targetId : 0,
					sheet: character?.sheet,
					value: Number(pair.value),
					is_subtract: operation === "-",
				});
			}
			// The label should however still be replaced
			stringsToReplace.push({
				newString: Number(pair.value),
				length: pair.label.length,
				indexInCommand: labelIndex,
			});
		}
	}

	// We replace from the end of the string to not mess up the indexes when the string length changes
	for (let i = stringsToReplace.length - 1; i >= 0; --i) {
		let info = stringsToReplace[i];
		command =
			command.substr(0, info.indexInCommand) + info.newString + command.substr(info.indexInCommand + info.length);
	}

	if (setPairsFound) setPairsFound(pairsFound);

	return command;
};

const extractRollInstances = (command) => {
	const rolls = [];
	const rollPattern = RegExp(/-?\d+d[\w<>!=]+/gm);
	let match;
	while ((match = rollPattern.exec(command)) !== null) {
		const rollInfo = getSingleRollInfo(match[0]);
		if (typeof rollInfo === "string") {
			rolls.push(rollInfo);
			continue;
		}
		// Get db-ready instance of DiceRoll
		const rollInstance = rollInfoToInstance(rollInfo);
		rolls.push(rollInstance);
	}
	return rolls;
};

const getSingleRollInfo = (rollCode) => {
	rollCode = rollCode.trim();
	let noMinusCode = rollCode.replace("-", "");

	const diceAmountMatch = /^\d+/;
	const dieTemplateMatch = /^\d+d\w+(?=$|[^\d])/;
	let amount = noMinusCode.match(diceAmountMatch);
	let dieTemplateName = noMinusCode.match(dieTemplateMatch);

	if (!amount || !dieTemplateName) {
		return _("invalid command (not a sheet value, not a modifier, not a dice roll)", "roll error");
	}

	amount = Number(amount[0]);
	// limit dice amount to 100
	if (amount > 100) {
		return _("too many dice", "roll error");
	}

	// can keep and drop simultaneously
	if (rollCode.search(/Dh?\d/) > -1 && rollCode.search(/kl?\d/) > -1) {
		return _("can't keep and drop", "roll error");
	}

	dieTemplateName = dieTemplateName[0].replace(/\d+d/, "");

	let template = null;

	const state = store.getState();
	const currentGameId = state.games.currentId;


	const gameDiceSets = state.diceSets[currentGameId];

	// Not great... what if we try to get roll info outside of a game?
	let dice = Object.values(state.dice);
	dice = dice.filter(d=> gameDiceSets.some(s=> s.id === d.dice_set));
	
	for (let i = dieTemplateName.length; !template && i > 0; --i) {
		let name = dieTemplateName.substr(0, i);

		// TODO
		// THIS WILL BUG IF THE GAME HAS MORE THAN ONE DIE WITH THE SAME NAME
		// OR IF THEY LOAD ANOTHER GAME USING A DIE WITH THAT NAME BEFORE.

		template = dice.find((d) => {
			// At this point, dieTemplateName still incorporates the special characters (for reroll, explode, etc)
			// Removes more and more character from the end until a proper name is found (or not)
			return d.name === name || d.name === "d" + name;
		});
	}

	if (!template) {
		if (!Number.isNaN(Number(dieTemplateName))) {
			return _n(
				"no dice with %(face_amount)s face",
				"no dice with %(face_amount)s faces",
				"roll error",
				Number(dieTemplateName),
				{ face_amount: dieTemplateName }
			);
		}
		return _("no dice named %(die_name)s", "roll error", { die_name: dieTemplateName });
	}

	const dieMax = template.high_face ? template.faces.find((f) => f.id === template.high_face).value : null;
	const dieMin = template.low_face ? template.faces.find((f) => f.id === template.low_face).value : null;

	noMinusCode = noMinusCode.replace(diceAmountMatch, "");
	noMinusCode = noMinusCode.replace("d" + template.name, "");
	// If the "d" is in the name of the template (ex: d20), the command will be something like be 1d20, not 1dd20.
	// Therefore we do a replace based on the name without adding a "d"
	noMinusCode = noMinusCode.replace(template.name, "");

	const exploding = noMinusCode.indexOf("!") > -1;
	if (exploding && !dieMax) {
		return _("cannot explode %(die_name)s", "roll error", { die_name: template.name });
	}
	if (exploding && template.faces.length <= 2) {
		return _("can't explode die with less than 3 faces", "roll error");
	}
	noMinusCode = noMinusCode.replace("!", "");

	let specialMatch = /D\d+/;
	let drop_lowest_amount = noMinusCode.match(specialMatch);
	if (drop_lowest_amount) drop_lowest_amount = Number(drop_lowest_amount[0].replace("D", ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	if (drop_lowest_amount && !dieMin) {
		return _("cannot drop lowest %(die_name)s", "roll error", { die_name: template.name });
	}

	specialMatch = /k\d+/i;
	let keep_highest_amount = noMinusCode.match(specialMatch);
	if (keep_highest_amount) keep_highest_amount = Number(keep_highest_amount[0].replace("k", ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	if (keep_highest_amount && !dieMax) {
		return _("cannot keep highest %(die_name)s", "roll error", { die_name: template.name });
	}

	specialMatch = /Dh\d+/;
	let drop_highest_amount = noMinusCode.match(specialMatch);
	if (drop_highest_amount) drop_highest_amount = Number(drop_highest_amount[0].replace("Dh", ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	if (drop_highest_amount && !dieMax) {
		return _("cannot drop highest %(die_name)s", "roll error", { die_name: template.name });
	}

	specialMatch = /kl\d+/i;
	let keep_lowest_amount = noMinusCode.match(specialMatch);
	if (keep_lowest_amount) keep_lowest_amount = Number(keep_lowest_amount[0].replace("kl", ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	if (keep_lowest_amount && !dieMin) {
		return _("cannot keep lowest %(die_name)s", "roll error", { die_name: template.name });
	}

	specialMatch = /ro[<>]?\w+/gi;
	let re_rolls_once = noMinusCode.match(specialMatch);
	if (re_rolls_once) re_rolls_once = re_rolls_once.map((s) => s.replace("ro", ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	if (re_rolls_once && !isRerollValid(re_rolls_once, dieMax, dieMin)) {
		return _("invalid reroll once", "roll error");
	}

	specialMatch = /r[<>]?\w+/gi;
	let re_rolls = noMinusCode.match(specialMatch);
	if (re_rolls) re_rolls = re_rolls.map((s) => s.replace("r", ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	if (re_rolls && !isRerollValid(re_rolls, dieMax, dieMin)) {
		return _("invalid reroll", "roll error");
	}

	specialMatch = /[<=>]\w+/gi;
	let successConditions = noMinusCode.match(specialMatch);
	// if (successConditions) successConditions = successConditions.map(s => s.replace(/.*(?=[<>])/, ""));
	noMinusCode = noMinusCode.replace(specialMatch, "");

	// if there is anything left to the code, then we have some uncecessary extra and the command is invalid
	if (noMinusCode.trim().length) {
		return _("some unused extra characters: %(characters)s", "roll error", { characters: noMinusCode }, true);
	}

	const is_subtract = rollCode.startsWith("-");

	return {
		amount,
		template,
		is_subtract,
		exploding,
		drop_lowest_amount,
		keep_highest_amount,
		re_rolls,
		re_rolls_once,
		drop_highest_amount,
		keep_lowest_amount,
		successConditions,
	};
};

export const isRerollValid = (rerollArray, dieMax, dieMin) => {
	for (let i = 0; i < rerollArray.length; ++i) {
		let code = rerollArray[i];
		const value = code.match(/[<>]?(\d+)/);
		if (!value || !value[1]) {
			return false;
		}
		let num = Number(value[1]);

		if (dieMax && code.startsWith("<") && num > dieMax) {
			return false;
		}
		if (dieMin && code.startsWith(">") && num < dieMin) {
			return false;
		}
	}
	return true;
};

const getResultAsNumber = (resultArray) => {
	if (!resultArray) {
		return null;
	}
	if (resultArray.some((r) => typeof r !== "number")) {
		return null;
	}
	return resultArray.reduce((sum, result) => sum + result, 0);
};

// Returns a simulation of DiceRoll model object
const rollInfoToInstance = ({
	amount,
	template,
	is_subtract,
	exploding,
	drop_lowest_amount,
	keep_highest_amount,
	re_rolls,
	re_rolls_once,
	drop_highest_amount,
	keep_lowest_amount,
	successConditions,
}) => {
	const { dice_results, info } = rollDice(
		template,
		amount,
		exploding,
		re_rolls,
		re_rolls_once,
		drop_lowest_amount,
		keep_highest_amount,
		drop_highest_amount,
		keep_lowest_amount
	);

	const specials = {
		drop_lowest_amount,
		keep_highest_amount,
		drop_highest_amount,
		keep_lowest_amount,
		re_rolls,
		re_rolls_once,
	};

	if (exploding) specials.explosions = info.explosions;

	drop_lowest_amount = drop_lowest_amount || [];
	drop_highest_amount = drop_highest_amount || [];
	keep_highest_amount = keep_highest_amount || [];
	keep_lowest_amount = keep_lowest_amount || [];
	let successes = null;

	if (successConditions && successConditions.length) {
		successes = 0;
		for (let i = 0; i < dice_results.length; ++i) {
			let result = dice_results[i];
			if (isSuccess(result, successConditions)) {
				successes++;
			}
		}
	}

	let final_result = dice_results.slice();

	// Drops
	for (let i = 0; i < drop_lowest_amount; ++i) {
		final_result = dropLowest(final_result);
	}
	for (let i = 0; i < drop_highest_amount; ++i) {
		final_result = dropHighest(final_result);
	}

	// Keeps
	let amountToDrop = final_result.length - keep_highest_amount;
	for (let i = 0; keep_highest_amount > 0 && i < amountToDrop; ++i) {
		final_result = dropLowest(final_result);
	}
	amountToDrop = final_result.length - keep_lowest_amount;
	for (let i = 0; keep_lowest_amount > 0 && i < amountToDrop; ++i) {
		final_result = dropHighest(final_result);
	}

	final_result = mergeNumberResults(final_result);

	return { amount, template: template.id, results: dice_results, is_subtract, ...specials, successes, final_result };
};

// Returns object with:
// dice_result: array of Number, "EMPTY" and/or DieSymbol object
// info: object (currently only giving info on how many time the dice exploded)
export const rollDice = (dieTemplate, amount, exploding, re_rolls, re_rolls_once) => {
	re_rolls = re_rolls || [];
	re_rolls_once = re_rolls_once || [];

	let dice_results = [];

	let explosions = 0;

	re_rolls_once = re_rolls_once ? re_rolls_once.slice() : null; // Copy before splicing

	for (let roll = 0; roll < amount + explosions; ++roll) {
		// Pick a random face
		const resultIndex = Math.floor(Math.random() * dieTemplate.faces.length);
		const face = dieTemplate.faces[resultIndex];

		let dieResult = [];

		if (hasValue(face.value)) {
			dieResult.push(Number(face.value));
		} else {
			dieResult = getFaceSymbols(face);
		}

		// Re-rolls
		let index = findInRerollConditions(dieResult, re_rolls_once);
		if (index > -1) {
			re_rolls_once.splice(index, 1);
		} else {
			index = findInRerollConditions(dieResult, re_rolls);
		}

		if (index > -1) {
			// Don't add this rolled result to the list and do it again
			roll--;
			continue;
		}

		// Explosion
		if (exploding && idEqual(face, { id: dieTemplate.high_face })) {
			explosions++;
		}

		// Adding results
		dice_results = [...dice_results, ...dieResult];
	}

	return { dice_results, info: { explosions } };
};

export const mergeNumberResults = (results) => {
	const finalResults = [];

	for (let r = 0; r < results.length; r++) {
		const result = results[r];
		if (typeof result !== "number") {
			finalResults.push(result);
			continue;
		}
		// if there already is a number in the results, increase it
		const numberIndex = finalResults.findIndex((v) => typeof v === "number");

		if (numberIndex > -1) {
			finalResults[numberIndex] += Number(result);
		} else {
			finalResults.push(result);
		}
	}

	return finalResults;
};

// dieResults is an array of results (Numbers, Symbol objects or "EMPTY")
const findInRerollConditions = (dieResults, re_rolls) => {
	for (let i = 0; i < re_rolls.length; ++i) {
		let rr = re_rolls[i];
		const valueToReroll = rr.replace(/[<=>]/, "");

		if (!rr.match(/[<>]/) && resultsHaveValue(dieResults, valueToReroll)) {
			return i;
		}
		// Only do comparison if die has an order
		const valueTested = dieResults[0];

		// Do not compare numbers and strings
		if (Number.isNaN(Number(valueToReroll)) && !Number.isNaN(Number(valueTested))) {
			continue;
		}

		if (
			(rr.startsWith("<") && Number(valueTested) < Number(valueToReroll)) ||
			(rr.startsWith(">") && Number(valueTested) > Number(valueToReroll)) ||
			(!rr.match(/[<>]/) && String(valueTested) === String(valueToReroll))
		) {
			return i;
		}
	}

	return -1;
};

export const resultsHaveValue = (resultArray, value) => {
	for (let i = 0; i < resultArray.length; ++i) {
		let res = resultArray[i];
		if (res === value) {
			return true;
		}
		if (res.name && res.name === value) {
			return true;
		}
	}

	return false;
};

export const dropHighest = (faceValues) => {
	if (faceValues.length <= 1) return faceValues;

	faceValues = faceValues.slice();

	let highest = null;
	let dropIndex = -1;

	for (let i = 0; i < faceValues.length; ++i) {
		let value = Number(faceValues[i]);
		if (Number.isNaN(value)) {
			continue;
		}

		if (!highest || Number.isNaN(highest) || value > highest) {
			highest = value;
			dropIndex = i;
		}
	}
	if (dropIndex > -1) {
		faceValues.splice(dropIndex, 1);
	}
	return faceValues;
};

export const dropLowest = (faceValues) => {
	if (faceValues.length <= 1) return faceValues;

	faceValues = faceValues.slice();

	let lowest = null;
	let dropIndex = -1;

	for (let i = 0; i < faceValues.length; ++i) {
		let value = Number(faceValues[i]);
		if (Number.isNaN(value)) {
			continue;
		}

		if (!lowest || Number.isNaN(lowest) || value < lowest) {
			lowest = value;
			dropIndex = i;
		}
	}
	if (dropIndex > -1) {
		faceValues.splice(dropIndex, 1);
	}
	return faceValues;
};

const getFaceSymbols = (face) => {
	const symbols = [];
	for (let i = 0; i < face.symbol_links.length; ++i) {
		let link = face.symbol_links[i];
		for (let c = 0; c < link.count; ++c) {
			symbols.push(link.symbol);
		}
	}

	// Empty face
	if (!face.symbol_links.length) {
		symbols.push("EMPTY");
	}

	return symbols;
};

export const applyCancels = (results) => {
	const interactions = store.getState().symbolInteractions;
	const finalResults = results.slice();

	// Eventually cancel them out
	for (let r = 0; r < finalResults.length; r++) {
		const result = finalResults[r];

		if (typeof result === "number") continue;

		const myInteractions = interactions.filter(
			(int) => idEqual(int.symbol_a, result) || idEqual(int.symbol_b, result)
		);
		const othersToLookFor = myInteractions.map((int) =>
			idEqual(int.symbol_a, result) ? int.symbol_b : int.symbol_a
		);

		const otherIndex = finalResults.findIndex((other) => othersToLookFor.rg_hasElementWithSameId(other));
		if (otherIndex > -1) {
			finalResults.splice(otherIndex, 1); // must be first as it is normally a higher index;
			finalResults.splice(r, 1);
			r--;
		}
	}

	return finalResults;
};

export function isChildOf(pool, parentPool) {
	if (!pool.parent_pool || !parentPool) return false;
	if (parentPool.id && pool.parent_pool === parentPool.id) return true;
	if (pool.parent_pool.command_match && pool.parent_pool.command_match === parentPool.command_match) return true;
	return false;
}

export const getCancelledResults = (rolls, finalResults) => {
	if (typeof finalResults === "string") {
		finalResults = JSON.parse(finalResults);
	} else {
		finalResults = finalResults.slice();
	}

	let unmatchedResults = [];

	// Concat the results of each roll into a single array
	rolls.forEach((roll) => {
		if (typeof roll.results === "string") {
			unmatchedResults = [...unmatchedResults, ...JSON.parse(roll.results)];
		} else {
			unmatchedResults = [...unmatchedResults, ...roll.results];
		}
	});

	for (let r = unmatchedResults.length - 1; r >= 0; --r) {
		let result = unmatchedResults[r];

		let matched = false;

		for (let fr = finalResults.length - 1; !matched && fr >= 0; --fr) {
			let finalResult = finalResults[fr];

			if (resultsAreEqual(result, finalResult)) {
				finalResults.splice(fr, 1);
				matched = true;
			}
		}

		if (matched) {
			unmatchedResults.splice(r, 1);
		}
	}

	return unmatchedResults;
};

export const resultsAreEqual = (resultA, resultB) => {
	if (resultA === resultB) return true;
	if (resultA.name && resultB.name && resultA.name === resultB.name) {
		return true;
	}
	if (!Number.isNaN(Number(resultA)) && !Number.isNaN(Number(resultB)) && Number(resultA) === Number(resultB)) {
		return true;
	}
	return false;
};

export const isMaxOnRoll = (value, roll) => {
	const template = idToDieTemplate(roll.template);
	if (!template) return false;
	value = Number(value);
	if (Number.isNaN(value)) return false;
	if (!template.high_face) return false;
	const face = template.faces.find((f) => f.id === template.high_face);
	if (!face) return false;
	return value >= Number(face.value);
};

export const isMinOnRoll = (value, roll) => {
	const template = idToDieTemplate(roll.template);
	if (!template) return false;
	value = Number(value);
	if (Number.isNaN(value)) return false;
	if (!template.low_face) return false;
	const face = template.faces.find((f) => f.id === template.low_face);
	if (!face) return false;
	return value <= Number(face.value);
};

export const rollHasMax = (roll) => {
	for (let i = 0; i < roll.results.length; ++i) {
		if (isMaxOnRoll(roll.results[i], roll)) {
			return true;
		}
	}
	return false;
};

export const rollHasMin = (roll) => {
	for (let i = 0; i < roll.results.length; ++i) {
		if (isMinOnRoll(roll.results[i], roll)) {
			return true;
		}
	}
	return false;
};

export const specialsToText = (roll) => {
	if (!roll) return [];
	const strings = [];
	if (roll.drop_lowest_amount)
		strings.push(
			_n("drop lowest", "drop %(count)s lowest", "dice roll rule", roll.drop_lowest_amount, {
				count: roll.drop_lowest_amount,
			})
		);
	if (roll.keep_highest_amount)
		strings.push(
			_n("keep highest", "keep %(count)s highest", "dice roll rule", roll.keep_highest_amount, {
				count: roll.keep_highest_amount,
			})
		);
	if (roll.drop_highest_amount)
		strings.push(
			_n("drop highest", "drop %(count)s highest", "dice roll rule", roll.drop_highest_amount, {
				count: roll.drop_highest_amount,
			})
		);
	if (roll.keep_lowest_amount)
		strings.push(
			_n("keep lowest", "keep %(count)s lowest", "dice roll rule", roll.keep_lowest_amount, {
				count: roll.keep_lowest_amount,
			})
		);
	if (roll.re_rolls) {
		for (let i = 0; i < roll.re_rolls.length; ++i) {
			let rr = roll.re_rolls[i];
			const valueToReroll = Number(rr.replace(/[<>]/, ""));
			if (rr.startsWith(">")) {
				strings.push(_("reroll above %(value)s", "dice roll rule", { value: valueToReroll }));
			} else if (rr.startsWith("<")) {
				strings.push(_("reroll under %(value)s", "dice roll rule", { value: valueToReroll }));
			} else {
				strings.push(_("reroll %(value)s", "dice roll rule", { value: valueToReroll }));
			}
		}
	}
	if (roll.re_rolls_once) {
		for (let i = 0; i < roll.re_rolls_once.length; ++i) {
			let rr = roll.re_rolls_once[i];
			const valueToReroll = Number(rr.replace(/[<>]/, ""));
			if (rr.startsWith(">")) {
				strings.push(_("reroll once above %(value)s", "dice roll rule", { value: valueToReroll }));
			} else if (rr.startsWith("<")) {
				strings.push(_("reroll once under %(value)s", "dice roll rule", { value: valueToReroll }));
			} else {
				strings.push(_("reroll once %(value)s", "dice roll rule", { value: valueToReroll }));
			}
		}
	}

	if (roll.explosions !== undefined && roll.explosions !== null)
		strings.push(
			_n("exploded %(number)s time", "exploded %(number)s times", "dice roll rule", roll.explosions, {
				number: roll.explosions,
			})
		);

	return strings;
};

// dieResult is a single result (Number, Symbol object or "EMPTY")
export const isSuccess = (dieResult, successConditions) => {
	for (let i = 0; i < successConditions.length; ++i) {
		let sc = successConditions[i];
		let successValue = sc.replace(/[<=>]/, "");

		if (sc.startsWith("=") && dieResult.toString() === successValue.toString()) {
			return true;
		}

		const valueTested = Number(dieResult);
		successValue = Number(successValue);

		// Do not compare numbers and strings
		if (Number.isNaN(successValue) && !Number.isNaN(valueTested)) {
			continue;
		}

		if (!Number.isNaN(valueTested)) {
			if (
				(sc.startsWith("<") && valueTested < successValue) ||
				(sc.startsWith(">") && valueTested > successValue)
			) {
				return true;
			}
		}
	}

	return false;
};

export const idsToSelectedDie = (ids, selectedDice) => selectedDice.filter((d) => ids.includes(d.id));
export const getRuleGroupIndex = (id, groups) => (groups ? groups.findIndex((g) => g.dice.includes(id)) : -1);
export const getRuleGroup = (id, groups, selectedDice) => {
	const group = groups?.find((g) => g.dice.includes(id));
	if (group) {
		return { ...group, dice: idsToSelectedDie(group.dice, selectedDice) };
	}
};

function rollDataToPool(selectedDicePool, poolRules, modifier) {
	if (!selectedDicePool) return null;
	const allRolls = [];

	const selectedDiceWithNoGroup = selectedDicePool.filter((sd) => getRuleGroupIndex(sd.id, poolRules) < 0);

	let allPools = [];

	if (poolRules) {
		for (let i = 0; i < poolRules.length; i++) {
			const ruleGroup = { ...poolRules[i], dice: idsToSelectedDie(poolRules[i].dice, selectedDicePool) };
			const selectedDie = ruleGroup.dice[0];

			allPools.push({
				dieTemplateId: selectedDie.die.id,
				negative: selectedDie.negative,
				amount: ruleGroup.dice.length,
				...ruleGroup, // Get all the activeOptions and their values
			});
		}
	}

	// group together dice from the same template that aren't in a group.
	const noRulePools = selectedDiceWithNoGroup.reduce((acc, cur, idx, src) => {
		// Find all the others using the same template that are not in a group.
		const allLikeMe = src.filter((sd) => sd.die.id === cur.die.id && sd.negative === cur.negative);
		// If I am not the first in the pool with this template, such group has already been created. Plese just ignore me
		if (allLikeMe[0] !== cur) {
			return acc;
		}

		return acc.concat([{ dieTemplateId: cur.die.id, negative: cur.negative, amount: allLikeMe.length }]);
	}, []);

	allPools = [...allPools, ...noRulePools];
	// put negative pools at the end
	allPools.sort((a, b) => {
		if (a.negative === b.negative) return 0;
		if (!a.negative && b.negative) return -1;
		if (a.negative && !b.negative) return 1;
	});

	// Roll all the pools and add their results
	let finalResult = allPools.reduce((acc, cur, idx, src) => {
		const rerolls = [];
		cur.reroll && rerolls.push(cur.reroll_value);
		cur.rerollAbove && rerolls.push(">" + cur.rerollAbove_value);
		cur.rerollBelow && rerolls.push("<" + cur.rerollBelow_value);
		const rerollsOnce = [];
		cur.rerollOnce && rerollsOnce.push(cur.rerollOnce_value);
		cur.rerollAboveOnce && rerollsOnce.push(">" + cur.rerollAboveOnce_value);
		cur.rerollBelowOnce && rerollsOnce.push("<" + cur.rerollBelowOnce_value);

		const successConditions = [];
		cur.successAbove && successConditions.push(">" + cur.successAbove_value);
		cur.successBelow && successConditions.push("<" + cur.successBelow_value);

		const roll = rollInfoToInstance({
			amount: cur.amount,
			template: idToDieTemplate(cur.dieTemplateId),
			is_subtract: cur.negative,
			exploding: cur.explode,
			drop_lowest_amount: cur.dropLowest ? cur.dropLowest_value : undefined,
			keep_highest_amount: cur.keepHighest ? cur.keepHighest_value : undefined,
			re_rolls: rerolls,
			re_rolls_once: rerollsOnce,
			drop_highest_amount: cur.dropHighest ? cur.dropHighest_value : undefined,
			keep_lowest_amount: cur.keepLowest ? cur.keepLowest_value : undefined,
			successConditions,
		});

		allRolls.push(roll);

		const resultsWithNegative = roll.is_subtract
			? roll.final_result.map((r) => {
					if (Number.isNaN(Number(r))) return r;
					return r * -1;
			  })
			: roll.final_result;

		return [...acc, ...resultsWithNegative];
	}, []);

	if (modifier) finalResult.push(modifier);
	finalResult = mergeNumberResults(finalResult);
	finalResult = applyCancels(finalResult);

	return {
		rolls: allRolls,
		modifier,
		parent_pool: null, // FYI: same parameter types as this pool here.
		command_match: null, // addding this here just so you know the backend will look for that info
		final_result: finalResult,
	};
}

export async function rollPool(
	gameId,
	partyId,
	author,
	toGMOnly = false,
	gmInfo,
	rollName,
	selectedDicePool,
	poolRules,
	modifier,
	comparedPool,
	comparedPoolRules,
	comparedPoolModifier,
	compareType,
	dispatch
) {
	comparedPool = rollDataToPool(comparedPool, comparedPoolRules, comparedPoolModifier);
	const pool = rollDataToPool(selectedDicePool, poolRules, modifier);

	let lineContent = null;

	const dice_pools = [pool];
	if (comparedPool) {
		lineContent = "[[POOL_A" + compareType + "POOL_B]]";
		const parentPool = {
			command_match: lineContent,
			final_result: [
				compareSuccess(
					getResultAsNumber(pool.final_result),
					compareType,
					getResultAsNumber(comparedPool.final_result)
				)
					? "success"
					: "failure",
			],
		};

		dice_pools[0].parent_pool = { command_match: parentPool.command_match };
		dice_pools[0].command_match = "POOL_A";
		comparedPool.parent_pool = { command_match: parentPool.command_match };
		comparedPool.command_match = "POOL_B";

		dice_pools.push(comparedPool);
		dice_pools.splice(0, 0, parentPool);
	}

	const action = JSON.stringify({
		name: rollName,
		type: "dice_action",
		author: author?.id,
		dice_pools,
	});

	const whisperList = toGMOnly ? [gmInfo.id] : []; // Uses the checkbox boolean to determine if the roll should be whispered to the GM

	const line = {
		...GenerateDefaultLine(gameId),
		content: lineContent,
		author: author?.id,
		party: partyId,
		// this field is not sent to the backend and only used locally until the db line is sent back. The server will take care of generating a "proper/clean" action for the line before saving it to the db
		action,
		// this field is sent to the backend. It has a different name to not have django serializer automatically convert the string above into the "action" field of the DiceRoll model
		action_json: action,
		whispered_to: whisperList,
	};

	await dispatch(sendLine(line));
}

export const isValidCommand = (command, targets, actor) => {
	const pools = getPools(command, targets, actor);

	if (!pools.length) return [false, null];

	for (let i = 0; i < pools.length; ++i) {
		const pool = pools[i];
		const innerPools = pools.filter((p) => isChildOf(p, pool));
		if (pool.error) return [false, pool.error];
		if (!pool.rolls && !innerPools.length) return [false, null];
	}

	return [true, null];
};

export const sendRoll = (gameId, partyId, rollCommand, rollName, targets, actor, toGMOnly = false, gmInfo, dispatch) => {
	const dice_pools = getPools(rollCommand, targets, actor);

	const action = JSON.stringify({
		name: rollName,
		type: "dice_action",
		author: actor?.id,
		dice_pools,
	});

	const whisperList = !!toGMOnly && !!gmInfo ? [gmInfo.id] : []; // Uses the checkbox boolean to determine if the roll should be whispered to the GM

	const line = {
		...GenerateDefaultLine(gameId),
		content: rollCommand,
		author: actor?.id,
		party: partyId,
		// this field is not sent to the backend and only used locally until the db line is sent back. The server will take care of generating a "proper/clean" action for the line before saving it to the db
		action,
		// this field is sent to the backend. It has a different name to not have django serializer automatically convert the string above into the "action" field of the DiceRoll model
		action_json: action,
		whispered_to: whisperList,
	};

	return dispatch(sendLine(line));
};

export const findSheetLabelInputs = (command) => {
	const reg = RegExp(/\[\[[^\]]+(?=]])/, "g");
	let commandMatch;
	const labels = [];
	// iterate through all caught instances
	while ((commandMatch = reg.exec(command)) !== null) {
		const currentCommand = commandMatch[0].replace("[[", "").trim();
		const poolIndex = commandMatch.index + 2; // +2 for additional [[;

		const sheetValueReg = RegExp(/(^|[+<>=-]+)(\D[^+<>=-]*)/, "gm");
		let labelMatch;
		while ((labelMatch = sheetValueReg.exec(currentCommand)) !== null) {
			const operation = labelMatch[1];
			const sheetLabel = labelMatch[2];
			labels.push({
				value: sheetLabel,
				poolIndex,
				index: labelMatch.index + operation.length,
				targetId: 0,
			});
		}
	}

	return labels;
};

export const commandHasSheetValues = (command) => {
	return !!findSheetLabelInputs(command).length;
};
