import * as React from "react";
import { View, StyleSheet } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStylesMethod } from "../../../styles/global";
import { idKeyExtractor, ws } from "../../../tools/generic";
import { navPush } from "../../../tools/webnavigation";
import AppFlatList from "../../generic/AppFlatList";
import AppText from "../../generic/AppText";
import UserItem from "../../users/UserItem";
import { _ } from "../../../i18n/i18n";
import HelpButton from "../../tutorials/HelpButton";
import { fetchGameUsers } from "../../../store/slices/usersSlice";

function GameAssitants({ game, style, dispatch, users }) {
	style = style || globalStylesMethod(global.theme).floatingSection;

    
	React.useEffect(() => {
		if (game.assistants.some((pid) => !users[pid])) {
			dispatch(fetchGameUsers(game.id));
		}
	}, [game.id, game.assistants, users]);

	const { onLayout } = useLayout();

	const message = (
		<View>
			<AppText>{_("Assitant Game Masters have the same controls as the creator of the game, except:")}</AppText>
			<AppText list>{_("Changing the main Game Master")}</AppText>
			<AppText list>{_("Deleting or archiving the game")}</AppText>
            <AppText list>{_("Editing the character sheet template")}</AppText>
            <AppText style={{marginTop: 8}}>{_("They also do not own a specific character like players do.")}</AppText>
		</View>
	);

    

	return (
		<View onLayout={onLayout} style={[style, ws({ alignItems: "center"  })]}>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
					<AppText color="primary">{_("Assistant Game Masters")}</AppText>
					<HelpButton title={_("Assistant Game Masters")} message={message} />
				</View>

				<AppFlatList
					keyExtractor={idKeyExtractor}
					data={game.assistants.map((id) => users[id]).filter((p) => p)}
					renderItem={({ item, index }) => (
						<UserItem
							key={item.id}
							index={index}
							user={item}
							game={game}
							onPress={() => {
								navPush(navigation)("ProfilePage", { username: item.username });
							}}
						/>
					)}
					ListEmptyComponent={
						<AppText color="hint" italic centered style={{ marginVertical: 8 }}>
							{_("None", "no assistant game master")}
						</AppText>
					}
				/>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		users: state.users || state.savedStore.users,
	};
};

export default connect(mapStateToProps)(GameAssitants);

const styles = StyleSheet.create({
	container: {},
});
