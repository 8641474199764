import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";

export const messageGroupsSlice = createSlice({
	name: "messageGroups",
	initialState: {
		all: [],
		nextUrl: null,
		searching: false,
		searchGroups: [],
		searchNextUrl: null,
		// [group.id] : {}
	},
	reducers: {
		updateMessageGroup: (state, { payload: group }) => {
			return {
				...state,
				all: state.all.rg_replaceById(group.id, group, true),
				[group.id]: group,
			};
		},
		receiveMessageGroups: (state, { payload: { groups, next } }) => {
			return {
				...state,
				nextUrl: next,
				all: groups,
				...groups.rg_toStore(),
			};
		},
		removeMessageGroup: (state, { payload: group }) => {
			state.all = state.all.filter((g) => g.id !== group.id);
		},
		receiveMessageGroupsSearch: (state, { payload: { groups, next } }) => {
			return {
				...state,
				searchGroups: groups,
				searchNextUrl: next,
				...groups.rg_toStore(),
			};
		},
		setSearchingMessageGroups: (state, { payload: value }) => {
			state.searching = value;
		},
	},
});

export const {
	removeMessageGroup,
	receiveMessageGroups,
	receiveMessageGroupsSearch,
	updateMessageGroup,
	setSearchingMessageGroups,
} = messageGroupsSlice.actions;

export function fetchMessageGroups(userId, queryParams) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/message-groups`, "GET", queryParams).then(({ results, next }) => {
			if (queryParams && queryParams.search) {
				dispatch(receiveMessageGroupsSearch({ groups: results, next }));
			} else {
				dispatch(receiveMessageGroups({ groups: results, next }));
			}
		});
	};
}

export function fetchMessageGroup(userId, groupId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/message-groups/${groupId}`)
			.then((group) => {
				if (group) {
					return dispatch(updateMessageGroup(group));
				} else {
					return false;
				}
			})
			.catch(() => {
				return false;
			});
	};
}

export function fetchNextGroups(url, isSearch) {
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then(({ results, next }) => {
			if (isSearch) {
				const groups = getState().messageGroups.searchGroups;
				dispatch(receiveMessageGroupsSearch({ groups: groups.rg_overlapById(results), next }));
			} else {
				const groups = getState().messageGroups.all;
				dispatch(receiveMessageGroups({ groups: groups.rg_overlapById(results), next }));
			}
		});
	};
}
