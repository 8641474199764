import { StyleSheet } from "react-native";
import { ws } from "../tools/generic";
import { BuildStyleMethod } from "./theming";
import { fullScreenContentWidth } from "./dynamicStyles";

export const globalStyles = StyleSheet.create({
	// rc: row centered
	rc: {
		flexDirection: "row",
		alignItems: "center",
	},
	// rcsb: row centered space between
	rcsb: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	// sb: space between
	sb: {
		justifyContent: "space-between",
	},
	// sb: space between
	sa: {
		justifyContent: "space-around",
	},
	// jac: justify align center
	jac: {
		justifyContent: "center",
		alignItems: "center",
	},
});

export const globalStylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		fab: {
			position: "absolute",
			margin: 16,
			right: ws(64, 0),
			bottom: ws(16, 64),
			backgroundColor: colors.primary,
		},
		tagPill: {
			backgroundColor: colors.translucidBackground,
			borderRadius: 16,
			paddingVertical: 2,
			paddingHorizontal: 4,
		},
		floatingSection: {
			backgroundColor: colors.cardBackground,
			marginBottom: 8,
			shadowColor: "black",
			shadowOffset: { width: 1, height: 1 },
			shadowOpacity: 1,
			shadowRadius: 1,
			elevation: 1,
			paddingHorizontal: 8,
			paddingVertical: 4,
			...ws({
				paddingVertical: 16,
				marginBottom: 16,
			}),
		},
		centeredBlock: ws({ width: "100%", maxWidth: fullScreenContentWidth() }),
	})
);
