import { useNavigation } from "@react-navigation/core";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { addTemporaryStoryMarker } from "../../store/slices/storyMarkersSlice";
import { isAndroid, ws } from "../../tools/generic";
import AppButton from "../generic/buttons/AppButton";

function AddStoryMarkerButton({ useInverted, gameId, dispatch, currentPartyId }) {
	const navigation = useNavigation();

	return (
		<AppButton
			style={[{ width: null, paddingHorizontal: 16 }, useInverted && isAndroid() && { scaleY: -1 }]}
			transparentHighlight
			title={_("Add act/scene/chapter here")}
			info={ws(
				_(
					"To add a story marker later in the story, click the message after which you want to create it directly"
				),
				_(
					"To add a story marker later in the story, tap the message after which you want to create it directly"
				)
			)}
			onPress={() => {
				const newMarker = {
					id: "new",
					game: gameId,
					start_line_id: 0,
					party: currentPartyId,
				};
				dispatch(addTemporaryStoryMarker(newMarker));
				navigation.navigate("StoryMarkerModalStack", {
					screen: "EditStoryMarkerModal",
					params: { markerId: newMarker.id, backToScreen: "Game" },
				});
			}}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameId } = ownProps;
	return {
		currentPartyId: state.parties[gameId]?.current?.id,
	};
};

export default connect(mapStateToProps)(AddStoryMarkerButton);

const styles = StyleSheet.create({
	container: {},
});
