import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { colors } from "../../styles/colors";
import { _ } from '../../i18n/i18n';

import { BuildStyleMethod } from "../../styles/theming";

function NewContentSeparator({text}) {
	const styles = stylesMethod(global.theme);

    text = text || _("New content", "new content message");
    
    const line = (
        <View style={styles.crossLineContainer}>
            <View style={styles.crossLine} />
        </View>
    );
	return (
		<View style={[styles.container, styles.newContent]}>
			{line}
			<AppText bold size="small" style={{ marginHorizontal: 4 }} color="attentionImportant">
				{text}
			</AppText>
			{line}
		</View>
	);
}

export default NewContentSeparator;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		paddingHorizontal: 8,
		paddingVertical: 2,
		marginBottom: 4,
	},
	crossLineContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		left: 0,
		opacity: 0.5,
	},
	crossLine: {
		width: "100%",
		borderTopColor: colors.attentionImportant,
		borderTopWidth: 1,
	},
}));
