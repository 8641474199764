import { createIconSet } from "@expo/vector-icons";
import React from "react";

// https://jrgraphix.net/r/Unicode/E000-F8FF
export const tokenGlyphMap = { 
    tk_adv: '',
    tk_adv2: '',
    tk_ar: '',
    tk_bo: '',
    tk_ci: '',
    tk_d4: '',
    tk_d6: '',
    tk_d8: '',
    tk_d10: '',
    tk_d12: '',
    tk_d20: '',
    tk_d100: '',
    tk_desp: '',
    tk_desp2: '',
    tk_el: '',
    tk_ey: '',
    tk_fail: '',
    tk_fail2: '',
    tk_fi: '',
    tk_fr: '',
    tk_he: '',
    tk_hg: '',
    tk_ho: '',
    tk_in: '',
    tk_ke: '',
    tk_mg: '',
    tk_min: '',
    tk_mn: '',
    tk_mo: '',
    tk_nu: '',
    tk_plus: '',
    tk_py: '',
    tk_ra: '',
    tk_ro: '',
    tk_roc: '',
    tk_sh: '',
    tk_sk: '',
    tk_sl: '',
    tk_sq: '',
    tk_st: '',
    tk_su: '',
    tk_succ: '',
    tk_succ2: '',
    tk_sw: '',
    tk_ta: '',
    tk_tar: '',
    tk_threat: '',
    tk_threat2: '',
    tk_ti: '',
    tk_trium2: '',
    tk_triumph: '',
    tk_wa: '',
    tk_warn: '',
    tk_x: '',
};

const CustomIcon = createIconSet(
  tokenGlyphMap,
  "rg-token-font"
);

function RGTokenIcon(props){  
    return (<CustomIcon {...props} />);  
}

export default RGTokenIcon;
