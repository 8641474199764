import { _ } from "../i18n/i18n";
import { apiFetch } from "../network/apiFetch";
import { editNode, receiveSheetNodes } from "../store/slices/sheetNodesSlice";
import store from "../store/store";
import { receiveSheetTabs, updateSheetTab, addSheetTab } from "../store/slices/sheetTabsSlice";
import { receiveSheets } from "../store/slices/sheetsSlice";
import { fetchCharacterStates } from "../store/slices/characterStatesSlice";
import { fetchCharacters } from "../store/slices/charactersSlice";

export const filterDefaultLabels = (svp) =>
	svp.label.toLowerCase() !== _("Label", "Default character sheet item label").toLowerCase();

function saveOrder(node) {
	apiFetch(`sheets/${node.sheet}/nodes/${node.id}/children-order`, "PATCH", { order: node.items });
}

export const moveNodeUp = (node, parent, dispatch) => {
	const index = parent.items.indexOf(node.id);
	if (index > 0) {
		const items = parent.items.slice();

		items.splice(index, 1);
		items.splice(index - 1, 0, node.id);

		parent = { ...parent, items };
		dispatch(editNode(parent));
		saveOrder(parent);
	}
};

export const moveNodeDown = (node, parent, dispatch) => {
	const index = parent.items.indexOf(node.id);
	if (index < parent.items.length - 1) {
		const items = parent.items.slice();

		items.splice(index, 1);
		items.splice(index + 1, 0, node.id);

		parent = { ...parent, items };
		dispatch(editNode(parent));
		saveOrder(parent);
	}
};

export const resizeNode = (node, newSize, dispatch) => {
	if (newSize >= 0 && newSize <= 20) {
		if (newSize > 1) newSize = Math.round(newSize);
		node = { ...node, size: newSize };
		dispatch(editNode(node));
	}
};


export const resizeNodeHeight = (node, newSize, dispatch) => {
	if (newSize >= 0 && newSize <= 20) {
		if (newSize > 1) newSize = Math.round(newSize);
		node = { ...node, height: newSize };
		dispatch(editNode(node));
	}
};

export const addNode = async (parent, type, direction, isList, dispatch) => {
	return apiFetch(`sheets/${parent.sheet}/nodes`, "POST", {
		"parent-id": parent.id,
		type,
		direction,
		user_list: !!isList,
	}).then((newSection) => {
		if (newSection.failed) {
			return;
		}

		dispatch(receiveSheetNodes([newSection]));
		dispatch(editNode({ ...parent, items: parent.items.concat(newSection.id) }));
	});
};

export const removeNode = (parent, node, dispatch, gameId) => {
	const index = parent.items.indexOf(node.id);
	if (index > -1) {
		const items = parent.items.rg_removeAtPure(index);
		dispatch(editNode({ ...parent, items }));
	}
	const url = gameId ? `games/${gameId}/` : "";
	apiFetch(`${url}sheets/${node.sheet}/nodes/${node.id}`, "DELETE");
};

export const sheetItemHasElement = (nodeId) => {
	const state = store.getState();
	const item = state.sheetNodes[nodeId];
	if (item.type !== "section") return true;

	return item.items.some((id) => sheetItemHasElement(id));
};

export const createTab = (sheet, dispatch) => {
	return apiFetch(`sheets/${sheet.id}/tabs`, "POST").then((tab) => {
		dispatch(addSheetTab(tab));

		return apiFetch(`sheets/${sheet.id}/nodes/${tab.root_node}`)
			.then((root) => dispatch(receiveSheetNodes([root])))
			.then(() => tab);
	});
};

export const renameTab = (tab, newName, dispatch) => {
	dispatch(updateSheetTab({ ...tab, name: newName.trim() }));
	apiFetch(`sheets/${tab.sheet}/tabs/${tab.id}`, "PATCH", { name: newName });
};

export const moveTabLeft = (tabs, tab, dispatch) => {
	tabs = tabs.slice();
	const currentIndex = tabs.rg_indexWithId(tab.id);
	tabs.splice(currentIndex, 1);
	tabs.splice(currentIndex - 1, 0, tab);

	dispatch(receiveSheetTabs({ sheetId: tab.sheet, tabs }));
	apiFetch(`sheets/${tab.sheet}/tabs-order`, "PATCH", { order: tabs.map((t) => t.id) });
};

export const moveTabRight = (tabs, tab, dispatch) => {
	tabs = tabs.slice();
	const currentIndex = tabs.rg_indexWithId(tab.id);

	tabs.splice(currentIndex, 1);
	tabs.splice(currentIndex + 1, 0, tab);

	dispatch(receiveSheetTabs({ sheetId: tab.sheet, tabs }));
	apiFetch(`sheets/${tab.sheet}/tabs-order`, "PATCH", { order: tabs.map((t) => t.id) });
};

export const deleteTab = (tabs, tab, dispatch) => {
	tabs = tabs.filter((t) => t.id !== tab.id);
	dispatch(receiveSheetTabs({ sheetId: tab.sheet, tabs }));
	apiFetch(`sheets/${tab.sheet}/tabs/${tab.id}`, "DELETE");
};

export const saveSheetToProfile = (sheet, name, user, dispatch) => {
	dispatch(receiveSheets([{ ...sheet, save_name: name, saved_on_profile: user.profile }]));
	apiFetch(`profiles/${user.profile.id}/sheet-templates`, "POST", { save_name: name, sheet_id: sheet.id });
};

export const renameSheet = (sheet, newName, dispatch) => {
	dispatch(receiveSheets([{ ...sheet, save_name: newName }]));
	apiFetch(`sheets/${sheet.id}`, "PATCH", { save_name: newName });
};

export const addPlayerItem = (parent, copyParentId, dispatch) => {
	return apiFetch(`sheets/${parent.sheet}/nodes`, "POST", {
		"player-added": true,
		"parent-id": copyParentId,
		"template-id": parent.items[0],
	}).then(({ parent: newParent, new_nodes }) => {
		return dispatch(receiveSheetNodes([newParent, ...new_nodes]));
	});
};

export const renameCharacter = (sheet, newName, dispatch) => {
	dispatch(receiveSheets([{ ...sheet, character_name: newName }]));
	apiFetch(`sheets/${sheet.id}`, "PATCH", { character_name: newName });
};

export const replicateNameChanges = (character, from, to, sheet, dispatch) => {
	return apiFetch(`games/${character.game}/characters/${character.id}/states/replicate-name`, "POST", {
		from,
		to,
	})
		.then(() => dispatch(fetchCharacterStates(character.game)))
		.then(() => dispatch(fetchCharacters(character.game)))
		.then(() => dispatch(receiveSheets([{ ...sheet, character_name: to }]))); // force rerender of lines);
};
