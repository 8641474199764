import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { apiFetch } from "../../network/apiFetch";
import { getLevelFromPlanId, levelToRankName } from "../../tools/account";

export const subscriptionSlice = createSlice({
	name: "subscriptionInfo",
	initialState: {
		fetched: false,
		renewedPaid: null,
		renewedLevel: null,
		nextPayment: null,
		levelWithoutUpgrades: null,
		levelPricesInfo: [null, null, null, null, null, null],
	},
	reducers: {
		receiveSubscriptionInfo: (state, { payload: { user, subscriptionInfo } }) => {
			let upgrades = 0;

			if (user.profile.temporary_upgrade_deadline) {
				upgrades++;
			}
			upgrades += user.profile.permanent_upgrade;

			state.levelWithoutUpgrades = user.profile.reward_level - upgrades;

			if (subscriptionInfo && subscriptionInfo.state != "deleted") {
				const renewedPaid = getLevelFromPlanId(subscriptionInfo.plan_id);
				state.renewedPaid = renewedPaid;
				state.renewedLevel = renewedPaid;
				state.nextPayment = moment(subscriptionInfo.next_payment.date);
			} else {
				if (user.profile.reset_account_deadline) {
					state.nextPayment = moment(user.profile.reset_account_deadline);
				}
				state.renewedPaid = 0;
				state.renewedLevel = 0;
			}

			state.fetched = true;
		},
		receiveLevelPriceInfo: (state, { payload: { level, info } }) => {
			state.levelPricesInfo[level] = info;
		},
	},
});

export const { receiveSubscriptionInfo, receiveLevelPriceInfo } = subscriptionSlice.actions;

export function fetchSubscriptionInfo() {
	return async (dispatch, getState) => {
		const user = getState().user;
		return apiFetch(`profiles/${user.id}/subscription-info`).then((subscriptionInfo) => {
			dispatch(receiveSubscriptionInfo({ user, subscriptionInfo }));
		});
	};
}
