import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { receiveCharacters } from "../../store/slices/charactersSlice";
import ModalScreen from "../generic/modal/ModalScreen";
import { idEqual } from "../../tools/generic";
import { apiFetch } from "../../network/apiFetch";

function AssignCharacterModal({ game, characters, navigation, route, dispatch }) {
	const { character, player, backToScreen } = route.params;

	const assignPlayer = React.useCallback(
		(addToPlayable) => {
			const currentCharacter = characters.find((c) => idEqual(c.player, player));

			// Make new character mine
			const editedCharacter = {
				...character,
				original_player: player.id,
				player: currentCharacter ? currentCharacter.player : player,
				is_npc: false,
			};
			dispatch(receiveCharacters({ gameId: editedCharacter.game, characters: [editedCharacter]}));

			// Make old character NPC
			if (!addToPlayable && currentCharacter) {
				dispatch(receiveCharacters({ gameId: currentCharacter.game, characters: [{ ...currentCharacter, player: game.game_master, is_npc: true }]}));
            }

			apiFetch(`games/${game.id}/characters/${editedCharacter.id}/assign`, "POST", {
				player: player.id,
				add_to_playable: addToPlayable,
			});

			navigation.navigate(backToScreen);
		},
		[characters, game, character, player]
	);

	const options = [
		{
			title: _("Replace current character", "assign character"),
			info: _("Simple reassignment", "assign character"),
			onPress: () => assignPlayer(),
		},

		{
			title: _("Add to playable characters", "assign character"),
			info: _("Allow this player to play several characters", "assign character"),
			onPress: () => assignPlayer(true),
		},
	];

	return <ModalScreen options={options} />;
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	return {
		game,
		characters: state.charactersByGame[game.id],
	};
};

export default connect(mapStateToProps)(AssignCharacterModal);


