import * as React from "react";
import { idToDieTemplate } from "../../tools/dice";
import { specialsToText } from "../../tools/diceRoll";
import DiceRollCalculation from "../diceroll/DiceRollCalculation";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { useDispatch } from "react-redux";
import { fetchDie } from "../../store/slices/diceSlice";

function DiceRoll({ details, cancelledResults, roll, index }) {
	const dispatch = useDispatch();
	if (
		typeof roll.successes === "string" ||
		typeof roll.re_rolls === "string" ||
		typeof roll.re_rolls_once === "string"
	) {
		roll = { ...roll };
		roll.successes = JSON.parse(roll.successes);
		roll.re_rolls = JSON.parse(roll.re_rolls);
		roll.re_rolls_once = JSON.parse(roll.re_rolls_once);
	}

	React.useEffect(() => {
		if (!idToDieTemplate(roll.template)) {
			dispatch(fetchDie(roll.template));
		}
	}, []);

	const specials = specialsToText(roll);

	let specialText = "";
	specials.forEach((v, index) => {
		if (index > 0) {
			specialText += ", ";
		}
		specialText += v;
	});

	// const showCalc = (roll.successes !== null && details > 0) || details > 1 || rollHasMax(roll) || rollHasMin(roll);
	// const showSpecials = specialText && ((roll.successes === null && details > 0) || details > 1);
	const showSpecials = !!specialText;

	let amount = <AppText>{roll.amount}</AppText>;

	const template = idToDieTemplate(roll.template);
	if (!template) return null;
	let name = template?.name || _("[DELETED]", "die have been deleted");
	if (!name.match(/^d\d+/)) {
		name = (
			<AppText>
				<AppText className="die_marker" size="small" color="secondary">
					x
				</AppText>
				{name}
			</AppText>
		);
	}

	const sign = roll.is_subtract ? "-" : "+";

	const calcLine = <DiceRollCalculation roll={roll} cancelledResults={cancelledResults} />;

	return (
		<AppText>
			<AppText hide={index <= 0 && !roll.is_subtract} color="secondary">{sign} </AppText>
			<AppText color="primary" bold>
				{amount}
				{name}
			</AppText>
			<AppText size="small" color="primary" textOnlyStyle={{ alignItems: "center", justifyContent: "center" }}>
				{" "}
				({calcLine})
			</AppText>{" "}
			<AppText hide={!showSpecials} color="hint" size="small">
				({specialText})
			</AppText>
		</AppText>
	);
}

export default DiceRoll;
