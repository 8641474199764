import * as React from "react";
import { View, StyleSheet, TextInput } from "react-native";
import AppScreenView from "../AppScreenView";
import { connect } from "react-redux";
import { isGM } from "../../tools/games";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { usePrevious } from "../../tools/react";
import { setNotes } from "../../store/slices/gamesUISlice";
import { updateStoredGame } from "../../store/slices/gamesSlice";
import { colors } from "../../styles/colors";
import { ws } from "../../tools/generic";

function NoteFullScreen({ route, navigation, dispatch, game, notes, userId }) {
	const { private: isPrivate } = route.params;

	const [textValue, settextValue] = React.useState(isPrivate ? notes?.content : game.handouts);

	const canEdit = isPrivate || isGM();

	const saveTimeout = React.useRef();
	const prevValue = usePrevious(textValue);

	const textValueRef = React.useRef(textValue);
	textValueRef.current = textValue;
	const saveRef = React.useRef();

	const save = React.useCallback(() => {
		if (isPrivate) {
			dispatch(setNotes({ gameId: game.id, value: { ...notes, content: textValueRef.current } }));
			apiFetch(`users/${userId}/games/${game.id}/notes`, "POST", { content: textValueRef.current });
		} else {
			dispatch(updateStoredGame({ ...game, handouts: textValueRef.current }));
			apiFetch(`games/${game.id}`, "PATCH", { handouts: textValueRef.current });
		}
	}, [isPrivate, userId, game]);

	saveRef.current = save;

	React.useEffect(() => {
		if (!prevValue) {
			return;
		}
		saveTimeout.current = setTimeout(save, 2000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [textValue]);

	React.useEffect(() => {
		return () => {
			saveTimeout.current && saveRef.current();
		};
	}, []);

	return (
		<AppScreenView scroll style={{ paddingBottom: 16 }}>
			<AppText bold style={{ marginBottom: 8 }}>
				{isPrivate ? _("Private notes") : _("GM handouts")}
			</AppText>
			{(canEdit && (
				<TextInput
					value={textValue}
					onChangeText={settextValue}
					multiline
					autoFocus
					style={{ color: global.colors.textDefault, ...ws({flex: 1, outlineWidth: 0}) }}
				/>
			)) || (
				<AppText placeholder={!canEdit ? _("Only the GM can write here", "empty GM handouts text") : null}>
					{textValue}
				</AppText>
			)}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	return {
		game,
		userId: state.user?.id,
		notes: state.gamesUI[game.id]?.notes,
	};
};

export default connect(mapStateToProps)(NoteFullScreen);


