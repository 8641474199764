import * as React from "react";
import { ScrollView } from "react-native";
import { View } from "react-native";

class CondView extends React.PureComponent {
	render() {
		const { show, children, scroll } = this.props;
		if (!show) {
			return null;
		}
		if(scroll) return <ScrollView {...this.props}>{children}</ScrollView>
		return <View {...this.props}>{children}</View>;
	}
}

export default CondView;
