import * as React from "react";
import { SafeAreaView } from "react-native";
import { _ } from "../../i18n/i18n";
import ButtonFooter from "./buttons/ButtonFooter";
import FullScreenKeyboardAvoidingView from "./FullScreenKeyboardAvoidingView";
import InputField from "./InputField";

function FullScreenTextEdit({ route, navigation }) {
	const { inputFieldProps, previousScreenName, focusedKey } = route.params;
	const [editedFieldProps, setEditedFieldProps] = React.useState(inputFieldProps.slice());

	return (
		<FullScreenKeyboardAvoidingView>
			{editedFieldProps.map((ifp, index) => {
				return (
					<InputField
						key={ifp.key || index}
						autoFocus={focusedKey === ifp.key}
						style={ifp.multiline ? { flex: 1 } : {}}
						{...ifp}
						onChangeText={(text) => {
							editedFieldProps[index] = { ...editedFieldProps[index], value: text };
							setEditedFieldProps([...editedFieldProps]);
						}}
					/>
				);
			})}

			<ButtonFooter
				buttonProps={[
					{ title: _("Cancel"), onPress: () => navigation.goBack() },
					{
						title: _("Confirm"),
						onPress: () =>
							navigation.navigate({
								name: previousScreenName,
								params: { editedFieldProps },
								merge: true,
							}),
					},
				]}
			/>
		</FullScreenKeyboardAvoidingView>
	);
}

export default FullScreenTextEdit;
