import { isWeb } from "./generic";
import { CommonActions } from "@react-navigation/native";

// .replace and .push do not work with Drawer navigators,
// which are used by the web version of the app instead of a Stack.
// This uses navigate instead.
export function navReplace(navigation, screenName, params) {
	if (isWeb()) {
		navigation.navigate(screenName, params);
		navigation.dispatch((state) => {
			// /!\ History only exists in drawer and tab navigators
			// https://reactnavigation.org/docs/navigation-state
			// TODO: update routes instead of history in case we need to use this method with a stack navigator
			if (state.history) {
				return CommonActions.reset({
					...state,
					history: state.history.rg_removeAtPure(state.history.length - 2),
				});
			} else {
				const routes = state.routes.rg_removeAtPure(state.routes.length - 2);
				return CommonActions.reset({
					...state,
					routes,
					index: routes.length - 1,
				});
			}
		});
	} else {
		navigation.replace(screenName, params);
	}
}

export function navPush(navigation) {
	if (isWeb()) return navigation.navigate;
	return navigation.push;
}

export function paramToArray(param) {
	if (typeof param === "string") {
		return param.split(",");
	}
	return param;
}

// Makes sure that if we reload the app/site directly on this page (with empty history),
// pressing the back arrow will send to a specific screen
export function ensureBackScreen(navigation, targetScreenName, params) {
	if (navigation.canGoBack()) {
		return;
	}

	if(!isWeb()){
		return;
	}

	navigation.dispatch((state) => {
		if (state.history) {
			const backRouteIndex = state.routes.findIndex((r) => r.name === targetScreenName);
			const backRoute = state.routes[backRouteIndex];

			const history = [{ type: "route", key: backRoute.key }, ...state.history];
			const routes = [...state.routes];
			if (params) routes[backRouteIndex].params = params;

			return CommonActions.reset({
				...state,
				routes,
				history,
			});
		} else {
			const routes = [{ name: targetScreenName, params }, ...state.routes];
			return CommonActions.reset({
				...state,
				routes,
				index: routes.length - 1,
			});
		}
	});
}
