import * as React from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import { isInGame } from "../../tools/games";
import { apiFetch } from "../../network/apiFetch";
import { setNotes } from "../../store/slices/gamesUISlice";
import { ensureBackScreen } from "../../tools/webnavigation";

import { BuildStyleMethod } from "../../styles/theming";

function NotesScreen({ route, navigation, dispatch, game, notes, user }) {
	const styles = stylesMethod(global.theme);
	const inGame = isInGame(user, game);

	React.useEffect(() => {
		if (inGame && notes === undefined) {
			apiFetch(`users/${user.id}/games/${game.id}/notes`).then((notesReceived) =>
				dispatch(setNotes({ gameId: game.id, value: notesReceived }))
			);
		}
	}, [inGame, notes, user.id, game.id]);

	return (
		<AppScreenView>
			<View style={{ flexDirection: "row", flex: 1 }}>
				<View style={[styles.noteBlock, { marginRight: 8 }]}>
					<AppText bold style={{ marginBottom: 8 }}>
						{_("GM handouts")}
					</AppText>
					<ScrollView style={{ flex: 1 }} contentContainerStyle={styles.textContainer}>
						<AppText
							hideEmpty={false}
							onPress={() => navigation.navigate("NoteFullScreen", { private: false })}
							style={styles.text}
						>
							{game.handouts}
						</AppText>
					</ScrollView>
				</View>

				{inGame && (
					<View style={styles.noteBlock}>
						<AppText bold style={{ marginBottom: 8 }}>
							{_("Private notes")}
						</AppText>
						<ScrollView style={{ flex: 1 }} contentContainerStyle={styles.textContainer}>
							<AppText
								hideEmpty={false}
								onPress={() => navigation.navigate("NoteFullScreen", { private: true })}
								style={styles.text}
							>
								{notes?.content || ""}
							</AppText>
						</ScrollView>
					</View>
				)}
			</View>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[ownProps.route.params.gameslug];
	return {
		game,
		user: state.user,
		notes: state.gamesUI[game.id]?.notes,
	};
};

export default connect(mapStateToProps)(NotesScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	noteBlock: {
		flex: 1,
		paddingBottom: 8,
	},
	textContainer: {
		padding: 4,
		borderWidth: 1,
		borderColor: colors.lightBorder,
		borderRadius: 6,
		flexGrow: 1,
	},
	text: { flexGrow: 1, alignItems: "flex-start" },
}));
