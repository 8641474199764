import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { fetchCharacterStates } from "./characterStatesSlice";
import { getCurrentGameUI } from "./gamesUISlice";
import { fetchSheet } from "./sheetsSlice";

export const charactersSlice = createSlice({
	name: "characters",
	initialState: {},
	reducers: {
		receiveCharacters: (state, { payload: { gameId, characters } }) => {
			return { ...state, ...characters.rg_toStore() };
		},
	},
});

export const charactersByGameSlice = createSlice({
	name: "charactersByGame",
	initialState: {},

	extraReducers: {
		[charactersSlice.actions.receiveCharacters]: (state, { payload: { gameId, characters } }) => {
			let gameLines = state[gameId] || [];
			state[gameId] = gameLines.rg_overlapById(characters);
			return state;
		},
	},
});

export const { receiveCharacters } = charactersSlice.actions;

export function fetchCharacters(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/characters`).then((response) => {
			dispatch(receiveCharacters({ gameId, characters: response.results }));
		});
	};
}

export function getCurrentCharacter(state) {
	const gamesUI = getCurrentGameUI(state);
	return state.characters[gamesUI?.currentCharacterId];
}

export function deleteCharacter(character) {
	return async (dispatch, getState) => {
		apiFetch(`games/${character.game}/characters/${character.id}`, "DELETE");
		dispatch(receiveCharacters({ gameId: character.game, characters: [{ ...character, deleted: true }] }));
	};
}

export function createCharacter(gameId) {
	return async (dispatch, getState) => {
		apiFetch(`games/${gameId}/characters`, "POST", {
			"player-id": getState().user.id,
			npc: true,
		}).then((character) => {
			dispatch(receiveCharacters({ gameId, characters: [character] }));
			dispatch(fetchSheet(character.sheet));
			dispatch(fetchCharacterStates(gameId));
		});
	};
}
