import { useRef, useEffect, useState } from "react";

export function debounceValue(value, delay) {
		const [debouncedValue, setDebouncedValue] = useState(value);
	
		useEffect(() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay)
	
			return () => {
				clearTimeout(handler);
			}
		}, [value, delay])
	
		return debouncedValue
	}

export function simpleReducer(state, action){
	return {...state, ...action}
}

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
	  const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
		if (prev.current[k] !== v) {
		  ps[k] = [prev.current[k], v];
		}
		return ps;
	  }, {});
	  if (Object.keys(changedProps).length > 0) {
		console.log('Changed props:', changedProps);
	  }
	  prev.current = props;
	});
  }

// Conditional style
// Receives an array formatted as:
// [ [cond1, style1], [cond2, style2], ... ]
// MAKE SURE CONDITIONS ARE BOOLEANS
// You can also replace '[cond, style]' by 'style' if there are no conditions to apply
export function cStyle(stylesArray) {
	const finalStyles = [];

	for (let i = 0; i < stylesArray.length; i++) {
		const element = stylesArray[i];
		// if element is already a style
		if (!Array.isArray(element)) {
			finalStyles.push(element);
			continue;
		}
		// if no condition is given, push the style automatically
		else if (element.length == 1) {
			finalStyles.push(element[0]);
			continue;
		}
		// if the first element is an array, it is likely a array of style
		else if (typeof element[0] !== "boolean") {			
			finalStyles.push(...element);
		}
		// if condition is true
		else if (element[0]) {
			finalStyles.push(element[1]);
		}
	}
	return finalStyles;
}
