import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import ModalScreen from "../generic/modal/ModalScreen";
import AppButton from "../generic/buttons/AppButton";
import { _ } from "../../i18n/i18n";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
function SelectSingleTagModal({ route, navigation }) {
	const { tags, previousScreen, selectedTag } = route.params;

	const selectTag = (tag) => {
		navigation.navigate(previousScreen, { selectedTag: tag });
	};

	return (
		<ModalScreen  title={_("Select tag")}>
			<FlatList
				data={tags}
				ListHeaderComponent={() => {
					if (selectedTag) {
						return (
							<AppButton
								title={_("None", "no tag selected")}
								onPress={() => selectTag(null)}
								list
								icon={{ type: MaterialIcons, name: "cancel" }}
							/>
						);
					}
					return null;
				}}
				renderItem={({ item }) => (
					<AppButton
						title={item.name}
						onPress={() => selectTag(item)}
						list
						icon={{ type: Ionicons, name: "md-pricetag" }}
					/>
				)}
				keyExtractor={(item, index) => String(index)}
			/>
		</ModalScreen>
	);
}

export default SelectSingleTagModal;


