import GatewayDest from "../../gateway/GatewayDest";
import { Entypo, FontAwesome } from "@expo/vector-icons";
import moment from "moment";
import * as React from "react";
import { findNodeHandle, StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchSheetNodes } from "../../store/slices/sheetNodesSlice";
import { receiveSheets, setSelectedNodeId } from "../../store/slices/sheetsSlice";
import { fetchSheetTabs } from "../../store/slices/sheetTabsSlice";
import store from "../../store/store";
import { sheetPadding } from "../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../styles/theming";
import { isGM } from "../../tools/games";
import { isWeb, isWide, ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import {
	createTab,
	deleteTab,
	moveTabLeft,
	moveTabRight,
	renameCharacter,
	renameSheet,
	renameTab,
	replicateNameChanges,
	saveSheetToProfile,
} from "../../tools/sheets";
import AppScreenView from "../AppScreenView";
import CharacterAvatar from "../avatar/CharacterAvatar";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import IconButton from "../generic/buttons/IconButton";
import FullScreenLoader from "../generic/FullScreenLoader";
import HorizontalTabs from "../generic/HorizontalTabs";
import BoxModal from "../generic/modal/BoxModal";
import Cond from "../meta/Cond";
import CondView from "../meta/CondView";
import RGIcon from "../RGIcon";
import SheetNode from "./nodes/SheetNode";

const loadingFailed = false;

function CharacterSheetScreen({
	route,
	navigation,
	dispatch,
	character,
	sheet,
	template,
	tabs,
	user,
	languages,
	game,
	macroGroups,
}) {
	const styles = stylesMethod(global.theme);
	let { isTemplate, selectingTemplateFor } = route.params;

	const [selectedTabIdx, setselectedTabIdx] = React.useState(0);
	const [creatingTab, setcreatingTab] = React.useState(false);
	const [previewMode, setpreviewMode] = React.useState(false);
	const [previousName, setpreviousName] = React.useState(sheet.character_name);
	const [characterName, setcharacterName] = React.useState(sheet.character_name);

	const viewRef = React.useRef();
	const confirmDelete = React.useRef();
	const confirmNameChangeRef = React.useRef();

	const avatarSize = 128;
	const gm = isGM();

	React.useEffect(() => {
		if (!tabs) {
			dispatch(fetchSheetTabs(sheet.id));
			dispatch(fetchSheetNodes(sheet.id));
		}
	}, [tabs]);

	React.useEffect(() => {
		if (!tabs) return () => {};
		const loadData = async () => {
			const requireUpdate = await querySheetNeedsRefresh(sheet.id, sheet.edited, dispatch).catch((e) => {
				return true;
			});

			if (requireUpdate) {
				await dispatch(fetchSheetTabs(sheet.id));
				await dispatch(fetchSheetNodes(sheet.id));
			}
		};

		loadData();
	}, [sheet.id, tabs]);

	React.useEffect(() => {
		dispatch(setSelectedNodeId(null));
	}, [previewMode]);

	let allowChanges = ((user && character?.player?.id === user.id) || gm) && !game?.archived;
	if (isTemplate) {
		allowChanges = !sheet.saved_on_profile || sheet.saved_on_profile.id === user?.id;
	}

	const screenNodeHandle = viewRef.current ? findNodeHandle(viewRef.current) : null;

	const defaultTabName = React.useCallback(
		(tabIndex) => {
			return _("Tab %(tab_number)s", "default character sheet tab name", { tab_number: " #" + (tabIndex + 1) });
		},
		[tabs]
	);

	const addTab = React.useCallback(() => {
		if (creatingTab) return;
		setcreatingTab(true);
		createTab(sheet, dispatch).then((tab) => {
			setselectedTabIdx(tabs.length);
			setcreatingTab(false);
		});
	}, [sheet, dispatch, creatingTab, tabs]);

	let displayedTabs =
		tabs?.map((t, index) => ({ id: t.id, title: t.name || defaultTabName(index) })).filter((t) => t) || [];
	const editingTemplate = allowChanges && isTemplate && !previewMode;

	if (editingTemplate)
		displayedTabs.push({
			title: _("Add tab"),
			id: "add",
			icon: { type: FontAwesome, name: "plus" },
			onPress: addTab,
		});

	if (!editingTemplate && displayedTabs.length <= 1) displayedTabs = [];

	const { onLayout } = useLayout();

	if (!tabs) return <AppActivityIndicator />;

	const currentTab = tabs[selectedTabIdx];

	return (
		<>
			<AppScreenView
				borderless
				scroll
				scrollViewRef={viewRef}
				style={[styles.container, ws({ paddingHorizontal: sheetPadding() })]}
				onLayout={onLayout}
				onScroll={(event) => {
					global.sheetContentOffsetY = event.nativeEvent.contentOffset.y
				}}
				scrollEventThrottle={100}
			>
				<CondView show={character} style={{ flexDirection: "row", marginVertical: 16, marginRight: 8 }}>
					<CharacterAvatar character={character} size={avatarSize} />

					<View style={{ flex: 1, justifyContent: allowChanges ? "flex-end" : "flex-start" }}>
						<IconButton
							hide={!languages.length}
							icon={{ type: RGIcon, name: "language" }}
							transparent
							style={{ alignSelf: "flex-end" }}
							onPress={() => navigation.navigate("CharacterLanguagesList", { character })}
							title={_("Lang.", "short for languages (must be as short as possible)")}
						/>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<AppText
								size="large"
								bold
								editable={allowChanges}
								onTextChanged={(t) => {
									setcharacterName(t);
									confirmNameChangeRef.current.show();
								}}
								placeholder={_("A person", "default character name")}
							>
								{characterName}
							</AppText>
						</View>
						<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
							<AppButton
								hide={!allowChanges}
								size="small"
								title={_("Change Avatar")}
								onPress={() => navigation.navigate("ChangeAvatarScreen", { character })}
							/>
							<AppButton
								size="small"
								hide={!template || !gm || game?.archived}
								title={_("Edit template")}
								onPress={() =>
									navigation.navigate("CharacterSheetTemplateScreen", {
										sheetId: sheet.template,
										isTemplate: true,
										gameId: character.game,
									})
								}
							/>
						</View>
					</View>

					<BoxModal
						ref={confirmNameChangeRef}
						message={
							<View>
								<AppText style={{ marginBottom: 16 }}>
									{_("Do you want to replicate the name change everywhere in the story?")}
								</AppText>

								<AppButton
									style={{ marginBottom: 8 }}
									list
									title={_("Only use this name from now on")}
									info={_(
										"Useful to change your character name temporarily (for example, if you are reusing an NPC sheet for a different character)."
									)}
									onPress={() => {
										renameCharacter(sheet, characterName, dispatch);
										setpreviousName(characterName);
										confirmNameChangeRef.current.hide();
									}}
								/>
								<AppButton
									list
									danger
									title={_("Rename everywhere in the story")}
									info={_(
										"Every existing instances of the name [%(old_name)s] will be replaced by [%(new_name)s].",
										"rename character",
										{ old_name: previousName, new_name: characterName }
									)}
									onPress={() => {
										renameCharacter(sheet, characterName, dispatch);
										replicateNameChanges(character, previousName, characterName, sheet, dispatch);
										setpreviousName(characterName);
										confirmNameChangeRef.current.hide();
									}}
								/>
							</View>
						}
						options={[]}
					/>
				</CondView>

				<CondView
					show={editingTemplate}
					style={{ backgroundColor: global.colors.primary, alignItems: "center", paddingVertical: 8 }}
				>
					<AppText
						editable
						size="large"
						iconColor={global.colors.textLight}
						color="textLight"
						italic={!sheet.save_name}
						onTextChanged={(text) =>
							sheet.saved_on_profile
								? renameSheet(sheet, text, dispatch)
								: saveSheetToProfile(sheet, text, user, dispatch)
						}
						placeholder={
							sheet.saved_on_profile
								? _("Unnamed sheet", "character sheet has no name")
								: _("Add name to save")
						}
					>
						{sheet.save_name}
					</AppText>
				</CondView>

				<HorizontalTabs
					tabs={displayedTabs}
					selectedId={tabs[selectedTabIdx].id}
					onTabSelected={(tabId) => setselectedTabIdx(tabs.rg_indexWithId(tabId))}
					parentPadding={sheetPadding()}
				/>

				<CondView show={currentTab && editingTemplate} style={styles.tabOptions}>
					<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
						<View style={{ flexDirection: "row", alignItems: "center", minHeight: 48 }}>
							<IconButton
								hide={tabs.length <= 1}
								icon={{ type: Entypo, name: "chevron-left" }}
								onPress={() => {
									moveTabLeft(tabs, currentTab, dispatch);
									setselectedTabIdx(tabs.rg_prevIndex(selectedTabIdx, false, true));
								}}
							/>
							<AppText
								editable
								onTextChanged={(text) => renameTab(currentTab, text, dispatch)}
								color="textLight"
								placeholder={defaultTabName(selectedTabIdx)}
								iconColor={global.colors.textLight}
							>
								{currentTab?.name}
							</AppText>
							<IconButton
								hide={tabs.length <= 1}
								icon={{ type: Entypo, name: "chevron-right" }}
								onPress={() => {
									moveTabRight(tabs, currentTab, dispatch);
									setselectedTabIdx(tabs.rg_nextIndex(selectedTabIdx, false, true));
								}}
							/>
						</View>
						<AppButton
							hide={tabs.length <= 1}
							title={_("Delete")}
							danger
							size="small"
							onPress={() => confirmDelete.current.show()}
						/>
					</View>
				</CondView>

				<View style={styles.sheetContainer}>
					{!!currentTab?.root_node && (
						<SheetNode
							isRoot
							key={currentTab.root_node}
							nodeId={currentTab.root_node}
							currentTabId={currentTab.id}
							allowSaveChanges={allowChanges}
							editingTemplate={editingTemplate}
							screenNativeNode={screenNodeHandle}
						/>
					)}

					{!currentTab?.root_node && <AppActivityIndicator />}
				</View>

				<GatewayDest
					name="sheetOptionBar"
					unmountOnEmpty
					component={({ children }) => (
						<View
							style={{
								position: "absolute",
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
							}}
							pointerEvents="box-none"
						>
							{children}
						</View>
					)}
				/>

				<FullScreenLoader visible={creatingTab} />
				<BoxModal
					ref={confirmDelete}
					message={_(
						"Are you sure you want to delete this tab? It will destroy all its items and this action cannot be reverted."
					)}
					isDelete
					onConfirm={() => {
						setselectedTabIdx(tabs.rg_prevIndex(selectedTabIdx, true));
						deleteTab(tabs, currentTab, dispatch);
					}}
				/>
			</AppScreenView>
			{isTemplate && allowChanges && (
				<ButtonFooter
					style={ws({ paddingHorizontal: sheetPadding() })}
					narrow={global.navigatorWidth < 1700}
					buttonProps={[
						{
							title: previewMode ? _("Edit") : _("Switch to preview", "preview character sheet template"),
							onPress: () => setpreviewMode((current) => !current),
						},
						global.appleStoreReview
							? null
							: {
									title: _("Attach macros", "go to manage sheet macros menu"),
									onPress: () => navigation.navigate("ManageMacrosScreen", { sheetId: sheet.id }),
							  },
						isWeb() && navigation.canGoBack()
							? {
									title: _("Done"),
									onPress: () => navigation.goBack(),
							  }
							: null,
					]}
				/>
			)}
			{isTemplate && !allowChanges && !!macroGroups.bySheet[sheet.id]?.length && (
				<ButtonFooter
					narrow={!isWide()}
					style={ws({ paddingHorizontal: sheetPadding() })}
					buttonProps={[
						{
							title: _("See macros", "go to manage sheet macros menu"),
							onPress: () => navigation.navigate("ViewSheetMacrosScreen", { sheet }),
						},
					]}
				/>
			)}
			{!!selectingTemplateFor && (
				<ButtonFooter
					style={ws({ paddingHorizontal: sheetPadding() })}
					narrow={false}
					buttonProps={[
						{
							title: _("Select this template", "select character sheet template for new game"),
							onPress: () => navigation.navigate(selectingTemplateFor, { sheetSelected: sheet }),
						},
					]}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	let sheetId = ownProps.sheet.id;
	let characterId = ownProps.character?.id;
	const sheets = state.sheets;
	const character = state.characters[characterId];
	let sheet = sheets[sheetId || character?.sheet];
	sheetId = sheet?.id || sheetId || character?.sheet;

	return {
		template: sheets[sheet?.template],
		sheet,
		tabs: state.sheetTabs[sheetId],
		user: state.user,
		character,
		languages: character ? state.languages[character.game] || Array.rg_empty : Array.rg_empty,
		game: state.games[character?.game],
		macroGroups: state.macroGroups,
	};
};

export default connect(mapStateToProps)(CharacterSheetScreen);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			paddingBottom: 16,
		},
		sheetContainer: {
			marginHorizontal: ws(0, 4),
			flexGrow: 0,
			flexShrink: 1,
			flexBasis: "auto",
		},
		tabOptions: {
			backgroundColor: colors.primary,
		},
	})
);

// Query the database to compare the saved state with the one currently loaded
const querySheetNeedsRefresh = (sheetId, sheetEditedTime, dispatch) => {
	return apiFetch(`sheets/${sheetId}`).then((result) => {
		// get the state instead of passing as redux props to no trigger a re-render every time a node is updated.
		const state = store.getState();
		const nodes = state.sheetNodes;
		let requireUpdate = !Object.values(nodes).some((n) => n.sheet === result.id);
		requireUpdate = requireUpdate || moment(result.edited).isAfter(sheetEditedTime);

		if (requireUpdate) {
			receiveSheets([result]);
		}
		return requireUpdate;
	});
};
