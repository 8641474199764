import AsyncStorage from '@react-native-async-storage/async-storage';
import { _ } from "../i18n/i18n";
import { deleteToken } from "../network/mobileNotifications";
import { CLEAR_STORE } from "../store/generics";
import store from "../store/store";

export function logout(userId, dispatch) {
	deleteToken(userId);
	dispatch(CLEAR_STORE());
	AsyncStorage.removeItem("expoPushToken" + global.expoTokenVersion);
	AsyncStorage.removeItem("authToken");
	AsyncStorage.removeItem("storeState");
}

export function levelToCoinFileName(level) {
	switch (level) {
		case 1:
			return "Copper";
		case 2:
			return "Silver";
		case 3:
			return "Gold";
		case 4:
			return "Platinum";
		case 5:
			return "Legendary";
		default:
			return "Wooden";
	}
}

export function levelToCoinColor(level) {
	return global.colors.accountColors[levelToCoinFileName(level).toLowerCase()];
}

export const levelToCoinName = (level) => {
	switch (level) {
		case 1:
			return _("Copper", "rank name");
		case 2:
			return _("Silver", "rank name");
		case 3:
			return _("Gold", "rank name");
		case 4:
			return _("Platinum", "rank name");
		case 5:
			return _("Legendary", "rank name");
		default:
			return _("Wooden", "basic coin type");
	}
};

export const levelToRankName = (level) => {
	switch (level) {
		case 0:
			return _("Basic", "rank name");
		case 1:
			return _("Copper", "rank name");
		case 2:
			return _("Silver", "rank name");
		case 3:
			return _("Gold", "rank name");
		case 4:
			return _("Platinum", "rank name");
		case 5:
			return _("Legendary", "rank name");
		default:
			return _("Legendary+", "rank name");
	}
};

export const levelToRankShort = (level) => {
	switch (level) {
		case 1:
			return "co";
		case 2:
			return "si";
		case 3:
			return "go";
		case 4:
			return "pl";
		case 5:
			return "le";
		default:
			return "no";
	}
};

export const getLevelFromPlanId = (planId) => {
	planId = String(planId);
	if (planId === "535646") return 1;
	if (planId === "535647") return 2;
	if (planId === "535648") return 3;
	if (planId === "535716") return 3;
	if (planId === "535649") return 4;
	if (planId === "535650") return 5;
	return 0;
};

export const getRankPrice = (level) => {
	switch (level) {
		case 1:
			return 2;
		case 2:
			return 5;
		case 3:
			return 10;
		case 4:
			return 20;
		case 5:
			return 50;
		default:
			return 0;
	}
};

export const getPlanPaddleId = (planLevel) => {
	if (planLevel === 1) return 535646;
	// return 535684; // Test plan
	if (planLevel === 2) return 535647;
	// return 535685; // Test plan
	if (planLevel === 3) return 535716;
	if (planLevel === 4) return 535649;
	if (planLevel === 5) return 535650;
};

export const getUsername = (user) => {
	return user?.username || _("[Deleted]", "deleted user");
};

export const HasLevelOrShare = (currentLevel, requiredLevel) => {
	if (currentLevel >= requiredLevel) return true;
	if (requiredLevel <= 2) return HasActiveLegendaryShare();
	return false;
};

export const HasActiveLegendaryShare = () => {
	const state = store.getState();
	const game = state.games[state.games.currentId];

	if (!game) return false;
	if (!game.players.length) return false;

	const users = state.users;

	let players = [
		...game.players.map((p) => users[p.id]),
		...game.assistants.map((id) => users[id]),
		users[game.game_master.id],
	];

	players = players.filter(p=>p);

	return players.some((p) => p.profile?.reward_level >= 5);
};

export const hasDevAccess = ()=>{
	const state = store.getState();
	const user = state.user;

	return user && (user.is_staff || user.username === "Div02");	
}