import * as React from "react";
import { StyleSheet, FlatList } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchDice } from "../../store/slices/diceSlice";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import SymbolItem from "./SymbolItem";
import { idKeyExtractor } from "../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";

function NameSymbolsScreen({ route, navigation }) {
	const { symbols, diceSet } = route.params;

	const dispatch = useDispatch();

	React.useEffect(() => {
		return () => {
			dispatch(fetchDice([diceSet.id]));
		};
	}, [diceSet]);

	return (
		<AppScreenView borderless style={{ paddingLeft: fullScreenLeftPadding()}}>
			<FlatList			
				contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 8, maxWidth: fullScreenContentWidth() }}
				ListHeaderComponent={
					<>
						<AppText bold>{_("Symbols", "name symbols screen")}</AppText>
						<AppText color="hint" style={{ marginBottom: 16 }}>
							{_("Give each symbol a name that will be displayed when rolled.", "name symbols screen")}
						</AppText>
					</>
				}
				keyExtractor={idKeyExtractor}
				data={symbols}
				renderItem={({ item }) => <SymbolItem symbol={item} style={{ marginBottom: 8 }} />}
			/>
		</AppScreenView>
	);
}

export default NameSymbolsScreen;
