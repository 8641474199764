import * as React from "react";
import { Image, Linking, StyleSheet, TouchableOpacity, View } from "react-native";
import { _ } from "../../i18n/i18n";
import { BuildStyleMethod } from "../../styles/theming";
import { isWeb } from "../../tools/generic";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import AppModal from "../generic/modal/AppModal";

const isMobile = () => {
	return !!(
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	);
};

const isiOs = () => {
	return (
		navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)
	);
};

export const maybeOpenURL = async (url, { appName, appStoreId, appStoreLocale, playStoreId }) => {
	try {
		Linking.openURL(url).catch((err) => {
			alert({ err });
			if (err.code === "EUNSPECIFIED") {
				if (isiOs()) {
					// check if appStoreLocale is set
					const locale = typeof appStoreLocale === "undefined" ? "us" : appStoreLocale;

					Linking.openURL(`https://apps.apple.com/${locale}/app/${appName}/id${appStoreId}`);
				} else {
					Linking.openURL(`https://play.google.com/store/apps/details?id=${playStoreId}`);
				}
			} else {
				throw new Error(`Could not open ${appName}. ${err.toString()}`);
			}
		});
	} catch (e) {
		alert("error", e);
	}
};

export const openInStore = async ({ appName, appStoreId, appStoreLocale = "us", playStoreId }) => {
	if (isiOs()) {
		Linking.openURL(`https://apps.apple.com/${appStoreLocale}/app/${appName}/id${appStoreId}`);
	} else {
		Linking.openURL(`https://play.google.com/store/apps/details?id=${playStoreId}`);
	}
};


function DownloadAppSuggestion() {
	const styles = stylesMethod(global.theme);
	const [visible, setvisible] = React.useState(isMobile());
	const [canOpenApp, setcanOpenApp] = React.useState(false);

	if (!isWeb() || !isMobile()) return false;

	const inAppUrl = "rolegate://";

	const hide = React.useCallback(() => {
		setvisible(false);
	}, []);

	const appLinkConfig = {
		appName: "role-gate",
		appStoreId: "1528692531",
		appStoreLocale: "us",
		playStoreId: "com.LodestarTeam.rolegateapp",
	};

    React.useEffect(()=>{
        const canOpen = async ()=>{
            Linking.canOpenURL(inAppUrl).then(available=>{
                alert(available);
            })
        }
        // canOpen();
    }, []);

	const onOpenClicked = React.useCallback(() => {
		openInStore(appLinkConfig);
		// maybeOpenURL(inAppUrl, appLinkConfig);
	}, []);

	const iconSize = 64;

	return (
		<AppModal animationType="fade" visible={visible} onRequestClose={hide} transparent>
			<TouchableOpacity style={styles.backdrop} activeOpacity={1} onPress={hide} />

			<View style={styles.container}>
				<AppText centered>{_("The webpage is built for a desktop experience")}</AppText>
				<AppText centered color="secondary" size="large" style={{ marginBottom: 32, marginTop: 8 }}>
					{_("Use Role Gate as...")}
				</AppText>

				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: 32,
					}}
				>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						<Image
							style={{ width: iconSize, height: iconSize, marginRight: 16 }}
							source={require("../../../assets/images/icon.png")}
						/>
						<AppText size="large">{_("Role Gate app")}</AppText>
					</View>
					<AppButton
						size="small"
						title={_("Open", "use Role Gate app instead of website")}
						onPress={onOpenClicked}
						style={{ width: 80 }}
					/>
				</View>

				<AppButton
					title={_("Continue on web", "use website instead of Role Gate app")}
					transparent
					onPress={() => setvisible(false)}
				/>
			</View>
		</AppModal>
	);
}

export default DownloadAppSuggestion;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: colors.overlayBackground,
	},
	container: {
		position: "absolute",
		bottom: 0,
		backgroundColor: colors.cardBackground,
		padding: 24,
		paddingVertical: 12,
		left: 0,
		right: 0,
	},
}));
