import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { receiveMacros } from "../../store/slices/macrosSlice";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { colors } from "../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { commandHasSheetValues, findSheetLabelInputs } from "../../tools/diceRoll";
import { ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import { filterDefaultLabels } from "../../tools/sheets";
import AppScreenView from "../AppScreenView";
import ListCommandsHelpButton from "../diceroll/ListCommandsHelpButton";
import AppText from "../generic/AppText";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import ColorSelector from "../generic/ColorSelector";
import InputField from "../generic/InputField";
import TokenSelector from "../generic/TokenSelector";
import Cond from "../meta/Cond";
import CondView from "../meta/CondView";
import RGTokenIcon from "../RGTokenIcon";
import MacroVisualizer from "./MacroVisualizer";

function MacroScreen({ route, navigation, dispatch, macros, macroGroups, user, sheetValuePairs }) {
	let { macro, sheetId } = route.params;
	macro = macros[macro.id] || macro;

	sheetValuePairs = sheetValuePairs ? sheetValuePairs.filter(filterDefaultLabels) : [];

	const macroGroup = macroGroups[macro.group];

	const [name, setname] = React.useState(macro.name);
	const [description, setdescription] = React.useState(macro.description);
	const [icon, seticon] = React.useState(macro.icon);
	const [color, setcolor] = React.useState(macro.color);
	const [command, setcommand] = React.useState(macro.command);
	const [labels, setLabels] = React.useState(
		macro.labels ? JSON.parse(macro.labels) : findSheetLabelInputs(macro.command)
	);

	const prevCommand = usePrevious(command);

	const owns = user.id === macroGroup.user;

	React.useEffect(() => {
		dispatch(fetchSheetValuePairsIfNeeded(sheetId));
	}, [sheetId]);

	const save = React.useCallback(() => {
		const data = {
			name,
			description,
			icon,
			color,
			group: macro.group,
			command,
			labels: JSON.stringify(labels),
		};

		if (!macro.id) {
			apiFetch(`users/${user.id}/dice-macros`, "POST", data).then((savedMacro) => {
				dispatch(receiveMacros([savedMacro]));
			});
		} else {
			apiFetch(`users/${user.id}/dice-macros/${macro.id}`, "PATCH", data);
			dispatch(receiveMacros([{ ...macro, ...data }]));
		}

		navigation.goBack();
	}, [name, description, icon, color, command, labels, macro, user.id, navigation]);

	const updateLabel = React.useCallback(
		(label) => {
			const newLabels = labels.rg_replaceElement(
				(l) => l.poolIndex === label.poolIndex && l.index === label.index && label.value === l.value,
				label
			);
			let sortedLabels = newLabels.slice().sort((a, b) => a.targetId - b.targetId);
			// Make sure targetIds either start at 0 or 1
			if (sortedLabels.length && sortedLabels[0].targetId > 1) {
				sortedLabels = sortedLabels.map((l) =>
					l.targetId === sortedLabels[0].targetId ? { ...l, targetId: 1 } : l
				);
			}
			// Make sure there are no gaps;
			for (let sl = 0; sl < sortedLabels.length - 1; ++sl) {
				const diff = sortedLabels[sl + 1].targetId - sortedLabels[sl].targetId;
				if (diff > 1) {
					for (let i = sl + 1; i < sortedLabels.length; ++i) {
						sortedLabels[i].targetId = sortedLabels[i].targetId - diff + 1;
					}
				}
			}
			// Sort label back to order of appearance in the macro
			sortedLabels.sort((a, b) => a.poolIndex + a.index - (b.poolIndex + b.index));
			setLabels(sortedLabels);
		},
		[labels]
	);

	React.useEffect(() => {
		if (prevCommand === command) {
			return;
		}
		let newLabels = findSheetLabelInputs(command);
		newLabels = newLabels.map(
			(label) =>
				labels.find(
					(l) => l.poolIndex === label.poolIndex && l.index === label.index && label.value === l.value
				) || label
		);
		setLabels(newLabels);
	}, [command, labels, prevCommand]);

	const isValid = !!name.trim() && !!command.trim();

	const options = [];

	owns &&
		options.push({
			title: _("Cancel"),
			onPress: () => navigation.goBack(),
		});

	owns &&
		options.push({
			title: _("Save"),
			disabled: !isValid,
			onPress: save,
		});

	return (
		<>
			<AppScreenView
				scroll
				keyboardShouldPersistTaps="handled"
				style={ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() })}
			>
				<AppText bold editable={owns} size="large" onTextChanged={setname}>
					{name}
				</AppText>
				<AppText editable={owns} placeholder={_("No description")} onTextChanged={setdescription}>
					{description}
				</AppText>

				<CondView show={!owns} style={{ marginTop: 16 }}>
					<RGTokenIcon name={icon} color={global.colors.userColors[color]} size={62} style={{ marginBottom: 16 }} />
					<AppText bold>{_("Command rolled:", "macro details")}</AppText>
					<MacroVisualizer command={command} labels={labels} />
				</CondView>

				<Cond show={owns}>
					<View show={owns} style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 16 }}>
						<View style={{ alignItems: "center" }}>
							<AppText>{_("Icon", "die icon")}</AppText>
							<TokenSelector currentToken={icon} setToken={seticon} />
						</View>
						<View style={{ alignItems: "center" }}>
							<AppText>{_("Color", "die icon color")}</AppText>
							<ColorSelector currentColor={color} setColor={setcolor} />
						</View>
					</View>

					<Divider style={{ marginVertical: 16 }} />

					<InputField
						label={_("Roll command", "roll+ command input field")}
						value={command}
						onChangeText={setcommand}
						multiline={true}
						placeholder={_("Ex: attacks for [[1d20+STR]] and deals [[1d10]] damage", "roll+ placeholder")}
						autocompleteProps={{
							itemsPool: sheetValuePairs,
							itemToStringMap: sheetValuePairs.map((svp) => ({ item: svp, string: svp.label })),
							filterItemsByKey: "label",
							wordBoundaryRegexString: "(\\[\\[)|(]])|[+><=-]|(\\d+d)",
							wordBoundaryRegexStringFlags: "gm",
							appendToAutocomplete: "",
							noScroll: true,
						}}
					/>

					<ListCommandsHelpButton />

					<Cond show={commandHasSheetValues(command)}>
						<Divider style={{ marginVertical: 16 }} />

						<AppText bold>{_("Define targets", "create macro info")}</AppText>

						<MacroVisualizer command={command} labels={labels} updateLabel={updateLabel} />

						<View style={{ marginTop: 16 }}>
							<AppText color="hint">
								{ws(
									_(
										"Click on a character sheet value to make it target another character. This will let you use sheet values from that target when rolling your macro"
									),
									_(
										"Tap a character sheet value to make it target another character. This will let you use sheet values from that target when rolling your macro"
									)
								)}
							</AppText>

							<AppText color="hint" style={{ marginTop: 8 }}>
								{_(
									"Example: [[1d20+STR>=AC(target)]] will roll 1d20 + the STR value of the character rolling, and compare it to the AC of the target."
								)}
							</AppText>
							<AppText color="hint" style={{ marginTop: 8 }}>
								{_(
									"You can have more than a single target. Simply tap the second value again to assign to an additional target."
								)}
							</AppText>
						</View>
					</Cond>
				</Cond>
			</AppScreenView>
			<ButtonFooter narrow={false} style={ws({ maxWidth: fullScreenContentWidth() })} buttonProps={options} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { sheetId } = ownProps.route.params;
	return {
		macros: state.macros,
		macroGroups: state.macroGroups,
		user: state.user,
		sheetValuePairs: state.sheetValuePairs[sheetId],
	};
};

export default connect(mapStateToProps)(MacroScreen);

const styles = StyleSheet.create({
	container: { marginHorizontal: 16 },
});
