import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import ReadablePoolCommand from "../diceroll/ReadablePoolCommand";
import { _ } from "../../i18n/i18n";
import { getCharName } from "../../tools/characters";

function MacroVisualizer({
	command,
	labels,
	selectedLabel,
	updateLabel,
	onLabelClicked,
	ignoreTarget0,
	targets,
	author,
	characterName,
	style,
}) {
	const parts = command.split(/(\[\[.*?]])/).filter((p) => p);
	let partIndex = 0;
	const components = parts.map((p, index) => {
		partIndex = index > 0 ? partIndex + parts[index - 1].length : 0;
		if (p.startsWith("[[")) {
			return (
				<ReadablePoolCommand
					key={index}
					command={p}
					poolLabels={labels?.filter((l) => l.poolIndex === partIndex + 2)}
					labels={labels}
					ignoreTarget0={ignoreTarget0}
					updateLabel={updateLabel}
					onLabelClicked={onLabelClicked}
					targets={targets}
					selectedLabel={selectedLabel}
				/>
			);
		}
		return <AppText key={p}>{p}</AppText>;
	});

	if (author || characterName) {
		const name = characterName
			? characterName
			: author
			? getCharName(author)
			: _("GM", "Game Master, Roll+ author");
		components.splice(0, 0, <AppText key="author">{name + " "}</AppText>);
	}

	return <View style={[styles.container, style]}>{components}</View>;
}

export default MacroVisualizer;

const styles = StyleSheet.create({
	container: { flexDirection: "row", flexWrap: "wrap" },
});
