import * as React from "react";
import { View, StyleSheet, TouchableOpacity, Modal } from "react-native";
import RGTokenIcon, { tokenGlyphMap } from "../RGTokenIcon";
import BoxModal from "./modal/BoxModal";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";

function TokenSelector({ currentToken, setToken }) {
	const allTokens = Object.keys(tokenGlyphMap);

	const modalRef = React.useRef();

	return (
		<TouchableOpacity style={styles.container} onPress={() => modalRef.current.show()}>
			<RGTokenIcon name={currentToken} size={64} color={global.colors.textDefault} />
			<BoxModal ref={modalRef} wide options={[{ title: _("Cancel") }]}>
				<View style={styles.list}>
					{allTokens.map((item) => (
						<TouchableOpacity
							key={item}
							style={styles.tokenListed}
							onPress={() => {
								setToken(item);
								modalRef.current.hide();
							}}
						>
							<RGTokenIcon name={item} key={item} size={32} color={global.colors.textDefault} />
						</TouchableOpacity>
					))}
				</View>
			</BoxModal>
		</TouchableOpacity>
	);
}

export default TokenSelector;

const styles = StyleSheet.create({
	container: {},
	list: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	tokenListed: {
		margin: 8,
	},
});
