import * as React from "react";
// @react-native-community/clipboard getString doesn't work.
// Returns Cannot read property "getString" of undefined
//import Clipboard from "@react-native-community/clipboard";
// import Clipboard from 'expo-clipboard';
// import { Clipboard } from "react-native"
import * as Clipboard from 'expo-clipboard';
import ModalScreen from "../generic/modal/ModalScreen";
import { connect } from "react-redux";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import BoxModal from "../generic/modal/BoxModal";
import { deleteMessage } from "../../store/slices/directMessagesSlice";
import { setEditingMessage } from "../../store/slices/directMessageUISlice";
import { _ } from '../../i18n/i18n';

function MessageOptionsModal({ route, navigation, dispatch, user }) {
	const { message } = route.params;
	const copiedModalRef = React.useRef(null);
	const confirmDeleteModalRef = React.useRef(null);

	const options = [];

	options.push({
		title: _("Copy"),
		onPress: () => {
			Clipboard.setStringAsync(message.content);
			copiedModalRef.current.show();
		},
	});
	if (message.user === user.id) {
		options.push({
			title: _("Edit", "Edit direct message content"),
			onPress: () => {
                dispatch(setEditingMessage({ groupId: message.group, message }));
                navigation.goBack();
			},
		});
		options.push({
			title: _("Delete"),
			onPress: () => {
				confirmDeleteModalRef.current.show();
			},
			danger: true,
		});
	}

	return (
		<ModalScreen options={options}>
			<TinyFeedbackModal ref={copiedModalRef} message={_("Copied to clipboard!")} />
			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete message")}
				message={_("Are you sure you want to delete this message?")}
				isDelete
				onConfirm={() => {
					dispatch(deleteMessage(message));
					navigation.goBack();
				}}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(MessageOptionsModal);


