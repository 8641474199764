import { MaterialIcons } from "@expo/vector-icons";
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import { useHeaderHeight } from '@react-navigation/elements';
import * as React from "react";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { sizes } from "../../styles/font";
import { isWide } from "../../tools/generic";
import CharacterAvatar from "../avatar/CharacterAvatar";
import ExploreScreen from "../games/ExploreScreen";
import MyGamesScreen from "../games/MyGamesScreen";
import RGIcon from "../RGIcon";
import HeaderBadge from "./HeaderBadge";

const Tab = createMaterialBottomTabNavigator();

function HomeNavigator({ route, navigation, dispatch, user, lastCharacter }) {
	const headerHeight = useHeaderHeight();
	const [localeCode, setlocaleCode] = React.useState();

	React.useEffect(() => {
		// only used to force a refresh
		const removeFocusListener = navigation.addListener("focus", () => setlocaleCode(global.LOCALE_CODE));

		navigation.setOptions({
			headerLeft: isWide() ? null : () => (
				<TouchableOpacity
					style={{ width: 64, alignItems: "center" }}
					onPress={() => {
						navigation.openDrawer();
					}}
				>
					<CharacterAvatar character={lastCharacter} size={Math.max(24, Math.min(48, headerHeight - 48))} />
					<HeaderBadge />
				</TouchableOpacity>
			),
		});

		return () => {
			removeFocusListener();
		};
	}, []);

	return (
		<Tab.Navigator>
			<Tab.Screen
				name="MyGames"
				component={MyGamesScreen}
				options={{
					tabBarLabel: _("My games"),
					tabBarIcon: ({ focused }) => (
						<RGIcon color={focused ? global.colors.textLight : global.colors.textDark} name="die" size={sizes.large} />
					),
				}}
			/>
			<Tab.Screen
				name="Bookmarks"
				children={(props) => <MyGamesScreen isBookmarks {...props} />}
				options={{
					tabBarLabel: _("Bookmarks"),
					tabBarIcon: ({ focused }) => (
						<MaterialIcons
							name="bookmark"
							size={sizes.large}
							color={focused ? global.colors.textLight : global.colors.textDark}
						/>
					),
				}}
			/>
			<Tab.Screen
				name="Explore"
				component={ExploreScreen}
				options={{
					headerTitle: "Jonh",
					tabBarLabel: _("Explore"),
					tabBarIcon: ({ focused }) => (
						<MaterialIcons
							name="explore"
							size={sizes.large}
							color={focused ? global.colors.textLight : global.colors.textDark}
						/>
					),
				}}
			/>
		</Tab.Navigator>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		lastCharacter: state.user?.profile.last_used_character,
	};
};

export default connect(mapStateToProps)(HomeNavigator);
