import * as React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import GameTagHorizontalList from "../../gametags/GameTagHorizontalList";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import { getTimestamp } from "../../../tools/time";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import GameDetailsQuickActions from "./GameDetailsQuickActions";
import { isWeb, ws } from "../../../tools/generic";
import { fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { colors } from "../../../styles/colors";
import CondView from "../../meta/CondView";
import { useLayout } from "react-native-web-hooks";

function GameDetailsHeader({ game, openGame }) {
	const pathToBg = `${global.root_url}/static/images/stagebg/bg_${game.cover_background?.name || "neutral_1"}.png`;
	const textSize = isWeb() ? "average" : "small";

	const updateDateComponent = (
		<AppText centered={!isWeb()} bold={!isWeb()} color="textLight" size={isWeb() ? null : "small"}>
			{game.archived
				? _("ended %(last_update_date)s", "game details", {
						last_update_date: getTimestamp(game.last_line?.created || game.last_update),
				  })
				: _("updated %(last_update_date)s", "game details", {
						last_update_date: getTimestamp(game.last_update),
				  })}
		</AppText>
	);

	const beginnerFriendlyComponent = (
		<AppText
			hide={!game.beginner_friendly}
			color={ws("hint", "textLight")}
			size="small"
			style={[
				globalStyles.tagPill,
				{ minWidth: 100 },
				ws({
					backgroundColor: global.colors.translucidLightBackground,
					alignSelf: "flex-start",
				}),
			]}
		>
			{_("Beginner friendly")}
		</AppText>
	);

	const { onLayout } = useLayout();

	return (
		<TouchableOpacity onLayout={onLayout} style={styles.topBox} onPress={openGame}>
			<Image source={{ uri: pathToBg }} style={styles.background} resizeMode="cover" />

			<CondView show={!isWeb()} style={[globalStyles.rc, globalStyles.sb, { padding: 8 }]}>
				<GameTagHorizontalList game={game} />
				{beginnerFriendlyComponent}
			</CondView>

			<View style={[styles.titles, ws({ alignItems: "center"  })]}>
				<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
					<AppText centered={!isWeb()} bold={!isWeb()} color="textLight" size="large">
						{game.name}
					</AppText>
					<AppText centered={!isWeb()} bold={!isWeb()} color="secondary" size={textSize}>
						{_("by %(gm_name)s, created %(creation_date)s", "game details", {
							gm_name: game.game_master.username,
							creation_date: getTimestamp(game.start_date),
						})}
						{isWeb() && (
							<AppText>
								{" | "} {updateDateComponent}
							</AppText>
						)}
					</AppText>
					{!isWeb() && updateDateComponent}
					<CondView show={isWeb()} style={{ flexDirection: "row", marginTop: 8 }}>
						{beginnerFriendlyComponent}
						<GameTagHorizontalList game={game} large />
					</CondView>
				</View>

				<GameDetailsQuickActions game={game} openGame={openGame} />
			</View>
		</TouchableOpacity>
	);
}

export default GameDetailsHeader;

const boxHeight = 260;

const styles = StyleSheet.create({
	topBox: {
		height: boxHeight,
		justifyContent: "space-between",
		backgroundColor: "black",
		...ws({ justifyContent: "flex-end" }),
	},
	titles: {
		backgroundColor: "rgba(0,0,0,0.2)",
	},
	background: {
		position: "absolute",
		height: boxHeight,
		width: "100%",
	},
	beginnerFriendlyTag: {},
});
