import moment from "moment";

export const formatJSDateToDjangoDate = (date) => {
    // From:
    // Mon Jul 15 2019 13:21:03 GMT+0200 (Central European Summer Time)
    // to:
    // 2019-07-15T11:20:32.993857Z
    // YYYY-MM-DDTHH:mm:ss.SSSSSS

    const date_utc = moment.utc(date);

    return date_utc.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")+"Z";

};