import * as React from "react";
import { View, StyleSheet } from "react-native";
import { colors } from "../../styles/colors";

import { BuildStyleMethod } from "../../styles/theming";

function PlaceholderLine() {
	const styles = stylesMethod(global.theme);
	return (
		<View style={styles.line}>
            <View style={styles.avatar}></View>
			<View style={styles.lineBox}></View>
		</View>
	);
}

export default PlaceholderLine;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
    avatar:{
        width: 64
    },
    line:{
		marginBottom: 8,
        marginHorizontal: 8,
        flexDirection: "row"

    },
	lineBox: {
		borderColor: colors.lightBorder,
		borderWidth: 1,
		borderRadius: 8,
		padding: 8,
        height: 72,
        flex: 1,
	},
}));
