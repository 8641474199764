import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const sheetNodesSlice = createSlice({
	name: "sheetNodes",
	initialState: {},
	reducers: {
		receiveSheetNodes: (state, { payload: sheets }) => ({ ...state, ...sheets.rg_toStore() }),
		editNode: (state, { payload: node }) => {
			state[node.id] = node;
		},
		editNodeValue: (state, { payload: { nodeId, newValue } }) => {
			const currentNode = state[nodeId];
			state[nodeId] = { ...currentNode, value: newValue };
		},
	},
});

export const { receiveSheetNodes, editNode, editNodeValue } = sheetNodesSlice.actions;

export function fetchSheetNodes(sheetId) {
	return async (dispatch, getState) => {
		return apiFetch(`sheets/${sheetId}/nodes`).then((response) => {
			dispatch(receiveSheetNodes(response));
		});
	};
}
