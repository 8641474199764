import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, isQueryInProgress } from "../../network/apiFetch";
import moment from "moment";

export const sheetValuePairsSlice = createSlice({
	name: "sheetValuePairs",
	initialState: {},
	reducers: {
		receiveSheetValuePairs: (state, { payload: { sheetId, pairs } }) => {
			state[sheetId] = pairs;
			return state;
		},
	},
});

export const { receiveSheetValuePairs } = sheetValuePairsSlice.actions;

export function fetchSheetValuePairsIfNeeded(sheetId) {
	if (!sheetId) return () => null;
	const url = `sheets/${sheetId}/sheet-number-values`;
	if (isQueryInProgress(url)) {
		return () => null;
	}
	return async (dispatch, getState) => {
		const { sheets, sheetValuePairs } = getState();

		let test = new Promise((resolve) => resolve(true));
		if (sheetValuePairs[sheetId]) {
			test = apiFetch(`sheets/${sheetId}`).then((result) =>
				moment(result.edited).isAfter(sheets[sheetId].edited)
			);
		}

		return test
			.then(async (isNeeded) => {
				if (isNeeded && !isQueryInProgress(url)) {
					return apiFetch(url).then((data) => dispatch(receiveSheetValuePairs({ sheetId, pairs: data })));
				}
			})
			.catch((e) => null);
	};
}

export default sheetValuePairsSlice.reducer;
