import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const tokensSlice = createSlice({
	name: "tokens",
	// { gameId: []}
	initialState: {},
	reducers: {
		receiveTokens: (state, { payload: { gameId, tokens } }) => {
			state[gameId] = (state[gameId] || []).rg_overlapById(tokens);
		},
		removeToken: (state, { payload: token }) => {
			state[token.game] = state[token.game].filter((t) => t.id !== token.id);
		},
	},
});

export const { receiveTokens, removeToken } = tokensSlice.actions;

export function fetchTokens(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/tokens`).then(({ results }) => {
			dispatch(receiveTokens({ tokens: results, gameId }));
		});
	};
}
