import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { saveStore } from "../../tools/storeTools";

export const avatarsSlice = createSlice({
	name: "avatars",
	initialState: { unloaded: true },
	reducers: {
		receiveAvatars: (state, { payload: avatars }) => avatars.rg_toStore(),
		updateAvatar: (state, { payload: avatar }) => {
			state[avatar.id] = avatar;
		},
	},
});

export const { receiveAvatars, updateAvatar } = avatarsSlice.actions;

export function fetchAvatars() {
	return async (dispatch, getState) => {
		let avatars = await AsyncStorage.getItem("avatars");
		if (avatars) {
			avatars = JSON.parse(avatars);
			dispatch(receiveAvatars(avatars));
		}

		apiFetch("avatars")
			.then((response) => {
				dispatch(receiveAvatars(response));
				AsyncStorage.setItem("avatars", JSON.stringify(response));
				saveStore(getState());
			})
			.catch(() => null);
	};
}

export default avatarsSlice.reducer;
