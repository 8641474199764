import * as React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import AppScreenView from '../AppScreenView';
import InputField from '../generic/InputField';
import { _ } from '../../i18n/i18n';
import AppButton from '../generic/buttons/AppButton';
import ButtonsRow from '../generic/buttons/ButtonsRow';
import { useDispatch } from 'react-redux'
import { updateUserToken } from '../../store/slices/authtokenSlice';
import AppText from '../generic/AppText';
import CondView from '../meta/CondView';
import { apiFetch } from '../../network/apiFetch';
import SimpleCheckbox from '../generic/SimpleCheckbox';
import { fetchCurrentUser } from '../../store/slices/usersSlice';
import { isIos, ws } from '../../tools/generic';
import { fullScreenContentWidth } from '../../styles/dynamicStyles';
import { colors } from '../../styles/colors';

const styles = StyleSheet.create({
    screen:{
        flex: 1,
        justifyContent: "space-around",
    }
});

function LoginScreen() {

    const dispatch = useDispatch();

    const [logingIn, setLogingIn] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rememberMe, setRememberMe] = React.useState(true);
    const [error, setError] = React.useState(_(null));
    const [errorCode, setErrorCode] = React.useState(_(null));

    React.useEffect(() => {
        setLogingIn(false);
        setError(null);
        setErrorCode(null);
    }, [])


    const onSign = React.useCallback(async () => {
        setLogingIn(true);
        const { username: responseUsername, error_username, error_password } = await validateCredentials(username, password);

        if (error_username || error_password) {
            setErrorCode(error_username || error_password);
            setError(errorCodeToString(error_username || error_password));
            setLogingIn(false);
            return;
        }
        // If manual login (ie: just downloaded the app), force a refresh
        global.manualLogin = true;
        // If user used a email address to log in, use responseUsername
        await dispatch(updateUserToken(responseUsername || username, password, rememberMe));
        await dispatch(fetchCurrentUser());
    }, [username, password, rememberMe]);
    
    return (
        <AppScreenView style={[styles.screen, ws({alignItems: "center"})]}>
            <View style={ws({maxWidth: fullScreenContentWidth(), width: "100%"})}>
                <InputField label={_("Username")} value={username} onChangeText={setUsername}
                    textContentType="username" autoCompleteType="username" autoCapitalize="none" error={isUsernameError(errorCode)}/>
                <InputField label={_("Password")} value={password} onChangeText={setPassword}
                    textContentType="password" autoCompleteType="password" secureTextEntry autoCapitalize="none"
                    error={isPasswordError(errorCode)}
                />

                <CondView show={error} style={{ marginTop: 16, alignItems: "center" }}>
                    <AppText color="danger">{error}</AppText>
                </CondView>

                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 16 }}>
                    <SimpleCheckbox label={_("Remember me")} checked={rememberMe} setValue={setRememberMe} />
                    <AppText navigateTo="ForgotPassword">{_("Forgot password?")}</AppText>
                </View>

                <CondView show={logingIn} style={{ marginTop: 32 }}>
                <ActivityIndicator color={colors.hint}/>
                </CondView>
            </View>

            <ButtonsRow style={ws({maxWidth: fullScreenContentWidth(), width: "100%"})}>
                <AppButton title={_("Create account").toUpperCase()} hide={isIos()} centerButton={false} navigateTo="CreateAccount" disabled={logingIn} transparent />
                <AppButton title={_("Login").toUpperCase()} centerButton={false} disabled={logingIn || !username || !password} onPress={onSign} />
            </ButtonsRow>
        </AppScreenView>
    );
}

export default LoginScreen;

async function validateCredentials(username, password) {
    return await apiFetch(
        "account/validate-credentials/",
        "POST",
        { username, password },
        true
    );
}

export function isUsernameError(code){
    switch (code) {
        case "USERNAME_DONT_EXIST":
        case "INVALID_PAIR":
        case "USERNAME_TOO_SHORT":
        case "USERNAME_TAKEN":
        case "BLOCKED":
            return true;
        default:
            return false;
    }
    return false;
}
export function isEmailError(code){
    switch (code) {
        case "EMAIL_NO_VALID":
        case "EMAIL_DONT_EXIST":
        case "INVALID_PAIR":
        case "EMAIL_EXISTS":
        case "BLOCKED":
            return true;
        default:
            return false;
    }
}


export function isPasswordError(code){
    switch (code) {
        case "PASSWORD_TOO_SHORT":
        case "PASSWORD_NO_MATCH":
        case "INVALID_PAIR":
            return true;
        default:
            return false;
    }
}

export function isPassword2Error(code){
    
    switch (code) {
        case "PASSWORD_NO_MATCH":
            return true;
        default:
            return false;
    }
}

export function errorCodeToString(code) {
    switch (code) {
        case "USERNAME_DONT_EXIST":
            return _("Username doesn't exist");
        case "EMAIL_NO_VALID":
            return _("Invalid email address");
        case "EMAIL_DONT_EXIST":
            return _("No account with this email address");
        case "INVALID_PAIR":
            return _("Invalid username/password pair");
        case "NO_PASSWORD":
            return _("Please type your password");
        case "EMAIL_EXISTS":
            return _("This email address is already registered");
        case "PASSWORD_TOO_SHORT":
            return _("Password too short");
        case "PASSWORD_NO_MATCH":
            return _("Passwords do not match");
        case "USERNAME_TOO_SHORT":
            return _("Please use at least 3 characters for your username");
        case "USERNAME_TAKEN":
            return _("This username is taken");
        case "BLOCKED":
            return _("This account has been blocked");
        case "FORBIDDEN_@":
            return _("The use of @ in your username is forbidden");
        default:
            return _("An error has occurred: %(error)s", null, {error: code});
    }
};