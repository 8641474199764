import { Platform, Dimensions } from "react-native";

export function idEqual(objA, objB) {
	if ((!objA && objB) || (objA && !objB)) {
		return false;
	}

	if (!objA && !objB) {
		return true;
	}
	const idA = objA.id || null;
	const idB = objB.id || null;

	return idA === idB;
}

export const numberToRoman = (number) => {
	if (number > 10 && number < 20) {
		number -= 10;
		return "X" + numberToRoman(number);
	}
	if (number >= 20 && number < 30) {
		number -= 20;
		return "XX" + numberToRoman(number);
	}
	if (number >= 30 && number < 40) {
		number -= 30;
		return "XXX" + numberToRoman(number);
	}
	if (number >= 40 && number < 50) {
		number -= 40;
		return "XL" + numberToRoman(number);
	}
	if (number >= 50 && number < 60) {
		number -= 50;
		return "L" + numberToRoman(number);
	}

	switch (number) {
		case 0:
			return "";
		case 1:
			return "I";
		case 2:
			return "II";
		case 3:
			return "III";
		case 4:
			return "IV";
		case 5:
			return "V";
		case 6:
			return "VI";
		case 7:
			return "VII";
		case 8:
			return "VIII";
		case 9:
			return "IX";
		case 10:
			return "X";
		default:
			return number;
	}
};

export function isIos() {
	return Platform.OS === "ios";
}

export function isWeb() {
	return Platform.OS === "web";
}

export function isAndroid() {
	return Platform.OS === "android";
}

export function isWide() {
	const windowDim = Dimensions.get("window");
	return windowDim.width > 1200;
	// return windowDim.width > 768;
}

export function approximateValue(value, approximateFrom = 1000) {
	if (value < approximateFrom) {
		return value;
	}

	if (value > 1000) {
		value = Math.floor(value / 1000) + "K+";
	} else if (value > 100) {
		value = Math.floor(value / 100) * 100 + "+";
	} else if (value > 10) {
		value = Math.floor(value / 10) * 10 + "+";
	}
}

export function optimalColumnNum(numberOfElementsShown, maxColumns, minColumns = 2) {
	if (maxColumns < minColumns) maxColumns = minColumns;
	let numColumns = maxColumns;
	let highestRest = 0;
	for (let i = maxColumns; i > minColumns; i--) {
		const rest = numberOfElementsShown % i;
		if (rest === 0) {
			break;
		}
		if (rest > highestRest) {
			highestRest = rest;
			numColumns = i;
		}
	}
	return numColumns;
}

export function idKeyExtractor(item, index) {
	return String(item?.id) || `no_id_${index}`;
}

export function Clamp(number, minIncluded, maxIncluded) {
	return Math.min(maxIncluded, Math.max(minIncluded, number));
}

// Because !value will return true if value is 0. This check if the value is defined at all;
export function hasValue(value) {
	return value !== null && value !== undefined;
}

export function enumerate(array) {
	return array.reduce((acc, cur, idx, src) => {
		if (idx > 0 && idx == src.length - 1) acc += " & ";
		else if (idx > 0) acc += ", ";
		acc += cur;
		return acc;
	}, "");
}

// Web Switcher. Just a shorter method to save some space in code, as we often do a select on web vs native
export function ws(onWebValue, onNativeValue) {
	return Platform.select({
		web: onWebValue,
		native: onNativeValue,
	});
}
