import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { _ } from '../../i18n/i18n';
import AppText from '../generic/AppText';

function PartyMergedIndicator() {

    return (
        <AppText color="secondary" style={{marginVertical: 16}}>{_("All characters are now together")}</AppText>
    );
}

export default PartyMergedIndicator;

const styles = StyleSheet.create({
    container:{
        
    }
});