import * as React from "react";
import { View, StyleSheet } from "react-native";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import { connect } from "react-redux";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import DiceSetItem from "../../dicesets/DiceSetItem";
import IconButton from "../../generic/buttons/IconButton";
import { FontAwesome } from "@expo/vector-icons";
import { colors } from "../../../styles/colors";
import { useNavigation, useRoute } from "@react-navigation/native";
import { isGM } from "../../../tools/games";
import { apiFetch } from "../../../network/apiFetch";
import { addDiceSetToGame, removeDiceSetFromGame } from "../../../store/slices/dicesetsSlice";
import { ws } from "../../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";

function GameSettingDiceSets({ game, style, dispatch, diceSets }) {
	
	style = style || globalStylesMethod(global.theme).floatingSection;

	const navigation = useNavigation();
	const route = useRoute();

	const selectedDiceSet = route.params?.selectedDiceSet;

	React.useEffect(() => {
		if (!selectedDiceSet) return () => null;
		navigation.setParams({ selectedDiceSet: null });

		dispatch(addDiceSetToGame({ gameId: game.id, diceSet: selectedDiceSet }));
		apiFetch(`games/${game.id}/dice-sets/${selectedDiceSet.id}/add`, "POST");
	}, [selectedDiceSet, game.id]);

	const remove = React.useCallback(
		(diceSet) => {
			dispatch(removeDiceSetFromGame({ gameId: game.id, diceSet }));
			apiFetch(`games/${game.id}/dice-sets/${diceSet.id}/remove`, "POST");
		},
		[game]
	);

	const canEdit = isGM(game) && !game.archived;

	return (
		<View style={[style, ws({ alignItems: "center"  })]}>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<View style={globalStyles.rcsb}>
					<AppText color="primary">{_("Dice sets", "list game's dice sets")}</AppText>
					<IconButton
						hide={!canEdit}
						style={{ height: 24 }}
						transparent
						icon={{ type: FontAwesome, name: "plus", color: global.colors.primary, size: 24 }}
						onPress={() =>
							navigation.navigate("DiceSetStack", {
								screen: "BrowseDiceSetsScreen",
								params: { selectingSetFor: route.name },
							})
						}
					/>
				</View>
				{diceSets.map((item) => (
					<DiceSetItem
						key={item.id}
						diceSet={item}
						onPress={() =>
							navigation.navigate("DiceSetStack", {
								screen: "EditDiceSetScreen",
								params: { diceSet: item },
							})
						}
						options={
							canEdit
								? [
										{
											title: _("Remove from game", "remove dice set from game"),
											onPress: () => remove(item),
										},
								  ]
								: []
						}
					/>
				))}

				<AppText hide={diceSets.length} color="hint" italic centered style={{ marginVertical: 8 }}>
					{_("This game uses no dice.")}
				</AppText>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		diceSets: state.diceSets[ownProps.game.id] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(GameSettingDiceSets);


