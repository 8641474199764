import { Entypo } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { isGM } from "../../tools/games";
import CharacterAvatar from "../avatar/CharacterAvatar";
import { SELECT_CHARACTER_CHANGE_ACTIVE } from "../characters/FilteredCharacterList";
import IconButton from "../generic/buttons/IconButton";
import UserAvatar from "../users/UserAvatar";

function CurrentCharacterButton({ messageType, show, style, size=24, dispatch, user, characters, currentCharacter, gameId }) {
	const route = useRoute();

	const gm = isGM(gameId);
	const navigation = useNavigation();

	const onAvatarPressed = React.useCallback(() => {
		let charactersAvailable = null;
		if (!gm) {
			charactersAvailable = characters.filter((c) => !c.deleted && c.player?.id === user?.id);
		}

		if (gm || charactersAvailable?.length > 1) {
			navigation.navigate("QuickCharacterSearchScreen", {
				sendToScreen: route.name,
				charactersAvailable,
				showNarrator: gm,
				code: SELECT_CHARACTER_CHANGE_ACTIVE,
			});
		} else {
			navigation.navigate("CharacterOptionsModal", { characterId: currentCharacter.id });
		}
	}, [characters, currentCharacter?.id, user?.id, gm, route.name, navigation]);

	if (!show) return null;

	return (
		<View style={style}>
			{currentCharacter && messageType !== "chat" && (
				<CharacterAvatar character={currentCharacter} size={size} onPress={onAvatarPressed} />
			)}
			{gm && !currentCharacter && messageType !== "chat" && (
				<IconButton
					transparent
					style={{ height: size, width: size }}
					icon={{
						type: Entypo,
						name: "open-book",
						size: size,
						color: global.colors.textLight,
					}}
					onPress={onAvatarPressed}
				/>
			)}
			{messageType === "chat" && <UserAvatar user={user} size={size} />}
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		currentCharacter: getCurrentCharacter(state),
		user: state.user,
		characters: state.charactersByGame[gameId],
		gameId,
	};
};

export default connect(mapStateToProps)(CurrentCharacterButton);

const styles = StyleSheet.create({
	container: {},
});
