import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import CharacterAvatar from "../avatar/CharacterAvatar";
import AppText from "../generic/AppText";
import { getCharName } from "../../tools/characters";
import { colors } from "../../styles/colors";
import { cStyle } from "../../tools/react";
import { ws } from "../../tools/generic";
import { useHover } from "react-native-web-hooks";

import { BuildStyleMethod } from "../../styles/theming";

function CharacterIconButton({ character, onPress, style, selected }) {
	const styles = stylesMethod(global.theme);
	const ContainerTag = onPress ? TouchableOpacity : View;

	const size = ws(24, 48);

	const ref = React.useRef();
	const isHovered = useHover(ref);

	return (
		<ContainerTag
			ref={ref}
			style={[styles.container, isHovered && styles.hovered, selected && styles.selected, style]}
			onPress={() => onPress && onPress(character)}
		>
			<View style={{ height: size }}>
				<CharacterAvatar character={character} size={size} />
			</View>
			<AppText
				size="small"
				numberOfLines={2}
				ellipsizeMode="tail"
				style={{ width: ws(null, 52) }}
				centered
				textBreakStrategy="highQuality"
			>
				{getCharName(character)}
			</AppText>
		</ContainerTag>
	);
}

export default CharacterIconButton;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		borderWidth: 1,
		paddingHorizontal: 2,
		borderColor: "transparent",
		borderRadius: 6,
		...ws({ alignItems: "center", padding: 8 }),
	},
	selected: {
		borderColor: colors.primary,
	},
	hovered: {
		borderColor: colors.lightBorder,
		backgroundColor: colors.hoverItemLightBackground
	}
}));
