import * as React from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { arrow } from "../../../styles/arrows";
import { colors } from "../../../styles/colors";
import CondView from "../../meta/CondView";

import { BuildStyleMethod } from "../../../styles/theming";

function InternalPopoverSimple({ popoverComponent, targetMeasure, arrowPosition, visible, style }) {
	const styles = stylesMethod(global.theme);
	const [popoverMeasure, setpopoverMeasure] = React.useState(null);

	const popoverRef = React.useRef();

	const savePopoverMeasure = React.useCallback((x, y, width, height) => {
		setpopoverMeasure({ x, y, width, height });
	}, []);

	const getTopPosition = React.useCallback(
		(windowDim) => {
			switch (arrowPosition) {
				case "top":
					return targetMeasure.height;
				case "left":
				case "right":
					return (targetMeasure?.height - popoverMeasure?.height) * 0.5 || 0;
				default:
					return undefined;
			}
		},
		[targetMeasure, arrowPosition, popoverMeasure]
	);
	const getBottomPosition = React.useCallback(
		(windowDim) => {
			switch (arrowPosition) {
				case "bottom":
					return targetMeasure.height;
				default:
					return undefined;
			}
		},
		[targetMeasure]
	);

	const getLeftPosition = React.useCallback(
		(windowDim) => {
			switch (arrowPosition) {
				case "top":
				case "bottom":
					return (targetMeasure?.width - popoverMeasure?.width) * 0.5 || 0;
				case "left":
					return targetMeasure?.width + 10;
				default:
					return undefined;
			}
		},
		[targetMeasure, arrowPosition, popoverMeasure]
	);

	const getRightPosition = React.useCallback(
		(windowDim) => {
			switch (arrowPosition) {
				case "right":
					return targetMeasure?.width + 10;
				default:
					return undefined;
			}
		},
		[targetMeasure, arrowPosition, popoverMeasure]
	);

	if (!visible) return false;

	const windowDim = Dimensions.get("window");

	let topPosition = getTopPosition(windowDim);
	let leftPosition = getLeftPosition(windowDim);
	let bottomPosition = getBottomPosition(windowDim);
	let rightPosition = getRightPosition(windowDim);

	// let positionStyle = { top: topPosition, left: leftPosition };
	// if (arrowPosition === "bottom") {
	// 	positionStyle = { bottom: bottomPosition, left: leftPosition };
	// 	positionStyle = { bottom: bottomPosition, left: leftPosition };
	// }

	let positionStyle = { top: topPosition, right: rightPosition, bottom: bottomPosition, left: leftPosition };

	return (
		<View
			ref={popoverRef}
			style={[
				styles.popoverContainer,
				positionStyle,
				{
					minWidth: 300,
					flexGrow: 1,
					flexShrink: 0,
					flexBasis: "auto",
					pointerEvents: "none",
				},
				popoverMeasure ? null : { opacity: "0" },
				style,
			]}
			onLayout={() => {
				if (popoverRef.current) {
					popoverRef.current.measureInWindow(savePopoverMeasure);
				}
			}}
		>
			<CondView
				show={!!arrowPosition}
				style={arrow(arrowPosition, { color: global.colors.secondary }, popoverMeasure)}
			/>
			{popoverComponent}
		</View>
	);
}

export default InternalPopoverSimple;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	popoverContainer: {
		position: "absolute",
		backgroundColor: colors.cardBackground,
		shadowColor: "black",
		shadowOffset: { width: 1, height: 1 },
		shadowOpacity: 1,
		shadowRadius: 1,
		elevation: 10,
	},
}));
