import * as React from "react";
import { StyleSheet } from "react-native";
import { isMaxOnRoll, isMinOnRoll, resultsAreEqual } from "../../tools/diceRoll";
import AppText from "../generic/AppText";
import DieFaceSymbol from "../lines/DieFaceSymbol";

function DiceRollCalculation({ roll, cancelledResults }) {
	let results = roll.results;
	if (typeof roll.results === "string") {
		results = JSON.parse(roll.results);
	}

    let calcLine = [];

	for (let i = 0; i < results.length; ++i) {
		let result = results[i];

		if (i > 0) {
			calcLine.push(<AppText size="small" key={i + "+"} color="hint">{"\u2022"}</AppText>);
		}

		if (isMaxOnRoll(result, roll)) {
			calcLine.push(
				<AppText size="small" key={i} color="success" bold>
					{result}
				</AppText>
			);
		} else if (isMinOnRoll(result, roll)) {
			calcLine.push(
				<AppText size="small" key={i} color="failure" bold>
					{result}
				</AppText>
			);
		} else {
			if (typeof result === "object") {
				let cancelled = false;
				for (let cr = 0; cr < cancelledResults.length; ++cr) {
					let resToCancel = cancelledResults[cr];
					if (resultsAreEqual(result, resToCancel)) {
						cancelled = true;
						cancelledResults.splice(cr, 1);
						break;
					}
				}

				calcLine.push(<DieFaceSymbol key={i} symbol={result} cancelled={cancelled} usedInText size={12} />);
			} else {
				calcLine.push(
					<AppText size="small" key={i} color="primary">
						{result}
					</AppText>
				);
			}
		}
	}

	if (roll.modifier) {
		const modLine = (
			<AppText key="mod" color="hint"> {(roll.modifier >= 0 ? "+" : "-") + Math.abs(roll.modifier)}</AppText>
		);
		calcLine.push(modLine);
	}

	return calcLine;
}

export default DiceRollCalculation;


