import * as React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { colors } from "../../styles/colors";
import HelpButton from "../tutorials/HelpButton";
import AppIcon from "./AppIcon";
import AppText from "./AppText";
import AppButton from "./buttons/AppButton";

function EmptyScreenView({ messageTop, messageBottom, icon, options, helpButtonProps, loading, style }) {
	if (loading) {
		return (
			<View style={[styles.container, style]}>
				<ActivityIndicator color={global.colors.hint}/>
			</View>
		);
	}
	return (
		<View style={[styles.container, style]}>
			<AppText centered color="hint">
				{messageTop}
			</AppText>
			{icon && <AppIcon color={global.colors.primary} size={128} style={styles.iconStyle} {...icon} />}
			{helpButtonProps && <HelpButton {...helpButtonProps} style={styles.iconStyle}/>}
			<AppText centered color="hint">
				{messageBottom}
			</AppText>
			{options && options.map((opt, index) => <AppButton key={index} {...opt} />)}
		</View>
	);
}

export default EmptyScreenView;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		paddingBottom: 256,
	},
	iconStyle: {
		marginVertical: 32,
	},
});
