import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchCurrentUser } from "../../store/slices/usersSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { BuildStyleMethod } from "../../styles/theming";
import { idKeyExtractor, ws } from "../../tools/generic";
import { simpleReducer, usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import GameSettingsPace from "../games/settings/GameSettingsPace";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ListSwitch from "../generic/ListSwitch";
import TagItem from "../tags/TagItem";


function AppGameSettingsScreen({ route, navigation, dispatch, user }) {
	const styles = stylesMethod(global.theme);
	const [profile, updateProfile] = React.useReducer(simpleReducer, user.profile);
	const prevProfile = usePrevious(profile);

	React.useEffect(() => {
		updateProfile(user.profile);
	}, [user.profile]);

	React.useEffect(() => {
		if (!prevProfile) return () => null;
		apiFetch(`profiles/${profile.id}`, "PATCH", {
			is_beginner: profile.is_beginner,
			preferred_pace: profile.preferred_pace,
			ask_auto_split: profile.ask_auto_split,
		});
	}, [prevProfile, profile.id, profile.is_beginner, profile.preferred_pace, profile.ask_auto_split]);

	React.useEffect(() => {
		if (!prevProfile) return () => null;
		apiFetch(`profiles/${profile.id}/add`, "POST", {
			tags: route.params?.selectedTags || [],
		}).then(() => dispatch(fetchCurrentUser()));
	}, [profile.id, route.params?.selectedTags]);

	const { onLayout } = useLayout();

	return (
		<AppScreenView scroll style={styles.container} borderless borderTop onLayout={onLayout}>
			<View style={[globalStylesMethod(global.theme).floatingSection, ws({ paddingLeft: fullScreenLeftPadding() })]}>
				<View style={ws({ maxWidth: fullScreenContentWidth() })}>
					<ListSwitch
						title={_("I am a beginner", "profile settings")}
						isEnabled={profile.is_beginner}
						setIsEnabled={(is_beginner) => updateProfile({ is_beginner })}
					/>
				</View>
			</View>

			<GameSettingsPace
			centered={false}
				title={_("Preferred pace", "profile settings")}
				game={{ expected_pace: profile.preferred_pace }}
				onChangePace={(preferred_pace) => updateProfile({ preferred_pace })}
				style={ws({ paddingLeft: fullScreenLeftPadding() })}
			/>

			<View
				style={[
					globalStylesMethod(global.theme).floatingSection,
					ws({ paddingLeft: fullScreenLeftPadding() }),
					{ paddingBottom: 8 },
				]}
			>
				<View style={ws({ maxWidth: fullScreenContentWidth() })}>
					<AppText style={{ marginBottom: 8 }}>
						{_("I am interested in games with the following tags", "profile settings")}
					</AppText>
					<FlatList
						horizontal
						data={profile.searching_tags}
						keyExtractor={idKeyExtractor}
						renderItem={({ item }) => <TagItem tag={item} />}
						ItemSeparatorComponent={() => <View style={{ marginRight: 4 }} />}
						ListEmptyComponent={
							<AppText italic color="secondary">
								{_("None", "no 'interested in' tag selected")}
							</AppText>
						}
					/>
					<AppText style={{ marginVertical: 8 }} color="hint">
						{_(
							"These will change the games shown on the Explore screen, as well as the email notifications about new games getting started.",
							"explains the 'interested in' tags on the profile settings page"
						)}
					</AppText>
					<AppButton
						title={_("Edit tags", "profile settings")}
						onPress={() =>
							navigation.navigate("SelectGameTagsScreen", {
								activeTags: profile.searching_tags,
								sendToScreen: route.name,
							})
						}
					/>
				</View>
			</View>

			<View style={[globalStylesMethod(global.theme).floatingSection, ws({ paddingLeft: fullScreenLeftPadding() })]}>
				<View style={ws({ maxWidth: fullScreenContentWidth() })}>
					<ListSwitch
						title={_("Prompt to automatically split my text", "profile settings")}
						isEnabled={profile.ask_auto_split}
						setIsEnabled={(ask_auto_split) => updateProfile({ ask_auto_split })}
					/>
					<AppText color="hint">
						{_("Writing a blank line or using quotes can trigger auto message splitting.")}
					</AppText>
					<AppText color="hint" style={{ marginTop: 8 }}>
						{_("Blank lines start a new message, text between quotes becomes dialogue.")}
					</AppText>
				</View>
			</View>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(AppGameSettingsScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.backgroundSecondary,
	},
}));
