import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colors } from "../../styles/colors";
import { cStyle } from "../../tools/react";
import AppText from "../generic/AppText";
import CoinIcon from "./CoinIcon";

function CoinCounter({ level, amount, onCoinPressed, disabled, awarded, small }) {
	const style = [styles.counter];

	const ContainerTag = onCoinPressed ? TouchableOpacity : View;

	disabled = disabled || awarded;

	return (
		<ContainerTag style={style} onPress={() => onCoinPressed(level)} activeOpacity={0.6} disabled={disabled}>
			<View>
				<CoinIcon level={level} size={small ? 18 : 24} style={cStyle([[!!disabled, styles.disabled]])} />
				{awarded && (
					<MaterialIcons name="check-circle" size={12} style={styles.checkmark} color={global.colors.primary} />
				)}
			</View>
			<AppText color="secondary" size="small">
				{amount || "-"}
			</AppText>
		</ContainerTag>
	);
}

export default CoinCounter;

const styles = StyleSheet.create({
	counter: {
		alignItems: "center",
		flex: 1,
	},
	disabled: {
		opacity: 0.2,
	},
	checkmark: {
		position: "absolute",
		bottom: 0,
		right: 0,
	},
});
