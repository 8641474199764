import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { BuildStyleMethod } from "../../styles/theming";
import { ws } from "../../tools/generic";
import { getMaxToken, getUsageLeft } from "../../tools/oaiTokens";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppText from "../generic/AppText";
import CondView from "../meta/CondView";

function OAITokenBar({ projectedUsage, style, estimatingCost, navigation, closeModal, dispatch, user }) {
	const styles = stylesMethod(global.theme);

	const maxTokens = getMaxToken(user);
	const percentage = user.profile.openai_token_used / maxTokens;

	const projectedPercentage = projectedUsage / maxTokens;

	const borderRadius = 12;

	const usageLeft = getUsageLeft(user);
	const cannotAfford = usageLeft < projectedUsage;

	const projectedFlex = Math.min(projectedPercentage, 1 - percentage);

	return (
		<View style={[styles.container, style]}>
			<AppText bold>{_("AI tokens usage", "ai tokens")} </AppText>

			<View style={{ flexDirection: "row", marginVertical: 8 }}>
				<View style={[styles.tokenCounter, !projectedUsage && { opacity: 0.5 }]}>
					<AppText color="secondary">
						{ws(_("Estimated cost", "ai tokens"), _("Cost (est.)", "ai tokens estimated cost"))}
					</AppText>
					<AppText hide={estimatingCost} placeholder="-" color={cannotAfford ? "danger" : "dangerLight"}>
						≈{projectedUsage || ""}
					</AppText>
					<AppActivityIndicator show={estimatingCost} />
				</View>

				<View style={styles.tokenCounter}>
					<AppText color="secondary">{_("Used", "ai tokens")}</AppText>
					<AppText color="primaryLight">{user.profile.openai_token_used}</AppText>
				</View>

				<TouchableOpacity
					style={[styles.tokenCounter, styles.tokenCounterLast]}
					onPress={() => {
						closeModal && closeModal();
						navigation.navigate("UpgradeAccountScreen");
					}}
				>
					<AppText color="secondary">{_("Granted", "ai tokens")}</AppText>
					<AppText>{maxTokens}</AppText>
				</TouchableOpacity>
			</View>

			<View style={{ flexDirection: "row", alignItems: "center" }}>
				<View
					style={{
						flexDirection: "row",
						width: ws("100%", 300),
						height: 24,
						backgroundColor: global.colors.textDark,
						borderRadius,
					}}
				>
					<View
						style={{
							flex: percentage,
							backgroundColor: global.colors.primaryLight,
							borderTopLeftRadius: borderRadius,
							borderBottomLeftRadius: borderRadius,
						}}
					/>
					<CondView
						show={projectedUsage}
						style={{
							flex: projectedFlex,
							backgroundColor: cannotAfford ? global.colors.danger : global.colors.dangerLight,
							borderTopRightRadius: borderRadius,
							borderBottomRightRadius: borderRadius,
						}}
					/>
				</View>
			</View>

			<AppText hide={!projectedUsage} color="hint" size="small" style={{ marginTop: 8 }}>
				*
				{_(
					"The estimated cost is an approximation of the number of tokens that will be used to generate content.",
					"ai tokens"
				)}
			</AppText>

			{user.profile.reward_level === 0 && (
				<AppText color="secondary" style={{ marginTop: 8 }}>
					{_("Upgrade your account to be granted monthly tokens to spend on AI generation.")}
				</AppText>
			)}
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(OAITokenBar);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.translucidBackground,
			padding: 16,
		},
		tokenCounter: {
			flex: 1,
			marginRight: 8,
			paddingRight: 8,
			borderRightWidth: 1,
			borderRightColor: colors.lightBorder,
			alignItems: "center",
		},
		tokenCounterLast: {
			marginRight: 0,
			borderRightColor: "transparent",
		},
	})
);
