import * as React from "react";
import { FlatList, ActivityIndicator, View } from "react-native";
import { connect } from "react-redux";
import StageBackgroundItem from "./StageBackgroundItem";
import { fetchStageBackgrounds } from "../../store/slices/stagebackgroundsSlice";
import CoinIcon from "../coins/CoinIcon";
import AppText from "../generic/AppText";
import { levelToRankName } from "../../tools/account";

function RankHeader({ level }) {
	return (
		<View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: 8, marginBottom: 16 }}>
			<CoinIcon level={level} size={24} style={{ marginRight: 16 }} />
			<AppText>{levelToRankName(level)}</AppText>
		</View>
	);
}

function StageBackgroundSelectionScreen({ previousScreenName, route, navigation, stageBackgrounds, loading, dispatch }) {
	React.useEffect(() => {
		if (loading) {
			dispatch(fetchStageBackgrounds());
		}
	}, [loading]);

	const onSelected = (background) => {
		navigation.navigate(previousScreenName, { backgroundSelected: background.id });
	};

	const renderItem = ({ item }) => <StageBackgroundItem background={item} onSelected={onSelected}/>;

	return (
		<FlatList
			data={stageBackgrounds}
			ListEmptyComponent={ActivityIndicator}
			keyExtractor={(item) => String(item.id)}
			renderItem={renderItem}
			ListHeaderComponent={() => <RankHeader level={3} />}
			ItemSeparatorComponent={({ leadingItem }) => {
				const index = stageBackgrounds.indexOf(leadingItem);
				const next = stageBackgrounds.rg_next(index);
				if (next && next.rank !== leadingItem.rank) {
					return <RankHeader level={next.rank} />;
				}
				return null;
			}}
			style={{ paddingTop: 16 }}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		stageBackgrounds: state.stageBackgrounds,
		loading: !state.stageBackgrounds.length,
	};
};

export default connect(mapStateToProps)(StageBackgroundSelectionScreen);
