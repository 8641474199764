import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { View } from "react-native";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { ws } from "../../tools/generic";
import IconButton from "../generic/buttons/IconButton";
import BoxModal from "../generic/modal/BoxModal";
class HelpButton extends React.PureComponent {
	render() {
		const { title, message, style, transparent } = this.props;
		return (
			<View style={style}>
				<BoxModal
					ref="ModalRef"
					title={title}
					message={message}
					options={[{ title: _("Got it!", "close info box") }]}
					wide
				/>
				<IconButton
					icon={{
						type: MaterialCommunityIcons,
						name: "help",
						color: transparent ? global.colors.textDefault : global.colors.textLight,
						size: ws(18, 24)
					}}
					onPress={() => {
						this.refs.ModalRef && this.refs.ModalRef.show();
					}}
					transparent={transparent}
				/>
			</View>
		);
	}
}

export default HelpButton;
