import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import ModalScreen from "../generic/modal/ModalScreen";
import { removeToken, receiveTokens } from "../../store/slices/tokensSlice";

function TokenOptionsModal({ route, navigation }) {
	const { token, line } = route.params;

	const dispatch = useDispatch();

	const removeTokenFrom = React.useCallback(() => {
		apiFetch(`games/${token.game}/tokens/${token.id}/remove`, "POST", { current_line: line?.id || 0 }).then(() => {
			navigation.navigate("ManageTokensScreen", { selectedToken: null });
			const editedToken = { ...token, remove_line_id: line?.id || 0 };
			dispatch(receiveTokens({ gameId: token.game, tokens: [editedToken] }));
		});
	}, [token, line]);

	const deleteToken = React.useCallback(() => {
		apiFetch(`games/${token.game}/tokens/${token.id}`, "DELETE").then(() => {
			navigation.navigate("ManageTokensScreen", { selectedToken: null });
			dispatch(removeToken(token));
		});
	}, [token]);

	const hideToken = React.useCallback(
		(flag) => {
			const editedToken = { ...token, creator_see_only: flag };
			dispatch(receiveTokens({ gameId: token.game, tokens: [editedToken] }));
			apiFetch(`games/${token.game}/tokens/${token.id}`, "PATCH", editedToken);
			navigation.navigate("ManageTokensScreen", { selectedToken: editedToken });
		},
		[token]
	);

	const options = [];
	options.push({
		title: _("Remove", "remove token"),
		info: _("The token won't appear from now on"),
		icon: { type: FontAwesome, name: "remove" },
		subicon: { type: MaterialIcons, name: "arrow-downward" },
		onPress: removeTokenFrom,
	});
	!token.creator_see_only &&
		options.push({
			title: _("Hide", "hide token"),
			info: _("Make this token only visible to you"),
			icon: { type: MaterialCommunityIcons, name: "eye-off" },
			onPress: () => hideToken(true),
		});
	token.creator_see_only &&
		options.push({
			title: _("Show", "un-hide token"),
			info: _("Un-hide this token for everyone to see"),
			icon: { type: MaterialCommunityIcons, name: "eye" },
			onPress: () => hideToken(false),
		});
	options.push({
		title: _("Delete", "delete token"),
		info: _("Remove the token form this line and the previous ones", "delete token"),
		icon: { type: MaterialIcons, name: "delete" },
		danger: true,
		onPress: deleteToken,
	});

	return <ModalScreen options={options} />;
}

export default TokenOptionsModal;


