import * as React from "react";
import {SafeAreaView, StyleSheet, TextInput, TouchableOpacity, View} from "react-native";
import {FlatList} from "react-native";
import {Divider} from "react-native-paper";
import {connect} from "react-redux";
import {setTone, setToneColor} from "../../store/slices/gamesUISlice";
import {saveLineEdit} from "../../store/slices/linesSlice";
import {fullScreenContentWidth, fullScreenLeftPadding} from "../../styles/dynamicStyles";
import {ws} from "../../tools/generic";
import {translatedTones, translateTone} from "../../tools/tones";
import AppScreenView from "../AppScreenView";
import ToneButton from "./ToneButton";
import IconButton from "../generic/buttons/IconButton";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import BoxModal from "../generic/modal/BoxModal";
import {_} from "../../i18n/i18n";
import {colors} from "../../styles/colors";
import {sizes} from "../../styles/font";
import AppText from "../generic/AppText";

function ToneSelectionScreen({navigation, route, dispatch, gameId}) {
    const [tones] = React.useState(translatedTones());
    const [search, setSearch] = React.useState("");
    const [customTone, setCustomTone] = React.useState('');
    const [customToneColor, setCustomToneColor] = React.useState('');

    const {editingLine, backToScreen} = route.params ? route.params : {};

    const onToneSelected = React.useCallback(
        (tone) => {
            if (editingLine) {
                dispatch(saveLineEdit(editingLine, {tone}));
            } else {
                dispatch(setTone({gameId, value: tone}));
            }

            if (backToScreen) {
                navigation.navigate(backToScreen);
            } else {
                navigation.goBack();
            }
        },
        [dispatch, navigation, editingLine, backToScreen]
    );
    const onToneColorSelected = React.useCallback(
        (toneColor) => {
            if (editingLine) {
                dispatch(saveLineEdit(editingLine, {toneColorOverride: toneColor}));
            } else {
                dispatch(setToneColor({gameId, value: toneColor}));
            }
            setCustomToneColor(toneColor);
        },
        [dispatch, editingLine]
    );

    const updateSearch = (text) => {
        setCustomTone(text);
    };

    let tonesDisplayed = tones;
    if (!!customTone) {
        tonesDisplayed = tonesDisplayed.filter((t) => t.name.toLowerCase().includes(customTone.toLowerCase()));
    }

    const customToneStyle = {
        ...styles.input,
        color: !!customTone ? colors.textDefault : colors.placeholder,
        fontSize: sizes.average,
        flexGrow: 1,
        ...ws({outlineWidth: 0}, null)
    };

    const customToneContainerStyles = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.inputFieldBackground,
        paddingHorizontal: 12,
        paddingVertical: 4,
        borderRadius: 16,
        gap: 4
    };

    const colorBoxStyle = {
        height: 32,
        width: 32,
        borderColor: '#FF0000',
        borderStyle: 'solid'
    };

    return (
        <AppScreenView borderless style={[styles.container, ws({
            paddingLeft: fullScreenLeftPadding(),
            width: fullScreenContentWidth()
        })]}>
            <View style={customToneContainerStyles}>
                <TextInput placeholder={_("Custom Tone")}
                           style={customToneStyle}
                           autoFocus={true}
                           onChangeText={updateSearch}
                           value={customTone}
                           onSubmitEditing={() => onToneSelected(customTone)}
                />
                <IconButton
                    disabled={!customTone}
                    onPress={() => onToneSelected(customTone)}
                    icon={{
                        type: MaterialCommunityIcons,
                        size: 22,
                        name: "chevron-right",
                        color: global.colors.textDefault
                    }}
                />
            </View>
            <View style={{
                flexDirection: "row",
                flexWrap: "wrap",
            }}>
                {global.colors.userColors.map((item, index) => (
                    <TouchableOpacity
                        key={item}
                        style={{...colorBoxStyle, backgroundColor: item,
                            borderWidth: (item === customToneColor) ? 2 : 0
                        }}
                        onPress={() => {
                            onToneColorSelected(item);
                        }}
                    />
                ))}
            </View>

            <SafeAreaView style={{flex: 1}}>
                <FlatList
                    ListHeaderComponent={() => {
                        if (search && !translateTone("neutral").toLowerCase().includes(search.toLowerCase())) {
                            return null;
                        }
                        return (
                            <>
                                <ToneButton
                                    tone={{code: "neutral", name: translateTone("neutral")}}
                                    onToneSelected={onToneSelected}
                                />
                                {!!!search && <Divider/>}
                            </>
                        );
                    }}
                    data={tonesDisplayed}
                    renderItem={({item}) => <ToneButton tone={item} onToneSelected={onToneSelected}/>}
                    keyExtractor={(item, index) => String(index)}
                    initialNumToRender={50}
                />
            </SafeAreaView>
        </AppScreenView>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        gameId: state.games.currentId,
    };
};

export default connect(mapStateToProps)(ToneSelectionScreen);

const styles = StyleSheet.create({
    container: {
        paddingTop: 8,
    },
});
