import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { modalStackNavigatorScreenOptions } from "../../tools/navigationOptions";
import AssignCharacterModal from "./AssignCharacterModal";
import CharacterTagsModal from "./CharacterTagsModal";
import SelectSingleTagModal from "../tags/SelectSingleTagModal";
import ManageTagVisibilityModal from "../tags/ManageTagVisibilityModal";

const Stack = createStackNavigator();

function CharactersScreenModalStack() {
	return (
		<Stack.Navigator screenOptions={modalStackNavigatorScreenOptions}>
			<Stack.Screen name="AssignCharacterModal" component={AssignCharacterModal} />
			<Stack.Screen name="CharacterTagsModal" component={CharacterTagsModal} />
			<Stack.Screen name="SelectSingleTagModal" component={SelectSingleTagModal} />
			<Stack.Screen name="ManageTagVisibilityModal" component={ManageTagVisibilityModal} />
		</Stack.Navigator>
	);
}

export default CharactersScreenModalStack;
