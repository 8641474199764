import * as React from "react";
import { View, StyleSheet, FlatList, useWindowDimensions } from "react-native";
import ModalScreen from "../generic/modal/ModalScreen";
import CharacterItem from "./CharacterItem";
import { idEqual, ws } from "../../tools/generic";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import { useDimensions } from "react-native-web-hooks";
import { filterByVisibility } from "../../tools/characters";

export const SELECT_CHARACTERS_WHISPER = "whisper_targets";
export const SELECT_CHARACTERS_REQUEST_MACRO = "request_macro";

function SelectCharactersModal({  route, navigation, dispatch, storeCharacters, characterTags}) {
	const {
		pcOnly,
		goToScreen,
		charactersSelected: charactersSelectedReceived,
		nextButtonTitle,
		code,
		allowEmpty
	} = route.params;

	const [charactersSelected, setcharactersSelected] = React.useState(charactersSelectedReceived?.slice() || []);

	const characters = React.useMemo(() => {
		let array = filterByVisibility(storeCharacters, characterTags);		
		if (pcOnly) {
			array = array.filter((c) => !c.is_npc);
		}
		return array;
	}, [pcOnly, storeCharacters, characterTags]);

	const toggleSelection = React.useCallback(
		(character) => {
			const alreadySelected = charactersSelected.find((c) => idEqual(c, character));
			if (alreadySelected) {
				setcharactersSelected(charactersSelected.rg_removeSimple(alreadySelected));
			} else {
				setcharactersSelected(charactersSelected.concat([character]));
			}
		},
		[charactersSelected]
	);

	const isSelected = React.useCallback(
		(character) => {
			return charactersSelected.some((c) => idEqual(c, character));
		},
		[charactersSelected]
	);

	const options = [
		{
			title: _("Back"),
			onPress: () => navigation.goBack(),
		},
		{
			title: nextButtonTitle || _("Done"),
			disabled: !allowEmpty && !charactersSelected.length,
			onPress: () => navigation.navigate({name: goToScreen, params: { charactersSelected, charactersSelectedCodeSent: code }, merge: true}),
		},
	];

	const { height } = useDimensions().screen;

	return (
		<ModalScreen title={_("Select characters")} options={options} useButtonFooter borderless>
			<FlatList
				contentContainerStyle={ws({ maxHeight: height * 0.6 })}
				data={characters}
				keyExtractor={(c) => String(c.id)}
				renderItem={({ item }) => (
					<CharacterItem
						character={item}
						onPress={() => toggleSelection(item)}
						isSelected={isSelected(item)}
					/>
				)}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameId } = ownProps.route.params;
	return {
		storeCharacters: state.charactersByGame[gameId],
		characterTags: state.characterTags[gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(SelectCharactersModal);
