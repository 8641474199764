import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppActivityIndicator from "./AppActivityIndicator";
import { colors } from "../../styles/colors";
import Modal from "../../reimports/Modal";

import { BuildStyleMethod } from "../../styles/theming";

function FullScreenLoader({ visible }) {
	const styles = stylesMethod(global.theme);	
	return (
		<Modal transparent visible={visible} animationType="fade" >
			<View style={styles.container}>
				<AppActivityIndicator show />
			</View>
		</Modal>
	);
}

export default FullScreenLoader;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: colors.overlayBackground,
		justifyContent: "center",
		alignItems: "center",
	},
}));
