import { useHeaderHeight } from '@react-navigation/elements';
import * as React from "react";
import { View, KeyboardAvoidingView } from "react-native";
import { isIos } from "../../tools/generic";

function FullScreenKeyboardAvoidingView({ children }) {
	
	const headerHeight = isIos() ? useHeaderHeight() : 0;
	const ViewTag = isIos() ? KeyboardAvoidingView : View

	return (
		<ViewTag style={{ flex: 1 }} behavior="padding" keyboardVerticalOffset={headerHeight}>
			{children}
		</ViewTag>
	);
}

export default FullScreenKeyboardAvoidingView;
