import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import { fetchGameTags } from "../../store/slices/gameTagsSlice";
import AppScreenView from "../AppScreenView";
import GameTagHorizontalList from "../gametags/GameTagHorizontalList";
import AppButton from "../generic/buttons/AppButton";
import { ws } from "../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../styles/theming";
import ListSwitch from "../generic/ListSwitch";
import { Divider } from "react-native-paper";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import { apiFetch } from "../../network/apiFetch";

function CreateAlertScreen({ route, navigation, dispatch, tags, user }) {
	const styles = stylesMethod(global.theme);

	const [tagsSelected, settagsSelected] = React.useState([]);
	const [tagsToAvoid, settagsToAvoid] = React.useState([]);

	const [mobileNotification, setmobileNotification] = React.useState(true);
	const [emailNotification, setemailNotification] = React.useState(true);
	const [openGamesOnly, setopenGamesOnly] = React.useState(false);
	const [lowRequest, setlowRequest] = React.useState(false);

	const [selectingTags, setselectingTags] = React.useState(true);

	const selectedTags = route.params?.selectedTags;

	React.useEffect(() => {
		if (!selectedTags) return () => null;
		if (selectingTags) {
			settagsSelected(selectedTags);
		} else {
			settagsToAvoid(selectedTags);
		}

		navigation.setParams({ selectedTags: null });
	}, [selectedTags, selectingTags]);

	const createAlert = React.useCallback(() => {
		apiFetch(`users/${user.id}/game-alerts`, "POST", {
			user: user.id,
			must_have_tags: tagsSelected.map((t) => t.id),
			tags_to_avoid: tagsToAvoid.map((t) => t.id),
			mobile_notifications: mobileNotification,
			email_notifications: emailNotification,
			open_games_only: openGamesOnly,
			low_requests: lowRequest,
		});

		resetData();
		navigation.navigate("MyGames");
	}, [tagsSelected, tagsToAvoid, user.id, mobileNotification, emailNotification, openGamesOnly, lowRequest]);

	const resetData = React.useCallback(() => {
		settagsSelected([]);
		settagsToAvoid([]);
		setmobileNotification(true);
		setemailNotification(true);
	}, []);

	return (
		<>
			<AppScreenView
				style={[
					ws({
						paddingTop: 16,
						paddingLeft: fullScreenLeftPadding(),
						maxWidth: fullScreenContentWidth(),
					}),
					{
						paddingBottom: 32,
					},
				]}
				scroll
			>
				<View style={styles.infoPanel}>
					<AppText bold size="large">
						{_("Create a notifier")}
					</AppText>
					<AppText style={{ marginTop: 8 }}>
						{_(
							"Receive a mobile and/or an email notification when a game matching your criteria is looking for players."
						)}
					</AppText>
				</View>

				<Divider style={styles.divider} theme={{ dark: true }} />

				<AppText bold color="primary">
					{_("Tag(s) to receive an alert for")}
				</AppText>
				<AppText hide={!!tagsSelected.length} color="secondary" size="small">
					{_(
						"You can leave this list empty if you want to receive an alert for every game that is looking for players."
					)}
				</AppText>

				<View style={{ marginBottom: 8, marginTop: 16 }}>
					{!tagsSelected.length && (
						<AppText color="hint" italic>
							{_("No tags selected")}
						</AppText>
					)}
					<GameTagHorizontalList tagsToShow={tagsSelected} large tagSize="average" />
				</View>
				<AppText hide={tagsSelected.length < 2} color="secondary" size="small">
					<AppText bold color="danger">
						{_("Only games that use all of these tags at once will trigger an alert.")}
					</AppText>{" "}
					<AppText>{_("We do not recommend adding more than one or two tags per notifier.")}</AppText>
				</AppText>
				<AppButton
					transparent
					title={tagsSelected.length ? _("Change tags") : _("Add tags")}
					onPress={() => {
						setselectingTags(true);
						navigation.navigate("CreateGameTagsScreen", {
							sendToScreen: route.name,
							activeTags: tagsSelected,
						});
					}}
				/>

				<Divider style={styles.divider} theme={{ dark: true }} />

				<AppText bold style={{ marginTop: 32 }} color="primary">
					{_("Tag(s) to avoid")}
				</AppText>
				<AppText color="secondary" size="small">
					{_("Games that use any of these tags won't trigger an alert.")}
				</AppText>

				<View style={{ marginBottom: 8, marginTop: 16 }}>
					{!tagsToAvoid.length && (
						<AppText color="hint" italic>
							{_("No tags selected")}
						</AppText>
					)}
					<GameTagHorizontalList tagsToShow={tagsToAvoid} large tagSize="average" />
				</View>
				<AppButton
					transparent
					title={tagsToAvoid.length ? _("Change tags") : _("Add tags")}
					onPress={() => {
						setselectingTags(false);
						navigation.navigate("CreateGameTagsScreen", {
							sendToScreen: route.name,
							activeTags: tagsToAvoid,
						});
					}}
				/>

				<Divider style={styles.divider} theme={{ dark: true }} />

				<AppText color="primary" bold style={{ marginBottom: 8 }}>
					{_("Explore less popular styles of games?")}
				</AppText>
				<ListSwitch
					title={_("Low-demand games only", "set an alarm to only trigger for games with a low demade")}
					isEnabled={lowRequest}
					setIsEnabled={setlowRequest}
				/>

				<AppText style={{ marginBottom: 8 }} color="secondary" size="small">
					{_(
						"This is perfect if you want to discover new game systems and move away from the most popular/used ones. You will only be notified of games that reach a small number of players. We recommend leaving the list of tags empty if you use this option."
					)}
				</AppText>

				<Divider style={styles.divider} theme={{ dark: true }} />

				<AppText bold style={{ marginTop: 32 }} color="primary">
					{_("Other options", "game alert options")}
				</AppText>
				<View style={{ marginTop: 16 }}>
					<ListSwitch
						title={_("Open games only", "set game alert to only triggers for open games")}
						isEnabled={openGamesOnly}
						setIsEnabled={setopenGamesOnly}
					/>
					<AppText color="secondary" size="small" style={{ marginTop: -4, marginBottom: 8 }}>
						{_("Open games do not require a password to join.")}
					</AppText>

					<ListSwitch
						title={_("Email notification", "activate game alert email notifications")}
						isEnabled={emailNotification}
						setIsEnabled={setemailNotification}
					/>
					<AppText color="secondary" size="small" style={{ marginTop: -4, marginBottom: 8 }}>
						{_("Receive an email notification when this alert is triggered.")}
					</AppText>

					<ListSwitch
						title={_("Mobile notification", "activate game alert mobile notifications")}
						isEnabled={mobileNotification}
						setIsEnabled={setmobileNotification}
					/>
					<AppText color="secondary" size="small" style={{ marginTop: -4, marginBottom: 8 }}>
						{ws(
							_("Receive a mobile notification if you have the mobile app installed."),
							_("Receive a mobile notification when an alert is triggered.")
						)}
					</AppText>
				</View>
			</AppScreenView>

			<ButtonFooter
				style={ws({ maxWidth: fullScreenContentWidth() })}
				narrow={false}
				buttonProps={[
					{
						title: _("Cancel"),
						onPress: () => {
							resetData();
							navigation.navigate("MyGames");
						},
					},
					{
						title: _("Create", "create a game watchlist"),
						onPress: createAlert,
					},
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		tags: state.gameTags || Array.rg_empty,
		user: state.user,
	};
};

export default connect(mapStateToProps)(CreateAlertScreen);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		infoPanel: {
			padding: 16,
			backgroundColor: global.colors.translucidBackground,
			marginTop: 16,
			marginBottom: 16,
		},
		divider: { marginVertical: 16 },
	})
);
