import * as React from "react";
import { connect } from "react-redux";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import {
	getCurrentGameUI,
	setCommentMessage, setMessage,
	setTone, setToneColor
} from "../../store/slices/gamesUISlice";
import { sendLine } from "../../store/slices/linesSlice";
import { characterIdToCharacterStateId, getCharName } from "../../tools/characters";
import { findTaggedIds, GenerateDefaultLine } from "../../tools/lines";
import InternalSendMessageButton from "./InternalSendMessageButton";
import SplitMessageModal from "./SplitMessageModal";

class SendMessageButton extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			lastSentType: null,
		};

		this.splitModalRef = React.createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.minimumMode && this.props.lastSentType) {
			this.setState({ lastSentType: null });
		}
	}

	sendMessage = (lineType, skipSplitCheck, overrideMessage) => {
		const {
			inputRef,
			dispatch,
			message,
			tone,
			toneColorOverride,
			user,
			currentParty,
			currentCharacter,
			language,
			gameId,
			whisperToCharacterIds,
			talkToCharacterIds,
			talkToPlayerIds,
			characters,
			users,
			currentStoryMarker,
		} = this.props;

		let content = overrideMessage || message;

		if (!content?.trim()) {
			return;
		}

		if (
			!skipSplitCheck &&
			lineType !== "chat" &&
			lineType !== "comment" &&
			user.profile.ask_auto_split &&
			content.match(/(".+")|(\n\n)/)
		) {
			inputRef.current.blur(); // On android, the input keeps focus and user needs to tap twice to split
			this.splitModalRef.current.show(lineType);
			return;
		}

		let line = GenerateDefaultLine(gameId);

		for (let i = 0; i < talkToCharacterIds?.length; i++) {
			const characterId = talkToCharacterIds[i];
			content = `@${getCharName(characters[characterId])}#${characterId} ` + content;
		}
		for (let i = 0; i < talkToPlayerIds?.length; i++) {
			const targetId = talkToPlayerIds[i];
			content = `@${users[targetId].username}#${targetId} ` + content;
		}

		line = {
			...line,
			content,
			user,
			tone: tone || line.tone,
			toneColorOverride: toneColorOverride || line.toneColorOverride,
			tagged_users: [],
			tagged_characters: [],
			scene: currentStoryMarker?.id,
		};

		if (lineType === "description" || lineType === "dialogue") {
			line = {
				...line,
				tone: lineType === "dialogue" ? line.tone : "neutral",
				author: currentCharacter?.id,
				is_storytelling: !currentCharacter,
				character_state: currentCharacter ? characterIdToCharacterStateId(currentCharacter.id) : null,
				is_description: lineType === "description" && !!currentCharacter,
				party: currentParty?.id,
				tagged_characters: findTaggedIds(content, false, gameId),
				language: language && lineType === "dialogue" ? language.id : null,
				whispered_to: whisperToCharacterIds || [],
			};
		} else {
			line = {
				...line,
				is_chat: true,
				tagged_users: findTaggedIds(content, true, gameId),
			};
		}

		if (lineType === "comment") {
			line.is_chat = false;
			line.is_comment = true;
			dispatch(setCommentMessage({ gameId, value: "" }));
		} else {
			dispatch(setMessage({ gameId, value: "" }));
			this.setState({ lastSentType: lineType });
		}

		dispatch(setTone({ gameId, value: null }));
		dispatch(setToneColor({ gameId, value: null }));

		return dispatch(sendLine(line));
	};

	render() {
		const { minimumMode, isComments, messageType, message, user } = this.props;

		return (
			<>
				<InternalSendMessageButton
					message={this.props.message}
					sendMessage={this.sendMessage}
					lastSentType={this.state.lastSentType}
					minimumMode={minimumMode}
					isComments={isComments}
					messageType={messageType}
				/>
				<SplitMessageModal
					ref={this.splitModalRef}
					user={user}
					sendMessage={this.sendMessage}
					message={message}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const gameUI = getCurrentGameUI(state);
	const gameId = state.games.currentId;

	let props = {
		gameId,
		user: state.user,
		message: gameUI?.commentMessage,
		tone: gameUI?.tone,
		toneColorOverride: gameUI?.toneColorOverride,
		users: state.users,
	};

	if (!ownProps.isComments) {
		props = {
			...props,
			currentCharacter: getCurrentCharacter(state),
			message: gameUI.message,
			currentParty: state.parties[gameId].current,
			currentStoryMarker: gameUI.currentStoryMarker,
			language: gameUI.language,
			whisperToCharacterIds: gameUI.whisperToCharacterIds,
			whisperToPlayerIds: gameUI.whisperToPlayerIds,
			talkToCharacterIds: gameUI.talkToCharacterIds,
			talkToPlayerIds: gameUI.talkToPlayerIds,
			characters: state.characters,
		};
	}

	return props;
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SendMessageButton);
