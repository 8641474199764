import { createIconSet } from "@expo/vector-icons";
import React from "react";

const glyphMap = {
	die: "",
	language: "",
};

const CustomIcon = createIconSet(glyphMap, "rg-icons");

function RGIcon(props) {
	return <CustomIcon {...props} />;
}

export default RGIcon;
