import store from "../store/store";

export const pickDefaultPovCharacterId = (game, characters) => {
	const pcs = characters.filter((c) => !c.deleted && !c.is_npc);
	if (!pcs.length) return null;

	const state = store.getState();
	const user = state.user;

	const gameUnreadLines = user?.unread_lines.filter((l) => l.game === game.id) || [];
	const partyLines = gameUnreadLines.filter((l) => l.party);

	if (partyLines.length) {
		// Take the Pov of someone in a party with unread lines
		const partyId = partyLines[0].party;
		const party = game.parties.find((p) => p.id === partyId);
		//TODO: Investigate why party can be null at this point
		if (party) {
			// make sure that the person we pick is still and active player
			return pcs.find((c) => party.characters.includes(c.id))?.id || party.characters[0].id;
		}
	}

	const myCharacters = pcs.filter((pc) => pc.player?.id === user?.id);
	if (myCharacters.length) return myCharacters[0].id;

	// Take the first pc
	return pcs[0].id;
};

export const pickPovForParty = (characters, party) => {
	if(!party) return null;
	let pcs = characters.filter((c) => !c.deleted && !c.is_npc);
	if (!pcs.length) {
		pcs = characters.filter((c) => c.player);
	}
	if (!pcs.length) return null;	
	return pcs.find((c) => party.characters.includes(c.id))?.id || party.characters[0].id;
};

export const idToParty = (partyId, gameId) => {
	const parties = store.getState().parties[gameId];
	return parties?.store[partyId];
};
