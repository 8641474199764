import * as React from 'react';
import { View } from 'react-native';

function Cond({ show, children }) {
    if (!show || !children) {
        return null;
    }
    return children;
}

export default Cond;