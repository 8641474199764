import * as React from "react";
import { StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import {
	createInteractiveHelperOption,
	fetchHelperPanelOptions,
	receivePanelEdit,
	setOpenedHelper,
	setOpenedPanelId,
} from "../../store/slices/interactiveHelpersSlice";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import InputField from "../generic/InputField";
import InteractiveHelperOptionItem from "./InteractiveHelperOptionItem";
import OverflowMenu from "../generic/OverflowMenu";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { usePrevious, simpleReducer } from "../../tools/react";
import { apiFetch } from "../../network/apiFetch";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { isWeb, ws } from "../../tools/generic";

function EditInteractiveHelperPanelScreen({ navigation, route, dispatch, helper, panel, options, userId }) {

	const [creating, setcreating] = React.useState(false);
	const [editedPanel, updatePanel] = React.useReducer(simpleReducer, { ...panel });

	const prevPanelData = usePrevious(editedPanel);
	const editedPanelRef = React.useRef(editedPanel);
	editedPanelRef.current = editedPanel;

	const saveTimeout = React.useRef(null);

	

	const save = React.useCallback(() => {
		clearTimeout(saveTimeout.current);
		dispatch(receivePanelEdit(editedPanelRef.current));
		const { name, content, id } = editedPanelRef.current;

		apiFetch(`users/${userId}/helpers/${helper.id}/panels/${id}`, "PATCH", { name, content });
	}, [userId, helper.id]);

	const openPanel = React.useCallback(async () => {
		saveTimeout.current && save();
		await dispatch(setOpenedHelper(null));
		await dispatch(setOpenedHelper(helper));
		dispatch(setOpenedPanelId(panel.id));
	}, [helper, panel.id, save]);

	React.useEffect(() => {
		const options = [];

		options.push({
			title: _("Preview panel"),
			icon: { type: MaterialCommunityIcons, name: "eye" },
			onPress: openPanel,
		});

		navigation.setOptions({
			headerRight: () => <OverflowMenu options={options} />,
		});
	}, [openPanel]);

	// save on changes
	React.useEffect(() => {
		if (!prevPanelData) return () => null;
		if (prevPanelData.id !== editedPanel.id) return () => null;
		saveTimeout.current = setTimeout(save, 3000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [editedPanel.id, editedPanel.name, editedPanel.content, save]);

	const createOption = React.useCallback(async () => {
		setcreating(true);
		await dispatch(createInteractiveHelperOption(panel.helper, panel.id));
		setcreating(false);
	}, [panel]);

	// Mount/Dismount effect
	React.useEffect(() => {
		if (!options.length) {
			dispatch(fetchHelperPanelOptions(panel.id));
		}
		return () => {
			saveTimeout.current && save();
		};
	}, [panel.id, save, options.length]);

	React.useEffect(() => {
		if (prevPanelData && prevPanelData.id !== route.params.panel.id) {
			updatePanel(route.params.panel);
		}
	}, [route.params.panel]);

	return (
		<AppScreenView
			borderless={!isWeb()}
			scroll
			style={ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })}
		>
			<InputField
				value={editedPanel.name}
				onChangeText={(t) => updatePanel({ name: t })}
				label={_("Name", "interactive helper input field")}
			/>
			<InputField
				value={editedPanel.content}
				onChangeText={(t) => updatePanel({ content: t })}
				label={_("Content", "interactive helper input field")}
				multiline
				autoGrow
			/>

			<AppText bold style={{ margin: 8 }}>
				{_("Navigation", "list panels in interactive helper")}
			</AppText>

			{options.map((o) => (
				<InteractiveHelperOptionItem
					key={o.id}
					option={o}
					panel={panel}
					helper={helper}
					openPanel={openPanel}
					onNavigateOut={() => {
						saveTimeout.current && save();
					}}
				/>
			))}

			{!options.length && (
				<AppText centered color="hint" style={{ marginTop: 64, marginHorizontal: 16 }}>
					{_("Press the + button to add navigation options to this panel.")}
				</AppText>
			)}

			<FAB icon="plus" style={globalStylesMethod(global.theme).fab} onPress={createOption} disabled={creating} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { panelId, helperId } = ownProps.route.params;
	return {
		helper: state.interactiveHelpers.all[helperId],
		userId: state.user.id,
		panel: state.interactiveHelpers.panels[helperId][panelId],
		options: state.interactiveHelpers.options[panelId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(EditInteractiveHelperPanelScreen);


