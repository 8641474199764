import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import React from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { Divider } from "react-native-paper";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchAvatarRequest } from "../../store/slices/avatarRequestSlice";
import { fetchNotifications } from "../../store/slices/notificationsSlice";
import { drawerWidth } from "../../styles/dynamicStyles";
import { globalStyles } from "../../styles/global";
import { hasDevAccess, logout } from "../../tools/account";
import { isIos, isWeb, ws } from "../../tools/generic";
import { ScreenToURL } from "../../tools/navigationConfig";
import { cStyle } from "../../tools/react";
import CoinGivenFeedback from "../coins/CoinGivenFeedback";
import Purse from "../coins/Purse";
import IconButton from "../generic/buttons/IconButton";
import ListButton from "../generic/buttons/ListButton";
import RGIcon from "../RGIcon";
import HomeIcon from "./HomeIcon";

function MainDrawerPanel({
	folded,
	setuserFolded,
	forceFolded,
	navigation,
	state,
	drawerType,
	dispatch,
	user,
	avatarRequest,
	notifications,
	pendingRequestsCount,
}) {
	const [referralCount, setreferralCount] = React.useState(0);

	const winDim = useWindowDimensions();

	React.useEffect(() => {
		if (user.profile.reward_level >= 5) {
			// dispatch(fetchAvatarRequest());
		}
		dispatch(fetchNotifications());
		apiFetch(`users/${user.id}/profiles-referring-count`).then((value) => setreferralCount(value));
	}, [user]);

	const progressToTemp = Math.max(0, referralCount - user.profile.last_temporary_upgrade);

	const unreadNotificationsCount = React.useMemo(
		() => notifications.filter((n) => n.id > user.profile.last_transaction_seen_id).length,
		[notifications, user.profile.last_transaction_seen_id]
	);

	const activeRouteName = state.routeNames[state.index];

	const Header = (
		<>
			<View style={[globalStyles.rcsb, ws({ width: drawerWidth() - 10 })]}>
				<HomeIcon
					folded={folded}
					forceFolded={forceFolded}
					drawerType={drawerType}
					onFoldPressed={() => (drawerType == "front" ? navigation.closeDrawer() : setuserFolded(!folded))}
				/>
				{!folded && (
					<IconButton
						icon={{ type: MaterialCommunityIcons, name: "treasure-chest" }}
						transparent
						style={{ marginRight: 8 }}
						title={_("Chest", "name under chest icon. MUST BE SHORT TO FIT")}
						badgeValue={unreadNotificationsCount}
						onPress={() => navigation.navigate("NotificationsScreen")}
					/>
				)}
			</View>

			{isWeb() && !folded && <Divider style={styles.divider} theme={{ dark: true }} />}

			{!folded && (
				<Purse small={isWeb()} style={{ paddingHorizontal: 8, marginTop: 12, ...ws({ marginTop: 0, width: drawerWidth() - 18 }) }} />
			)}

			{!forceFolded && <Divider style={styles.divider} theme={{ dark: true }} />}
		</>
	);

	return (
		<View key="drawer" style={{ flex: 1 }}>
			{isWeb() && Header}

			<Hoverable>
				{(isHovered) => (
					<View style={{ flex: 1 }}>
						<DrawerContentScrollView
							contentContainerStyle={cStyle([ws({ height: winDim.height })])}
							showsVerticalScrollIndicator={isWeb() ? isHovered : null}
						>
							{!isWeb() && Header}

							{isWeb() && (
								<>
									<ListButton
										hideTitle={folded}
										noBorder
										isSelected={activeRouteName === "MyGames"}
										title={_("My games")}
										iconLeft={{ type: RGIcon, name: "die" }}
										to={ScreenToURL("MyGames")}
										hoverStrongBackground
									/>
									<ListButton
										hideTitle={folded}
										noBorder
										isSelected={activeRouteName === "Bookmarks"}
										title={_("Bookmarks")}
										iconLeft={{ type: MaterialIcons, name: "bookmark" }}
										to={ScreenToURL("Bookmarks")}
										hoverStrongBackground
									/>
									<ListButton
										hideTitle={folded}
										noBorder
										isSelected={activeRouteName === "Explore"}
										title={_("Explore")}
										iconLeft={{ type: MaterialIcons, name: "search" }}
										to={ScreenToURL("Explore")}
										hoverStrongBackground
									/>
									<ListButton
										hideTitle={folded}
										noBorder
										isSelected={activeRouteName === "MessagesStack"}
										title={_("Messages", "menu title")}
										iconLeft={{ type: MaterialIcons, name: "chat-bubble" }}
										badgeValue={user.unread_messages.length + pendingRequestsCount}
										to={ScreenToURL("MessagesStack")}
										hoverStrongBackground
									/>
								</>
							)}

							{!isWeb() && (
								<ListButton
									noBorder
									title={_("Games", "menu title")}
									iconLeft={{ type: RGIcon, name: "die" }}
									to={ScreenToURL("MyGames")}
									hoverStrongBackground
								/>
							)}
							{!isWeb() && (
								<ListButton
									noBorder
									isSelected={activeRouteName === "MessagesStack"}
									title={_("Messages", "menu title")}
									iconLeft={{ type: MaterialIcons, name: "chat-bubble" }}
									to={ScreenToURL("MessagesStack")}
									badgeValue={user.unread_messages.length + pendingRequestsCount}
									hoverStrongBackground
								/>
							)}

							<Divider style={styles.divider} theme={{ dark: true }} />

							
							{/* <ListButton
								hideTitle={folded}
								isSelected={activeRouteName === "OpenAIGeneratorScreen"}
								title={_("AI Generator",  "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "brain" }}
								to={ScreenToURL("OpenAIGeneratorScreen")}
								hoverStrongBackground
							/> */}

							<ListButton
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "CharacterSheetStack"}
								title={_("Character sheet templates", "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "file-account" }}
								to={ScreenToURL("CharacterSheetStack")}
								hoverStrongBackground
							/>

							<ListButton
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "DiceSetStack"}
								title={_("Dice sets", "menu title")}
								iconLeft={{ type: FontAwesome5, name: "dice" }}
								to={ScreenToURL("DiceSetStack")}
								hoverStrongBackground
							/>

							<ListButton
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "InteractiveHelpersStack"}
								title={_("Interactive helpers", "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "paperclip" }}
								to={ScreenToURL("InteractiveHelpersStack")}
								hoverStrongBackground
							/>

							<Divider style={styles.divider} theme={{ dark: true }} />

							{!global.appleStoreReview && (
								<ListButton
									hide={folded && !forceFolded}
									hideTitle={folded}
									noBorder
									isSelected={activeRouteName === "UpgradeAccountScreen"}
									title={_("Upgrade account", "menu title")}
									subtitle={_("Referral upgrade (%(referal_count)s)", "menu title", {
										// Do not use ` ` strings in localized string method, as it won't get extracted
										referal_count: progressToTemp + "/10",
									})}
									iconLeft={{ type: MaterialCommunityIcons, name: "chevron-up-circle-outline" }}
									to={ScreenToURL("UpgradeAccountScreen")}
									hoverStrongBackground
								/>
							)}
							<ListButton
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "ProfilePage"}
								title={_("Profile", "menu title")}
								iconLeft={{ type: MaterialIcons, name: "person" }}
								to={`/me/${encodeURIComponent(user.username)}`}
								hoverStrongBackground
							/>
							{/* <ListButton
							hideTitle={folded}
							isSelected={activeRouteName === "AvatarRequestWizardStack"}
							hide={global.appleStoreReview}
							noBorder
							title={_("Legendary Avatar request", "menu title")}
							iconLeft={<CharacterAvatar avatarName={`ico_le_16`} size={isWeb() ? 24 : undefined} />}
							to={ScreenToURL("AvatarRequestWizardStack")}
							badgeValue={avatarRequest?.pending_feedback ? "!" : null}
							hoverStrongBackground
						/> */}

							<Divider style={styles.divider} theme={{ dark: true }} />

							{/* <ListButton
								hide={folded && !forceFolded}
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "VoteFeatureScreen"}
								title={_("Vote for features", "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "vote" }}
								to={ScreenToURL("VoteFeatureScreen")}
								hoverStrongBackground
							/> */}

							<ListButton
								hideTitle={folded}
								noBorder
								title={_("Discord server", "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "discord" }}
								to="https://discord.com/invite/3E37sQ7"
								hoverStrongBackground
							/>

							<ListButton
								hide={folded && !forceFolded}
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "BugReportScreen"}
								title={_("Report bug", "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "bug" }}
								to={ScreenToURL("BugReportScreen")}
								hoverStrongBackground
							/>

							<ListButton
								hideTitle={folded}
								noBorder
								isSelected={activeRouteName === "AboutRGScreen"}
								title={_("About Role Gate", "menu title")}
								iconLeft={{ type: MaterialCommunityIcons, name: "information" }}
								to={ScreenToURL("AboutRGScreen")}
								hoverStrongBackground
							/>

							<Divider style={styles.divider} theme={{ dark: true }} />

							<ListButton
								hideTitle={folded}
								isSelected={activeRouteName === "AppSettingsScreen"}
								noBorder
								title={_("Settings", "app settings")}
								iconLeft={{ type: MaterialIcons, name: "settings" }}
								to={ScreenToURL("AppSettingsScreen")}
								hoverStrongBackground
							/>

							<ListButton
								hideTitle={folded}
								noBorder
								title={_("Log out")}
								iconLeft={{ type: MaterialCommunityIcons, name: "logout" }}
								onPress={() => logout(user.id, dispatch)}
								hoverStrongBackground
							/>
							<ListButton
								hideTitle={folded}
								hide={!hasDevAccess()}
								isSelected={activeRouteName === "DevSettingsScreen"}
								title="Development tools"
								iconLeft={{ type: MaterialCommunityIcons, name: "bug" }}
								to={ScreenToURL("DevSettingsScreen")}
								hoverStrongBackground
							/>
							<ListButton
								hideTitle={folded}
								hide={!hasDevAccess()}
								isSelected={activeRouteName === "OpenAIPlaygroundScreen"}
								title="Open AI"
								iconLeft={{ type: MaterialCommunityIcons, name: "brain" }}
								to={ScreenToURL("OpenAIPlaygroundScreen")}
								hoverStrongBackground
							/>
						</DrawerContentScrollView>
					</View>
				)}
			</Hoverable>

			<CoinGivenFeedback />
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		avatarRequest: state.avatarRequest,
		notifications: state.notifications.all,
		pendingRequestsCount: state.friends.pendingRequests?.length,
	};
};

export default connect(mapStateToProps)(MainDrawerPanel);

const styles = StyleSheet.create({
	divider: { marginVertical: 8 },
});
