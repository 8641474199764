import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import AppText from "../generic/AppText";
import Cond from "../meta/Cond";
import CoinCounter from "./CoinCounter";
import CoinHelpModal from "./CoinHelpModal";

function Chest({ style, dispatch, user }) {
	const chest = user.profile.chest;
	
	if(!chest) return null

	const modalRef = React.useRef();

	const Tag = global.appleStoreReview ? View : TouchableOpacity;

	return (
		<Tag style={[styles.container, style]} onPress={() => modalRef.current.show()}>
			<View style={{ flexDirection: "row", alignItems: "center" }}>
				<MaterialCommunityIcons
					name="treasure-chest"
					size={24}
					style={{ marginRight: 4 }}
					color={global.colors.textDefault}
				/>
				<AppText bold>{_("Chest", "chest containing the coins")}</AppText>
			</View>

			<View style={styles.coinContainer}>
				<CoinCounter level={0} amount={chest.wood} />
				<Cond show={!global.appleStoreReview}>
					<CoinCounter level={1} amount={chest.copper} />
					<CoinCounter level={2} amount={chest.silver} />
					<CoinCounter level={3} amount={chest.gold} />
					<CoinCounter level={4} amount={chest.platinum} />
					<CoinCounter level={5} amount={chest.legendary} />
				</Cond>
			</View>
			<CoinHelpModal ref={modalRef} />
		</Tag>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: ownProps.user || state.user,
	};
};

export default connect(mapStateToProps)(Chest);

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},
	coinContainer: {
		flexDirection: "row",
		flex: 1,
		maxWidth: 200,
	},
});
