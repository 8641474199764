import { StyleSheet } from "react-native";
import { colors } from "./colors";


export function BuildStyleMethod(createStyleMethod){

    const darkStyle = createStyleMethod(colors.darkTheme);
    const darkestStyle = createStyleMethod(colors.darkestTheme);
    
    return (theme)=>{
        if(theme == "darkest") return darkestStyle;
        return darkStyle;
    }

}
