import * as Device from 'expo-device';
import * as Notifications from "expo-notifications";
import { apiFetch } from "./apiFetch";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from "react-native";
import { isWeb } from "../tools/generic";

export async function registerForPushNotificationsAsync(userId) {
	if (Platform.OS === "web") return;
	let token;

	if (Device.isDevice) {
		const { status: existingStatus } = await Notifications.getPermissionsAsync();
		let finalStatus = existingStatus;
		if (existingStatus !== "granted") {
			const { status } = await Notifications.requestPermissionsAsync();
			finalStatus = status;
		}
		if (finalStatus !== "granted") {
			alert("Failed to get push token for push notification!");
			return;
		}
		try {
			token = await Notifications.getExpoPushTokenAsync({ projectId: '2c18703b-95b1-48b8-9c59-0d78a1cd64c2' });
		} catch (err) {
			alert(`failed to get token error ${err}`);
			return;
		}

		token = token.data;
		apiFetch(`users/${userId}/expo-push-token`, "POST", {
			user: userId,
			token,
			experience_id: "@divone/rolegate-app",
		});
		AsyncStorage.setItem("expoPushToken" + global.expoTokenVersion, token);
	}

	if (Platform.OS === "android") {
		Notifications.setNotificationChannelAsync("default", {
			name: "default",
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: "#FF231F7C",
		});

		await Notifications.setNotificationChannelAsync("game-updates", {
			name: "game-updates",
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: "#FF231F7C",
		});

		await Notifications.setNotificationChannelAsync("chat-messages", {
			name: "chat-messages",
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: "#FF231F7C",
		});

		await Notifications.setNotificationChannelAsync("pending-roll-requests", {
			name: "pending-roll-requests",
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: "#FF231F7C",
		});
	}

	return token;
}

export const deleteToken = async (userId) => {
	
	if (Device.isDevice && !isWeb()) {
		let token = (await Notifications.getExpoPushTokenAsync()).data;
		if (!!token) {
			apiFetch(`users/${userId}/expo-push-token/current`, "DELETE", { token });
		}
	}
};
