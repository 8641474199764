import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const sheetTabsSlice = createSlice({
	name: "sheetTabs",
	initialState: {},
	reducers: {
		addSheetTab: (state, { payload: tab }) => {			
			state[tab.sheet] = state[tab.sheet].concat(tab);
		},
		receiveSheetTabs: (state, { payload: {sheetId, tabs} }) => {			
			state[sheetId] = tabs;
		},
		updateSheetTab: (state, { payload: tab }) => {
			state[tab.sheet] = state[tab.sheet].rg_overlapById([tab]);
		},
	},
});

export const { receiveSheetTabs, updateSheetTab, addSheetTab } = sheetTabsSlice.actions;

export function fetchSheetTabs(sheetId) {
	return async (dispatch, getState) => {
		return apiFetch(`sheets/${sheetId}/tabs`).then((response) => {
			dispatch(receiveSheetTabs({sheetId, tabs: response}));
		});
	};
}
