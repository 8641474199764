import * as React from "react";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import ModalScreen from "../generic/modal/ModalScreen";
import { fetchStoryMarkers, receiveStoryMarkers } from "../../store/slices/storyMarkersSlice";
import { markerTypeToString } from "../../tools/storyMarker";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import InputFieldButton from "../generic/buttons/InputFieldButton";
import StageBackgroundImage from "../stagebackgrounds/StageBackgroundImage";
import { Dimensions, ScrollView } from "react-native";
import { isWeb, ws } from "../../tools/generic";
import InputField from "../generic/InputField";
import { connect } from "react-redux";

function EditStoryMarkerModal({ route, navigation, dispatch, marker, backgroundSelected }) {
	const { editedFieldProps, backToScreen } = route.params;

	const [name, setName] = React.useState(marker.name || "");
	const [background, setBackground] = React.useState(null);
	const [synopsis, setSynopsis] = React.useState(marker.description);
	const [currentType, setType] = React.useState(marker.type || 3);
	const [saving, setSaving] = React.useState(false);

	React.useEffect(() => {
		if (editedFieldProps) {
			setName(editedFieldProps[0].value);
			setSynopsis(editedFieldProps[1].value);
		}

		if (backgroundSelected) {
			setBackground(backgroundSelected);
		}
	}, [editedFieldProps, backgroundSelected]);

	const windowDim = Dimensions.get("window");

	const ButtonSwitch = React.useCallback(
		({ type }) => (
			<AppButton
				title={markerTypeToString(type)}
				isToggle
				toggled={currentType === type}
				onPress={() => setType(type)}
				size="small"
				style={{ maxWidth: windowDim.width / 3 - 16 }}
			/>
		),
		[currentType, windowDim.width]
	);

	const nameProps = {
		key: "name",
		label: _("Name", "Story marker name"),
		value: name,
	};

	const synopsisProps = {
		key: "synopsis",
		label: _("Synopsis", "Story marker synopsis"),
		value: synopsis,
		multiline: true,
	};

	const goFullScreen = (focusedKey) => {
		navigation.navigate("EditData", {
			inputFieldProps: [nameProps, synopsisProps],
			previousScreenName: route.name,
			focusedKey,
		});
	};

	const saveChanges = React.useCallback(() => {
		if (name !== marker.name || synopsis !== marker.description || currentType !== marker.type || background) {
			setSaving(true);

			let data = { ...marker, name, description: synopsis, type: currentType };
			if (background) {
				data.background = background.id;
			} else {
				delete data.background;
			}

			let query = null;
			if (marker.id && marker.id !== "new") {
				dispatch(
					receiveStoryMarkers([
						{
							...data,
							...(background ? { stage: { background } } : {}),
						},
					])
				);
				query = apiFetch(`games/${marker.game}/story-markers/${marker.id}`, "PATCH", data).then((response) => {
					dispatch(receiveStoryMarkers([response]));
				});;
			} else {
				delete data.game;
				query = apiFetch(`games/${marker.game}/story-markers`, "POST", data).then(() =>
					dispatch(fetchStoryMarkers(marker.game))
				);
			}
		}

		if (backToScreen) {
			navigation.navigate(backToScreen);
		} else {
			navigation.goBack();
		}
	}, [navigation, marker, name, synopsis, currentType, background, dispatch]);

	const inputFields = isWeb() ? (
		<>
			<InputField {...nameProps} onChangeText={setName} />
			<InputField {...synopsisProps} autoGrow onChangeText={setSynopsis} />
		</>
	) : (
		<>
			<InputFieldButton {...nameProps} onPress={() => goFullScreen("name")} />
			<InputFieldButton {...synopsisProps} onPress={() => goFullScreen("synopsis")} />
		</>
	);

	return (
		<ModalScreen
			wide
			options={[
				{ title: _("Cancel"), onPress: () => navigation.goBack() },
				{ title: marker.id ? _("Save") : _("Create"), onPress: saveChanges, disabled: saving},
			]}
			useButtonFooter
			style={{ marginTop: 0 }}
		>
			<ScrollView>
				<StageBackgroundImage
					marker={marker}
					name={backgroundSelected ? backgroundSelected.name : null}
					text={
						global.appleStoreReview
							? null
							: ws(_("click to change scene background"), _("tap to change scene background"))
					}
					onPress={() => {
						if (global.appleStoreReview) return;
						navigation.navigate("SelectBackground", { previousScreenName: route.name });
					}}
					height={Math.min(windowDim.height * 0.1, 136)}
				/>
				<ButtonsRow>
					<ButtonSwitch type={1} />
					<ButtonSwitch type={2} />
					<ButtonSwitch type={3} />
				</ButtonsRow>

				{inputFields}
			</ScrollView>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const id = ownProps.route.params.markerId;
	return {
		marker: state.storyMarkers.byId[id],
		backgroundSelected: state.stageBackgrounds.rg_findById(ownProps.route.params?.backgroundSelected),
	};
};

export default connect(mapStateToProps)(EditStoryMarkerModal);
