const { idEqual } = require("./generic");

/**
 * add items to an array and replace new items if they already exist
 */
Array.prototype.rg_overlap = function (arrayToOverlap, equalityMethod, pushIfNotFound = true) {
	const newArray = this.slice();
	arrayToOverlap.forEach((newElement) => {
		const index = newArray.findIndex((oldElement) => equalityMethod(newElement, oldElement));
		if (index >= 0) {
			newArray[index] = newElement;
		} else if (pushIfNotFound) {
			newArray.push(newElement);
		}
	});

	return newArray;
};

/**
 * add items to an array and replace new items if they already exist. Use item.id to test for equality
 */
Array.prototype.rg_overlapById = function (arrayToOverlap, pushIfNotFound = true) {
	const equalityMetod = (newElement, oldElement) => newElement.id === oldElement.id;
	return this.rg_overlap(arrayToOverlap, equalityMetod, pushIfNotFound);
};

Array.prototype.rg_pushUnique = function (element, test) {
	if (!test) {
		if (!this.includes(element)) {
			this.push(element);
			return true;
		}
	} else {
		if (!this.find(test)) {
			this.push(element);
			return true;
		}
	}
	return false;
};

Array.prototype.rg_pushUniquePure = function (element, test) {
	const array = this.slice();
	if (!test) {
		if (!array.includes(element)) {
			array.push(element);
		}
	} else {
		if (!array.find(test)) {
			array.push(element);
		}
	}
	return array;
};

Array.prototype.rg_removeAllElements = function (test) {
	return this.filter((e) => !test(e));
};

Array.prototype.rg_removeElement = function (test) {
	let index = this.findIndex(test);
	if (index > -1) {
		this.splice(index, 1);
		return true;
	}
	return false;
};

Array.prototype.rg_removeAtPure = function (index) {
	return [...this.slice(0, index), ...this.slice(index + 1)];
};

Array.prototype.rg_popPure = function (index) {
	return this.slice(0, this.length - 1);
};

Array.prototype.rg_removeElementPure = function (test) {
	const array = this.slice();
	let index = array.findIndex(test);
	if (index > -1) {
		array.splice(index, 1);
	}
	return array;
};

Array.prototype.rg_removeElementByIdPure = function (id) {
	return this.rg_removeElementPure((el) => el.id === id);
};

Array.prototype.rg_removeSimple = function (element) {
	let array = this.slice();
	let index = array.indexOf(element);

	if (index > -1) {
		array.splice(index, 1);
	}
	return array;
};

Array.prototype.rg_findById = function (id) {	
	return this.find((el) => el.id === id);
};

Array.prototype.rg_indexWithId = function (id) {
	return this.findIndex((el) => el.id === id);
};


Array.prototype.rg_sortById = function (reverse) {
	const array = this.slice();
	array.sort((a, b) => (reverse ? b.id - a.id : a.id - b.id));
	return array;
};

Array.prototype.rg_toStore = function (storeByKey = "id") {
	const storage = {};
	this.forEach((element) => {
		if (element[storeByKey]) {
			storage[element[storeByKey]] = element;
		}
	});
	return storage;
};


Array.prototype.rg_prevIndex = function (currIndx, loop, sameIfEnd) {
	if (currIndx <= 0) {
		if (loop) {
			return this.length - 1;
		}
		return sameIfEnd ? currIndx : -1;
	}
	return currIndx - 1;
};

Array.prototype.rg_prev = function (currIndx, loop, sameIfEnd) {
	const prevIndex = this.rg_prevIndex(currIndx, loop, sameIfEnd);
	return prevIndex > -1 ? this[prevIndex] : null;
};

Array.prototype.rg_nextIndex = function (currIndx, loop, sameIfEnd) {
	if (currIndx >= this.length - 1) {
		if (loop) {
			return 0;
		}
		return sameIfEnd ? currIndx : -1;
	}
	return currIndx + 1;
};

Array.prototype.rg_next = function (currIndx, loop, sameIfEnd) {
	const nextIndex = this.rg_nextIndex(currIndx, loop, sameIfEnd);
	return nextIndex > -1 ? this[nextIndex] : null;
};

Array.prototype.rg_first = function () {
	if (!this.length) {
		return null;
	}

	return this[0];
};

Array.prototype.rg_last = function (defaultResult = null) {
	if (!this.length) {
		return defaultResult;
	}

	return this[this.length - 1];
};

Array.rg_empty = [];

Array.prototype.rg_findLast = function (findMethod) {
	for (let i = this.length - 1; i >= 0; i--) {
		if (findMethod(this[i])) {
			return this[i];
		}
	}
	return null;
};

Array.prototype.rg_mapDistinct = function (mapFunction, equalityMethod) {
	const mapped = [];

	if (!equalityMethod) {
		for (let i = 0; i < this.length; ++i) {
			mapped.rg_pushUnique(mapFunction(this[i]));
		}
	} else {
		this.map(mapFunction).forEach((elementToAdd) => {
			const hasIt = mapped.some((addedElement) => equalityMethod(elementToAdd, addedElement));
			if (!hasIt) mapped.push(elementToAdd);
		});
	}

	return mapped;
};

Array.prototype.rg_replaceById = function (id, newElement, addIfNotFound, returnSameIfNotFound) {
	return this.rg_replaceElement((el) => el.id === id, newElement, addIfNotFound, returnSameIfNotFound);
};

Array.prototype.rg_replaceElement = function (test, newElement, addIfNotFound, returnSameIfNotFound) {
	const array = this.slice();
	let found = false;
	for (let i = array.length - 1; i >= 0; --i) {
		let el = array[i];
		if (test(el)) {
			array[i] = newElement;
			found = true;
			break;
		}
	}

	if (!found) {
		if (addIfNotFound) {
			array.push(newElement);
		} else if (returnSameIfNotFound) {
			return this;
		}
	}

	return array;
};

Array.prototype.rg_shuffle = function () {
	const array = this.slice();
	var currentIndex = array.length,
		temporaryValue,
		randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

Array.prototype.rg_hasSameContent = function (otherArray) {
	if (!otherArray) return false;
	if (this.length !== otherArray.length) return false;
	for (let i = 0; i < this.length; i++) {
		const element = this[i];
		if (!otherArray.includes(element)) {
			return false;
		}
	}
	return true;
};

Array.prototype.rg_hasElementWithSameId = function (element) {
	return this.some((el) => idEqual(el, element));
};

Array.prototype.rg_get = function (index) {
	if (this.length < index - 1) {
		return null;
	}
	return this[index];
};
