import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { editNode } from "../../../store/slices/sheetNodesSlice";
import { colors } from "../../../styles/colors";
import { cStyle } from "../../../tools/react";
import AppText from "../../generic/AppText";
import InputField from "../../generic/InputField";
import CondView from "../../meta/CondView";
import { _ } from "../../../i18n/i18n";
import { isWeb, ws } from "../../../tools/generic";

import { BuildStyleMethod } from "../../../styles/theming";

function SheetNodeNumber({ node, horizontal, index, editingTemplate, style, allowSaveChanges }) {
	const styles = stylesMethod(global.theme);
	const dispatch = useDispatch();

	const [inputing, setinputing] = React.useState(isWeb());

	const changeValue = React.useCallback(
		(text) => {
			const edit = { ...node, value: text };
			dispatch(editNode(edit));
		},
		[node, dispatch]
	);

	const changeLabel = React.useCallback(
		(text) => {
			const edit = { ...node, label: text };
			dispatch(editNode(edit));
		},
		[node, dispatch]
	);

	const startInput = React.useCallback(() => {
		setinputing(true);
	}, []);

	const stopInput = React.useCallback(() => {
		setinputing(false);
	}, []);

	return (
		<View style={cStyle([styles.container, style])}>
			<CondView show={node.show_label} style={styles.label}>
				<AppText
					bold
					color="textDefault"
					editable={editingTemplate}
					onTextChanged={changeLabel}
					placeholder={_("Label", "Default character sheet item label")}
					iconColor={global.colors.textLight}
				>
					{node.label}
				</AppText>
			</CondView>
			{(!allowSaveChanges || !inputing) && (
				<View style={[styles.number, styles.uneditableNumber ]}>
					<AppText
						hideEmpty={false}
						onPress={allowSaveChanges && startInput}
						style={{ flexShrink: 0, flexGrow: 1, flexBasis: "auto" }}
						textOnlyStyle={{ textAlign: "center", flex: 1 }}
						placeholder="#"
					>
						{node.value || ""}
					</AppText>
				</View>
			)}
			{!!allowSaveChanges && inputing && (
				<InputField
					autoFocus={!isWeb()}
					style={styles.number}
					value={node.value || ""}
					keyboardType="numeric"
					placeholder="#"
					onChangeText={changeValue}
					onBlur={ws(null, stopInput)}
				/>
			)}
		</View>
	);
}

export default SheetNodeNumber;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flexDirection: "row",
			alignItems: "center",
			alignItems: "stretch",
			flex: 1,
			paddingRight: 4,
		},
		label: {
			backgroundColor: colors.primary,
			flex: 2,
			justifyContent: "center",
			paddingLeft: 8,
		},
		number: {
			flex: 1,
		},
		uneditableNumber: {
			minHeight: 36,
			justifyContent: "center",
			borderColor: colors.lightBorder,
			borderWidth: 1,
			borderLeftWidth: 0,
			backgroundColor: colors.inputFieldBackground,
		},
	})
);
