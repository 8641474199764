import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { Animated, StyleSheet, TouchableOpacity } from "react-native";
import ReAnimated from "react-native-reanimated";
import { colors } from "../../styles/colors";
import { sizes, weight } from "../../styles/font";
import { cStyle } from "../../tools/react";
import AppIcon from "./AppIcon";
import EditTextModal from "./EditTextModal";
import MarkdownText from "./MarkdownText";

import { BuildStyleMethod } from "../../styles/theming";
import { hasValue, isWeb } from "../../tools/generic";

function AppText({
	style: styleProp,
	hide,
	children,
	color,
	size,
	navigateTo,
	numberOfLines,
	ellipsizeMode,
	centered,
	bold,
	italic,
	editable,
	onTextChanged,
	placeholder,
	placeholderColor,
	hideEmpty = true,
	debug,
	iconSize = sizes.small,
	iconColor = global.colors.primary,
	onPress,
	avoidTouchable, // used if AppText is inside another Text
	inputFieldProps,
	editTextTitle,
	fontFamily,
	allowMarkdown = true,
	underline,
	textOnlyStyle,
	parentSize,
	parentColor,
	onLayout,
	adjustsFontSizeToFit,
	useReanimated,
	list,
	selectable,
	parentSelectable,
	pointerEvents,
	accessibilityRole,
	href,
	target,
}) {
	const styles = stylesMethod(global.theme);
	if (hide) {
		return null;
	}

	color = color || parentColor;
	size = size || parentSize || "average";
	selectable = selectable || parentSelectable;

	const editModalRef = React.useRef();
	const textRef = React.useRef();

	React.useEffect(() => {
		// target _blank is not automatically added anymore, so we need to force add it
		// if (target && isWeb()) textRef.current.setNativeProps({ target: "_blank" });
	}, [target]);

	const navigation = navigateTo ? useNavigation() : null;

	if (fontFamily) {
		if (bold) fontFamily += "-bold";
		if (italic) fontFamily += "-italic";
		bold = false;
		italic = false;
	}

	const textStyle = [styles.default];
	fontFamily && textStyle.push({ fontFamily });
	size === "small" && textStyle.push(styles.small);
	size === "large" && textStyle.push(styles.large);
	size === "header" && textStyle.push(styles.header);
	size === "header2" && textStyle.push(styles.header2);
	size === "header3" && textStyle.push(styles.header3);

	underline && textStyle.push(styles.underline);
	list && textStyle.push(styles.list);
	if (placeholder && !children) color = placeholderColor || "hint";

	color && textStyle.push({ color: global.colors[color] || global.colors.toneColors[color] || color });
	centered && textStyle.push(styles.centered);
	bold && textStyle.push(styles.bold);
	italic && textStyle.push(styles.italic);

	const textProps = {
		numberOfLines,
		ellipsizeMode,
		selectable,
		pointerEvents,
		accessibilityRole,
		href,
		target,
		adjustsFontSizeToFit,
	};

	const actOnPress = navigateTo || editable || onPress;
	let content = hasValue(children) && children !== "" ? children : placeholder;

	if ((!hasValue(content) || content === "") && hideEmpty) return null;

	if (typeof content === "string" && allowMarkdown) {
		content = <MarkdownText textProps={{ size, color, bold, italic, selectable }}>{content}</MarkdownText>;
	} else {
		content = React.Children.map(content, (child) => {
			// Checking isValidElement is the safe way and avoids a TS error too.
			if (React.isValidElement(child)) {
				return React.cloneElement(child, {
					parentSize: size,
					parentColor: color,
					parentSelectable: selectable,
				});
			}

			return child;
		});
	}

	const TextTag = useReanimated ? ReAnimated.Text : Animated.Text;

	const component = (
		<>
			<TextTag
				ref={textRef}
				onLayout={onLayout}
				style={[textStyle, !actOnPress && styleProp, textOnlyStyle]}
				{...textProps}
				onPress={
					!actOnPress || !avoidTouchable
						? null
						: () => {
								onPress && onPress();
								editModalRef?.current && editModalRef.current.show();
								navigateTo && navigation.navigate(navigateTo);
						  }
				}
			>
				{list && "\u2022" + "  "}
				{content}
			</TextTag>
			{editable && (
				<EditTextModal
					ref={editModalRef}
					onDone={onTextChanged}
					initialValue={children}
					title={editTextTitle}
					inputFieldProps={inputFieldProps}
				/>
			)}
		</>
	);

	if (actOnPress && !avoidTouchable) {
		return (
			<TouchableOpacity
				style={[{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, styleProp]}
				onPress={() => {
					if (editModalRef.current) editModalRef.current.show();
					navigateTo && navigation.navigate(navigateTo);
					onPress && !avoidTouchable && onPress();
				}}
			>
				{component}
				{editable && (
					<AppIcon
						type={MaterialIcons}
						name="edit"
						color={iconColor}
						size={iconSize}
						style={{ marginHorizontal: 8 }}
					/>
				)}
			</TouchableOpacity>
		);
	} else {
		return component;
	}
}

export default React.memo(AppText);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		default: {
			fontSize: sizes.average,
			color: colors.textDefault,
		},
		small: { fontSize: sizes.small },
		large: { fontSize: sizes.large },
		header: { fontSize: sizes.header },
		header2: { fontSize: sizes.header2 },
		header3: { fontSize: sizes.header3 },
		centered: { textAlign: "center" },
		bold: { fontWeight: weight.semibold },
		italic: { fontStyle: "italic" },
		underline: { textDecorationLine: "underline" },
		list: { paddingLeft: 8 },
	})
);
