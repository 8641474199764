import { FontAwesome } from "@expo/vector-icons";
import * as React from "react";
import { connect } from "react-redux";
import { fetchHelperPanels, setOpenedHelper, setOpenedPanelId } from "../../store/slices/interactiveHelpersSlice";
import { isWeb } from "../../tools/generic";
import IconButton from "../generic/buttons/IconButton";
import ActiveHelpersList from "./ActiveHelpersList";
import InteractiveHelperPanel from "./InteractiveHelperPanel";
import InteractiveHelperSwitch from "./InteractiveHelperSwitch";

function InteractiveHelperDrawer({
	route,
	navigation,
	state: navigationState,
	folded,
	setfolded,
	onGameScreen,
	dispatch,
	helper,
	panels,
	panelId,
}) {
	const [history, sethistory] = React.useState([]);

	// helper will be momentarily set to null when a new helper is selected to force the drawer to reopen, even if we select twice the same
	// this variable helps tracking the last opened drawer
	const [lastOpenedHelper, setlastOpenedHelper] = React.useState(null);

	const onHelperChanged = React.useCallback(() => {
		navigation.openDrawer();
		if (folded) setfolded(false);
		dispatch(fetchHelperPanels(helper.id));

		if (lastOpenedHelper?.id !== helper?.id) {
			dispatch(setOpenedPanelId(null));
			sethistory([]);
		}
		setlastOpenedHelper(helper);
	}, [helper, lastOpenedHelper, folded]);

	React.useEffect(() => {
		if (helper) {
			onHelperChanged();
			setlastOpenedHelper(helper);
		}
		// Shameless hack.
		// openDrawer() has to be called from inside this component, otherwise it will open the left drawer.
		// (React navigation doesn't let you pick which drawer to open)
		// Therefore, I made it so setting the opened helper to undefined instead of "null" will call openDrawer()
		else if (helper === undefined) {
			dispatch(setOpenedHelper(null));
			navigation.openDrawer();
			if (folded) setfolded(false);
		}
	}, [helper]);

	const goBack = React.useCallback(() => {
		dispatch(setOpenedPanelId(history.rg_last()));
		sethistory(history.rg_popPure());
	}, [history]);

	const goToPanel = React.useCallback(
		(id) => {
			sethistory(history.concat(panelId));
			dispatch(setOpenedPanelId(id));
		},
		[history]
	);

	let component = null;
	if (folded) {
		if (!onGameScreen) return <InteractiveHelperSwitch onPress={() => setfolded(false)} />;
		return null;
	} else if (!helper) {
		component = <ActiveHelpersList navigation={navigation} navigationState={navigationState} />;
	} else {
		component = (
			<InteractiveHelperPanel
				history={history}
				interactiveHelper={helper}
				panel={panels[panelId] ? panels[panelId] : helper.default_panel}
				goBack={goBack}
				goToPanel={goToPanel}
			/>
		);
	}

	return (
		<>
			{component}
			{isWeb() && (
				<IconButton
					style={{
						position: "absolute",
						bottom: 10,
						left: 10,
						backgroundColor: global.colors.cardBackground,
					}}
					icon={{ type: FontAwesome, name: "forward" }}
					onPress={() => setfolded(true)}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const helper = state.interactiveHelpers.openedHelper;
	return {
		helper,
		panels: state.interactiveHelpers.panels[helper?.id] || {},
		panelId: state.interactiveHelpers.openedPanelId,
	};
};

export default connect(mapStateToProps)(InteractiveHelperDrawer);
