import * as React from "react";
import { Linking, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { socket } from "../../network/websocket";
import { fetchSubscriptionInfo } from "../../store/slices/subscriptionSlice";
import { fullScreenContentWidth } from "../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../styles/theming";
import { ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import AccountLevelText from "./AccountLevelText";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

function CurrentSbuscriptionInfo({ dispatch, user, subscriptionInfo }) {
	const styles = stylesMethod(global.theme);
	const { renewedLevel, nextPayment, levelWithoutUpgrades, renewedPaid } = subscriptionInfo;

	const confirmCancelRef = React.useRef();

	React.useEffect(() => {
		const fetchInfo = () => dispatch(fetchSubscriptionInfo());
		socket.on("profile_updated", fetchInfo);
		return () => {
			socket.off("profile_updated", fetchInfo);
		};
	}, []);

	React.useEffect(() => {
		if (!subscriptionInfo.fetched) dispatch(fetchSubscriptionInfo());
	}, [user.id, subscriptionInfo]);

	if (!subscriptionInfo.fetched) return null;
	if (!subscriptionInfo.nextPayment && !user.profile.reward_level) return null;

	const renewing = user.profile.reward_level === renewedLevel;

	return (
		<View style={[styles.container, ws({ maxWidth: fullScreenContentWidth() })]}>
			<AppText centered>
				{_("You have an active %(subscription_level)s subscription", "upgrade account screen", {
					subscription_level: <AccountLevelText key="lvl" bold level={levelWithoutUpgrades} />,
				})}
			</AppText>
			<AppText centered hide={user.profile.reward_level === levelWithoutUpgrades} size="small" bold>
				{_(
					"(upgraded to a %(subscription_level)s subscription)",
					"upgrade account screen, indicated that the sub is upgraded thanks to referals",
					{
						subscription_level: <AccountLevelText key="lvl" bold level={user.profile.reward_level} />,
					}
				)}
			</AppText>
			<AppText centered hide={renewing}>
				{_(
					"You subscription will be changed to %(subscription_level)s on %(renewal_date)s",
					"upgrade account screen",
					{
						subscription_level: <AccountLevelText key="lvl" bold level={renewedPaid} />,
						renewal_date: nextPayment?.format("LL"),
					}
				)}
			</AppText>
			<AppText centered hide={!renewing}>
				{_("Renewal date:", "upgrade account screen")}
			</AppText>
			<AppText centered hide={!renewing}>
				{nextPayment?.format("LL")}
			</AppText>

			{subscriptionInfo && !!subscriptionInfo.nextPayment && !!subscriptionInfo.renewedLevel && (
				<ButtonsRow>
					<AppButton
						title={_("Cancel subscription")}
						onPress={() => confirmCancelRef.current.show()}
						danger
					/>
					<AppButton
						title={_("Update payment info")}
						onPress={() => Linking.openURL(user.profile.update_sub_url)}
						to={user.profile.update_sub_url}
					/>
				</ButtonsRow>
			)}
			<CancelSubscriptionModal reference={confirmCancelRef} />
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		subscriptionInfo: state.subscriptionInfo,
	};
};

export default connect(mapStateToProps)(CurrentSbuscriptionInfo);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			borderWidth: 1,
			borderColor: colors.lightBorder,
			padding: 8,
			margin: 8,
			...ws({ mwxWidth: fullScreenContentWidth() }),
		},
	})
);
