import { MaterialIcons } from "@expo/vector-icons";
//import Clipboard from "@react-native-community/clipboard";
// import { Clipboard } from "react-native"
import * as Clipboard from 'expo-clipboard';
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { BuildStyleMethod } from "../../styles/theming";
import { ws } from "../../tools/generic";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import Cond from "../meta/Cond";
import CondView from "../meta/CondView";
import OAIReclamation from "./OAIReclamation";

function OAIOutput({ oairequest, loading, showEmpty, noRating, input, dispatch, user }) {
	const styles = stylesMethod(global.theme);

	const [showrisky, setshowrisky] = React.useState(false);
	const [rating, setrating] = React.useState(0);

	const modalRef = React.useRef();

	const copiedModalRef = React.useRef();

	React.useEffect(() => {
		setshowrisky(false);
		setrating(0);
	}, [oairequest]);

	const isCompleteOutput = React.useMemo(() => {
		if (!oairequest?.ai_output) return false;
		return oairequest.ai_output.trim().endsWith(".");
	}, [oairequest?.ai_output]);

	const ratePositively = React.useCallback(() => {
		if (rating > 0) return;
		// rate negatively twice
		const chain = apiFetch(`users/${user.id}/openairequest/${oairequest?.id}/rate-positively`, "POST");
		if (rating < 0)
			chain.then(() => apiFetch(`users/${user.id}/openairequest/${oairequest?.id}/rate-positively`, "POST"));

		setrating(1);
	}, [rating, oairequest?.id]);

	const rateNegatively = React.useCallback(() => {
		if (rating < 0) return;
		// rate negatively twice
		const chain = apiFetch(`users/${user.id}/openairequest/${oairequest?.id}/rate-negatively`, "POST");
		if (rating > 0)
			chain.then(() => apiFetch(`users/${user.id}/openairequest/${oairequest?.id}/rate-negatively`, "POST"));

		setrating(-1);
	}, [rating, oairequest?.id]);

	if (loading) {
		return (
			<View style={styles.container}>
				<AppText color="hint" italics centered>
					{_("The AI is writing... it might take a moment.")}
				</AppText>
				<AppActivityIndicator style={{ marginTop: 16 }} />
			</View>
		);
	}

	if (!oairequest) {
		if (!showEmpty) return null;
		return (
			<View style={styles.container}>
				<AppText color="hint" italic>
					{_("The AI output will be shown here")}
				</AppText>
			</View>
		);
	}

	if (oairequest.no_result) {
		return (
			<View style={styles.container}>
				<AppText>
					{_("No pregenarated content matching your settings", "pregenerated AI content not found")}
				</AppText>
			</View>
		);
	}
	if (oairequest.error) {
		return (
			<View style={styles.container}>
				<AppText>{_("There was an error with the result")}</AppText>
			</View>
		);
	}

	if (oairequest.safety_label >= 2 && !showrisky) {
		return (
			<View style={styles.container}>
				<AppText centered color="secondary">
					{_(
						"The generated content has been judged unsafe and won't be shown.",
						"ai generated content has sensitive content"
					)}
				</AppText>

				<Cond show={oairequest.refunded}>
					<AppText centered color="danger">
						{_("Your tokens have not been consumed.", "ai generated content has sensitive content")}
					</AppText>
					<AppText centered color="hint" style={{ marginTop: 8 }}>
						{_(
							"Please note that your tokens will be consumed in the future if you continuously generate unsafe content.",
							"ai generated content has sensitive content"
						)}
					</AppText>

					<AppText centered color="hint" style={{ marginTop: 8 }}>
						{_(
							"Make sure your parameters do not contain sensitive themes.",
							"ai generated content has sensitive content"
						)}
					</AppText>
					<AppText centered color="hint">
						{_(
							"You can try again if you believe it was a mistake.",
							"ai generated content has sensitive content"
						)}
					</AppText>
				</Cond>

				<Cond show={!oairequest.refunded}>
					<AppText centered color="danger">
						{_(
							"You have generated too much unsafe content to receive a tokens refund.",
							"ai generated content has sensitive content"
						)}
					</AppText>
					<AppText centered color="hint" style={{ marginTop: 8 }}>
						{_(
							"You will receive refunds for unsafe content again after a few days.",
							"ai generated content has sensitive content"
						)}
					</AppText>
				</Cond>
			</View>
		);
	}

	return (
		<>
			<View style={styles.container}>
				<AppText>
					{oairequest.ai_output}
					<AppText color="hint" hide={isCompleteOutput}>
						[{_("max output size reached", "message showing where is the end of the ai output when it is not clear")}]
					</AppText>
				</AppText>

				<View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
					<IconButton
						icon={{ type: MaterialIcons, name: "content-copy" }}
						transparent
						onPress={() => {
							Clipboard.setStringAsync(oairequest.ai_output);
							copiedModalRef.current.show();
						}}
					/>
					<View>
						<AppText
							color="hint"
							size="small"
							textOnlyStyle={{ textAlign: "right" }}
							style={{ marginTop: 8 }}
						>
							{_("Generation cost: %(cost)s tokens", "AI generation final token cost", {
								cost: oairequest.total_token_cost,
							})}
						</AppText>
						<CondView show={!noRating} style={{ alignItems: "flex-end" }}>
							<AppText color="hint" size="small">
								{_("Rate this output to help the algorithm")}
							</AppText>
							<View style={{ flexDirection: "row" }}>
								<IconButton
									transparent
									icon={{
										type: MaterialIcons,
										name: "thumb-down",
										color: rating < 0 ? global.colors.primary : null,
										size: ws(12),
									}}
									onPress={rateNegatively}
								/>
								<IconButton
									transparent
									icon={{
										type: MaterialIcons,
										name: "thumb-up",
										color: rating > 0 ? global.colors.primary : null,
										size: ws(12),
									}}
									onPress={ratePositively}
								/>
							</View>
						</CondView>
					</View>
				</View>
			</View>

			<OAIReclamation modalRef={modalRef} input={input} oairequest={oairequest} />

			<AppText
				size="small"
				color="url"
				style={{ justifyContent: "flex-end" }}
				onPress={() => modalRef.current.show()}
			>
				{_("something wrong with the result?", "link to refund ai tokens if ai result is empty.")}
			</AppText>

			<TinyFeedbackModal ref={copiedModalRef} message={_("Copied to clipboard!")} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(OAIOutput);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.translucidBackground,
			padding: 16,
		},
	})
);
