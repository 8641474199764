import * as React from "react";
import {FlatList, TextInput, TouchableOpacity, View} from "react-native";
import {Divider} from "react-native-paper";
import {colors} from "../../styles/colors";
import {_} from "../../i18n/i18n";
import {isWeb, ws} from "../../tools/generic";
import {translatedTones, translateTone} from "../../tools/tones";
import ChatInputSubMenu from "./ChatInputSubMenu";
import ToneButton from "./ToneButton";
import {sizes} from "../../styles/font";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import IconButton from "../generic/buttons/IconButton";
import BoxModal from "../generic/modal/BoxModal";
import AppText from "../generic/AppText";

function ToneSelection({show, selectTone, setToneColor}) {
    const toneList = React.useRef(translatedTones()).current
    const [tones, setTones] = React.useState(toneList);
    const [customTone, setCustomTone] = React.useState('');
    const [toneColorOverride, setToneColorOverride] = React.useState('');
    const modalRef = React.useRef();

    const customToneStyle = {
        color: !!customTone ? colors.textDefault : colors.placeholder,
        fontSize: sizes.average,
        flexGrow: 1,
        ...ws({ outlineWidth: 0 }, null)
    };

    const customToneContainerStyles = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.inputFieldBackground,
        paddingHorizontal: 12,
        paddingVertical: 4,
        borderRadius: 16,
        gap: 4
    };

    const colorBoxStyle = {
        height: 32,
        width: 32,
        borderColor: '#FF0000',
        borderStyle: 'solid'
    };

    const updateSearch = (text) => {
        setCustomTone(text);
        setTones(toneList.filter(t => t.name.toLowerCase().includes(text)));
    };

    const openColorPicker = () => {
        modalRef.current.show();
    };

    return (
        <ChatInputSubMenu show={show} headerTitle={_("Select tone")} searchScreenName="ToneSelectionScreen">
            <FlatList
                ListHeaderComponent={() => {
                    return (
                        <>
                            <ToneButton
                                tone={{code: "neutral", name: translateTone("neutral")}}
                                onToneSelected={selectTone}
                            />
                            {isWeb() ? (
                                <View style={customToneContainerStyles}>
                                    <TextInput placeholder={_("Custom Tone")}
                                               style={customToneStyle}
                                               autoFocus={true}
                                               onChangeText={updateSearch}
                                               value={customTone}
                                               onSubmitEditing={() => selectTone(customTone)}
                                    />
                                    <IconButton
                                        style={{backgroundColor: !!toneColorOverride ? toneColorOverride : colors.primary }}
                                        icon={{ type: MaterialCommunityIcons,
                                            size: 22,
                                            name: "palette",
                                            color: global.colors.textDefault}}
                                        onPress={openColorPicker}
                                    />
                                    <IconButton
                                        disabled={!customTone}
                                        onPress={() => selectTone(customTone)}
                                        icon={{ type: MaterialCommunityIcons,
                                            size: 22,
                                            name: "chevron-right",
                                            color: global.colors.textDefault }}
                                    />
                                    <BoxModal ref={modalRef} wide options={[{ title: _("Cancel") }]}>
                                        <View style={{
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                        }}>
                                            {global.colors.userColors.map((item, index) => (
                                                <TouchableOpacity
                                                    key={item}
                                                    style={{...colorBoxStyle, backgroundColor: item,
                                                        borderWidth: (item === toneColorOverride) ? 2 : 0
                                                    }}
                                                    onPress={() => {
                                                        setToneColorOverride(item);
                                                        setToneColor(item);
                                                        modalRef.current.hide();
                                                    }}
                                                />
                                            ))}
                                        </View>
                                    </BoxModal>
                                </View>
                            ) : null}
                        </>
                    );
                }}
                data={tones}
                renderItem={({item}) => <ToneButton tone={item} onToneSelected={selectTone}/>}
                keyExtractor={(item, index) => String(index)}
            />
        </ChatInputSubMenu>
    );
}

export default ToneSelection;
