import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import AppScreenView from "../../AppScreenView";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import { navReplace } from "../../../tools/webnavigation";
import { FontAwesome5 } from "@expo/vector-icons";
import { colors } from "../../../styles/colors";

function WizardGameMasterScreen({ route, navigation }) {
	return (
		<>
			<AppScreenView scroll>
				<AppText bold centered size="large">
					{_("Game master", "option play as a Game master")}
				</AppText>

				<View style={styles.iconContainer}>
					<FontAwesome5 name="book-reader" color={global.colors.textDefault} size={128} />
				</View>

				<AppText style={styles.text} size="large">
					{_("The Game Master is the narrator of the story.")}
				</AppText>
				<AppText style={styles.text} size="large" color={"secondary"}>
					{_(
						"You will create a game that players can join. Each player will then create a character to represent them."
					)}
				</AppText>
				<AppText style={styles.text} size="large" color={"secondary"}>
					{_(
						"Your role is to describe imaginary situations to the players and ask them how they want their characters to act."
					)}
				</AppText>
				<AppText style={styles.text} size="large" color={"secondary"}>
					{_("You then describe the consequences of their actions, and the game progresses.")}
				</AppText>
			</AppScreenView>
			<ButtonFooter
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.goBack() },
					{
						title: _("Let's create a game!"),
						onPress: () => navReplace(navigation, "CreateGameWizardStack"),
					},
				]}
			/>
		</>
	);
}

export default WizardGameMasterScreen;

const styles = StyleSheet.create({
	text: {
		marginTop: 16,
	},
	iconContainer: {
		alignItems: "center",
		marginTop: 12,
	},
});
