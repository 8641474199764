import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import * as React from "react";
import { connect } from "react-redux";
import { _ } from '../../i18n/i18n';
import { apiFetch } from "../../network/apiFetch";
import { removeStoryMarker } from "../../store/slices/storyMarkersSlice";
import { isGM } from "../../tools/games";
import { getMarkerTitle } from "../../tools/storyMarker";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";

function StoryMarkerItem({ marker, separators, onSelected, dispatch, storyMarkers }) {
	React.useEffect(() => {
		if (separators) separators.updateProps("leading", { trailingItem: marker });
	}, [marker]);
	const markerTitle = getMarkerTitle(marker, storyMarkers);
	const confirmDeleteModalRef = React.useRef(null);

	const navigation = useNavigation();

	const options = [];

	if(isGM(marker.game)){
		options.push({
			danger: true,
			title: _("Delete"),
			onPress: () => confirmDeleteModalRef.current.show(),
		});
	}

	return (
		<>
		<ListButton
			title={markerTitle}
			subtitle={marker.name}
			subtitle2={marker.description}
			subtitle2TextProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
			onPress={() => onSelected && onSelected(marker)}
			iconLeft={{ type: MaterialIcons, name: marker.type > 2 ? "bookmark-border" : "bookmark" }}
			style={marker.type > 2 ? { paddingLeft: 32 } : null}
			options={options}
			narrow
		/>
		<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete Story Marker")}
				message={_("Are you sure you want to delete this story marker?")}
				isDelete
				onConfirm={() => {
					navigation.goBack();
					apiFetch("games/" + marker.game + "/story-markers/" + marker.id, "DELETE");
					dispatch(removeStoryMarker(marker));
				}}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		storyMarkers: state.storyMarkers[ownProps.marker.game] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(StoryMarkerItem);