import * as React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import AppText from "../generic/AppText";

function ToneButton({ tone, onToneSelected }) {
	return (
		<TouchableOpacity onPress={() => onToneSelected(tone.code)} style={styles.container}>
			<AppText>{tone.name}</AppText>
		</TouchableOpacity>
	);
}

export default ToneButton;

const styles = StyleSheet.create({
	container: {
		padding: 8
	}
});
