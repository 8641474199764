import * as React from "react";
import { View, StyleSheet, TouchableOpacity, Modal } from "react-native";
import RGTokenIcon, { tokenGlyphMap } from "../RGTokenIcon";
import BoxModal from "./modal/BoxModal";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";

function ColorSelector({ currentColor, setColor }) {
	const modalRef = React.useRef();

	return (
		<TouchableOpacity style={styles.container} onPress={() => modalRef.current.show()}>
			<View style={{ height: 56, width: 56, backgroundColor: global.colors.userColors[currentColor] }} />
			<BoxModal ref={modalRef} wide options={[{ title: _("Cancel") }]}>
				<View style={styles.list}>
					{global.colors.userColors.map((item, index) => (
						<TouchableOpacity
							key={item}
							style={[styles.colorListed, { backgroundColor: item }]}
							onPress={() => {
								setColor(index);
								modalRef.current.hide();
							}}
						/>
					))}
				</View>
			</BoxModal>
		</TouchableOpacity>
	);
}

export default ColorSelector;

const styles = StyleSheet.create({
	container: {
		height: 68,
		width: 64,
		justifyContent: "center",
		alignItems: "center"
	},
	list: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	colorListed: {
		margin: 8,
		height: 32,
		width: 32,
	},
});
