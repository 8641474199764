import * as React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { createNewGame } from "../../../store/slices/gamesSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { ws } from "../../../tools/generic";
import { simpleReducer } from "../../../tools/react";
import { navReplace } from "../../../tools/webnavigation";
import AppScreenView from "../../AppScreenView";
import AppText from "../../generic/AppText";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import InputField from "../../generic/InputField";
import Cond from "../../meta/Cond";

function WizardCreateGamePassword({ route, navigation, dispatch }) {
	const { game: gameReceived } = route.params;
	const [game, updateGame] = React.useReducer(simpleReducer, gameReceived);

	const [creating, setcreating] = React.useState(false);

	React.useEffect(() => {
		if (route.params?.game) {
			updateGame(route.params?.game);
		}
	}, [route.params?.game]);

	const create = React.useCallback(async () => {
		setcreating(true);

		const data = { ...game };
		delete data.sheet_template;
		delete data.template_selected;
		delete data.tags;

		const newGame = await dispatch(
			createNewGame({
				...data,
				password: game.password || null,
				cover: game.cover ? game.cover.name : null,
				sheet: game.sheet_template,
				tags: game.tags.map((t) => t.name),
				dice_sets: game.dice_sets.map((ds) => ds.id),
				public_template: game.template_selected?.id,
			})
		);

		setcreating(false);
		navReplace(navigation, "GameStack", { gameslug: newGame.slug, screen: "Game" });
	}, [game]);

	return (
		<>
			<AppScreenView>
				<View style={ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })}>
					<InputField
						style={{ marginTop: 128 }}
						value={game.password}
						onChangeText={(password) => updateGame({ password })}
						label={_("Game password (optional)")}
					/>
					<AppText hide={game.password} style={{ marginTop: 32 }}>
						{_("Your game is not password locked and will be open for players to join freely.")}
					</AppText>
					<Cond show={game.password}>
						<AppText centered color="attentionSecondary" style={{ marginTop: 16 }}>
							{_("Warning", "information for the user")}
						</AppText>
						<AppText style={{ marginTop: 16 }}>
							{_(
								'Games that use a password can be found in most places but will not appear in the simple "Find a game to join" tool.'
							)}
						</AppText>
						<AppText style={{ marginTop: 16 }}>
							{_(
								"To increase your chances to find players, we recommend leaving your game open with no password."
							)}
						</AppText>
					</Cond>
				</View>
			</AppScreenView>

			<ButtonFooter
				narrow={false}
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.navigate("WizardCreateGameMetaData", { game }) },
					{
						title: game.password ? _("Create locked game") : _("Create open game"),
						onPress: create,
						disabled: creating,
					},
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default connect(mapStateToProps)(WizardCreateGamePassword);
