import * as React from "react";
import { View, StyleSheet } from "react-native";
import { globalStyles } from "../../styles/global";
import RGTokenIcon from "../RGTokenIcon";
import InputField from "../generic/InputField";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { connect } from "react-redux";
import { usePrevious } from "../../tools/react";
import { colors } from "../../styles/colors";

function SymbolItem({ symbol, style, dispatch, gameId }) {
	const [name, setname] = React.useState(symbol.name);
	const previousName = usePrevious(name);
	const nameRef = React.useRef(name);
	nameRef.current = name;

	const saveTimeout = React.useRef();

	const save = React.useCallback(() => {
		clearTimeout(saveTimeout.current);
		saveTimeout.current = null;
		let url = gameId ? `games/${gameId}/` : "";
		url += `die-symbols/${symbol.id}`;
		apiFetch(url, "PATCH", { name: nameRef.current });
	}, [gameId, symbol.id]);

	React.useEffect(() => {
		if (previousName === undefined || previousName === null) return () => null;
		saveTimeout.current = setTimeout(save, 3000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [name]);

	React.useEffect(() => {
		() => {
			saveTimeout.current && save();
		};
	}, [save]);

	return (
		<View style={[globalStyles.rc, style]}>
			<RGTokenIcon name={symbol.value} size={40} style={{ marginRight: 8 }} color={colors.textDefault} />
			<InputField
				value={name}
				onChangeText={setname}
				label={_("Symbol name", "die symbol name")}
				style={{ flex: 1 }}
				onBlur={() => saveTimeout.current && save()}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		gameId: state.games.currentId,
	};
};

export default connect(mapStateToProps)(SymbolItem);


