import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import AppScreenView from "../../AppScreenView";
import { globalStyles } from "../../../styles/global";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import ButtonsRow from "../../generic/buttons/ButtonsRow";
import AppButton from "../../generic/buttons/AppButton";
import { Hoverable } from "react-native-web-hooks";
import { cStyle } from "../../../tools/react";
import { colors } from "../../../styles/colors";
import { gameScreenPadding } from "../../../styles/dynamicStyles";
import { Entypo, Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import RGIcon from "../../RGIcon";
import CondView from "../../meta/CondView";
import { fadeLayout } from "../../../tools/animation";
import { BuildStyleMethod } from "../../../styles/theming";

function WizardRoleScreen({ route, navigation }) {
	const styles = stylesMethod(global.theme);
	React.useEffect(() => {
		fadeLayout();
	}, []);
	return (
		<AppScreenView style={styles.screen} scroll>
			<AppText centered style={{ marginBottom: 16 }} bold size="large">
				{_("I want to be a")}
			</AppText>
			<View style={styles.optionsContainer}>
				<Hoverable>
					{(isHovered) => (
						<TouchableOpacity
							style={cStyle([styles.option, [isHovered, styles.optionHovered]])}
							onPress={() => navigation.navigate("CreateGameWizardStack")}
						>
							<AppText bold centered size="large" color="primary">
								{_("Game master", "option play as a Game master")}
							</AppText>

							<View style={styles.iconContainer}>
								<FontAwesome5 name="book-reader" color={global.colors.textDefault} size={128} />
							</View>
							<AppText style={styles.text} size="large">
								{_("The Game Master is the narrator of the story.")}
							</AppText>
							<CondView show={isHovered}>
								<AppText style={styles.text} size="large" color="secondary">
									{_(
										"You will create a game that players can join. Each player will then create a character to represent them."
									)}
									{"\n\n"}
									{_(
										"Your role is to describe imaginary situations to the players and ask them how they want their characters to act."
									)}
									{"\n\n"}
									{_("You then describe the consequences of their actions, and the game progresses.")}
								</AppText>
							</CondView>
						</TouchableOpacity>
					)}
				</Hoverable>

				<Hoverable>
					{(isHovered) => (
						<TouchableOpacity
							style={cStyle([styles.option, [isHovered, styles.optionHovered]])}
							onPress={() => navigation.navigate("FindGameWizardStack")}
						>
							<AppText bold centered size="large"  color="primary">
								{_("Player", "option play as a player (as opposed to a game master)")}
							</AppText>
							<View style={styles.iconContainer}>
								<RGIcon name="die" color={global.colors.textDefault} size={128} />
							</View>
							<AppText style={styles.text} size="large">
								{_(
									"As a player, you will join a game with a character representing you."
								)}
							</AppText>
							<CondView show={isHovered}>
								<AppText style={styles.text} size="large" color="secondary">
									{_(
										"The Game Master will describe a situation and ask how the players' characters want to act in response."
									)}
									{"\n\n"}
									{_(
										"Once you wrote what your character does, the Game Master will describe the consequences of your action, and the game progresses."
									)}
									{"\n\n"}
									{_(
										"Most of the times, you will have to roll some dice to determine if your character succeeds or not."
									)}
								</AppText>
							</CondView>
						</TouchableOpacity>
					)}
				</Hoverable>
			</View>
		</AppScreenView>
	);
}

export default WizardRoleScreen;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	screen: {
		marginTop: 128,
	},
	optionsContainer: {
		justifyContent: "center",
		flexDirection: "row",
		alignItems: "flex-start"
	},
	iconContainer: {
		alignItems: "center",
		marginTop: 12,
	},
	option: {
		borderWidth: 2,
		borderRadius: 16,
		borderColor: colors.lightBorder,
		marginHorizontal: 32,
		padding: 32,
		flex: 1,
	},
	optionHovered: {
		borderColor: colors.boldBorder,
	},
	text: {
		marginTop: 16,
	},
}));
