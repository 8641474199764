import { AntDesign, Feather } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { deleteInteractiveHelperPanelOption, receiveHelperPanelOptions, receivePanelOptionEdit } from "../../store/slices/interactiveHelpersSlice";
import { globalStyles } from "../../styles/global";
import { idEqual } from "../../tools/generic";
import { simpleReducer, usePrevious } from "../../tools/react";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import IconButton from "../generic/buttons/IconButton";
import InputField from "../generic/InputField";
import CondView from "../meta/CondView";

function InteractiveHelperOptionItem({ option, panel, helper, onNavigateOut, dispatch, panels, options, userId }) {
	const navigation = useNavigation();
	const route = useRoute();

	const [editedOption, updateOption] = React.useReducer(simpleReducer, { ...option });

	const prevOptionData = usePrevious(editedOption);
	const optionRef = React.useRef(editedOption);
	optionRef.current = editedOption;

	const saveTimeout = React.useRef(null);

	const index = options.findIndex((o) => o.id === option.id);
	const patchURL = `users/${userId}/helpers/${helper.id}/panels/${panel.id}/options/${option.id}`;

	const save = React.useCallback(() => {
		clearTimeout(saveTimeout.current);
		dispatch(receivePanelOptionEdit(optionRef.current));
		apiFetch(patchURL, "PATCH", { title: optionRef.current.title.trim() });
	}, [patchURL]);

	const setTarget = React.useCallback(
		(target) => {
			apiFetch(patchURL, "PATCH", { target_id: target.id });
			updateOption({ target });
		},
		[patchURL]
	);

	const deleteOption = React.useCallback(() => {
		dispatch(deleteInteractiveHelperPanelOption(helper.id, option));
	}, [helper.id, option]);

	const changeOrder = React.useCallback(
		(moveUp) => {
			let newIndex = moveUp ? index - 1 : index + 1;
			const newOptions = options.slice();
			const replaced = newOptions[newIndex];
			newOptions[newIndex] = option;
			newOptions[index] = replaced;

			dispatch(receiveHelperPanelOptions({ panelId: panel.id, options: newOptions }));
			apiFetch(`users/${userId}/helpers/${helper.id}/panels/${panel.id}/options-order`, "PATCH", {
				order: newOptions.map((o) => o.id),
			});
		},
		[options, option, index, userId, helper.id, panel.id]
	);

	React.useEffect(() => {
		dispatch(receivePanelOptionEdit(editedOption));
	}, [editedOption.target?.id]);

	React.useEffect(() => {
		if (!prevOptionData) return () => null;
		saveTimeout.current = setTimeout(save, 3000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [editedOption.title, save]);

	// Mount/Dismount effect
	React.useEffect(
		() => () => {
			saveTimeout.current && save();
		},
		[save]
	);

	React.useEffect(() => {
		if (!route.params) return () => null;
		const { selectedPanel, selectedOption } = route.params;
		if (!idEqual(selectedOption, option)) return () => null;

		setTarget(selectedPanel);

		navigation.setParams({ selectedPanel: null, selectedOption: null });
	}, [route.params, option, setTarget]);

	const storeTarget = panels[editedOption.target?.id];

	const TargetTag = storeTarget ? TouchableOpacity : View;

	return (
		<View style={styles.container}>
			<CondView show={options.length == 1} style={{ width: 8 }} />
			<CondView show={options.length > 1}>
				<IconButton
					icon={{ type: AntDesign, name: "caretup" }}
					transparent
					style={{ flex: 1 }}
					disabled={index === 0}
					onPress={() => changeOrder(true)}
				/>
				<IconButton
					icon={{ type: AntDesign, name: "caretdown" }}
					transparent
					style={{ flex: 1 }}
					disabled={index === options.length - 1}
					onPress={() => changeOrder(false)}
				/>
			</CondView>
			<View style={{ flex: 1 }}>
				<InputField
					value={editedOption.title}
					onChangeText={(t) => updateOption({ title: t })}
					label={_("Option name", "helper panel option placeholder")}
					onBlur={() => {
						saveTimeout.current && save();
					}}
				/>
				<View style={globalStyles.rcsb}>
					<TargetTag
						style={{ flex: 1 }}
						onPress={() => {
							onNavigateOut && onNavigateOut();
							navigation.push("EditInteractiveHelperPanelScreen", {
								panelId: storeTarget.id,
								helperId: helper.id,
							});
						}}
					>
						<AppText>
							{_(
								"Moves to panel:",
								"before indicating to which panel the helper option sends you (i.e: Moves to panel: xxx)"
							)}
						</AppText>
						{!!editedOption.target ? (
							<AppText bold numberOfLines={1} ellipsizeMode="tail">
								{storeTarget?.name}
							</AppText>
						) : (
							<AppText color="hint" italic>
								{_("No destination")}
							</AppText>
						)}
					</TargetTag>
					<AppButton
						title={_("Change", "change helper panel option target")}
						size="small"
						onPress={() => {
							onNavigateOut && onNavigateOut();
							navigation.navigate("SelectPanelOptionTargetScreen", { option, helperId: panel.helper });
						}}
					/>
					<IconButton transparent icon={{ type: Feather, name: "delete" }} onPress={deleteOption} />
				</View>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { option, panel } = ownProps;
	const panels = state.interactiveHelpers.panels[panel.helper] || {};
	return {
		userId: state.user.id,
		options: state.interactiveHelpers.options[panel.id] || Array.rg_empty,
		panels,
	};
};

export default connect(mapStateToProps)(InteractiveHelperOptionItem);

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		marginBottom: 16,
	},
});
