import { StyleSheet } from "react-native";
import { ws } from "../tools/generic";

// const arrowBase = width => ({
// 	border: "solid transparent",
// 	content: " ",
// 	position: "absolute",
// 	borderWidth: $width
// });

// const arrowLeft = (top = "50%", color = "white", width = 10) => ({
// 	...arrow_base(width),
// 	right: "100%",
//     top,
//     borderRightColor: color,
//     marginTop: width*-1,
// });

const arrowBase = (width) => ({
	width: 0,
	height: 0,
	backgroundColor: "transparent",
	borderStyle: "solid",
	borderWidth: width,
	borderTopColor: "transparent",
	borderBottomColor: "transparent",
	borderLeftColor: "transparent",
	borderRightColor: "transparent",
	position: "absolute",
});

export const arrowLeft = ({ top = "calc(50% - 10px)", color = "white", width = 10, bottom }, objectMeasure) => ({
	...arrowBase(width),
	borderLeftWidth: 0,
	borderRightColor: color,
	left: -width,
	top,
	bottom,
});

export const arrowRight = ({ top = "calc(50% - 10px)", color = "white", width = 10, bottom }, objectMeasure) => ({
	...arrowBase(width),
	borderRightWidth: 0,
	borderLeftColor: color,
	right: -width,
	top,
	bottom,
});

export const arrowTop = ({ left = "50%", color = "white", height: width = 10, top }, objectMeasure) => ({
	...arrowBase(width),
	borderTopWidth: 0,
	borderBottomColor: color,
	top: top === undefined ? -width : top,
	left: objectMeasure ? ((objectMeasure.width || 0) - width) * 0.5 : left,
});

export const arrowBottom = ({ left = "50%", color = "white", height: width = 10, bottom }, objectMeasure) => ({
	...arrowBase(width),
	borderBottomWidth: 0,
	borderTopColor: color,
	bottom: bottom === undefined ? -width * ws(2, 1) : bottom,
	left: objectMeasure ? ((objectMeasure.width || 0) - width) * 0.5 : left,
});

export const arrow = (direction, params, objectMeasure) => {
	switch (direction) {
		case "left":
			return arrowLeft(params, objectMeasure);
		case "right":
			return arrowRight(params, objectMeasure);
		case "bottom":
			return arrowBottom(params, objectMeasure);
		default:
			return arrowTop(params, objectMeasure);
	}
};

export const arrowStyles = {
	arrowLeft,
	arrowRight,
	arrowBottom,
	arrowTop,
};

// export const arrowStyles = StyleSheet.create({
//     arrowLeft,
//     triangle: {
//         width: 0,
//         height: 0,
//         backgroundColor: 'transparent',
//         borderStyle: 'solid',
//         borderLeftWidth: 27,
//         borderRightWidth: 27,
//         borderBottomWidth: 43,
//         borderLeftColor: 'transparent',
//         borderRightColor: 'transparent',
//         borderBottomColor:"yellow",
//         // transform: [
//         //     { rotate: '90deg' }
//         // ],
//         // margin: 0,
//         // marginLeft: -6,
//         // borderWidth: 0,
//         // borderColor:"black"
//     }
// })
