import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import store from "../../store/store";
import { gameHeaderOptions, modalScreenOptions } from "../../tools/navigationOptions";
import CharacterSheetModalStack from "./CharacterSheetModalStack";
import CharacterSheetScreen from "./CharacterSheetScreen";
import ChangeAvatarScreen from "../avatar/ChangeAvatarScreen";
import CharacterLanguagesList from "../languages/CharacterLanguagesList";
import BrowseSheetTemplatesScreen from "./BrowseSheetTemplatesScreen";
import { colors } from "../../styles/colors";
import CharacterSheetLoader from "./CharacterSheetLoader";
import MacrosNavigation from "../macros/MacrosNavigation";
import { GameContext } from "../navigation/GameStack";
import UploadImageModal from "../imageupload/UploadImageModal";
import { ensureBackScreen } from "../../tools/webnavigation";

const Stack = createStackNavigator();

const sheetScreenOptions = ({ route, navigation }) => {
	let { sheetId, characterId, gameId, gameslug, isTemplate } = route.params;
	const state = store.getState();
	const character = state.characters[characterId];
	const sheet = state.sheets[sheetId] || state.sheets[character?.sheet];

	if (!character && sheet) {
		return { headerTitle: sheet.save_name || _("Edit template", "edit character sheet template") };
	}
	const game = state.games[gameslug || gameId || character?.game];

	return gameHeaderOptions({
		route,
		navigation,
		game,
		title: sheet?.character_name || sheet?.save_name || _("Unnamed template", "unnamed character sheet template"),
		subtitle: isTemplate ? _("Edit template", "edit character sheet template") : game?.name,
	});
};

function CharacterSheetStack({ route }) {	
	const game = React.useContext(GameContext);
	
	return (
		<Stack.Navigator screenOptions={({ navigation: screenNavigation, route}) => {
			if (route.name != "BrowseSheetTemplatesScreen") {
				// NOTE ensures that the back button will navigate to BrowseSheetTemplatesScreen when there is no screen history
				ensureBackScreen(screenNavigation, "BrowseSheetTemplatesScreen");
			}

			return { headerTintColor: global.colors.textDefault }
		}}
		
		>
			<Stack.Screen
				name="BrowseSheetTemplatesScreen"
				component={BrowseSheetTemplatesScreen}
				options={{ headerTitle: _("Character sheet templates"), title: _("Sheet templates | Role Gate") }}
			/>
			<Stack.Screen
				name="CharacterSheetScreen"
				children={(props) => <CharacterSheetLoader {...props} RenderComponent={CharacterSheetScreen} gameslug={game?.slug} />}
				options={sheetScreenOptions}
				initialParams={{ characterId: route.params?.characterId }}
			/>
			<Stack.Screen name="ChangeAvatarScreen" component={ChangeAvatarScreen} options={sheetScreenOptions} />
			<Stack.Screen
				name="CharacterSheetTemplateScreen"
				children={(props) => <CharacterSheetLoader {...props} RenderComponent={CharacterSheetScreen} gameslug={game?.slug} />}
				options={sheetScreenOptions}
				initialParams={{ characterId: route.params?.characterId }}
			/>
			
			<Stack.Screen
				name="CharacterLanguagesList"
				component={CharacterLanguagesList}
				options={sheetScreenOptions}
			/>
			<Stack.Screen name="UploadSheetImageModal" component={UploadImageModal} options={modalScreenOptions} />
			{MacrosNavigation(Stack)}
			{CharacterSheetModalStack(Stack)}
		</Stack.Navigator>
	);
}

export default CharacterSheetStack;
