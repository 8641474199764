import { Entypo, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { colors } from "../../../styles/colors";
import { globalStyles } from "../../../styles/global";
import IconButton from "../../generic/buttons/IconButton";
import { connect } from "react-redux";
import { isInGame } from "../../../tools/games";
import { useNavigation } from "@react-navigation/native";
import { deleteBookmark, fetchBookmarks, saveBookmark } from "../../../store/slices/bookmarksSlice";
import { receiveBookmarkedGames } from "../../../store/slices/gamesSlice";
import TinyFeedbackModal from "../../generic/modal/TinyFeedbackModal";
import { _, _n } from "../../../i18n/i18n";
import { ws } from "../../../tools/generic";
import { fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { useLayout } from "react-native-web-hooks";

import { BuildStyleMethod } from "../../../styles/theming";

function GameDetailsQuickActions({ game, openGame, dispatch, user, bookmarkedGames, storyMarkers }) {
	const styles = stylesMethod(global.theme);
	const storyMarkersLength = React.useMemo(() => storyMarkers.filter((sm) => !sm.hidden).length, [storyMarkers]);
	const inGame = isInGame(user, game);
	const navigation = useNavigation();

	const bookmarkModalRef = React.useRef();
	const bookmarkRemovedModalRef = React.useRef();

	const line = (
		<View style={[styles.crossLineContainer, { width: fullScreenLeftPadding() }]}>
			<View style={styles.crossLine} />
		</View>
	);

	const iconParams = { color: global.colors.textLight, size: 16 };

	const bookmarked = bookmarkedGames.some((g) => g.id === game.id);

	const toggleBookmark = React.useCallback(() => {
		if (bookmarked) {
			dispatch(deleteBookmark(game.id));
			dispatch(receiveBookmarkedGames({ games: bookmarkedGames.rg_removeElementByIdPure(game.id) }));
			bookmarkRemovedModalRef.current.show();
		} else {
			dispatch(saveBookmark(game.id));
			dispatch(receiveBookmarkedGames({ games: bookmarkedGames.concat(game) }));
			bookmarkModalRef.current.show();
		}
	}, [bookmarked, game, bookmarkedGames]);

	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={[globalStyles.rc, { marginTop: 8, marginBottom: 8 }]}>
			{line}
			<IconButton
				hide={(inGame && !game.archived) || !game.allow_spectators}
				transparent
				titleColorName="textLight"
				style={styles.boxButton}
				icon={{ type: Entypo, name: "open-book", ...iconParams }}
				title={_("Read", "read game")}
				onPress={openGame}
				disabled={!game.allow_spectators}
			/>
			<IconButton
				hide={!inGame || game.archived}
				transparent
				titleColorName="textLight"
				style={styles.boxButton}
				icon={{ type: MaterialIcons, name: "chat-bubble", ...iconParams }}
				title={_("Play", "play game")}
				onPress={openGame}
			/>
			{line}
			<IconButton
				hide={!game.allow_spectators}
				transparent
				titleColorName="textLight"
				style={styles.boxButton}
				icon={{ type: FontAwesome, name: "th-list", ...iconParams }}
				disabled={!storyMarkersLength}
				title={_n("%(number)s scene", "%(number)s scenes", "number of scenes in a game", storyMarkersLength, {
					number: storyMarkersLength,
				})}
				onPress={() => navigation.navigate("GameStack", { gameslug: game.slug, screen: "IndexScreen" })}
			/>
			{!inGame && !!user && line}
			<IconButton
				hide={inGame || !user}
				transparent
				titleColorName="textLight"
				style={styles.boxButton}
				icon={{
					type: MaterialIcons,
					name: bookmarked ? "bookmark" : "bookmark-border",
					...iconParams,
				}}
				title={bookmarked ? _("Bookmarked", "bookmarked game") : _("Bookmark", "bookmark a game")}
				onPress={toggleBookmark}
			/>
			<View style={[styles.crossLineContainer, ws({ flex: 1, width: fullScreenLeftPadding() })]}>
				<View style={styles.crossLine} />
			</View>
			<TinyFeedbackModal ref={bookmarkModalRef} message={_("Bookmarked!", "confirm bookmark added")} />
			<TinyFeedbackModal
				ref={bookmarkRemovedModalRef}
				message={_("Bookmark removed", "confirm bookmark removed")}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { game } = ownProps;
	return {
		user: state.user,
		storyMarkers: state.storyMarkers[game?.id] || state.savedStore.storyMarkers[game?.id] || [],
		bookmarkedGames: state.games.bookmarked || state.savedStore.games.bookmarked,
	};
};

export default connect(mapStateToProps)(GameDetailsQuickActions);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		boxButton: {
			minWidth: 72,
			...ws({ height: 48 }),
		},
		crossLineContainer: {
			flex: 1,
			alignItems: "center",
			justifyContent: "center",
			left: 0,
			opacity: 0.5,
			...ws({ flex: null }),
		},
		crossLine: {
			width: "100%",
			borderTopColor: colors.secondary,
			borderTopWidth: 1,
		},
	})
);
