import { FontAwesome } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import {
	getCurrentGameUI,
	setTalkToCharacterIds,
	setTalkToPlayerIds,
	setWhisperToCharacterIds,
	setWhisperToPlayerIds,
} from "../../store/slices/gamesUISlice";
import { colors } from "../../styles/colors";
import { idEqual, ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import CharacterIconButton from "../characters/CharacterIconButton";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import UserAvatar from "../users/UserAvatar";
import { SELECT_CHARACTER_TALK_TO, SELECT_CHARACTER_WHISPER } from "../characters/FilteredCharacterList";

import { BuildStyleMethod } from "../../styles/theming";

function TargetsBox({
	gameId,
	dispatch,
	users,
	characters,
	whisperToCharacterIds,
	whisperToPlayerIds,
	talkToCharacterIds,
	talkToPlayerIds,
	gameUIId
}) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const route = useRoute();
	const characterSelected = characters[route.params?.characterSelectedId];

	let targetIds = null;

	const playerSelected = route.params?.playerSelected;
	
	const characterSelectedCodeSent = route.params?.characterSelectedCodeSent;
	const prevCharacterSelected = usePrevious(characterSelected);

	const isWhisper = whisperToCharacterIds?.length;
	if (isWhisper) {
		targetIds = whisperToPlayerIds?.length ? whisperToPlayerIds : whisperToCharacterIds;
	} else {
		targetIds = talkToPlayerIds?.length ? talkToPlayerIds : talkToCharacterIds;
	}

	const targetingUsers = talkToPlayerIds?.length;

	let setIds = targetingUsers ? setTalkToPlayerIds : setTalkToCharacterIds;
	if (isWhisper) {
		setIds = targetingUsers ? setWhisperToPlayerIds : setWhisperToCharacterIds;
	}

	React.useEffect(() => {
		if (idEqual(prevCharacterSelected, characterSelected)) return;
		if (characterSelected && characterSelectedCodeSent) {
			if (characterSelectedCodeSent === SELECT_CHARACTER_WHISPER) {
				dispatch(
					setWhisperToCharacterIds({
						gameId: gameUIId,
						value: whisperToCharacterIds.rg_pushUniquePure(characterSelected.id),
					})
				);
			} else if (characterSelectedCodeSent === SELECT_CHARACTER_TALK_TO) {
				dispatch(
					setTalkToCharacterIds({
						gameId: gameUIId,
						value: talkToCharacterIds.rg_pushUniquePure(characterSelected.id),
					})
				);
			}
			navigation.setParams({ characterSelectedId: null, characterSelectedCodeSent: null });
		}
	}, [characterSelected, characterSelectedCodeSent, whisperToCharacterIds, talkToCharacterIds, gameUIId]);

	React.useEffect(() => {
		if (!playerSelected) return () => null;
		dispatch(setTalkToPlayerIds({ gameId: gameUIId, value: talkToPlayerIds.rg_pushUniquePure(playerSelected.id) }));
		navigation.setParams({ playerSelected: null });
	}, [playerSelected, talkToPlayerIds, gameUIId]);

	const removeTargetId = React.useCallback(
		(targetId) => {
			dispatch(setIds({ gameId: gameUIId, value: targetIds.filter((id) => id != targetId) }));
		},
		[targetIds, setIds, gameUIId]
	);

	const renderItem = React.useCallback(
		({ item }) => {
			if (targetingUsers) {
				const targetUser = users[item];
				return (
					<TouchableOpacity
						style={{ paddingHorizontal: 2, alignItems: "center" }}
						onPress={() => removeTargetId(item)}
					>
						<View style={{ height: 48, justifyContent: "center" }}>
							<UserAvatar user={targetUser} />
						</View>
						<AppText
							size="small"
							numberOfLines={2}
							ellipsizeMode="tail"
							style={{ width: 52 }}
							centered
							textBreakStrategy="highQuality"
						>
							{targetUser.username}
						</AppText>
					</TouchableOpacity>
				);
			} else {
				return <CharacterIconButton character={characters[item]} onPress={() => removeTargetId(item)} />;
			}
		},
		[targetingUsers, removeTargetId, characters, users]
	);

	if (!targetIds?.length) return null;

	return (
		<View style={styles.container}>
			<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
				<AppText style={{ marginBottom: 8 }} size="small">
					{isWhisper ? _("Whispering to:", "whisper message") : _("Talking to:", "talk to character/player")}
				</AppText>
				<AppText color="primary" onPress={() => dispatch(setIds({ gameId: gameUIId, value: [] }))}>
					{_("clear")}
				</AppText>
			</View>
			<FlatList
				keyboardShouldPersistTaps="always"
				horizontal
				data={targetIds}
				keyExtractor={(item) => String(item)}
				renderItem={renderItem}
				ListFooterComponent={() => (
					<IconButton
						style={{ marginLeft: 16, height: null }}
						iconContainerStyle={styles.addCharacterIcon}
						icon={{
							type: FontAwesome,
							name: "plus",
							size: 24,
						}}
						square
						transparent
						title={_("Add", "add a character to the list")}
						onPress={() =>
							targetingUsers
								? navigation.navigate("SelectPlayerModal", {
										backToScreen: "Game",
										gameId,
								  })
								: navigation.navigate("SelectTalkToCharacterTarget", {
										sendToScreen: "Game",
										code: isWhisper ? SELECT_CHARACTER_WHISPER : SELECT_CHARACTER_TALK_TO,
								  })
						}
					/>
				)}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	
	const characters = state.characters;
	
	const gameId = state.games.currentId;
	// Used on desktop so that we can separately manage the "talk to" 
	// characters in new messages and in the currently edited line
	let gameUIId = gameId;
	if(ownProps.inlineEditing){
		gameUIId += "inlineEdit"
	}
	const gameUI = state.gamesUI[gameUIId]

	return {
		characters,
		users: state.users,
		whisperToCharacterIds: gameUI?.whisperToCharacterIds,
		whisperToPlayerIds: gameUI?.whisperToPlayerIds,
		talkToCharacterIds: gameUI?.talkToCharacterIds,
		talkToPlayerIds: gameUI?.talkToPlayerIds,
		gameUIId,
	};
};

export default connect(mapStateToProps)(TargetsBox);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		padding: 8,
		borderWidth: 1,
		marginHorizontal: ws(0,4),
		...ws({ backgroundColor: colors.cardBackground }, null),
	},
	addCharacterIcon: {
		height: ws(24, 48),
		width: ws(24, 48),
		justifyContent: "center",
		alignItems: "center",
	},
}));
