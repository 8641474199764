import * as React from "react";
import { View, StyleSheet } from "react-native";
import EmptyScreenView from "../generic/EmptyScreenView";
import { MaterialIcons } from "@expo/vector-icons";
import { _ } from "../../i18n/i18n";
import { getInterlocutor } from "../../tools/directMessages";
import { getUsername } from "../../tools/account";

function EmptyMessageGroup({ group }) {
	const interlocutor = getInterlocutor(group);
	return (
		<EmptyScreenView
			style={{ flex: 1 }}
			messageTop={_("You can now chat with %(username)s!", "empty direct messaging screen", {
				username: getUsername(interlocutor),
            })}
            messageBottom={_('Say hello!', "empty direct messaging screen")}
			icon={{ type: MaterialIcons, name: "chat-bubble" }}
		/>
	);
}

export default EmptyMessageGroup;


