import { getPathFromState, getStateFromPath } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { Platform } from "react-native";

// The configuration is not processed in the same order on Firefox and Chrome
// https://github.com/react-navigation/react-navigation/issues/8839
// This makes using the same path for different screens, ie "/",  impossible,
// as the url will point to a different screen based on the platform.
// To work around this, and make sure we can have screen with an empty path, we give them a different url
// that gets removed in getPathFromState

const prefix = Linking.createURL("/");

const ScreenToPath = {
	MyGames: "",
	Bookmarks: "m/bookmarks",
	Explore: "m/explore",

	CreateAlertScreen: "m/create-alert",

	AvatarRequestWizardStack: "m/avatar-request",
	WizardAvatarRequestConfirmSendModal: "createCLEARCWizardAvatarRequestConfirmSendModalLEAR",
	WizardAvatarRequestSummary: "createCLEARWizardAvatarRequestSummaryCLEAR",
	WizardAvatarRequestDescription: "createCLEARWizardAvatarRequestDescriptionCLEAR",
	WizardAvatarRequestDeadline: "DELWizardAvatarRequestDeadline",

	CharacterSheetStack: "m/sheets",
	CharacterSheetStackGame: "characters",
	CharacterSheetScreen: "view",
	CharacterSheetTemplateScreen: "edit",
	BrowseSheetTemplatesScreen: "DELBrowseSheetTemplatesScreen",

	DiceSetStack: "m/dice-sets",
	EditDiceSetScreen: "edit-set",
	EditDieScreen: "edit-die",
	NameSymbolsScreen: "name-symbols",
	SetDiceRulesScreen: "rules",
	DieSymbolOptionsModal: "DieSymbolOptionsModal",
	CreateSymbolInteractionModal: "symbol-interactions",
	BrowseDiceSetsScreen: "DELBrowseDiceSetsScreen",

	InteractiveHelpersStack: "m/interactive-helpers",
	OpenHelperScreen: "DELOpenHelperScreen",
	EditInteractiveHelperScreen: "edit/helper/:helperId",
	EditInteractiveHelperPanelScreen: "edit/helper/:helperId/panel/:panelId",
	SelectPanelOptionTargetScreen: "DELSelectPanelOptionTargetScreen",

	BrowseGamesScreen: "m/browse",
	MessagesStack: "m/messages",
	MyMessagesScreen: "DELMyMessagesScreen",
	NotificationsScreen: "m/notifications",
	ReferralUpgradeScreen: "DELReferralUpgradeScreen",
	UpgradeAccountScreen: "m/upgrade",
	DiscordSettingsScreen: "s/dis/exchange",
	AvatarRequestTermsAndConditions: "m/avatar-request/terms",
	VoteFeatureScreen: "m/vote",
	BugReportScreen: "m/bug-report",
	AboutRGScreen: "m/about",

	PlayGameWizardStack: "play",
	WizardRoleScreen: "role",

	FindGameWizardStack: "find",
	WizardTagSelectionScreen: "tags",
	WizardAgeSelectionScreen: "age",
	WizardNumPlayersScreen: "players",
	WizardExpectedPace: "pace",
	WizardAvoidTagsScreen: "avoid-tags",
	FindWizardBrowseGamesScreen: "results",
	SelectGameTagsScreen: "tags",
	GameSettingScreen: "settings",
	IndexScreen: "index",
	SelectCover: "cover",
	NoteFullScreen: "notes/DELNoteFullScreen",
	NotesScreen: "notes",
	PublicChatScreen: "public",

	RollDiceStack: "DELRollDiceStack",
	SimpleRollModal: "DELSimpleRollModal",
	AdvancedRollModal: "DELAdvancedRollModal",
	SelectMacroModal: "DELSelectMacroModal",
	SelectCharacter: "DELSelectCharacter",
	CharacterOptionsModal: "DELCharacterOptionsModal",
	LineOptionsModal: "DELLineOptionsModal",
	ShareGameModal: "DELShareGameModal",
	Game: "DELGame",
	StoryMarkerModalStack: "marker",
	StoryMarkerModal: "DELStoryMarkerModal",
	EditStoryMarkerModal: "edit",
	SelectBackground: "background",
	QuickCharacterSearchScreen: "DELQuickCharacterSearchScreen",

	AppSettingsScreen: "m/rolegate-settings",
	DevSettingsScreen: "m/dev-settings",
	AppGameSettingsScreen: "m/game-settings",
	EmailsSettingsScreen: "m/email-settings",
	NotificationSettingsScreen: "m/notifications-settings",
	LocaleSettings: "m/locale-settings",
	AppDisplaySettings: "m/display-settings",
	DeleteAccountScreen: "m/delete-account",

	SelectPlayerModal: "DELSelectPlayerModal",

	SelectGameTagsScreen: "DELSelectGameTagsScreen",

	CreateGameWizardStack: "m/create-game",
	WizardCreateGameDetails: "DELWizardCreateGameDetails",
	WizardCreateGameSheet: "DELWizardCreateGameSheet",
	WizardCreateGameDiceSets: "DELWizardCreateGameDiceSets",
	WizardCreateGameMetaData: "DELWizardCreateGameMetaData",
	WizardCreateGamePassword: "DELWizardCreateGamePassword",

	CreateGameTagsScreen: "DELCreateGameTagsScreen",

	ManageMacrosScreen: "macros/manage",
	ViewSheetMacrosScreen: "macros/view",
	MacroGroupScreen: "macro-group",
	MacroScreen: "macro",

	MessageOptionsModal: "DELMessageOptionsModal",
	SearchGamesOptionsModal: "DELSearchGamesOptionsModal",
	UserOptionsModal: "DELUserOptionsModal",
	CharacterOptionsModal: "DELCharacterOptionsModal",

	JoinGameScreen: "DELJoinGameScreen",
	ChangeAvatarScreen: "change-vatar",

	CreateAccount: "signup",
	Login: "login",

	ManageCharactersScreen: "manage-characters",
	AssignCharacterModal: "DELAssignCharacterModal",
	CoinHistory: "DELCoinHistory",

	OpenAIGeneratorScreen: "m/ai-generator",
	OpenAIPlaygroundScreen: "m/open-ai",

	UploadImageModal: "DELUploadImageModal",

	GiveCoin: "DELGiveCoin",

	SelectTargets: "DELSelectTargets",
};

// If a screen uses parsing somewhere in the config but doesn't higher in the same config, it won't use it at all.
// This allows to reuse the same parsing everytime the screen is used without copy-pasting code
const Parser = {
	BrowseGamesScreen: {
		parse: {
			selectedTags: (tagList) => tagList.split(","),
		},
		stringify: {
			selectedTags: (tags) => (typeof tags === "string" ? tags : tags.map((t) => t.name || t).join(",")),
		},
	},
};

const CharacterSheetStackCONFIG = {
	BrowseSheetTemplatesScreen: ScreenToPath["BrowseSheetTemplatesScreen"],
	CharacterSheetScreen: ScreenToPath["CharacterSheetScreen"] + "/:sheetId?",
	CharacterSheetTemplateScreen: ScreenToPath["CharacterSheetTemplateScreen"] + "/:sheetId",

	ManageMacrosScreen: ScreenToPath["ManageMacrosScreen"],
	ViewSheetMacrosScreen: ScreenToPath["ViewSheetMacrosScreen"],
	MacroGroupScreen: ScreenToPath["MacroGroupScreen"],
	MacroScreen: ScreenToPath["MacroScreen"],
};

const DiceSetStackCONFIG = {
	EditDiceSetScreen: "edit-set",
	EditDieScreen: "edit-die",
	NameSymbolsScreen: "name-symbols",
	SetDiceRulesScreen: "rules",
	DieSymbolOptionsModal: "DieSymbolOptionsModal",
	CreateSymbolInteractionModal: "symbol-interactions",
	BrowseDiceSetsScreen: "DELBrowseDiceSetsScreen",
};

const GameStackCONFIG = {
	path: ":gameslug",
	screens: {
		GameLoader: "DELGameLoader",
		GameSettingScreen: ScreenToPath["GameSettingScreen"],
		IndexScreen: ScreenToPath["IndexScreen"],
		StoryMarkerModalStack: {
			path: ScreenToPath["StoryMarkerModalStack"] + "/:markerId",
			screens: {
				StoryMarkerModal: ScreenToPath["StoryMarkerModal"],
				EditStoryMarkerModal: ScreenToPath["EditStoryMarkerModal"],
				SelectBackground: ScreenToPath["SelectBackground"],
			},
		},
		SelectCover: ScreenToPath["SelectCover"],
		
		SimpleRollModal: ScreenToPath["SimpleRollModal"],
		AdvancedRollModal: ScreenToPath["AdvancedRollModal"],
		SelectMacroModal: ScreenToPath["SelectMacroModal"],

		CharacterSheetStackGame: {
			path: ScreenToPath["CharacterSheetStackGame"] + "/:characterId",
			screens: CharacterSheetStackCONFIG,
		},
		LineOptionsModal: ScreenToPath["LineOptionsModal"],
		SelectTargets: ScreenToPath["SelectTargets"],
		GiveCoin: ScreenToPath["GiveCoin"],
		DiceSetStack: {
			path: "dice",
			screens: DiceSetStackCONFIG,
		},
		SelectCharacter: ScreenToPath["SelectCharacter"],
		// You can reuse a path name, but make sure that the LAST register is the default screen for that path
		// Except that on firefox it should be the opposite -_-... FIRST resgister will be the default.......
		NoteFullScreen: ScreenToPath["NoteFullScreen"],
		NotesScreen: ScreenToPath["NotesScreen"],
		CharacterOptionsModal: ScreenToPath["CharacterOptionsModal"],
		ShareGameModal: ScreenToPath["ShareGameModal"],
		QuickCharacterSearchScreen: ScreenToPath["QuickCharacterSearchScreen"],
		PublicChatScreen: ScreenToPath["PublicChatScreen"],
		CreateGameTagsScreen: ScreenToPath["CreateGameTagsScreen"],
		JoinGameScreen: ScreenToPath["JoinGameScreen"],
		ChangeAvatarScreen: ScreenToPath["ChangeAvatarScreen"],
		ManageCharactersScreen: ScreenToPath["ManageCharactersScreen"],
		ManageMacrosScreen: ScreenToPath["ManageMacrosScreen"],
		UploadImageModal: ScreenToPath["UploadImageModal"],
		Game: ScreenToPath["Game"],
	},
};

export const ScreenToURL = (screenName) => {
	return "/" + ScreenToPath[screenName];
};

const generalScreens = {
	GameStack: GameStackCONFIG,

	CreateAlertScreen: ScreenToPath["CreateAlertScreen"],
	ProfilePage: {
		path: "me/:username",
	},
	PlayGameWizardStack: {
		path: ScreenToPath["PlayGameWizardStack"],
		screens: {
			WizardRoleScreen: ScreenToPath["WizardRoleScreen"],
		},
	},
	FindGameWizardStack: {
		path: ScreenToPath["FindGameWizardStack"],
		screens: {
			WizardTagSelectionScreen: {
				path: ScreenToPath["WizardTagSelectionScreen"],
				parse: {
					preferredTags: (tagList) => tagList.split(","),
				},
			},
			WizardAgeSelectionScreen: ScreenToPath["WizardAgeSelectionScreen"],
			WizardNumPlayersScreen: ScreenToPath["WizardNumPlayersScreen"],
			WizardExpectedPace: ScreenToPath["WizardExpectedPace"],
			WizardAvoidTagsScreen: {
				path: ScreenToPath["WizardAvoidTagsScreen"],
				parse: {
					preferredTags: (tagList) => tagList.split(","),
					avoidedTags: (tagList) => tagList.split(","),
				},
			},
			BrowseGamesScreen: { path: ScreenToPath["FindWizardBrowseGamesScreen"], ...Parser["BrowseGamesScreen"] },
			SelectGameTagsScreen: ScreenToPath["SelectGameTagsScreen"],
		},
	},
	AvatarRequestWizardStack: {
		path: ScreenToPath["AvatarRequestWizardStack"],
		screens: {
			WizardAvatarRequestConfirmSendModal: ScreenToPath["WizardAvatarRequestConfirmSendModal"],
			WizardAvatarRequestSummary: ScreenToPath["WizardAvatarRequestSummary"],
			WizardAvatarRequestDescription: ScreenToPath["WizardAvatarRequestDescription"],
			WizardAvatarRequestDeadline: ScreenToPath["WizardAvatarRequestDeadline"],
		},
	},
	CharacterSheetStack: {
		path: ScreenToPath["CharacterSheetStack"],
		screens: CharacterSheetStackCONFIG,
	},
	DiceSetStack: {
		path: ScreenToPath["DiceSetStack"],
		screens: DiceSetStackCONFIG,
	},
	InteractiveHelpersStack: {
		path: ScreenToPath["InteractiveHelpersStack"],
		screens: {
			OpenHelperScreen: ScreenToPath["OpenHelperScreen"],
			EditInteractiveHelperScreen: ScreenToPath["EditInteractiveHelperScreen"],
			EditInteractiveHelperPanelScreen: ScreenToPath["EditInteractiveHelperPanelScreen"],
			SelectPanelOptionTargetScreen: ScreenToPath["SelectPanelOptionTargetScreen"],
		},
	},
	AppGameSettingsScreen: ScreenToPath["AppGameSettingsScreen"],
	AppDisplaySettings: ScreenToPath["AppDisplaySettings"],
	GameDetailsPanelScreen: ":gameslug/details",
	BrowseGamesScreen: {
		path: ScreenToPath["BrowseGamesScreen"],
		...Parser["BrowseGamesScreen"],
	},
	CreateGameWizardStack: {
		path: ScreenToPath["CreateGameWizardStack"],
		screens: {
			WizardCreateGameDiceSets: ScreenToPath["WizardCreateGameDiceSets"],
			WizardCreateGameMetaData: ScreenToPath["WizardCreateGameMetaData"],
			WizardCreateGamePassword: ScreenToPath["WizardCreateGamePassword"],
			WizardCreateGameSheet: ScreenToPath["WizardCreateGameSheet"],
			CharacterSheetStackWizard: {
				path: "DELCharacterSheetStackWizard",
				screens: {
					BrowseSheetTemplatesScreen: "DELBrowseSheetTemplatesScreen",
					CharacterSheetScreen: "DELCharacterSheetScreen",
					CharacterSheetTemplateScreen: "DELCharacterSheetTemplateScreen",
					ManageMacrosScreen: "DELManageMacrosScreen",
					ViewSheetMacrosScreen: "DELViewSheetMacrosScreen",
					MacroGroupScreen: "DELMacroGroupScreen",
					MacroScreen: "DELMacroScreen",
				},
			},
			DiceSetStack: {
				path: "DELDiceSetStack",
				screens: DiceSetStackCONFIG,
			},
			CreateGameTagsScreen: ScreenToPath["CreateGameTagsScreen"],
			WizardCreateGameDetails: ScreenToPath["WizardCreateGameDetails"],
		},
	},

	MessagesStack: {
		path: ScreenToPath["MessagesStack"],
		screens: {
			MyMessagesScreen: ScreenToPath["MyMessagesScreen"],
			MessageGroupScreen: "/:groupId",
		},
	},

	ViewSheetMacrosScreen: ScreenToPath["ViewSheetMacrosScreen"],
	MacroGroupScreen: ScreenToPath["MacroGroupScreen"],
	MacroScreen: ScreenToPath["MacroScreen"],

	NotificationsScreen: ScreenToPath["NotificationsScreen"],
	UpgradeAccountScreen: ScreenToPath["UpgradeAccountScreen"],
	DiscordSettingsScreen: ScreenToPath["DiscordSettingsScreen"],
	AvatarRequestTermsAndConditions: ScreenToPath["AvatarRequestTermsAndConditions"],
	VoteFeatureScreen: ScreenToPath["VoteFeatureScreen"],
	BugReportScreen: ScreenToPath["BugReportScreen"],
	AboutRGScreen: ScreenToPath["AboutRGScreen"],
	AppSettingsScreen: ScreenToPath["AppSettingsScreen"],
	DevSettingsScreen: ScreenToPath["DevSettingsScreen"],
	EmailsSettingsScreen: ScreenToPath["EmailsSettingsScreen"],
	NotificationSettingsScreen: ScreenToPath["NotificationSettingsScreen"],
	LocaleSettings: ScreenToPath["LocaleSettings"],
	DeleteAccountScreen: ScreenToPath["DeleteAccountScreen"],

	SelectPlayerModal: ScreenToPath["SelectPlayerModal"],
	AssignCharacterModal: ScreenToPath["AssignCharacterModal"],
	CoinHistory: ScreenToPath["CoinHistory"],

	OpenAIGeneratorScreen: ScreenToPath["OpenAIGeneratorScreen"],
	OpenAIPlaygroundScreen: ScreenToPath["OpenAIPlaygroundScreen"],

	CreateGameTagsScreen: ScreenToPath["CreateGameTagsScreen"],
};

export const linkingConfiguration = (isLogged) => ({
	prefixes: [prefix, "https://www.rolegate.com"],
	config: {
		screens: {
			CreateAccount: ScreenToPath["CreateAccount"],
			Login: ScreenToPath["Login"],
			CoinHistory: ScreenToPath["CoinHistory"] + "/DEL",
			...Platform.select({
				native: {
					LoggedOff: "DELLoggedOff",
					MainDrawerNavigator: {
						screens: {
							MainStack: {
								screens: {
									Home: {
										screens: {
											MyGames: ScreenToPath["MyGames"],
											Bookmarks: ScreenToPath["Bookmarks"],
											Explore: ScreenToPath["Explore"],
										},
									},
									...generalScreens,
									ReferralUpgradeScreen: ScreenToPath["ReferralUpgradeScreen"]
								},
							},
						},
					},
				},
				web: {
					// Only add these paths if the user is not logged, otherwise they will interfere with the logged paths that share the name
					...(isLogged
						? {}
						: {
								GameStack: GameStackCONFIG,
								GameLoader: ":gameslug/DELLoader",
								GameDetailsPanelScreen: ":gameslug/details",
								LoggedOff: "DELLoggedOff",
								Explore: ScreenToPath["Explore"],
						  }),

					// Those are always added
					MessageOptionsModal: ScreenToPath["MessageOptionsModal"],
					SearchGamesOptionsModal: ScreenToPath["SearchGamesOptionsModal"],
					UserOptionsModal: ScreenToPath["UserOptionsModal"],
					ReferralUpgradeScreen: ScreenToPath["ReferralUpgradeScreen"],
					SelectGameTagsScreen: ScreenToPath["SelectGameTagsScreen"],
					LineOptionsModal: ScreenToPath["LineOptionsModal"],
					SelectTargets: ScreenToPath["SelectTargets"],
					CharacterOptionsModal: ScreenToPath["CharacterOptionsModal"],
					...(isLogged
						? {
								Main: {
									screens: {
										MainDrawerNavigator: {
											screens: {
												MyGames: ScreenToPath["MyGames"],
												Bookmarks: ScreenToPath["Bookmarks"],
												Explore: ScreenToPath["Explore"],
												...generalScreens,
											},
										},
									},
								},
						  }
						: {}),
				},
			}),
		},
	},

	getStateFromPath: (path, config) => {
		if (path.includes("ref/")) {
			path = path.replace(/ref\/\w+\//, "");
		}
		const bookmarkIndex = path.rg_regexIndexOf(/\/bookmark\/\d+/);
		if (bookmarkIndex > -1) {
			path = path.replace(/\/bookmark\/(\d+)/, "?scene=$1");
		}
		return getStateFromPath(path, config);
	},
	getPathFromState: (state, config) => {
		if (!state) return; // State is undefined on Web, when you go from login to VerifyEmailScreen
		let path = getPathFromState(state, config);

		path = path.replace(/\/DEL[^/]*/g, "");
		path = path.replace(/\/CLEAR[^/]*CLEAR/g, "");

		// Do no display avatar-request params
		path = path.replace(/avatar-request\?.*/, "avatar-request");
		path = path.replace(/avatar-request\/create\?.*/, "avatar-request/create");

		path = path.replace(/&?\w+=null/g, "");
		path = path.replace(/&?\w+=%5Bobject%20Object%5D/, "");
		path = path.replace(/\?$/, "");

		return path;
	},
});
