import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiFetch } from "../../network/apiFetch";
import AsyncStorage from '@react-native-async-storage/async-storage';


const authtokenSlice = createSlice({
    name: "authtoken",
    initialState: "",
    reducers: {
        receiveAuthToken: (state, { payload: token }) => token
    }

});

export const { receiveAuthToken } = authtokenSlice.actions;

export function updateUserToken(username, password, save) {
    if (!password) {
        return;
    }
    return async (dispatch, getState) => {
        return apiFetch("api-token-auth", "POST", { username, password }).then(responseJson => {
            const { token } = responseJson;
            if (save) {
                AsyncStorage.setItem("authToken", token);
            } else {
                AsyncStorage.removeItem("authToken");
            }
            global.authToken = token;
            dispatch(receiveAuthToken(token));
        })
    }
};



export default authtokenSlice.reducer;