import { MaterialIcons } from "@expo/vector-icons";
import { useKeyboard } from "@react-native-community/hooks";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _, _n } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { getCurrentCharacter, receiveCharacters } from "../../store/slices/charactersSlice";
import { updateStoredGame } from "../../store/slices/gamesSlice";
import { colors } from "../../styles/colors";
import { getCharName } from "../../tools/characters";
import { isGM, isInGame } from "../../tools/games";
import { enumerate, ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import ListButton from "../generic/buttons/ListButton";

function WaitingCharacters({ dispatch, game, characters, currentParty, currentCharacter }) {
	if (!isInGame()) return null;
	if (game.archived) return null;

	const keyboard = useKeyboard();

	const waitingCharacters = React.useMemo(() => {
		let waiting = characters.filter((c) => c.waiting && !c.deleted && !c.is_npc);

		if (currentParty) {
			waiting = waiting.filter((c) => currentParty.characters.indexOf(c.id) > -1);
		}

		if (game.gm_waiting) waiting.push(null);

		return waiting;
	}, [characters, currentParty, game.gm_waiting]);

	const isGameMaster = isGM();
	const isWaiting = (isGameMaster && game.gm_waiting) || (!isGameMaster && currentCharacter?.waiting);

	const toggleWaiting = React.useCallback(() => {
		const character = isGameMaster ? null : currentCharacter;

		if (isGameMaster) {
			dispatch(updateStoredGame({ ...game, gm_waiting: !isWaiting }));
		} else {
			dispatch(
				receiveCharacters({ gameId: game.id, characters: [{ ...currentCharacter, waiting: !isWaiting }] })
			);
		}

		apiFetch(`games/${game.id}/character-waiting`, "PATCH", {
			character: character ? character.id : null,
			waiting: !isWaiting,
		});
	}, [game, currentCharacter, isWaiting, isGameMaster]);

	const PC = React.useMemo(() => characters.filter((c) => !c.is_npc && !c.deleted), [characters]);
	let waitingText = "";
	if (!game.gm_waiting && PC.length > 1 && waitingCharacters.length >= PC.length) {
		waitingText = _("All players are waiting", "players are waiting for the GM");
	} else if (waitingCharacters.length >= PC.length + 1) {
		waitingText = _("Everyone is waiting...", "Both the players and the GM are waiting");
	} else {
		waitingText = _n(
			"%(name_list)s is waiting",
			"%(name_list)s are waiting",
			"list characters waiting for game to continue",
			waitingCharacters.length,
			{
				name_list: enumerate(
					waitingCharacters.map((c) => getCharName(c, null, _("GM", "indicates the game master")))
				),
			}
		);
	}

	if (keyboard.keyboardShown) return null;

	return (
		<View style={styles.container}>
			<AppText
				hide={!waitingCharacters.length}
				color="secondary"
				style={{ marginBottom: 8 }}
				onPress={isWaiting ? toggleWaiting : null}
			>
				{waitingText}
				<AppText hide={!isWaiting} size="small">
					{" "}
					({ws(_("click to cancel", "tap to cancel waiting"), _("tap to cancel", "tap to cancel waiting"))})
				</AppText>
			</AppText>

			{!isWaiting && (
				<ListButton
					title={_("I am done", "mark yourself as done for this game")}
					subtitle={_("Let others know you are waiting for them")}
					iconLeft={{ type: MaterialIcons, name: "check" }}
					size="fullWidth"
					// transparent
					style={{
						borderColor: global.colors.borderDefault,
						borderBottomColor: global.colors.borderDefault,
						borderWidth: 1,
						backgroundColor: global.colors.inputFieldBackground,
					}}
					onPress={toggleWaiting}
				/>
			)}
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	return {
		game,
		characters: state.charactersByGame[game?.id] || [],
		currentParty: state.parties[game?.id]?.current,
		currentCharacter: getCurrentCharacter(state),
		sheets: state.sheets,
	};
};

export default connect(mapStateToProps)(WaitingCharacters);

const styles = StyleSheet.create({
	container: {
		marginBottom: 4,
		marginHorizontal: 8,
	},
});
