import * as React from "react";
import { FlatList, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { isGM } from "../../tools/games";
import AppButton from "../generic/buttons/AppButton";
import ModalScreen from "../generic/modal/ModalScreen";
import UserAvatar from "./UserAvatar";

function SelectPlayerModal({ navigation, route, dispatch, game, characterSelected }) {
	const players = game.players.filter((p) => !isGM(game, p));
	const { backToScreen } = route.params;

	return (
		<ModalScreen>
			<FlatList
				data={players}
				keyExtractor={(item) => String(item.id)}
				renderItem={({ item }) => {
					return (
						<AppButton
							title={item.username}
							list
							iconComponent={<UserAvatar user={item} />}
							onPress={() => {
								if (characterSelected) {
									navigation.navigate("CharactersScreenModalStack", {
										screen: "AssignCharacterModal",
										params: { player: item, character: characterSelected, backToScreen },
									});
								} else {
									navigation.navigate(backToScreen, { playerSelected: item });
								}
							}}
						/>
					);
				}}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameId } = ownProps.route.params;
	return {
		game: state.games[gameId],
		characterSelected: state.characters[ownProps.route.params?.characterSelectedId],
	};
};

export default connect(mapStateToProps)(SelectPlayerModal);


