import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { socket } from "../../network/websocket";
import { receiveMessages } from "../../store/slices/directMessagesSlice";
import { fetchPending } from "../../store/slices/friendsSlice";
import {
	fetchMessageGroups,
	fetchNextGroups,
	setSearchingMessageGroups,
	updateMessageGroup,
} from "../../store/slices/messageGroupsSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { idKeyExtractor, isIos, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import IconButton from "../generic/buttons/IconButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import SearchBar from "../generic/Searchbar";
import MessageGroupItem from "./MessageGroupItem";
import PendingFriendRequestItem from "./PendingRequestItem";

function MyMessagesScreen({
	route,
	navigation,
	dispatch,
	user,
	groups,
	nextUrl,
	searching,
	searchGroups,
	searchNextUrl,
	pendingRequests,
}) {
	const [refreshing, setrefreshing] = React.useState(false);
	const [searchInput, setsearchInput] = React.useState("");

	const hasActiveSearch = searching && searchInput;

	const onMessageReceived = React.useCallback(
		(message) => {
			const group = groups.find((g) => g.id === message.group);
			dispatch(receiveMessages({ groupId: message.group, messages: [message] }));
			dispatch(updateMessageGroup({ ...group, last_message: message }));
		},
		[groups]
	);

	const fetchPendingRequests = React.useCallback(() => {
		dispatch(fetchPending(user.id));
	}, [user.id]);

	const updateSearch = React.useCallback(
		(search) => {
			setsearchInput(search);
			dispatch(fetchMessageGroups(user.id, { search }));
		},
		[user.id]
	);

	React.useEffect(() => {
		if (searching) {
			navigation.setOptions({
				headerTitle: () => (
					<SearchBar
						autoFocus
						onSearchUpdated={updateSearch}
						placeholder={_("Type a friend name", "search for messages with friend")}
					/>
				),
				headerRight: () => (
					<IconButton
						icon={{ type: MaterialIcons, name: "cancel" }}
						transparent
						onPress={() => dispatch(setSearchingMessageGroups(false))}
					/>
				),
				headerTitleContainerStyle: [{left: 0}, !isIos() && { left: 48, right: 48 }, { flexGrow: 1 }],
				headerBackTitle: "",
			});
		} else {
			navigation.setOptions({
				headerTitle: _("Direct messages", "screen title"),
				headerRight: () => (
					<IconButton
						icon={{ type: MaterialIcons, name: "search" }}
						onPress={() => dispatch(setSearchingMessageGroups(true))}
						transparent
					/>
				),
			});
		}
	}, [searching, updateSearch]);

	React.useEffect(() => {
		fetchPendingRequests();
	}, [fetchPendingRequests]);

	React.useEffect(() => {
		socket.on("new_message", onMessageReceived);
		socket.on("friend_request_received", fetchPendingRequests);
		return () => {
			socket.off("new_message", onMessageReceived);
			socket.off("friend_request_received", fetchPendingRequests);
		};
	}, [onMessageReceived, fetchPendingRequests]);

	React.useEffect(() => {
		setrefreshing(true);
		dispatch(fetchMessageGroups(user.id)).then(() => setrefreshing(false));
	}, [user.id]);

	const fecthNext = React.useCallback(() => {
		if (nextUrl) {
			dispatch(fetchNextGroups(nextUrl, hasActiveSearch));
		}
	}, [nextUrl, searchNextUrl, hasActiveSearch]);

	const pendingRequestsList = (
		<View>
			{pendingRequests.map((request) => (
				<PendingFriendRequestItem key={request.id} request={request} />
			))}
		</View>
	);

	return (
		<AppScreenView borderless>
			<FlatList
				contentContainerStyle={[
					{ flexGrow: 1, flexShrink: hasActiveSearch ? 1 : null },
					ws({
						paddingTop: 16,
						paddingLeft: fullScreenLeftPadding(),
						maxWidth: fullScreenContentWidth(),
					}),
				]}
				data={hasActiveSearch ? searchGroups : groups}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => <MessageGroupItem group={item} />}
				onEndReached={fecthNext}
				ListHeaderComponent={hasActiveSearch ? null : pendingRequestsList}
				refreshing={refreshing}
				onRefresh={() => {
					dispatch(fetchMessageGroups(user.id));
					fetchPendingRequests();
				}}
				ListEmptyComponent={
					hasActiveSearch ? (
						<EmptyScreenView
							style={{ flex: 1, marginHorizontal: 16 }}
							messageBottom={_("No friends matching the search", "empty search result for friend")}
							icon={{ type: MaterialIcons, name: "search" }}
						/>
					) : (
						<EmptyScreenView
							style={{ flex: 1, marginHorizontal: 16 }}
							messageTop={_("You don't have any friends... Yet!")}
							icon={{ type: MaterialIcons, name: "person" }}
							messageBottom={_("Send friend requests to any player from their profile page")}
						/>
					)
				}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { all, nextUrl, searchGroups, searching, searchNextUrl } = state.messageGroups;
	return {
		user: state.user,
		groups: all,
		nextUrl,
		searching,
		searchGroups,
		searchNextUrl,
		pendingRequests: state.friends.pendingRequests,
	};
};

export default connect(mapStateToProps)(MyMessagesScreen);


