import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { useDispatch } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { updateStoredGame } from "../../../store/slices/gamesSlice";
import { colors } from "../../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import { isGM } from "../../../tools/games";
import { isWeb, ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";

function GameDescription({ game, style, noEdit }) {
	const [showingMore, setshowingMore] = React.useState(false);

	style = style || globalStylesMethod(global.theme).floatingSection;

	const route = useRoute();
	const navigation = useNavigation();
	const dispatch = useDispatch();

	const editedFieldProps = route.params?.editedFieldProps;

	const inputFieldProps = [
		{ key: "description", label: _("Description", "game details"), value: game.description, multiline: true },
		{ key: "rules", label: _("Rules", "game details"), value: game.rules, multiline: true },
	];

	React.useEffect(() => {
		if (!editedFieldProps) return () => null;
		if (!editedFieldProps[0].key === "description") return () => null;

		navigation.setParams({ editedFieldProps: null });

		const description = editedFieldProps.find((p) => p.key === "description")?.value;
		const rules = editedFieldProps.find((p) => p.key === "rules")?.value;

		apiFetch(`games/${game.id}`, "PATCH", { description, rules });
	}, [editedFieldProps, game.id]);

	const saveDescription = React.useCallback(
		(description) => {
			dispatch(updateStoredGame({ ...game, description }));
			apiFetch(`games/${game.id}`, "PATCH", { description });
		},
		[game]
	);

	const saveRules = React.useCallback(
		(rules) => {
			dispatch(updateStoredGame({ ...game, rules }));
			apiFetch(`games/${game.id}`, "PATCH", { rules });
		},
		[game.id]
	);

	const canEdit = !noEdit && isGM(game) && !game.archived;
	const ViewTag = canEdit && !isWeb() ? TouchableOpacity : View;
	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={[style, ws({ alignItems: "center" })]}>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<ViewTag
					onPress={() =>
						navigation.navigate("EditDescription", {
							previousScreenName: route.name,
							inputFieldProps,
						})
					}
				>
					<View style={globalStyles.rcsb}>
						<AppText color="primary">
							{showingMore ? _("Description", "game details") : _("Description & Rules", "game details")}
						</AppText>
						{canEdit && !isWeb() && (
							<MaterialIcons
								name="edit"
								size={24}
								color={global.colors.primary}
								style={{ marginRight: 8 }}
							/>
						)}
					</View>

					<AppText
						numberOfLines={showingMore ? null : 4}
						color={!game.description ? "hint" : null}
						italic={!game.description}
						editable={canEdit && isWeb()}
						inputFieldProps={{ multiline: true }}
						onTextChanged={saveDescription}
					>
						{game.description || _("No description.", "game details")}
					</AppText>
					<AppText hide={!showingMore} color="primary" style={{ marginTop: 8 }}>
						{_("Rules", "game details")}{" "}
					</AppText>
					<AppText
						hide={!showingMore}
						color={!game.rules ? "hint" : null}
						italic={!game.rules}
						style={{ marginBottom: 4 }}
						editable={canEdit && isWeb()}
						inputFieldProps={{ multiline: true }}
						onTextChanged={saveRules}
					>
						{game.rules || _("No rules were specified", "game details")}
					</AppText>
				</ViewTag>
				<AppText
					style={{
						paddingTop: 16,
						paddingBottom: 8,
					}}
					textOnlyStyle={{
						textAlign: "right",
						flex: 1,
					}}
					bold
					onPress={() => setshowingMore(true)}
					hide={showingMore}
				>
					{_("MORE")} <FontAwesome name="caret-down" size={24} color={global.colors.textDefault} />
				</AppText>
			</View>
		</View>
	);
}

export default GameDescription;
