import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import CoinIcon from "./CoinIcon";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { levelToCoinName, levelToCoinFileName, getUsername } from "../../tools/account";
import { cStyle } from "../../tools/react";
import { useNavigation } from "@react-navigation/native";
import { openGameAtLine } from "../../tools/lines";
import { fetchGame } from "../../store/slices/gamesSlice";

import { BuildStyleMethod } from "../../styles/theming";

function NotificationItem({ onStartNavigation, coinTransaction, dispatch, user }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const isNew = user.profile.last_transaction_seen_id < coinTransaction.id;

	const { amount, subject, coin_level, sender } = coinTransaction;

	const senderName = getUsername(sender);

	const coinComponent = (
		<AppText key="icon">
			<CoinIcon level={coin_level} size={12} />{" "}
			<AppText textOnlyStyle={{ color: global.colors.accountColors[levelToCoinFileName(coin_level).toLowerCase()] }}>
				{levelToCoinName(coin_level)}
			</AppText>
		</AppText>
	);

	const content = subject?.content ? `"${subject.content}"` : null;

	const ViewTag = content ? TouchableOpacity : View;

	const openNotification = React.useCallback(async () => {
        onStartNavigation && onStartNavigation();
		const game = await dispatch(fetchGame(subject.game));
		openGameAtLine(game, subject.id, subject.party, 0.2, dispatch, navigation, true);
	}, [subject]);

	return (
		<ViewTag onPress={openNotification} style={cStyle([styles.container, [isNew, styles.new]])}>
			{isNew && (
				<AppText color="primary" textOnlyStyle={{ fontSize: 32 }}>
					{"\u2022"}
				</AppText>
			)}
			<CoinIcon level={coin_level} style={{ marginRight: 8 }} />
			<AppText numberOfLines={3} ellipsizeMode="tail" style={{ flex: 1 }}>
				<AppText>
					{_("%(username)s gave you %(number)sx%(coin_type)s for: ", "coin received notification", {
						username: (
							<AppText bold key="u">
								{senderName}
							</AppText>
						),
						number: amount,
						coin_type: coinComponent,
					})}
				</AppText>
				<AppText color="hint">{content || subject?.name || subject?.save_name}</AppText>
				<AppText hide={subject} color="hint">
					{_("[Deleted]", "deleted user")}
				</AppText>
			</AppText>
		</ViewTag>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(NotificationItem);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		borderBottomWidth: 1,
		borderBottomColor: colors.lightBorder,
		padding: 8,
	},
	new: {
		backgroundColor: colors.inputFieldBackground,
	},
}));
