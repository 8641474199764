import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { useDimensions } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { levelToCoinName } from "../../tools/account";
import { allowGiveCoinTo } from "../../tools/coins";
import { ws } from "../../tools/generic";
import ModalScreen from "../generic/modal/ModalScreen";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import StreamedList from "../generic/StreamedList";
import Cond from "../meta/Cond";
import CoinCounter from "./CoinCounter";
import CoinTransactionItem from "./CoinTransactionItem";
import ConfirmGiveCoinBoxModal from "./ConfirmGiveCoinBoxModal";

function CoinHistoryModal({ route, navigation, user }) {
	// subjectGetter template {line_id: 00}
	const { subject, subjectGetter, blockGive } = route.params;
	const [givingCoinLevel, setGivingCoinLevel] = React.useState(null);
	const [coinGivenLevel, setCoinGivenLevel] = React.useState(null);
	const [userTransactions, setUserTransactions] = React.useState(null);

	const canGiveCoin = React.useMemo(
		() => !blockGive && allowGiveCoinTo(user.id, subject),
		[subject, user.id, blockGive]
	);

	const purse = user.profile.purse;

	const modalRef = React.useRef(null);
	const coingGivenModalRef = React.useRef(null);

	const modalConfirm = React.useCallback(
		(level) => {
			if (canGiveCoin) {
				setGivingCoinLevel(level);
				modalRef.current.show();
			}
		},
		[canGiveCoin]
	);

	React.useEffect(() => {
		apiFetch(`users/${user.id}/coin-transactions`, "GET", {
			history: true,
			"page-size": 10000,
			sender_id: user.id,
			...subjectGetter,
		}).then(({ results }) => {
			setUserTransactions(results);
		});
	}, [coinGivenLevel]);

	const alreadyRewarded = React.useCallback(
		(level) => {
			if (!userTransactions) {
				return true;
			}
			return userTransactions.some((ct) => ct.coin_level === level);
		},
		[userTransactions]
	);

	const { height } = useDimensions().screen;

	return (
		<ModalScreen containerStyle={ws({ maxHeight: height * 0.6 })}>
			<View style={styles.container}>
				<CoinCounter
					disabled={purse.wood <= 0}
					awarded={alreadyRewarded(0)}
					onCoinPressed={modalConfirm}
					level={0}
					amount={subject.wood}
				/>
				<Cond show={!global.appleStoreReview}>
					<CoinCounter
						disabled={purse.copper <= 0}
						awarded={alreadyRewarded(1)}
						onCoinPressed={modalConfirm}
						level={1}
						amount={subject.copper}
					/>
					<CoinCounter
						disabled={purse.silver <= 0}
						awarded={alreadyRewarded(2)}
						onCoinPressed={modalConfirm}
						level={2}
						amount={subject.silver}
					/>
					<CoinCounter
						disabled={purse.gold <= 0}
						awarded={alreadyRewarded(3)}
						onCoinPressed={modalConfirm}
						level={3}
						amount={subject.gold}
					/>
					<CoinCounter
						disabled={purse.platinum <= 0}
						awarded={alreadyRewarded(4)}
						onCoinPressed={modalConfirm}
						level={4}
						amount={subject.platinum}
					/>
					<CoinCounter
						disabled={purse.legendary <= 0}
						awarded={alreadyRewarded(5)}
						onCoinPressed={modalConfirm}
						level={5}
						amount={subject.legendary}
					/>
				</Cond>
			</View>
			<Divider style={{ marginVertical: 8 }} />
			<StreamedList
				fetchURL="coin-transactions"
				fetchParams={{
					history: true,
					ordering: "-date",
					"page-size": 50,
					...subjectGetter,
				}}
				renderItem={({ item }) => <CoinTransactionItem transaction={item} key={item.id} />}
			/>

			<ConfirmGiveCoinBoxModal
				modalRef={modalRef}
				level={givingCoinLevel}
				subjectGetter={subjectGetter}
				onTransactionPerformed={(level) => {
					setCoinGivenLevel(level);
					coingGivenModalRef.current.show();
				}}
			/>

			<TinyFeedbackModal
				ref={coingGivenModalRef}
				message={_("%(coin_type)s awarded!", "confirm coin given message", {
					coin_type: levelToCoinName(coinGivenLevel),
				})}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(CoinHistoryModal);

export const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
});
