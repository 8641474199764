import * as React from "react";
import AppText from "../generic/AppText";
import CharacterSheetValue from "./CharacterSheetValue";

function RollModifiers({ pool, details, line, isFirstInLine }) {
	if (typeof pool.modifiers === "string") {
		pool = { ...pool };
		pool.modifiers = JSON.parse(pool.modifiers);
	}

	let modifiers = pool.modifiers;
	if (!modifiers && pool.modifier) modifiers = [pool.modifier];

	if (!modifiers) return null;

	const sheetValues =
		pool.sheet_values?.map((v, index) => (
			<CharacterSheetValue
				key={index}
				value={v}
				line={line}
				details={details}
				pool={pool}
				index={index}
				isFirstInLine={isFirstInLine}
			/>
		)) || [];

	const staticModifiers = [];

	for (let i = 0; i < modifiers.length; ++i) {
		let modifier = modifiers[i];
		let value = (modifier >= 0 && (i > 0 || pool.rolls.length) ? "+" : "") + modifier;

		staticModifiers.push(
			<AppText key={i} color={modifier > 0 ? "success" : "failure"}>
				{value}
			</AppText>
		);
	}

	if (!staticModifiers.length && !sheetValues.length) {
		return null;
	}

	return (
		<AppText className="modifier">
			{sheetValues}
			<AppText hide={!staticModifiers.length}>{staticModifiers}</AppText>
			{" "}
		</AppText>
	);
}

export default RollModifiers;
