import AsyncStorage from '@react-native-async-storage/async-storage';
import { setTheme } from "../store/slices/appSettingsSlice";

export const updateTheme = (theme, dispatch)=>{
    
    AsyncStorage.setItem("theme", theme);
    global.theme = theme;
    if (theme === "darkest") global.colors = { ...colors, ...colors.darkestTheme };
    else global.colors = { ...colors, ...colors.darkTheme };
    dispatch(setTheme(theme));
}