import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { TouchableOpacity, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _, _n } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { fetchCharacters } from "../../../store/slices/charactersSlice";
import { fetchPlayerSheets } from "../../../store/slices/sheetsSlice";
import { fetchGameUsers } from "../../../store/slices/usersSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import { isGM, isInGame } from "../../../tools/games";
import { idKeyExtractor, ws } from "../../../tools/generic";
import { navPush } from "../../../tools/webnavigation";
import AppFlatList from "../../generic/AppFlatList";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import EditTextModal from "../../generic/EditTextModal";
import UserItem from "../../users/UserItem";
import GamePasswordModal from "../GamePasswordModal";

function GamePlayers({ game, style, noEdit, dispatch, users, user }) {
	const editModalRef = React.useRef();
	const passwordModalRef = React.useRef();

	const ids = game.players.map((p) => p.id || p).filter((id) => !game.assistants.includes(id));

	style = style || globalStylesMethod(global.theme).floatingSection;

	React.useEffect(() => {
		if (ids.some((pid) => !users[pid])) {
			dispatch(fetchGameUsers(game.id));
			dispatch(fetchCharacters(game.id));
			dispatch(fetchPlayerSheets(game.id));
		}
	}, [game.id, ids, users]);

	const onNumberPlayersChanged = React.useCallback(
		(max_players) => {
			max_players = Math.max(0, Math.min(30, max_players));
			apiFetch(`games/${game.id}`, "PATCH", { max_players });
		},
		[game.id]
	);

	const full = game.players.length >= game.max_players;

	const navigation = useNavigation();

	const canEdit = !noEdit && isGM(game) && !game.archived;

	const ViewTag = canEdit ? TouchableOpacity : View;

	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={[style, ws({ alignItems: "center"  })]}>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<ViewTag style={globalStyles.rcsb} onPress={() => editModalRef.current.show()}>
					<AppText color="primary" hide={game.archived}>
						{_n(
							"%(current)s/%(max)s player",
							"%(current)s/%(max)s players",
							"game details",
							game.players.length,
							{
								current: game.players.length,
								max: game.max_players,
							}
						)}
					</AppText>
					<AppText color="primary" hide={!game.archived}>
						{_n("%(current)s player", "%(current)s players", "game details", game.players.length, {
							current: game.players.length,
							max: game.max_players,
						})}
					</AppText>
					{canEdit && (
						<MaterialIcons
							name="edit"
							size={24}
							color={global.colors.primary}
							style={ws(null, { marginRight: 8 })}
						/>
					)}
				</ViewTag>

				<AppFlatList
					keyExtractor={idKeyExtractor}
					data={ids.map((id) => users[id]).filter((p) => p)}
					renderItem={({ item, index }) => (
						<UserItem
							key={item.id}
							index={index}
							user={item}
							game={game}
							onPress={() => {
								navPush(navigation)("ProfilePage", { username: item.username });
							}}
						/>
					)}
				/>

				<AppText
					hide={full || game.archived || game.password?.trim()}
					color="primary"
					centered
					style={{ marginVertical: 16 }}
				>
					{_("This game welcomes new players")}
				</AppText>
				<AppText hide={!game.password?.trim()} color="danger" centered style={{ marginVertical: 16 }}>
					{_("This game is locked", "password protected game")}
				</AppText>
				<AppButton
					hide={full || isInGame(user, game) || game.archived}
					title={_("Join", "join game")}
					disabled={full}
					onPress={() => {
						if (!user) {
							navigation.navigate("Login");
						} else if (game.password) {
							passwordModalRef.current.show();
						} else {
							navigation.navigate("GameStack", { gameslug: game.slug, screen: "JoinGameScreen" });
						}
					}}
				/>
				<AppText hide={!full || game.archived} color="primary" centered style={{ marginVertical: 16 }}>
					{_("This game is full")}
				</AppText>
				<AppText hide={!game.archived} color="secondary" centered style={{ marginVertical: 16 }}>
					{_("This game has been archived")}
				</AppText>
				<EditTextModal
					ref={editModalRef}
					onDone={onNumberPlayersChanged}
					initialValue={game.max_players}
					title={_("Change maximum players")}
					inputFieldProps={{ keyboardType: "numeric" }}
				/>
				<GamePasswordModal ref={passwordModalRef} game={game} navigation={navigation} />
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		users: state.users || state.savedStore.users,
	};
};

export default connect(mapStateToProps)(GamePlayers);
