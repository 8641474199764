import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchCharacters } from "../../store/slices/charactersSlice";
import { fetchDefaultSample, fetchLineContext } from "../../store/slices/gameSamplesSlice";
import { openGameAtLine } from "../../tools/lines";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import IsolatedLineList from "../lines/IsolateLineList";

function GamePreviewSample({
	game,
	scrollView,
	onPreviewLoaded,
	dispatch,
	usesSaved,
	gameSample,
	savedGameSample,
	characters,
}) {
	const [samplePage, setsamplePage] = React.useState(0);
	const [noPreview, setnoPreview] = React.useState(false);

	const navigation = useNavigation();

	React.useEffect(() => {
		if (!characters) {
			dispatch(fetchCharacters(game.id));
		}
	}, [game.id, characters]);

	const firstFetch = React.useCallback(async () => {
		if (usesSaved) {
			const result = await dispatch(fetchDefaultSample(game.id));
			if (result === false) {
				setnoPreview(true);
			}
		}
		onPreviewLoaded && onPreviewLoaded();
	}, [usesSaved, game.id]);

	React.useEffect(() => {
		firstFetch();
	}, [game.id]);

	const loadPageIfNeeded = React.useCallback(
		(page) => {
			if (!gameSample) return;
			if (page >= gameSample.showcaseLineIds.length) return;
			if (!gameSample.contexts[page]) {
				dispatch(fetchLineContext(game.id, gameSample.showcaseLineIds[page]));
			}
		},
		[samplePage, gameSample, game.id]
	);

	React.useEffect(() => {
		loadPageIfNeeded(samplePage + 1);
		loadPageIfNeeded(samplePage);
	}, [samplePage]);

	const showCaseLineId = gameSample?.showcaseLineIds[samplePage];

	const goToLine = React.useCallback(() => {
		const context = gameSample.contexts[showCaseLineId];
		const line = context.rg_last();

		openGameAtLine(game, line.id, line.party, 0.9, dispatch, navigation);
	}, [showCaseLineId, gameSample]);

	if (noPreview) {
		return (
			<View style={[styles.container, { justifyContent: "center" }]}>
				<AppText color="hint" centered>
					{_("This game doesn't have content yet", "no available preview")}
				</AppText>
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<IsolatedLineList
				isPreview
				disableLoading
				game={game}
				lines={gameSample?.contexts[showCaseLineId] || savedGameSample?.contexts[showCaseLineId] || []}
			/>
			<ButtonsRow style={{ marginTop: 16 }}>
				<AppButton
					title={_("Next sample", "see next game preview sample")}
					hide={!gameSample || gameSample.showcaseLineIds.length <= 1}
					onPress={() => {
						setsamplePage((current) => {
							current++;
							if (current >= gameSample.showcaseLineIds.length) {
								current = 0;
							}
							if (scrollView) {
								// if (scrollView.scrollTo) {
								// 	scrollView.scrollTo(layout.y);
								// } else if (scrollView.scrollToOffset) {
								// 	scrollView.scrollToOffset(layout.y);
								// }
							}
							return current;
						});
					}}
				/>
				<AppButton title={_("Read from here", "go from game preview sample to game")} onPress={goToLine} />
			</ButtonsRow>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		usesSaved: !state.gameSamples[ownProps.game.id],
		gameSample: state.gameSamples[ownProps.game.id] || state.savedStore.gameSamples[ownProps.game.id],
		savedGameSample: state.savedStore.gameSamples[ownProps.game.id],
		characters: state.charactersByGame[ownProps.game.id],
	};
};

export default connect(mapStateToProps)(GamePreviewSample);

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingBottom: 16,
		minHeight: 64,
	},
});
