import avatar_ico_co_34 from "../../assets/images/avatars/ico_co_34.svg";
import avatar_ico_co_33 from "../../assets/images/avatars/ico_co_33.svg";
import avatar_ico_co_32 from "../../assets/images/avatars/ico_co_32.svg";
import avatar_ico_co_31 from "../../assets/images/avatars/ico_co_31.svg";
import avatar_ico_co_30 from "../../assets/images/avatars/ico_co_30.svg";
import avatar_ico_co_29 from "../../assets/images/avatars/ico_co_29.svg";
import avatar_ico_co_28 from "../../assets/images/avatars/ico_co_28.svg";
import avatar_ico_co_27 from "../../assets/images/avatars/ico_co_27.svg";
import avatar_ico_co_26 from "../../assets/images/avatars/ico_co_26.svg";
import avatar_ico_co_25 from "../../assets/images/avatars/ico_co_25.svg";
import avatar_ico_co_24 from "../../assets/images/avatars/ico_co_24.svg";
import avatar_ico_co_23 from "../../assets/images/avatars/ico_co_23.svg";
import avatar_ico_co_22 from "../../assets/images/avatars/ico_co_22.svg";
import avatar_ico_co_21 from "../../assets/images/avatars/ico_co_21.svg";
import avatar_ico_co_20 from "../../assets/images/avatars/ico_co_20.svg";
import avatar_ico_co_19 from "../../assets/images/avatars/ico_co_19.svg";
import avatar_ico_co_18 from "../../assets/images/avatars/ico_co_18.svg";
import avatar_ico_co_17 from "../../assets/images/avatars/ico_co_17.svg";
import avatar_ico_co_16 from "../../assets/images/avatars/ico_co_16.svg";
import avatar_ico_co_15 from "../../assets/images/avatars/ico_co_15.svg";
import avatar_ico_co_14 from "../../assets/images/avatars/ico_co_14.svg";
import avatar_ico_co_13 from "../../assets/images/avatars/ico_co_13.svg";
import avatar_ico_co_12 from "../../assets/images/avatars/ico_co_12.svg";
import avatar_ico_co_11 from "../../assets/images/avatars/ico_co_11.svg";
import avatar_ico_co_10 from "../../assets/images/avatars/ico_co_10.svg";
import avatar_ico_co_9 from "../../assets/images/avatars/ico_co_9.svg";
import avatar_ico_co_8 from "../../assets/images/avatars/ico_co_8.svg";
import avatar_ico_co_7 from "../../assets/images/avatars/ico_co_7.svg";
import avatar_ico_co_6 from "../../assets/images/avatars/ico_co_6.svg";
import avatar_ico_co_5 from "../../assets/images/avatars/ico_co_5.svg";
import avatar_ico_co_4 from "../../assets/images/avatars/ico_co_4.svg";
import avatar_ico_co_3 from "../../assets/images/avatars/ico_co_3.svg";
import avatar_ico_co_2 from "../../assets/images/avatars/ico_co_2.svg";
import avatar_ico_co_1 from "../../assets/images/avatars/ico_co_1.svg";
import avatar_ico_si_46 from "../../assets/images/avatars/ico_si_46.svg";
import avatar_ico_si_45 from "../../assets/images/avatars/ico_si_45.svg";
import avatar_ico_si_44 from "../../assets/images/avatars/ico_si_44.svg";
import avatar_ico_si_43 from "../../assets/images/avatars/ico_si_43.svg";
import avatar_ico_si_42 from "../../assets/images/avatars/ico_si_42.svg";
import avatar_ico_si_41 from "../../assets/images/avatars/ico_si_41.svg";
import avatar_ico_si_40 from "../../assets/images/avatars/ico_si_40.svg";
import avatar_ico_si_39 from "../../assets/images/avatars/ico_si_39.svg";
import avatar_ico_si_38 from "../../assets/images/avatars/ico_si_38.svg";
import avatar_ico_si_37 from "../../assets/images/avatars/ico_si_37.svg";
import avatar_ico_si_36 from "../../assets/images/avatars/ico_si_36.svg";
import avatar_ico_si_35 from "../../assets/images/avatars/ico_si_35.svg";
import avatar_ico_si_34 from "../../assets/images/avatars/ico_si_34.svg";
import avatar_ico_si_33 from "../../assets/images/avatars/ico_si_33.svg";
import avatar_ico_si_32 from "../../assets/images/avatars/ico_si_32.svg";
import avatar_ico_si_31 from "../../assets/images/avatars/ico_si_31.svg";
import avatar_ico_si_30 from "../../assets/images/avatars/ico_si_30.svg";
import avatar_ico_si_29 from "../../assets/images/avatars/ico_si_29.svg";
import avatar_ico_si_28 from "../../assets/images/avatars/ico_si_28.svg";
import avatar_ico_si_27 from "../../assets/images/avatars/ico_si_27.svg";
import avatar_ico_si_26 from "../../assets/images/avatars/ico_si_26.svg";
import avatar_ico_si_25 from "../../assets/images/avatars/ico_si_25.svg";
import avatar_ico_si_24 from "../../assets/images/avatars/ico_si_24.svg";
import avatar_ico_si_23 from "../../assets/images/avatars/ico_si_23.svg";
import avatar_ico_si_22 from "../../assets/images/avatars/ico_si_22.svg";
import avatar_ico_si_21 from "../../assets/images/avatars/ico_si_21.svg";
import avatar_ico_si_20 from "../../assets/images/avatars/ico_si_20.svg";
import avatar_ico_si_19 from "../../assets/images/avatars/ico_si_19.svg";
import avatar_ico_si_18 from "../../assets/images/avatars/ico_si_18.svg";
import avatar_ico_si_17 from "../../assets/images/avatars/ico_si_17.svg";
import avatar_ico_si_16 from "../../assets/images/avatars/ico_si_16.svg";
import avatar_ico_si_15 from "../../assets/images/avatars/ico_si_15.svg";
import avatar_ico_si_14 from "../../assets/images/avatars/ico_si_14.svg";
import avatar_ico_si_13 from "../../assets/images/avatars/ico_si_13.svg";
import avatar_ico_si_12 from "../../assets/images/avatars/ico_si_12.svg";
import avatar_ico_si_11 from "../../assets/images/avatars/ico_si_11.svg";
import avatar_ico_si_10 from "../../assets/images/avatars/ico_si_10.svg";
import avatar_ico_si_9 from "../../assets/images/avatars/ico_si_9.svg";
import avatar_ico_si_8 from "../../assets/images/avatars/ico_si_8.svg";
import avatar_ico_si_7 from "../../assets/images/avatars/ico_si_7.svg";
import avatar_ico_si_6 from "../../assets/images/avatars/ico_si_6.svg";
import avatar_ico_si_5 from "../../assets/images/avatars/ico_si_5.svg";
import avatar_ico_si_4 from "../../assets/images/avatars/ico_si_4.svg";
import avatar_ico_si_3 from "../../assets/images/avatars/ico_si_3.svg";
import avatar_ico_si_2 from "../../assets/images/avatars/ico_si_2.svg";
import avatar_ico_si_1 from "../../assets/images/avatars/ico_si_1.svg";
import avatar_ico_go_53 from "../../assets/images/avatars/ico_go_53.svg";
import avatar_ico_go_52 from "../../assets/images/avatars/ico_go_52.svg";
import avatar_ico_go_51 from "../../assets/images/avatars/ico_go_51.svg";
import avatar_ico_go_50 from "../../assets/images/avatars/ico_go_50.svg";
import avatar_ico_go_49 from "../../assets/images/avatars/ico_go_49.svg";
import avatar_ico_go_48 from "../../assets/images/avatars/ico_go_48.svg";
import avatar_ico_go_47 from "../../assets/images/avatars/ico_go_47.svg";
import avatar_ico_go_46 from "../../assets/images/avatars/ico_go_46.svg";
import avatar_ico_go_45 from "../../assets/images/avatars/ico_go_45.svg";
import avatar_ico_go_44 from "../../assets/images/avatars/ico_go_44.svg";
import avatar_ico_go_43 from "../../assets/images/avatars/ico_go_43.svg";
import avatar_ico_go_42 from "../../assets/images/avatars/ico_go_42.svg";
import avatar_ico_go_41 from "../../assets/images/avatars/ico_go_41.svg";
import avatar_ico_go_40 from "../../assets/images/avatars/ico_go_40.svg";
import avatar_ico_go_39 from "../../assets/images/avatars/ico_go_39.svg";
import avatar_ico_go_38 from "../../assets/images/avatars/ico_go_38.svg";
import avatar_ico_go_37 from "../../assets/images/avatars/ico_go_37.svg";
import avatar_ico_go_36 from "../../assets/images/avatars/ico_go_36.svg";
import avatar_ico_go_35 from "../../assets/images/avatars/ico_go_35.svg";
import avatar_ico_go_34 from "../../assets/images/avatars/ico_go_34.svg";
import avatar_ico_go_33 from "../../assets/images/avatars/ico_go_33.svg";
import avatar_ico_go_32 from "../../assets/images/avatars/ico_go_32.svg";
import avatar_ico_go_31 from "../../assets/images/avatars/ico_go_31.svg";
import avatar_ico_go_30 from "../../assets/images/avatars/ico_go_30.svg";
import avatar_ico_go_29 from "../../assets/images/avatars/ico_go_29.svg";
import avatar_ico_go_28 from "../../assets/images/avatars/ico_go_28.svg";
import avatar_ico_go_27 from "../../assets/images/avatars/ico_go_27.svg";
import avatar_ico_go_26 from "../../assets/images/avatars/ico_go_26.svg";
import avatar_ico_go_25 from "../../assets/images/avatars/ico_go_25.svg";
import avatar_ico_go_24 from "../../assets/images/avatars/ico_go_24.svg";
import avatar_ico_go_23 from "../../assets/images/avatars/ico_go_23.svg";
import avatar_ico_go_22 from "../../assets/images/avatars/ico_go_22.svg";
import avatar_ico_go_21 from "../../assets/images/avatars/ico_go_21.svg";
import avatar_ico_go_20 from "../../assets/images/avatars/ico_go_20.svg";
import avatar_ico_go_19 from "../../assets/images/avatars/ico_go_19.svg";
import avatar_ico_go_18 from "../../assets/images/avatars/ico_go_18.svg";
import avatar_ico_go_17 from "../../assets/images/avatars/ico_go_17.svg";
import avatar_ico_go_16 from "../../assets/images/avatars/ico_go_16.svg";
import avatar_ico_go_15 from "../../assets/images/avatars/ico_go_15.svg";
import avatar_ico_go_14 from "../../assets/images/avatars/ico_go_14.svg";
import avatar_ico_go_13 from "../../assets/images/avatars/ico_go_13.svg";
import avatar_ico_go_12 from "../../assets/images/avatars/ico_go_12.svg";
import avatar_ico_go_11 from "../../assets/images/avatars/ico_go_11.svg";
import avatar_ico_go_10 from "../../assets/images/avatars/ico_go_10.svg";
import avatar_ico_go_9 from "../../assets/images/avatars/ico_go_9.svg";
import avatar_ico_go_8 from "../../assets/images/avatars/ico_go_8.svg";
import avatar_ico_go_7 from "../../assets/images/avatars/ico_go_7.svg";
import avatar_ico_go_6 from "../../assets/images/avatars/ico_go_6.svg";
import avatar_ico_go_5 from "../../assets/images/avatars/ico_go_5.svg";
import avatar_ico_go_4 from "../../assets/images/avatars/ico_go_4.svg";
import avatar_ico_go_3 from "../../assets/images/avatars/ico_go_3.svg";
import avatar_ico_go_2 from "../../assets/images/avatars/ico_go_2.svg";
import avatar_ico_go_1 from "../../assets/images/avatars/ico_go_1.svg";
import avatar_ico_pl_35 from "../../assets/images/avatars/ico_pl_35.svg";
import avatar_ico_pl_34 from "../../assets/images/avatars/ico_pl_34.svg";
import avatar_ico_pl_33 from "../../assets/images/avatars/ico_pl_33.svg";
import avatar_ico_pl_32 from "../../assets/images/avatars/ico_pl_32.svg";
import avatar_ico_pl_31 from "../../assets/images/avatars/ico_pl_31.svg";
import avatar_ico_pl_30 from "../../assets/images/avatars/ico_pl_30.svg";
import avatar_ico_pl_29 from "../../assets/images/avatars/ico_pl_29.svg";
import avatar_ico_pl_28 from "../../assets/images/avatars/ico_pl_28.svg";
import avatar_ico_pl_27 from "../../assets/images/avatars/ico_pl_27.svg";
import avatar_ico_pl_26 from "../../assets/images/avatars/ico_pl_26.svg";
import avatar_ico_pl_25 from "../../assets/images/avatars/ico_pl_25.svg";
import avatar_ico_pl_24 from "../../assets/images/avatars/ico_pl_24.svg";
import avatar_ico_pl_23 from "../../assets/images/avatars/ico_pl_23.svg";
import avatar_ico_pl_22 from "../../assets/images/avatars/ico_pl_22.svg";
import avatar_ico_pl_21 from "../../assets/images/avatars/ico_pl_21.svg";
import avatar_ico_pl_20 from "../../assets/images/avatars/ico_pl_20.svg";
import avatar_ico_pl_19 from "../../assets/images/avatars/ico_pl_19.svg";
import avatar_ico_pl_18 from "../../assets/images/avatars/ico_pl_18.svg";
import avatar_ico_pl_17 from "../../assets/images/avatars/ico_pl_17.svg";
import avatar_ico_pl_16 from "../../assets/images/avatars/ico_pl_16.svg";
import avatar_ico_pl_15 from "../../assets/images/avatars/ico_pl_15.svg";
import avatar_ico_pl_14 from "../../assets/images/avatars/ico_pl_14.svg";
import avatar_ico_pl_13 from "../../assets/images/avatars/ico_pl_13.svg";
import avatar_ico_pl_12 from "../../assets/images/avatars/ico_pl_12.svg";
import avatar_ico_pl_11 from "../../assets/images/avatars/ico_pl_11.svg";
import avatar_ico_pl_10 from "../../assets/images/avatars/ico_pl_10.svg";
import avatar_ico_pl_9 from "../../assets/images/avatars/ico_pl_9.svg";
import avatar_ico_pl_8 from "../../assets/images/avatars/ico_pl_8.svg";
import avatar_ico_pl_7 from "../../assets/images/avatars/ico_pl_7.svg";
import avatar_ico_pl_6 from "../../assets/images/avatars/ico_pl_6.svg";
import avatar_ico_pl_5 from "../../assets/images/avatars/ico_pl_5.svg";
import avatar_ico_pl_4 from "../../assets/images/avatars/ico_pl_4.svg";
import avatar_ico_pl_3 from "../../assets/images/avatars/ico_pl_3.svg";
import avatar_ico_pl_2 from "../../assets/images/avatars/ico_pl_2.svg";
import avatar_ico_pl_1 from "../../assets/images/avatars/ico_pl_1.svg";
import avatar_ico_le_27 from "../../assets/images/avatars/ico_le_27.svg";
import avatar_ico_le_26 from "../../assets/images/avatars/ico_le_26.svg";
import avatar_ico_le_25 from "../../assets/images/avatars/ico_le_25.svg";
import avatar_ico_le_24 from "../../assets/images/avatars/ico_le_24.svg";
import avatar_ico_le_23 from "../../assets/images/avatars/ico_le_23.svg";
import avatar_ico_le_22 from "../../assets/images/avatars/ico_le_22.svg";
import avatar_ico_le_21 from "../../assets/images/avatars/ico_le_21.svg";
import avatar_ico_le_20 from "../../assets/images/avatars/ico_le_20.svg";
import avatar_ico_le_19 from "../../assets/images/avatars/ico_le_19.svg";
import avatar_ico_le_18 from "../../assets/images/avatars/ico_le_18.svg";
import avatar_ico_le_17 from "../../assets/images/avatars/ico_le_17.svg";
import avatar_ico_le_16 from "../../assets/images/avatars/ico_le_16.svg";
import avatar_ico_le_15 from "../../assets/images/avatars/ico_le_15.svg";
import avatar_ico_le_14 from "../../assets/images/avatars/ico_le_14.svg";
import avatar_ico_le_13 from "../../assets/images/avatars/ico_le_13.svg";
import avatar_ico_le_12 from "../../assets/images/avatars/ico_le_12.svg";
import avatar_ico_le_11 from "../../assets/images/avatars/ico_le_11.svg";
import avatar_ico_le_10 from "../../assets/images/avatars/ico_le_10.svg";
import avatar_ico_le_9 from "../../assets/images/avatars/ico_le_9.svg";
import avatar_ico_le_8 from "../../assets/images/avatars/ico_le_8.svg";
import avatar_ico_le_7 from "../../assets/images/avatars/ico_le_7.svg";
import avatar_ico_le_6 from "../../assets/images/avatars/ico_le_6.svg";
import avatar_ico_le_5 from "../../assets/images/avatars/ico_le_5.svg";
import avatar_ico_le_4 from "../../assets/images/avatars/ico_le_4.svg";
import avatar_ico_le_3 from "../../assets/images/avatars/ico_le_3.svg";
import avatar_ico_le_2 from "../../assets/images/avatars/ico_le_2.svg";
import avatar_ico_le_1 from "../../assets/images/avatars/ico_le_1.svg";
import avatar_ico_le_28 from "../../assets/images/avatars/ico_le_28.svg";
import avatar_august_ProfWinning from "../../assets/images/avatars/august_ProfWinning.svg";
import avatar_august_PugaSensei from "../../assets/images/avatars/august_PugaSensei.svg";
import avatar_august_TitanPB from "../../assets/images/avatars/august_TitanPB.svg";
import avatar_August_AlexLeonheart from "../../assets/images/avatars/August_AlexLeonheart.svg";
import avatar_August_MsMarthe from "../../assets/images/avatars/August_MsMarthe.svg";
import avatar_August_Sforzanda from "../../assets/images/avatars/August_Sforzanda.svg";
import avatar_Sept_Frexz from "../../assets/images/avatars/Sept_Frexz.svg";
import avatar_Sept2_Nintendocyde from "../../assets/images/avatars/Sept2_Nintendocyde.svg";
import avatar_Sept2_ProfWinning from "../../assets/images/avatars/Sept2_ProfWinning.svg";
import avatar_Oct__Frexz from "../../assets/images/avatars/Oct__Frexz.svg";
import avatar_Nov1_BaiLang from "../../assets/images/avatars/Nov1_BaiLang.svg";
import avatar_Nov2019__MsMarthe from "../../assets/images/avatars/Nov2019__MsMarthe.svg";
import avatar_Nov2019__BrutuZ from "../../assets/images/avatars/Nov2019__BrutuZ.svg";
import avatar_201912_Frexz from "../../assets/images/avatars/201912_Frexz.svg";
import avatar_202001_LeonCelestine from "../../assets/images/avatars/202001_LeonCelestine.svg";
import avatar_202001_MalacDondarion from "../../assets/images/avatars/202001_MalacDondarion.svg";
import avatar_202001_DaniVonZombie from "../../assets/images/avatars/202001_DaniVonZombie.svg";
import avatar_202001_Frexz_2 from "../../assets/images/avatars/202001_Frexz_2.svg";
import avatar_202001_Ingenitus from "../../assets/images/avatars/202001_Ingenitus.svg";
import avatar_202001_kazel from "../../assets/images/avatars/202001_kazel.svg";
import avatar_202001_MsMarthe from "../../assets/images/avatars/202001_Ms.Marthe.svg";
import avatar_202001_P_Ninny from "../../assets/images/avatars/202001_P_Ninny.svg";
import avatar_202001_Profoundant89 from "../../assets/images/avatars/202001_Profoundant89.svg";
import avatar_202001_Profoundant89_2 from "../../assets/images/avatars/202001_Profoundant89_2.svg";
import avatar_202001_TitanPB from "../../assets/images/avatars/202001_TitanPB.svg";
import avatar_202001_Symbelmyna from "../../assets/images/avatars/202001_Symbelmyna.svg";
import avatar_202002_Ditto from "../../assets/images/avatars/202002_Ditto.svg";
import avatar_202002_PapaDossvg from "../../assets/images/avatars/202002_PapaDossvg.svg";
import avatar_202003_Tashdacat from "../../assets/images/avatars/202003_Tashdacat.svg";
import avatar_202003_LeonCelestine_2 from "../../assets/images/avatars/202003_LeonCelestine_2.svg";
import avatar_202003_Dreizehn from "../../assets/images/avatars/202003_Dreizehn.svg";
import avatar_202003_Ditto_2 from "../../assets/images/avatars/202003_Ditto_2.svg";
import avatar_202003_Demonsword from "../../assets/images/avatars/202003_Demonsword.svg";
import avatar_202003_Distorted from "../../assets/images/avatars/202003_Distorted.svg";
import avatar_202003_Mythic from "../../assets/images/avatars/202003_Mythic.svg";
import avatar_202003_PapaDos2 from "../../assets/images/avatars/202003_PapaDos2.svg";
import avatar_202004_Dreizehn from "../../assets/images/avatars/202004_Dreizehn.svg";
import avatar_202004_Ingenitus from "../../assets/images/avatars/202004_Ingenitus.svg";
import avatar_202004_LeonCelestine from "../../assets/images/avatars/202004_LeonCelestine.svg";
import avatar_202004_MsMarthe from "../../assets/images/avatars/202004_MsMarthe.svg";
import avatar_202004_Tashdacat from "../../assets/images/avatars/202004_Tashdacat.svg";
import avatar_202005_Ditto from "../../assets/images/avatars/202005_Ditto.svg";
import avatar_202005_DizzyIzzy from "../../assets/images/avatars/202005_DizzyIzzy.svg";
import avatar_202005_Dreizehn from "../../assets/images/avatars/202005_Dreizehn.svg";
import avatar_20200502_LeonCelestine from "../../assets/images/avatars/20200502_LeonCelestine.svg";
import avatar_20200502_MsMarthe from "../../assets/images/avatars/20200502_MsMarthe.svg";
import avatar_20200502_Mythic from "../../assets/images/avatars/20200502_Mythic.svg";
import avatar_20200502_SobaNoodle from "../../assets/images/avatars/20200502_SobaNoodle.svg";
import avatar_20200601_Ditto from "../../assets/images/avatars/20200601_Ditto.svg";
import avatar_20200601_kiwitea_artistry from "../../assets/images/avatars/20200601_kiwitea_artistry.svg";
import avatar_20200602_MsMarthe from "../../assets/images/avatars/20200602_MsMarthe.svg";
import avatar_20200701_Sforzanda from "../../assets/images/avatars/20200701_Sforzanda.svg";
import avatar_20200801_Sirpuaps from "../../assets/images/avatars/20200801_Sirpuaps.svg";
import avatar_20200802_Tashdacat from "../../assets/images/avatars/20200802_Tashdacat.svg";
import avatar_20200802_Sparrowhawk from "../../assets/images/avatars/20200802_Sparrowhawk.svg";
import avatar_20200901_MsMarthe from "../../assets/images/avatars/20200901_MsMarthe.svg";
import avatar_20200901_SobaNoodle from "../../assets/images/avatars/20200901_SobaNoodle.svg";
import avatar_20200902_Chosokabe from "../../assets/images/avatars/20200902_Chosokabe.svg";
import avatar_20200902_Symbelmyna from "../../assets/images/avatars/20200902_Symbelmyna.svg";
import avatar_20201001_SobaNoodle from "../../assets/images/avatars/20201001_SobaNoodle.svg";
import avatar_20201001_Zohariel from "../../assets/images/avatars/20201001_Zohariel.svg";
import avatar_20201101_AlexLeonheart from "../../assets/images/avatars/20201101_AlexLeonheart.svg";
import avatar_20201101_MsMarthe from "../../assets/images/avatars/20201101_MsMarthe.svg";
import avatar_20201101_Symbelmyna from "../../assets/images/avatars/20201101_Symbelmyna.svg";
import avatar_20201101_Zohariel from "../../assets/images/avatars/20201101_Zohariel.svg";
import avatar_20201201_Cannox from "../../assets/images/avatars/20201201_Cannox.svg";
import avatar_20201201_DimaJeydar from "../../assets/images/avatars/20201201_DimaJeydar.svg";
import avatar_20201201_hamildong from "../../assets/images/avatars/20201201_hamildong.svg";
import avatar_20201201_Zohariel from "../../assets/images/avatars/20201201_Zohariel.svg";
import avatar_20201202_PipkinTO from "../../assets/images/avatars/20201202_PipkinTO.svg";
import avatar_20201202_OctoBay from "../../assets/images/avatars/20201202_OctoBay.svg";
import avatar_20201202_Magnus909 from "../../assets/images/avatars/20201202_Magnus909.svg";
import avatar_20201202_Blakkmonk from "../../assets/images/avatars/20201202_Blakkmonk.svg";
import avatar_20201202_AlchemicalAgent from "../../assets/images/avatars/20201202_AlchemicalAgent.svg";
import avatar_20201202_AlaskanAshley from "../../assets/images/avatars/20201202_AlaskanAshley.svg";
import avatar_20210101_Zohariel from "../../assets/images/avatars/20210101_Zohariel.svg";
import avatar_20210101_DimaJeydar from "../../assets/images/avatars/20210101_DimaJeydar.svg";
import avatar_20210101_AlexLeonheart from "../../assets/images/avatars/20210101_AlexLeonheart.svg";
import avatar_20210101_abar22 from "../../assets/images/avatars/20210101_abar22.svg";
import avatar_20210101_Magnus909 from "../../assets/images/avatars/20210101_Magnus909.svg";
import avatar_20210101_thebostinian from "../../assets/images/avatars/20210101_thebostinian.svg";
import avatar_ico_no_1 from "../../assets/images/avatars/no/po/ico_no_1.svg";

const AvatarStrings = {
	ico_no_1: avatar_ico_no_1,
	ico_co_34: avatar_ico_co_34,
	ico_co_33: avatar_ico_co_33,
	ico_co_32: avatar_ico_co_32,
	ico_co_31: avatar_ico_co_31,
	ico_co_30: avatar_ico_co_30,
	ico_co_29: avatar_ico_co_29,
	ico_co_28: avatar_ico_co_28,
	ico_co_27: avatar_ico_co_27,
	ico_co_26: avatar_ico_co_26,
	ico_co_25: avatar_ico_co_25,
	ico_co_24: avatar_ico_co_24,
	ico_co_23: avatar_ico_co_23,
	ico_co_22: avatar_ico_co_22,
	ico_co_21: avatar_ico_co_21,
	ico_co_20: avatar_ico_co_20,
	ico_co_19: avatar_ico_co_19,
	ico_co_18: avatar_ico_co_18,
	ico_co_17: avatar_ico_co_17,
	ico_co_16: avatar_ico_co_16,
	ico_co_15: avatar_ico_co_15,
	ico_co_14: avatar_ico_co_14,
	ico_co_13: avatar_ico_co_13,
	ico_co_12: avatar_ico_co_12,
	ico_co_11: avatar_ico_co_11,
	ico_co_10: avatar_ico_co_10,
	ico_co_9: avatar_ico_co_9,
	ico_co_8: avatar_ico_co_8,
	ico_co_7: avatar_ico_co_7,
	ico_co_6: avatar_ico_co_6,
	ico_co_5: avatar_ico_co_5,
	ico_co_4: avatar_ico_co_4,
	ico_co_3: avatar_ico_co_3,
	ico_co_2: avatar_ico_co_2,
	ico_co_1: avatar_ico_co_1,
	ico_si_46: avatar_ico_si_46,
	ico_si_45: avatar_ico_si_45,
	ico_si_44: avatar_ico_si_44,
	ico_si_43: avatar_ico_si_43,
	ico_si_42: avatar_ico_si_42,
	ico_si_41: avatar_ico_si_41,
	ico_si_40: avatar_ico_si_40,
	ico_si_39: avatar_ico_si_39,
	ico_si_38: avatar_ico_si_38,
	ico_si_37: avatar_ico_si_37,
	ico_si_36: avatar_ico_si_36,
	ico_si_35: avatar_ico_si_35,
	ico_si_34: avatar_ico_si_34,
	ico_si_33: avatar_ico_si_33,
	ico_si_32: avatar_ico_si_32,
	ico_si_31: avatar_ico_si_31,
	ico_si_30: avatar_ico_si_30,
	ico_si_29: avatar_ico_si_29,
	ico_si_28: avatar_ico_si_28,
	ico_si_27: avatar_ico_si_27,
	ico_si_26: avatar_ico_si_26,
	ico_si_25: avatar_ico_si_25,
	ico_si_24: avatar_ico_si_24,
	ico_si_23: avatar_ico_si_23,
	ico_si_22: avatar_ico_si_22,
	ico_si_21: avatar_ico_si_21,
	ico_si_20: avatar_ico_si_20,
	ico_si_19: avatar_ico_si_19,
	ico_si_18: avatar_ico_si_18,
	ico_si_17: avatar_ico_si_17,
	ico_si_16: avatar_ico_si_16,
	ico_si_15: avatar_ico_si_15,
	ico_si_14: avatar_ico_si_14,
	ico_si_13: avatar_ico_si_13,
	ico_si_12: avatar_ico_si_12,
	ico_si_11: avatar_ico_si_11,
	ico_si_10: avatar_ico_si_10,
	ico_si_9: avatar_ico_si_9,
	ico_si_8: avatar_ico_si_8,
	ico_si_7: avatar_ico_si_7,
	ico_si_6: avatar_ico_si_6,
	ico_si_5: avatar_ico_si_5,
	ico_si_4: avatar_ico_si_4,
	ico_si_3: avatar_ico_si_3,
	ico_si_2: avatar_ico_si_2,
	ico_si_1: avatar_ico_si_1,
	ico_go_53: avatar_ico_go_53,
	ico_go_52: avatar_ico_go_52,
	ico_go_51: avatar_ico_go_51,
	ico_go_50: avatar_ico_go_50,
	ico_go_49: avatar_ico_go_49,
	ico_go_48: avatar_ico_go_48,
	ico_go_47: avatar_ico_go_47,
	ico_go_46: avatar_ico_go_46,
	ico_go_45: avatar_ico_go_45,
	ico_go_44: avatar_ico_go_44,
	ico_go_43: avatar_ico_go_43,
	ico_go_42: avatar_ico_go_42,
	ico_go_41: avatar_ico_go_41,
	ico_go_40: avatar_ico_go_40,
	ico_go_39: avatar_ico_go_39,
	ico_go_38: avatar_ico_go_38,
	ico_go_37: avatar_ico_go_37,
	ico_go_36: avatar_ico_go_36,
	ico_go_35: avatar_ico_go_35,
	ico_go_34: avatar_ico_go_34,
	ico_go_33: avatar_ico_go_33,
	ico_go_32: avatar_ico_go_32,
	ico_go_31: avatar_ico_go_31,
	ico_go_30: avatar_ico_go_30,
	ico_go_29: avatar_ico_go_29,
	ico_go_28: avatar_ico_go_28,
	ico_go_27: avatar_ico_go_27,
	ico_go_26: avatar_ico_go_26,
	ico_go_25: avatar_ico_go_25,
	ico_go_24: avatar_ico_go_24,
	ico_go_23: avatar_ico_go_23,
	ico_go_22: avatar_ico_go_22,
	ico_go_21: avatar_ico_go_21,
	ico_go_20: avatar_ico_go_20,
	ico_go_19: avatar_ico_go_19,
	ico_go_18: avatar_ico_go_18,
	ico_go_17: avatar_ico_go_17,
	ico_go_16: avatar_ico_go_16,
	ico_go_15: avatar_ico_go_15,
	ico_go_14: avatar_ico_go_14,
	ico_go_13: avatar_ico_go_13,
	ico_go_12: avatar_ico_go_12,
	ico_go_11: avatar_ico_go_11,
	ico_go_10: avatar_ico_go_10,
	ico_go_9: avatar_ico_go_9,
	ico_go_8: avatar_ico_go_8,
	ico_go_7: avatar_ico_go_7,
	ico_go_6: avatar_ico_go_6,
	ico_go_5: avatar_ico_go_5,
	ico_go_4: avatar_ico_go_4,
	ico_go_3: avatar_ico_go_3,
	ico_go_2: avatar_ico_go_2,
	ico_go_1: avatar_ico_go_1,
	ico_pl_35: avatar_ico_pl_35,
	ico_pl_34: avatar_ico_pl_34,
	ico_pl_33: avatar_ico_pl_33,
	ico_pl_32: avatar_ico_pl_32,
	ico_pl_31: avatar_ico_pl_31,
	ico_pl_30: avatar_ico_pl_30,
	ico_pl_29: avatar_ico_pl_29,
	ico_pl_28: avatar_ico_pl_28,
	ico_pl_27: avatar_ico_pl_27,
	ico_pl_26: avatar_ico_pl_26,
	ico_pl_25: avatar_ico_pl_25,
	ico_pl_24: avatar_ico_pl_24,
	ico_pl_23: avatar_ico_pl_23,
	ico_pl_22: avatar_ico_pl_22,
	ico_pl_21: avatar_ico_pl_21,
	ico_pl_20: avatar_ico_pl_20,
	ico_pl_19: avatar_ico_pl_19,
	ico_pl_18: avatar_ico_pl_18,
	ico_pl_17: avatar_ico_pl_17,
	ico_pl_16: avatar_ico_pl_16,
	ico_pl_15: avatar_ico_pl_15,
	ico_pl_14: avatar_ico_pl_14,
	ico_pl_13: avatar_ico_pl_13,
	ico_pl_12: avatar_ico_pl_12,
	ico_pl_11: avatar_ico_pl_11,
	ico_pl_10: avatar_ico_pl_10,
	ico_pl_9: avatar_ico_pl_9,
	ico_pl_8: avatar_ico_pl_8,
	ico_pl_7: avatar_ico_pl_7,
	ico_pl_6: avatar_ico_pl_6,
	ico_pl_5: avatar_ico_pl_5,
	ico_pl_4: avatar_ico_pl_4,
	ico_pl_3: avatar_ico_pl_3,
	ico_pl_2: avatar_ico_pl_2,
	ico_pl_1: avatar_ico_pl_1,
	ico_le_27: avatar_ico_le_27,
	ico_le_26: avatar_ico_le_26,
	ico_le_25: avatar_ico_le_25,
	ico_le_24: avatar_ico_le_24,
	ico_le_23: avatar_ico_le_23,
	ico_le_22: avatar_ico_le_22,
	ico_le_21: avatar_ico_le_21,
	ico_le_20: avatar_ico_le_20,
	ico_le_19: avatar_ico_le_19,
	ico_le_18: avatar_ico_le_18,
	ico_le_17: avatar_ico_le_17,
	ico_le_16: avatar_ico_le_16,
	ico_le_15: avatar_ico_le_15,
	ico_le_14: avatar_ico_le_14,
	ico_le_13: avatar_ico_le_13,
	ico_le_12: avatar_ico_le_12,
	ico_le_11: avatar_ico_le_11,
	ico_le_10: avatar_ico_le_10,
	ico_le_9: avatar_ico_le_9,
	ico_le_8: avatar_ico_le_8,
	ico_le_7: avatar_ico_le_7,
	ico_le_6: avatar_ico_le_6,
	ico_le_5: avatar_ico_le_5,
	ico_le_4: avatar_ico_le_4,
	ico_le_3: avatar_ico_le_3,
	ico_le_2: avatar_ico_le_2,
	ico_le_1: avatar_ico_le_1,
	ico_le_28: avatar_ico_le_28,
	august_ProfWinning: avatar_august_ProfWinning,
	august_PugaSensei: avatar_august_PugaSensei,
	august_TitanPB: avatar_august_TitanPB,
	August_AlexLeonheart: avatar_August_AlexLeonheart,
	August_MsMarthe: avatar_August_MsMarthe,
	August_Sforzanda: avatar_August_Sforzanda,
	Sept_Frexz: avatar_Sept_Frexz,
	Sept2_Nintendocyde: avatar_Sept2_Nintendocyde,
	Sept2_ProfWinning: avatar_Sept2_ProfWinning,
	Oct__Frexz: avatar_Oct__Frexz,
	Nov1_BaiLang: avatar_Nov1_BaiLang,
	Nov2019__MsMarthe: avatar_Nov2019__MsMarthe,
	Nov2019__BrutuZ: avatar_Nov2019__BrutuZ,
	"201912_Frexz": avatar_201912_Frexz,
	"202001_LeonCelestine": avatar_202001_LeonCelestine,
	"202001_MalacDondarion": avatar_202001_MalacDondarion,
	"202001_DaniVonZombie": avatar_202001_DaniVonZombie,
	"202001_Frexz_2": avatar_202001_Frexz_2,
	"202001_Ingenitus": avatar_202001_Ingenitus,
	"202001_kazel": avatar_202001_kazel,
	"202001_Ms.Marthe": avatar_202001_MsMarthe,
	"202001_P_Ninny": avatar_202001_P_Ninny,
	"202001_Profoundant89": avatar_202001_Profoundant89,
	"202001_Profoundant89_2": avatar_202001_Profoundant89_2,
	"202001_TitanPB": avatar_202001_TitanPB,
	"202001_Symbelmyna": avatar_202001_Symbelmyna,
	"202002_Ditto": avatar_202002_Ditto,
	"202002_PapaDossvg": avatar_202002_PapaDossvg,
	"202003_Tashdacat": avatar_202003_Tashdacat,
	"202003_LeonCelestine_2": avatar_202003_LeonCelestine_2,
	"202003_Dreizehn": avatar_202003_Dreizehn,
	"202003_Ditto_2": avatar_202003_Ditto_2,
	"202003_Demonsword": avatar_202003_Demonsword,
	"202003_Distorted": avatar_202003_Distorted,
	"202003_Mythic": avatar_202003_Mythic,
	"202003_PapaDos2": avatar_202003_PapaDos2,
	"202004_Dreizehn": avatar_202004_Dreizehn,
	"202004_Ingenitus": avatar_202004_Ingenitus,
	"202004_LeonCelestine": avatar_202004_LeonCelestine,
	"202004_MsMarthe": avatar_202004_MsMarthe,
	"202004_Tashdacat": avatar_202004_Tashdacat,
	"202005_Ditto": avatar_202005_Ditto,
	"202005_DizzyIzzy": avatar_202005_DizzyIzzy,
	"202005_Dreizehn": avatar_202005_Dreizehn,
	"20200502_LeonCelestine": avatar_20200502_LeonCelestine,
	"20200502_MsMarthe": avatar_20200502_MsMarthe,
	"20200502_Mythic": avatar_20200502_Mythic,
	"20200502_SobaNoodle": avatar_20200502_SobaNoodle,
	"20200601_Ditto": avatar_20200601_Ditto,
	"20200601_kiwitea_artistry": avatar_20200601_kiwitea_artistry,
	"20200602_MsMarthe": avatar_20200602_MsMarthe,
	"20200701_Sforzanda": avatar_20200701_Sforzanda,
	"20200801_Sirpuaps": avatar_20200801_Sirpuaps,
	"20200802_Tashdacat": avatar_20200802_Tashdacat,
	"20200802_Sparrowhawk": avatar_20200802_Sparrowhawk,
	"20200901_MsMarthe": avatar_20200901_MsMarthe,
	"20200901_SobaNoodle": avatar_20200901_SobaNoodle,
	"20200902_Chosokabe": avatar_20200902_Chosokabe,
	"20200902_Symbelmyna": avatar_20200902_Symbelmyna,
	"20201001_SobaNoodle": avatar_20201001_SobaNoodle,
	"20201001_Zohariel": avatar_20201001_Zohariel,
	"20201101_AlexLeonheart": avatar_20201101_AlexLeonheart,
	"20201101_MsMarthe": avatar_20201101_MsMarthe,
	"20201101_Symbelmyna": avatar_20201101_Symbelmyna,
	"20201101_Zohariel": avatar_20201101_Zohariel,
	"20201201_Cannox": avatar_20201201_Cannox,
	"20201201_DimaJeydar": avatar_20201201_DimaJeydar,
	"20201201_hamildong": avatar_20201201_hamildong,
	"20201201_Zohariel": avatar_20201201_Zohariel,
	"20201202_PipkinTO": avatar_20201202_PipkinTO,
	"20201202_OctoBay": avatar_20201202_OctoBay,
	"20201202_Magnus909": avatar_20201202_Magnus909,
	"20201202_Blakkmonk": avatar_20201202_Blakkmonk,
	"20201202_AlchemicalAgent": avatar_20201202_AlchemicalAgent,
	"20201202_AlaskanAshley": avatar_20201202_AlaskanAshley,
	"20210101_Zohariel": avatar_20210101_Zohariel,
	"20210101_DimaJeydar": avatar_20210101_DimaJeydar,
	"20210101_AlexLeonheart": avatar_20210101_AlexLeonheart,
	"20210101_abar22": avatar_20210101_abar22,
	"20210101_Magnus909": avatar_20210101_Magnus909,
	"20210101_thebostinian": avatar_20210101_thebostinian,
};

export default AvatarStrings;


