import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { globalStyles } from "../../styles/global";
import { BuildStyleMethod } from "../../styles/theming";
import { isAndroid, isIos, ws } from "../../tools/generic";
import AppIcon from "./AppIcon";
import AppText from "./AppText";
import BoxModal from "./modal/BoxModal";
import Popover from "./popover/Popover";

function AppPicker({ selectedValue, onValueChange, mode, pickerStyle, itemStyle, items, icon, hide, containerStyle }) {
	const styles = stylesMethod(global.theme);
	const popoverRef = React.useRef();

	let picker = null;
	let optionsComponent = null;

	if (!isAndroid()) {
		const [showselection, setshowselection] = React.useState();
		const selectedItem = React.useMemo(() => items.find((i) => i.value === selectedValue) || items[0], [
			selectedValue,
		]);

		const modalRef = React.useRef();

		const openSelection = React.useCallback(() => {
			if (isIos() || mode === "dialog") {
				modalRef.current.show();
			} else {
				popoverRef.current.show();
			}
		}, []);

		optionsComponent = items.map((item, index) => (
			<Hoverable key={index}>
				{(isHovered) => (
					<TouchableOpacity
						style={[{
							paddingVertical: 8,
							paddingHorizontal: ws(8, 0),
							backgroundColor: isHovered ? global.colors.hoverItemBackground : null,
						}, itemStyle]}
						onPress={() => {
							onValueChange(item.value);
							modalRef.current.hide();
							popoverRef.current.hide();
						}}
					>
						<AppText>{item.label}</AppText>
					</TouchableOpacity>
				)}
			</Hoverable>
		));

		picker = (
			<>
				<TouchableOpacity
					onPress={openSelection}
					style={[globalStyles.rc, { flex: 1, paddingHorizontal: 8, overflow: "hidden" }, pickerStyle]}
				>
					<AppText style={{ flex: 1 }} numberOfLines={1} ellipsizeMode="tail">
						{selectedItem.label}
					</AppText>
					<MaterialIcons name="arrow-drop-down" color={global.colors.textDefault} size={24} />
				</TouchableOpacity>

				<BoxModal
					ref={modalRef}
					visible={showselection}
					onRequestClose={() => setshowselection(false)}
					transparent
					options={[]}
					boxStyle={{ minHeight: null }}
				>
					{optionsComponent}
				</BoxModal>
			</>
		);
	} else {
		picker = (
			<Picker
				selectedValue={selectedValue}
				onValueChange={onValueChange}
				mode={mode}
				style={pickerStyle}
				itemStyle={itemStyle}
			>
				{items.map((item, index) => (
					<Picker.Item color={global.colors.textDark} key={index} label={item.label} value={item.value} />
				))}
			</Picker>
		);
	}

	const iconComponent = icon ? (
		<AppIcon size={24} color={global.colors.textDefault} {...icon} />
	) : icon === undefined ? (
		<MaterialCommunityIcons name="sort-descending" size={24} color={global.colors.textDefault} />
	) : null;

	if (hide) return null;

	return (
		<Popover
			containerStyle={[styles.container, containerStyle]}
			ref={popoverRef}
			arrowPosition="top"
			popoverComponent={optionsComponent}
		>
			{iconComponent}
			{picker}
		</Popover>
	);
}

export default AppPicker;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flexDirection: "row",
			alignItems: "center",
			backgroundColor: colors.secondary,
			height: 32,
			paddingLeft: 4,
			marginRight: 4,
			flex: 1,
			maxWidth: "50%",
		},
	})
);
