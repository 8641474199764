import * as React from "react";
import { StyleSheet, Switch, View } from "react-native";
import CheckBox from "../../reimports/Checkbox";
import { isIos } from "../../tools/generic";
// import CheckBox from "@react-native-community/checkbox";
import AppText from "./AppText";


const basicStyle = StyleSheet.create({
	default: {
		flexDirection: "row",
		alignItems: "center",
	},
});

function SimpleCheckbox({ label, setValue, checked, style }) {
	return (
		<View style={[basicStyle.default, style]}>
			{isIos() ? (
				<Switch
					style={{ transform: [{ scale: 0.7 }] }}
					trackColor={{ false: global.colors.secondary, true: global.colors.attention }}
					thumbColor={checked ? "white" : "white"}
					onValueChange={setValue}
					value={checked}
				/>
			) : (
				<CheckBox value={checked} onValueChange={setValue} tintColors={{false: global.colors.hint }}/>
			)}
			<AppText style={{marginLeft: 8}}>{label}</AppText>
		</View>
	);
}

export default SimpleCheckbox;
