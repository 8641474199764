import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { cStyle } from "../../tools/react";
import CoinValue from "../coins/CoinValue";
import GameTagHorizontalList from "../gametags/GameTagHorizontalList";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import CondView from "../meta/CondView";
import { useNavigation } from "@react-navigation/native";
import { deleteBookmark, saveBookmark } from "../../store/slices/bookmarksSlice";
import { receiveBookmarkedGames } from "../../store/slices/gamesSlice";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

import { BuildStyleMethod } from "../../styles/theming";
import { getTimestamp } from "../../tools/time";
import { isWeb } from "../../tools/generic";

function GameCardCompact({ game, tagIdToHide, large, small, style, dispatch, bookmarkedGames, anonymous }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();

	const bookmarkModalRef = React.useRef();
	const bookmarkRemovedModalRef = React.useRef();

	const bookmarked = bookmarkedGames.some((g) => g.id === game.id);

	const pathToBg = `${global.root_url}/static/images/stagebg/bg_${game.cover_background?.name || "neutral_1"}.png`;
	const locked = !!game.password;
	const playerColor = locked || game.archived ? "secondary" : "textLight";

	const toggleBookmark = React.useCallback(() => {
		if (bookmarked) {
			dispatch(deleteBookmark(game.id));
			dispatch(receiveBookmarkedGames({ games: bookmarkedGames.rg_removeElementByIdPure(game.id) }));
			bookmarkRemovedModalRef.current.show();
		} else {
			dispatch(saveBookmark(game.id));
			dispatch(receiveBookmarkedGames({ games: bookmarkedGames.concat(game) }));
			bookmarkModalRef.current.show();
		}
	}, [bookmarked, game, bookmarkedGames]);

	const updateDateComponent = (
		<AppText hide={!large} bold size="small" color="secondary" style={{ marginBottom: 4 }}>
			{game.archived
				? _("ended %(last_update_date)s", "game details", {
						last_update_date: getTimestamp(game.last_line?.created || game.last_update),
				  })
				: _("updated %(last_update_date)s", "game details", {
						last_update_date: getTimestamp(game.last_update),
				  })}
		</AppText>
	);

	return (
		<View
			style={cStyle([
				styles.container,
				[!!large, styles.containerLarge],
				[!!small, styles.containerSmall],
				style,
			])}
		>
			<TouchableOpacity
				onPress={() => navigation.navigate("GameDetailsPanelScreen", { gameslug: game.slug })}
				style={cStyle([styles.insideBox, [!!large, styles.insideBoxLarge], [!!small, styles.insideBoxSmall]])}
			>
				<Image
					source={{ uri: pathToBg }}
					style={cStyle([
						styles.background,
						[!!large, styles.backgroundLarge],
						[!!small, styles.backgroundSmall],
					])}
					resizeMode="cover"
				/>

				<View style={styles.bottomRow}>
					<AppText bold numberOfLines={small ? 1 : 2} ellipsizeMode="tail" size={small ? "small" : null}>
						{game.name}
					</AppText>

					<AppText color="secondary" hide={!large} numberOfLines={2} ellipsizeMode="tail" size="small">
						{game.description}
					</AppText>

					<CondView show={!small} style={{ flexDirection: "row", alignItems: "center" }}>
						<FontAwesome name="user" size={12} style={{ marginRight: 4 }} color={colors[playerColor]} />
						<AppText color={playerColor} size="small" hide={game.archived}>
							{game.players.length}/{game.max_players}
						</AppText>
						<AppText hide={!game.archived} color={playerColor} size="small">
							{game.players.length}{" "}
							<AppText italic color="secondary">
								({_("Archived", "game is archived")})
							</AppText>
						</AppText>
						{locked && (
							<FontAwesome name="lock" size={10} style={{ marginLeft: 4 }} color={colors[playerColor]} />
						)}
					</CondView>
					
					{updateDateComponent}

					<CondView show={large} style={[styles.footer, { flexDirection: "row-reverse" }]}>
						<CoinValue subject={game} subjectGetter={{ game_id: game.id }} syle={{ flex: 1 }} hideIfNone />
						<AppText
							color="secondary"
							numberOfLines={1}
							ellipsizeMode="tail"
							style={{ flex: 1 }}
							color="secondary"
						>
							<AppText size="small" color="secondary">
								{_("by %(gm_name)s", "indicated the gm of a game", {
									gm_name: (
										<AppText key="1" bold color="secondary">
											{game.game_master.username}
										</AppText>
									),
								})}
							</AppText>
						</AppText>
					</CondView>

					{small && <GameTagHorizontalList game={game} large={large} tagIdToHide={tagIdToHide} />}
				</View>

				<CondView show={!large && !small} style={styles.footer}>
					<AppText color="secondary" numberOfLines={1} ellipsizeMode="tail" style={{ flex: 1 }}>
						<AppText size="small">
							{_("by %(gm_name)s", "indicated the gm of a game", {
								gm_name: (
									<AppText key="1" bold color="secondary">
										{game.game_master.username}
									</AppText>
								),
							})}
						</AppText>
					</AppText>
					<CoinValue subject={game} subjectGetter={{ game_id: game.id }} syle={{ flex: 1 }} hideIfNone />
				</CondView>
			</TouchableOpacity>

			<CondView show={!small && !anonymous} style={styles.topRow}>
				<GameTagHorizontalList game={game} large={large} tagIdToHide={tagIdToHide} />
				<IconButton
					style={{ height: 32, width: 32 }}
					icon={{
						type: MaterialIcons,
						name: bookmarked ? "bookmark" : "bookmark-border",
						color: global.colors.textLight,
					}}
					transparent
					onPress={toggleBookmark}
				/>
			</CondView>

			<TinyFeedbackModal ref={bookmarkModalRef} message={_("Bookmarked!", "confirm bookmark added")} />
			<TinyFeedbackModal
				ref={bookmarkRemovedModalRef}
				message={_("Bookmark removed", "confirm bookmark removed")}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		bookmarkedGames: state.games.bookmarked,
		anonymous: !state.user,
	};
};

export default connect(mapStateToProps)(GameCardCompact);

const size = 180;
const sizeLarge = 220;
const sizeSmall = 128;
const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			width: size,
		},
		insideBox: {
			height: size,
			justifyContent: "flex-end",
		},
		background: {
			position: "absolute",
			height: size,
			width: size,
			backgroundColor: "black",
		},
		tags: {
			flexDirection: "row",
			flex: 1,
		},
		topRow: {
			padding: 4,
			justifyContent: "space-between",
			alignItems: "center",
			flexDirection: "row",
			color: colors.textLight,
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
		},
		bottomRow: {
			padding: 4,
			backgroundColor: "rgba(0,0,0,0.5)",
		},
		footer: {
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			minHeight: 20,
		},

		containerLarge: {
			width: null,
			marginBottom: 8,
		},
		insideBoxLarge: {
			height: sizeLarge,
		},
		backgroundLarge: {
			width: "100%",
			height: sizeLarge,
		},

		containerSmall: {
			width: sizeSmall,
		},
		insideBoxSmall: {
			height: sizeSmall,
		},
		backgroundSmall: {
			width: "100%",
			height: sizeSmall,
		},
	})
);
