import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import {
	fetchNextPublicHelpers,
	fetchPublicHelpers,
	fetchUserHelpers,
	clearPublicHelpers,
	fetchFavoriteHelpers,
	createInteractiveHelper,
} from "../../store/slices/interactiveHelpersSlice";
import InteractiveHelperItem from "./InteractiveHelperItem";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import AppFlatList from "../generic/AppFlatList";
import { _ } from "../../i18n/i18n";
import SearchBar from "../generic/Searchbar";
import AppScreenView from "../AppScreenView";
import CoinGivenFeedback from "../coins/CoinGivenFeedback";
import { FAB } from "react-native-paper";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import BoxModal from "../generic/modal/BoxModal";
import { apiFetch } from "../../network/apiFetch";
import { fullScreenLeftPadding, fullScreenContentWidth } from "../../styles/dynamicStyles";
import { useLayout } from "react-native-web-hooks";

function OpenHelperScreen({ route, navigation, dispatch, user, publicHelpers, publicNextUrl, userHelpers, favorites }) {
	const { searching } = route.params || {};

	const [currentSearch, setcurrentSearch] = React.useState(null);
	const confirmCreateModalRef = React.useRef();
	const [creating, setcreating] = React.useState(false);

	React.useEffect(() => {
		dispatch(fetchUserHelpers(user.id));
		dispatch(fetchFavoriteHelpers(user.id));
	}, [user.id]);

	const performSearch = React.useCallback(() => {
		const params = {};
		if (currentSearch) {
			params.search = currentSearch;
		}
		dispatch(clearPublicHelpers());
		dispatch(fetchPublicHelpers(params));
	}, [currentSearch]);

	React.useEffect(() => {
		if (!currentSearch) {
			performSearch();
		} else {
			const searchTimeout = setTimeout(performSearch, 1000);
			return () => clearTimeout(searchTimeout);
		}
	}, [currentSearch]);

	const renderHelper = React.useCallback(
		({ item }) => <InteractiveHelperItem key={item.id} interactiveHelper={item} />,
		[]
	);

	const createHelper = React.useCallback(async () => {
		setcreating(true);
		const helper = await dispatch(createInteractiveHelper());
		setcreating(false);
		navigation.navigate("EditInteractiveHelperScreen", { helperId: helper.id });
	}, [user.id]);

	const searchHeaderComponent = (
		<SearchBar autoFocus style={{ marginTop: 8, marginBottom: 16 }} onSearchUpdated={setcurrentSearch} />
	);

	const headerComponent = (
		<View>
			<AppText color="hint" style={{marginTop: 8}}>
				{ws(
					_("Click and hold down to see an helper's description"),
					_("Tap and hold down to see an helper's description")
				)}
			</AppText>
			<AppText bold style={styles.separatorTitle}>
				{_("Your helpers", "interactive helpers screen")}
			</AppText>
			<AppFlatList
				scrollEnabled={false}
				data={userHelpers}
				renderItem={renderHelper}
				ListEmptyComponent={
					<AppText color="hint" italic style={{ marginHorizontal: 16 }}>
						{_("You haven't created any helper yet.")}
					</AppText>
				}
			/>
			<AppText bold style={styles.separatorTitle}>
				{_("Favorites", "favorite interactive helpers")}
			</AppText>
			<AppFlatList
				scrollEnabled={false}
				data={favorites}
				renderItem={renderHelper}
				ListEmptyComponent={
					<AppText color="hint" italic style={{ marginHorizontal: 16 }}>
						{_("No favorites yet.", "no favorite helpers")}
					</AppText>
				}
			/>
			<AppText bold style={styles.separatorTitle}>
				{_("Community helpers", "interactive helpers screen")}
			</AppText>
		</View>
	);

	const { onLayout } = useLayout();

	return (
		<AppScreenView
			onLayout={onLayout}
			style={ws({
				paddingLeft: fullScreenLeftPadding(),
			})}
			borderless
		>
			<FlatList
				contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
				keyExtractor={idKeyExtractor}
				data={publicHelpers}
				renderItem={renderHelper}
				onEndReached={() => dispatch(fetchNextPublicHelpers(publicNextUrl))}
				ListHeaderComponent={searching ? searchHeaderComponent : headerComponent}
			/>
			<CoinGivenFeedback />
			<FAB
				icon="plus"
				style={globalStylesMethod(global.theme).fab}
				onPress={() => confirmCreateModalRef.current.show()}
				disabled={creating}
			/>
			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create helper")}
				message={_("Create a new interactive helper?")}
				onConfirm={createHelper}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		publicHelpers: state.interactiveHelpers.public,
		favorites: state.interactiveHelpers.favorites,
		userHelpers: state.interactiveHelpers.userHelpers,
		publicNextUrl: state.interactiveHelpers.publicNextUrl,
	};
};

export default connect(mapStateToProps)(OpenHelperScreen);

const styles = StyleSheet.create({
	separatorTitle: {
		marginHorizontal: ws(0, 16),
		marginVertical: 8,
	},
});
