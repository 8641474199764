import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import AsyncStorage from '@react-native-async-storage/async-storage';

export const stageBackgroundsSlice = createSlice({
	name: "stageBackgrounds",
	initialState: [],
	reducers: {
		receiveStageBackgrounds: (state, { payload: stageBackgrounds }) => stageBackgrounds
	}
});

export const { receiveStageBackgrounds } = stageBackgroundsSlice.actions;

export function fetchStageBackgrounds() {
	return async (dispatch, getState) => {
		let stageBackgrounds = await AsyncStorage.getItem("stageBackgrounds");
		if (stageBackgrounds) {
			stageBackgrounds = JSON.parse(stageBackgrounds);
			dispatch(receiveStageBackgrounds(stageBackgrounds));
		}

		apiFetch("stage-backgrounds", "GET", { "page-size": 100 })
			.then(({results}) => {				
				dispatch(receiveStageBackgrounds(results));
				AsyncStorage.setItem("stageBackgrounds", JSON.stringify(results));
			})
			.catch(() => null);
	};
}