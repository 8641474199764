import * as React from "react";
import { View, StyleSheet } from "react-native";
import CoinIcon from "./CoinIcon";
import AppText from "../generic/AppText";
import { levelToCoinName, levelToCoinFileName } from "../../tools/account";
import { colors } from "../../styles/colors";

function CoinText({ level, amount }) {
	return (
		<View style={styles.container}>
			<AppText>
				{amount}x{" "}
				<AppText style={{ color: global.colors.accountColors[levelToCoinFileName(level).toLowerCase()] }}>
					{levelToCoinName(level)}
				</AppText>
			</AppText>
			<CoinIcon level={level}/>
		</View>
	);
}

export default CoinText;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center"
	}
});
