import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Appbar, Avatar } from "react-native-paper";
import { getCoverUrl } from "../../tools/games";
import { isIos } from "../../tools/generic";

const styles = StyleSheet.create({
	header: isIos() ? {} : { flexDirection: "row" }
});

function GameBackAction({ navigation, game }) {
	return (
		<View style={styles.header}>
			<Appbar.BackAction onPress={navigation.goBack} color={global.colors.textDefault} />
			{!isIos() && <Avatar.Image source={{ uri: getCoverUrl(game) }} size={48} />}
		</View>
	);
}

export default GameBackAction;
