import * as React from "react";
import { View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import { updateTheme } from "../../tools/theme";
import AppScreenView from "../AppScreenView";
import AppButton from "../generic/buttons/AppButton";

function AppDisplaySettings({ dispatch }) {
	const changeTheme = React.useCallback((theme) => {
		updateTheme(theme, dispatch);
	}, []);

	const { onLayout } = useLayout();

	return (
		<AppScreenView
			onLayout={onLayout}
			style={[{ alignItems: "flex-start" }, ws({ paddingLeft: fullScreenLeftPadding() })]}
		>
			<View>
				<AppButton
					title={_("Darkest", "app theme name")}
					size="large"
					style={{
						backgroundColor: colors.darkestTheme.cardBackground,
						borderColor: colors.darkestTheme.borderDefault,
						borderWidth: 1,
					}}
					overrideHoverStyle={{
						backgroundColor: colors.darkestTheme.cardBackgroundLight,
					}}
					onPress={() => changeTheme("darkest")}
				/>
				<AppButton
					title={_("Default", "app theme name")}
					size="large"
					style={{
						backgroundColor: colors.darkTheme.cardBackground,
						borderColor: colors.darkTheme.primary,
						borderWidth: 1,
					}}
					overrideHoverStyle={{
						backgroundColor: colors.darkTheme.cardBackgroundLight,
					}}
					onPress={() => changeTheme("dark")}
				/>
			</View>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default connect(mapStateToProps)(AppDisplaySettings);
