import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import WizardGameMasterScreen from "./WizardGameMasterScreen";
import WizardPlayerScreen from "./WizardPlayerScreen";
import WizardRoleScreen from "./WizardRoleScreen";
import { _ } from '../../../i18n/i18n';

const Stack = createStackNavigator();

function PlayGameWizardStack() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen
				name="WizardRoleScreen"
				component={WizardRoleScreen}
				options={{ title: _("Play | Role Gate") }}
			/>
			<Stack.Screen name="WizardGameMasterScreen" component={WizardGameMasterScreen} />
			<Stack.Screen name="WizardPlayerScreen" component={WizardPlayerScreen} />
		</Stack.Navigator>
	);
}

export default PlayGameWizardStack;
