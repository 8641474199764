import * as React from "react";
import { View, Image } from "react-native";
import { levelToCoinFileName } from "../../tools/account";

function CoinIcon({ level, size = 24, style }) {
	const path = `${global.root_url}/static/images/accounts/${levelToCoinFileName(level)}.png`;

	return (<Image source={{ uri: path }} style={[{ height: size, width: size }, style]} />);
}

export default CoinIcon;
