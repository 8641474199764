import { Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { deleteInteractiveHelperPanel, receiveEditedHelper } from "../../store/slices/interactiveHelpersSlice";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/global";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";
import { connect } from "react-redux";
import PopoverMessage from "../generic/popover/PopoverMessage";
import { isWide } from "../../tools/generic";

function InteractiveHelperPanelItem({ helper, panel, onPress, panelSelection, dispatch, userId }) {
	const navigation = useNavigation();
	const confirmDeleteModalRef = React.useRef();

	const deletePanel = React.useCallback(() => {
		dispatch(deleteInteractiveHelperPanel(panel));
	}, [panel]);

	const isDefault = helper.default_panel?.id === panel.id;

	const makeDefault = React.useCallback(() => {
		apiFetch(`users/${userId}/helpers/${helper.id}`, "PATCH", {
			default_panel_id: panel.id,
		}).then(() => {
			dispatch(receiveEditedHelper({ ...helper, default_panel: panel }));
		});
	}, [userId, helper.id, panel]);

	React.useEffect(()=>{
		if(!helper.default_panel){
			makeDefault();
		}
	}, [helper.default_panel, makeDefault]);

	return (
		<View style={[globalStyles.rc, { width: "100%" }]}>
			{!panelSelection && (
				<IconButton
					transparent
					icon={{ type: MaterialIcons, name: "play-arrow" }}
					onPress={makeDefault}
					disabled={isDefault}
					popoverProps={{
						popoverComponent: (
							<PopoverMessage
								message={_(
									"Set default panel",
									"option to set the default panel in the current interactive helper"
								)}
							/>
						),
						arrowPosition: isWide() ? "bottom" : "left",
						style: { minWidth: 150 },
					}}
				/>
			)}
			<ListButton
				style={{ flex: 1 }}
				title={panel.name}
				iconLeft={{
					type: MaterialCommunityIcons,
					name: "file-presentation-box",
					color: isDefault ? global.colors.attention : global.colors.textDefault,
				}}
				onPress={
					onPress
						? () => onPress(panel)
						: () =>
								navigation.navigate("EditInteractiveHelperPanelScreen", {
									panelId: panel.id,
									helperId: helper.id,
								})
				}
			/>
			<IconButton
				transparent
				icon={{ type: Feather, name: "delete" }}
				onPress={() => confirmDeleteModalRef.current.show()}
			/>
			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete panel")}
				message={
					<AppText>
						{_(
							"Delete the panel %(panel_name)s? You cannot revert this action and will lose all of its content.",
							"delete interactive helper panel",
							{
								panel_name: (
									<AppText key="panel_name" bold>
										{panel.name}
									</AppText>
								),
							}
						)}
					</AppText>
				}
				isDelete
				onConfirm={deletePanel}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(InteractiveHelperPanelItem);


