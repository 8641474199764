import * as React from "react";
import { View, StyleSheet, FlatList, TouchableOpacity } from "react-native";
import { getCurrentWord, getCurrentWordBoundaries } from "../../tools/input";
import AppText from "./AppText";
import { colors } from "../../styles/colors";
import { isWeb, ws } from "../../tools/generic";
import { Portal } from "react-native-paper";
import { Hoverable } from "react-native-web-hooks";
import { cStyle } from "../../tools/react";
import { Dimensions } from "react-native";
import { BuildStyleMethod } from "../../styles/theming";

class Autocomplete extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentWord: null,
			items: [],
			measure: { x: 0, y: 0, width: 0, height: 0 },
			selectedIndex: 0,
			justSelected: false,
			lastCompleteCaretPos: null,
		};

		this.containerRef = React.createRef();
		this.clearItemTimeout = null;
	}

	componentDidUpdate(prevProps, prevState) {
		const { currentWord, selectedIndex, items, justSelected, lastCompleteCaretPos } = this.state;
		const { selection, text, focused } = this.props;

		const wordBoundaryRegex = this.getBoundaryRegex();

		const newCurrentWord = getCurrentWord(selection.start, text, wordBoundaryRegex);
		if (newCurrentWord !== currentWord) {
			this.setState({ currentWord: newCurrentWord });
		}

		if (this.state.currentWord !== prevState.currentWord && selection.start !== lastCompleteCaretPos) {
			// When we just selected an item, the focus() call call didUpdate before the autocomplete did its work. So ignore that update.
			if (justSelected) {
				this.setState({ justSelected: false });
			} else {
				this.updateItems();
			}
		}

		if (!focused && prevProps.focused) {
			// Adding a timer so that it doesn't remove the components before the input is used
			this.clearItemTimeout = setTimeout(this.clearItems, isWeb() ? 100 : 0);
		}

		let newIndex = selectedIndex;
		if (selectedIndex < 0) newIndex = items.length - 1;
		else if (selectedIndex >= items.length) newIndex = 0;
		if (newIndex !== selectedIndex) {
			this.setState({ selectedIndex: newIndex });
		}
	}

	componentWillUnmount() {
		clearTimeout(this.clearItemTimeout);
	}

	handleKey(e) {
		const {
			nativeEvent: { key: keyValue },
		} = e;

		const { items, selectedIndex } = this.state;

		let newIndex = selectedIndex;

		switch (keyValue) {
			case "Escape":
				if (items.length) {
					e.preventDefault();
					this.clearItems();
				}
				break;
			case "Enter":
				if (items.length) {
					e.preventDefault();
					this.selectItem(items[selectedIndex || 0]);
				}
				break;
			case "ArrowUp":
				if (items.length) {
					e.preventDefault();
					newIndex = (selectedIndex !== null ? selectedIndex : items.length) - 1;
					this.setState({ selectedIndex: newIndex });
				}
				break;
			case "ArrowDown":
				if (items.length) {
					e.preventDefault();
					newIndex = (selectedIndex !== null ? selectedIndex : -1) + 1;
				}
				break;
			default:
				break;
		}

		if (newIndex !== selectedIndex) {
			this.setState({ selectedIndex: newIndex });
		}
	}

	clearItems = (justSelected) => {
		this.setState({ items: [], justSelected });
	};

	itemToString = (item) => {
		const { itemToStringMap } = this.props;

		if (itemToStringMap) {
			return itemToStringMap.find((el) => el.item === item)?.string;
		}
		if (typeof item === "string") return item;
		return "no string";
	};

	filterItems = (item, word) => {
		const { filterItemsByKey, marker } = this.props;

		let itemName = item;

		// use a non-method to be able to receive params from navigation
		if (filterItemsByKey) {
			itemName = item[filterItemsByKey];
		} else {
			itemName = this.itemToString(item);
		}

		if (typeof itemName !== "string") return false;

		itemName = itemName.toLowerCase();
		word = word.toLowerCase();
		if (marker) {
			word = word.substr(marker.length);
		}

		return itemName.includes(word) && itemName !== word;
	};

	getBoundaryRegex() {
		const { wordBoundaryRegexString, wordBoundaryRegex, wordBoundaryRegexStringFlags } = this.props;
		if (wordBoundaryRegexString) {
			return new RegExp(wordBoundaryRegexString, wordBoundaryRegexStringFlags);
		}
		return wordBoundaryRegex;
	}

	updateItems = () => {
		const { marker, itemsPool = [], maxResults = 5, reverseList } = this.props;
		const { currentWord } = this.state;

		if (!currentWord) return this.clearItems();
		if (marker && !currentWord.startsWith(marker)) {
			return this.clearItems();
		}

		const newItems = itemsPool.filter((item) => this.filterItems(item, currentWord)).slice(0, maxResults);
		if (reverseList) {
			newItems.reverse();
		}

		this.setState({ items: newItems });
	};

	selectItem = (item) => {
		let {
			selection,
			text,
			textInput,
			setselection,
			appendToAutocomplete = " ",
			changeText,
			extractAutocompleteWord,
			onItemSelected,
		} = this.props;

		if (isWeb()) {
			textInput.focus();
		}

		const wordBoundaryRegex = this.getBoundaryRegex();

		const { start, end } = getCurrentWordBoundaries(selection.start, text, wordBoundaryRegex);

		let autoWord = extractAutocompleteWord ? extractAutocompleteWord(item) : this.itemToString(item);

		text = text.substr(0, start) + autoWord + appendToAutocomplete + text.substr(end);

		const caretPos = start + autoWord.length + appendToAutocomplete.length;
		setselection({ start: caretPos, end: caretPos });
		this.setState({ lastCompleteCaretPos: caretPos });
		changeText(text);
		this.clearItems(true);
		onItemSelected && onItemSelected(item);
	};

	render() {
		const styles = stylesMethod(global.theme);

		let { renderMethod, noScroll, showOnTop } = this.props;

		const { items, measure, selectedIndex } = this.state;

		if (!items.length) return null;

		renderMethod =
			renderMethod ||
			((item) => (
				<AppText style={{ paddingVertical: 20, paddingHorizontal: 8 }}>{this.itemToString(item)}</AppText>
			));

		if (noScroll) {
			return items.map((item, index) => (
				<Hoverable>
					{(isHovered) => (
						<TouchableOpacity
							key={index}
							onPress={() => this.selectItem(item)}
							style={cStyle([styles.item, [isHovered || selectedIndex === index, styles.itemHovered]])}
						>
							{renderMethod(item)}
						</TouchableOpacity>
					)}
				</Hoverable>
			));
		}

		const window = Dimensions.get("window");

		const webStyle = showOnTop
			? {
					bottom: window.height - measure.y,
					left: measure.x,
			  }
			: {
					top: measure.y,
					left: measure.x,
			  };

		const list = (
			<FlatList
				style={ws([
					{
						position: "absolute",
						opacity: measure.y ? 1 : 0,
					},
					webStyle,
				])}
				data={items}
				renderItem={({ item, index }) => (
					<Hoverable>
						{(isHovered) => (
							<TouchableOpacity
								onPress={() => this.selectItem(item)}
								style={cStyle([
									styles.item,
									[isHovered || selectedIndex === index, styles.itemHovered],
								])}
							>
								{renderMethod(item)}
							</TouchableOpacity>
						)}
					</Hoverable>
				)}
				keyExtractor={(item, index) => String(index)}
				keyboardShouldPersistTaps="always"
			/>
		);

		if (isWeb()) {
			const saveMesure = (x, y, width, height) => {
				this.setState({ measure: { x, y, width, height } });
			};

			return (
				<View
					ref={this.containerRef}
					onLayout={() => {
						if (this.containerRef.current) {
							this.containerRef.current.measureInWindow(saveMesure);
						}
					}}
				>
					<Portal>{list}</Portal>
				</View>
			);
		}

		return list;
	}
}

Autocomplete.defaultProps = {
	itemsPool: [],
	maxResults: 5,
	wordBoundaryRegexStringFlags: "",
	appendToAutocomplete: " ",
};

export default Autocomplete;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		item: {
			backgroundColor: colors.cardBackground,
		},
		itemHovered: ws({
			backgroundColor: colors.hoverItemBackground,
		}),
	})
);
