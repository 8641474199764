import { MaterialIcons } from "@expo/vector-icons";
import { HeaderBackButton } from "@react-navigation/elements";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import Animated, { interpolate, useAnimatedStyle } from "react-native-reanimated";
import { transformOrigin } from "react-native-redash";
import { SafeAreaView } from "react-native-safe-area-context";
import { _ } from "../../i18n/i18n";
import { isIos } from "../../tools/generic";
import AppText from "./AppText";
import IconButton from "./buttons/IconButton";
import EditTextModal from "./EditTextModal";

export const HEADER_HEIGHT_OPENED = 256;
export const HEADER_HEIGHT_COLLAPSED = 56;
const MAX_TITLE_SCALE = 1.5;
const HEADER_LEFT_WIDTH = isIos() ? 82 : 64;

const START_CHANGE_PERCENTAGE = 0.2;

const TEXT_CONTAINER_HEIGHT_OPENED = HEADER_HEIGHT_OPENED - 16;
const TEXT_CONTAINER_HEIGHT_COLLAPSED = HEADER_HEIGHT_COLLAPSED - 24;

function CollapsableHeader({
	scrollAnimatedValue,
	headerLeft,
	headerTitle,
	headerRight,
	openedComponent,
	collapsedComponent,
	openedAt = 0,
	closedAt = 250,
	title,
	subtitle,
	showEditTitleButton,
	onTitleChanged,
}) {
	const [titleDefaultDimension, settitleDefaultDimension] = React.useState({ width: 0, height: 0 });
	const [smallTitleDefaultDimension, setsmallTitleDefaultDimension] = React.useState({ width: 0, height: 0 });
	const navigation = useNavigation();

	const editModalRef = React.useRef();

	const onLayout = React.useCallback((e) => {
		const { width, height } = e.nativeEvent.layout;
		settitleDefaultDimension({ width, height });
	}, []);
	const onLayoutSmall = React.useCallback((e) => {
		const { width, height } = e.nativeEvent.layout;
		setsmallTitleDefaultDimension({ width, height });
	}, []);

	const windowDim = Dimensions.get("window");
	const openedTitleMaxWidth = (windowDim.width - (showEditTitleButton ? 64 : 16)) / MAX_TITLE_SCALE;

	const collapsedCompOpacityStyle = useAnimatedStyle(() => {
		const collapsedCompOpacity = interpolate(
			scrollAnimatedValue.value,
			[closedAt * 0.75, closedAt],
			[0, 1],
			"clamp"
		);
		return { opacity: collapsedCompOpacity };
	});

	const subtitleOpacityStyle = useAnimatedStyle(() => {
		const subtitleOpacity = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * 0.2, closedAt],
			[1, 1, 0],
			"clamp"
		);
		return { opacity: subtitleOpacity };
	});

	const editButtonOpacityStyle = useAnimatedStyle(() => {
		const editButtonOpacity = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * 0.75, closedAt * 0.8],
			[1, 1, 0],
			"clamp"
		);
		return { opacity: editButtonOpacity };
	});

	const titleSmallOpacityStyle = useAnimatedStyle(() => {
		const titleSmallOpacity = interpolate(scrollAnimatedValue.value, [closedAt * 0.9, closedAt], [0, 1], "clamp");
		return { opacity: titleSmallOpacity };
	});

	const titleBottomStyle = useAnimatedStyle(()=>{
		const titleBottom = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * START_CHANGE_PERCENTAGE, closedAt],
			[16, 16, TEXT_CONTAINER_HEIGHT_COLLAPSED * 0.5 - smallTitleDefaultDimension.height * 0.5],
			"clamp"
		);
		return {bottom: titleBottom};
	})

	const titleStyle = useAnimatedStyle(() => {
		const titleBigOpacity = interpolate(scrollAnimatedValue.value, [closedAt * 0.9, closedAt], [1, 0], "clamp");

		const titleBottom = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * START_CHANGE_PERCENTAGE, closedAt],
			[16, 16, TEXT_CONTAINER_HEIGHT_COLLAPSED * 0.5 - smallTitleDefaultDimension.height * 0.5],
			"clamp"
		);
		const titleScale = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * START_CHANGE_PERCENTAGE, closedAt],
			[MAX_TITLE_SCALE, MAX_TITLE_SCALE, 1],
			"clamp"
		);

		return {
			position: "absolute",
			bottom: titleBottom,
			opacity: titleBigOpacity,
			transform: transformOrigin(
				{ x: titleDefaultDimension.width * -0.5, y: titleDefaultDimension.height * 0.5 },
				{ scale: titleScale }
			),
		};
	});

	const headerHeightStyle = useAnimatedStyle(() => {
		const headerHeight = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt],
			[HEADER_HEIGHT_OPENED, HEADER_HEIGHT_COLLAPSED],
			"clamp"
		);

		return { height: headerHeight };
	});

	const textContainerStyle = useAnimatedStyle(() => {
		const textContainerHeight = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt],
			[TEXT_CONTAINER_HEIGHT_OPENED, TEXT_CONTAINER_HEIGHT_COLLAPSED],
			"clamp"
		);

		const textContainerLeft = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * START_CHANGE_PERCENTAGE, closedAt],
			[8, 12, HEADER_LEFT_WIDTH],
			"clamp"
		);

		const textContainerBottom = interpolate(
			scrollAnimatedValue.value,
			[openedAt, closedAt * START_CHANGE_PERCENTAGE, closedAt],
			[8, 8, 16],
			"clamp"
		);

		return { height: textContainerHeight, left: textContainerLeft, bottom: textContainerBottom };
	});

	const openedComponentBottomStyle = useAnimatedStyle(() => {
		const openedComponentBottom = interpolate(scrollAnimatedValue.value, [openedAt, closedAt], [0, -128], "clamp");
		return { bottom: openedComponentBottom };
	});

	headerLeft = headerLeft || <HeaderBackButton onPress={navigation.goBack} tintColor="white" />;
	headerTitle = <View style={{ flex: 1, height: 48 }} />;

	openedComponent = openedComponent || <View style={{ height: 128, backgroundColor: "red" }} />;
	collapsedComponent = collapsedComponent || <View style={{ height: 64, backgroundColor: "purple" }} />;

	const titleProps = {
		bold: true,
		color: "textLight",
		style: titleStyle,
		numberOfLines: 2,
		ellipsizeMode: "tail",
		useReanimated: true,
		avoidTouchable: true,
	};

	return (
		<View
			style={{
				position: "absolute",
				width: "100%",
				backgroundColor: "black",
			}}
		>
			<SafeAreaView>
				<Animated.View style={[styles.container, headerHeightStyle]}>
					<Animated.View style={[styles.content, openedComponentBottomStyle]}>
						{openedComponent}
					</Animated.View>
					<Animated.View style={[styles.content, collapsedCompOpacityStyle]}>
						{collapsedComponent}
					</Animated.View>

					<Animated.View
						style={[
							{
								position: "absolute",
								right: 0,
								left: 0,
							},
							textContainerStyle,
						]}
						pointerEvents="box-none"
					>
						<AppText
							{...titleProps}
							onLayout={onLayout}
							style={[{ width: openedTitleMaxWidth }, titleStyle]}
						>
							{title}
						</AppText>

						<AppText
							{...titleProps}
							onLayout={onLayoutSmall}
							style={[
								{
									position: "absolute",
									width: windowDim.width - HEADER_LEFT_WIDTH,
								},
								titleSmallOpacityStyle,
							]}
						>
							{title}
						</AppText>

						{showEditTitleButton && (
							<IconButton
								useAnimated
								style={[
									{
										position: "absolute",
										bottom: 0,
										right: 0,
									},
									editButtonOpacityStyle,
								]}
								icon={{ type: MaterialIcons, name: "edit", color: global.colors.textLight }}
								transparent
								onPress={() => editModalRef.current.show()}
							/>
						)}

						<AppText
							color="textLight"
							size="small"
							style={[
								{
									position: "absolute",
									bottom: 0,
								},
								subtitleOpacityStyle,
							]}
							useReanimated
						>
							{subtitle}
						</AppText>
					</Animated.View>

					<View style={styles.titleBar}>
						{headerLeft}
						{headerTitle}
						{headerRight}
					</View>
				</Animated.View>
			</SafeAreaView>
			<EditTextModal ref={editModalRef} onDone={onTitleChanged} initialValue={title} title={_("Edit title")} />
		</View>
	);
}

export default CollapsableHeader;

const styles = StyleSheet.create({
	container: {
		overflow: "hidden",
		backgroundColor: "black",
	},
	titleBar: {
		position: "absolute",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	content: {
		position: "absolute",
		width: "100%",
	},
});
