import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import { getMarkerTitle, findMarkerParent } from "../../tools/storyMarker";

function StoryMarkerSeparator({ leadingItem, trailingItem, dispatch, storyMarkers, party }) {
	if (!trailingItem || leadingItem.party === trailingItem.party) {
		return null;
	}

	let content = "";

	if (party) {
		content = party.name;
	} else {
		content = _("Main story line", "index information when chapter/scene is not in a party");
	}

	return (
		<View style={styles.container}>
			<AppText bold>{content}</AppText>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { leadingItem, trailingItem } = ownProps;
	return {
		storyMarkers: state.storyMarkers[leadingItem.game],
		party: state.parties[leadingItem.game]?.all?.rg_findById(trailingItem?.party),
	};
};

export default connect(mapStateToProps)(StoryMarkerSeparator);

const styles = StyleSheet.create({
	container: { marginHorizontal: 8 },
});
