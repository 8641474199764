import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";

export const gameAlertsSlice = createSlice({
	name: "gameAlerts",
	initialState: {
		all: [],
		nextUrl: null,
		countPerGame: {}, // [gameId]: int
	},
	reducers: {
		receiveGameAlerts: (state, { payload: { results, count, next } }) => {
			state.all = state.all.rg_overlapById(results);
			state.nextUrl = next;
		},
		removeGameAlert: (state, { payload: gameAlert }) => {
			state.all = state.all.filter((ga) => ga.id != gameAlert.id);
		},
		setCountForGame: (state, { payload: { gameId, count } }) => {
			state.countPerGame[gameId] = count;
		},
	},
});

export const { receiveGameAlerts, removeGameAlert, setCountForGame } = gameAlertsSlice.actions;

export function fetchGameAlerts(userId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/game-alerts`).then((response) => {
			dispatch(receiveGameAlerts(response));
		});
	};
}

export function fetchNext(url) {
	if (!url) return (dispatch, getState) => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receiveGameAlerts(response));
		});
	};
}

export function deleteGameAlert(gameAlert) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${gameAlert.user}/game-alerts/${gameAlert.id}`, "DELETE").then((response) => {
			dispatch(removeGameAlert(gameAlert));
		});
	};
}

export function udpateAlertedPlayersCountForGame(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/game-alerts/count-interested-players`).then((count) => {
			dispatch(setCountForGame({ gameId, count }));
		});
	};
}

export function updateAlert(gameAlert) {
	return async (dispatch, getState) => {
		dispatch(receiveGameAlerts({ results: [gameAlert], next: getState().gameAlerts.nextUrl }));
	};
}
