import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Hoverable, useLayout } from "react-native-web-hooks";
// tab: {id, onPress, icon, content, title, style}
// content is if you want to put a component instead of text
import { BuildStyleMethod } from "../../styles/theming";
import { isWeb, ws } from "../../tools/generic";
import CondView from "../meta/CondView";
import AppIcon from "./AppIcon";
import AppText from "./AppText";
import IconButton from "./buttons/IconButton";

const tabSizes = [];

function HorizontalTabs({ tabs, selectedId, onTabSelected, noScroll, style, tabStyle, alignLeft, parentPadding }) {
	const styles = stylesMethod(global.theme);
	const scrollRef = React.useRef();
	const [contentWidth, setcontentWidth] = React.useState();
	const [scrollX, setscrollX] = React.useState(0);

	const selectTab = React.useCallback(
		(id) => {
			onTabSelected(id);
			if (scrollRef.current) {
				const index = tabs.findIndex((t) => t.id === id) || 0;
				scrollRef.current.scrollTo({ x: index * 80 });
			}
		},
		[onTabSelected, tabs]
	);
	const containerUseLayout = useLayout();

	const goNext = React.useCallback(() => {
		const average = tabSizes.reduce((acc, width) => acc + width, 0) / tabSizes.length;
		const destination = scrollX + average;
		if (contentWidth - destination - containerUseLayout.width < average) {
			scrollRef.current.scrollToEnd();
		} else scrollRef.current.scrollTo({ x: destination });
	}, [scrollX, contentWidth, containerUseLayout.width]);

	const goPrevious = React.useCallback(() => {
		const average = tabSizes.reduce((acc, width) => acc + width, 0) / tabSizes.length;
		const destination = scrollX - average;
		if (destination < average) scrollRef.current.scrollTo({ x: 0 });
		else scrollRef.current.scrollTo({ x: destination });
	}, []);

	const renderTab = React.useCallback(
		(tab, index) => {
			if (!tab) return null;
			const selected = selectedId === tab.id;
			return (
				<Hoverable key={index}>
					{(isHovered) => (
						<TouchableOpacity
							style={[
								styles.tab,
								noScroll && styles.noScrollTab,
								alignLeft && styles.tabLeft,
								selected && styles.tabSelected,
								tabStyle
							]}
							onPress={() => (tab.onPress ? tab.onPress() : selectTab(tab.id))}
							onLayout={({
								nativeEvent: {
									layout: { width },
								},
							}) => (tabSizes[index] = width)}
							disabled={tab.disabled}
						>
							{tab.content}
							<AppText hideEmpty color="textDefault" bold={selected}>
								{tab.title}
							</AppText>
							{tab.icon && <AppIcon size={24} color={global.colors.textLight} {...tab.icon} />}
							<CondView
								show={selected || isHovered}
								style={[
									styles.underLine,
									selected && styles.underLineNoScroll,
									isHovered && styles.underlineHovered,
								]}
							/>
						</TouchableOpacity>
					)}
				</Hoverable>
			);
		},
		[selectedId]
	);

	if (!tabs || !tabs.length) return null;

	if (noScroll) {
		return (
			<View style={[styles.container, styles.noScrollContainer, style]}>
				{tabs.map((tab, index) => (
					<View key={index} style={[{ height: "100%", ...ws({}, { flex: 1 }) }, alignLeft && styles.tabLeft]}>
						{renderTab(tab, index)}
					</View>
				))}
			</View>
		);
	}

	const scrollView = (
		<ScrollView
			ref={scrollRef}
			horizontal
			style={[styles.scrollView, style]}
			contentContainerStyle={[styles.container]}
			snapToAlignment="start"
			disableIntervalMomentum
			showsHorizontalScrollIndicator={false}
			onContentSizeChange={(contentWidth) => setcontentWidth(contentWidth)}
			onScroll={({
				nativeEvent: {
					contentOffset: { x },
				},
			}) => setscrollX(x)}
			scrollEventThrottle={150}
		>
			{tabs.map(renderTab)}
		</ScrollView>
	);

	if (!isWeb()) return scrollView;

	const widthDelta = contentWidth - containerUseLayout.width;
	const hasOverflow = widthDelta > 0;
	const arrowMargin = parentPadding > 16 ? -24 : 8;

	const showArrowLeft = hasOverflow && scrollX > 0;
	const showArrowRight = hasOverflow && scrollX < widthDelta - 16; //16 accounts for the arrows margin I think?

	return (
		<View style={{ flexDirection: "row", backgroundColor: global.colors.buttonBackgroundColor }}>
			<CondView
				show={hasOverflow}
				style={[styles.arrowContainer]}
				pointerEvents={showArrowLeft ? "auto" : "none"}
			>
				<IconButton
					icon={{ type: MaterialIcons, name: "chevron-left", color: global.colors.textLight }}
					onPress={goPrevious}
					style={[
						{ opacity: showArrowLeft ? 1 : 0, elevation: 1 },
						styles.arrow,
						{ marginLeft: arrowMargin },
					]}
				/>
			</CondView>
			<View style={[{ flex: 1 }, ws({ alignItems: "center"  })]} onLayout={containerUseLayout.onLayout}>
				{scrollView}
			</View>
			<CondView
				show={hasOverflow}
				style={[styles.arrowContainer]}
				pointerEvents={showArrowRight ? "auto" : "none"}
			>
				<IconButton
					icon={{ type: MaterialIcons, name: "chevron-right", color: global.colors.textLight }}
					onPress={goNext}
					style={[{ opacity: showArrowRight ? 1 : 0 }, styles.arrow, { marginRight: arrowMargin }]}
				/>
			</CondView>
		</View>
	);
}

export default HorizontalTabs;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		scrollView: { flexGrow: 0, flexShrink: 0 },
		container: {
			backgroundColor: colors.buttonBackgroundColor,
			height: 48,
			minWidth: "100%",
			justifyContent: "center",
		},
		containerLeft: { justifyContent: null },
		noScrollContainer: {
			justifyContent: null,
			flexDirection: "row",
			width: null,
			minWidth: null,
		},
		tab: {
			flex: 1,
			justifyContent: "center",
			alignItems: "center",
			paddingHorizontal: 8,
			minWidth: 80,
			...ws({ minWidth: 140 }),
		},
		tabLeft: {
			alignItems: null,
		},
		noScrollTab: {
			height: "100%",
			flex: null,
		},
		tabSelected: {},
		underLine: {
			position: "absolute",
			bottom: 1,
			borderTopColor: colors.textDefault,
			borderTopWidth: 4,
			width: "100%",
		},
		underlineHovered: {
			borderTopColor: colors.hint,
		},
		underLineNoScroll: ws({}, { width: "70%" }),
		arrowContainer: {
			justifyContent: "center",
			alignItems: "flex-start",
			zIndex: 1,
		},
	})
);
