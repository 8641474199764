import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from "moment";
import * as React from "react";
import AppScreenView from "../AppScreenView";
import ListButton from "../generic/buttons/ListButton";

function LocaleSettings({ route, navigation }) {
	const languages = [
		{ name: "English", code: "en" },
		// { name: "Bulgarian", code: "bg" },
		// { name: "Hrvatski", code: "hr" },
		// { name: "Nederlands", code: "nl" },
		{ name: "Français", code: "fr" },
		// { name: "Deutsch", code: "de" },
		// { name: "Greek", code: "el" },
		{ name: "Italiano", code: "it" },
		// { name: "Polski", code: "pl" },
		{ name: "Português", code: "pt-br" },
		// { name: "Español", code: "es" },
		// { name: "Română", code: "ro" },
		// { name: "Русский", code: "ru" },
	].sort((a, b) => a.name.localeCompare(b.name));

	const changeLanguage = React.useCallback((localeCode) => {
		global.LOCALE_CODE = localeCode;
		moment.locale(localeCode);
		navigation.navigate("AppSettingsScreen");
		AsyncStorage.setItem("localeCode", localeCode);
	}, []);

	return (
		<AppScreenView borderless>
			{languages.map((item) => (
				<ListButton key={item.code} title={item.name} onPress={() => changeLanguage(item.code)} />
			))}
		</AppScreenView>
	);
}

export default LocaleSettings;


