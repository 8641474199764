import * as React from "react";
import { View, StyleSheet } from "react-native";
import { getLanguageProficiency, proficiencyLevelToText } from "../../tools/languages";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import RGIcon from "../RGIcon";
import { Divider } from "react-native-paper";
import { sizes } from "../../styles/font";
import { ws } from "../../tools/generic";

function CharacterLanguageItem({ character, language, setProficienyLevel }) {
	const level = getLanguageProficiency(character, language);

	const changeProficiencyLevel = React.useCallback(
		(newLevel) => {
			setProficienyLevel(level, newLevel);
		},
		[level, setProficienyLevel]
	);

	return (
		<View style={styles.container}>
			<View style={{ flexDirection: "row", alignItems: "center", marginBottom: 8 }}>
				<View style={{ justifyContent: "space-between" }}>
					<AppText>
						<RGIcon name="language" size={sizes.average} style={{ marginRight: 16 }} /> {language.name} >{" "}
						<AppText color="primary">{proficiencyLevelToText(level)}</AppText>
					</AppText>
				</View>
			</View>
			{/* prettier-ignore */}
			<ButtonsRow style={ws({maxWidth: 300})}>                
                <IconButton icon={{}} style={styles.button} toggled={level === 0} onPress={() => setProficienyLevel && changeProficiencyLevel(0)} />
                <IconButton icon={{}} style={styles.button} toggled={level === 1} onPress={() => setProficienyLevel && changeProficiencyLevel(1)} />
                <IconButton icon={{}} style={styles.button} toggled={level === 2} onPress={() => setProficienyLevel && changeProficiencyLevel(2)} />
                <IconButton icon={{}} style={styles.button} toggled={level === 3} onPress={() => setProficienyLevel && changeProficiencyLevel(3)} />
            </ButtonsRow>
		</View>
	);
}

export default CharacterLanguageItem;

const buttonSize = 32;
const styles = StyleSheet.create({
	container: {
        paddingHorizontal: 16
    },
	button: {
		height: buttonSize,
		width: buttonSize,
	},
});
