import * as React from "react";
import { View, StyleSheet, TouchableOpacity, Image } from "react-native";
import { _ } from "../../../i18n/i18n";
import AppText from "../../generic/AppText";
import AppScreenView from "../../AppScreenView";
import InputField from "../../generic/InputField";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import { simpleReducer } from "../../../tools/react";
import { connect } from "react-redux";
import { ws } from "../../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { useLayout } from "react-native-web-hooks";

function WizardCreateGameDetails({ route, navigation, dispatch, backgroundSelected }) {
	const gameReceived = route.params?.game || { name: "", rules: "", description: "", max_players: 4 };

	const [game, updateGame] = React.useReducer(simpleReducer, gameReceived);

	const pathToBg = `${global.root_url}/static/images/stagebg/bg_${
		backgroundSelected?.name || game.cover?.name || "neutral_1"
	}.png`;

	React.useEffect(() => {
		if (route.params?.game) {
			updateGame(route.params?.game);
		}
	}, [route.params?.game]);

	React.useEffect(() => {
		if (backgroundSelected) updateGame({ cover: backgroundSelected });
	}, [backgroundSelected]);

	React.useEffect(() => {
		if (game.max_players < 0 || game.max_players > 30)
			updateGame({ max_players: Math.max(0, Math.min(30, game.max_players)) });
	}, [game.max_players]);

	const {onLayout} = useLayout();

	return (
		<>
			<AppScreenView scroll onLayout={onLayout}>
				<View style={ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })}>
					<AppText color="hint" centered style={{ marginTop: 16, marginBottom: 16 }}>
						{_("All the information you enter can be changed after the game is created.")}
					</AppText>

					<InputField
						value={game.name}
						onChangeText={(name) => updateGame({ name })}
						label={_("Game name", "game details")}
					/>

					<AppText style={styles.sectionTitle} bold>
						{_("Game cover")}
					</AppText>
					<TouchableOpacity
						style={{ height: 160, backgroundColor: "black", marginHorizontal: ws(0, -16) }}
						onPress={() => navigation.navigate("SelectCover", { previousScreenName: route.name })}
					>
						<Image source={{ uri: pathToBg }} style={styles.background} resizeMode="cover" />
					</TouchableOpacity>
					<AppText color="hint" textOnlyStyle={{ textAlign: "right" }} style={{ marginBottom: 16 }}>
						{ws(
							_("Click on the picture to the change the cover"),
							_("Tap the picture to the change the cover")
						)}
					</AppText>

					<InputField
						style={styles.input}
						value={game.description}
						onChangeText={(description) => updateGame({ description })}
						label={_("Description", "game details")}
						placeholder={_(_("Describe what the game is about", "game details, description"))}
						multiline
						autoGrow
					/>
					<InputField
						style={styles.input}
						value={String(game.max_players)}
						onChangeText={(max_players) => updateGame({ max_players })}
						label={_("Max players", "game details")}
						keyboardType="numeric"
					/>
					<InputField
						style={styles.input}
						value={game.rules}
						onChangeText={(rules) => updateGame({ rules })}
						label={_("Rules (optional)", "game details")}
						multiline
						placeholder={_(_("If you want to specify the game rules", "game details, rules"))}
						autoGrow
					/>
				</View>
			</AppScreenView>
			<ButtonFooter
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={[
					{
						title: _("Next"),
						onPress: () => navigation.navigate("WizardCreateGameSheet", { game }),
						disabled: !game.name,
					},
				]}
				noBack
				narrow={false}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		backgroundSelected: state.stageBackgrounds.rg_findById(ownProps.route.params?.backgroundSelected),
	};
};

export default connect(mapStateToProps)(WizardCreateGameDetails);

const styles = StyleSheet.create({
	background: {
		position: "absolute",
		height: 160,
		width: "100%",
	},
	sectionTitle: {
		marginVertical: 8,
	},
	input: { marginBottom: 8 },
});
