import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { _ } from '../../i18n/i18n';
import { getInterlocutor } from "../../tools/directMessages";
import { isWeb } from "../../tools/generic";
import MessageGroupLoader from "./MessageGroupLoader";
import MyMessagesScreen from "./MyMessagesScreen";

const Stack = createStackNavigator();

function MessagesStack() {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="MyMessagesScreen"
				options={{ title: _("My messages | Role Gate") }}
				component={MyMessagesScreen}
			/>
			<Stack.Screen
				name="MessageGroupScreen"
				component={MessageGroupLoader}
				options={({ route, navigation }) => ({
					headerTitle: getInterlocutor(route.params?.groupId)?.username || _("[Deleted]", "deleted user"),
					title: _("Messages | Role Gate"),
				})}
			/>
		</Stack.Navigator>
	);
}

export default MessagesStack;
