import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppScreenView from "../AppScreenView";
import { connect } from "react-redux";
import InputField from "../generic/InputField";
import { _ } from "../../i18n/i18n";
import { simpleReducer, usePrevious } from "../../tools/react";
import ListSwitch from "../generic/ListSwitch";
import AppText from "../generic/AppText";
import { apiFetch } from "../../network/apiFetch";
import AppButton from "../generic/buttons/AppButton";
import { errorCodeToString } from "../authentication/LoginScreen";
import { ws } from "../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { colors } from "../../styles/colors";
import { useLayout } from "react-native-web-hooks";

import { BuildStyleMethod } from "../../styles/theming";

function EmailsSettingsScreen({ dispatch, user }) {
	const styles = stylesMethod(global.theme);
	const [email, setemail] = React.useState(user.email);
	const [error, seterror] = React.useState(null);

	const [profile, updateProfile] = React.useReducer(simpleReducer, user.profile);
	const prevProfile = usePrevious(profile);
	React.useEffect(() => {
		if (!prevProfile) return () => {};

		apiFetch(`profiles/${profile.id}`, "PATCH", {
			game_update_emails: profile.game_update_emails,
			game_with_tags_emails: profile.game_with_tags_emails,
			role_gate_updates_emails: profile.role_gate_updates_emails,
		});
	}, [profile]);

	const changeEmail = React.useCallback(
		(newEmail) => {
			seterror(null);
			if (newEmail === email) {
				return;
			}

			setemail(newEmail);

			apiFetch(`profiles/${profile.id}`, "PATCH", {
				email: newEmail,
			}).then((response) => {
				if (response.error_mail) {
					seterror(errorCodeToString(response.error_mail));
				} else {
					seterror(null);
				}
			});
		},
		[email]
	);

	const { onLayout } = useLayout();

	return (
		<AppScreenView style={[styles.container]} borderless borderTop onLayout={onLayout}>
			<View style={[globalStylesMethod(global.theme).floatingSection]}>
				<View style={ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() })}>
					<AppText
						bold
						style={{ marginTop: 16, marginBottom: 8 }}
						inputFieldProps={{ keyboardType: "email-address" }}
					>
						{_("Email address")}
					</AppText>
					<AppText color="danger">{error}</AppText>
					<AppText editable onTextChanged={changeEmail}>
						{email}
					</AppText>
				</View>
			</View>
			<View style={[globalStylesMethod(global.theme).floatingSection]}>
				<View style={ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() })}>
					<AppText bold style={{ marginTop: 16, marginBottom: 8 }}>
						{_("Email notifications", "email settings")}
					</AppText>
					<ListSwitch
						title={_("Role Gate updates", "email settings")}
						isEnabled={profile.role_gate_updates_emails}
						setIsEnabled={(role_gate_updates_emails) => updateProfile({ role_gate_updates_emails })}
					/>
					<ListSwitch
						title={_("My games progress", "email settings")}
						isEnabled={profile.game_update_emails}
						setIsEnabled={(game_update_emails) => updateProfile({ game_update_emails })}
					/>
					<ListSwitch
						title={_("New games suggestions", "email settings")}
						isEnabled={profile.game_with_tags_emails}
						setIsEnabled={(game_with_tags_emails) => updateProfile({ game_with_tags_emails })}
					/>
				</View>
			</View>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(EmailsSettingsScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.backgroundSecondary,
	},
}));
