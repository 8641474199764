const defaultColors = {
	attention: "#4F89BC",
	attentionOpacity: "#4F89BC50",
	attentionImportant: "#D93535",
	attentionImportantOpacity: "#D9353550",
	attentionSecondary: "#7A545B",
	attentionSecondaryOpacity: "#7A545B50",
	borderDefault: "#000000",
	cancel: "#263238",
	danger: "#D93535",
	dangerLight: "#D93535",
	descriptionBackground: "#D2D3D4",
	storyTellingBackground: "#D2D3D4",
	hint: "#A4A4A4",
	hintLight: "#A4A4A4",
	hintDark: "#706A6A",
	lightBorder: "#E2DFDF",
	boldBorder: "#b8b8b8",
	placeholder: "#898989",
	primary: "#607D8B",
	url: "#607D8B",
	unactive: "#BFBFBF",
	secondary: "#817A7A",
	textReversed: "#FFFFFF",
	textDefault: "#000000",
	cardBackground: "#f2f2f2",
	cardBackgroundLight: "#f2f2f2",
	inputFieldBackground: "#e7e7e7",
	chatInputFieldBackground: "#504F4F",
	cardOverlayBackground: "rgba(0, 0, 0, 0.4)",
	overlayBackground: "rgba(0, 0, 0, 0.8)",
	buttonBackgroundColor: "white",
	selectedItemBackground: "#ebebeb",
	backgroundSecondary: "#E2DFDF",
	paceFast: "#328cc1",
	paceAverage: "#cd8f5c",
	paceSlow: "#d9b310",
	success: "#858712",
	failure: "#D93535",
	failureOpacity: "#D9353550",
	translucidBackground: "rgba(0,0,0,0.5)",
	textDark: "#000000",
	textLight: "#FFFFFF",
	popupBackground: "white",
	talkBackground: "#E8EAEC",
	messageBackground: "#E8EAEC",
	messageOwnBackground: "#E8EAEC",
};

const darkTheme = {
	textReversed: "#000000",
	textDefault: "#FFFFFF",
	buttonBackgroundColor: "#40464B",
	buttonBackgroundColorLight: "rgb(72, 78, 83)",
	secondary: "#7D8098",
	cardBackground: "rgb(24, 27, 39)",
	cardBackgroundLight: "#23263B",
	translucidBackground: "rgba(0,0,0,0.2)",
	translucidLightBackground: "rgba(255,255,255,0.1)",
	lightBorder: "#252840",
	nearTransparentBorder: "#252840",
	descriptionBackground: "#40464B",
	storyTellingBackground: "rgb(34, 38, 53)",
	talkBackground: "#E8EAEC",
	messageBackground: "#27292b",
	messageOwnBackground: "#5F738F",
	primary: "#5F738F",
	primaryLight: "rgb(113, 130, 154)",
	inputFieldBackground: "#282A3A",
	boldBorder: "#b8b8b8",
	placeholder: "#898989",
	attentionSecondary: "#C1596C",
	backgroundSecondary: "black",
	attention: "#5BB2FF",
	popupBackground: "#1F223B",
	success: "#C5B41C",
	dangerLight: "#D99235",
	selectedItemBackground: "#464a6d",
	hoverItemBackground: "#464a6d",
	hoverItemLightBackground: "rgba(92, 95, 121, 0.22)",
	hoverSelectedItemBackground: "#7D8098",
	// Left to change:
	attentionOpacity: "#4F89BC50",
	attentionImportant: "#D93535",
	attentionImportantOpacity: "#D9353550",
	borderDefault: "#000000",
	cancel: "#263238",
	danger: "#D93535",
	hint: "#A4A4A4",
	hintLight: "#C4C0C0",
	hintDark: "#6b6767",
	url: "#607D8B",
	unactive: "#BFBFBF",	
	chatInputFieldBackground: "#504F4F",
	cardOverlayBackground: "rgba(0, 0, 0, 0.4)",
	overlayBackground: "rgba(0, 0, 0, 0.8)",
	paceFast: "#328cc1",
	paceAverage: "#cd8f5c",
	paceSlow: "#d9b310",
	failure: "#D93535",
	failureOpacity: "#D9353550",
	textDark: "#000000",
	textLight: "#FFFFFF",
	textSecondary: "rgb(167, 125, 73)"
};

const darkestTheme = {
	...darkTheme,
	buttonBackgroundColor: "#373737",
    buttonBackgroundColorLight: "#40464B",
	secondary: "#aaa",
	cardBackground: "#181818",
    cardBackgroundLight: "#202020",
	lightBorder: "#383838",
	nearTransparentBorder: "#222222",
	descriptionBackground: "#40464B",
	storyTellingBackground: "rgb(38,38,38)",
	messageOwnBackground: "rgb(85, 98, 117)",
	primary: "rgb(94, 102, 112)",
	primaryLight: "rgb(98, 110, 120)",
	inputFieldBackground: "rgb(18, 18, 18)",
	attentionSecondary: "rgb(166, 131, 79)",
	popupBackground: "#202020",
	dangerLight: "#D93535",
	selectedItemBackground: "#383838",
	hoverItemBackground: "#383838",
	hoverItemLightBackground: "rgba(95, 95, 96, 0.22)",
	hoverSelectedItemBackground: "#424242",
	textSecondary: "rgb(167, 125, 73)"
}

const userColors = [
	"#FFFFFF",
	"#D7CEC7",
	"#a5a5b0",
	"#a4c9df",
	"#328CC1",
	"#767ccb",
	"cadetblue",
	"#78C478",
	"#cdc09c",
	"#D9B310",
	"#c38cae",
	"indianred",
	"#CD8F5C",
];

const toneColors = {
	admiring: "#767CCB",
	apologetic: "#5F9EA0",
	annoyed: "#905757",
	angry: "indianred",
	anxious: "#5F9EA0",
	affectionate: "#f796c6",
	alarmed: "#D9B310",
	amused: "lightskyblue",
	baffled: "darkseagreen",
	brooding: "#D7CEC7",
	cheerful: "#328CC1",
	condescending: "#CD8F5C",
	confident: "#8a80cb",
	confused: "#A5A5B0",
	curious: "#5F9EA0",
	desperate: "#C4751E",
	detached: "#7e7e89",
	disappointed: "cadetblue",
	disgusted: "darkseagreen",
	doubtful: "#D7CEC7",
	embarrassed: "cadetblue",
	excited: "#D9B310",
	fascinated: "#d9a02f",
	friendly: "#328CC1",
	haughty: "#4ec19a",
	impressed: "#767CCB",
	kind: "#328CC1",
	mocking: "#CD8F5C",
	nervous: "#FFB53F",
	panicked: "#cd691b",
	panting: "#a97268",
	passionate: "#dcba08",
	// pensive: "#EDECEB",
	pensive: "#A5A5B0",
	playful: "lightskyblue",
	puzzled: "#A5A5B0",
	proud: "#8a80cb",
	pressing: "#cb5837",
	remorseful: "skyblue",
	sarcastic: "#CD8F5C",
	scared: "#8dccce",
	shy: "#5F9EA0",
	seductive: "#767CCB",
	serious: "#7e7e89",
	suffering: "#cd691b",
	surprised: "#e2ca49",
	threatening: "indianred",
	relieved: "darkseagreen",
	sad: "cadetblue",
	sleepy: "darkseagreen",
	suspicious: "#a5a5b0",
	tired: "darkseagreen",
	tense: "#7e7e89",
	worried: "cadetblue",
};

export const accountColors = {
	basic: "#D7CEC7",
	wooden: "#D7CEC7",
	copper: "#f19d40",
	// silver: "#A4C9DF",
	silver: "#a4afdf",
	gold: "#D9B310",
	// platinum: "#dcf6f7",
	platinum: "#9fcacc",
	legendary: "#a962f5",
	legendaryDark: "rgba(169, 98, 245, 0.6)",
};


let theme = darkTheme;
if(global.theme === "darkest"){
	theme = darkestTheme;
}

export const colors = {
	...defaultColors,
	...theme,
	userColors,
	toneColors,
	accountColors,
	darkestTheme: darkestTheme,
	darkTheme: darkTheme
};
