import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { globalStyles } from "../../styles/global";
import { BuildStyleMethod } from "../../styles/theming";
import { filterDefaultLabels } from "../../tools/sheets";
import AppPicker from "../generic/AppPicker";
import AppText from "../generic/AppText";


function CharacterSheetValueDropDown({ sheetId, defaultValue, onSheeValuePicked, dispatch, sheetValuePairs }) {
	const styles = stylesMethod(global.theme);
	const displayedValuePairs = React.useMemo(() => {
		let array = sheetValuePairs || [];
		array = array.filter(filterDefaultLabels);
		array = array.sort((a, b) => a.label.localeCompare(b.label));
		return array;
	}, [sheetValuePairs]);

	const [selectedValue, setselectedValue] = React.useState(defaultValue || displayedValuePairs[0]);

	React.useEffect(() => {
		if (!sheetValuePairs?.length) dispatch(fetchSheetValuePairsIfNeeded(sheetId));
	}, [sheetValuePairs?.length, sheetId]);

	React.useEffect(() => {
		onSheeValuePicked(selectedValue);
	}, [selectedValue]);

	const androidMode = displayedValuePairs.length < 10 ? "dropdown" : "dialog";

	if (!displayedValuePairs.length) {
		return (
			<View style={[{ flex: 1 }, globalStyles.jac]}>
				<AppText centered color="hint">
					{_(
						"No numbers in character sheet",
						"shown when there is no number on the character sheet to be used in a Roll+"
					)}
				</AppText>
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<AppPicker
				containerStyle={{ maxWidth: null }}
				selectedValue={selectedValue}
				onValueChange={(itemValue, itemIndex) => {
					setselectedValue(itemValue);
				}}
				mode={androidMode}
				pickerStyle={{ flex: 1, color: "white" }}
				itemStyle={{ color: "white" }}
				items={displayedValuePairs.map((svp, index) => ({
					label: svp.label + ` (${svp.value || 0})`,
					value: svp,
				}))}
				icon={{}}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		sheetValuePairs: state.sheetValuePairs[ownProps.sheetId],
	};
};

export default connect(mapStateToProps)(CharacterSheetValueDropDown);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: colors.secondary,
	},
}));
