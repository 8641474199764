import * as React from "react";
// @react-native-community/clipboard getString doesn't work.
// Returns Cannot read property "getString" of undefined
//import Clipboard from "@react-native-community/clipboard";
//import { Clipboard } from "react-native"
import * as Clipboard from 'expo-clipboard';
import AppScreenView from "../../AppScreenView";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import InputField from "../../generic/InputField";
import { simpleReducer } from "../../../tools/react";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import AppButton from "../../generic/buttons/AppButton";
import { Divider } from "react-native-paper";
import { isWeb, ws } from "../../../tools/generic";
import { fullScreenContentWidth } from "../../../styles/dynamicStyles";

function WizardAvatarRequestDescription({ route, navigation }) {
	const [requestInfo, updateRequestInfo] = React.useReducer(
		simpleReducer,
		route.params?.requestInfo || { description: "", references: "" }
	);

	const pasteFromClipboard = React.useCallback(async () => {
		const content = await Clipboard.getStringAsync();
		const newReferences = requestInfo.references + "\n" + content;
		updateRequestInfo({ references: newReferences.trim() });
	}, [requestInfo.references, updateRequestInfo]);

	return (
		<>
			<AppScreenView scroll borderTop style={ws({ alignItems: "center" })}>
				<AppText bold>{_("Describe your avatar")}</AppText>

				<AppText style={{ marginTop: 8 }} color="hint">
					{_("Being clear will help getting you exactly the avatar you want.")}
				</AppText>
				<AppText style={{ marginBottom: 8 }} color="hint">
					{_("Please consider that avatars have a limited size and can only fit so many details.")}
				</AppText>

				<InputField
					value={requestInfo.description}
					onChangeText={(description) => updateRequestInfo({ description })}
					multiline
					placeholder={_("Describe the defining features of the avatar here")}
					style={ws({ maxWidth: fullScreenContentWidth()})}
					inputStyle={ws({ height: 150 })}
				/>
				<Divider />

				<AppText style={{ marginTop: 16, marginBottom: 8 }} color="hint">
					{_("If you have any image reference links, paste them here")}
				</AppText>

				<InputField
					value={requestInfo.references}
					onChangeText={(references) => updateRequestInfo({ references })}
					multiline
					placeholder={_("Paste reference links here")}
					style={ws({ maxWidth: fullScreenContentWidth() })}
					inputStyle={ws({ height: 100 })}
				/>

				{!isWeb() && <AppButton title={_("Paste from clipboard")} onPress={pasteFromClipboard} />}
			</AppScreenView>
			<ButtonFooter
				buttonProps={[
					{
						title: _("Back"),
						onPress: () => navigation.navigate("WizardAvatarRequestDeadline", { requestInfo }),
					},
					{
						title: _("Review", "review avatar request"),
						onPress: () => navigation.navigate("WizardAvatarRequestSummary", { requestInfo }),
						disabled: !requestInfo.description.trim() && !requestInfo.references.trim(),
					},
				]}
			/>
		</>
	);
}

export default WizardAvatarRequestDescription;
