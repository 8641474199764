import moment from "moment";
import * as React from "react";
import { StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import { SELECT_CHARACTERS_REQUEST_MACRO } from "../characters/SelectCharactersModal";
import AppText from "../generic/AppText";
import InputField from "../generic/InputField";
import ModalScreen from "../generic/modal/ModalScreen";

function SetTimerModal({ route, navigation }) {
	const { charactersSelected, charactersSelectedCodeSent } = route.params;

	const [timer, setTimer] = React.useState("1");

	let minutes = timer * 60;

	const hours = Math.floor(timer);
	minutes = Math.floor(minutes % 60);
	let humanize;
	if (hours) {
		humanize = moment.duration({ hours }).humanize();
		if (minutes) {
			humanize += ", " + moment.duration({ minutes }).humanize();
		}
	} else {
		humanize = moment.duration({ minutes }).humanize();
	}

	const options = [
		{
			title: _("Back"),
			onPress: () => navigation.goBack(),
		},
		{
			title: _("Request", "request roll from players"),
			disabled: !charactersSelected.length,
			onPress: () =>
				navigation.navigate({
					name:
						charactersSelectedCodeSent === SELECT_CHARACTERS_REQUEST_MACRO
							? "RollMacroModal"
							: "AdvancedRollModal",
					params: {
						charactersSelected,
						timer: Number(timer),
					},
					merge: true
				}),
		},
	];

	return (
		<ModalScreen title={_("Set timer", "set timer for roll request")} options={options} useButtonFooter>
			{/* <AppText color="hint">{_("Time limit in hours (0.5 = 30 minutes)")}</AppText> */}
			<InputField
				label={_("Time limit in hours (0.5 = 30 minutes)")}
				keyboardType="numeric"
				value={timer}
				onChangeText={(text) => setTimer(text)}
			/>

			<AppText style={{ marginVertical: 8 }}>
				<AppText>{_("Timer set to: ", "Roll request timer")}</AppText>
				<AppText bold>{humanize}</AppText>
			</AppText>
		</ModalScreen>
	);
}

export default SetTimerModal;
