import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import {
	fetchFriendIds,
	fetchPending,
	fetchSentRequests,
	addSentRequest,
	cancelSentRequest,
} from "../../store/slices/friendsSlice";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/global";
import AppButton from "../generic/buttons/AppButton";
import { apiFetch } from "../../network/apiFetch";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import BoxModal from "../generic/modal/BoxModal";

import { BuildStyleMethod } from "../../styles/theming";

function AddFriendButton({ style, otherUser, dispatch, userId, friendIds, pendingRequests, sentRequests }) {
	const styles = stylesMethod(global.theme);
	if (!userId) return null;
	const isFriend = friendIds.some((id) => id === otherUser.id);

	const cancelConfirmedRef = React.useRef();
	const confirmUnfriendModal = React.useRef();

	React.useEffect(() => {
		if (otherUser.id !== userId) {
			dispatch(fetchFriendIds(userId));
			dispatch(fetchPending(userId));
			dispatch(fetchSentRequests(userId));
		}
	}, [userId, otherUser.id]);

	const send = React.useCallback(() => {
		apiFetch(`users/${userId}/friend-requests`, "POST", {
			sender: userId,
			receiver: otherUser.id,
		}).then((newRequest) => dispatch(addSentRequest(newRequest)));
	}, [userId, otherUser.id]);

	const acceptFriendRequest = React.useCallback(() => {
		const request = pendingRequests.find((r) => r.sender.id === otherUser.id);
		apiFetch(`users/${userId}/friend-requests/${request.id}/accept`, "POST").then(() => {
			dispatch(fetchFriendIds(userId));
			dispatch(fetchPending(userId));
		});
	}, [userId, otherUser.id, pendingRequests]);

	const unfriend = React.useCallback(() => {
		apiFetch(`profiles/${userId}/unfriend`, "POST", {
			profile: otherUser.id,
		}).then(() => dispatch(fetchFriendIds(userId)));
	}, [userId, otherUser.id]);

	const cancelRequest = React.useCallback(() => {
		dispatch(
			cancelSentRequest(
				userId,
				sentRequests.find((r) => r.receiver === otherUser.id)
			)
		);
		cancelConfirmedRef.current.show();
	}, [userId, sentRequests]);

	let component = null;

	const requestSent = sentRequests.some((r) => r.receiver === otherUser.id);
	const pending = pendingRequests.some((r) => r.sender.id === otherUser.id);

	if (isFriend) {
		component = (
			<View style={globalStyles.jac}>
				<TouchableOpacity style={[styles.container, style]} onPress={() => confirmUnfriendModal.current.show()}>
					<AppText color="primary">
						<MaterialCommunityIcons name="account-check" size={16} color={global.colors.textDefault} />{" "}
						{_("Friend", "indicates other user is on friend list")}
					</AppText>
				</TouchableOpacity>
			</View>
		);
	} else if (requestSent) {
		const requestSent = sentRequests.some((r) => r.receiver === otherUser.id);

		component = (
			<View style={globalStyles.jac}>
				<TouchableOpacity style={[styles.container, style]} onPress={cancelRequest}>
					<AppText color="secondary">
						<MaterialCommunityIcons name="account-clock" size={16} color={global.colors.textDefault} />{" "}
						{_("Request sent", "indicates other user is on friend list")}
					</AppText>
				</TouchableOpacity>
			</View>
		);
	} else if (pending) {
		component = (
			<AppButton title={_("Accept request", "Mmst keep text short")} onPress={acceptFriendRequest} size="small" />
		);
	} else {
		component = <AppButton title={_("Add friend", "Must keep text short")} onPress={send} size="small" />;
	}

	return (
		<>
			{component}
			<BoxModal
				ref={confirmUnfriendModal}
				title={_("Un-friend")}
				message={
					<AppText>
						{_(
							"Do you want to un-friend %(username)s? You will lose all private messages exchanged this person.",
							"",
							{
								username: (
									<AppText key="u" bold>
										{otherUser.username}
									</AppText>
								),
							}
						)}
					</AppText>
				}
				onConfirm={unfriend}
			/>
			<TinyFeedbackModal ref={cancelConfirmedRef} message={_("Request canceled", "cancelling friend request")} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		friendIds: state.friends.friendIds,
		pendingRequests: state.friends.pendingRequests,
		sentRequests: state.friends.sentRequests,
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(AddFriendButton);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		borderWidth: 1,
		borderColor: colors.lightBorder,
		borderRadius: 6,
		padding: 4,
		paddingHorizontal: 16,
	},
}));
