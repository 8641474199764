import * as React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import AppText from "../generic/AppText";
import { Avatar } from "react-native-paper";
import { getCoverUrl } from "../../tools/games";
import { isIos, isWeb } from "../../tools/generic";
import { _ } from "../../i18n/i18n";
import { navPush } from "../../tools/webnavigation";
import { connect } from "react-redux";

const styles = StyleSheet.create({
	header: isIos()
		? { justifyContent: "center", flexDirection: "row" }
		: { alignItems: "center", flexDirection: "row" },
	image: {
		marginRight: 10,
	},
});

function GameHeader({ game, title, subtitle, navigation, dispatch, user }) {

	title = title || game?.name || _("Loading...", "loading message");

	const goToSettings = React.useCallback(() => {
		navPush(navigation)("GameSettingScreen", { gameslug: game.slug });
	}, [game]);

	const iOs = isIos();
	return (
		<View style={[styles.header]}>
			{(iOs || !user) && (
				<Avatar.Image source={{ uri: getCoverUrl(game) }} size={iOs ? 32 : 48} style={[styles.image]} />
			)}
			<TouchableOpacity style={{ justifyContent: "center" }} onPress={goToSettings}>
				<AppText numberOfLines={1} ellipsizeMode="tail">
					{title}
				</AppText>
				<AppText size="small" hide={!subtitle} color="secondary">
					{subtitle}
				</AppText>
			</TouchableOpacity>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(GameHeader);
