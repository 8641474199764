import * as React from "react";
import { ActivityIndicator, FlatList, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { fetchGameTags } from "../../../store/slices/gameTagsSlice";
import { colors } from "../../../styles/colors";
import { idKeyExtractor, isWeb, ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import WizardScreen from "./WizardScreen";

function WizardTagSelectionScreen({ route, navigation, dispatch, tags: tagProps }) {
	let tags = React.useMemo(() => tagProps.filter((t) => !t.irrelevant), [tagProps]);

	const preferredTags = React.useMemo(() => {
		const tagNames = route.params?.preferredTags || [];
		return tags.filter((t) => tagNames.includes(t.name));
	}, [route.params?.preferredTags, tags]);

	// selectedTags is the data received from SelectGameTagsScreen
	// transfer it to avoidedTags
	React.useEffect(() => {
		const selectedTags = route.params?.selectedTags;
		if (selectedTags) {
			const call = () => navigation.setParams({ selectedTags: null, preferredTags: selectedTags });
			// On web, calling setParams immediately on focus won't update the url. So add a small timeout.
			isWeb() ? setTimeout(call, 100) : call();
		}
	}, [route.params?.selectedTags]);

	tags.sort((a, b) => {
		const aIn = preferredTags.some((t) => t.id === a.id);
		const bIn = preferredTags.some((t) => t.id === b.id);

		if (aIn && !bIn) return -1;
		if (!aIn && bIn) return 1;
		return 0;
	});

	React.useEffect(() => {
		if (!tags.length) {
			dispatch(fetchGameTags());
		}
	}, [tags]);

	const toggleTag = React.useCallback(
		(tag) => {
			let tags = preferredTags.map((t) => t.name);
			if (preferredTags.some((t) => t.id === tag.id)) {
				tags = tags.rg_removeSimple(tag.name);
			} else {
				tags.push(tag.name);
			}
			navigation.setParams({ preferredTags: tags });
		},
		[preferredTags]
	);

	return (
		<WizardScreen nextScreen="WizardAgeSelectionScreen">
			<AppText bold centered={!isWeb()} style={ws({ marginBottom: 16 }, { marginTop: 16 })}>
				{_("I am looking for the tags")}
			</AppText>

			<FlatList
				contentContainerStyle={[{ flex: 1 }, ws({}, { justifyContent: "center", alignItems: "center" })]}
				columnWrapperStyle={{ margin: 8 }}
				keyExtractor={idKeyExtractor}
				data={tags.slice(0, 10)}
				renderItem={({ item }) => (
					<AppButton
						title={item.name}
						isToggle
						toggled={preferredTags.some((t) => t.id === item.id)}
						style={{ marginHorizontal: 8 }}
						onPress={() => toggleTag(item)}
					/>
				)}
				ListEmptyComponent={<ActivityIndicator color={global.colors.hint}/>}
				numColumns={2}
			/>
			<AppText
				size="large"
				bold
				textOnlyStyle={{ textAlign: "right", flex: 1 }}
				onPress={() =>
					navigation.navigate("SelectGameTagsScreen", {
						activeTags: preferredTags || [],
						sendToScreen: route.name,
					})
				}
			>
				{_("More tags", "find a game wizard")}
			</AppText>
		</WizardScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		tags: state.gameTags || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(WizardTagSelectionScreen);


