import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-paper";
import { useHover, useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { clearLines } from "../../store/slices/linesSlice";
import { BuildStyleMethod } from "../../styles/theming";
import { gameHasUnloadedNext, hasUnreadTaggedChat } from "../../tools/games";
import { idEqual, ws } from "../../tools/generic";
import { openGameAtLine } from "../../tools/lines";
import { cStyle } from "../../tools/react";
import { getTimestampShort } from "../../tools/time";
import { navPush } from "../../tools/webnavigation";
import AppText from "../generic/AppText";
import UnreadBadge from "../generic/UnreadBadge";


function getUnreadLines(user, game) {
	if (!user) return [];
	return user.unread_lines.filter((l) => l.game === game.id);
}

function ActiveGameItem({ game, isBookmarked, dispatch, user, hasNext, bookmark }) {
	const styles = stylesMethod(global.theme);

	const navigation = useNavigation();
	let bgName = game.cover_background ? game.cover_background.name : "neutral_cover_1";
	if (bgName === "neutral_1") bgName = "neutral_cover_1";
	const imageURL = `${global.root_url}/static/images/stagebg/bg_${bgName}.png`;

	let { last_line: previewLine } = game;
	if (isBookmarked) {
		if (bookmark) {
			previewLine = bookmark.line;
		} else {
			previewLine = null;
		}
	}

	let authorName = "";

	if (previewLine) {
		if (previewLine.author) {
			authorName = previewLine.author.sheet?.character_name;
		} else if (previewLine.is_chat && previewLine.user) {
			authorName = previewLine.user.username;
		} else {
			authorName = _("Narrator");
		}
	}

	const unreadLines = getUnreadLines(user, game);
	const unreadGameLines = unreadLines.filter((l) => !l.is_comments);
	const isTagged = unreadLines.length && hasUnreadTaggedChat(game, user);

	const onPress = React.useCallback(
		(e) => {
			if (e) {
				e.preventDefault();
			}
			if (unreadGameLines?.length) {
				openGameAtLine(
					game,
					unreadGameLines[0].id,
					unreadGameLines[0].party?.id || unreadGameLines[0].party,
					0.2,
					dispatch,
					navigation
				);
				return;
			} else if (isBookmarked && bookmark) {
				openGameAtLine(game, bookmark.line?.id || 0, bookmark.line?.party, 0.2, dispatch, navigation);
				return;
			}
			// if the last time the game was open, it wasn't at the bottom, unloaded everything to reload the bottom
			if (hasNext) {
				dispatch(clearLines(game.id));
			}

			navPush(navigation)("GameStack", {
				screen: "Game",
				gameslug: game.slug,
				// Reset params, otherwise it will keep the lineToReach and linePosition from previously opened games
				params: { lineToReach: null, linePosition: null, party: null },
			});
		},
		[game, hasNext, bookmark, isBookmarked, unreadGameLines]
	);

	let lastLineContent = authorName + ": ";

	React.useEffect(() => {
		if (!previewLine && !isBookmarked) {
			apiFetch(`games/${game.id}/register_last_line`, "POST");
		}
	}, [previewLine, isBookmarked, game.id]);

	if (!previewLine) {
		lastLineContent = isBookmarked
			? _("Start reading", "empty game line")
			: _("No content yet.", "empty game line");
	} else if (previewLine.content) {
		lastLineContent += previewLine.content;
	} else if (previewLine.action) {
		const action = JSON.parse(previewLine.action);
		if (action.name) {
			lastLineContent += action.name + " ";
		}
		if (action.type === "open_map") {
			lastLineContent += _("[Map]", "indicates that a line is showing a map");
		} else {
			lastLineContent += _("[Dice roll]", "indicates that a line was a dice roll");
		}
	} else {
		lastLineContent += "...";
	}

	const pending = user && game.users_pending.indexOf(user.id) > -1;
	const userIsInGame =
		user && (game.players.some((playerId) => playerId === user.id) || idEqual(game.game_master, user));
	const checked = !pending && userIsInGame;

	const ref = React.useRef(null);
	const isHovered = useHover(ref);

	const { onLayout } = useLayout();

	return (
		<TouchableOpacity
			onLayout={onLayout}
			ref={ref}
			onPress={onPress}
			activeOpacity={0.7}
			href={`/${game.slug}`}
			accessibilityRole="link"
			style={ws([
				{ borderWidth: 1, borderColor: "transparent", padding: 4 },
				isHovered && {
					borderColor: global.colors.lightBorder,
					backgroundColor: global.colors.hoverItemLightBackground,
				},
			])}
		>
			<View style={cStyle([styles.container, [!!isTagged, styles.tagged]])}>
				<View>
					<Avatar.Image
						source={{ uri: imageURL }}
						size={64}
						style={cStyle([styles.image, [checked, { opacity: 0.5 }]])}
					/>
				</View>
				<View style={styles.content}>
					<View style={{ justifyContent: "space-between", flexDirection: "row" }}>
						<AppText
							bold
							style={{ flex: 1 }}
							color={checked ? "secondary" : null}
							numberOfLines={2}
							ellipsizeMode="tail"
						>
							{game.name}
						</AppText>
						<AppText
							size="small"
							color={isTagged ? "attentionImportant" : unreadLines.length ? "attention" : null}
						>
							{getTimestampShort(previewLine?.created || game.last_update, true)}
						</AppText>
					</View>

					<View style={{ justifyContent: "space-between", flexDirection: "row" }}>
						<AppText
							style={styles.lastLine}
							numberOfLines={1}
							ellipsizeMode="tail"
							italic={!previewLine}
							color={unreadLines.length ? null : !previewLine ? "hint" : "secondary"}
						>
							{!!bookmark && isBookmarked && !!previewLine && (
								<MaterialIcons name="bookmark" size={12} color={global.colors.textDefault} />
							)}
							{lastLineContent}
						</AppText>
						<View>
							<UnreadBadge value={unreadLines.length} important={isTagged} />
						</View>
					</View>
				</View>
			</View>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.game.id;

	return {
		user: state.user,
		hasNext: gameHasUnloadedNext(state, gameId),
		bookmark: state.bookmarkForGames[gameId] || state.savedStore.bookmarkForGames[gameId],
	};
};

export default connect(mapStateToProps)(ActiveGameItem);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flexDirection: "row",
			marginBottom: 4,
		},

		tagged: {
			borderWidth: 2,
			borderColor: colors.attentionImportantOpacity,
			padding: 8,
			borderRadius: 6,
		},

		content: { flex: 1 },

		image: { marginRight: 16, backgroundColor: "transparent" },

		newContent: { color: colors.attention },

		lastLine: {
			overflow: "hidden",
			flex: 1,
		},
	})
);
