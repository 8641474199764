import * as React from "react";
import { View, StyleSheet } from "react-native";
import ListButton from "../generic/buttons/ListButton";
import { _ } from "../../i18n/i18n";
import RGIcon from "../RGIcon";
import { useNavigation } from "@react-navigation/native";
import BoxModal from "../generic/modal/BoxModal";
import { removeLanguage } from "../../store/slices/languagesSlice";
import { apiFetch } from "../../network/apiFetch";
import { useDispatch } from "react-redux";
import AppText from "../generic/AppText";

function LanguageItem({ language }) {
	const deleteModalRef = React.useRef();

	const navigation = useNavigation();
	const dispatch = useDispatch();

	const options = [];

	options.push({
		title: _("Delete"),
		danger: true,
		onPress: () => deleteModalRef.current.show(),
	});
	const deleteLanguage = React.useCallback(() => {
		dispatch(removeLanguage(language));
		apiFetch(`games/${language.game}/languages/${language.id}`, "DELETE");
	}, [language, dispatch]);

	return (
		<>
			<ListButton
				title={language.name}
				iconLeft={{ type: RGIcon, name: "language" }}
				onPress={() => navigation.navigate("EditLanguageScreen", { language })}
				options={options}
			/>

			<BoxModal
				ref={deleteModalRef}
				message={
					<>
						<AppText>
							{_("Are you sure you want to delete the language %(language)s?", "", {
								language: language.name,
							})}
						</AppText>
						<AppText style={{marginTop: 4}} color="hint">
							{_(
								"This action cannot be reverted and every message written in this language will be readable by all players."
							)}
						</AppText>
					</>
				}
				isDelete
				onConfirm={deleteLanguage}
			/>
		</>
	);
}

export default LanguageItem;


