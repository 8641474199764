import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { saveStore } from "../../tools/storeTools";
import { gamesSlice } from "./gamesSlice";
import { messageGroupsSlice } from "./messageGroupsSlice";
import { idEqual } from "../../tools/generic";

export const userSlice = createSlice({
	name: "user",
	initialState: null,
	reducers: {
		receiveMainUser: (state, { payload: user }) => user,
	},
});

export const { receiveMainUser } = userSlice.actions;

export const usersSlice = createSlice({
	name: "users",
	initialState: {
		current: null,
	},
	reducers: {
		receiveUsers: (state, { payload: users }) => {
			for (let i = 0; i < users.length; i++) {
				const user = users[i];
				state[user.id] = user;
				state[user.username.toLowerCase()] = user;
			}
			return state;
		},
	},
	extraReducers: {
		[userSlice.actions.receiveMainUser]: (state, { payload: user }) => {
			state[user.id] = user;
			state[user.username] = user;
			state.current = user;
			return state;
		},
		// extract user info from the game object
		[gamesSlice.actions.updateStoredGame]: (state, { payload: game }) => {
			if (!game) return state;
			state[game.game_master.id] = { ...state[game.game_master.id], ...game.game_master };
			for (let i = 0; i < game.players.length; i++) {
				const player = game.players[i];
				if (!player.id) continue;
				const updatedUser = { ...state[player.id], ...player };
				state[updatedUser.id] = updatedUser;
				state[updatedUser.username.toLowerCase()] = updatedUser;
			}
		},
		[messageGroupsSlice.actions.receiveMessageGroups]: (state, { payload: { groups } }) => {
			const users = groups
				.reduce((finalArray, group) => [...finalArray, ...group.members], [])
				.filter((u) => !idEqual(u, state.current))
				.rg_toStore();

			return { ...state, ...users };
		},
	},
});

export const { receiveUsers } = usersSlice.actions;

export function fetchCurrentUser() {
	return async (dispatch, getState) => {
		return apiFetch("users/current")
			.then((response) => {
				dispatch(receiveMainUser(response));
				return response;
			})
			.catch((e) => {
				console.warn("Error while fetching user", e);
			});
	};
}

export function fetchGameUsers(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/users`).then((response) => {
			dispatch(receiveUsers(response));
			saveStore(getState());
		});
	};
}

export function fetchUser(username) {
	return async (dispatch, getState) => {
		username = encodeURIComponent(username);
		return apiFetch(`users/${username}`).then((user) => {
			if (user) {
				dispatch(receiveUsers([user]));
				saveStore(getState());
			}
			return user;
		});
	};
}
