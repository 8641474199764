import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { Share, ScrollView, Dimensions } from "react-native";
//import Clipboard from "@react-native-community/clipboard";
// import { Clipboard } from "react-native"
import * as Clipboard from 'expo-clipboard';
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import ModalScreen from "../generic/modal/ModalScreen";
import { removeStoryMarker } from "../../store/slices/storyMarkersSlice";
import { isGM } from "../../tools/games";
import { getMarkerTitle } from "../../tools/storyMarker";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import StageBackgroundImage from "../stagebackgrounds/StageBackgroundImage";
import { isWeb, ws } from "../../tools/generic";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function StoryMarkerModal({ route, navigation, storyMarkers, dispatch, game, user, marker }) {
	const confirmDeleteModalRef = React.useRef(null);
	const copiedModalRef = React.useRef();

	const onShare = React.useCallback(async () => {
		const url = `https://www.rolegate.com/${game.slug}/bookmark/${marker.id}`;
		let message = `${game.name} | ${marker.name}\n`;
		if (marker.description) message += `${marker.description}\n\n`;
		message += url;

		if (isWeb()) {
			Clipboard.setStringAsync(url);
			copiedModalRef.current.show();
		} else {
			try {
				Share.share({ title: game.name, message, url }, { dialogTitle: game.name });
			} catch (error) {
				// alert(error.message);
			}
		}
	}, [marker, game.name, game.slug]);

	if (!marker) {
		return null;
	}

	const markerTitle = getMarkerTitle(marker, storyMarkers);

	const gm = isGM(game, user);

	const options = [];
	gm &&
		options.push({
			title: _("Edit"),
			info: _("Change name, synopsis and background", "story marker edit button info"),
			icon: { type: MaterialIcons, name: "edit" },
			onPress: () => navigation.navigate("EditStoryMarkerModal", { markerId: marker.id }),
		});
	!!marker.id &&
		options.push({
			title: ws(_("Copy link", "copy game url button"), _("Share", "share button")),
			info: _("Share your game from this scene", "share game from story marker"),
			icon: { type: MaterialIcons, name: "share" },
			onPress: onShare,
		});
	gm &&
		options.push({
			title: _("Delete"),
			info: _("Remove this story marker"),
			icon: { type: MaterialIcons, name: "delete" },
			danger: true,
			onPress: () => confirmDeleteModalRef.current.show(),
		});

	const windowDim = Dimensions.get("window");

	return (
		<ModalScreen options={options} style={{ marginTop: 0 }} wide>
			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete Story Marker")}
				message={_("Are you sure you want to delete this story marker?")}
				isDelete
				onConfirm={() => {
					navigation.goBack();
					apiFetch("games/" + marker.game + "/story-markers/" + marker.id, "DELETE");
					dispatch(removeStoryMarker(marker));
				}}
			/>

			<ScrollView style={{ marginHorizontal: -16, paddingHorizontal: 16 }}>
				<StageBackgroundImage
					marker={marker}
					height={Math.min(windowDim.height * 0.1, 136)}
					text={
						<AppText>
							<AppText color="secondary" hide={!marker.id}>
								{markerTitle} |{" "}
							</AppText>
							<AppText>{marker.name}</AppText>
						</AppText>
					}
				/>
				<AppText
					italic={!marker.description}
					color={!marker.description ? "hint" : null}
					style={{ marginTop: 4 }}
				>
					{marker.description || _("no description", "story marker description placeholder")}
				</AppText>
			</ScrollView>
			<TinyFeedbackModal ref={copiedModalRef} message={_("Copied to clipboard!")} />
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const id = ownProps.route.params.markerId;
	const marker = state.storyMarkers.byId[id];
	const gameId = marker.game;
	return {
		game: state.games[gameId],
		user: state.user,
		storyMarkers: state.storyMarkers[gameId] || Array.rg_empty,
		marker,
	};
};

export default connect(mapStateToProps)(StoryMarkerModal);
