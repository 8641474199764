import * as React from "react";
import { View, Linking } from "react-native";
import BoxModal from "../generic/modal/BoxModal";
import { levelToRankName } from "../../tools/account";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import CoinIcon from "../coins/CoinIcon";
import { isWeb } from "../../tools/generic";

class LockedFeatureModal extends React.PureComponent {
	show() {
		this.refs.Modal.show();
	}

	render() {
		const { requiredLevel, navigation } = this.props;

		const rankName = levelToRankName(requiredLevel);

		const options = [
			{
				title: _("Not now"),
				transparent: true,
			},
			{
				title: _("Upgrade"),
				onPress: isWeb() ? () => Linking.openURL("https://www.rolegate.com/m/upgrade") : null,
			},
		];

		return (
			<BoxModal
				ref="Modal"
				options={options}
				message={
					<View>
						<View style={{ alignItems: "center", marginBottom: 16 }}>
							<CoinIcon level={requiredLevel} size={64} />
						</View>
						<AppText style={{ marginBottom: 8 }}>
							{_("This feature requires a %(account_level)s account.", "locked feature message", {
								account_level: rankName,
							})}
						</AppText>

						<AppText>
							{_("Upgrade your account to unlock it and support Role Gate!", "locked feature message")}
						</AppText>
					</View>
				}
			/>
		);
	}
}

export default LockedFeatureModal;
