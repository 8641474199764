import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _, _n } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { setAverageIntervalSeconds } from "../../../store/slices/gamesUISlice";
import { colors } from "../../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { sizes } from "../../../styles/font";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import { getPaceText } from "../../../tools/games";
import { ws } from "../../../tools/generic";
import CoinValue from "../../coins/CoinValue";
import AppText from "../../generic/AppText";
import Cond from "../../meta/Cond";
import CondView from "../../meta/CondView";

function GameStats({ game, style, dispatch, averageIntervalSeconds }) {
	const locked = !!game.password;

	style = style || globalStylesMethod(global.theme).floatingSection;

	React.useEffect(() => {
		if (!averageIntervalSeconds) {
			apiFetch(`games/${game.id}/average-interval`).then((value) => {
				dispatch(setAverageIntervalSeconds({ gameId: game.id, value }));
			});
		}
	}, [averageIntervalSeconds, game.id]);

	let pace = game.expected_pace;
	let messagePace = _("This game hasn't started", "info regarding game pace");

	if (averageIntervalSeconds) {
		const averageIntervalMinutes = averageIntervalSeconds / 60;
		const averageIntervalHours = averageIntervalMinutes / 60;
		const averageIntervalDay = averageIntervalHours / 24;
		const messagesPerDay = Math.round(24 / averageIntervalHours);

		const dailyMessagesPerPlayer = Math.round(messagesPerDay / game.players.length);

		messagePace = _n(
			"%(number)s message per day",
			"%(number)s messages per day",
			"info regarding game pace",
			messagesPerDay,
			{
				number: messagesPerDay,
			}
		);

		if (averageIntervalDay > 1) {
			const days = Math.round(averageIntervalDay);
			messagePace = _n("1 message per day", "1 message every %(x)s days", "info regarding game pace", days, {
				x: days,
			});
		}

		if (dailyMessagesPerPlayer > 10) {
			pace = 2;
		} else if (dailyMessagesPerPlayer > 3) {
			pace = 1;
		} else {
			pace = 0;
		}
	}

	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={[style, ws({ alignItems: "center" })]}>
			<View style={[ws(globalStylesMethod(global.theme).centeredBlock)]}>
				<View style={globalStyles.rc}>
					<FontAwesome
						name="user"
						style={{ marginRight: 4 }}
						size={sizes.large}
						color={global.colors.textDefault}
					/>
					<AppText size="large" hide={game.archived}>
						{game.players.length}/{game.max_players}
					</AppText>
					<AppText size="large" hide={!game.archived}>
						{game.players.length}
					</AppText>
					{locked && (
						<FontAwesome
							name="lock"
							style={{ marginLeft: 4 }}
							size={sizes.large}
							color={global.colors.textDefault}
						/>
					)}
				</View>

				<CondView show={!game.archived}>
					<AppText style={{ textAlign: "right" }} color={"pace" + getPaceText(pace, true)}>
						<AppText color="hint" hide={averageIntervalSeconds}>
							{_("Expected: ", "game's expected pace")}
						</AppText>
						{getPaceText(pace)}
					</AppText>
					<AppText style={{ textAlign: "right" }} italic>
						{messagePace}
					</AppText>
				</CondView>
				<CondView show={game.archived}>
					<AppText color="secondary" size="average">
						{_("Archived", "game is archived")}
					</AppText>
				</CondView>
			</View>

			<AppText
				hide={game.allow_spectators}
				color="hint"
				centered
				italic
				style={[{ marginVertical: 8 }, ws({ maxWidth: fullScreenContentWidth() })]}
			>
				{_("This game does not allow readers.")}
			</AppText>

			<View style={[globalStyles.rcsb, { marginTop: 8 }, ws({ maxWidth: fullScreenContentWidth() })]}>
				<Cond show={game.allow_spectators}>
					<View style={globalStyles.rc}>
						<MaterialIcons
							name="remove-red-eye"
							size={sizes.large}
							style={{ marginRight: 8 }}
							color={global.colors.textDefault}
						/>
						<AppText color="secondary">{game.all_time_spectators}</AppText>
					</View>
					<View style={globalStyles.rc}>
						<MaterialIcons
							name="bookmark"
							size={sizes.large}
							style={{ marginRight: 8 }}
							color={global.colors.textDefault}
						/>
						<AppText color="secondary">{game.total_bookmarks}</AppText>
					</View>
				</Cond>

				<CoinValue subject={game} subjectGetter={{ game_id: game.id }} syle={{ flex: 1 }} />
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		averageIntervalSeconds: state.gamesUI[ownProps.game.id]?.averageIntervalSeconds,
	};
};

export default connect(mapStateToProps)(GameStats);
