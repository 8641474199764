//@ts-check
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { gameHeaderOptions } from "../../tools/navigationOptions";
import MacroGroupScreen from "./MacroGroupScreen";
import MacroScreen from "./MacroScreen";
import ManageMacrosScreen from "./ManageMacrosScreen";
import ViewSheetMacrosScreen from "./ViewSheetMacrosScreen";
import { _ } from "../../i18n/i18n";
import CharacterSheetLoader from "../sheets/CharacterSheetLoader";

function MacrosNavigation(Stack, initialParams, game) {
	return (
		<>
			<Stack.Screen
				name="ManageMacrosScreen"
				children={(props) => <CharacterSheetLoader {...props} RenderComponent={ManageMacrosScreen} />}
				options={({ route, navigation }) =>
				game
						? gameHeaderOptions({
								route,
								navigation,
								title: _("Manage macros"),
								game: game,
								subtitle: game.name,
						  })
						: { headerTitle: _("Manage macros"), title: _("Manage macros | Role Gate") }
				}
				initialParams={initialParams}
			/>
			<Stack.Screen
				name="ViewSheetMacrosScreen"
				component={ViewSheetMacrosScreen}
				options={({ route, navigation }) => ({ headerTitle: _("Macros"), title: _("Macros | Role Gate") })}
			/>
			<Stack.Screen
				name="MacroGroupScreen"
				component={MacroGroupScreen}
				options={({ route, navigation }) => ({
					headerTitle: route.params?.macroGroup.name,
					title: _("Macro groups | Role Gate"),
				})}
			/>
			<Stack.Screen
				name="MacroScreen"
				component={MacroScreen}
				options={({ route, navigation }) => ({
					headerTitle: route.params?.macro.name,
					title: _("Macros | Role Gate"),
				})}
			/>
		</>
	);
}

export default MacrosNavigation;


