import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { addFaceToDie, saveDie } from "../../store/slices/diceSlice";
import { colors } from "../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { ws } from "../../tools/generic";
import { simpleReducer, usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import ColorSelector from "../generic/ColorSelector";
import InputField from "../generic/InputField";
import TokenSelector from "../generic/TokenSelector";
import RGTokenIcon from "../RGTokenIcon";
import DieFaceItem from "./DieFaceItem";

function EditDieScreen({ route, navigation, dispatch, die, user }) {
	const [editedDie, updateDie] = React.useReducer(simpleReducer, die);
	const editedDieRef = React.useRef(editedDie);
	editedDieRef.current = editedDie;
	const previousDie = usePrevious(editedDieRef);

	const scrollViewRef = React.useRef();

	const isMine = die.creator && die.creator === user?.id;

	const saveTimeout = React.useRef(null);

	const save = React.useCallback(() => {
		dispatch(saveDie(editedDieRef.current));
	}, []);

	React.useEffect(() => {
		return () => {
			saveTimeout.current && save();
		};
	}, [die.id, save]);

	React.useEffect(() => {
		if (previousDie) return () => null;
		saveTimeout.current = setTimeout(save, 3000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [save, editedDie.name, editedDie.icon, editedDie.color]);

	const addNumberFace = React.useCallback(async () => {
		await dispatch(addFaceToDie(die, true));
		scrollViewRef.current.scrollToEnd();
	}, [die]);

	const addSymbolFace = React.useCallback(async () => {
		dispatch(addFaceToDie(die));
	}, [die]);

	
	return (
		<>
			<AppScreenView scroll scrollViewRef={scrollViewRef} style={{paddingLeft: fullScreenLeftPadding(), width: fullScreenContentWidth() }}>
				{isMine ? (
					<View>
						<InputField
							value={editedDie.name}
							onChangeText={(t) => updateDie({ name: t })}
							label={_("Die name", "input field label")}
						/>
						<View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 16 }}>
							<View style={{ alignItems: "center" }}>
								<AppText>{_("Icon", "die icon")}</AppText>
								<TokenSelector currentToken={editedDie.icon} setToken={(icon) => updateDie({ icon })} />
							</View>
							<View style={{ alignItems: "center" }}>
								<AppText>{_("Color", "die icon color")}</AppText>
								<ColorSelector
									currentColor={editedDie.color}
									setColor={(color) => updateDie({ color })}
								/>
							</View>
						</View>
					</View>
				) : (
					<View style={globalStyles.rc}>
						<RGTokenIcon
							name={die.icon}
							color={global.colors.userColors[die.color]}
							style={{ marginRight: 8 }}
							size={24}
						/>
						<AppText bold size="large">
							{die.name}
						</AppText>
					</View>
				)}

				<AppText bold style={{ marginTop: 24 }}>
					{_("Die faces", "listing faces of currently selected die")}
				</AppText>
				<AppText color="hint" hide={!isMine}>
					{ws(_("Click a symbol to change or delete it."), _("Tap a symbol to change or delete it."))}
				</AppText>
				<View style={{ marginTop: 16 }}>
					{die.faces.map((item, index) => (
						<DieFaceItem key={item.id} face={item} index={index} allowEdit={isMine} die={die} />
					))}
				</View>
			</AppScreenView>
			{isMine && (
				<ButtonFooter
					narrow={false}
					style={{maxWidth: fullScreenContentWidth()}}
					buttonProps={[
						{ title: _("Add number face"), onPress: addNumberFace },
						{ title: _("Add symbol/text face"), onPress: addSymbolFace },
					]}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { die } = ownProps.route.params;
	return {
		user: state.user,
		die: state.dice[die.id],
	};
};

export default connect(mapStateToProps)(EditDieScreen);


