import * as React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { fetchFeaturedGames } from "../../store/slices/gamesSlice";
import GameCard from "./GameCard";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import GamePreviewSample from "./GamePreviewSample";
import { colors } from "../../styles/colors";
import { fetchCharacterStates } from "../../store/slices/characterStatesSlice";
import { ws } from "../../tools/generic";
import { previewSampleSize } from "../../styles/dynamicStyles";

import { BuildStyleMethod } from "../../styles/theming";

function FeatureGame({ scrollView, onPreviewLoaded, dispatch, game, usesSaved }) {
	const styles = stylesMethod(global.theme);
	React.useEffect(() => {
		if (usesSaved) {
			dispatch(fetchFeaturedGames());
		}
	}, [usesSaved, dispatch]);

	React.useEffect(() => {
		if (game) {
			dispatch(fetchCharacterStates(game.id));
		}
	}, [game?.id]);

	if (!game) return null;

	return (
		<View>
			<AppText bold style={{ margin: 8 }}>
				{_("Featured game")}
			</AppText>
			<GameCard game={game} large style={{ marginBottom: 0 }} />
			<View style={styles.container}>
				<AppText color="hint" centered>
					{_("Preview")}
				</AppText>
				<GamePreviewSample game={game} scrollView={scrollView} onPreviewLoaded={onPreviewLoaded} />
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		usesSaved: !state.games.featured.length,
		game: state.games.featured.rg_first() || state.savedStore.games.featured.rg_first(),
	};
};

export default connect(mapStateToProps)(FeatureGame);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		borderWidth: 1,
		marginBottom: 8,
		borderColor: colors.lightBorder,
		paddingTop: 8,
		...ws({ maxWidth: previewSampleSize(), paddingHorizontal: 32 }),
	},
}));
