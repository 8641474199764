import * as React from "react";
import { View, StyleSheet, TouchableOpacity, ImageBackground } from "react-native";
import { Hoverable, useHover } from "react-native-web-hooks";
import { colors } from "../../../styles/colors";
import { ws } from "../../../tools/generic";
import AppIcon from "../AppIcon";
import AppText from "../AppText";

import { BuildStyleMethod } from "../../../styles/theming";

function XLButton({ title, icon, onPress, style }) {
	const styles = stylesMethod(global.theme);
	const iconComponent = <AppIcon color={global.colors.textDefault} size={64} style={styles.icons} size={64} {...icon} />;

	const ref = React.useRef(null);

	const isHovered = useHover(ref);

	return (
		<TouchableOpacity
			ref={ref}
			style={[styles.container, isHovered && styles.containerHover, style]}
			onPress={onPress}
		>
			<View style={{ flex: 1, width: "100%", borderRadius: 6 }}>
				<ImageBackground
					source={require("../../../../assets/images/splash_screen_background.png")}
					style={styles.image}
					imageStyle={{borderTopLeftRadius: 6, borderTopRightRadius: 6}}
				>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{iconComponent}
					</View>
				</ImageBackground>
			</View>
			<View style={styles.bottomContainer}>
				<AppText centered style={[{ height: 64, textAlignVertical: "center" }, ws({ paddingTop: 16 })]}>
					{title}
				</AppText>
			</View>
		</TouchableOpacity>
	);
}

export default XLButton;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		height: 192,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: colors.lightBorder,
		flex: 1,
		...ws({ flex: null, width: 175 }),
	},
	containerHover: {
		borderColor: colors.boldBorder,
	},
	bottomContainer: {
		paddingHorizontal: 8,
		backgroundColor: colors.backgroundSecondary,
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},
	icons: {},
	image: {
		flex: 1,
		resizeMode: "contain",
		justifyContent: "center",
	},
}));
