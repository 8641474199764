import * as React from "react";
import { View, StyleSheet, Linking } from "react-native";
import ModalScreen from "../../generic/modal/ModalScreen";
import { _ } from "../../../i18n/i18n";
import AppText from "../../generic/AppText";
import ListSwitch from "../../generic/ListSwitch";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import { apiFetch } from "../../../network/apiFetch";
import { connect } from "react-redux";
import { receiveAvatarRequest } from "../../../store/slices/avatarRequestSlice";

function WizardAvatarRequestConfirmSendModal({ route, navigation, dispatch, userId }) {
	const { requestInfo } = route.params;

	const [accepted, setaccepted] = React.useState(false);

	const [sending, setsending] = React.useState(false);

	const sendRequest = React.useCallback(() => {
		setsending(true);
		apiFetch(`users/${userId}/avatar-requests`, "POST", {
			sender: userId,
			description: requestInfo.description,
			references: requestInfo.references,
		}).then((request) => {
			dispatch(receiveAvatarRequest(request));
			setsending(false);
			navigation.navigate("WizardAvatarRequestDeadline");
		});
    }, [userId, requestInfo]);    
    

	return (
		<>
			<ModalScreen title={_("Confirm request", "confirm avatar request")} borderless>
				<View style={styles.container}>
					<AppText navigateTo="AvatarRequestTermsAndConditions">
						{_(
							"By clicking send, you acknowledge that you have read the %(terms_url)s for Avatar requests.",
							"",
							{
								terms_url: (
									<AppText key="url" color="url">
										{_("Terms and conditions")}
									</AppText>
								),
							}
						)}
					</AppText>

					<ListSwitch
						isEnabled={accepted}
						setIsEnabled={setaccepted}
						title={_("I have read and agreed to the Terms and conditions")}
						titleProps={{ color: "hint" }}
					/>
				</View>
				<ButtonFooter				
					buttonProps={[
						{
							title: _("Back"),
							onPress: () => navigation.navigate("WizardAvatarRequestSummary", { requestInfo }),
						},
						{
							title: _("Send", "send avatar request"),
							onPress: sendRequest,
							disabled: !accepted || sending,
						},
					]}
				/>
			</ModalScreen>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user.id,
	};
};

export default connect(mapStateToProps)(WizardAvatarRequestConfirmSendModal);

const styles = StyleSheet.create({
	container: { marginHorizontal: 16, marginBottom: 16 },
});
