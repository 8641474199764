import * as React from "react";
import { View, StyleSheet } from "react-native";
import ListButton from "../generic/buttons/ListButton";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import { FontAwesome5 } from "@expo/vector-icons";
import BoxModal from "../generic/modal/BoxModal";
import { connect } from "react-redux";
import { deleteDiceSet } from "../../store/slices/dicesetsSlice";
import { getUsername } from "../../tools/account";

function DiceSetItem({ diceSet, onPress, dispatch, options, user }) {
	const confirmDeleteModalRef = React.useRef();

	const isMine = diceSet.creator?.id === user?.id;

	const deleteSet = React.useCallback(() => {
		dispatch(deleteDiceSet(diceSet));
	}, [diceSet]);

	options = options || [];
	if (!options.length) {
		isMine &&
			options.push({
				title: _("Delete"),
				danger: true,
				onPress: () => confirmDeleteModalRef.current.show(),
			});
	}

	return (
		<>
			<ListButton
				wide
				options={options}
				title={diceSet.name}
				subtitle2={
					diceSet.official
						? _("By Role Gate", "dice set creator")
						: _("By %(username)s", "dice set creator", {
								username: getUsername(diceSet.creator),
						  })
				}
				subtitle={diceSet.description}
				subtitleTextProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
				onPress={onPress}
				iconLeft={{ type: FontAwesome5, name: "dice" }}
			/>

			{isMine && (
				<BoxModal
					ref={confirmDeleteModalRef}
					title={_("Delete dice set")}
					message={
						<>
							<AppText>{_("Are you sure you want to delete this dice set?")}</AppText>
							<AppText color="hint" style={{ marginTop: 8 }}>
								{_(
									"The set will be deleted but rolls that already used one of its dice will be maintained."
								)}
							</AppText>
						</>
					}
					onConfirm={deleteSet}
					isDelete
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(DiceSetItem);


