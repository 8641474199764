import * as React from "react";
import { StyleSheet } from "react-native";
import WebView from "react-native-webview";
import AppText from "../generic/AppText";
import FullScreenLoader from "../generic/FullScreenLoader";

function TermsScreen() {
	const [loading, setloading] = React.useState(true);

	return (
		<>
			{loading && <FullScreenLoader />}
			<WebView
				style={{ backgroundColor: "transparent" }}
				source={{ uri: "https://www.rolegate.com/tos/" }}
				scalesPageToFit={false}
				renderError={() => <AppText>ERROR LOADING THE PAGE</AppText>}
				onLoad={() => setloading(false)}
			/>
		</>
	);
}

export default TermsScreen;


