import * as React from "react";
import { FlatList, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { createInteractiveHelperPanel } from "../../store/slices/interactiveHelpersSlice";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { idKeyExtractor } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import InteractiveHelperPanelItem from "./InteractiveHelperPanelItem";

function SelectPanelOptionTargetScreen({ route, navigation, dispatch, panels, helper }) {
	const [creating, setcreating] = React.useState(false);

	const { option } = route.params;

	const panelArray = React.useMemo(() => Object.values(panels), [panels]);

	const selectPanel = React.useCallback(
		(panel) => {
			navigation.navigate({
				name: "EditInteractiveHelperPanelScreen",
				params: { selectedPanel: panel, selectedOption: option },
				merge: true,
			});
		},
		[option]
	);

	const createOption = React.useCallback(async () => {
		setcreating(true);
		const panel = await dispatch(createInteractiveHelperPanel(helper.id));
		setcreating(false);
		selectPanel(panel);
	}, [helper.id, selectPanel]);

	return (
		<AppScreenView borderless>
			<FlatList
				data={panelArray}
				renderItem={({ item }) => (
					<InteractiveHelperPanelItem helper={helper} panel={item} onPress={selectPanel} panelSelection />
				)}
				keyExtractor={idKeyExtractor}
				ListFooterComponent={() => (
					<AppText color="hint" centered style={{ marginVertical: 32 }}>
						{_("Press + to make a new panel the target.", "select interactive helper option target panel")}
					</AppText>
				)}
			/>

			<FAB
				icon="plus"
				style={globalStylesMethod(global.theme).fab}
				onPress={() => createOption()}
				disabled={creating}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { helperId } = ownProps.route.params;
	const panels = state.interactiveHelpers.panels[helperId];
	return {
		panels,
		helper: state.interactiveHelpers.all[helperId],
	};
};

export default connect(mapStateToProps)(SelectPanelOptionTargetScreen);
