import * as React from "react";
import { StyleSheet, View } from "react-native";
import InternalPopoverFullScreen from "./InternalPopoverFullScreen";
import InternalPopoverSimple from "./InternalPopoverSimple";

class Popover extends React.PureComponent {
	showTimeout = null;
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			measure: {},
			popoverMeasure: null,
		};

		this.child = null;
		this.setChildRef = (element) => {
			this.child = element;
		};
	}

	componentDidMount() {
		// There is a bug with *something*, can be react native, navigation or god knows what,
		// but we cannot have the modal visible before a certain delay without triggering
		// "Malformed calls from JS: field sizes are different"
		// https://github.com/facebook/react-native/issues/23835
		if (this.props.visible && !this.state.visible) {
			this.showTimeout = setTimeout(() => this.show(), 100);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.showTimeout);
	}

	show() {
		if (this.child) {
			this.child.measureInWindow(this.saveMesure);
		}
		this.setState({ visible: true });
	}

	hide = () => {
		this.setState({ visible: false });
	};

	toggle = () => {
		this.setState({ visible: !this.state.visible });
	};

	saveMesure = (x, y, width, height) => {
		this.setState({ measure: { x, y, width, height } });
	};

	render() {
		let {
			children,
			popoverComponent,
			closeOnTap,
			arrowPosition,
			noClose,
			localCoordinates,
			title,
			displacementY,
			containerStyle,
			style,
		} = this.props;
		const { visible } = this.state;

		if (React.isValidElement(popoverComponent)) {
			popoverComponent = React.cloneElement(popoverComponent, { parentPopover: this, noClose });
		}

		const InternalPopover = localCoordinates ? InternalPopoverSimple : InternalPopoverFullScreen;

		return (
			<View
				ref={this.setChildRef}
				style={[
					{
						zIndex: 100,
					},
					containerStyle,
				]}
				onLayout={() => {
					if (this.child) {
						this.child.measureInWindow(this.saveMesure);
					}
				}}
			>
				{children}
				<InternalPopover
					title={title}
					arrowPosition={arrowPosition}
					popoverComponent={popoverComponent}
					targetMeasure={this.state.measure}
					visible={visible}
					onRequestClose={() => this.setState({ visible: false })}
					closeOnTap={closeOnTap}
					displacementY={displacementY}
					style={style}
				/>
			</View>
		);
	}
}

export default Popover;
