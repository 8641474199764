import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as Updates from "expo-updates";
import * as React from "react";
import { Linking, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { socket } from "../../network/websocket";
import { isWeb } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import ListButton from "../generic/buttons/ListButton";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function AboutRGScreen({ route, navigation, dispatch, user }) {
	const pongRef = React.useRef();

	const [updateAvailable, setupdateAvailable] = React.useState(global.updateAvailable);
	const [checkingForUpdate, setcheckingForUpdate] = React.useState(!global.updateAvailable);

	const checkForUpdate = React.useCallback(async () => {
		setcheckingForUpdate(true);
		try {
			const update = await Updates.checkForUpdateAsync();
			if (update.isAvailable) {
				await Updates.fetchUpdateAsync();
				setupdateAvailable(true);
			} else {
				setupdateAvailable(false);
			}
		} catch (e) {}
		setcheckingForUpdate(false);
	}, []);

	React.useEffect(() => {
		const showPong = () => pongRef.current.show();
		socket.on("pong", showPong);

		if (!isWeb()) {
			checkForUpdate();
		}

		return () => {
			socket.off("pong", showPong);
		};
	}, []);

	const updateApp = React.useCallback(async () => {
		await Updates.reloadAsync();
	}, []);

	return (
		<AppScreenView borderless>
			<AppText style={{ margin: 8 }}>
				{_("Version %(app_version)s", "about the app page", {
					app_version: global.appVersionMajor + "." + global.appVersionMinor,
				})}
			</AppText>
			<ListButton
				title={_("Contact", "about the app page")}
				onPress={() => Linking.openURL("mailto:contact@rolegate.com")}
				iconLeft={{ type: MaterialCommunityIcons, name: "email" }}
			/>
			<ListButton
				title={_("Terms of service", "about the app page")}
				onPress={() => Linking.openURL("https://www.rolegate.com/tos/")}
				iconLeft={{ type: MaterialCommunityIcons, name: "alert" }}
			/>
			<ListButton
				title={_("Privacy policy", "about the app page")}
				onPress={() => Linking.openURL("https://www.rolegate.com/privacy/")}
				iconLeft={{ type: MaterialCommunityIcons, name: "alert" }}
			/>
			<ListButton
				title={_("Ping server", "check if the Role Gate server responds")}
				onPress={() => apiFetch("users/" + user.id + "/ping", "POST")}
				iconLeft={{ type: MaterialIcons, name: "call-missed" }}
			/>
			<ListButton
				hide={!global.allowLiveUpdate || isWeb()}
				title={_("Update app", "button to update the app")}
				disabled={!updateAvailable}
				onPress={updateApp}
				badgeValue={updateAvailable ? 1 : null}
				iconLeft={{ type: MaterialIcons, name: "system-update" }}
				subtitle={
					checkingForUpdate
						? _("Checking for updates...")
						: updateAvailable
						? _("A new version of the app is available")
						: _("You are already running the latest version")
				}
			/>
			<View style={{ margin: 8, marginTop: 32 }}>
				<AppText size="small" color="hint">
					{_("Role Gate is developed by Tabletop Mirror, LLC.")}
				</AppText>
				<AppText size="small" color="hint">
					al. Solidarności 68/121, 00-240 Warsaw
				</AppText>
			</View>
			<TinyFeedbackModal ref={pongRef} message={_("Pong!", "response from the server when you 'ping' it")} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(AboutRGScreen);


