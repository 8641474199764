export const cancellablePromiseChain = (cancelCondition, promiseArray) => {
	let promiseChain = Promise.resolve();

	for (let i = 0; i < promiseArray.length; ++i) {
		let promise = promiseArray[i];

		promiseChain = promiseChain.then((data) => {
			if (cancelCondition()) {
				return;
			}
			return promise(data);
		}).catch(e=>console.log("Cancellable promise error:", e));
	}
	return promiseChain;
};

export const cancelOnErrorPromise = (promiseArray) => {
	let promiseChain = Promise.resolve();

	let cancelled = false;
	// Stupid unnecessary functions to avoid even stupider eslint warning about usage usage of a variable defined outside of the for loop.
	const cancel = () => (cancelled = true);
	const isCancelled = () => cancelled;

	for (let i = 0; i < promiseArray.length; ++i) {
		let promise = promiseArray[i];

		promiseChain = promiseChain
			.then((data) => {
				if (isCancelled()) return;
				return promise(data);
			})
			.catch((e) => {
				if (e) console.error(e);
				cancel();
			});
	}
	return promiseChain;
};
