import AsyncStorage from '@react-native-async-storage/async-storage';
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { apiFetch } from '../../network/apiFetch';
import { receiveAuthToken } from "../../store/slices/authtokenSlice";
import { fetchUserGames } from "../../store/slices/gamesSlice";
import { fetchCurrentUser } from "../../store/slices/usersSlice";
import { hasDevAccess, logout } from "../../tools/account";
import AppScreenView from "../AppScreenView";
import ErrorLoading from "../errors/ErrorLoading";
import ListButton from "../generic/buttons/ListButton";
import InputField from "../generic/InputField";

function DevSettingsScreen({ dispatch, user }) {
	const [token, settoken] = React.useState();

	const switchAccount = React.useCallback(async () => {
		let newToken = await apiFetch(`users/${user.id}}/debug-user-token`, "POST", {username: token});

		if(!newToken) return;
		logout(user.id, dispatch);

		AsyncStorage.setItem("authToken", newToken);
		global.authToken = newToken;
		await dispatch(receiveAuthToken(newToken));
		await dispatch(fetchCurrentUser());
		await dispatch(fetchUserGames());
	}, [user, token]);

	if (!hasDevAccess()) {
		return <ErrorLoading />;
	}

	return (
		<AppScreenView borderless>
			<InputField value={token} onChangeText={settoken} label="Token" />
			<ListButton onPress={switchAccount} title="Execute" size="large" />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(DevSettingsScreen);


