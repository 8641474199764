import * as React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Divider } from "react-native-paper";
import { _ } from "../../../i18n/i18n";
import { modalWidthNarrow, modalWidthWide } from "../../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../../styles/theming";
import { ws } from "../../../tools/generic";
import { cStyle } from "../../../tools/react";
import CondView from "../../meta/CondView";
import AppText from "../AppText";
import AppButton from "../buttons/AppButton";
import ButtonsRow from "../buttons/ButtonsRow";
import AppModal from "./AppModal";

class BoxModal extends React.PureComponent {
	state = {
		visible: false,
	};

	show() {
		this.setState({ visible: true });
	}

	hide = () => {
		this.setState({ visible: false });
		if (this.props.onRequestClose) {
			this.props.onRequestClose();
		}
	};

	render() {
		let {
			title,
			message,
			options: optionsReceived,
			isDelete,
			onConfirm,
			wide,
			children,
			addCancel,
			keepOpen,
			danger,
			boxStyle,
			titleStyle,
		} = this.props;
		const { visible } = this.state;

		let options = optionsReceived?.slice();

		addCancel = addCancel || (addCancel !== false && !options);

		if (!options) {
			options = [];

			if (isDelete) {
				options.push({
					title: _("Delete"),
					danger: true,
					onPress: () => {
						onConfirm && onConfirm();
						this.hide();
					},
				});
			} else {
				options.push({
					danger,
					title: _("Confirm"),
					onPress: () => {
						onConfirm && onConfirm();
						this.hide();
					},
				});
			}
		} else if (options && !keepOpen) {
			// Make sure all options close the modal
			options = options.map((o) => ({
				...o,
				onPress: () => {
					if (!o.keepOpen) {
						this.hide();
					}
					o.onPress && o.onPress();
				},
			}));
		}

		if (addCancel) {
			options.splice(0, 0, { title: _("Cancel"), transparent: true, onPress: this.hide });
		}

		const styles = stylesMethod(global.theme);

		let childrenComponent = null;
		if (children) {
			childrenComponent = React.Children.map(children, (child) => {
				// Checking isValidElement is the safe way and avoids a TS error too.
				if (React.isValidElement(child)) {
					return React.cloneElement(child, {
						closeModal: this.hide,
					});
				}

				return child;
			});
		}

		// react-native Modal doesn't allow keyboardShouldPersistTaps
		// https://github.com/facebook/react-native/issues/28871
		return (
			<AppModal animationType="fade" visible={visible} onRequestClose={this.hide} transparent>
				<ScrollView contentContainerStyle={styles.screen} keyboardShouldPersistTaps="handled">
					<TouchableOpacity style={styles.backdrop} activeOpacity={1} onPress={this.hide} />

					<View style={cStyle([styles.box, [wide, styles.boxWide], boxStyle])}>
						<CondView show={!!title} style={titleStyle}>
							{title && typeof title === "string" ? (
								<AppText style={styles.title} size="large" bold>
									{title}
								</AppText>
							) : (
								title
							)}
						</CondView>

						{title && <Divider />}
						<View style={styles.content}>
							{message && typeof message === "string" ? (
								<AppText>{message}</AppText>
							) : (
								childrenComponent || message
							)}
						</View>

						{!!options?.length && <Divider />}

						{!!options?.length && (
							<ButtonsRow style={styles.footer}>
								{options.map((opt, index) => (
									<AppButton key={index} size="small" {...opt} />
								))}
							</ButtonsRow>
						)}
					</View>
				</ScrollView>
			</AppModal>
		);
	}
}

export default BoxModal;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		screen: {
			flexGrow: 1,
			flexShrink: 0,
			flexBasis: "auto",
			alignItems: "center",
			justifyContent: "center",
			paddingVertical: 32,
		},
		backdrop: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: colors.overlayBackground,
		},
		box: {
			minHeight: 150,
			width: modalWidthNarrow(),
			maxWidth: "70%",
			backgroundColor: colors.cardBackground,
		},
		boxWide: {
			maxWidth: "90%",
			...ws({ width: modalWidthWide() }),
		},
		content: {
			padding: 16,
		},
		title: {
			padding: 16,
		},
		footer: {
			padding: 16,
		},
	})
);
