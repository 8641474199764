import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { deleteBookmark } from "../../store/slices/bookmarksSlice";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/global";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import { isInGame } from "../../tools/games";
import { idEqual, ws } from "../../tools/generic";
import NewContentSeparator from "../lines/NewContentSeparator";

import { BuildStyleMethod } from "../../styles/theming";

function Bookmark({ line, gameId, isAtTop, newContentBookmark, dispatch, bookmark }) {
	const styles = stylesMethod(global.theme);
	if (isInGame()) {
		bookmark = newContentBookmark;
	}
	if (!bookmark) return null;
	if (!bookmark.line && !isAtTop) return null;
	if (bookmark.line && !idEqual(bookmark.line, line)) return null;

	const modalRef = React.useRef();

	const removeBookmark = React.useCallback(() => {
		dispatch(deleteBookmark(gameId));
	}, []);

	if (isInGame()) {
		return <NewContentSeparator />;
	}

	return (
		<TouchableOpacity style={styles.container} onPress={() => modalRef.current.show()}>
			<View style={globalStyles.rc}>
				<MaterialIcons name="bookmark" size={18} color={global.colors.textLight} />
				<AppText color="textLight">{_("Bookmark")}</AppText>
			</View>
			<AppText color="textLight" color="hint" size="small">
				{ws(_("click to remove", "remove bookmark"), _("tap to remove", "remove bookmark"))}
			</AppText>
			<BoxModal
				ref={modalRef}
				title={_("Remove bookmark")}
				message={
					<>
						<AppText>
							{_("Removing the bookmark will remove this game from your list of bookmarked games.")}
						</AppText>
						<AppText style={{ marginTop: 16 }}>
							{ws(
								_(
									"You can click on the bookmark icon at the bottom of the screen if you simply want to move it."
								),
								_(
									"You can tap the bookmark icon at the bottom of the screen if you simply want to move it."
								)
							)}
						</AppText>
					</>
				}
				isDelete
				onConfirm={removeBookmark}
			/>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		bookmark: state.bookmarkForGames[ownProps.gameId],
	};
};

export default connect(mapStateToProps)(Bookmark);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: colors.primary,
		paddingHorizontal: 8,
		paddingVertical: 2,
		marginBottom: 4,
	},
	newContent: {
		backgroundColor: "transparent",
		justifyContent: "flex-start",
	},
}));
