import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";
import { idEqual } from "../../tools/generic";
import { saveStore } from "../../tools/storeTools";

export const gamesSlice = createSlice({
	name: "games",
	initialState: {
		currentId: null,
		byTagName: {},
		userGames: [],
		userNextUrl: null,
		bookmarked: [],
		bookmarkedNextUrl: null,
		browsed: [],
		browsedTotal: null,
		browsedGamesNextUrl: null,
		featured: [],
	},
	reducers: {
		receiveGames: (state, { payload: games }) => {
			let current = state.current;
			const updateCurrent = games.find((g) => idEqual(current, g));
			current = updateCurrent || current;
			return {
				...state,
				current,
				...games.rg_toStore(),
				...games.rg_toStore("slug"),
				userGames: state.userGames.rg_overlapById(games, false),
				bookmarked: state.bookmarked.rg_overlapById(games, false),
			};
		},
		updateStoredGame: (state, { payload: game }) => {
			state[game.id] = game;
			state[game.slug] = game;
			state.userGames = state.userGames.rg_replaceById(game.id, game);
			state.bookmarked = state.bookmarked.rg_replaceById(game.id, game);
			state.featured = state.featured.rg_replaceById(game.id, game);
		},
		setCurrentGameId: (state, { payload: gameId }) => {
			state.currentId = gameId;
		},
		receiveUserGames: (state, { payload: { games, next } }) => {
			return {
				...state,
				...games.rg_toStore(),
				...games.rg_toStore("slug"),
				userGames: games,
				userNextUrl: next,
			};
		},
		receiveBrowsedGames: (state, { payload: { results, count, next } }) => {
			state.browsed = state.browsed.rg_overlapById(results, true);
			state.browsedTotal = count;
			state.browsedGamesNextUrl = next;
		},
		replaceBrowsedGames: (state, { payload: { results, count, next } }) => {
			state.browsed = results;
			state.browsedTotal = count;
			state.browsedGamesNextUrl = next;
		},
		clearBrowsedGames: (state, action) => {
			state.browsed = [];
		},
		receiveBookmarkedGames: (state, { payload: { games, next } }) => {
			
			if(!next) next = state.bookmarkedNextUrl;
			return {
				...state,
				...games.rg_toStore(),
				...games.rg_toStore("slug"),
				bookmarked: games,
				bookmarkedNextUrl: next,
			};
		},
		receiveTagGames: (state, { payload: { tagName, games } }) => {
			state.byTagName[tagName] = (state.byTagName[tagName] || []).rg_overlapById(games);
		},
		receiveFeaturedGames: (state, { payload: games }) => {
			state.featured = games;
		},
		removeGameFromStore: (state, { payload: game }) => {
			state.userGames = state.userGames.rg_removeElementByIdPure(game.id);
			state.bookmarked = state.bookmarked.rg_removeElementByIdPure(game.id);
			state.browsed = state.browsed.rg_removeElementByIdPure(game.id);
			state.featured = state.featured.rg_removeElementByIdPure(game.id);

			const keys = Object.keys(state.byTagName);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				state.byTagName[key] = state.byTagName[key].rg_removeElementByIdPure(game.id);
			}
		},
	},
});

export const {
	receiveGames,
	setCurrentGameId,
	updateStoredGame,
	receiveUserGames,
	receiveTagGames,
	receiveBookmarkedGames,
	receiveBrowsedGames,
	clearBrowsedGames,
	replaceBrowsedGames,
	receiveFeaturedGames,
	removeGameFromStore,
} = gamesSlice.actions;

export function fetchGame(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}`).then((game) => {
			if (!game?.id) return null;
			// do not replace "last_line" with a version that has less info
			const currentVersion = getState().games[gameId];
			if (currentVersion) {
				game = { ...game, last_line: { ...currentVersion.last_line, ...game.last_line } };
			}

			dispatch(updateStoredGame(game));

			return game;
		});
	};
}

export function fetchUserGames() {
	return async (dispatch, getState) => {
		const user = getState().user;
		if (!user) {
			return;
		}
		return apiFetch(`users/${user.id}/games`, "GET", {
			ordering: "-last_update",
			archived: false,
			with_last_line: true,
		}).then(({ results: games, next }) => {
			dispatch(receiveUserGames({ games, next }));
		});
	};
}

export function fetchBookmarkedGames(userId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/games`, "GET", {
			ordering: "-last_update",
			bookmarked: true,
		}).then(({ results: games, next }) => {
			dispatch(receiveBookmarkedGames({ games, next }));
			saveStore(getState());
		});
	};
}

export function fetchTagGames(tag) {
	return async (dispatch, getState) => {
		return apiFetch(`tags/${tag.id}/games`, "GET", { "page-size": 7 }).then(async ({ results }) => {
			await dispatch(receiveTagGames({ tagName: tag.name, games: results }));
			await dispatch(receiveGames(results));
			saveStore(getState());
		});
	};
}

let lastSearchId = "";
export function fetchBrowsedGames(params) {
	return async (dispatch, getState) => {
		const searchId = JSON.stringify(params);

		if (searchId === lastSearchId) {
			return;
		}

		lastSearchId = searchId;

		return apiFetch(`games`, "GET", {
			hidden: false,
			...params,
		}).then((response) => {
			if (searchId !== lastSearchId) {
				return;
			}
			return dispatch(replaceBrowsedGames(response));
		});
	};
}

export function fetchNextUserGames(url) {
	if (!url) return (dispatch, getState) => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then(({ results: games, next }) => {
			games = getState().games.userGames.rg_overlapById(games, true);
			dispatch(receiveUserGames({ games, next }));
		});
	};
}

export function fetchNextBookmaredGames(url) {
	if (!url) return (dispatch, getState) => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then(({ results: games, next }) => {
			games = getState().games.bookmarked.rg_overlapById(games, true);
			dispatch(receiveBookmarkedGames({ games, next }));
		});
	};
}

export function fetchNexBrowsedGames(url) {
	if (!url) return (dispatch, getState) => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receiveGames(response.results));
			dispatch(receiveBrowsedGames(response));
		});
	};
}

export function fetchFeaturedGames() {
	return async (dispatch, getState) => {
		return apiFetch(`games/featured-games`).then((results) => {
			results = results.rg_shuffle();
			dispatch(receiveGames(results));
			dispatch(receiveFeaturedGames(results));
			saveStore(getState());
		});
	};
}

export function createNewGame(data) {
	return async (dispatch, getState) => {
		const state = getState();
		const userGames = state.games.userGames;
		return apiFetch(`games`, "POST", data).then((newGame) => {
			dispatch(receiveUserGames({ games: userGames.concat(newGame) }));
			return newGame;
		});
	};
}
