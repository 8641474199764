import * as React from "react";
import { connect } from "react-redux";
import { getCharName, getCharacterStateThen } from "../../tools/characters";
import AppText from "../generic/AppText";
import Cond from "../meta/Cond";

function CharacterSheetValue({ value, details, line, characters, isFirstInLine, index }) {
	
	const sign = value.is_subtract ? "-" : (index > 0 || !isFirstInLine) ? "+" : "";
	let name = null;
	if (value.target_id > 0) {
		const character = Object.values(characters).find((c) => c.sheet === value.sheet);
		if (line && character) {
			const state = getCharacterStateThen(line, character);
			name = getCharName(character, state.name);
		} else {
			name = getCharName(character);
		}
	}

	return (
		<AppText color="secondary">
			{sign} {value.label} <Cond show={name}>({name})</Cond>
			<AppText hide={details === 0} color="primary">({value.value})</AppText>
		</AppText>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		characters: state.characters,
		characterStates: state.characterStates,
	};
};

export default connect(mapStateToProps)(CharacterSheetValue);
