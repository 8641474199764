import { _ } from "../i18n/i18n";
import store from "../store/store";
import { enumerate } from "./generic";

export const getDefaultCharacter = () => {
	return {
		portrait_avatar: null,
		color: 1,
		background_color: 1,
		background_rank: "no",
		background_key: 0,
	};
};

export const characterFromGame = (gameId) => {
	const state = store.getState();
	return state.charactersByGame[gameId];
};

export const idToCharacter = (id) => {
	id = Number(id);
	const state = store.getState();

	return state.characters[id];
};

export const characterIdToCharacterStateId = (characterId) => {
	if (!characterId) return null;
	const state = store.getState();
	const characterStates = state.characterStates;
	const charState = characterStates.rg_findLast((s) => s.character === characterId);
	return charState ? charState.id : null;
};

export const idToCharacterState = (id) => {
	if (!id) return null;
	const { characterStates } = store.getState();
	return characterStates.find((s) => s.id === id);
};

export const getCharName = (character, nameThen, defaultIfNull = null) => {
	if (!character) return defaultIfNull;

	if (nameThen) return nameThen;

	const sheet = store.getState().sheets[character.sheet] || character.sheet;

	if (!sheet || !sheet.character_name) return _("A person", "default character name");

	return sheet.character_name;
};

export const getCharacterSheetValuePairs = (character, game) => {
	const state = store.getState();
	if (!character && !game) {
		game = state.games[state.games.currentId];
	}
	if (!character && !game) {
		return null;
	}

	const pairs = state.sheetValuePairs;

	const sheetId = character ? character.sheet : game.sheet_template;

	return pairs[sheetId];
};

const characterToState = (character, line) => {
	const party = line ? line.party : null;
	return {
		id: character.id,
		name: getCharName(character),
		color: character.color,
		portrait_avatar: character.portrait_avatar,
		background_rank: character.background_rank,
		background_key: character.background_key,
		background_color: character.background_color,
		party: party?.id,
	};
};

export const getCharacterStateThen = (line, character) => {
	let states = store.getState().characterStates;
	states = states.filter((s) => s.character === character.id);
	if (!line) {
		return states.rg_last(characterToState(character));
	}

	if (states.length > 0) {
		const partyId = line.party || null;
		const stagedLineId = line.id;

		for (let i = states.length - 1; i >= 0; --i) {
			let state = states[i];

			let partyMatch = false;
			// if is or was a player, the party doesn't matter
			if (!character.is_npc || character.original_player !== character.player?.id) {
				partyMatch = true;
			} else {
				partyMatch = !partyId || !state.party || state.party === partyId;
			}

			if ((state.state_start_line_id <= stagedLineId && partyMatch) || i === 0) {
				return state;
			}
		}
	}

	return characterToState(character);
};

export function filterByVisibility(characters, tags) {
	const characterIdsToHide = tags.reduce((results, tag) => results.concat(tag.hidden ? tag.characters : []), []);

	return characters.filter((c) => {
		if (c.hidden) return false;
		return !characterIdsToHide.includes(c.id);
	});
}

export function filterEmptyTags(tags, visibleCharacters) {
	return tags.filter((tag) => {
		const characters = visibleCharacters.filter((c) => tag.characters.includes(c.id));

		return !!characters.length;
	});
}

export function getStageNPC(characters, currentStoryMarker, lines) {
	let chars =
		currentStoryMarker &&
		currentStoryMarker.stage ?
		characters.filter((c) => currentStoryMarker.stage.npc_actors.includes(c.id) && c.is_npc) : null;

	if (!chars?.length) {
		chars = characters.filter((c) => c.is_npc);
	}

	const lastLines = {};
	// NPC who spoke last is shown first
	chars.sort((a, b) => {
		// Some optimization, to not get the last line at every loop of the sort
		const lastLineA = lastLines[a.id] || lines.rg_findLast((l) => l.author === a.id);
		const lastLineB = lastLines[b.id] || lines.rg_findLast((l) => l.author === b.id);
		lastLines[a.id] = lastLineA;
		lastLines[b.id] = lastLineB;

		if (lastLineA && !lastLineB) return -1;
		if (!lastLineA && lastLineB) return 1;
		if (!lastLineA && !lastLineB) return 0;

		return lastLineB.id - lastLineA.id;
	});

	return chars;
}
