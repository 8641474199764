import AsyncStorage from '@react-native-async-storage/async-storage';
import * as React from "react";
import { Linking, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles } from "../../styles/global";
import { defaultBugReportBody } from "../../tools/emailing";
import { isWeb, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import InputField from "../generic/InputField";
import BoxModal from "../generic/modal/BoxModal";

// Too lazy to create a store just for that
let savedReport = { bugTitle: "", bugSteps: "" };

function BugReportScreen({ user }) {
	const [discordUser, setdiscordUser] = React.useState(null);
	const [bugTitle, setbugTitle] = React.useState(savedReport.bugTitle);
	const [bugDescription, setbugDescription] = React.useState(savedReport.bugDescription);
	const [bugSteps, setbugSteps] = React.useState(savedReport.bugSteps);
	const emailaddress = "rolegate.app.bug@lodestarteam.com";

	const boxmodalRef = React.useRef();

	savedReport = { bugTitle, bugSteps, bugDescription };

	React.useEffect(() => {
		const fetchDiscordUser = async () => {
			const dUser = JSON.parse(await AsyncStorage.getItem("discordUser"));
			if (!dUser) {
				await apiFetch(`profiles/${user.id}/discord-user`).then((data) => {
					setdiscordUser(data);
					AsyncStorage.setItem("discordUser", JSON.stringify(data));
				});
			} else {
				setdiscordUser(dUser);
			}
		};
		if (user?.id) {
			fetchDiscordUser();
		}
	}, [user?.id]);

	const sendReport = React.useCallback(() => {
		setbugSteps("");
		setbugTitle("");

		let body = defaultBugReportBody({
			username: user?.username,
			description: bugDescription,
			steps: bugSteps,
			discordUsername: discordUser?.username,
		});

		if(isWeb()) body = encodeURIComponent(body);
		
		Linking.openURL(
			`mailto:${emailaddress}?subject=${bugTitle}&body=${body}`
		);
	}, [emailaddress, bugTitle, bugDescription, bugSteps, discordUser, user]);

	return (
		<AppScreenView scroll  style={{paddingLeft: fullScreenLeftPadding()}}>
			<AppText>{_("You can see the bugs that are already reported on this page:")}</AppText>
			<View style={[ws(null, globalStyles.jac), { marginTop: 12, marginBottom: 64 }]}>
				<AppText
					centered={!isWeb()}
					size="large"
					color="url"
					onPress={ws(null, () => Linking.openURL("https://trello.com/b/B0r4G8Is/role-gate-app-bug-tracker"))}
					accessibilityRole="link"
					href="https://trello.com/b/B0r4G8Is/role-gate-app-bug-tracker"
					target="_blank"
				>
					{_("Reported bugs list", "link to bug report page")}
				</AppText>
			</View>

			<View style={ws({ maxWidth: fullScreenContentWidth() })}>
				<AppText bold style={{ marginBottom: 4 }}>
					{_("Report a new bug:")}
				</AppText>
				<InputField
					value={bugTitle}
					onChangeText={setbugTitle}
					label={_("Short bug summary (%(characters_count)s characters left)", "", {
						characters_count: 50 - bugTitle.length,
					})}
					maxLength={50}					
				/>

				<InputField
					value={bugSteps}
					onChangeText={setbugSteps}
					label={_("Steps to reproduce the bug")}
					multiline
					inputStyle={ws({ height: 150 })}
				/>

				<AppText style={{ marginVertical: 24 }}>
					{ws(
						_("Click the button below to fill additional details and send a new report."),
						_("Tap the button below to fill additional details and send a new report.")
					)}
				</AppText>

				<AppButton
					title={_("Send new report")}
					onPress={() => {
						if (bugSteps.length < 100) {
							boxmodalRef.current.show();
							return;
						}
						sendReport();
					}}
					disabled={!bugTitle.trim() || !bugSteps.trim()}
				/>
			</View>

			<BoxModal
				ref={boxmodalRef}
				message={
					<View>
						<AppText color="danger">
							{_('It looks like you didn\'t write much in the "Steps to reproduce" section.')}
						</AppText>
						<AppText style={{ marginTop: 8 }}>
							{_(
								"With complete information, bugs can be fixed very rapidly. Without details however, it is possible that they are never reproduced by the team and not fixed at all."
							)}
						</AppText>
						<AppText style={{ marginTop: 8 }}>
							{_("Are you sure you do not want to elaborate on the issue you encountered?")}
						</AppText>
					</View>
				}
				options={[
					{ title: _("Go back"), transparent: true },
					{ title: _("Send without details"), onPress: sendReport },
				]}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(BugReportScreen);
