import * as React from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "../../../styles/colors";
import AppButton from "../buttons/AppButton";

import { BuildStyleMethod } from "../../../styles/theming";

function PopoverOptionsList({ options }) {
	const styles = stylesMethod(global.theme);
	
	return (
		<View style={styles.container}>
			{options.map((item, index) => (
				<AppButton key={index} {...item} list icon={null} popupMode />
			))}
		</View>
	);
}

export default PopoverOptionsList;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {	
		borderWidth: 1,
		borderColor: colors.lightBorder,
	},
}));
