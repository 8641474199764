import { isIos, isWeb } from "./generic";

export const defaultBugReportBody = ({username, description, steps, discordUsername}) =>
`
Please fill the missing information in before sending the report
---------

Name of the game:

Bug description: ${description || ""}

Steps to reproduce: 
${steps || ""}

Any additional relevant info:


Attach screenshots to the email if you have any relevant captures.

Discord username: ${discordUsername || ""}
RoleGate username: ${username || ""}
Platform:  ${isWeb() ? "web" : isIos() ? "iOs" : "android"}
App version: ${global.appVersion}`;
