import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";

import { BuildStyleMethod } from "../../styles/theming";

function ErrorLoading() {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	return (
		<AppScreenView style={styles.container} useSafeArea borderless>
			<AppText bold centered style={styles.text}>
				{_("Something went wrong.", "unacessible page")}
			</AppText>
			<AppText centered style={styles.text}>
				{_("The page failed to load, please go back and try again.", "unacessible page")}
			</AppText>
			<MaterialIcons name="error-outline" size={128} color={global.colors.primary} style={{ marginVertical: 64 }} />
			<AppText centered style={[styles.text, {marginBottom: 32}]}>
				{_("Feel free to submit a bug report if you believe it is a recurring issue.", "unacessible page")}
			</AppText>
			<AppButton
				onPress={() => navigation.navigate("BugReportScreen")}
				title={_("Report", "go to bug report screen")}
			/>
		</AppScreenView>
	);
}

export default ErrorLoading;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.cardBackground,
		alignItems: "center",
		justifyContent: "center",
	},
	text: {
		marginHorizontal: 16,
	},
}));
