import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { receiveCharacterTag } from "../../store/slices/characterTagsSlice";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ModalScreen from "../generic/modal/ModalScreen";
import HelpButton from "../tutorials/HelpButton";

function ManageTagVisibilityModal({ gameId, characterTags, navigation, dispatch }) {
	const toggleVisibility = (tag) => {
		apiFetch(`games/${gameId}/character-tags/${tag.id}`, "PATCH", {
			hidden: !tag.hidden,
		});
		dispatch(receiveCharacterTag({ ...tag, hidden: !tag.hidden }));
	};

	return (
		<ModalScreen
			
			title={_("Hide tags", "menu title")}
			helpButton={
				<HelpButton
					transparent
					title={_("Hide tags", "menu title")}
					message={
						"Characters marked with a hidden tag will not appear to players in places where characters are listed.\n\nUse this to keep characters secret or avoid clutter caused by characters that are not relevant to the current situation."
					}
				/>
			}
		>
			<AppText></AppText>
			<FlatList
				data={characterTags}
				renderItem={({ item }) => (
					<AppButton
						title={item.name}
						onPress={() => toggleVisibility(item)}
						list
						icon={{ type: MaterialCommunityIcons, name: item.hidden ? "eye-off" : "eye" }}
					/>
				)}
				keyExtractor={(item, index) => String(index)}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.route.params.gameId;
	return {
		gameId,
		characterTags: state.characterTags[gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(ManageTagVisibilityModal);


