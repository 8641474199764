import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { idEqual } from "../../tools/generic";

export const characterTagsSlice = createSlice({
	name: "characterTags",
	initialState: {},
	reducers: {
		receiveCharacterTags: (state, { payload: tags }) => {
			if (!tags || !tags.length) return;
			const gameId = tags[0].game;
			state[gameId] = tags.sort((a, b) => a.name.localeCompare(b.name));
			return state;
		},
		receiveCharacterTag: (state, { payload: tag }) => {
			const gameId = tag.game;
			state[gameId] = state[gameId] || [];
			state[gameId] = state[gameId].rg_overlapById([tag]).sort((a, b) => a.name.localeCompare(b.name));
			return state;
		},
		removeCharacterTag: (state, { payload: tag }) => {
			const gameId = tag.game;
			state[gameId].rg_removeElement((t) => idEqual(t, tag));
			return state;
		},
		replaceCharacterTag: (state, { payload: tag }) => {
			const gameId = tag.game;
			state[gameId] = state[gameId] || [];
			const array = state[gameId].rg_overlap(
				[tag],
				(newElement, oldElement) => newElement.name === oldElement.name
			);
			state[gameId] = array;
			return state;
		},
	},
});

export const {
	replaceCharacterTag,
	receiveCharacterTags,
	receiveCharacterTag,
	removeCharacterTag,
} = characterTagsSlice.actions;

export function fetchcharacterTags(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/character-tags`).then((response) => {
			dispatch(receiveCharacterTags(response));
		});
	};
}

export function addTagToCharacter(tag, character) {
	return async (dispatch, getState) => {
		const characters = tag.characters.slice();
		characters.push(character.id);
		dispatch(receiveCharacterTag({ ...tag, characters }));
		apiFetch(`games/${character.game}/character-tags/${tag.id}/add`, "POST", { character: character.id });
	};
}

export function removeTagFromCharacter(tag, character) {
	return async (dispatch, getState) => {
		const characters = tag.characters.rg_removeSimple(character.id);
		if (!characters.length) {
			dispatch(removeCharacterTag(tag));
		} else {
			dispatch(receiveCharacterTag({ ...tag, characters }));
		}

		apiFetch(`games/${character.game}/character-tags/${tag.id}/remove`, "POST", { character: character.id });
	};
}

export function createTagForCharacter(tagName, character) {
	return async (dispatch, getState) => {
		const tempTag = { name: tagName, game: character.game, characters: [character.id] };
		dispatch(receiveCharacterTag(tempTag));
		apiFetch(`games/${character.game.id}/character-tags`, "POST", { ...tempTag }).then((newTag) => {
			dispatch(replaceCharacterTag(newTag));
		});
	};
}

export default characterTagsSlice.reducer;
