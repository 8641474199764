import store from "../store/store";

export const groupIsRead = (group, user) => {
	if (group.last_message && user.unread_messages.includes(group.last_message.id)) {
		return false;
	}
	const messages = store.getState().directMessages[group.id];
	if (messages && messages.some((m) => user.unread_messages.includes(m.id))) {
		return false;
	}
	return true;
};

export const getInterlocutor = (group) => {
	if (typeof group === "number" || typeof group === "string") {
		group = store.getState().messageGroups[group];
	}

	if (!group) return null;
	const user = store.getState().user;
	return group.members.find((other) => other.id !== user.id);
};
