import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { allowGiveCoinTo, hasCoin } from "../../tools/coins";
import CoinIcon from "./CoinIcon";
import AppText from "../generic/AppText";
import { approximateValue } from "../../tools/generic";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import { connect } from 'react-redux';

function CoinValue({ subject, subjectGetter, style, navigation, hideIfNone, blockGive, dispatch, userId }) {

	navigation = navigation || useNavigation();
	const size = 12;

	const openHistory = React.useCallback(()=>{
		navigation.navigate({ name: "CoinHistory", params: { subject, subjectGetter, blockGive } });
	}, [subject, subjectGetter, blockGive]);

	if (!hasCoin(subject) && userId) {
		if (hideIfNone || !allowGiveCoinTo(userId, subject)) return null;

		return (
			<TouchableOpacity
				style={[styles.container, {width: Math.max(size, 24), height: 28, justifyContent: "center"}, style]}
				onPress={openHistory}
			>
				<CoinIcon level={0} style={{opacity: 0.65}} size={size}/>
				<MaterialCommunityIcons
					name="plus-circle"
					style={{ position: "absolute", bottom: 0, right: 0 }}
					color={global.colors.textLight}
					size={10}
				/>
			</TouchableOpacity>
		);
	}

	const total = subject.wood + subject.copper + subject.silver + subject.gold + subject.platinum + subject.legendary;

	return (
		<TouchableOpacity
			style={[styles.container, style]}
			onPress={openHistory}
		>
			{!!subject.wood && <CoinIcon level={0} size={size} style={styles.coin} />}
			{!!subject.copper && <CoinIcon level={1} size={size} style={styles.coin} />}
			{!!subject.silver && <CoinIcon level={2} size={size} style={styles.coin} />}
			{!!subject.gold && <CoinIcon level={3} size={size} style={styles.coin} />}
			{!!subject.platinum && <CoinIcon level={4} size={size} style={styles.coin} />}
			{!!subject.legendary && <CoinIcon level={5} size={size} style={styles.coin} />}
			<AppText color="secondary" small>
				{approximateValue(total)}
			</AppText>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(CoinValue);

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
	},
	coin: {
		marginRight: 2,
	},
});
