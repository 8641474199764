import { Feather, FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { addSymbolToFace, deleteFace, saveFaceValue } from "../../store/slices/diceSlice";
import { globalStyles } from "../../styles/global";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import RGTokenIcon from "../RGTokenIcon";
import EditTextModal from "../generic/EditTextModal";
import { colors } from "../../styles/colors";
import { hasValue } from "../../tools/generic";

function DieFaceItem({ die, face, index, allowEdit }) {
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const symbols = [];

	const editModalRef = React.useRef();

	for (let i = 0; i < face.symbol_links.length; ++i) {
		let link = face.symbol_links[i];
		for (let c = 0; c < link.count; ++c) {
			symbols.push(link.symbol);
		}
	}

	const changeFaceValue = React.useCallback((newValue)=>{
		dispatch(saveFaceValue(die, face, newValue))
	}, [die, face]);

	const IconTag = allowEdit ? TouchableOpacity : View;

	return (
		<View style={[globalStyles.rc, { marginBottom: 8 }]}>
			<AppText>{_("Face %(number)s", "indicates the index of die face", { number: index + 1 })} | </AppText>
			<FlatList
				data={symbols}
				horizontal={true}
				keyExtractor={(item, index) => String(index)}
				contentContainerStyle={globalStyles.rc}
				renderItem={({ item }) => {
					if (item.value)
						return (
							<IconTag
								onPress={() =>
									navigation.navigate("DieSymbolOptionsModal", { symbol: item, die, face })
								}
							>
								<RGTokenIcon name={item.value} size={46} color={global.colors.textDefault} />
							</IconTag>
						);
					return (
						<IconTag
							style={styles.textBox}
							onPress={() => navigation.navigate("DieSymbolOptionsModal", { symbol: item, die, face })}
						>
							<AppText size="small" numberOfLines={3} ellipsizeMode="tail">
								{item.name}
							</AppText>
						</IconTag>
					);
				}}
				ItemSeparatorComponent={() => <View style={{ width: 8 }} />}
				ListFooterComponent={
					<View style={globalStyles.rc}>
						{hasValue(face.value) && (
							<IconTag style={[styles.textBox, {borderColor: "transparent"}]} onPress={() => editModalRef.current.show()}>
								<AppText bold size="large">{face.value}</AppText>
							</IconTag>
						)}
						{allowEdit && !hasValue(face.value) && (
							<IconButton
								icon={{ type: FontAwesome, name: "plus" }}
								style={{ marginLeft: 16 }}
								onPress={() => dispatch(addSymbolToFace(die, face))}
							/>
						)}
					</View>
				}
			/>
			{allowEdit && (
				<IconButton
					icon={{ type: Feather, name: "delete" }}
					transparent
					onPress={() => dispatch(deleteFace(die, face))}
				/>
			)}
			<EditTextModal ref={editModalRef} initialValue={face.value} inputFieldProps={{ keyboardType: "numeric" }} onDone={changeFaceValue}/>
		</View>
	);
}

export default DieFaceItem;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
	},
	textBox: {
		borderWidth: 1,
		height: 46,
		width: 46,
		alignItems: "center",
		justifyContent: "center",
	},
});
