import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { isAndroid } from "../../tools/generic";
import PendingRequests from "../diceroll/PendingRequests";
import TypingFeedback from "../games/TypingFeedback";
import WaitingCharacters from "../games/WaitingCharacters";
import AppText from "../generic/AppText";
import LineSeparator from "./LineSeparator";
import { _ } from '../../i18n/i18n';

function LineListBottomComponent({ game, useInverted, lines, isComments, loadingMoreLines, hasNext, loadBottom }) {
    
	return (
		<>
			{!hasNext && !isComments && (
				<LineSeparator
					gameId={game.id}
					isAtBottom
					inverted={useInverted}
					leadingItem={useInverted ? null : lines.rg_last()}
					trailingItem={useInverted ? lines.rg_last() : null}
					lines={lines}
				/>
			)}
			<View style={[useInverted && isAndroid() && { scaleY: -1 }]}>
				<TypingFeedback />
				{!!lines.length && !hasNext && !isComments && <WaitingCharacters />}
				{!isComments && <PendingRequests gameId={game.id} />}
				{!loadingMoreLines && hasNext && !!lines.length && (
					<TouchableOpacity onPress={loadBottom} style={{ alignItems: "center", padding: 16 }}>
						<AppText color="primary" centered bold>
							{_("Load more", "load more lines")}
						</AppText>
					</TouchableOpacity>
				)}
			</View>
		</>
	);
}

export default LineListBottomComponent;

const styles = StyleSheet.create({
	container: {},
});
