import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { _ } from "../../i18n/i18n";
import { levelToCoinName } from "../../tools/account";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function CoinGivenFeedback() {
	const route = useRoute();
	const navigation = useNavigation();

	const { coinGivenLevel } = route.params || {};

	const [lastCoinGiven, setlastCoinGiven] = React.useState(null);

	const coingGivenModalRef = React.useRef(null);

	React.useEffect(() => {
		if (coinGivenLevel !== undefined) {
			setlastCoinGiven(coinGivenLevel);
			coingGivenModalRef.current.show();
			navigation.setParams({ coinGivenLevel: undefined });
		}
	}, [coinGivenLevel]);

	return (
		<TinyFeedbackModal
			ref={coingGivenModalRef}
			message={_("%(coin_type)s awarded!", "confirm coin given message", {
				coin_type: levelToCoinName(lastCoinGiven),
			})}
		/>
	);
}

export default CoinGivenFeedback;


