import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchSubscriptionInfo } from "../../store/slices/subscriptionSlice";
import { globalStyles } from "../../styles/global";
import { cStyle } from "../../tools/react";
import AppText from "../generic/AppText";
import Cond from "../meta/Cond";
import CoinCounter from "./CoinCounter";
import CoinHelpModal from "./CoinHelpModal";

function Purse({ style, small, dispatch, user, subscriptionInfo }) {
	const purse = user.profile.purse;

	if(!purse) return null

	const modalRef = React.useRef();

	React.useEffect(() => {
		if (!subscriptionInfo.fetched) {
			dispatch(fetchSubscriptionInfo());
		}
	}, [dispatch, subscriptionInfo]);

	let delay = "-";
	if (subscriptionInfo && subscriptionInfo.nextPayment) {
		delay = subscriptionInfo.nextPayment.fromNow();
	} else {
		const renewDate = moment();
		renewDate.add(1, "months").startOf("month");
		delay = renewDate.fromNow();
	}

	const Tag = global.appleStoreReview ? View : TouchableOpacity;

	const Coins = (
		<View style={[styles.coinContainer, !!small && styles.coinContainerSmall, global.appleStoreReview && styles.coinContainerSingle]}>
			<CoinCounter level={0} small={small} amount={purse.wood} />
			<Cond show={!global.appleStoreReview}>
				<CoinCounter small={small} level={1} amount={purse.copper} />
				<CoinCounter small={small} level={2} amount={purse.silver} />
				<CoinCounter small={small} level={3} amount={purse.gold} />
				<CoinCounter small={small} level={4} amount={purse.platinum} />
				<CoinCounter small={small} level={5} amount={purse.legendary} />
			</Cond>
		</View>
	);

	return (
		<Tag style={[styles.container, global.appleStoreReview && {justifyContent: "flex-start"}, style]} onPress={() => modalRef.current.show()}>
			<View>
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<FontAwesome5
						name="coins"
						size={24}
						style={{ marginRight: 4 }}
						color={global.colors.textDefault}
					/>
					<AppText bold>{_("Purse", "purse containing the coins")}</AppText>
				</View>
				<View style={[globalStyles.rc, { marginRight: 8 }]}>
					<MaterialCommunityIcons name="refresh" size={12} color={global.colors.hint} />
					<AppText color="hint" size="small">
						{delay}
					</AppText>
				</View>
			</View>

			{Coins}
			<CoinHelpModal ref={modalRef} />
		</Tag>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: ownProps.user || state.user,
		subscriptionInfo: state.subscriptionInfo,
	};
};

export default connect(mapStateToProps)(Purse);

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},
	coinContainer: {
		flexDirection: "row",
		flex: 1,
		maxWidth: 160,
	},
	coinContainerSmall: {
		maxWidth: 130
	},
	coinContainerSingle: {
		alignItems: "flex-start",
		flex: 0,
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: "auto",
		marginLeft: 24,
		maxWidth: null,		
	},
});
