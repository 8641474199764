import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from "react-native";
import Animated,  { useSharedValue, useAnimatedScrollHandler } from "react-native-reanimated";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { removeGameFromStore } from "../../../store/slices/gamesSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStylesMethod } from "../../../styles/global";
import { BuildStyleMethod } from "../../../styles/theming";
import { isAssistantGM, isGM, isInGame, isMainGM, isPlayer } from "../../../tools/games";
import { isWeb, ws } from "../../../tools/generic";
import { getTimestamp } from "../../../tools/time";
import ErrorLoading from "../../errors/ErrorLoading";
import AppText from "../../generic/AppText";
import IconButton from "../../generic/buttons/IconButton";
import ListButton from "../../generic/buttons/ListButton";
import CollapsableHeader, { HEADER_HEIGHT_OPENED } from "../../generic/CollapsableHeader";
import BoxModal from "../../generic/modal/BoxModal";
import Cond from "../../meta/Cond";
import CondView from "../../meta/CondView";
import GameAssitants from "../descriptionpanel/GameAssitants";
import GameDescription from "../descriptionpanel/GameDescription";
import GamePlayers from "../descriptionpanel/GamePlayers";
import GameStats from "../descriptionpanel/GameStats";
import GameSettingDiceSets from "./GameSettingDiceSets";
import GameSettingsPace from "./GameSettingsPace";
import GameSettingTags from "./GameSettingTags";
import GameSettingToggles from "./GameSettingToggles";
import { getStatusBarHeight } from "react-native-status-bar-height";
import ExportGameOption from "./ExportGameOption";
import FullScreenLoader from "../../generic/FullScreenLoader";

function GameSettingScreen({ route, navigation, dispatch, game, userId, backgroundSelected }) {
	const [updating_game_data, setUpdatingGameData] = React.useState(route.params?.updating_game_data ? route.params.updating_game_data : false);

	const styles = stylesMethod(global.theme);
	if (!game) return <ErrorLoading />;
	const [gamePassword, setgamePassword] = React.useState(game.password);
	
	// const scrollY = React.useRef(new Animated.Value(0)).current;
	const scrollY = useSharedValue(0);

	const scrollHandler = useAnimatedScrollHandler((event) => {
		scrollY.value = event.contentOffset.y;
	});

	const pathToBg = `${global.root_url}/static/images/stagebg/bg_${game.cover_background?.name || "neutral_1"}.png`;


	const confirmArchiveModal = React.useRef();
	const confirmLeaveModal = React.useRef();
	const confirmDeleteModal = React.useRef();

	React.useEffect(() => {
		if (route.params?.updating_game_data) {
			setUpdatingGameData(game.players.find((p) => p.id === userId));
		}
	},[route.params?.updating_game_data, game.players]);

	const archive = React.useCallback(() => {
		apiFetch(`games/${game.id}`, "PATCH", { archived: true });
	}, [game.id]);

	const unarchive = React.useCallback(() => {
		apiFetch(`games/${game.id}`, "PATCH", { archived: false });
	}, [game.id]);

	const onDeleteConfirmed = React.useCallback(() => {
		dispatch(removeGameFromStore(game));
		apiFetch(`games/${game.id}`, "DELETE");
		navigation.navigate("Home");
	}, [game]);

	const onLeaveConfirmed = React.useCallback(() => {
		setUpdatingGameData(false)
		dispatch(removeGameFromStore(game));
		apiFetch(`users/${userId}/games/${game.id}/leave`, "POST");
		navigation.navigate("Home");
	}, [game, userId]);

	const rename = React.useCallback(
		(newName) => {
			if (newName.trim() === game.name) return;
			if (!newName.trim()) return;

			let hidden = game.hidden;
			// Automatically hide games that contain "test" in the name (user can still unhide it if the want)
			if (new RegExp(/test/i).test(newName)) {
				hidden = true;
			}

			apiFetch(`games/${game.id}`, "PATCH", { name: newName, slug: null, hidden }).then((updatedGame) =>
				navigation.navigate("GameSettingScreen", { gameslug: updatedGame.slug })
			);
		},
		[game]
	);
	React.useEffect(() => {
		if (!backgroundSelected) return () => null;
		navigation.setParams({ backgroundSelected: null });
		apiFetch(`games/${game.id}`, "PATCH", { cover: backgroundSelected.name });
	}, [backgroundSelected, game.id]);

	const changePassword = React.useCallback(
		(newPassword) => {
			apiFetch(`games/${game.id}`, "PATCH", { password: newPassword?.trim() || null });
			setgamePassword(newPassword?.trim() || null);
		},
		[game.id]
	);

	const canEdit = isGM(game) && !game.archived;

	const ImageContainerTag = canEdit ? TouchableOpacity : View;

	const openedComponent = (
		<ImageContainerTag
			style={{ flexDirection: "column-reverse", height: HEADER_HEIGHT_OPENED }}
			onPress={() => navigation.navigate("SelectCover")}
		>
			<Image source={{ uri: pathToBg }} style={styles.background} resizeMode="cover" />
		</ImageContainerTag>
	);
	const collapsedComponent = <View style={{ backgroundColor: "black", height: 64 }}></View>;

	const shareButton = (
		<IconButton
			transparent
			icon={{ type: MaterialCommunityIcons, name: "share-variant", color: global.colors.textLight }}
			onPress={() => navigation.navigate("ShareGameModal", { gameslug: game.slug })}
		/>
	);

	// Template litterals inside a tag break GNU gettext
	// https://savannah.gnu.org/bugs/?50920#comment21
	const profileHref = `/me/${game.game_master.username}`;

	return (
		<>
			<Animated.ScrollView
				scrollEventThrottle={1}
				onScroll={scrollHandler}
				contentContainerStyle={styles.container}
			>
				{isWeb() && (
					<ImageContainerTag
						style={{
							flexDirection: "row",
							alignItems: "flex-end",
							justifyContent: "center",
							paddingVertical: 30,
							height: 300,
						}}
						onPress={() => navigation.navigate("SelectCover")}
					>
						<Image source={{ uri: pathToBg }} style={styles.background} resizeMode="cover" />

						<View style={[{ flexDirection: "row" }, globalStylesMethod(global.theme).centeredBlock]}>
							<View style={{ flex: 1 }}>
								<AppText size="large" editable={canEdit} onTextChanged={rename}>
									{game.name}
								</AppText>
								<AppText
									color="secondary"
									onPress={() =>
										navigation.navigate("ProfilePage", { username: game.game_master.username })
									}
									accessibilityRole="link"
									href={profileHref}
									target="_blank"
								>
									{_("by %(gm_name)s, created %(creation_date)s", "game details", {
										gm_name: game.game_master.username,
										creation_date: getTimestamp(game.start_date),
									})}
								</AppText>
							</View>
							{shareButton}
						</View>
					</ImageContainerTag>
				)}

				<GameStats game={game} />
				<GameDescription game={game} />
				<CondView
					show={isInGame()}
					style={[
						globalStylesMethod(global.theme).floatingSection,
						ws({ alignItems: "center" }),
					]}
				>
					<View style={[ws(globalStylesMethod(global.theme).centeredBlock)]}>
						<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
							<AppText color="primary">{_("Password (only visible to players)")}</AppText>
							<AppText color={gamePassword ? "danger" : "hint"} size="small" italics>
								{gamePassword?.trim()
									? _("This game is locked", "password protected game")
									: _("This game is open", "no password on game")}
							</AppText>
						</View>
						<AppText
							editable={canEdit}
							placeholder={_("None", "no password")}
							editTextTitle={_("Set password", "set password for game")}
							onTextChanged={changePassword}
						>
							{gamePassword?.trim()}
						</AppText>
					</View>
				</CondView>
				<GameSettingDiceSets game={game} />
				<GameSettingTags game={game} />
				<GameAssitants game={game} />
				<GamePlayers game={game} />
				{canEdit && <GameSettingsPace game={game} />}
				{canEdit && <GameSettingToggles game={game} />}

				<CondView
					show={isInGame()}
					style={[
						globalStylesMethod(global.theme).floatingSection,
						ws({ alignItems: "center"  }),
					]}
				>
					<ExportGameOption
						style={ws(globalStylesMethod(global.theme).centeredBlock)}
						gameId={game.id}
						disabled={!game.allow_export}
					/>
				</CondView>

				<Cond show={isMainGM(game)}>
					<View
						style={[
							globalStylesMethod(global.theme).floatingSection,
							ws({ alignItems: "center"  }),
						]}
					>
						<ListButton
							style={ws(globalStylesMethod(global.theme).centeredBlock)}
							hide={game.archived}
							title={_("Archive game")}
							iconLeft={{ type: MaterialCommunityIcons, name: "archive" }}
							noBorder
							onPress={() => confirmArchiveModal.current.show()}
						/>
						<ListButton
							style={ws(globalStylesMethod(global.theme).centeredBlock)}
							hide={!game.archived}
							title={_("Re-open game")}
							iconLeft={{ type: MaterialCommunityIcons, name: "book-lock-open" }}
							noBorder
							onPress={unarchive}
						/>
					</View>

					<View
						style={[
							globalStylesMethod(global.theme).floatingSection,
							ws({ alignItems: "center"  }),
						]}
					>
						<ListButton
							style={ws(globalStylesMethod(global.theme).centeredBlock)}
							danger
							title={_("Delete game")}
							iconLeft={{ type: MaterialCommunityIcons, name: "delete" }}
							noBorder
							onPress={() => confirmDeleteModal.current.show()}
						/>
					</View>
				</Cond>

				<CondView
					show={isPlayer(game) || isAssistantGM(game)}
					style={[
						globalStylesMethod(global.theme).floatingSection,
						ws({ alignItems: "center"  }),
					]}
				>
					<ListButton
						danger
						style={ws(globalStylesMethod(global.theme).centeredBlock)}
						title={_("Leave game")}
						iconLeft={{ type: MaterialCommunityIcons, name: "logout" }}
						noBorder
						onPress={() => confirmLeaveModal.current.show()}
					/>
				</CondView>
			</Animated.ScrollView>

			{!isWeb() && (
				<CollapsableHeader
					scrollAnimatedValue={scrollY}
					openedComponent={openedComponent}
					collapsedComponent={collapsedComponent}
					title={game.name}
					subtitle={_("by %(gm_name)s, created %(creation_date)s", "game details", {
						gm_name: game.game_master.username,
						creation_date: getTimestamp(game.start_date),
					})}
					headerRight={shareButton}
					showEditTitleButton={canEdit}
					onTitleChanged={rename}
				/>
			)}

			<BoxModal
				ref={confirmArchiveModal}
				title={_("Archive game")}
				message={
					<>
						<AppText>{_("Do you want to archive this game?")}</AppText>
						<AppText color="hint" style={{ marginTop: 8 }}>
							{_("Archived games cannot be edited and no new content can be added by any player.")}
						</AppText>
						<AppText color="hint" style={{ marginTop: 8 }}>
							{_("Archived games are visible on your profile page and can be re-opened at any time.")}
						</AppText>
					</>
				}
				onConfirm={archive}
			/>
			<BoxModal
				ref={confirmLeaveModal}
				title={_("Leave game")}
				message={
					<>
						<AppText>{_("Do you really want to leave this game?")}</AppText>
						<AppText color="hint" style={{ marginTop: 8 }}>
							{_("Your character will remain in the game as an NPC.")}
						</AppText>
					</>
				}
				addCancel
				options={[{ title: _("Leave", "leave game button"), onPress: onLeaveConfirmed, danger: true }]}
			/>

			<BoxModal
				ref={confirmDeleteModal}
				title={_("Delete game")}
				isDelete
				message={
					<>
						<AppText>{_("Do you REALLY want to delete this game?")}</AppText>
						<AppText color="danger" style={{ marginTop: 8 }}>
							{_("You cannot reverse this action and all this game's content will be lost.")}
						</AppText>
						<AppText color="hint" style={{ marginTop: 8 }}>
							{_(
								"Please make sure you discussed this decision with your players and consider archiving the game instead of deleting it."
							)}
						</AppText>
					</>
				}
				onConfirm={onDeleteConfirmed}
			/>
			<FullScreenLoader visible={updating_game_data} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameslug = ownProps.route.params?.gameslug;
	return {
		game: state.games[gameslug],
		userId: state.user?.id,
		backgroundSelected: state.stageBackgrounds.rg_findById(ownProps.route.params?.backgroundSelected),
	};
};

export default connect(mapStateToProps)(GameSettingScreen);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.backgroundSecondary,
			// flex: 1,
			paddingTop: ws(0, HEADER_HEIGHT_OPENED + getStatusBarHeight()),
		},
		background: ws(
			{
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				position: "absolute",
			},
			{
				position: "absolute",
				height: HEADER_HEIGHT_OPENED,
				width: "100%",
			}
		),
	})
);
