import * as React from "react";
import { Dimensions } from "react-native";
import GameBackAction from "../components/games/GameBackAction";
import GameHeader from "../components/games/GameHeader";
import { _ } from "../i18n/i18n";
import { isIos, ws } from "./generic";


export const gameHeaderOptions = ({ route, navigation, game, title, subtitle }) => ({
	headerTitle: () => (
		<GameHeader game={game} title={title} subtitle={subtitle || ws( _("Click for game details"), _("Tap for game details"))} navigation={navigation}/>
	),
	headerLeft: game ? () => <GameBackAction game={game} navigation={navigation} /> : undefined, 
	headerTitleAlign: "left",
	headerTitleContainerStyle: isIos() ? { left: 42 } : {},
	title: title ? title + " | Role Gate" : game ? game.name + " | Role Gate" : "Role Gate",
});


export const modalStackNavigatorScreenOptions = {
	cardStyle: { backgroundColor: global.colors.cardOverlayBackground },
	headerShown: false,
	headerTintColor: global.colors.textDefault ,
	animationEnabled: true,
	presentation: 'transparentModal'
};

export const modalScreenOptions = {
	title: "Role Gate",
	// gestureEnabled: true,
	gestureDirection: "vertical",
	gestureResponseDistance: Dimensions.get("window").height,
	headerShown: false,
	headerTintColor: global.colors.textDefault,
	cardStyle: {
		backgroundColor: "transparent",
		// opacity: 0.99 // Necessary if not using a cardstyleinterpolator that plays on the opacity
	},
	cardStyleInterpolator: ({ current: { progress } }) => ({
		cardStyle: {
			opacity: progress.interpolate({
				inputRange: [0, 0.5, 0.9, 1],
				outputRange: [0, 0.25, 0.7, 1],
			}),
		},
		overlayStyle: {
			opacity: progress.interpolate({
				inputRange: [0, 1],
				outputRange: [0, 0.5],
				extrapolate: "clamp",
			}),
		},
	}),
	cardOverlayEnabled: true,
	presentation: "transparentModal"
};
