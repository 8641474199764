import * as React from "react";
import { FlatList, StyleSheet, TextInput, View } from "react-native";
import { connect } from "react-redux";
import { fetchMessages, sendDirectMessage, editMessage } from "../../store/slices/directMessagesSlice";
import { idKeyExtractor, isWeb, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import Message from "./Message";
import { colors } from "../../styles/colors";
import { _ } from "../../i18n/i18n";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import IconButton from "../generic/buttons/IconButton";
import { setDirectMessageInput, setEditingMessage } from "../../store/slices/directMessageUISlice";
import { cStyle, usePrevious } from "../../tools/react";
import { groupIsRead, getInterlocutor } from "../../tools/directMessages";
import { apiFetch } from "../../network/apiFetch";
import { receiveMainUser } from "../../store/slices/usersSlice";
import NewContentSeparator from "../lines/NewContentSeparator";
import EmptyMessageGroup from "./EmptyMessageGroup";
import { fetchFriendIds } from "../../store/slices/friendsSlice";
import { fetchMessageGroup, removeMessageGroup } from "../../store/slices/messageGroupsSlice";
import OverflowMenu from "../generic/OverflowMenu";
import BoxModal from "../generic/modal/BoxModal";
import AppText from "../generic/AppText";
import { getUsername } from "../../tools/account";
import ErrorLoading from "../errors/ErrorLoading";
import { gameScreenPadding } from "../../styles/dynamicStyles";
import { useLayout } from "react-native-web-hooks";
import { Header, HeaderTitle } from "@react-navigation/stack";

let lastKeyCreated = 0;

import { BuildStyleMethod } from "../../styles/theming";

function MessageGroupScreen({ route, navigation, dispatch, user, messages, savedInput, editingMessage, group }) {
	const styles = stylesMethod(global.theme);
	const [input, setinput] = React.useState(savedInput);
	const [inputFocused, setinputFocused] = React.useState(false);
	const [unreadInGroup, setunreadInGroup] = React.useState([]);
	const [refreshing, setrefreshing] = React.useState(!messages.length);

	const lastEditingMessage = usePrevious(editingMessage);
	const textInputRef = React.useRef();
	const confirmUnfriendModal = React.useRef();
	const flatlistRef = React.useRef();

	if (!group) {
		return <ErrorLoading />;
	}

	const interlocutor = React.useMemo(() => getInterlocutor(group), [group]);

	const fetchLatest = React.useCallback(async () => {
		setrefreshing(true);
		await dispatch(fetchMessages(group.id, { to: "last" }));
		setrefreshing(false);
	}, []);

	React.useEffect(() => {
		fetchLatest();
	}, [fetchLatest]);

	React.useEffect(() => {
		const options = [];
		options.push({
			title: _("Un-friend"),
			onPress: () => confirmUnfriendModal.current.show(),
			danger: true,
			icon: { type: MaterialCommunityIcons, name: "account-remove" },
		});
		navigation.setOptions({
			headerRight: () => <OverflowMenu options={options} forceOverflow />,
		});
	}, []);

	React.useEffect(() => {
		if (!groupIsRead(group, user)) {
			setTimeout(() => {
				apiFetch(`users/${user.id}/message-groups/${group.id}/mark-read`, "POST").then((updatedUser) => {
					if (updatedUser) {
						dispatch(receiveMainUser(updatedUser));
					}
				});
			}, 1000);
		}
	}, [group, user, messages]);

	React.useEffect(() => {
		if (interlocutor?.username) {
			navigation.setOptions({
				title: _("%(username)s messages | Role Gate", "title for direct message page with user", {
					username: interlocutor.username,
				}),
			});
		}
	}, [interlocutor]);

	React.useEffect(() => {
		if (editingMessage && lastEditingMessage !== editingMessage) {
			setinput(editingMessage.content);
			// must use a timeout because react navigation has a bug that won't let you focus immediately after navigating back.
			// https://github.com/react-navigation/react-navigation/issues/8564
			setTimeout(() => textInputRef.current.focus(), 200);
		}
	}, [editingMessage]);

	React.useEffect(() => {
		const newUnreadInGroup = user.unread_messages
			.filter((m) => messages.some((mes) => mes.id === m))
			.sort((a, b) => a - b);
		if (messages.length && !unreadInGroup.length && newUnreadInGroup.length) {
			setunreadInGroup(newUnreadInGroup);
		}
	}, [messages, unreadInGroup.length]);

	React.useEffect(() => {
		const removeScreenFocusListener = navigation.addListener("blur", () => {
			dispatch(setDirectMessageInput({ groupId: group.id, input }));
		});
		return () => {
			removeScreenFocusListener();
		};
	}, [group.id, input]);

	const unfriend = React.useCallback(() => {
		navigation.goBack();
		dispatch(removeMessageGroup(group));
		if (interlocutor) {
			apiFetch(`profiles/${user.id}/unfriend`, "POST", {
				profile: interlocutor.id,
			}).then(() => {
				dispatch(fetchFriendIds(user.id));
			});
		} else {
			// if the interlocutor has deleted their account
			apiFetch(`users/${user.id}/message-groups/${group.id}`, "DELETE");
		}
	}, [user.id, interlocutor?.id, group]);

	const sendMessage = React.useCallback(() => {
		if (!input) return;
		setunreadInGroup([]);
		if (editingMessage) {
			setinput(savedInput);
			dispatch(editMessage({ ...editingMessage, content: input }));
			dispatch(setEditingMessage({ groupId: group.id, message: null }));
		} else {
			dispatch(
				sendDirectMessage({
					user: user.id,
					key: `userCreated_${lastKeyCreated++}`,
					group: group.id,
					content: input,
				})
			);
			setinput("");
		}
	}, [group.id, input, user.id, lastKeyCreated]);

	const cancelEditMessage = React.useCallback(() => {
		dispatch(setEditingMessage({ groupId: group.id, message: null }));
		setinput(savedInput);
	}, []);

	const loadMore = React.useCallback(() => {
		if (!messages.length) return;
		dispatch(fetchMessages(group.id, { to: messages[0].id }));
	}, [messages, group.id]);

	const handleChange = React.useCallback((e) => {
		e.target.style.height = 0;
		const height = Math.min(e.target.scrollHeight, 300);
		e.target.style.height = `${height}px`;
	}, []);

	const handleKey = React.useCallback(
		(e) => {
			const {
				nativeEvent: { key: keyValue },
			} = e;

			switch (keyValue) {
				case "Enter":
					if (e.shiftKey) return;
					e.preventDefault();
					sendMessage();
					break;
				default:
					break;
			}
		},
		[sendMessage]
	);

	React.useEffect(() => {
		if (!isWeb()) return () => null;
		const invertedWheelEvent = (e) => {
			if (flatlistRef.current) {
				flatlistRef.current.getScrollableNode().scrollTop -= e.deltaY;
				e.preventDefault();
			}
		};
		flatlistRef.current?.getScrollableNode().addEventListener("wheel", invertedWheelEvent);

		return () => flatlistRef.current?.getScrollableNode().removeEventListener("wheel", invertedWheelEvent);
	}, []);

	const reversedMessages = React.useMemo(() => messages.slice().reverse(), [messages]);

	const { onLayout } = useLayout();

	return (
		<AppScreenView borderless onLayout={onLayout}>
			<FlatList
				ref={flatlistRef}
				inverted={!!reversedMessages.length}
				data={reversedMessages}
				contentContainerStyle={[styles.contentContainer, ws({ paddingHorizontal: gameScreenPadding() })]}
				keyExtractor={idKeyExtractor}
				renderItem={({ item, index }) => (
					<Message
						message={item}
						index={index}
						prevMessage={reversedMessages.rg_next(index)}
						nextMessage={reversedMessages.rg_prev(index)}
					/>
				)}
				ItemSeparatorComponent={({ leadingItem }) => {
					if (unreadInGroup.length && leadingItem.id === unreadInGroup[0]) {
						return <NewContentSeparator text={_("New messages", "new direct messages")} />;
					}
					return null;
				}}
				onEndReached={loadMore}
				ListEmptyComponent={refreshing ? null : <EmptyMessageGroup group={group} />}
			/>
			<View
				style={[
					styles.bottomContainer,
					ws({ paddingHorizontal: gameScreenPadding() }),
					inputFocused && { maxHeight: 200 },
				]}
			>
				<View style={styles.inputContainer}>
					<TextInput
						ref={textInputRef}
						autoFocus={isWeb()}
						style={[styles.input, inputFocused && styles.inputFocused]}
						value={input}
						onChangeText={setinput}
						placeholder={_("Type a message...", "placeholder for direct messages")}
						// cannot change this value dynamically on iOS without losing focus. So keep it always true
						// https://github.com/facebook/react-native/issues/29907
						multiline
						onChange={isWeb() ? handleChange : null}
						onKeyPress={isWeb() ? handleKey : null}
						onFocus={() => setinputFocused(true)}
						onBlur={() => setinputFocused(false)}
						placeholderTextColor={global.colors.placeholder}
					/>
					{!!editingMessage && (
						<IconButton
							icon={{
								type: MaterialIcons,
								name: "clear",
								color: global.colors.textDefault,
							}}
							transparent
							style={{ height: 24 }}
							onPress={cancelEditMessage}
						/>
					)}
				</View>
				<IconButton
					icon={{ type: MaterialIcons, name: "send", color: global.colors.textLight }}
					style={{ height: 38, width: 38, marginLeft: 8 }}
					onPress={sendMessage}
				/>
			</View>
			<BoxModal
				ref={confirmUnfriendModal}
				title={_("Un-friend")}
				isDelete
				message={
					<AppText>
						{_(
							"Do you want to un-friend %(username)s? You will lose all private messages exchanged this person.",
							"",
							{
								username: (
									<AppText key="u" bold>
										{getUsername(interlocutor)}
									</AppText>
								),
							}
						)}
					</AppText>
				}
				onConfirm={unfriend}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { groupId } = ownProps.route.params;

	return {
		user: state.user,
		group: state.messageGroups[groupId],
		messages: state.directMessages[groupId] || Array.rg_empty,
		savedInput: state.directMessageUI[groupId]?.input,
		editingMessage: state.directMessageUI[groupId]?.editingMessage,
	};
};

export default connect(mapStateToProps)(MessageGroupScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	contentContainer: {
		flexGrow: 1,
		paddingBottom: 16,
	},
	bottomContainer: {
		flexDirection: "row",
		alignItems: "flex-end",
		marginHorizontal: 8,
		marginBottom: 16,
	},
	inputContainer: {
		flex: 1,
		borderWidth: 1,
		borderRadius: 18,
		paddingVertical: 4,
		paddingHorizontal: 8,
		flexDirection: "row",
		backgroundColor: colors.inputFieldBackground,
		borderColor: colors.inputFieldBackground,
		minHeight: 38,
	},
	input: {
		flex: 1,
		color: colors.textDefault,
		maxHeight: 24,
		// outlineWidth crashes on mobile
		...ws({ outlineWidth: 0, paddingTop: 4, paddingLeft: 8 }, null),
	},
	inputFocused: {
		maxHeight: 200,
	},
}));
