import { getDefaultHeaderHeight, HeaderHeightContext, useHeaderHeight } from "@react-navigation/elements";
import * as React from "react";
import { ScrollView, StatusBar, StyleSheet, View, KeyboardAvoidingView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { isIos } from "../tools/generic";

class AppScreenView extends React.PureComponent {
	render() {
		let {
			children,
			tight,
			borderless,
			borderTop,
			style,
			useSafeArea,
			scroll,
			onScroll,
			keyboardShouldPersistTaps,
			scrollViewRef,
			stickyHeaderIndices,
			onLayout,
			scrollEventThrottle,
		} = this.props;

		const baseStyle = [styles.base];
		tight && baseStyle.push(styles.tight);
		borderless && baseStyle.push(styles.borderless);
		borderTop && baseStyle.push(styles.borderTop);
		let headerHeight = this.context || 0;

		const ViewTag = useSafeArea ? SafeAreaView : isIos() ? KeyboardAvoidingView : View;

		let component = (
			<ViewTag
				onLayout={onLayout}
				style={[baseStyle, style]}
				behavior="padding"
				keyboardVerticalOffset={headerHeight}
			>
				{children}
			</ViewTag>
		);

		if (isIos()) {
			// Adding the onLayout props, NO MATTER THE VALUE, break the keyboardavoidingView on ios
			component = (
				<HeaderHeightContext.Consumer>
					{(headerHeightReceived) => (
						<ViewTag style={[baseStyle, style]} behavior="padding" keyboardVerticalOffset={headerHeightReceived}>
							{children}
						</ViewTag>
					)}
				</HeaderHeightContext.Consumer>
			);
		}

		if (scroll) {
			return (
				<ScrollView
					ref={scrollViewRef}
					contentContainerStyle={{ flexShrink: 0, flexGrow: 1 }}
					keyboardShouldPersistTaps={keyboardShouldPersistTaps}
					onScroll={onScroll}
					stickyHeaderIndices={stickyHeaderIndices}
					scrollEventThrottle={scrollEventThrottle}
				>
					{component}
				</ScrollView>
			);
		}

		return component;
	}
}

export default AppScreenView;

const styles = StyleSheet.create({
	base: { paddingHorizontal: 16, paddingTop: 8, flex: 1 },
	tight: { paddingHorizontal: 8 },
	borderless: { paddingHorizontal: 0, paddingTop: 0 },
	borderTop: { paddingTop: 16 },
});
