import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { isGM } from "../../tools/games";
import { isAndroid } from "../../tools/generic";
import AddStoryMarkerButton from "../storymarkers/AddStoryMarkerButton";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import LineSeparator from "./LineSeparator";

function LineListTopComponent({
	hasPrevious,
	isPreview,
	isComments,
	lines,
	game,
	useInverted,
	loadTop,
	newContentBookmark,
	loadingMoreLines,
}) {
	if (loadingMoreLines) return null;

	return (
		<>
			{!hasPrevious && !isPreview && !isComments && isGM() && (
				<>
					<AddStoryMarkerButton gameId={game.id} useInverted={useInverted} />
				</>
			)}
			{hasPrevious && !!lines.length && (
				<TouchableOpacity
					onPress={loadTop}
					style={[{ alignItems: "center", padding: 4 }, [useInverted && isAndroid() && { scaleY: -1 }]]}
				>
					<AppText color="primary" centered>
						{_("Load more", "load more lines")}
					</AppText>
				</TouchableOpacity>
			)}
			{hasPrevious || isComments ? null : (
				<LineSeparator
					isAtTop
					lines={lines}
					inverted={useInverted}
					leadingItem={useInverted ? lines.rg_first() : null}
					trailingItem={useInverted ? null : lines.rg_first()}
					newContentBookmark={newContentBookmark}
				/>
			)}
		</>
	);
}

export default LineListTopComponent;

const styles = StyleSheet.create({
	container: {},
});
