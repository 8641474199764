import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { _ } from "../../i18n/i18n";
import OpenAIPlaygroundScreen from "../openai/OpenAIPlaygroundScreen";
import { isIos, isWeb } from "../../tools/generic";
import AboutRGScreen from "../about/AboutRGScreen";
import AccountLevelText from "../account/AccountLevelText";
import DiscordSettingsScreen from "../account/DiscordSettingsScreen";
import UpgradeAccountScreen from "../account/UpgradeAccountScreen";
import AppScreenView from "../AppScreenView";
import AppDisplaySettings from "../appsettings/AppDisplaySettings";
import AppGameSettingsScreen from "../appsettings/AppGameSettingsScreen";
import AppSettingsScreen from "../appsettings/AppSettingsScreen";
import DeleteAccountScreen from "../appsettings/DeleteAccountScreen";
import EmailsSettingsScreen from "../appsettings/EmailsSettingsScreen";
import LocaleSettings from "../appsettings/LocaleSettings";
import NotificationSettingsScreen from "../appsettings/NotificationSettingsScreen";
import AvatarRequestTermsAndConditions from "../avatar/avatarrequest/AvatarRequestTermsAndConditions";
import AvatarRequestWizardStack from "../avatar/avatarrequest/AvatarRequestWizardStack";
import BugReportScreen from "../bugreport/BugReportScreen";
import FilteredCharacterList from "../characters/FilteredCharacterList";
import NotificationsScreen from "../coins/NotificationsScreen";
import DevSettingsScreen from "../debug/DevSettingsScreen";
import DiceSetStack from "../dicesets/DiceSetStack";
import MessagesStack from "../directmessages/MessagesStack";
import VoteFeatureScreen from "../feature/VoteFeatureScreen";
import CreateGameWizardStack from "../games/creategamewizard/CreateGameWizardStack";
import FindGameWizardStack from "../games/findgamewizard/FindGameWizardStack";
import MyGamesScreen from "../games/MyGamesScreen";
import PlayGameWizardStack from "../games/playgamewizard/PlayGameWizardStack";
import IconButton from "../generic/buttons/IconButton";
import FullScreenTextEdit from "../generic/FullScreenTextEdit";
import TwoLinesHeader from "../generic/TwoLinesHeader";
import InteractiveHelpersStack from "../interactivehelpers/InteractiveHelpersStack";
import CreateTagScreen from "../tags/CreateTagScreen";
import HomeNavigator from "./HomeNavigator";
import OpenAIGeneratorScreen from "../openai/OpenAIGeneratorScreen";
import CreateAlertScreen from "../gamealerts/CreateAlertScreen";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";

export default function LoggedNavigationScreens(Stack, user) {
	return (
		<>
			{!isWeb() && (
				<Stack.Screen
					name="Home"
					component={HomeNavigator}
					options={({ route, navigation }) => {
						const openedTabName = getFocusedRouteNameFromRoute(route);
						const SearchButton = (
							<IconButton
								icon={{ type: MaterialIcons, name: "search" }}
								onPress={() => navigation.navigate("BrowseGamesScreen", { searching: true })}
								transparent
							/>
						);

						return {
							headerTitle: tabNameToLabel(openedTabName),
							headerRight: () => (openedTabName === "Explore" ? SearchButton : null),
						};
					}}
				/>
			)}

			{isWeb() && (
				<>
					<Stack.Screen
						name="MyGames"
						component={MyGamesScreen}
						options={{ title: _("My games | Role Gate") }}
					/>
					<Stack.Screen
						name="Bookmarks"
						options={{ title: _("Bookmarks | Role Gate") }}
						children={(props) => <MyGamesScreen isBookmarks {...props} />}
					/>
				</>
			)}

			<Stack.Screen name="MessagesStack" component={MessagesStack} options={{ headerShown: false }} />

			<Stack.Screen
				name="CreateGameTagsScreen"
				component={CreateTagScreen}
				options={({ route, navigation }) => ({
					headerTitle: _("Select or create tags", "select game tags"),
					title: "Role Gate",
					unmountOnBlur: isWeb(),
				})}
			/>

			<Stack.Screen
				name="QuickCharacterSearchScreen"
				children={() => (
					<AppScreenView scroll useSafeArea={!isIos()} borderless borderTop>
						<FilteredCharacterList fullScreen wide />
					</AppScreenView>
				)}
				options={{
					headerTitle: _("Select character", "menu title"),
					headerShown: isIos() || isWeb(),
					title: "Role Gate",
				}}
			/>

			<Stack.Screen
				name="CreateAlertScreen"
				component={CreateAlertScreen}
				options={({ route, navigation }) => ({
					headerTitle: _("Create alert"),
					title: _("Create alert | Role Gate"),
				})}
			/>

			<Stack.Screen
				name="CreateGameWizardStack"
				component={CreateGameWizardStack}
				options={({ route, navigation }) => ({
					headerTitle: _("Create game"),
					unmountOnBlur: isWeb(),
					title: _("Create game | Role Gate"),
				})}
			/>
			<Stack.Screen
				name="FindGameWizardStack"
				component={FindGameWizardStack}
				options={({ route, navigation }) => ({
					headerTitle: _("Find a game"),
					unmountOnBlur: isWeb(),
					title: _("Find a game | Role Gate"),
				})}
			/>
			<Stack.Screen
				name="PlayGameWizardStack"
				component={PlayGameWizardStack}
				options={({ route, navigation }) => ({
					headerTitle: _("Play", "start playing wizard"),
					title: _("Play | Role Gate"),
				})}
			/>

			<Stack.Screen
				name="InteractiveHelpersStack"
				component={InteractiveHelpersStack}
				options={({ route, navigation }) => ({ headerShown: false, title: "Role Gate" })}
			/>
			<Stack.Screen
				name="DiceSetStack"
				component={DiceSetStack}
				options={({ route, navigation }) => ({
					headerShown: false,
					title: _("Dice sets | Role Gate"),
					headerTitle: _("Dice sets", "menu title"),
				})}
			/>
			<Stack.Screen
				name="AvatarRequestWizardStack"
				component={AvatarRequestWizardStack}
				options={{ headerTitle: _("Avatar request"), title: _("Avatar requests | Role Gate") }}
			/>
			<Stack.Screen
				name="AvatarRequestTermsAndConditions"
				component={AvatarRequestTermsAndConditions}
				options={{
					headerTitle: _("Terms & conditions", "avatar terms and conditions"),
					title: _("Terms & conditions | Role Gate"),
				}}
			/>

			<Stack.Screen
				name="NotificationsScreen"
				component={NotificationsScreen}
				options={{ headerTitle: _("Notifications", "screen title"), title: "Notifications | Role Gate" }}
			/>

			<Stack.Screen
				name="VoteFeatureScreen"
				component={VoteFeatureScreen}
				options={{
					headerTitle: _("Vote for features", "screen title"),
					title: _("Vote for features | Role Gate"),
				}}
			/>
			<Stack.Screen
				name="EditBio"
				options={{ headerTitle: _("Bio"), title: "Role Gate" }}
				component={FullScreenTextEdit}
			/>
			<Stack.Screen
				name="UpgradeAccountScreen"
				options={{
					headerTitle: () => (
						<TwoLinesHeader
							title={_("Subscription", "screen title")}
							subtitle={<AccountLevelText size="small" bold level={user?.profile.reward_level} />}
						/>
					),
					title: _("Subscription | Role Gate"),
				}}
				component={UpgradeAccountScreen}
			/>

			<Stack.Screen
				name="AboutRGScreen"
				options={{ headerTitle: _("About Role Gate"), title: _("About | Role Gate") }}
				component={AboutRGScreen}
			/>
			<Stack.Screen
				name="AppSettingsScreen"
				options={{ headerTitle: _("Settings", "app settings"), title: _("Settings | Role Gate") }}
				component={AppSettingsScreen}
			/>
			<Stack.Screen
				name="EmailsSettingsScreen"
				options={{ headerTitle: _("Emails", "app settings"), title: _("Emails settings | Role Gate") }}
				component={EmailsSettingsScreen}
			/>
			<Stack.Screen
				name="AppGameSettingsScreen"
				options={{ headerTitle: _("Games", "app settings"), title: _("Games settings | Role Gate") }}
				component={AppGameSettingsScreen}
			/>
			<Stack.Screen
				name="LocaleSettings"
				options={{ headerTitle: _("Language", "app settings"), title: _("Language settings | Role Gate") }}
				component={LocaleSettings}
			/>
			<Stack.Screen
				name="AppDisplaySettings"
				options={{ headerTitle: _("Display", "app settings"), title: _("Display settings | Role Gate") }}
				component={AppDisplaySettings}
			/>
			<Stack.Screen
				name="NotificationSettingsScreen"
				options={{
					headerTitle: _("Notifications", "app settings"),
					title: _("Notifications settings | Role Gate"),
				}}
				component={NotificationSettingsScreen}
			/>
			<Stack.Screen
				name="BugReportScreen"
				options={{ headerTitle: _("Bug report", "app settings"), title: _("Bug report | Role Gate") }}
				component={BugReportScreen}
			/>
			<Stack.Screen
				name="DiscordSettingsScreen"
				options={{
					headerTitle: _("Discord", "app discord settings"),
					title: _("Discord settings | Role Gate"),
				}}
				component={DiscordSettingsScreen}
			/>
			<Stack.Screen
				name="DevSettingsScreen"
				options={{ headerTitle: "Dev tools" }}
				component={DevSettingsScreen}
			/>
			<Stack.Screen
				name="DeleteAccountScreen"
				options={{ headerTitle: _("Delete account", "app settings"), title: _("Delete account | Role Gate") }}
				component={DeleteAccountScreen}
			/>
			<Stack.Screen
				name="OpenAIGeneratorScreen"
				component={OpenAIGeneratorScreen}
				options={{ headerTitle: _("AI Generator", "screen title"), title: _("AI Generator | Role Gate") }}
			/>
			<Stack.Screen name="OpenAIPlaygroundScreen" component={OpenAIPlaygroundScreen} />
		</>
	);
}

const tabNameToLabel = (name) => {
	if (!name) return _("My games");
	switch (name) {
		case "MyGames":
			return _("My games");
		case "Bookmarks":
			return _("Bookmarks");
		case "Explore":
			return _("Explore");
		case "Learn":
			return _("Learn");
		default:
			return name;
	}
};
