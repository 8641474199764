import * as React from "react";
import { Image, Linking, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { BuildStyleMethod } from "../../styles/theming";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import Zoom from "react-medium-image-zoom";


function UnderMaintenanceScreen({forceSkip, dispatch, user}) {
	const styles = stylesMethod(global.theme);
	const size = 256;
	return (
		<AppScreenView style={styles.container} useSafeArea borderless>
			<Image
				style={{
					width: "100%",
					height: "100%",
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: -1,
				}}
				source={require("../../../assets/images/splash_screen_background.png")}
			/>
			<AppText bold size="header" style={[styles.text, { marginBottom: 32 }]}>
				{_("We'll be right back!")}
			</AppText>
			<Image
				style={[styles.text, { width: size, height: size, marginBottom: 32 }]}
				source={require("../../../assets/images/Logo.png")}
			/>
			<AppText size="large" bold centered style={styles.text}>
				{_("The app is currently under maintenance.")}
			</AppText>
			<AppText style={styles.text} color="secondary" centered>
				{_("This usually only lasts a few minutes, please hang on!")}
			</AppText>
			<AppText
				color="url"
				centered
				style={[styles.text, { marginTop: 16 }]}
				onPress={() => Linking.openURL("https://discord.com/invite/3E37sQ7")}
			>
				{_("Check out the discord server for more info")}
			</AppText>

			{
				user?.is_staff && <AppButton title="I am from the dev team" onPress={forceSkip} size="large"/>
			}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(UnderMaintenanceScreen);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.cardBackground,
			alignItems: "center",
			justifyContent: "center",
		},
		text: {
			marginHorizontal: 16,
		},
	})
);
