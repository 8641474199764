import * as React from "react";
import { View, StyleSheet } from "react-native";
import CharacterAvatar from "../avatar/CharacterAvatar";
import AppText from "../generic/AppText";
import { proficiencyLevelToText } from "../../tools/languages";
import { getCharName } from "../../tools/characters";

import ButtonsRow from "../generic/buttons/ButtonsRow";
import IconButton from "../generic/buttons/IconButton";

function ProficiencyItem({ character, level, setProficienyLevel }) {
	return (
		<View style={styles.container}>
			<AppText color="hint">{getCharName(character)}</AppText>
			<View style={{flexDirection: "row", alignItems: "flex-end"}}>
				<CharacterAvatar character={character} size={64} />
				<View style={{ marginLeft: 8 }}>
					<AppText color="primary">{proficiencyLevelToText(level)}</AppText>
					{/* prettier-ignore */}
					<ButtonsRow>
						<IconButton icon={{}} toggled={level === 0} onPress={() => setProficienyLevel && setProficienyLevel(0)} />
						<IconButton icon={{}} toggled={level === 1} onPress={() => setProficienyLevel && setProficienyLevel(1)} />
						<IconButton icon={{}} toggled={level === 2} onPress={() => setProficienyLevel && setProficienyLevel(2)} />
						<IconButton icon={{}} toggled={level === 3} onPress={() => setProficienyLevel && setProficienyLevel(3)} />
					</ButtonsRow>
				</View>
			</View>
		</View>
	);
}

export default ProficiencyItem;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 8,
	},
});
