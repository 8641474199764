import * as React from "react";
import { ActivityIndicator, View } from "react-native";
import { colors } from "../../styles/colors";
import AppFlatList from "../generic/AppFlatList";
import Line from "./Line";
import PlaceholderLine from "./PlaceholderLine";

function IsolatedLineList({ lines, game }) {
	
	const ids = [];
	for (let i = 0; i < 5; i++) {
		ids.push(i);
	}
	return (
		<AppFlatList
			scrollEnabled={false}
			data={lines}
			renderItem={({ item, index, separators }) => (
				<Line
					key={item.id}
					line={item}
					prevLine={lines.rg_prev(index)}
					nextLine={lines.rg_next(index)}
					isPreview
				/>
			)}
			ListEmptyComponent={
				<View>
					<ActivityIndicator style={{ flex: 1, position: "absolute", width: "100%", height: "100%" }} color={global.colors.hint} />
					{ids.map((id) => (
						<PlaceholderLine key={id} />
					))}
				</View>
			}
			contentContainerStyle={{
				flexGrow: 1,
				justifyContent: "flex-end",
			}}
			style={{ flex: 1 }}
		/>
	);
}

export default IsolatedLineList;
