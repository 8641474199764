import { MaterialIcons } from "@expo/vector-icons";
import { HeaderBackButton } from "@react-navigation/elements";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { _ } from "../../i18n/i18n";
import IconButton from "../generic/buttons/IconButton";
import EditInteractiveHelperPanelScreen from "./EditInteractiveHelperPanelScreen";
import EditInteractiveHelperScreen from "./EditInteractiveHelperScreen";
import OpenHelperScreen from "./OpenHelperScreen";
import SelectPanelOptionTargetScreen from "./SelectPanelOptionTargetScreen";

const Stack = createStackNavigator();

function InteractiveHelpersStack() {
	return (
		<Stack.Navigator
			screenOptions={({ navigation }) => ({
				headerTintColor: global.colors.textDefault,
				headerBackTitle: "",
				headerLeft: () => (
					<HeaderBackButton
						onPress={() =>
							navigation.navigate({
								name: "InteractiveHelpersStack",
								params: { screen: "OpenHelperScreen" },
								merge: true,
							})
						}
					/>
				),
			})}
		>
			<Stack.Screen
				name="OpenHelperScreen"
				component={OpenHelperScreen}
				options={({ route, navigation }) => {
					return {
						headerTitle: _("Interactive helpers", "menu title"),
						headerRight: () =>
							route.params?.searching ? (
								<IconButton
									icon={{ type: MaterialIcons, name: "clear" }}
									onPress={() => navigation.setParams({ searching: false })}
									transparent
								/>
							) : (
								<IconButton
									icon={{ type: MaterialIcons, name: "search" }}
									onPress={() => navigation.setParams({ searching: true })}
									transparent
								/>
							),
						title: _("Interactive helpers | Role Gate"),
					};
				}}
			/>
			<Stack.Screen
				name="EditInteractiveHelperScreen"
				component={EditInteractiveHelperScreen}
				options={{
					headerTitle: _("Edit interactive helper", "screen title"),
					title: _("Edit Interactive helper | Role Gate"),
				}}
			/>
			<Stack.Screen
				name="EditInteractiveHelperPanelScreen"
				component={EditInteractiveHelperPanelScreen}
				options={({ navigation }) => ({
					headerTitle: _("Edit panel", "edit interactive helper panel"),
					title: _("Edit Interactive helper panel | Role Gate"),
					headerLeft: () => (
						<HeaderBackButton
							onPress={() => navigation.navigate({ name: "EditInteractiveHelperScreen", merge: true })}
						/>
					),
				})}
			/>
			<Stack.Screen
				name="SelectPanelOptionTargetScreen"
				component={SelectPanelOptionTargetScreen}
				options={{
					headerTitle: _("Select target panel", "select target panel for interactive helper option"),
					title: "Role Gate",
				}}
			/>
		</Stack.Navigator>
	);
}

export default InteractiveHelpersStack;
