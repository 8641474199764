import * as React from "react";
import { View } from "react-native";
import { FlatList, StyleSheet } from "react-native";
import { Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { clearNewLanguages, receiveLanguages } from "../../store/slices/languagesSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import { getLanguageProficiency } from "../../tools/languages";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import ProficiencyItem from "./ProfiencyItem";

const extractProficiency = (characters, language) => {
	return characters
		.filter((c) => !c.is_npc && !c.deleted)
		.map((c) => ({
			key: String(c.id),
			character: c,
			level: getLanguageProficiency(c, language),
		}));
};

function EditLanguageScreen({ route, navigation, dispatch, characters }) {
	const { language } = route.params;
	const [name, setname] = React.useState(language.name);

	const [proficiencies, setproficiencies] = React.useState(extractProficiency(characters, language));

	const setProficiency = React.useCallback(
		(character, level) => {
			const index = proficiencies.findIndex((p) => p.character.id === character.id);
			const prof = proficiencies.slice();
			prof[index] = { ...prof[index], level };
			setproficiencies(prof);
		},
		[proficiencies]
	);

	const saveChanges = React.useCallback(() => {
		const patchData = {
			name,
			beginners: proficiencies.filter((p) => p.level === 1).map((p) => p.character.id),
			intermediates: proficiencies.filter((p) => p.level === 2).map((p) => p.character.id),
			fluents: proficiencies.filter((p) => p.level === 3).map((p) => p.character.id),
		};

		if (language.id) {
			apiFetch(`games/${language.game}/languages/${language.id}`, "PATCH", patchData);
			dispatch(receiveLanguages({ gameId: language.game, languages: [{ ...language, ...patchData }] }));
		} else {
			apiFetch(`games/${language.game}/languages`, "POST", patchData).then((created) =>
				dispatch(receiveLanguages({ gameId: language.game, languages: [created] }))
			);
		}

		navigation.goBack();
	}, [language, name, proficiencies]);

	return (
		<>
			<AppScreenView style={ws({ paddingLeft: fullScreenLeftPadding() })}>
				<View style={ws({ maxWidth: fullScreenContentWidth() })}>
					<AppText
						size="large"
						editable
						onTextChanged={setname}
						placeholder={_("Language name", "create fictional language")}
					>
						{name}
					</AppText>

					<Divider style={{ marginVertical: 8 }} />
					<AppText bold>{_("Proficiencies", "language proficiency")}</AppText>
					<FlatList
						data={proficiencies}
						renderItem={({ item }) => (
							<ProficiencyItem
								character={item.character}
								level={item.level}
								setProficienyLevel={(newLevel) => setProficiency(item.character, newLevel)}
							/>
						)}
					/>
				</View>
			</AppScreenView>
			<ButtonFooter
				narrow={false}
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={[
					{
						title: _("Cancel"),
						onPress: () => navigation.goBack(),
					},
					{ title: language.id ? _("Save") : _("Create"), onPress: saveChanges },
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { language } = ownProps.route.params;
	return {
		characters: state.charactersByGame[language.game],
	};
};

export default connect(mapStateToProps)(EditLanguageScreen);
