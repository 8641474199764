import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import CharacterAvatar from "../avatar/CharacterAvatar";
import IconButton from "../generic/buttons/IconButton";

const avatarSize = 64;

function CharactersRow({ style, characters, showNames }) {
	const [currentIndex, setcurrentIndex] = React.useState(0);
	const [hasOverflow, sethasOverflow] = React.useState(false);

	const flastList = React.useRef();

	const navigation = useNavigation();

	const goNext = React.useCallback(() => {
		const newIndex = characters.rg_nextIndex(currentIndex, true, true);
		flastList.current.scrollToIndex({ animated: true, index: newIndex });
		setcurrentIndex(newIndex);
	}, [currentIndex, characters]);

	const goPrevious = React.useCallback(() => {
		const newIndex = characters.rg_prevIndex(currentIndex, true, true);
		flastList.current.scrollToIndex({ animated: true, index: newIndex });
		setcurrentIndex(newIndex);
	}, [currentIndex, characters]);

	// onScroll is the only way to get the actual content size. onContentSizechange will give a width of 0 for some reason.
	const onScroll = React.useCallback(
		({
			nativeEvent: {
				contentSize: { width: contentWidth },
				layoutMeasurement: { width: layoutWidth },
			},
		}) => {
			const newHasOverflow = contentWidth > layoutWidth;
			if (newHasOverflow !== hasOverflow) {
				sethasOverflow(newHasOverflow);
			}
		},
		[hasOverflow]
	);

	const getItemLayout = React.useRef((data, index) => ({ length: avatarSize, offset: avatarSize * index, index }));

	if (!characters.length) return null;

	return (
		<Hoverable>
			{(isHovered) => (
				<View style={[styles.container]}>
					<IconButton
						transparent
						icon={{ type: MaterialCommunityIcons, name: "arrow-left-bold", color: global.colors.textLight }}
						onPress={goPrevious}
						style={{ opacity: isHovered && hasOverflow ? 1 : 0 }}
					/>
					<FlatList
						onLayout={() => {
							// Work around the fact that onContentSizechange does not show the width of the content.
							// This will force call onScroll.
							if (!hasOverflow && currentIndex === 0)
								flastList.current?.scrollToOffset({ animated: true, offset: 1 });
						}}
						ref={flastList}
						contentContainerStyle={[style, { paddingBottom: 16 }]}
						data={characters}
						renderItem={({ item }) => (
							<CharacterAvatar
								loadName
								showName={showNames}
								showTokens
								character={item}
								size={avatarSize}
								onPress={() => navigation.navigate("CharacterOptionsModal", { characterId: item.id })}
								line={global.currentLine}
							/>
						)}
						keyExtractor={(item) => String(item.id)}
						horizontal
						showsHorizontalScrollIndicator={false}
						onScroll={onScroll}
						getItemLayout={getItemLayout.current}
					/>
					<IconButton
						transparent
						icon={{
							type: MaterialCommunityIcons,
							name: "arrow-right-bold",
							color: global.colors.textLight,
						}}
						onPress={goNext}
						style={{ opacity: isHovered && hasOverflow ? 1 : 0 }}
					/>
				</View>
			)}
		</Hoverable>
	);
}

export default CharactersRow;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
	},
	avatarContainer: {
		width: avatarSize,
		height: avatarSize,
	},
});
