//import Clipboard from "@react-native-community/clipboard";
// import { Clipboard } from "react-native"
import * as Clipboard from 'expo-clipboard';
import * as React from "react";
import { Share, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { isWeb, ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ListSwitch from "../generic/ListSwitch";
import ModalScreen from "../generic/modal/ModalScreen";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import HelpButton from "../tutorials/HelpButton";

const MAX_DESCRIPTION_LENGTH = 350;

function ShareGameModal({ route, navigation, dispatch, game, username }) {

	const [useReferral, setuseReferral] = React.useState(true);
	const copiedModalRef = React.useRef();

	const onShare = React.useCallback(async () => {
		const url = useReferral
			? `https://www.rolegate.com/ref/${username}/${game.slug}`
			: `https://www.rolegate.com/${game.slug}`;
		let message = `Role Gate | ${game.name}`;

		message += "\n";
		message += url;
		message += "\n\n";

		let description = game.description;
		// if (description.length > MAX_DESCRIPTION_LENGTH)
		// 	description = description.substr(0, MAX_DESCRIPTION_LENGTH - 3) + "...";
		message += description;

		if (isWeb()) {
			Clipboard.setStringAsync(url);
			copiedModalRef.current.show();
		} else {
			try {
				Share.share({ title: game.name, message, url }, { dialogTitle: game.name });
			} catch (error) {
				// alert(error.message);
			}
		}
	}, [useReferral, game.name, game.slug]);
	
	return (
		<ModalScreen title={_("Share", "share game")}>
			<View style={{ flexDirection: "row" }}>
				<ListSwitch
					style={{ flex: 1 }}
					title={_("Use referral link")}
					isEnabled={useReferral}
					setIsEnabled={setuseReferral}
				/>
				<HelpButton
					style={{ marginLeft: 8 }}
					title={_("Referral link")}
					message={
						<>
							<AppText>
								{_(
									"Every person who signs up to Role Gate after following your referral link will count towards your reward."
								)}
							</AppText>
							<AppText style={{ marginTop: 16 }}>
								{_(
									"You receive one month of free upgrade for 10 referrals, and a permanent upgrade for 100 referrals."
								)}
							</AppText>
						</>
					}
				/>
			</View>
			<AppButton title={ws(_("Copy link", "share link to game"), _("Share", "share game"))} onPress={onShare} />
			<TinyFeedbackModal ref={copiedModalRef} message={_("Copied to clipboard!")} />
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameslug } = ownProps.route.params;
	
	return {
		username: state.user?.username,
		game: state.games[gameslug]
	};
};

export default connect(mapStateToProps)(ShareGameModal);


