import * as React from "react";
import { SectionList, StyleSheet, View, ActivityIndicator } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import {
	clearPublicDiceSets,
	fetchNextPublicDiceSets,
	fetchPublicDiceSets,
	fetchUserSets,
	createDiceSet,
} from "../../store/slices/dicesetsSlice";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import SearchBar from "../generic/Searchbar";
import DiceSetItem from "./DiceSetItem";
import { colors } from "../../styles/colors";
import { fullScreenLeftPadding, fullScreenContentWidth } from "../../styles/dynamicStyles";
import { usePrevious } from "../../tools/react";
import { useLayout } from "react-native-web-hooks";

import { BuildStyleMethod } from "../../styles/theming";

function BrowseDiceSetsScreen({ route, navigation, dispatch, userId, userSets, publicSets, nextUrl }) {
	const styles = stylesMethod(global.theme);
	const confirmCreateModalRef = React.useRef();

	const [creating, setcreating] = React.useState(false);
	const [searching, setsearching] = React.useState(false);
	const [currentSearch, setcurrentSearch] = React.useState(null);

	const selectingSetFor = route.params?.selectingSetFor;

	React.useEffect(() => {
		if (!userSets.length) {
			dispatch(fetchUserSets(userId));
		}
	}, [userSets.length, userId]);

	React.useEffect(() => {
		if (!publicSets.length && !currentSearch) {
			dispatch(fetchPublicDiceSets());
		}
	}, [publicSets.length, currentSearch]);

	const fetchNext = React.useCallback(() => {
		if (!nextUrl) return;
		if (searching) return;
		dispatch(fetchNextPublicDiceSets(nextUrl));
	}, [nextUrl, searching]);

	const create = React.useCallback(async () => {
		setcreating(true);
		const newSet = await dispatch(createDiceSet());
		setcreating(false);
		navigation.navigate("EditDiceSetScreen", { diceSet: newSet });
	}, []);

	const performSearch = React.useCallback(async () => {
		const params = {};
		if (currentSearch) {
			params.search = currentSearch;
		}

		setsearching(true);
		await dispatch(clearPublicDiceSets());
		// Try catch so that await doesn't last forever when query is cancelled
		try {
			await dispatch(fetchPublicDiceSets(params));
		} catch (e) {}
		setsearching(false);
	}, [currentSearch]);

	React.useEffect(() => {
		if (currentSearch) {
			const searchTimeout = setTimeout(performSearch, 1000);
			return () => clearTimeout(searchTimeout);
		} else if (currentSearch !== null) {
			performSearch();
		}
	}, [currentSearch]);

	if (currentSearch) {
		userSets = userSets.filter((s) => s.name.toLowerCase().includes(currentSearch.toLowerCase()));
	}
	const renderDiceSet = React.useCallback(
		({ item }) => (
			<DiceSetItem
				diceSet={item}
				onPress={() => navigation.navigate("EditDiceSetScreen", { diceSet: item, selectingSetFor })}
			/>
		),
		[]
	);

	const sectionsData = [
		{
			private: true,
			title: _("Your sets", "show user's dice sets"),
			data: userSets,
		},
		{
			title: _("Community sets", "show community dice sets"),
			data: publicSets,
		},
	];

	const { onLayout } = useLayout();
	
	return (
		<AppScreenView
			onLayout={onLayout}
			borderless
			style={ws({
				paddingLeft: fullScreenLeftPadding(),
			})}
		>
			<SearchBar
				key="search_bar"
				onSearchUpdated={setcurrentSearch}
				style={[{ margin: 8 }, ws({ maxWidth: fullScreenContentWidth() })]}
			/>
			<SectionList
				contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
				keyExtractor={idKeyExtractor}
				sections={sectionsData}
				renderSectionHeader={({ section: { title } }) => <AppText style={styles.title}>{title}</AppText>}
				renderItem={renderDiceSet}
				onEndReachedThreshold={0.2}
				onEndReached={fetchNext}
				stickySectionHeadersEnabled={true}
				renderSectionFooter={({ section }) => {
					if (!section.data.length) {
						if (section.private) {
							return (
								<AppText color="hint" centered style={{ marginVertical: 16 }}>
									{_("Press + to create your own dice set.", "empty dice sets list message")}
								</AppText>
							);
						} else if (searching || !currentSearch) {
							return <ActivityIndicator color={global.colors.hint}/>;
						} else {
							return (
								<AppText color="hint" centered style={{ marginVertical: 16 }}>
									{_("No results", "empty dice sets list message")}
								</AppText>
							);
						}
					}
					return null;
				}}
				stickyHeaderIndices={currentSearch ? [0] : []}
				// Workaround for crash regarding stickyHeaderIndices on android
				// https://github.com/facebook/react-native/issues/25157#issuecomment-500833326
				removeClippedSubviews={false}
			/>
			<FAB
				icon="plus"
				style={globalStylesMethod(global.theme).fab}
				onPress={() => confirmCreateModalRef.current.show()}
				disabled={creating}
			/>
			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create dice set")}
				message={_("Create a new dice set?")}
				onConfirm={create}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const userId = state.user?.id;

	return {
		userId,
		userSets: state.diceSets.users[userId] || Array.rg_empty,
		publicSets: state.diceSets.public,
		nextUrl: state.diceSets.publicNextUrl,
	};
};

export default connect(mapStateToProps)(BrowseDiceSetsScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	title: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		backgroundColor: colors.cardBackground,
	},
}));
