import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import CoinHistoryModal from "./CoinHistoryModal";
import GiveCoinModal from "./GiveCoinModal";
import { modalStackNavigatorScreenOptions } from "../../tools/navigationOptions";
import { _ } from '../../i18n/i18n';

const Stack = createStackNavigator();

function CoinsModalStack(Stack) {
	return (
		<Stack.Group screenOptions={modalStackNavigatorScreenOptions}>
			<Stack.Screen name="CoinHistory" component={CoinHistoryModal} options={{title: _("Coin history | Role Gate")}} />
			<Stack.Screen name="GiveCoin" component={GiveCoinModal} options={{title: _("Give coin | Role Gate")}} />
		</Stack.Group>
	);
}

export default CoinsModalStack;
