import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { _ } from "../../../i18n/i18n";
import AppText from '../../generic/AppText';
import AppScreenView from '../../AppScreenView';
import ButtonFooter from '../../generic/buttons/ButtonFooter';
import { navReplace } from '../../../tools/webnavigation';
import RGIcon from '../../RGIcon';
import { colors } from '../../../styles/colors';

function WizardPlayerScreen({route, navigation}) {

	return (
		<>
			<AppScreenView scroll> 
				<AppText bold centered size="large">
					{_("Player", "option play as a player (as opposed to a game master)")}
				</AppText>
				<View style={styles.iconContainer}>
						<RGIcon name="die" color={global.colors.textDefault} size={128} />
					</View>
				<AppText style={styles.text} size="large">
					{_("As a player, you will join a game created by a Game Master, with a character representing you.")}
				</AppText>
				<AppText style={styles.text} size="large" color="secondary">
					{_(
						"The Game Master will describe a situation and ask how the players' characters want to act in response."
					)}
				</AppText>
				<AppText style={styles.text} size="large" color="secondary">
					{_(
						"Once you wrote what your character does, the Game Master will describe the consequences of your action, and the game progresses."
					)}
				</AppText>
				<AppText style={styles.text} size="large" color="secondary">
					{_(
						"Most of the times, you will have to roll some dice to determine if your character succeeds or not."
					)}
				</AppText>
			</AppScreenView>
			<ButtonFooter
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.goBack() },
					{ title: _("Let's find a game!"), onPress: () => navReplace(navigation, "FindGameWizardStack") },
				]}
			/>
		</>
	);
}

export default WizardPlayerScreen;

const styles = StyleSheet.create({
	text: {
		marginTop: 16,
	},
	iconContainer: {
		alignItems: "center",
		marginTop: 12,
	},
});