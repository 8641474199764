import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { saveStore } from "../../tools/storeTools";

export const storyMarkersSlice = createSlice({
	name: "storyMarkers",
	// [gameId]: object
	initialState: {
		byId: {},
	},
	reducers: {
		receiveStoryMarkers: {
			reducer(state, { payload: { gameId, storyMarkers, clearFirst } }) {
				if (clearFirst) {
					state[gameId] = storyMarkers;
				} else {
					const gameStoryMarkers = state[gameId] || [];
					state[gameId] = gameStoryMarkers.rg_overlapById(storyMarkers);
				}
				state.byId = { ...state.byId, ...storyMarkers.rg_toStore() };
				return state;
			},

			prepare(storyMarkers, clearFirst) {
				const gameId = storyMarkers[0].game;
				storyMarkers = storyMarkers.rg_sortById();
				return { payload: { gameId, storyMarkers, clearFirst } };
			},
		},
		clearStoryMarkers: (state, { payload: gameId }) => {
			state[gameId] = [];
		},
		addTemporaryStoryMarker: (state, { payload: marker }) => {
			state.byId[marker.id] = marker;
		},
		removeStoryMarker: (state, { payload: marker }) => {
			const array = state[marker.game].slice();
			array.rg_removeElement((m) => m.id === marker.id);
			state[marker.game] = array;
		},
	},
});

export const { receiveStoryMarkers, removeStoryMarker, addTemporaryStoryMarker, clearStoryMarkers } =
	storyMarkersSlice.actions;

export function fetchStoryMarkers(gameId, characterId) {
	return async (dispatch, getState) => {
		const params = characterId ? { character: characterId } : null;
		return apiFetch(`games/${gameId}/story-markers`, "GET", params)
			.then(({ results }) => {
				dispatch(receiveStoryMarkers(results, true));
				saveStore(getState());
			})
			.catch((e) => {});
	};
}

export function fetchStoryMarker(gameId, markerId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/story-markers/${markerId}`)
			.then((result) => {
				dispatch(receiveStoryMarkers([result]));
				return result;
			})
			.catch((e) => {});
	};
}

export default storyMarkersSlice.reducer;
