import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import AppScreenView from "../AppScreenView";
import { idKeyExtractor, ws } from "../../tools/generic";
import MacroGroupItem from "./MacroGroupItem";
import { fetchSheetMacros } from "../../store/slices/macrosSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";

function ViewSheetMacrosScreen({ route, navigation, dispatch, macroGroups }) {
	const { sheet } = route.params;
	const data = React.useMemo(
		() => Object.values(macroGroups).filter((g) => macroGroups.bySheet[sheet.id].includes(g.id)),
		[macroGroups, sheet.id]
	);

	React.useEffect(() => {
		dispatch(fetchSheetMacros(sheet.id));
	}, [sheet.id]);

	return (
		<AppScreenView borderless style={ws({ paddingLeft: fullScreenLeftPadding() })}>
			<FlatList
				contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
				ListHeaderComponent={
					<View style={styles.sectionHeaders}>
						<AppText color="hint">
							{_("Using this template in your game will give you access to the following macros.")}
						</AppText>
					</View>
				}
				keyExtractor={idKeyExtractor}
				data={data}
				renderItem={({ item, index }) => <MacroGroupItem macroGroup={item} index={index} sheetId={sheet.id} />}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		macroGroups: state.macroGroups,
	};
};

export default connect(mapStateToProps)(ViewSheetMacrosScreen);

const styles = StyleSheet.create({
	sectionHeaders: {
		marginHorizontal: 8,
		marginVertical: 16,
	},
});
