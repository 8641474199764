import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { saveStore } from "../../tools/storeTools";

export const gameSamplesSlice = createSlice({
	name: "gameSamplesSlice",
	// { gameId: {showcaseLineIds: [], contexts: {}}}
	initialState: {},
	reducers: {
		receiveShowcaseLineIds: (state, { payload: { gameId, lineIds } }) => {
			state[gameId] = state[gameId] || { showcaseLineIds: [], contexts: {} };
			state[gameId].showcaseLineIds = lineIds;
		},
		receiveLineContext: (state, { payload: { gameId, lineId, context } }) => {
			state[gameId] = state[gameId] || { showcaseLineIds: [], contexts: {} };
			state[gameId].contexts[lineId] = context;
		},
	},
});

export const { receiveShowcaseLineIds, receiveLineContext } = gameSamplesSlice.actions;

export function fetchDefaultSample(gameId) {
	return async (dispatch, getState) => {
		// Gets the highest rating lines to showcase
		return apiFetch(`games/${gameId}/lines/preview-selection`)
			.then((lineIds) => {
				dispatch(receiveShowcaseLineIds({ gameId, lineIds }));
				return lineIds.rg_first();
			})
			.then((firstLineId) => {
				if (!firstLineId) {
					saveStore(getState());
					return false;
				}
				dispatch(fetchLineContext(gameId, firstLineId));
			});
	};
}

export function fetchLineContext(gameId, lineId) {
	return async (dispatch, getState) => {
		if (!lineId) {
			saveStore(getState());
			return;
		}
		return apiFetch(`games/${gameId}/lines/${lineId}/context`).then((lines) => {
			dispatch(receiveLineContext({ gameId, lineId, context: lines }));
			saveStore(getState());
		});
	};
}
