import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Hoverable, useHover } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { globalStyles } from "../../styles/global";
import { BuildStyleMethod } from "../../styles/theming";
import { getStageNPC } from "../../tools/characters";
import { isInGame, isPlayer } from "../../tools/games";
import { ws } from "../../tools/generic";
import ChatOptions from "../chat/ChatOptions";
import AppButton from "../generic/buttons/AppButton";
import IconButton from "../generic/buttons/IconButton";
import CondView from "../meta/CondView";
import StageBackgroundImage from "../stagebackgrounds/StageBackgroundImage";
import CharactersRow from "./CharactersRow";

let updateOnLastInterval = null;

function Stage({ dispatch, currentStoryMarker, characters, lines, parties, hasPartiesOnScreen, game, anonymous, onSearchUpdatedCallback, failedSearch }) {
	const styles = stylesMethod(global.theme);
	const [onLastIndex, setonLastIndex] = React.useState(false);

	const navigation = useNavigation();


	const buttonsRowStyle = [styles.buttonsRow];

	let playerCharacters = React.useMemo(() => characters.filter((c) => !c.is_npc), [characters]);

	if (currentStoryMarker?.party) {
		const party = parties.rg_findById(currentStoryMarker.party);
		if (party) playerCharacters = playerCharacters.filter((c) => party.characters.includes(c.id));
	}

	let npcs = React.useMemo(
		() => getStageNPC(characters, currentStoryMarker, lines),
		[currentStoryMarker, characters, lines]
	);

	const inGame = isInGame();
	const readerMode = !inGame || game.archived;

	const linesRef = React.useRef(lines);
	linesRef.current = lines;

	const updateIsOnLast = React.useCallback(() => {
		const currentIndex = linesRef.current.findIndex((l) => l.id === global.currentLine?.id);
		if (currentIndex < linesRef.current.length - 1) {
			setonLastIndex(false);
			clearInterval(updateOnLastInterval);
		}
	}, []);

	const toNextLine = React.useCallback(() => {
		const currentIndex = lines.findIndex((l) => l.id === global.currentLine?.id);
		if (currentIndex > -1 && currentIndex < lines.length - 1) {
			const next = lines[currentIndex + 1];
			navigation.setParams({ lineToReach: next.id, linePosition: 1, readerMode: true });
		}

		if (currentIndex >= lines.length - 2) {
			setonLastIndex(true);
			updateOnLastInterval = setInterval(updateIsOnLast, 1000);
		}
	}, [lines]);

	const characterRowsRef = React.useRef(null);
	const isHovered = useHover(characterRowsRef);
	const showNames = isHovered;

	return (
		<>
			<View style={[styles.container]}>
				{currentStoryMarker?.stage?.background && (
					<StageBackgroundImage
						marker={currentStoryMarker}
						height={160}
						containerStyle={styles.stage}
						resizeMode="cover"
						blurRadius={2.5}
					/>
				)}
				<StageBackgroundImage
					marker={currentStoryMarker}
					height={160}
					containerStyle={[styles.stage, { alignItems: "center", paddingHorizontal: 8 }]}
					additionalImageStyle={{ width: 730 }}
					imageClass="stageImage"
				/>

				<View style={[styles.charactersContainer]} ref={characterRowsRef}>
					<IconButton
						hide={!hasPartiesOnScreen || isPlayer(game)}
						style={styles.changePovOption}
						onPress={() => navigation.navigate("ChangePOVModal")}
						icon={{ type: MaterialIcons, name: "people", color: global.colors.textDefault }}
						subicon={{ type: MaterialIcons, name: "swap-horiz" }}
					/>
					<CharactersRow characters={playerCharacters} style={styles.playerRow} showNames={showNames} />
					<CharactersRow characters={npcs} style={styles.npcRow} showNames={showNames} />
				</View>

				<View style={buttonsRowStyle}>
					{!readerMode && <ChatOptions onSearchUpdatedCallback={onSearchUpdatedCallback} failedSearch={failedSearch}/>}

					<CondView show={readerMode} style={[{ width: "100%", left: 0 }, globalStyles.jac]}>
						<AppButton
							title={_("Next", "reader mode go to next line")}
							style={{ minHeight: 32 }}
							onPress={toNextLine}
							disabled={onLastIndex}
						/>
					</CondView>
				</View>
			</View>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	const gameUI = state.gamesUI[game.id] || {};

	return {
		currentStoryMarker: gameUI.currentStoryMarker,
		characters: state.charactersByGame[game.id] || Array.rg_empty,
		lines: state.storyLines[game.id] || Array.rg_empty,
		game,
		hasPartiesOnScreen: gameUI.hasPartiesOnScreen,
		parties: state.parties[game.id].openedParties,
		anonymous: !state.user,
	};
};

export default connect(mapStateToProps)(Stage);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			height: 160,
			alignItems: "center",
			backgroundColor: "rgba(0,0,0,0.3)",
		},
		buttonsRow: {
			bottom: 4,
			flexDirection: "row",
			alignItems: "flex-end",
			alignSelf: "flex-end",
			width: "100%",
		},
		overlay: {
			backgroundColor: colors.overlayBackground,
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},
		stage: {
			position: "absolute",
			width: "100%",
			top: 0,
		},
		charactersContainer: {
			flexDirection: "row",
			paddingHorizontal: 8,
			flex: 1,
			marginBottom: ws(24, 12),
			justifyContent: "space-evenly",
			alignItems: "flex-end",
			width: 730,
			bottom: -16,
		},
		playerRow: {
			flex: 0,
			flexShrink: 1,
		},
		npcRow: {
			flex: 0,
			...ws({ width: 64 }, { marginLeft: 50, width: 58 }),
		},
		changePovOption: {
			position: "absolute",
			right: 4,
		},
	})
);
