import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { cStyle } from "../../tools/react";
import CoinValue from "../coins/CoinValue";
import GameTagHorizontalList from "../gametags/GameTagHorizontalList";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import CondView from "../meta/CondView";
import { useNavigation } from "@react-navigation/native";
import { deleteBookmark, saveBookmark } from "../../store/slices/bookmarksSlice";
import { receiveBookmarkedGames } from "../../store/slices/gamesSlice";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import { LinearGradient } from "expo-linear-gradient";
import GameCardCompact from "./GameCardCompact";
import { isWeb } from "../../tools/generic";
import { getTimestamp } from "../../tools/time";

function GameCard(props) {
	let { game, tagIdToHide, large, small, style, dispatch, bookmarkedGames, anonymous } = props;

	if (!large) return <GameCardCompact {...props} />;

	const navigation = useNavigation();

	const bookmarkModalRef = React.useRef();
	const bookmarkRemovedModalRef = React.useRef();

	const bookmarked = bookmarkedGames.some((g) => g.id === game.id);

	const pathToBg = `${global.root_url}/static/images/stagebg/bg_${game.cover_background?.name || "neutral_1"}.png`;
	const locked = !!game.password;
	const playerColor = locked || game.archived ? "secondary" : "textLight";

	const toggleBookmark = React.useCallback(() => {
		if (bookmarked) {
			dispatch(deleteBookmark(game.id));
			dispatch(receiveBookmarkedGames({ games: bookmarkedGames.rg_removeElementByIdPure(game.id) }));
			bookmarkRemovedModalRef.current.show();
		} else {
			dispatch(saveBookmark(game.id));
			dispatch(receiveBookmarkedGames({ games: bookmarkedGames.concat(game) }));
			bookmarkModalRef.current.show();
		}
	}, [bookmarked, game, bookmarkedGames]);

	const updateDateComponent = (
		<AppText centered={!isWeb()} bold={!isWeb()} size="small" color="secondary">
			{game.archived
				? _("ended %(last_update_date)s", "game details", {
						last_update_date: getTimestamp(game.last_line?.created || game.last_update),
				  })
				: _("updated %(last_update_date)s", "game details", {
						last_update_date: getTimestamp(game.last_update),
				  })}
		</AppText>
	);

	return (
		<TouchableOpacity
			style={cStyle([styles.container, style])}
			onPress={() => navigation.navigate("GameDetailsPanelScreen", { gameslug: game.slug })}
		>
			<View style={[styles.imageSize, { marginRight: -64 }]}>
				<Image source={{ uri: pathToBg }} style={[styles.image]} resizeMode="cover" />
				<LinearGradient
					colors={["transparent", "transparent", global.colors.cardBackground]}
					locations={[0, 0.2, 1]}
					style={[styles.imageSize, { position: "absolute" }]}
					start={{ x: 0, y: 1 }}
				/>
			</View>

			<View style={styles.info}>
				<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
					<AppText bold numberOfLines={1} ellipsizeMode="tail" size={"large"}>
						{game.name}
					</AppText>
					<CondView show={!small && !anonymous} style={styles.topRow}>
						<IconButton
							style={{ height: 32, width: 32 }}
							icon={{
								type: MaterialIcons,
								name: bookmarked ? "bookmark" : "bookmark-border",
								color: global.colors.textLight,
							}}
							transparent
							onPress={toggleBookmark}
						/>
					</CondView>
				</View>
				<GameTagHorizontalList game={game} large={large} tagIdToHide={tagIdToHide} />
				<AppText
					color="secondary"
					hide={!large}
					numberOfLines={4}
					ellipsizeMode="tail"
					size="small"
					placeholder={_("No description.", "game details")}
					style={{ marginTop: 12 }}
				>
					{game.description}
				</AppText>

				<View style={{ flex: 1 }} />

				<View style={{ flexDirection: "row", justifyContent: "space-between",  marginBottom: 8, marginTop: 8 }}>
					<AppText
						size="small"
						color="secondary"
						onPress={() => navigation.navigate("ProfilePage", { username: game.game_master.username })}
					>
						{_("by %(gm_name)s", "indicated the gm of a game", {
							gm_name: (
								<AppText key="1" bold>
									{game.game_master.username}
								</AppText>
							),
						})}
					</AppText>

					{updateDateComponent}
				</View>
				<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						<FontAwesome name="user" size={12} style={{ marginRight: 4 }} color={colors[playerColor]} />
						<AppText color={playerColor} size="small" hide={game.archived}>
							{game.players.length}/{game.max_players}
						</AppText>
						<AppText hide={!game.archived} color={playerColor} size="small">
							{game.players.length}{" "}
							<AppText italic color="secondary">
								({_("Archived", "game is archived")})
							</AppText>
						</AppText>
						{locked && (
							<FontAwesome name="lock" size={10} style={{ marginLeft: 4 }} color={colors[playerColor]} />
						)}
					</View>
					<CoinValue subject={game} subjectGetter={{ game_id: game.id }} syle={{ flex: 1 }} />
				</View>
			</View>

			<TinyFeedbackModal ref={bookmarkModalRef} message={_("Bookmarked!", "confirm bookmark added")} />
			<TinyFeedbackModal
				ref={bookmarkRemovedModalRef}
				message={_("Bookmark removed", "confirm bookmark removed")}
			/>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		bookmarkedGames: state.games.bookmarked,
		anonymous: !state.user,
	};
};

export default connect(mapStateToProps)(GameCard);
const cardHeight = 200;
const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		height: cardHeight,
		marginBottom: 16,
		maxWidth: 900,
	},
	image: {
		position: "absolute",
		backgroundColor: "black",
		height: cardHeight,
		width: "100%",
	},
	info: {
		marginLeft: 12,
		padding: 8,
		flex: 1,
	},
	imageSize: {
		height: cardHeight,
		width: 300,
	},
});
