import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { fetchLanguages } from "../../store/slices/languagesSlice";
import { idKeyExtractor } from "../../tools/generic";
import AppText from "../generic/AppText";
import ChatInputSubMenu from "./ChatInputSubMenu";
import { isGM } from "../../tools/games";
import { setLanguage } from "../../store/slices/gamesUISlice";

function LanguageSelection({ show, dispatch, gameId, languages, currentCharacter, onLanguageSelected }) {
	const gm = isGM();
	React.useEffect(() => {
		if (show) {
			dispatch(fetchLanguages(gameId, gm ? null : currentCharacter?.id));
		}
	}, [show, currentCharacter, gm]);

	const selectLanguage = React.useCallback(
		(language) => {
			dispatch(setLanguage({ gameId, value: language }));
			onLanguageSelected && onLanguageSelected(language);
		},
		[gameId]
	);

	return (
		<ChatInputSubMenu
			show={show}
			headerTitle={_("Select language")}
			searchScreenName={languages.length ? "LanguageSelectionScreen" : null}
		>
			<FlatList
				data={languages}
				keyExtractor={idKeyExtractor}
				ListHeaderComponent={() => (
					<AppText style={{ padding: 8 }} onPress={() => selectLanguage(null)}>
						{_("None", "default language")}
					</AppText>
				)}
				renderItem={({ item }) => (
					<AppText style={{ padding: 8 }} onPress={() => selectLanguage(item)}>
						{item.name}
					</AppText>
				)}
				ListEmptyComponent={() => (
					<View>
						<AppText color="hint">
							{_("No language was created for this game.", "no fictional language in character sheet")}
						</AppText>
						<AppText color="hint">
							{_(
								"Create new languages in the Manage languages options, from the menu in the top right corner.",
								"no fictional language in character sheet"
							)}
						</AppText>
					</View>
				)}
			/>
		</ChatInputSubMenu>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		gameId,
		currentCharacter: getCurrentCharacter(state),
		languages: state.languages[gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(LanguageSelection);


