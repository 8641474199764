import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, View } from "react-native";
import { Divider } from "react-native-paper";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchBookmarkedGames, fetchTagGames } from "../../store/slices/gamesSlice";
import { fetchDefaultTagRecommendations, fetchTagRecommendations } from "../../store/slices/gameTagsSlice";
import { fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import GameTagPreview from "../gametags/GameTagPreview";
import PopularTagsSection from "../gametags/PopularTagsSections";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import XLButton from "../generic/buttons/XLButton";
import RGIcon from "../RGIcon";
import FeaturedGame from "./FeaturedGame";
import FeaturedGamesList from "./FeaturedGamesList";

function Header({ user, navigation, scrollView, onPreviewLoaded }) {
	return (
		<>
			{!!user && (
				<ButtonsRow
					style={ws(
						{ marginVertical: 16, justifyContent: "flex-start" },
						{ marginVertical: 16, paddingHorizontal: 8 }
					)}
				>
					<XLButton
						style={{ marginRight: 8 }}
						onPress={() => navigation.navigate("FindGameWizardStack")}
						title={_("Find a game to join")}
						icon={{ type: RGIcon, name: "die" }}
					/>
					<XLButton
						onPress={() => navigation.navigate("BrowseGamesScreen")}
						title={_("Find a game to read")}
						icon={{ type: MaterialCommunityIcons, name: "bookmark" }}
					/>
				</ButtonsRow>
			)}
			<FeaturedGame scrollView={scrollView} onPreviewLoaded={onPreviewLoaded} />
			<FeaturedGamesList />
			<PopularTagsSection />
			<Divider theme={{ dark: true }} style={{ marginVertical: 16 }} />
			<AppText bold size="large" style={{ margin: 8 }}>
				{_("Browse games")}
			</AppText>
		</>
	);
}

function ExploreScreen({ navigation, dispatch, user, recommendedGameTags, hasBookmarkedGames }) {
	const [loadingIndex, setloadingIndex] = React.useState(-1);

	const scrollView = React.useRef();

	const loadTagRecommendations = React.useCallback(() => {
		if (user) {
			dispatch(fetchTagRecommendations(user.profile.id));
			if (!hasBookmarkedGames) {
				dispatch(fetchBookmarkedGames(user.id));
			}
		} else {
			dispatch(fetchDefaultTagRecommendations());
		}
	}, [hasBookmarkedGames, user]);

	React.useEffect(() => {
		const loadTags = async () => {
			if (!recommendedGameTags) return;

			for (let i = 0; i < recommendedGameTags.length; i++) {
				const tag = recommendedGameTags[i];
				await dispatch(fetchTagGames(tag));
			}
		};

		loadTags();
	}, [recommendedGameTags]);

	React.useEffect(loadTagRecommendations, []);

	// Add a delay because the page lags when if it starts loading games immediately
	React.useEffect(() => {
		const loadTimeout = setTimeout(() => {
			setloadingIndex(0);
		}, 2000);
		return () => clearTimeout(loadTimeout);
	}, []);

	const tagsDisplayed = React.useMemo(() => {
		const usedTags = recommendedGameTags?.slice(0, 5) || [];
		for (let i = usedTags.length; i < 5; i++) {
			usedTags.push(null);
		}
		return usedTags;
	}, [recommendedGameTags]);

	const { onLayout } = useLayout();

	return (
		<AppScreenView borderless style={{ flex: 1, overflow: "hidden" }}>
			<FlatList
				onLayout={onLayout}
				ref={scrollView}
				contentContainerStyle={[{ paddingBottom: 32 }, ws({ right: 32, paddingLeft: fullScreenLeftPadding() })]}
				data={tagsDisplayed}
				keyExtractor={(item, index) => index.toString()}
				renderItem={({ item, index }) => (
					<GameTagPreview
						tag={item}
						autoLoad={loadingIndex == index}
						onGamesLoaded={() => setloadingIndex((curr) => curr + 1)}
					/>
				)}
				ItemSeparatorComponent={() => <View style={{ marginBottom: 16 }} />}
				ListHeaderComponent={
					<Header
						user={user}
						navigation={navigation}
						scrollView={scrollView.current}
						onPreviewLoaded={loadTagRecommendations}
					/>
				}
				ListFooterComponent={<Footer navigation={navigation} />}
			/>
		</AppScreenView>
	);
}

function Footer({ navigation }) {
	return (
		<ButtonsRow style={{ marginTop: 16 }}>
			<AppButton title={_("Browse all")} onPress={() => navigation.navigate("BrowseGamesScreen")} />
		</ButtonsRow>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		recommendedGameTags: state.recommendedGameTags || Array.empty,
		hasBookmarkedGames: !!state.games.bookmarked.length,
	};
};

export default connect(mapStateToProps)(ExploreScreen);
