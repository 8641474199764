import * as React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import AppScreenView from "../../AppScreenView";
import CharacterAvatar from "../CharacterAvatar";
import AppLoading from 'expo-splash-screen';
import { fetchAvatars } from "../../../store/slices/avatarsSlice";
import AppText from "../../generic/AppText";
import { _ } from "../../../i18n/i18n";
import InputField from "../../generic/InputField";
import CondView from "../../meta/CondView";
import { receiveAvatarRequest } from "../../../store/slices/avatarRequestSlice";
import { apiFetch } from "../../../network/apiFetch";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import { globalStyles } from "../../../styles/global";
import UnreadBadge from "../../generic/UnreadBadge";
import { isWeb, ws } from "../../../tools/generic";
import { fullScreenContentWidth } from "../../../styles/dynamicStyles";

function AvatarRequestSendFeedback({ avatarRequest, dispatch, userId, avatars }) {
	const [feedback, setfeedback] = React.useState();

	React.useEffect(() => {
		dispatch(fetchAvatars());
	}, []);

	const avatar = avatars[avatarRequest.avatar];

	const sendFeedback = React.useCallback(() => {
		const patch = {
			feedback: feedback,
			pending_feedback: false,
		};

		dispatch(receiveAvatarRequest({ ...avatarRequest, ...patch }));
		apiFetch(`users/${userId}/avatar-requests/${avatarRequest.id}`, "PATCH", { feedback });
	}, [userId, avatarRequest, feedback]);

	if (!avatar) {
		return (
			<AppScreenView>
				<AppLoading />
			</AppScreenView>
		);
	}

	return (
		<>
			<AppScreenView scroll style={ws({ alignItems: "center" })}>
				<AppText centered bold size="large">
					{_("Current version of your avatar", "avatar request")}
				</AppText>
				<View style={{ alignItems: "center" }}>
					<CharacterAvatar avatarName={avatar.name} size={256} />
				</View>

				<CondView show={avatarRequest.question} style={{ marginTop: 32 }}>
					<AppText centered bold color="attention">
						{_("Comment from the artist", "avatar request")}
					</AppText>
					<AppText style={{ marginTop: 8, backgroundColor: "white", padding: 16 }}>
						{avatarRequest.question}
					</AppText>
				</CondView>

				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 32,
						marginBottom: 8,
					}}
				>
					<AppText centered bold color="primary" style={{ marginRight: 8 }}>
						{_("Your feedback", "avatar request")}
					</AppText>
					<UnreadBadge value="!" important />
				</View>
				<InputField
					value={feedback}
					onChangeText={setfeedback}
					multiline
					placeholder={_("Type your feedback", "avatar request")}
					style={ws({ maxWidth: fullScreenContentWidth()})}
					inputStyle={ws({ height: 150 })}
					autoFocus={isWeb()}
				/>
			</AppScreenView>
			<ButtonFooter narrow={false} buttonProps={[{ title: _("Send feedback"), onPress: sendFeedback }]} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		avatars: state.avatars,
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(AvatarRequestSendFeedback);


