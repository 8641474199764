import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppScreenView from "../AppScreenView";
import ListSwitch from "../generic/ListSwitch";
import { _ } from "../../i18n/i18n";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { deleteToken, registerForPushNotificationsAsync } from "../../network/mobileNotifications";
import { connect } from "react-redux";

function NotificationSettingsScreen({ route, navigation, dispatch, userId }) {
	const [allowNotifications, setallowNotifications] = React.useState(global.ALLOW_NOTIFICATIONS);

	React.useEffect(() => {
		if (allowNotifications != global.ALLOW_NOTIFICATIONS) {
			global.ALLOW_NOTIFICATIONS = allowNotifications;
			AsyncStorage.setItem("allowNotifications", JSON.stringify(allowNotifications));
			if (!allowNotifications) {
				deleteToken(userId);
			} else {
				registerForPushNotificationsAsync(userId);
			}
		}
	}, [allowNotifications, userId]);

	return (
		<AppScreenView>
			<ListSwitch
				title={_("Notifications", "app settings")}
				isEnabled={allowNotifications}
				setIsEnabled={setallowNotifications}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(NotificationSettingsScreen);


