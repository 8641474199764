import * as React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { fetchMessageGroup } from "../../store/slices/messageGroupsSlice";
import ErrorLoading from "../errors/ErrorLoading";
import MessageGroupScreen from "./MessageGroupScreen";

function MessageGroupLoader(props) {
	if (!props.route.params) return null;

	const { route, dispatch, user, group } = props;
	const [error, seterror] = React.useState(false);

	const { groupId } = route.params;

	React.useEffect(() => {
		const doFetch = async () => {
			const success = await dispatch(fetchMessageGroup(user.id, groupId));
			if (!success) {
				seterror(true);
			}
		};

		if (!group) {
			doFetch();
		}
	}, [group, user.id, groupId]);

	if (error) return <ErrorLoading />;

	if (!group) return null;

	return <MessageGroupScreen {...props} />;
}

const mapStateToProps = (state, ownProps) => {
	if (!ownProps.route.params) return {};
	const { groupId } = ownProps.route.params;
	return {
		user: state.user,
		group: state.messageGroups[groupId],
	};
};

export default connect(mapStateToProps)(MessageGroupLoader);


