import * as React from "react";
import { _ } from "../../i18n/i18n";
import ModalScreen from "../generic/modal/ModalScreen";
import RGIcon from "../RGIcon";
import ChangeCurrentCharacterOption from "../characters/ChangeCurrentCharacterOption";
import { isGM } from "../../tools/games";
import { MaterialIcons, MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";
import { useDispatch } from "react-redux";
import { setMacrosHistory } from "../../store/slices/gamesUISlice";
import { connect } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { HasLevelOrShare } from "../../tools/account";

function SelectRollTypeModal({ navigation, dispatch, macrosHistory, gameId, user }) {
	React.useEffect(() => {
		if (macrosHistory) return;
		async function loadHistory() {
			let history = await AsyncStorage.getItem("macrosHistory");
			if (history) {
				history = JSON.parse(history);
				dispatch(setMacrosHistory({ gameId, value: history[gameId] }));
			}
		}
		loadHistory();
	}, [macrosHistory, gameId, dispatch]);

	const options = [
		{
			title: _("Roll", "roll dice menu option"),
			info: _("Pick dice to roll", "roll dice menu option info"),
			icon: { type: RGIcon, name: "die" },
			onPress: () => {
				navigation.navigate("SimpleRollModal");
			},
		},
		{
			title: _("Roll+", "roll dice menu option"),
			info: _("Roll an advanced command", "roll dice menu option info"),
			icon: { type: RGIcon, name: "die" },
			subicon: { type: FontAwesome, name: "plus" },
			onPress: () => {
				navigation.navigate("AdvancedRollModal");
			},
		},
	];



	options.push({
		title: _("Macros", "roll dice menu option"),
		info: _("Quickly roll a saved command", "roll dice menu option info"),
		icon: { type: RGIcon, name: "die" },
		subicon: { type: MaterialIcons, name: "play-arrow" },
		onPress: () => {
			navigation.navigate("SelectMacroModal");
		},
	});

	if (isGM()) {
		options.push({
			title: _("Request roll", "roll dice menu option"),
			info: _("Request a roll from players, with a timer", "roll dice menu option info"),
			icon: { type: RGIcon, name: "die" },
			subicon: { type: MaterialIcons, name: "timer" },
			onPress: () => {
				navigation.navigate("AdvancedRollModal", { isRequest: true });
			},
		});
	}

	return (
		<ModalScreen options={options} borderless>
			<ChangeCurrentCharacterOption />
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		gameId,
		macrosHistory: state.gamesUI[gameId]?.macrosHistory,
		user: state.user,
	};
};

export default connect(mapStateToProps)(SelectRollTypeModal);
