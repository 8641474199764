import * as React from "react";
import { FlatList } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchGame } from "../../store/slices/gamesSlice";
import { clearLines, fetchLines } from "../../store/slices/linesSlice";
import { setPovCharacterId } from "../../store/slices/partiesSlice";
import { fetchStoryMarkers } from "../../store/slices/storyMarkersSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { isPlayer } from "../../tools/games";
import { idKeyExtractor, ws } from "../../tools/generic";
import { openGameAtLine } from "../../tools/lines";
import { getMarkerStartLineId, sortMarkers } from "../../tools/storyMarker";
import AppScreenView from "../AppScreenView";
import ErrorLoading from "../errors/ErrorLoading";
import AppButton from "../generic/buttons/AppButton";
import FullScreenLoader from "../generic/FullScreenLoader";
import StoryMarkerItem from "./StoryMarkerItem";
import StoryMarkerSeparator from "./StoryMarkerSeparator";

function IndexScreen({
	route,
	navigation,
	dispatch,
	game,
	parties,
	storyMarkerReceived,
	openedParties,
	currentCharacterId,
}) {
	const [loading, setloading] = React.useState(false);
	if (!game) return <ErrorLoading />;

	const storyMarkers = React.useMemo(() => {
		let returnArray = storyMarkerReceived.filter((sm) => !sm.hidden);

		returnArray = sortMarkers(returnArray);

		return returnArray;
	}, [storyMarkerReceived]);

	React.useEffect(() => {
		if (!game.parties) {
			dispatch(fetchGame(game.id));
		}
	}, [game]);

	React.useEffect(() => {
		if (!storyMarkers?.length) {
			const storyMarkersCharacterId = isPlayer(game) ? currentCharacterId : null;
			dispatch(fetchStoryMarkers(game.id, storyMarkersCharacterId));
		}
	}, [storyMarkers, game.id]);

	const goToMarker = React.useCallback(
		async (marker) => {
			setloading(true);
			if (marker.party) {
				const party = parties[marker.party];
				if (party) {
					await dispatch(setPovCharacterId({ gameId: marker.game, characterId: party.characters[0] }));
				}
			}
			const markerStartlineId = getMarkerStartLineId(marker, parties);
			await openGameAtLine(game, markerStartlineId, marker.party, 0.5, dispatch, navigation);
			setloading(false);
		},
		[game, dispatch, navigation, parties]
	);

	const openGameAtEnd = React.useCallback(async () => {
		setloading(true);
		await dispatch(clearLines(game.id));
		const params = { "opened-parties": (openedParties || []).map((p) => p.id) };
		params.to = "last";
		await dispatch(fetchLines(game.id, params));

		navigation.navigate("GameStack", { gameslug: game.slug, screen: "Game" });
		setloading(false);
	}, [game.id, openedParties]);

	const jumpToStart = (
		<AppButton
			transparent
			size="fullWidth"
			title={_("Go to start of the story", "jump to start/end of the game in index")}
			onPress={async () => {
				setloading(true);
				await openGameAtLine(game, 0, null, 0, dispatch, navigation);
				setloading(false);
			}}
		/>
	);
	const jumpToEnd = (
		<AppButton
			transparent
			size="fullWidth"
			title={
				game.archived
					? _("Go to end of the story", "jump to start/end of the game in index")
					: _("Go to last message", "jump to start/end of the game in index")
			}
			onPress={openGameAtEnd}
		/>
	);

	return (
		<AppScreenView borderless style={ws({ paddingLeft: fullScreenLeftPadding() })}>
			<FlatList
				contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
				data={storyMarkers}
				keyExtractor={idKeyExtractor}
				ListHeaderComponent={jumpToStart}
				ListFooterComponent={jumpToEnd}
				renderItem={({ item, separators }) => (
					<StoryMarkerItem marker={item} separators={separators} onSelected={goToMarker} />
				)}
				ItemSeparatorComponent={StoryMarkerSeparator}
			/>
			<FullScreenLoader visible={loading} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameslug } = ownProps.route.params;
	const game = state.games[gameslug];
	const gameUI = state.gamesUI[game?.id];
	return {
		game,
		storyMarkerReceived: state.storyMarkers[game?.id] || Array.rg_empty,
		parties: state.parties[game?.id]?.store || {},
		openedParties: state.parties[game?.id]?.openedParties,
		currentCharacterId: gameUI?.currentCharacterId,
	};
};

export default connect(mapStateToProps)(IndexScreen);
