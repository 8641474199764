import * as React from "react";
import AppText from "../components/generic/AppText";

var frLocale = require("../../locale/json/fr.json");
var hrLocale = require("../../locale/json/hr.json");
var nlLocale = require("../../locale/json/nl.json");
var plLocale = require("../../locale/json/pl.json");
var itLocale = require("../../locale/json/it.json");
var ptBRLocale = require("../../locale/json/pt_BR.json");

global.LOCALE_CODE = "en";

function getLocaleDictionary() {
	switch (global.LOCALE_CODE) {
		case "fr":
			return frLocale;
		case "hr":
			return hrLocale;
		case "pl":
			return plLocale;
		case "nl":
			return nlLocale;
		case "pt-br":
			return ptBRLocale;
		case "it":
			return itLocale;
		default:
			break;
	}
}

function getNumberToLocaleNumberId(n) {
	switch (global.LOCALE_CODE) {
		case "fr":
		case "pt-br":
			return n > 1 ? "plural" : null;
		case "pl":
			return n == 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 12 || n % 100 > 14) ? 1 : 2;
		case "hr":
			return n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<12 || n%100>14) ? 1 : 2;
		case "nl":
		case "it":
			return n != 1 ? "plural" : null;
		default:
			break;
	}
	return n != 1 ? "plural" : null;
}

export function _n(singularString, pluralString, commentToTranslator, currentValue, variables) {
	let localeDictionary = getLocaleDictionary();

	let localizedString = null;

	if (localeDictionary) {
		let stringWithComment = singularString;
		if (commentToTranslator) {
			stringWithComment += "::" + commentToTranslator;
		}

		const localeNumberId = getNumberToLocaleNumberId(currentValue);
		let stringWithValue = stringWithComment;
		if(localeNumberId){
			stringWithValue = `${stringWithComment}_${localeNumberId}`;	
		}

		localizedString = localeDictionary[stringWithValue];
		if (!localizedString) {
			localizedString = currentValue == 0 || currentValue > 1 ? pluralString : singularString;
		}
	} else {
		localizedString = currentValue == 0 || currentValue > 1 ? pluralString : singularString;
	}

	return ReplaceVariables(localizedString, variables);
}

export function _(stringToLocalize, commentToTranslator, variables) {
	let localeDictionary = getLocaleDictionary();

	let localizedString = stringToLocalize;

	let stringWithComment = localizedString;
	if (commentToTranslator) {
		stringWithComment += "::" + commentToTranslator;
	}

	if (localeDictionary) {
		localizedString = localeDictionary[stringWithComment] || stringToLocalize;
	}

	return ReplaceVariables(localizedString, variables);
}

function ReplaceVariables(stringToEdit, variables) {
	if (variables == null) {
		return stringToEdit;
	}

	const keys = Object.keys(variables);

	const hasNonString = Object.values(variables).some((value) => value && typeof value !== "string" && isNaN(value));

	if (hasNonString) {
		const regArray = keys.map((key) => "(%\\(" + key + "\\)s)");
		const regString = regArray.join("|");
		const splitArray = stringToEdit.split(new RegExp(regString)).filter((s) => !!s);

		const componentArray = splitArray.map((string, index) => {
			if (!string.startsWith("%")) return <AppText key={index}>{string}</AppText>;
			const key = string.substr(2, string.length - 4);
			return variables[key];
		});

		return componentArray;
	} else {
		keys.forEach((key) => {
			const re = new RegExp("%\\(" + key + "\\)s", "g");
			stringToEdit = stringToEdit.replace(re, variables[key]);
		});
	}

	return stringToEdit;
}
