import * as React from "react";
import { View, StyleSheet } from "react-native";
import ListButton from "../generic/buttons/ListButton";
import { _ } from "../../i18n/i18n";
import AppScreenView from "../AppScreenView";
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import RGIcon from "../RGIcon";
import { connect } from "react-redux";
import { Divider } from "react-native-paper";
import { ScreenToURL } from "../../tools/navigationConfig";
import { isWeb } from "../../tools/generic";

function AppSettingsScreen({ route, navigation, dispatch, user }) {
	const [localeCode, setlocaleCode] = React.useState();

	React.useEffect(() => {
		// only used to force a refresh
		const removeFocusListener = navigation.addListener("focus", () => setlocaleCode(global.LOCALE_CODE));
		return () => {
			removeFocusListener();
		};
	}, []);

	return (
		<AppScreenView borderless>
			<ListButton
				title={_("Emails", "app settings")}
				iconLeft={{ type: MaterialCommunityIcons, name: "email" }}
				to={ScreenToURL("EmailsSettingsScreen")}
			/>
			<ListButton
				hide={isWeb()}
				title={_("Notifications", "app settings")}
				iconLeft={{ type: MaterialIcons, name: "notifications" }}
				to={ScreenToURL("NotificationSettingsScreen")}
			/>
			<ListButton
				title={_("Games", "app settings")}
				iconLeft={{ type: RGIcon, name: "die" }}
				to={ScreenToURL("AppGameSettingsScreen")}
			/>
			<ListButton
				title={_("Language", "app settings")}
				iconLeft={{ type: MaterialIcons, name: "language" }}
				to={ScreenToURL("LocaleSettings")}
			/>
			<ListButton
				title={_("Display", "app settings")}
				iconLeft={{ type: FontAwesome, name: "paint-brush" }}
				to={ScreenToURL("AppDisplaySettings")}
			/>
			<ListButton
				title={_("Discord", "app settings")}
				iconLeft={{ type: MaterialCommunityIcons, name: "discord" }}
				to={ScreenToURL("DiscordSettingsScreen")}
			/>
			<Divider style={{ marginVertical: 16 }} />
			<ListButton
				title={_("Delete account", "app settings")}
				iconLeft={{ type: MaterialCommunityIcons, name: "account-off" }}
				to={ScreenToURL("DeleteAccountScreen")}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(AppSettingsScreen);


