import * as React from "react";
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import CharacterIconButton from "../characters/CharacterIconButton";
import AppText from "../generic/AppText";
import ModalScreen from "../generic/modal/ModalScreen";
import CondView from "../meta/CondView";
import { Divider } from "react-native-paper";
import { setPovCharacterId } from "../../store/slices/partiesSlice";
import { colors } from "../../styles/colors";

import { BuildStyleMethod } from "../../styles/theming";
import { gameHasUnloadedNext } from "../../tools/games";
import { getLinesDisplayed } from "../../tools/lines";
import { clearLines, fetchLastLines, fetchLines, receiveLines } from "../../store/slices/linesSlice";
import { setLoadingMoreLines } from "../../store/slices/gamesUISlice";

function PartyButton({ party, characters, onPress }) {
	const styles = stylesMethod(global.theme);
	return (
		<TouchableOpacity style={styles.partyButtonContainer} onPress={onPress}>
			<AppText centered bold>
				{party.name}
			</AppText>
			<ScrollView horizontal contentContainerStyle={{ flex: 1, justifyContent: "center" }}>
				{party.characters.map((characterId) => (
					<CharacterIconButton key={characterId} character={characters[characterId]} />
				))}
			</ScrollView>
		</TouchableOpacity>
	);
}

const mapStateToPartyButtonProps = (state, ownProps) => {
	return {
		characters: state.characters,
	};
};

PartyButton = connect(mapStateToPartyButtonProps)(PartyButton);

function ChangePOVModal({ parties, characters, gameId, navigation, dispatch, hasNext, lines }) {
	const onGoingParties = parties.filter((p) => p.on_going);

	characters = characters.filter((c) => parties.some((p) => p.characters.includes(c.id)));

	const selectPOV = React.useCallback(
		async (characterId) => {
			// remove lines to allow a clean reload
			const noPartyLines = lines.filter((l) => !l.party);

			await dispatch(setLoadingMoreLines({gameId, value: true}));

			await dispatch(setPovCharacterId({ gameId, characterId }));

			if (noPartyLines.length > 0) {
				await dispatch(clearLines(gameId));
				if (hasNext) {
					// remove all lines after the first one that is not a "party" line
					await dispatch(receiveLines({ lines: [noPartyLines[0]], gameId }));
				} else {
					// remove all lines before the last one that is is not a "party" line
					await dispatch(receiveLines({ lines: [noPartyLines.rg_last()], gameId }));
				}
				await dispatch(setLoadingMoreLines({gameId, value: false}));
			}
			// if the screen was cleared and I was at the bottom
			else if(!hasNext){
				dispatch(fetchLastLines(gameId));
			}

			navigation.navigate("Game");
		},
		[gameId, hasNext, lines]
	);

	const selectParty = React.useCallback(
		(party) => {
			selectPOV(party.characters[0]);
		},
		[selectPOV]
	);

	return (
		<ModalScreen title={_("Select a party or Point of View (POV) to switch to")}>
			<ScrollView>
				<CondView show={onGoingParties.length}>
					<AppText bold>{_("Party", "select party screen")}</AppText>
					<AppText color="hint" style={{ marginBottom: 8 }}>
						{_("Select a party to reveal its content.")}
					</AppText>

					{onGoingParties.map((party) => (
						<PartyButton key={party.id} party={party} onPress={() => selectParty(party)} />
					))}
				</CondView>

				<Divider />

				<AppText bold>{_("PoV", "Point of View for parties")}</AppText>
				<AppText color="hint" style={{ marginBottom: 8 }}>
					{_("Select a POV to directly switch to all the parties the character is in.")}
				</AppText>

				<ScrollView horizontal>
					{characters.map((character) => (
						<CharacterIconButton
							key={character.id}
							character={character}
							onPress={selectPOV.bind(this, character.id)}
						/>
					))}
				</ScrollView>
			</ScrollView>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;

	return {
		gameId,
		parties: state.parties[gameId]?.all,
		characters: state.charactersByGame[gameId],
		hasNext: gameHasUnloadedNext(state, gameId),
		lines: getLinesDisplayed(state, gameId),
	};
};

export default connect(mapStateToProps)(ChangePOVModal);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {},
		partyButtonContainer: {
			justifyContent: "center",
			borderWidth: 1,
			borderColor: colors.borderDefault,
			backgroundColor: colors.buttonBackgroundColor,
			padding: 4,
			marginBottom: 8,
		},
	})
);
