import AsyncStorage from '@react-native-async-storage/async-storage';
import * as React from "react";
import { newsStore } from "../../data/newsMessages";
import { _ } from "../../i18n/i18n";
import { isWeb } from "../../tools/generic";
import BoxModal from "../generic/modal/BoxModal";

function WhatsNewModal() {
	const modalRef = React.useRef();

	const newsObject = newsStore[0];

	// React.useEffect(()=>{
	// 	AsyncStorage.setItem("lastNewsReadVersion", JSON.stringify({ appVersionMajor: 1, appVersionMinor: 0 }));
	// }, []);

	React.useEffect(() => {
		const showLastNews = async () => {
			const lastNews = JSON.parse(await AsyncStorage.getItem("lastNewsReadVersion")) || {
				appVersionMajor: 0,
				appVersionMinor: 0,
			};

			if (
				lastNews.appVersionMajor < newsObject.appVersionMajor ||
				lastNews.appVersionMinor < newsObject.appVersionMinor
			) {
				modalRef.current.show();
			}
		};

		if (newsObject.content && (isWeb() || !newsObject.webOnly)) showLastNews();
	}, [newsObject]);

	const onClose = React.useCallback(() => {
		const { appVersionMajor, appVersionMinor } = global;
		AsyncStorage.setItem("lastNewsReadVersion", JSON.stringify({ appVersionMajor, appVersionMinor }));
	}, []);

	return (
		<BoxModal
			ref={modalRef}
			title={newsObject.title}
			options={[{ title: _("Got it!", "close info box") }]}
			onRequestClose={onClose}
		>
			{newsObject.content}
		</BoxModal>
	);
}

export default WhatsNewModal;


