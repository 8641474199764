import * as React from "react";
import { View, StyleSheet } from "react-native";
import CharacterAvatar from "../avatar/CharacterAvatar";
import { useNavigation } from "@react-navigation/native";

function LineTargetsAvatar({ targets, isPreview, line }) {
	const navigation = useNavigation();

	// There is a bug somewhere else that lets the same target show twice in the list.
	const displayedTargets = React.useMemo(
		() =>
			targets.rg_mapDistinct(
				(t) => t,
				(a, b) => a.id === b.id
			),
		[targets]
	);

	if (!displayedTargets?.length) return null;

	return (
		<View style={styles.container}>
			{displayedTargets.map((c) => (
				<CharacterAvatar
					key={c.id}
					character={c}
					onPress={() => navigation.navigate("CharacterOptionsModal", { characterId: c.id, line, isPreview })}
					line={line}
				/>
			))}
		</View>
	);
}

export default LineTargetsAvatar;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
	},
});
