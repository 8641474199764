import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import HelpButton from "../tutorials/HelpButton";
import { _ } from '../../i18n/i18n';

function JoinGameHelpButton({style}) {
	return (
		<HelpButton
			style={style}
			title={_("Join game", "stage menu")}
			message={
				<>
					<AppText>
						{_(
							'If a game has no password, it means that it welcomes new players. Feel free to hit "Join game", say hi in the chat and start playing! '
						)}
					</AppText>
					<AppText style={{ marginTop: 16 }}>
						{_(
							'If you are unsure, or if the game is protected by a password, press the "Talk with players" button to ask the players and Game master questions before joining!'
						)}
					</AppText>
				</>
			}
		/>
	);
}

export default JoinGameHelpButton;


