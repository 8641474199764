import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppScreenView from "../../AppScreenView";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import DiceSetItem from "../../dicesets/DiceSetItem";
import { connect } from "react-redux";
import { fetchClassicSet } from "../../../store/slices/dicesetsSlice";
import { simpleReducer } from "../../../tools/react";
import { _ } from "../../../i18n/i18n";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import { isWeb, ws } from "../../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";

function WizardCreateGameDiceSets({ route, navigation, dispatch, classic }) {
	const { game: gameReceived, selectedDiceSet } = route.params;

	const [game, updateGame] = React.useReducer(simpleReducer, {
		dice_sets: classic ? [classic] : [],
		...gameReceived,
	});

	React.useEffect(() => {
		if (!classic) {
			dispatch(fetchClassicSet()).then((diceSet) => {
				updateGame({ dice_sets: game.dice_sets.concat(diceSet) });
			});
		}
	}, [classic]);

	React.useEffect(() => {
		if (route.params?.game) {
			updateGame(route.params?.game);
		}
	}, [route.params?.game]);

	React.useEffect(() => {
		if (!selectedDiceSet) return () => null;
		navigation.setParams({ selectedDiceSet: null });

		updateGame({ dice_sets: game.dice_sets.concat(selectedDiceSet) });
	}, [selectedDiceSet, game.dice_sets]);

	const remove = React.useCallback(
		(diceSet) => {
			updateGame({ dice_sets: game.dice_sets.rg_removeElementByIdPure(diceSet.id) });
		},
		[game.dice_sets]
	);

	const centered = !isWeb();

	return (
		<>
			<AppScreenView
				scroll
				style={ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })}
			>
				<AppText centered={centered} bold style={{ marginTop: 32, marginBottom: 8 }}>
					{_("Dice sets", "create game wizard")}
				</AppText>
				<AppText centered={centered} style={{ marginBottom: 32 }} color="hint">
					{_("List the dice sets used in your game", "create game wizard")}
				</AppText>

				{game.dice_sets.map((item) => (
					<DiceSetItem
						key={item.id}
						diceSet={item}
						onPress={() =>
							navigation.navigate("DiceSetStack", {
								screen: "EditDiceSetScreen",
								params: { diceSet: item },
							})
						}
						options={[
							{
								title: _("Remove from game", "remove dice set from game"),
								onPress: () => remove(item),
							},
						]}
					/>
				))}

				<AppText hide={game.dice_sets.length} color="hint" centered>
					{_("This game won't use any dice.")}
				</AppText>

				<AppButton
					size="large"
					style={{ marginTop: 64 }}
					title={_("Add more sets", "select game dice sets")}
					onPress={() =>
						navigation.navigate("DiceSetStack", {
							screen: "BrowseDiceSetsScreen",
							params: { selectingSetFor: route.name },
						})
					}
				/>
			</AppScreenView>
			<ButtonFooter
				style={ws({ maxWidth: fullScreenContentWidth() })}
				narrow={false}
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.navigate("WizardCreateGameSheet", { game }) },
					{ title: _("Next"), onPress: () => navigation.navigate("WizardCreateGameMetaData", { game }) },
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		classic: state.diceSets.classic,
	};
};

export default connect(mapStateToProps)(WizardCreateGameDiceSets);
