let reconnectTimeout = null;
let reconnectionDelay = 2000;
let existingSocket = null;
export let socket = null;

export const connectToWebSocket = (onConnected) => {
	existingSocket = socket;
	let url = `${global.ws_scheme}://${global.host}/mobile-ws/`;
	socket = new WebSocket(url);

	socket.listeners = existingSocket ? existingSocket.listeners : [];
	socket.pendingEmits = existingSocket ? existingSocket.pendingEmits : [];
	socket.activeRooms = existingSocket ? existingSocket.activeRooms : [];

	// Custom functions ----
	socket.on = function (event, handler) {
		if (!handler) return;
		const listener = { event, handler };
		this.listeners.rg_pushUnique(listener, (l) => l.event === listener.event && l.handler === listener.handler);
	};

	socket.off = function (event, handler) {
		this.listeners.rg_removeElement((l) => l.event === event && l.handler === handler);
	};

	socket.emit = function (...args) {
		if (!this.connected) {
			this.pendingEmits.push(args);
			return;
		}
		const data = { event: args[0], data: args.splice(1) };
		try {
			this.send(JSON.stringify(data));
		} catch (err) {
			console.warn(err);
		}
	};
	// --------------------
	socket.onerror = (e) => {};

	socket.onmessage = function (e) {
		const message = JSON.parse(e.data);
		const event = message.event;
		for (let i = 0; i < this.listeners.length; ++i) {
			let listener = this.listeners[i];
			if (listener.event === event) {
				// send a copy of the data so that handler cannot affect the other handles by editing it
				if (message.data !== null && typeof message.data === "object") {
					listener.handler({ ...message.data });
				} else {
					listener.handler(message.data);
				}
			}
		}
	};

	socket.onclose = (e) => {
		reconnectTimeout = setTimeout(connectToWebSocket, reconnectionDelay);
		reconnectionDelay = reconnectionDelay + 2000;
		if (reconnectionDelay > 10000) {
			reconnectionDelay = 10000;
		}
	};

	socket.joinRoom = (roomName) => {
		socket.activeRooms.rg_pushUnique(roomName);
		socket.emit("join_room", roomName);
	};
	socket.leaveRoom = (roomName) => {		
		socket.activeRooms = socket.activeRooms.rg_removeSimple(roomName);
		socket.emit("leave_room", roomName);
	};

	if (!existingSocket) {
		socket.on("connected", () => {
			socket.connected = true;
			if (onConnected) {
				onConnected();
			}
		});

		socket.on("connected", () => {
			const queue = socket.pendingEmits.slice();
			socket.pendingEmits = [];
			for (let i = 0; i < queue.length; ++i) {
				let pEmit = queue[i];
				socket.emit(...pEmit);
			}

			for (let r = 0; r < socket.activeRooms.length; r++) {
				const roomName = socket.activeRooms[r];
				socket.emit("join_room", roomName);
			}
		});

		socket.on("disconnected", () => {
			socket.connected = false;
		});
	}
};

export const closeWebSocketConnection = () => {
	if (!socket) {
		return;
	}

	socket.onclose = null;
	socket.pendingEmits = [];
	socket.listeners = [];

	socket.close();
};
