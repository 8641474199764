import * as React from "react";
import { View, StyleSheet } from "react-native";
import { ws } from "../../tools/generic";
import AppPicker from "./AppPicker";
import AppText from "./AppText";

function DropdownListItem({ label, selectedValue, setValue, items, style }) {
	return (
		<View style={[{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, style]}>
			<AppText style={{ marginRight: 16 }}>{label}</AppText>
			<AppPicker
				selectedValue={selectedValue}
				onValueChange={(itemValue, itemIndex) => setValue(itemValue)}
				mode="dropdown"
				pickerStyle={{ flex: 1, color: ws(null, "white") }}
				itemStyle={{ color: ws(null, "white"), width: 200 }}
				items={items}
				icon={null}
			/>
		</View>
	);
}

export default DropdownListItem;

const styles = StyleSheet.create({
	container: {},
});
