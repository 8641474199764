import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { receiveMessageGroups, messageGroupsSlice } from "./messageGroupsSlice";

export const friendsSlice = createSlice({
	name: "friends",
	initialState: {
		friendIds: [],
		sentRequests: [],
		pendingRequests: [],
	},
	reducers: {
		receiveFriendIds: (state, { payload: ids }) => {
			state.friendIds = ids;
		},
		receivePendingRequests: (state, { payload: requests }) => {
			state.pendingRequests = requests;
		},
		receiveSentRequests: (state, { payload: requests }) => {
			state.sentRequests = requests;
		},
		addSentRequest: (state, { payload: request }) => {
			state.sentRequests = state.sentRequests.concat(request);
		},
		removeSentRequest: (state, { payload: request }) => {
			state.sentRequests = state.sentRequests.rg_removeElementByIdPure(request.id);
		},
		removePendingRequest: (state, { payload: request }) => {
			state.pendingRequests = state.pendingRequests.rg_removeElementByIdPure(request.id);
		},
	},
});

export const {
	receiveFriendIds,
	receivePendingRequests,
	receiveSentRequests,
	addSentRequest,
	removeSentRequest,
	removePendingRequest,
} = friendsSlice.actions;

export function fetchFriendIds(userId) {
	return async (dispatch, getState) => {
		apiFetch(`users/${userId}/friend-ids`).then((response) => {
			dispatch(receiveFriendIds(response));
		});
	};
}
export function fetchPending(userId) {
	return async (dispatch, getState) => {
		apiFetch(`users/${userId}/friend-requests`, "GET", { receiver_id: userId }).then(({ results }) => {
			dispatch(receivePendingRequests(results));
		});
	};
}

export function fetchSentRequests(userId) {
	return async (dispatch, getState) => {
		apiFetch(`users/${userId}/friend-requests`, "GET", { sender_id: userId }).then(({ results }) => {
			dispatch(receiveSentRequests(results));
		});
	};
}

export function cancelSentRequest(userId, request) {
	return async (dispatch, getState) => {
		apiFetch(`users/${userId}/friend-requests/${request.id}`, "DELETE");
		dispatch(removeSentRequest(request));
	};
}

export function acceptFriendRequest(request) {
	return async (dispatch, getState) => {		
		dispatch(removePendingRequest(request));
		return apiFetch(`users/${request.receiver}/friend-requests/${request.id}/accept`, "POST").then(async (createdGroup) => {
			const { all, nextUrl } = getState().messageGroups;
			const newGroups = all.slice();
			newGroups.splice(0, 0, createdGroup);
			await dispatch(receiveMessageGroups({ groups: newGroups, next: nextUrl }));
			return createdGroup;
		});
	};
}

export function rejectFriendRequest(request) {
	return async (dispatch, getState) => {		
		apiFetch(`users/${request.receiver}/friend-requests/${request.id}`, "DELETE");
		dispatch(removePendingRequest(request));
	};
}
