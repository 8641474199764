import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import RGTokenIcon from "../RGTokenIcon";
import { colors } from "../../styles/colors";
import AppText from "../generic/AppText";
import { cStyle } from "../../tools/react";

import { BuildStyleMethod } from "../../styles/theming";

function Token({ shape, color, size = 32, title, usedInText, selected, style, onPress, onLongPress }) {
	const styles = stylesMethod(global.theme);

	const ContainerTag = usedInText ? AppText : onPress || onLongPress ? TouchableOpacity : View;

	return (
		<ContainerTag
			style={cStyle([styles.container, [!!selected, styles.selected], style])}
			onPress={onPress}
			onLongPress={onLongPress}
			delayLongPress={global.delayLongPress}
			avoidTouchable
		>
			<RGTokenIcon name={shape} color={global.colors.userColors[color] || color} size={size} />
			{!!title && (
				<View style={styles.title}>
					<AppText color="textLight" style={[{ fontSize: Math.round(size / 2) }]}>
						{title}
					</AppText>
				</View>
			)}
		</ContainerTag>
	);
}

export default Token;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		alignItems: "center",
		justifyContent: "center",
		borderWidth: 1,
		borderColor: "transparent",
	},
	selected: {
		borderColor: colors.primary,
	},
	title: {
		position: "absolute",
		backgroundColor: "rgba(0,0,0,0.3)",
		borderRadius: 100,
	},
}));
