import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import AppButton from "../generic/buttons/AppButton";
import { colors } from "../../styles/colors";
import BoxModal from "../generic/modal/BoxModal";
import { useDispatch } from "react-redux";
import { rejectFriendRequest, acceptFriendRequest } from "../../store/slices/friendsSlice";
import { useNavigation } from "@react-navigation/native";
import { connect } from "react-redux";

import { BuildStyleMethod } from "../../styles/theming";

function PendingFriendRequestItem({ request, dispatch }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const confirmRejectModalRef = React.useRef(null);

	const acceptRequest = React.useCallback(async () => {
		// cannot do await with the dispatch returned from useDispatch. Has to be from connect function.
		const newGroup = await dispatch(acceptFriendRequest(request));
		navigation.navigate("MessageGroupScreen", { groupId: newGroup.id });
	}, [request]);
	const rejectRequest = React.useCallback(() => {
		dispatch(rejectFriendRequest(request));
	}, [request]);

	return (
		<View style={styles.container}>
			<AppText centered>
				{_("%(username)s sent you a friend request!", "", {
					username: (
						<AppText bold key="username">
							{request.sender.username}
						</AppText>
					),
				})}
			</AppText>
			<ButtonsRow>
				<AppButton title={_("Accept", "accept friend request")} onPress={acceptRequest} />
				<AppButton
					title={_("Reject", "reject friend request")}
					transparent
					onPress={() => confirmRejectModalRef.current.show()}
				/>
			</ButtonsRow>
			<BoxModal
				ref={confirmRejectModalRef}
				title={_("Reject friend request")}
				message={
					<AppText>
						{_("Are you sure you want to reject the friend request from %(username)s?", "", {
							username: (
								<AppText bold key="username">
									{request.sender.username}
								</AppText>
							),
						})}
					</AppText>
				}
				isDelete
				onConfirm={rejectRequest}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default connect(mapStateToProps)(PendingFriendRequestItem);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		marginHorizontal: 8,
		borderWidth: 1,
		borderColor: colors.attention,
		borderRadius: 16,
		padding: 8,
		marginTop: 4,
	},
}));
