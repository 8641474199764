import { createSlice } from "@reduxjs/toolkit";

export const directMessageUISlice = createSlice({
	name: "directMessageUI",
	// {groupId: {input, editingMessage}}
	initialState: {},
	reducers: {
		setDirectMessageInput: (state, { payload: { groupId, input } }) => {
			state[groupId] = state[groupId] || {};
			state[groupId].input = input;
		},
		setEditingMessage: (state, { payload: { groupId, message } }) => {
			state[groupId] = state[groupId] || {};
			state[groupId].editingMessage = message;
		},
	},
});
export const { setDirectMessageInput, setEditingMessage } = directMessageUISlice.actions;
