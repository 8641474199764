import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { receiveMacroGroups, receiveSheetMacroGroups } from "./macroGroupsSlice";

export const macrosSlice = createSlice({
	name: "macros",
	initialState: {},
	reducers: {
		receiveMacros: (state, { payload: macros }) => ({ ...state, ...macros.rg_toStore() }),
		removeMacros: (state, { payload: macrosToRemove }) => {
			const allMacros = Object.values(state).filter(
				(existingMacro) => !macrosToRemove.some((m) => m.id === existingMacro.id)
			);
			return allMacros.rg_toStore();
		},
	},
});

export const { receiveMacros, removeMacros } = macrosSlice.actions;

export function fetchSheetMacros(sheetId) {
	return async (dispatch, getState) => {
		// fetch groups instead of macros
		return apiFetch(`sheets/${sheetId}/dice-macro-groups`)
			.then((results) => {
				// extract macros from groups
				const macros = results.reduce((result, group) => [...result, ...group.macros], []);
				dispatch(receiveMacroGroups(results));
				dispatch(receiveSheetMacroGroups({sheetId, groups: results}));
				dispatch(receiveMacros(macros));
			})
			.catch(() => {});
	};
}

export const fetchGameMacros = (game) => {
	return (dispatch, getState) => {
		const { sheets, user, macroGroups } = getState();
		const gameSheetTemplate = sheets[game.sheet_template];

		if (!global.userMacroFetched) {
			dispatch(fetchUserMacros(user.id));
		}

		if (gameSheetTemplate && (!macroGroups.bySheet[gameSheetTemplate.id] || macroGroups.bySheet[gameSheetTemplate.id].some((gId) => !macroGroups[gId]))) {
			dispatch(fetchSheetMacros(gameSheetTemplate.id));
		}
	};
};

export const fetchUserMacros = (userId) => {
	return (dispatch, getState) =>
		apiFetch(`users/${userId}/dice-macro-groups`)
			.then((results) => {
				const macros = results.reduce((result, group) => (result = [...result, ...group.macros]), []);
				dispatch(receiveMacroGroups(results));
				dispatch(receiveMacros(macros));
				global.userMacroFetched = true;
			})
			.catch(() => {});
};
