import * as React from "react";
import { Image, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { isWeb, ws } from "../../tools/generic";
import { isOwn, isStory } from "../../tools/lines";
import CoinValue from "../coins/CoinValue";
import SimpleOverlayModal from "../generic/modal/SimpleOverlayModal";
import LineAvatar from "./LineAvatar";
import Zoom from "react-medium-image-zoom";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";

function ImageLine({ onPress, onLayout, line, isPreview, style }) {
	const componentHeight = ws(200, 150);
	const [zoom, setzoom] = React.useState(false);
	const action = React.useMemo(() => JSON.parse(line.action), [line.action]);
	const [imageSize, setimageSize] = React.useState({ width: componentHeight, height: componentHeight });

	const { width } = useWindowDimensions();

	React.useEffect(() => {
		const success = (width, height) => {
			setimageSize({ width, height });
		};

		Image.getSize(action.url, success);
	}, [action.url]);

	const { onLayout: onInnerLayout, width: availableWidth } = useLayout();

	const finalImageSize = React.useMemo(() => {
		if (!availableWidth) return { height: componentHeight, width: 0 };

		const ratio = imageSize.width / imageSize.height;

		const maxHeight = componentHeight;
		const maxWidth = availableWidth * 0.9;

		let height = Math.min(imageSize.height, maxHeight);
		let width = height * ratio;

		if (width > maxWidth) {
			width = maxWidth;
			height = width / ratio;
		}
		return { height, width };
	}, [availableWidth, imageSize]);

	let imageComponent = (
		<Image source={{ uri: action.url }} style={finalImageSize} resizeMethod="resize" resizeMode="contain" />
	);

	if (isWeb()) {
		imageComponent = (
			<Zoom
				overlayBgColorStart={"transparent"}
				overlayBgColorEnd={"transparent"}
				zoomMargin={64}
				closeText={_("Click to unzoom", "zoom on picture")}
				openText={_("Click to zoom", "unzoom from picture")}
			>
				{imageComponent}
			</Zoom>
		);
	} else {
		imageComponent = <TouchableOpacity onPress={() => setzoom(true)}>{imageComponent}</TouchableOpacity>;
	}

	const owned = isOwn(line);

	let ImageContainer;
	if (isStory(line) && !line.author && !isWeb()) {
		ImageContainer = (
			<View style={{ alignItems: "center" }}>
				<Image
					source={{ uri: action.url }}
					style={{ width: "100%", height: "100%", position: "absolute" }}
					blurRadius={20}
				/>
				<View
					style={{ width: "100%", height: "100%", position: "absolute", backgroundColor: "rgba(0,0,0,0.5)" }}
				/>
				{imageComponent}
			</View>
		);
	} else {
		ImageContainer = (
			<View
				pointerEvents="box-none"
				style={{
					marginHorizontal: 16,
					alignItems: "center",
					flexDirection: owned ? "row-reverse" : "row",
					// height: finalImageSize.height + 16, // give some margin to always have a clickable spot
				}}
			>
				{imageComponent}
			</View>
		);
	}

	const ContainerTag = isWeb() ? View: TouchableOpacity;
	return (
		<>
			<ContainerTag onPress={onPress} style={[styles.line, owned && { flexDirection: "row-reverse" }, style]} onLayout={onLayout}>
				{isWeb() && <TouchableOpacity
					onPress={onPress}
					style={{
						flex: 1,
						position: "absolute",
						height: "100%",
						width: "100%",
					}}
				/>}
				<LineAvatar avatarStyle={{ position: "absolute" }} line={line} isPreview={isPreview} />
				<View style={[styles.lineBox]} onLayout={onInnerLayout} pointerEvents="box-none">
					<AppText
						color="attentionSecondary"
						textOnlyStyle={owned ? { textAlign: "right" } : null}
						style={{ marginBottom: 4, marginHorizontal: 16 }}
					>
						{action.name}
					</AppText>
					{ImageContainer}

					<CoinValue
						subject={line}
						style={styles.coinValue}
						subjectGetter={{ line_id: line.id }}
						hideIfNone
					/>
				</View>
			</ContainerTag>

			{!isWeb() && (
				<SimpleOverlayModal visible={zoom} onRequestClose={() => setzoom(false)}>
					<TouchableOpacity onPress={() => setzoom(false)}>
						<Image source={{ uri: action.url }} style={{ width, height: width }} resizeMode="contain" />
					</TouchableOpacity>
				</SimpleOverlayModal>
			)}
		</>
	);
}

export default ImageLine;

const styles = StyleSheet.create({
	line: {
		flexDirection: "row",
		marginBottom: 4,
		marginHorizontal: 8,
		marginVertical: ws(0, 16),
	},
	coinValue: { position: "absolute", bottom: -2 },
	lineBox: {
		paddingBottom: 4,
		flex: 1,
		justifyContent: "center",
	},
});
