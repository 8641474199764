import * as React from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';
import { colors } from '../../styles/colors';
import AppScreenView from '../AppScreenView';

import { BuildStyleMethod } from "../../styles/theming";

function RoleGateLoadingScreen() {
	const styles = stylesMethod(global.theme);

	const size = React.useRef(new Animated.Value(128)).current;
	const opacity = React.useRef(new Animated.Value(1)).current;

    React.useEffect(()=>{
		Animated.timing(size, {
			toValue: 256,
			duration: 5000,
			easing: Easing.linear,
			useNativeDriver: false,
			isInteraction: false,
		}).start();

		Animated.timing(opacity, {
			toValue: 0,
			delay: 2000,
			duration: 5000,
			easing: Easing.linear,
			useNativeDriver: false,
			isInteraction: false,
		}).start();


    }, []);

    return (
		<AppScreenView style={styles.container}>
			<Animated.Image
				style={{ width: size, height: size, opacity: opacity, marginBottom: 32 }}
				source={require("../../../assets/images/Logo.png")}
			/>
		</AppScreenView>
    );
}

export default RoleGateLoadingScreen;


const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.cardBackground,
		justifyContent: "center",
		alignItems: "center",
	},
}));
