import * as React from "react";
import { View, StyleSheet } from "react-native";
import { fetchGameAlerts } from "../../store/slices/gamealertsSlice";
import { connect } from "react-redux";
import { FlatList } from "react-native";
import GameAlertItem from "./GameAlertItem";
import { idKeyExtractor } from "../../tools/generic";

let timeoutGameAlerts = null;
let timeoutGameTags = null;

function GameAlertsList({ style, dispatch, gameAlerts, user }) {
	const refreshGameAlerts = React.useCallback(() => {
		dispatch(fetchGameAlerts(user.id));
	}, [user.id]);

	React.useEffect(() => {
		timeoutGameAlerts = setTimeout(() => refreshGameAlerts(), 500);
		return () => {
			clearTimeout(timeoutGameAlerts);
		};
	}, []);

	return (
		<FlatList
			style={style}
			data={gameAlerts}
			keyExtractor={idKeyExtractor}
			renderItem={({ item, index }) => <GameAlertItem gameAlert={item} />}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		gameAlerts: state.gameAlerts.all,
		user: state.user,
	};
};

export default connect(mapStateToProps)(GameAlertsList);

const styles = StyleSheet.create({
	container: {},
});
