import * as React from "react";
import AppText from "./AppText";
import { View, StyleSheet } from "react-native";
import { colors } from "../../styles/colors";


import { BuildStyleMethod } from "../../styles/theming";

function UnreadBadge({ value, important, style }) {
	const styles = stylesMethod(global.theme);
	if (!value) {
		return null;
	}

	const baseStyle = [styles.basic];
	if (important) {
		baseStyle.push(styles.important);
	}

	return (
		<View style={[baseStyle, style]}>
			<AppText size="small" color="textLight">{value}</AppText>
		</View>
	);
}

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	basic: {
		backgroundColor: colors.attention,
		borderRadius: 16,
		width: 32,
		alignItems: "center"
	},
	important: {
		backgroundColor: colors.attentionImportant
	}
}));
export default UnreadBadge;
