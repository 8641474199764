import * as React from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import UnreadBadge from "../generic/UnreadBadge";

function HeaderBadge({ user, notifications, avatarRequest }) {
	const unreadNotificationsCount = React.useMemo(
		() => notifications.filter((n) => n.id > user.profile.last_transaction_seen_id).length,
		[notifications, user.profile.last_transaction_seen_id]
	);

	let total = unreadNotificationsCount + user.unread_messages.length;
	if (avatarRequest?.pending_feedback) {
		total += 1;
	}

	if (!total) return null;

	return <UnreadBadge value={total} important  style={styles.container}/>;
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		notifications: state.notifications.all,
		avatarRequest: state.avatarRequest,
	};
};

export default connect(mapStateToProps)(HeaderBadge);

const styles = StyleSheet.create({
	container: {
        position: "absolute",
        right: 0,
    },
});
