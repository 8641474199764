import { createSlice } from '@reduxjs/toolkit';

export const appSettingsSlice = createSlice({
    name: "appsettings",
    initialState: {
        theme: "dark"
    },
    reducers: {
        setTheme: (state, { payload: theme }) => {
            state.theme = theme;
        }
    }

});

export const { setTheme } = appSettingsSlice.actions;



