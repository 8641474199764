import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { fullScreenLeftPadding, fullScreenContentWidth } from "../../styles/dynamicStyles";
import { filterByVisibility, getCharName } from "../../tools/characters";
import { idKeyExtractor, ws } from "../../tools/generic";
import CharacterItem from "../characters/CharacterItem";
import AppPicker from "../generic/AppPicker";
import AppButton from "../generic/buttons/AppButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import SearchBar from "../generic/Searchbar";

// Those are used because several component will observe the characterSelectedId route params on the "Game" screen
// that is sent from here. This makes sure only the proper one reacts to it.
export const SELECT_CHARACTER_WHISPER = "whisper";
export const SELECT_CHARACTER_TALK_TO = "talk_to";
export const SELECT_CHARACTER_CHANGE_AUTHOR = "change_author";
export const SELECT_CHARACTER_CHANGE_ACTIVE = "change_active";

import { BuildStyleMethod } from "../../styles/theming";

function FilteredCharacterList({ fullScreen, characters, characterTags, wide }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const route = useRoute();

	const showNarrator = route.params?.showNarrator;
	const charactersAvailable = route.params?.charactersAvailable;
	if (charactersAvailable) {
		characters = charactersAvailable;
	}

	const visibleCharacters = React.useMemo(() => characters.filter((c) => !c.deleted), [characters]);

	const [sortMode, setSortMode] = React.useState("LAST_ACTIVE");
	const [selectedTagId, setSelectedTagId] = React.useState(null);
	const [searchFilteredCharacters, setSearchFilteredCharacters] = React.useState(visibleCharacters);

	let sendToScreen = route.params?.sendToScreen || "CharacterOptionsModal";

	let code = route.params?.code;

	let filteredCharacters = filterByVisibility(searchFilteredCharacters, characterTags);
	let filteredTags = characterTags.filter((t) => !t.hidden);

	if (selectedTagId) {
		const tag = characterTags.find((t) => t.id === selectedTagId);
		filteredCharacters = filteredCharacters.filter((c) => tag.characters.includes(c.id));
	}

	switch (sortMode) {
		case "NAME":
			filteredCharacters.sort((a, b) => getCharName(a).localeCompare(getCharName(b)));
			break;
		case "RECENT":
			filteredCharacters.sort((a, b) => b.created.localeCompare(a.created));
			break;
		case "OLDEST":
			filteredCharacters.sort((a, b) => a.created.localeCompare(b.created));
			break;
		default:
			filteredCharacters.sort((a, b) => b.last_used.localeCompare(a.last_used));
			break;
	}

	if (showNarrator) {
		filteredCharacters.splice(0, 0, null);
	}

	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={styles.container}>
			{fullScreen && (
				<SearchBar
					autoFocus
					items={visibleCharacters}
					filterBy={(c) => getCharName(c)}
					onFilteredResultUpdated={setSearchFilteredCharacters}
					style={[
						{ marginBottom: 8 },
						wide && ws({ paddingLeft: fullScreenLeftPadding(), width: fullScreenContentWidth() }),
					]}
				/>
			)}

			<View
				style={[
					styles.topBar,
					wide && ws({ paddingLeft: fullScreenLeftPadding(), justifyContent: "flex-start", maxWidth: 500 }),
				]}
			>
				<AppPicker
					selectedValue={sortMode}
					onValueChange={(itemValue, itemIndex) => setSortMode(itemValue)}
					mode="dropdown"
					pickerStyle={{ flex: 1, color: ws(null, "white") }}
					itemStyle={{ color: ws(null, "white") }}
					items={[
						{ label: _("Last active", "sort by"), value: "LAST_ACTIVE" },
						{ label: _("Name", "sort by"), value: "NAME" },
						{ label: _("Created (recent first)", "sort by"), value: "RECENT" },
						{ label: _("Created (oldest first)", "sort by"), value: "OLDEST" },
					]}
				/>

				<AppPicker
					hide={!filteredTags.length}
					icon={{ type: Ionicons, name: "md-pricetag" }}
					selectedValue={selectedTagId}
					onValueChange={(itemValue, itemIndex) => setSelectedTagId(itemValue)}
					pickerStyle={{ flex: 1, color: "white" }}
					itemStyle={{ color: "white" }}
					mode={filteredTags.length < 10 ? "dropdown" : "dialog"}
					items={[{ label: _("None", "no tag selected"), value: null }].concat(
						filteredTags.map((tag, index) => ({ label: tag.name, value: tag.id }))
					)}
				/>

				{!fullScreen && (
					<AppButton
						transparent
						icon={{ type: MaterialIcons, name: "search", size: 24 }}
						size="small"
						onPress={() =>
							navigation.navigate("QuickCharacterSearchScreen", {
								sendToScreen,
								code,
							})
						}
					/>
				)}
			</View>

			<FlatList
				contentContainerStyle={[
					{ flexGrow: 1 },
					wide &&
						ws({
							paddingTop: 16,
							paddingLeft: fullScreenLeftPadding(),
							maxWidth: fullScreenContentWidth(),
						}),
				]}
				keyExtractor={idKeyExtractor}
				data={filteredCharacters}
				renderItem={({ item, index }) => (
					<CharacterItem
						character={item}
						index={index}
						onPress={() => {
							navigation.navigate({
								name: sendToScreen,
								params: {
									characterSelectedId: item?.id,
									characterSelectedCodeSent: code,
								},
								merge: true,
							});
						}}
					/>
				)}
				ListEmptyComponent={() => (
					<EmptyScreenView
						messageTop={
							characters.length
								? _("No results", "no characters match the search terms")
								: _("There are no characters in this game.")
						}
					/>
				)}
				keyboardShouldPersistTaps="handled"
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		characters: state.charactersByGame[gameId] || Array.rg_empty,
		characterTags: state.characterTags[gameId] || Array.rg_empty,
	};
};
export default connect(mapStateToProps)(FilteredCharacterList);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: { flexGrow: 1, flexShrink: 0 },
		topBar: {
			// flex: 1,
			flexDirection: "row",
			marginHorizontal: 4,
			alignItems: "center",
			justifyContent: "space-between",
		},
		picker: {
			flexDirection: "row",
			alignItems: "center",
			backgroundColor: colors.secondary,
			height: 32,
			maxWidth: 160,
			paddingLeft: 4,
			flex: 1,
			marginRight: 4,
			overflow: "hidden",
		},
	})
);
