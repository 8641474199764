export function compareSuccess(numberA, comparator, numberB) {
	switch (comparator) {
		case ">":
			return numberA > numberB;
		case "<":
			return numberA < numberB;
		case ">=":
			return numberA >= numberB;
		case "<=":
			return numberA <= numberB;
		case "=":
			return numberA === numberB;
		default:
			return false;
	}
}

export function ToLargeNumberString(number) {
	if (number > 10000) return Math.floor(number / 1000) * 1000 + "+";
	if (number > 100) return Math.floor(number / 100) * 100 + "+";
	if (number > 10) return Math.floor(number / 10) * 10 + "+";
	return number;
}
