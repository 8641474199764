import * as React from "react";
import { StyleSheet, View } from "react-native";

function ChatInputSubMenu({ children, style }) {
	return <View style={[styles.container, style]}>{children}</View>;
}

export default ChatInputSubMenu;

const styles = StyleSheet.create({
	container: {
		maxHeight: 500,
		width: 300,
	},
});
