import * as React from "react";
import { StyleSheet } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { createCharacterSheet } from "../../../store/slices/sheetsSlice";
import { simpleReducer } from "../../../tools/react";
import AppScreenView from "../../AppScreenView";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import ButtonsRow from "../../generic/buttons/ButtonsRow";
import BoxModal from "../../generic/modal/BoxModal";
import Cond from "../../meta/Cond";
import { connect } from "react-redux";
import { apiFetch } from "../../../network/apiFetch";
import { isWeb, ws } from "../../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";

function WizardCreateGameSheet({ route, navigation, dispatch, user, sheets }) {
	const { game: gameReceived, sheetSelected } = route.params;

	const [game, updateGame] = React.useReducer(simpleReducer, gameReceived);
	const [creating, setcreating] = React.useState(false);

	const confirmReset = React.useRef();
	const confirmChange = React.useRef();

	// const sheetInStore = sheets[game.sheet_template];

	// React.useEffect(() => {
	// 	if (sheetInStore) {
	// 		updateGame({ sheet_template: sheetInStore });
	// 	}
	// }, [sheetInStore]);

	React.useEffect(() => {
		if (route.params?.game) {
			updateGame(route.params?.game);
		}
	}, [route.params?.game]);

	const copyTemplate = React.useCallback(async (template) => {
		setcreating(true);
		apiFetch(`sheets`, "POST", { "copy-id": template.id }).then((newSheet) => {
			updateGame({ sheet_template: newSheet.id, template_selected: template });
			setcreating(false);
		});
	}, []);

	React.useEffect(() => {
		if (sheetSelected) {
			if (sheetSelected.saved_on_profile.id === user.id) {
				updateGame({ sheet_template: sheetSelected.id, template_selected: sheetSelected });
			} else {
				copyTemplate(sheetSelected);
			}

			navigation.setParams({ sheetSelected: null });
		}
	}, [sheetSelected, user.id]);

	const create = React.useCallback(() => {
		setcreating(true);
		dispatch(createCharacterSheet()).then((sheet) => {
			setcreating(false);
			updateGame({ sheet_template: sheet.id, template_selected: null });
			navigation.navigate("CharacterSheetStackWizard", {
				screen: "CharacterSheetTemplateScreen",
				params: { sheetId: sheet.id, isTemplate: true },
			});
		});
	}, []);

	const reset = React.useCallback(() => {
		updateGame({ sheet_template: null, template_selected: null });
	}, []);

	const changePreset = React.useCallback(() => {
		navigation.navigate("CharacterSheetStackWizard", {
			screen: "BrowseSheetTemplatesScreen",
			params: { selectingTemplateFor: route.name },
		});
	}, [route.name]);

	const centered = !isWeb();

	const templateName = game.sheet_template?.save_name || game.template_selected?.save_name;
	return (
		<>
			<AppScreenView
				scroll
				style={ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })}
			>
				<AppText centered={centered} bold style={{ marginTop: 32, marginBottom: 8 }}>
					{_("Character sheet template", "create game wizard")}
				</AppText>
				<AppText centered={centered} style={{ marginBottom: 32 }} color="hint">
					{_(
						"Define the character sheet template that the characters in your game will use.",
						"create game wizard"
					)}
				</AppText>

				<Cond show={!game.sheet_template}>
					<AppButton
						size="large"
						title={_("Create from a preset", "create sheet template")}
						onPress={() =>
							navigation.navigate("CharacterSheetStackWizard", {
								screen: "BrowseSheetTemplatesScreen",
								params: { selectingTemplateFor: route.name },
							})
						}
					/>
					<AppButton
						size="large"
						title={_("Create from scratch", "create sheet template")}
						disabled={creating}
						onPress={create}
					/>
				</Cond>

				<Cond show={game.sheet_template}>
					<Cond show={templateName}>
						<AppText centered={centered}>
							{_("You are using the template", "indicate the select sheet template for the game")}
						</AppText>
						<AppText centered={centered} bold>
							{templateName}
						</AppText>

						<Cond show={game.template_selected?.saved_on_profile?.id === user.id}>
							<AppText centered={centered} color="attentionSecondary" style={{ marginTop: 16 }}>
								{_("Warning", "information for the user")}
							</AppText>
							<AppText centered={centered}>
								{_(
									"You selected one of your own templates. Any edit made here will affect other places where this template is used."
								)}
							</AppText>
						</Cond>
					</Cond>

					<AppText centered={centered} hide={templateName}>
						{_("You are using an unsaved template", "indicate the select sheet template for the game")}
					</AppText>

					<AppButton
						title={_("Edit", "edit character sheet template")}
						onPress={() =>
							navigation.navigate("CharacterSheetStackWizard", {
								screen: "CharacterSheetTemplateScreen",
								params: { sheetId: game.sheet_template, isTemplate: true },
							})
						}
					/>

					<ButtonsRow>
						<AppButton
							title={_("Change preset", "change character sheet template")}
							onPress={() => confirmChange.current.show()}
						/>
						<AppButton
							danger
							title={_("Reset", "reset character sheet template")}
							onPress={() => confirmReset.current.show()}
						/>
					</ButtonsRow>

					<AppText centered={centered} color="attentionSecondary" style={{ marginTop: 16 }}>
						{_("Warning", "information for the user")}
					</AppText>
					<AppText centered={centered}>
						{_(
							"Once your game has started, you cannot load a different template.",
							"create sheet template"
						)}
					</AppText>
				</Cond>
			</AppScreenView>
			<ButtonFooter
				narrow={false}
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.navigate("WizardCreateGameDetails", { game }) },
					{
						title: _("Next"),
						onPress: () => navigation.navigate("WizardCreateGameDiceSets", { game }),
						disabled: !game.sheet_template,
					},
				]}
			/>

			<BoxModal
				ref={confirmChange}
				message={_(
					"Are you sure you want to change preset? You will lose all your changes on the currently edited sheet.",
					"confirm reset game character sheet template"
				)}
				addCancel
				options={[{ title: _("Change preset"), danger: true, onPress: changePreset }]}
			/>

			<BoxModal
				ref={confirmReset}
				message={_(
					"Are you sure you want to reset? You will lose all your changes on the currently edited sheet.",
					"confirm reset game character sheet template"
				)}
				addCancel
				options={[{ title: _("Reset"), danger: true, onPress: reset }]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		sheets: state.sheets,
		user: state.user,
	};
};

export default connect(mapStateToProps)(WizardCreateGameSheet);


