import { useFocusEffect, useNavigationState } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { socket } from "../../network/websocket";
import { fetchCharacters } from "../../store/slices/charactersSlice";
import { fetchCharacterStates } from "../../store/slices/characterStatesSlice";
import { fetchcharacterTags } from "../../store/slices/characterTagsSlice";
import { fetchDiceSets } from "../../store/slices/dicesetsSlice";
import { fetchExportGameRequests } from "../../store/slices/exportGameRequestsSlice";
import { fetchGame, removeGameFromStore, setCurrentGameId } from "../../store/slices/gamesSlice";
import { fetchRollRequests } from "../../store/slices/rollRequestsSlice";
import { fetchStoryMarkers } from "../../store/slices/storyMarkersSlice";
import { fetchTokens } from "../../store/slices/tokensSlice";
import { isPlayer } from "../../tools/games";
import { isIos, isWeb, ws } from "../../tools/generic";
import { gameHeaderOptions, modalScreenOptions, modalStackNavigatorScreenOptions } from "../../tools/navigationOptions";
import { ensureBackScreen } from "../../tools/webnavigation";
import AppScreenView from "../AppScreenView";
import ChangeAvatarScreen from "../avatar/ChangeAvatarScreen";
import CharacterOptionsModal from "../characters/CharacterOptionsModal";
import CharactersScreenModalStack from "../characters/CharactersScreenModalStack";
import FilteredCharacterList from "../characters/FilteredCharacterList";
import ManageCharactersScreen from "../characters/ManageCharactersScreen";
import SelectCharacterModal from "../characters/SelectCharacterModal";
import SelectCharactersModal from "../characters/SelectCharactersModal";
import LanguageSelectionScreen from "../chat/LanguageSelectionScreen";
import ToneSelectionScreen from "../chat/ToneSelectionScreen";
import CoinsModalStack from "../coins/CoinsModalStack";
import DiceRollPoolOptionsScreen from "../diceroll/DiceRollPoolOptionsScreen";
import RollDiceStack from "../diceroll/RollDiceStack";
import SimpleRollModal from "../diceroll/SimpleRollModal";
import DiceSetStack from "../dicesets/DiceSetStack";
import ErrorLoading from "../errors/ErrorLoading";
import GameScreen from "../games/GameScreen";
import JoinGameScreen from "../games/JoinGameScreen";
import NoteFullScreen from "../games/NoteFullScreen";
import NotesScreen from "../games/NotesScreen";
import PublicChatScreen from "../games/PublicChatScreen";
import GameSettingScreen from "../games/settings/GameSettingsScreen";
import ShareGameModal from "../games/ShareGameModal";
import FullScreenTextEdit from "../generic/FullScreenTextEdit";
import UploadImageModal from "../imageupload/UploadImageModal";
import EditLanguageScreen from "../languages/EditLanguageScreen";
import ManageLanguagesScreen from "../languages/ManageLanguagesScreen";
import MacrosNavigation from "../macros/MacrosNavigation";
import ChangePOVModal from "../parties/ChangePOVModal";
import ManagePartiesScreen from "../parties/ManagePartiesScreen";
import UserOptionsModal from "../profile/UserOptionsModal";
import CharacterSheetStack from "../sheets/CharacterSheetStack";
import StageBackgroundSelectionScreen from "../stagebackgrounds/StageBackgroundSelectionScreen";
import IndexScreen from "../storymarkers/IndexScreen";
import CreateTagScreen from "../tags/CreateTagScreen";
import ManageTokensScreen from "../tokens/ManageTokensScreen";
import TokenOptionsModal from "../tokens/TokenOptionsModal";
import SelectPlayerModal from "../users/SelectPlayerModal";
import HomeIcon from "./HomeIcon";
import LineOptionsModalStack from "./LineOptionsModalStack";
import StoryMarkerModalStack from "./StoryMarkerModalStack";

const Stack = createStackNavigator();

export const GameContext = React.createContext(null);

function GameStack({ route, navigation, dispatch, game, currentGameIdSet, user, currentCharacterId, gameReady }) {
	let params = { ...route.params };
	const screen = params.screen;
	delete params.screen;

	const [lastGameLoaderCalled, setlastGameLoaderCalled] = React.useState();

	const { gameslug } = params;

	const localIsPlayer = isPlayer(game, user);

	const navstate = useNavigationState((state) => state);
	React.useEffect(() => {
		ensureBackScreen(navigation, user ? "MyGames" : "LoggedOff");
	}, [navstate, user]);

	useFocusEffect(
		React.useCallback(() => {
			if (game?.id) {
				dispatch(setCurrentGameId(game?.id));
			}
		}, [game?.id])
	);

	const refreshMarkers = React.useCallback(() => {
		const storyMarkersCharacterId = localIsPlayer ? currentCharacterId : null;
		dispatch(fetchStoryMarkers(game.id, storyMarkersCharacterId));
	}, [game?.id, localIsPlayer, currentCharacterId]);

	// Fetch data that requires having characters loaded
	React.useEffect(() => {
		if (gameReady) refreshMarkers();
	}, [gameReady, refreshMarkers]);

	React.useEffect(() => {
		if (!game?.id) return () => null;

		const refreshCharacters = () => dispatch(fetchCharacters(game.id));
		const refreshDiceSets = () => dispatch(fetchDiceSets(game.id));
		const refreshRollRequests = () => dispatch(fetchRollRequests(game.id));
		const refreshTokens = () => dispatch(fetchTokens(game.id));
		const refreshCharacterStates = () => dispatch(fetchCharacterStates(game.id));
		const refreshCharacterTags = () => dispatch(fetchcharacterTags(game.id));
		const refreshGame = async () => dispatch(fetchGame(game.id));
		const getRollRequests = () => dispatch(fetchRollRequests(game.id));
		const getExportGameRequests = () => dispatch(fetchExportGameRequests(game.id));

		const removeGame = () => {
			dispatch(removeGameFromStore(game));
			navigation.navigate(ws("MyGames", "Home"));
		};

		socket.joinRoom(`game_${game.id}`);
		socket.on("roll_requests_updated", getRollRequests);
		socket.on("game_updated", refreshGame);
		socket.on("game_deleted", removeGame);
		socket.on("kicked_out", removeGame);

		socket.on("characters_changed", refreshCharacters);
		socket.on("dice_sets_updated", refreshDiceSets);
		socket.on("markers_updated", refreshMarkers);
		socket.on("roll_requests_updated", refreshRollRequests);
		socket.on("token_updated", refreshTokens);
		socket.on("character_states_updated", refreshCharacterStates);
		socket.on("character_tags_changed", refreshCharacterTags);

		socket.on("export_request_created", getExportGameRequests);

		return () => {
			socket.leaveRoom(`game_${game.id}`);
			socket.off("roll_requests_updated", getRollRequests);
			socket.off("game_updated", refreshGame);
			socket.off("game_deleted", removeGame);
			socket.off("kicked_out", removeGame);

			socket.off("characters_changed", refreshCharacters);
			socket.off("dice_sets_updated", refreshDiceSets);
			socket.off("markers_updated", refreshMarkers);
			socket.off("roll_requests_updated", refreshRollRequests);
			socket.off("token_updated", refreshTokens);
			socket.off("character_states_updated", refreshCharacterStates);
			socket.off("character_tags_changed", refreshCharacterTags);

			socket.off("export_request_created", getExportGameRequests);
		};
	}, [game?.id, user?.id, refreshMarkers]);

	React.useEffect(() => {
		if (game && game.slug !== params.gameslug) navigation.setParams({ gameslug: game.slug });
	}, [game, params]);

	const openDrawer = React.useCallback(() => {
		navigation.openDrawer();
	}, []);

	if (!game?.id) {
		if (lastGameLoaderCalled != route.params.gameslug) {
			navigation.navigate("GameLoader", { sendToGame: true, gameslug: route.params.gameslug });
			setlastGameLoaderCalled(route.params.gameslug);
		}

		return <ErrorLoading />;
	}

	if (game.deleted) {
		return <ErrorLoading />;
	}

	let gameScreenOptions;
	if (isWeb()) {
		gameScreenOptions = ({ route, navigation }) => ({
			...gameHeaderOptions({ route, navigation, game }),
			headerLeft: user ? () => <HomeIcon game={game} onFoldPressed={openDrawer} /> : undefined,
		});
	} else {
		gameScreenOptions = ({ route, navigation }) => gameHeaderOptions({ route, navigation, game });
	}

	return (
		<GameContext.Provider value={game}>
			<Stack.Navigator
				screenOptions={({ navigation: screenNavigation, route }) => {
					// ensureBackScreen should be called in each screen of this stack separately,
					// hooked to when the navigation state update. That would be cleaner and avoid the
					// Cannot update a component ... while rendering a different component ... console warning.
					// That would however be less flexible and create a lot of redundant code.
					// TODO: find a way to write this code only once, but in a different place than here
					if (route.name !== "Game") {
						ensureBackScreen(screenNavigation, "Game", { gameslug: game.slug });
					}

					// animationEnabled: true on FIREFOX only will cause the InteractiveHelperNavigator to unmount->mount everytime you navigate to "Game" with params.
					// This will cause all the components up to that one to unmount/re-mount and cause a bunch of unwanted issues.
					// The most critical being that after "lineToReach" in LinesList has been processed, the component will unmount/mount with lineToReach at null
					// and cause the loading of the latest lines.
					// It won't happen if you have navigate with no params, though. -_-
					return { headerTintColor: global.colors.textDefault, animationEnabled: !isWeb() };
				}}
			>
				<Stack.Screen name="Game" component={GameScreen} options={gameScreenOptions} initialParams={params} />
				<Stack.Screen
					name="GameSettingScreen"
					component={GameSettingScreen}
					options={{
						headerShown: isWeb(),
						headerTitle: _("Settings", "Game settings"),
						title: _("%(game_name)s | Settings | Role Gate", "", { game_name: game.name }),
					}}
					initialParams={params}
				/>
				<Stack.Screen
					name="EditDescription"
					options={{ headerShown: true, headerTitle: _("Description and rules"), cardStyle: {} }}
					component={FullScreenTextEdit}
				/>
				<Stack.Screen
					name="ManageCharactersScreen"
					component={ManageCharactersScreen}
					options={({ route, navigation }) =>
						gameHeaderOptions({
							route,
							navigation,
							game,
							title: _("Characters", "screen title"),
							subtitle: game.name,
						})
					}
					initialParams={{ gameslug }}
				/>

				<Stack.Screen
					name="SelectPlayerModal"
					component={SelectPlayerModal}
					options={modalStackNavigatorScreenOptions}
				/>

				<Stack.Screen
					name="StoryMarkerModalStack"
					component={StoryMarkerModalStack}
					options={modalScreenOptions}
				/>

				{RollDiceStack(Stack)}
				<Stack.Screen
					name="ToneSelectionScreen"
					options={{ headerTitle: _("Select tone") }}
					component={ToneSelectionScreen}
				/>
				<Stack.Screen
					name="LanguageSelectionScreen"
					options={{ headerTitle: _("Select language") }}
					component={LanguageSelectionScreen}
				/>
				<Stack.Screen
					name="CharactersScreenModalStack"
					component={CharactersScreenModalStack}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name="CreateTagScreen"
					component={CreateTagScreen}
					options={{ headerTitle: _("Add tag") }}
				/>
				<Stack.Screen
					name="DiceRollPoolOptionsScreen"
					component={DiceRollPoolOptionsScreen}
					options={{ headerTitle: _("Dice pool option") }}
				/>
				<Stack.Screen name="CompareToPoolModal" component={SimpleRollModal} options={modalScreenOptions} />
				<Stack.Screen
					name="ComparedDiceRollPoolOptionsScreen"
					component={DiceRollPoolOptionsScreen}
					options={{ headerTitle: _("Dice pool option") }}
				/>
				<Stack.Screen
					name="ManagePartiesModal"
					component={ManagePartiesScreen}
					options={ws(modalScreenOptions, { headerTitle: _("Manage parties") })}
				/>
				<Stack.Screen name="ChangePOVModal" component={ChangePOVModal} options={modalScreenOptions} />
				<Stack.Screen name="UploadImageModal" component={UploadImageModal} options={modalScreenOptions} />
				<Stack.Screen name="SelectCharacter" component={SelectCharacterModal} options={modalScreenOptions} />

				<Stack.Screen
					name="IndexScreen"
					component={IndexScreen}
					options={({ route, navigation }) =>
						gameHeaderOptions({
							route,
							navigation,
							game,
							title: _("Index", "game index page"),
							subtitle: game.name,
						})
					}
					initialParams={{ gameslug }}
				/>

				<Stack.Screen
					name="PublicChatScreen"
					component={PublicChatScreen}
					options={({ route, navigation }) =>
						gameHeaderOptions({
							route,
							navigation,
							game,
							title: _("Public chat", "screen title"),
							subtitle: game.name,
						})
					}
					initialParams={params}
				/>
				<Stack.Screen
					name="SelectTalkToCharacterTarget"
					component={SelectCharacterModal}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name="ManageLanguagesScreen"
					component={ManageLanguagesScreen}
					options={({ route, navigation }) =>
						gameHeaderOptions({
							route,
							navigation,
							game,
							title: _("Manage languages", "manage game fictional languages"),
							subtitle: game?.name,
						})
					}
					initialParams={params}
				/>
				<Stack.Screen
					name="EditLanguageScreen"
					component={EditLanguageScreen}
					options={{ headerTitle: _("Edit language", "manage game fictional languages") }}
				/>

				<Stack.Screen
					name="ManageTokensScreen"
					component={ManageTokensScreen}
					options={{ headerTitle: _("Tokens", "manage tokens on character") }}
				/>
				<Stack.Screen name="TokenOptionsModal" component={TokenOptionsModal} options={modalScreenOptions} />

				<Stack.Screen
					name="JoinGameScreen"
					component={JoinGameScreen}
					options={({ route, navigation }) =>
						gameHeaderOptions({
							route,
							navigation,
							game,
							title: game?.name,
							subtitle: _("Join game", "screen title"),
						})
					}
					initialParams={params}
				/>
				<Stack.Screen
					name="ChangeAvatarScreen"
					component={ChangeAvatarScreen}
					options={{ headerTitle: _("Change Avatar", "screen title"), title: _("Change Avatar | Role Gate") }}
				/>
				<Stack.Screen
					name="NotesScreen"
					component={NotesScreen}
					options={{ headerTitle: _("Notes"), title: _("Notes | Role Gate") }}
					initialParams={{ gameslug }}
				/>
				<Stack.Screen
					name="NoteFullScreen"
					component={NoteFullScreen}
					options={{ headerTitle: _("Notes"), title: _("Notes | Role Gate") }}
				/>

				{/* These screens are duplicates from other stacks, 
			but must be added here too or the Game stack will get unmounted on Web when navigating to them */}
				{LineOptionsModalStack(Stack)}
				<Stack.Screen name="SelectTargets" component={SelectCharactersModal} options={modalScreenOptions} />

				<Stack.Screen
					name="CharacterSheetStackGame"
					component={CharacterSheetStack}
					options={() => ({ headerShown: false })}
				/>
				<Stack.Screen
					name="CharacterOptionsModal"
					component={CharacterOptionsModal}
					options={modalScreenOptions}
				/>

				<Stack.Screen
					name="SelectCover"
					children={(props) => (
						<StageBackgroundSelectionScreen {...props} previousScreenName="GameSettingScreen" />
					)}
					options={{
						headerShown: true,
						headerTitle: _("Select game cover"),
						cardStyle: {},
						title: _("Select game cover | Role Gate"),
					}}
				/>

				<Stack.Screen
					name="QuickCharacterSearchScreen"
					children={() => (
						<AppScreenView scroll useSafeArea={!isIos()} borderless borderTop>
							<FilteredCharacterList fullScreen wide />
						</AppScreenView>
					)}
					options={{
						headerTitle: _("Select character", "menu title"),
						headerShown: isIos() || isWeb(),
						title: _("Select character | Role Gate"),
					}}
				/>
				<Stack.Screen name="UserOptionsModal" component={UserOptionsModal} options={modalScreenOptions} />
				<Stack.Screen name="ShareGameModal" component={ShareGameModal} options={modalScreenOptions} />
				<Stack.Screen
					name="CreateGameTagsScreen"
					component={CreateTagScreen}
					options={({ route, navigation }) => ({
						headerTitle: _("Select or create tags", "select game tags"),
						title: _("Select or create tags | Role Gate"),
						unmountOnBlur: isWeb(),
					})}
				/>
				{MacrosNavigation(Stack, { gameslug }, game)}
				<Stack.Screen
					name="DiceSetStack"
					component={DiceSetStack}
					options={{
						title: _("Dice sets | Role Gate"),
						headerTitle: _("Dice sets", "menu title"),
					}}
				/>
				{CoinsModalStack(Stack)}
			</Stack.Navigator>
		</GameContext.Provider>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameslug = ownProps.route.params?.gameslug;
	const game = state.games[gameslug];
	const gameUI = state.gamesUI[game?.id];

	return {
		game: game,
		currentGameIdSet: state.games.currentId,
		user: state.user,
		currentCharacterId: gameUI?.currentCharacterId,
		gameReady: gameUI?.gameReady,
	};
};

export default connect(mapStateToProps)(GameStack);
