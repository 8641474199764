import * as React from "react";
import { View } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";

export function DieRuleSummary({ rules }) {
	if (!rules) return null;

	const ruleInfo = [];

	// prettier-ignore
	{
	rules.keepHighest && ruleInfo.push(_("Keep %(number)s", "Keep highest X", { number: rules.keepHighest_value }));
	rules.keepLowest && ruleInfo.push(_("Keep low %(number)s", "Keep lowest X", { number: rules.keepLowest_value }));
	rules.dropHighest && ruleInfo.push(_("Drop high %(number)s", "drop x highest", { number: rules.dropHighest_value }));
	rules.dropLowest && ruleInfo.push(_("Drop %(number)s", "drop x lowest", { number: rules.dropLowest_value }));
	rules.explode && ruleInfo.push(_("Expl.", "explode dice"));
	rules.successAbove && ruleInfo.push(_("Succ. >%(number)s", "success above", {number: rules.successAbove_value}));
	rules.successBelow && ruleInfo.push(_("Succ. <%(number)s", "success below", {number: rules.successBelow_value}));
	rules.reroll && ruleInfo.push(_("Re %(number)s", "reroll number", {number: rules.reroll_value}));
	rules.rerollOnce && ruleInfo.push(_("Re (x1) %(number)s", "reroll once number", {number: rules.rerollOnce_value}));
	rules.rerollAbove && ruleInfo.push(_("Re >%(number)s", "reroll above number", {number: rules.rerollAbove_value}));
	rules.rerollAboveOnce && ruleInfo.push(_("Re (x1) >%(number)s", "reroll once above number", {number: rules.rerollAboveOnce_value}));
	rules.rerollBelow && ruleInfo.push(_("Re <%(number)s", "reroll below number", {number: rules.rerollBelow_value}));
	rules.rerollBelowOnce && ruleInfo.push(_("Re (x1) <%(number)s", "reroll once below number", {number: rules.rerollBelowOnce_value}));
	}

	return (
		<AppText centered numberOfLines={2} ellipsizeMode="tail">
			{ruleInfo.map((ri, index) => (
				<AppText color="hint" key={index}>
					{ri}
					{index < ruleInfo.length - 1 && ", "}
				</AppText>
			))}
		</AppText>
	);
}
