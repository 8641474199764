import * as React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchCharacters } from "../../store/slices/charactersSlice";
import { fetchSheet } from "../../store/slices/sheetsSlice";
import { colors } from "../../styles/colors";
import ErrorLoading from "../errors/ErrorLoading";

function CharacterSheetLoader(props) {
	const { RenderComponent, route, navigation, dispatch, character, sheet, game } = props;
	const [error, seterror] = React.useState(false);

	const [sheetOnOpen, setsheetOnOpen] = React.useState(sheet);

	let { sheetId, characterId, gameId } = route.params;

	gameId = gameId || game?.id;

	React.useEffect(() => {
		if(sheet) return;

		const doFetch = async (id) => {
			const success = await dispatch(fetchSheet(id));
			if (!success) {
				seterror(true);
			}else{
				seterror(false);
			}
		};

		if (sheetId && !sheet) {
			doFetch(sheetId);
		} else if (!sheet && character) {
			doFetch(character.sheet);
		} else if (!sheetId && !character && characterId && gameId) {
			dispatch(fetchCharacters(gameId));
		} else if (!sheetId && !character && !characterId && game?.sheet_template) {			
			doFetch(game.sheet_template)
		}
	}, [sheetId, characterId, sheet, character, gameId, game?.sheet_template]);

	React.useEffect(() => {
		if (sheet && !sheetOnOpen) {
			navigation.setOptions({
				headerTitle: sheet.save_name || _("Edit template", "edit character sheet template"),
			});
		}
	}, [sheet, characterId, sheetOnOpen]);

	if (error || (!sheetId && !characterId && !game)) {
		return <ErrorLoading />;
	}

	if (!sheet) {
		return (
			<View style={{ alignContent: "center", justifyContent: "center", flex: 1 }}>
				<ActivityIndicator color={global.colors.hint} />
			</View>
		);
	}

	return <RenderComponent {...props} />;
}

const mapStateToProps = (state, ownProps) => {
	let { sheetId, characterId, gameId } = ownProps.route.params;

	const sheets = state.sheets;
	const character = state.characters[characterId];
	const game = state.games[gameId || ownProps.gameslug];
	let sheet = sheets[sheetId || character?.sheet || game?.sheet_template];

	return {
		sheet,
		character,
		game,
	};
};

export default connect(mapStateToProps)(CharacterSheetLoader);


