import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { removeMacroGroups } from "../../store/slices/macroGroupsSlice";
import { removeMacros } from "../../store/slices/macrosSlice";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";
import { useNavigation } from "@react-navigation/native";

function MacroGroupItem({ sheetId, macroGroup, onSelected, index, options, isSelected, dispatch, allMacros, user }) {
	const deleteModalRef = React.useRef();
	const macros = React.useMemo(() => Object.values(allMacros).filter((m) => m.group === macroGroup.id), [allMacros]);
	const navigation = useNavigation();

	
	options = options?.slice() || [];
	user && macroGroup.user === user.id && options.push({
		title: _("Delete"),
		danger: true,
		onPress: () => deleteModalRef.current.show(),
	});

	const deleteMacroGroup = () => {
		dispatch(removeMacros(macros));
		dispatch(removeMacroGroups([macroGroup]));
		apiFetch(`users/${user.id}/dice-macro-groups/${macroGroup.id}`, "DELETE");
	};

	return (
		<>
			<ListButton
				index={index}
				title={macroGroup.name}
				subtitle={macroGroup.description || _("Dice macro group", "default macro group description")}
				onPress={() => navigation.navigate("MacroGroupScreen", { macroGroup, sheetId })}
				subtitleTextProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
				iconLeft={{ type: MaterialCommunityIcons, name: "dice-multiple" }}
				subiconLeft={{ type: MaterialIcons, name: "folder" }}
				options={options}
				isSelected={isSelected}
			/>
			<BoxModal
				ref={deleteModalRef}
				message={_(
					"Are you sure you want to delete the group %(macro_group)s? It will destroy all its macros and this action cannot be reverted.",
					"",
					{ macro_group: macroGroup.name }
				)}
				isDelete
				onConfirm={deleteMacroGroup}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		allMacros: state.macros,
	};
};

export default connect(mapStateToProps)(MacroGroupItem);


