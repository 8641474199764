import * as React from "react";
import { Dimensions, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import Modal from "../../../reimports/Modal";
import { arrow } from "../../../styles/arrows";
import { colors } from "../../../styles/colors";
import { isWeb, ws } from "../../../tools/generic";
import CondView from "../../meta/CondView";
import AppText from "../AppText";
import AppModal from "../modal/AppModal";

import { BuildStyleMethod } from "../../../styles/theming";

function InternalPopoverFullScreen({
	popoverComponent,
	targetMeasure,
	arrowPosition,
	visible,
	closeOnTap = true,
	onRequestClose,
	title,
	displacementY = 0,
}) {
	const styles = stylesMethod(global.theme);
	const [popoverMeasure, setpopoverMeasure] = React.useState(null);

	const popoverRef = React.useRef();

	const arrowHalfWidth = 5;

	const savePopoverMeasure = React.useCallback((x, y, width, height) => {
		setpopoverMeasure({ x, y, width, height });
	}, []);

	const getTopPosition = React.useCallback(
		(windowDim) => {
			const topMax = windowDim.height - (popoverMeasure?.height || 0) - ws(10, 10);
			let position = 0;
			switch (arrowPosition) {
				case "bottom":
					return null;
				case "top":
					position = targetMeasure.y + targetMeasure.height + ws(10, 58);
					break;
				default:
					position = targetMeasure.y;
					break;
			}
			return Math.max(0, Math.min(position, topMax));
		},
		[popoverMeasure, arrowPosition, targetMeasure]
	);

	const getRightPosition = React.useCallback(
		(windowDim) => {
			let position = 0;
			switch (arrowPosition) {
				case "left":
					return null;
				case "right":
					position = windowDim.width - targetMeasure.x;
					break;
				default:
					position =
						windowDim.width -
						targetMeasure.x -
						targetMeasure.width * 0.5 -
						(popoverMeasure?.width * 0.5 || 0) +
						arrowHalfWidth;
					break;
			}
			const maxPosition = windowDim.width - (popoverMeasure?.width || 0);
			return Math.min(maxPosition, Math.max(position, 4));
		},
		[popoverMeasure, arrowPosition, targetMeasure]
	);

	const getLeftPosition = React.useCallback(
		(windowDim) => {
			if (arrowPosition !== "left") return null;
			let position = targetMeasure.x + targetMeasure.width + ws(10, 58);

			const maxPosition = windowDim.width - (popoverMeasure?.width || 0);
			return Math.min(maxPosition, Math.max(position, 4));
		},
		[targetMeasure, popoverMeasure, displacementY, arrowPosition]
	);

	const getBottomPosition = React.useCallback(
		(windowDim) => {
			if (arrowPosition !== "bottom") return null;

			let position = windowDim.height - targetMeasure.y + displacementY + ws(10, 58);

			return Math.min(windowDim.height - (popoverMeasure?.height || 40), position);
		},
		[targetMeasure, popoverMeasure, displacementY, arrowPosition]
	);

	const windowDim = Dimensions.get("window");

	const topPosition = getTopPosition(windowDim);
	const rightPosition = getRightPosition(windowDim);
	const leftPosition = getLeftPosition(windowDim);
	const bottomPosition = getBottomPosition(windowDim);

	let positionStyle = { top: topPosition, right: rightPosition, bottom: bottomPosition, left: leftPosition };

	const ContainerTag = closeOnTap ? Modal : AppModal;

	return (
		<ContainerTag
			animationType="fade"
			visible={visible}
			transparent
			presentationStyle="overFullScreen"
			onRequestClose={onRequestClose}
			style={popoverMeasure ? null : { overflow: "hidden", opacity: 0 }}
			allowTapThrough={!closeOnTap}
		>
			{closeOnTap && (
				<TouchableWithoutFeedback style={styles.modalBackdrop} onPress={onRequestClose}>
					<View style={styles.modalBackdrop} />
				</TouchableWithoutFeedback>
			)}
			<View
				ref={popoverRef}
				style={[styles.popoverContainer, positionStyle]}
				onLayout={() => {
					if (popoverRef.current) {
						popoverRef.current.measureInWindow(savePopoverMeasure);
					}
				}}
				pointerEvents="auto"
			>
				<CondView
					show={!!arrowPosition}
					style={arrow(arrowPosition, { color: global.colors.secondary }, popoverMeasure)}
				/>
				{!!title && (
					<View style={styles.title}>
						<AppText color="secondary">{title}</AppText>
					</View>
				)}
				{popoverComponent}
			</View>
		</ContainerTag>
	);
}

export default InternalPopoverFullScreen;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {},
		title: {
			padding: 8,
			borderBottomColor: colors.lightBorder,
			borderBottomWidth: 1,
		},

		popoverContainer: {
			position: "absolute",
			backgroundColor: colors.cardBackground,
			shadowColor: "black",
			shadowOffset: { width: 1, height: 1 },
			shadowOpacity: 1,
			shadowRadius: 1,
			elevation: 10,
		},
		modalBackdrop: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},
	})
);
