import { combineReducers } from "@reduxjs/toolkit";
import authtokenReducer from "./slices/authtokenSlice";
import avatarsReducer from "./slices/avatarsSlice";
import { charactersByGameSlice, charactersSlice } from "./slices/charactersSlice";
import characterStatesReducer from "./slices/characterStatesSlice";
import { characterTagsSlice } from "./slices/characterTagsSlice";
import { diceSetsSlice } from "./slices/dicesetsSlice";
import { diceSlice } from "./slices/diceSlice";
import { gamesSlice } from "./slices/gamesSlice";
import { gamesUISlice } from "./slices/gamesUISlice";
import { gameTagsSlice, gameTagsStoreSlice, recommendedGameTagsSlice, lookedForTagsSlice } from "./slices/gameTagsSlice";
import { languagesSlice } from "./slices/languagesSlice";
import {
	chatLinesSlice,
	commentLinesSlice,
	gameLinesBoundaries,
	linesSlice,
	storyLinesSlice,
} from "./slices/linesSlice";
import { macroGroupsSlice } from "./slices/macroGroupsSlice";
import { macrosSlice } from "./slices/macrosSlice";
import { partiesSlice } from "./slices/partiesSlice";
import { rollRequestSlice } from "./slices/rollRequestsSlice";
import { savedStoreSlice } from "./slices/savedStore";
import { sheetNodesSlice } from "./slices/sheetNodesSlice";
import { sheetsSlice, sheetUISlice } from "./slices/sheetsSlice";
import { sheetTabsSlice } from "./slices/sheetTabsSlice";
import { sheetValuePairsSlice } from "./slices/sheetValuePairsSlice";
import { stageBackgroundsSlice } from "./slices/stagebackgroundsSlice";
import { storyMarkersSlice } from "./slices/storyMarkersSlice";
import { subscriptionSlice } from "./slices/subscriptionSlice";
import { symbolInteractionsSlice } from "./slices/symbolInteractionsSlice";
import { tokensSlice } from "./slices/tokensSlice";
import { userSlice, usersSlice } from "./slices/usersSlice";
import { gameSamplesSlice } from "./slices/gameSamplesSlice";
import { bookmarkForGamesSlice } from "./slices/bookmarksSlice";
import { interactiveHelpersSlice } from "./slices/interactiveHelpersSlice";
import { avatarRequestSlice } from "./slices/avatarRequestSlice";
import { notificationsSlice } from "./slices/notificationsSlice";
import { friendsSlice } from "./slices/friendsSlice";
import { messageGroupsSlice } from "./slices/messageGroupsSlice";
import { directMessagesSlice } from "./slices/directMessagesSlice";
import { directMessageUISlice } from "./slices/directMessageUISlice";
import { featuresSlice } from "./slices/featuresSlice";
import { appSettingsSlice } from "./slices/appSettingsSlice";
import { exportGameRequestsSlice } from "./slices/exportGameRequestsSlice";
import { gameAlertsSlice } from "./slices/gamealertsSlice";

const rootReducer = combineReducers({
	savedStore: savedStoreSlice.reducer,
	user: userSlice.reducer,
	users: usersSlice.reducer,
	authToken: authtokenReducer,
	games: gamesSlice.reducer,
	avatars: avatarsReducer,
	characters: charactersSlice.reducer,
	charactersByGame: charactersByGameSlice.reducer,
	characterStates: characterStatesReducer,
	lines: linesSlice.reducer,
	storyLines: storyLinesSlice.reducer,
	chatLines: chatLinesSlice.reducer,
	commentLines: commentLinesSlice.reducer,
	gameLinesBoundaries: gameLinesBoundaries.reducer,
	sheets: sheetsSlice.reducer,
	sheetValuePairs: sheetValuePairsSlice.reducer,
	sheetTabs: sheetTabsSlice.reducer,
	sheetNodes: sheetNodesSlice.reducer,
	sheetUI: sheetUISlice.reducer,
	dice: diceSlice.reducer,
	diceSets: diceSetsSlice.reducer,
	symbolInteractions: symbolInteractionsSlice.reducer,
	storyMarkers: storyMarkersSlice.reducer,
	gamesUI: gamesUISlice.reducer,
	stageBackgrounds: stageBackgroundsSlice.reducer,
	subscriptionInfo: subscriptionSlice.reducer,
	characterTags: characterTagsSlice.reducer,
	parties: partiesSlice.reducer,
	rollRequests: rollRequestSlice.reducer,
	macros: macrosSlice.reducer,
	macroGroups: macroGroupsSlice.reducer,
	languages: languagesSlice.reducer,
	tokens: tokensSlice.reducer,
	gameTags: gameTagsSlice.reducer,
	gameTagsStore: gameTagsStoreSlice.reducer,
	recommendedGameTags: recommendedGameTagsSlice.reducer,
	lookedForTags: lookedForTagsSlice.reducer,
	gameSamples: gameSamplesSlice.reducer,
	bookmarkForGames: bookmarkForGamesSlice.reducer,
	interactiveHelpers: interactiveHelpersSlice.reducer,
	avatarRequest: avatarRequestSlice.reducer,
	notifications: notificationsSlice.reducer,
	friends: friendsSlice.reducer,
	messageGroups: messageGroupsSlice.reducer,
	directMessages: directMessagesSlice.reducer,
	directMessageUI: directMessageUISlice.reducer,
	features: featuresSlice.reducer,
	appsettings: appSettingsSlice.reducer,
	exportGameRequests: exportGameRequestsSlice.reducer,
	gameAlerts: gameAlertsSlice.reducer,
});

const reducer = (state, action) => {
	if (action.type === "CLEAR_STORE") {
		return rootReducer(undefined, action);
	}

	return rootReducer(state, action);
};

export default reducer;
