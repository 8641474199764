import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import { fetchFeatures, fetchNextFeatures, receiveFeatures } from "../../store/slices/featuresSlice";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import VoteFeatureItem from "./VoteFeatureItem";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { useLayout } from "react-native-web-hooks";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";

function VoteFeatureScreen({ navigation, route, dispatch, features }) {
	const [nextUrl, setnextUrl] = React.useState(null);
	const [refreshing, setrefreshing] = React.useState(false);

	const fetchStart = React.useCallback(async () => {
		setrefreshing(true);
		const next = await dispatch(fetchFeatures());
		setrefreshing(false);
		setnextUrl(next);
	}, []);

	React.useEffect(() => {
		if (features.length) return () => null;
		fetchStart();
	}, [features]);

	const fecthNext = React.useCallback(() => {
		if (!nextUrl) return null;

		const fetch = async () => {
			const next = await dispatch(fetchNextFeatures(nextUrl));
			setnextUrl(next);
		};
		fetch();
	}, [nextUrl]);

	// Refresh featured after vote
	React.useEffect(() => {
		if (route.params?.coinGivenLevel) {
			navigation.setParams({ coinGivenLevel: null });
			apiFetch(`features/${route.params.subjectGetter.feature_id}`).then((feature) =>
				dispatch(receiveFeatures([feature]))
			);
		}
	}, [route.params?.coinGivenLevel]);

	const { onLayout } = useLayout();

	return (
		<AppScreenView onLayout={onLayout}>
			<FlatList
				contentContainerStyle={[
					ws({
						paddingLeft: fullScreenLeftPadding(),
						maxWidth: fullScreenContentWidth(),
					}),
				]}
				ListHeaderComponent={() => (
					<AppText color="hint" centered style={{ marginVertical: 16 }}>
						{ws(_("Click a feature to get more info"), _("Tap a feature to get more info"))}
					</AppText>
				)}
				data={features}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => <VoteFeatureItem feature={item} />}
				onEndReached={fecthNext}
				onRefresh={fetchStart}
				refreshing={refreshing}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		features: state.features,
	};
};

export default connect(mapStateToProps)(VoteFeatureScreen);


