import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { _ } from "../../i18n/i18n";
import { ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import DropdownListItem from "../generic/DropdownListItem";
import InputField from "../generic/InputField";
import OAIOutput from "./OAIOutput";
import OAIRequestButton from "./OAIRequestButton";
import OAIUsePregenerated from "./OAIUsePregenerated";

function OAIDescriber() {
	const [mode, setmode] = React.useState("CHARACTER_DESCRIPTION");
	const [theme, settheme] = React.useState("");
	const [features, setfeatures] = React.useState("");
	const [featuresError, setfeaturesError] = React.useState();
	const [gender, setgender] = React.useState("Any");
	const [locationType, setlocationType] = React.useState("");
	const [itemType, setitemType] = React.useState("");
	const [hasGenerated, sethasGenerated] = React.useState(false);
	const [searchQuery, setsearchQuery] = React.useState();

	const [output, setoutput] = React.useState();
	const [waitingForResponse, setwaitingForResponse] = React.useState(false);

	// React.useEffect(() => {
	// 	apiFetch(`users/1/openairequest/8`).then(setoutput);
	// }, []);

	React.useEffect(() => {
		const featuresArray = features.split(",");
		if (featuresArray.length > 5) {
			setfeaturesError("Please limit yourself to 5 must-have features");
		} else if (featuresError) {
			setfeaturesError(null);
		}

		setsearchQuery([theme, locationType, itemType, ...featuresArray].join(","));
	}, [features, featuresError, theme, locationType, itemType]);

	const genderList = [
		{ label: _("Any", "AI description generation option"), value: "Any" },
		{ label: _("Male", "AI description generation option"), value: "Male" },
		{ label: _("Female", "AI description generation option"), value: "Female" },
		{ label: _("Neutral", "AI description generation option"), value: "Neutral" },
	];

	genderList.sort((a, b) => a.label.localeCompare(b.label));

	return (
		<View style={styles.container}>
			<AppText bold size="large" style={{ marginBottom: 8 }}>
				{_("Generate a description")}
			</AppText>

			<AppText color="hint">
				{_("Use this tool to have an advanced AI generate creative descriptions for you.")}
			</AppText>
			<AppText color="hint" hide={global.appleStoreReview}>
				{_(
					"Generating content will cost you **AI tokens**. Press the %(icon)s next to the ***Generate*** button to track your usage.",
					"",
					{ icon: <MaterialIcons name="info-outline" key="ic" /> }
				)}
			</AppText>

			<Divider theme={{ dark: true }} style={{ marginVertical: 16 }} />

			<View style={{ maxWidth: 500 }}>
				<DropdownListItem
					label={_("Element to describe", "AI description generation")}
					selectedValue={mode}
					setValue={setmode}
					items={[
						{ label: _("Character", "AI description generation option"), value: "CHARACTER_DESCRIPTION" },
						{ label: _("Location", "AI description generation option"), value: "LOCATION_DESCRIPTION" },
						{ label: _("Magic item", "AI description generation option"), value: "MAGIC_ITEM" },
					]}
				/>

				{mode === "CHARACTER_DESCRIPTION" && (
					<DropdownListItem
						style={{ marginTop: 16 }}
						label={_("Gender", "AI description generation")}
						selectedValue={gender}
						setValue={setgender}
						items={genderList}
					/>
				)}
			</View>

			{mode === "LOCATION_DESCRIPTION" && (
				<InputField
					style={{ marginTop: 16 }}
					label={_("Location type", "Type of the location generated by the AI")}
					placeholder={_("Tavern, Town, Street, etc.", "location type example")}
					value={locationType}
					onChangeText={setlocationType}
					maxLength={20}
				/>
			)}

			{mode === "MAGIC_ITEM" && (
				<InputField
					style={{ marginTop: 16 }}
					label={_("Item type (optional)", "Type of magic item generated by the AI")}
					placeholder={_("Sword, Ring, Armor, etc.", "location type example")}
					value={itemType}
					onChangeText={setitemType}
					maxLength={20}
				/>
			)}

			<InputField
				style={{ marginTop: 16 }}
				label={_("Theme (optional)", "theme of the content generated by the AI")}
				placeholder={_("Fantasy, Cyberpunk, Urban, etc.", "theme example")}
				value={theme}
				onChangeText={settheme}
				maxLength={20}
			/>

			<AppText color="hint" style={{ marginTop: 16 }}>
				{_("Write up to 5 must-have features, separated by a comma. Can be left empty.")}
			</AppText>
			<InputField
				label={_("Must have features (optional)", "AI description generation")}
				placeholder={_('Example: "Rich, large, dark"', "AI description generation must-have feature example")}
				value={features}
				onChangeText={setfeatures}
				maxLength={70}
			/>
			{featuresError && <AppText color="danger">{featuresError}</AppText>}

			<View style={{ marginBottom: 8 }} />
			<OAIOutput oairequest={output} loading={waitingForResponse} />

			<View
				style={[
					ws(
						{ flexDirection: "row", justifyContent: "space-around" },
						{ flexDirection: "column", marginTop: 8 }
					),
					{ alignItems: "center" },
				]}
			>
				<OAIUsePregenerated
					requestType={mode}
					setwaitingForResponse={setwaitingForResponse}
					onPregeneratedSet={(output) => {
						sethasGenerated(false);
						setoutput(output);
					}}
					searchQuery={searchQuery}
					buttonStyle={{ width: 250 }}
				/>
				<OAIRequestButton
					title={hasGenerated ? _("Generate another", "request the AI to re-generate content") : undefined}
					endpoint={mode.toLowerCase().replace("_", "-")}
					requestType={mode}
					requestParams={{ theme, gender, features, location_type: locationType, item_type: itemType }}
					setoutput={(output) => {
						sethasGenerated(true);
						setoutput(output);
					}}
					onWaitingForResponseChanged={setwaitingForResponse}
					disabled={featuresError}
					buttonStyle={{ width: 250 }}
				/>
			</View>
		</View>
	);
}

export default OAIDescriber;

const styles = StyleSheet.create({
	container: {},
});
