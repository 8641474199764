import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import PartySeparator from "./PartySeparator";

function CurrentPartyHeader({ dispatch, parties, currentStoryMarker }) {
	const navigation = useNavigation();

	const shownParty = parties[currentStoryMarker?.party];

	if (!shownParty) return null;

	return (
		<View style={styles.container}>
			<PartySeparator party={shownParty} small />
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameUI = state.gamesUI[ownProps.gameId] || {};
	return {
		parties: state.parties[ownProps.gameId].store,
		currentStoryMarker: gameUI.currentStoryMarker,
	};
};

export default connect(mapStateToProps)(CurrentPartyHeader);

const styles = StyleSheet.create({
	container: {
		marginVertical: 8,
		marginHorizontal: 16,
		flexDirection: "row",
	},
});
