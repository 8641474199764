import * as React from "react";
import { View, Image, StyleSheet, TouchableOpacity } from "react-native";
import AppText from "../generic/AppText";
import CondView from "../meta/CondView";
import { connect } from "react-redux";
import { getMarkerTitle, pathToBackground } from "../../tools/storyMarker";
import { _ } from "../../i18n/i18n";
import { useNavigation } from "@react-navigation/native";
import { ws } from "../../tools/generic";

function StoryMarker({ marker, storyMarkers }) {
	if (!marker) {
		return null;
	}

	const navigation = useNavigation();

	const group = storyMarkers.filter((sm) => sm.start_line_id === marker.start_line_id);
	const markerWithBackground = group.find((sm) => sm.stage && sm.stage.background);

	let backgroundName = "neutral_1";
	if (markerWithBackground && markerWithBackground.stage && markerWithBackground.stage.background) {
		backgroundName = markerWithBackground.stage.background.name;
	}

	const pathToBg = pathToBackground(markerWithBackground);

	const markerTitle = getMarkerTitle(marker, storyMarkers);

	const styleContainer = [styles.container];
	group.rg_last() == marker && styleContainer.push(styles.containerLast);

	return (
		<View style={styleContainer}>
			<TouchableOpacity
				onPress={() =>
					navigation.navigate("StoryMarkerModalStack", {
						screen: "StoryMarkerModal",
						params: { markerId: marker.id },
					})
				}
				// onPress={() => navigation.navigate("Modal", { marker })}
				activeOpacity={0.95}
			>
				<CondView show={marker == markerWithBackground}>
					<Image source={{ uri: pathToBg }} style={[styles.image]} resizeMode="cover" />
				</CondView>
				<View style={[styles.textContainer]}>
					<AppText color="secondary" size="small" centered>
						{markerTitle}
					</AppText>
					<AppText color="textLight" centered size={marker.type === 1 ? "large" : "average"}>
						{marker.name}
					</AppText>

					<AppText
						hide={group.rg_last() !== marker}
						color="hint"
						centered
						size="small"
						style={{ marginTop: 4 }}
					>
						{ws(
							_("click for details", "details for story markers"),
							_("tap for details", "details for story markers")
						)}
					</AppText>
				</View>
			</TouchableOpacity>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		marginHorizontal: -8,
	},
	containerLast: {
		marginBottom: 16,
	},
	textContainer: {
		backgroundColor: "black",
		paddingBottom: 8,
	},
	image: {
		backgroundColor: "black",
		height: 136,		
		marginBottom: -45,
		marginTop: 16,
	},
});

const mapStateToProps = (state, ownProps) => {
	return {
		storyMarkers: state.storyMarkers[ownProps.gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(StoryMarker);
