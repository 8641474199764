import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { modalScreenOptions, modalStackNavigatorScreenOptions } from "../../tools/navigationOptions";
import SelectCharacterModal from "../characters/SelectCharacterModal";
import LineChangeTypeModal from "../lines/LineChangeTypeModal";
import LineEditModal from "../lines/LineEditModal";
import LineOptionsModal from "../lines/LineOptionsModal";
const Stack = createStackNavigator();

function LineOptionsModalStack(Stack) {
	return (
		<Stack.Group screenOptions={modalStackNavigatorScreenOptions}>
			<Stack.Screen name="LineOptionsModal" component={LineOptionsModal} options={modalScreenOptions}/>
			<Stack.Screen name="LineEditModal" component={LineEditModal} options={modalScreenOptions}/>
			<Stack.Screen name="LineChangeTypeModal" component={LineChangeTypeModal} />
		</Stack.Group>
	);
}

export default LineOptionsModalStack;
