import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/core";
import * as React from "react";
import { SectionList, StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";
import { storeHelpers } from "../../store/slices/interactiveHelpersSlice";
import { fetchUser, receiveUsers } from "../../store/slices/usersSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles } from "../../styles/global";
import { levelToCoinFileName, levelToRankName } from "../../tools/account";
import { isWeb, ws } from "../../tools/generic";
import { simpleReducer } from "../../tools/react";
import CharacterAvatar from "../avatar/CharacterAvatar";
import Chest from "../coins/Chest";
import Purse from "../coins/Purse";
import DiceSetItem from "../dicesets/DiceSetItem";
import ErrorLoading from "../errors/ErrorLoading";
import ActiveGameItem from "../games/ActiveGameItem";
import AppText from "../generic/AppText";
import EmptyScreenView from "../generic/EmptyScreenView";
import FullScreenLoader from "../generic/FullScreenLoader";
import HorizontalTabs from "../generic/HorizontalTabs";
import InteractiveHelperItem from "../interactivehelpers/InteractiveHelperItem";
import RGIcon from "../RGIcon";
import CharacterSheetItem from "../sheets/CharacterSheetItem";
import AddFriendButton from "./AddFriendButton";
import AppButton from "../generic/buttons/AppButton";

function ProfilePage({ route, navigation, dispatch, pageUser, currentUser }) {
	function getRelativeTimeAgo(relevantDate) {
		relevantDate = new Date(relevantDate);
		const nowDiffInSeconds = (Date.now() - relevantDate.getTime()) / 1000;

		if(nowDiffInSeconds < 60) {
			return 'Just Now';
		} else if(nowDiffInSeconds < (60 * 60)) {
			const diffInMinutes = Math.floor(nowDiffInSeconds / 60);
			return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
		} else if(nowDiffInSeconds < (60 * 60 * 24)) {
			const diffInHours = Math.floor(nowDiffInSeconds / (60 * 60));
			return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
		} else if(nowDiffInSeconds < (60 * 60 * 24 * 7)) {
			const diffInDays = Math.floor(nowDiffInSeconds / (60 * 60 * 24));
			return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
		} else if(nowDiffInSeconds < (60 * 60 * 24 * 30)) {
			const diffInWeeks = Math.floor(nowDiffInSeconds / (60 * 60 * 24 * 7));
			return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago`;
		} else if(nowDiffInSeconds < (60 * 60 * 24 * 365)) {
			const diffInMonths = Math.floor(nowDiffInSeconds / (60 * 60 * 24 * 30));
			return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
		} else {
			return '1+ years ago';
		}
	}

	if (!route.params) return null;
	let { editedFieldProps, username } = route.params;

	const [selectedTabId, setselectedTabId] = React.useState("games");

	const [error, seterror] = React.useState(false);

	const [games, updategames] = React.useReducer(simpleReducer, { items: [], nextUrl: null });
	const [bookmarks, updatebookmarks] = React.useReducer(simpleReducer, { items: [], nextUrl: null });
	const [archives, updatearchives] = React.useReducer(simpleReducer, { items: [], nextUrl: null });
	const [helpers, updatehelpers] = React.useReducer(simpleReducer, { items: [], nextUrl: null });
	const [templates, updatetemplates] = React.useReducer(simpleReducer, { items: [], nextUrl: null });
	const [diceSets, updatediceSets] = React.useReducer(simpleReducer, { items: [], nextUrl: null });

	const [activeReducer, updateActiveReducer] = React.useMemo(() => {
		switch (selectedTabId) {
			case "games":
				return [games, updategames];
			case "bookmarks":
				return [bookmarks, updatebookmarks];
			case "archived":
				return [archives, updatearchives];
			case "helpers":
				return [helpers, updatehelpers];
			case "templates":
				return [templates, updatetemplates];
			case "dicesets":
				return [diceSets, updatediceSets];
			default:
				break;
		}
	}, [selectedTabId, games, bookmarks, archives, helpers, templates, diceSets]);

	const displayedTabs = [
		{ id: "games", title: _("Active", "profile page tab") },
		{ id: "bookmarks", title: _("Bookmarks", "profile page tab") },
		{ id: "archived", title: _("Archived", "profile page tab") },
		{ id: "helpers", title: _("Helpers", "profile page tab") },
		{ id: "templates", title: _("Sheets", "profile page tab") },
		{ id: "dicesets", title: _("Dice sets", "profile page tab") },
	];

	const rewardLevel = pageUser.profile?.reward_level;
	const lastCharacter = pageUser.profile?.last_used_character;

	const isMine = pageUser.id === currentUser?.id;

	React.useEffect(() => {
		if (pageUser && !pageUser.profile && !error) {
			const goFetch = async () => {
				const fetchedUser = await dispatch(fetchUser(decodeURI(username)));
				if (!fetchedUser) seterror(true);
			};
			goFetch();
		}
	}, [pageUser, username]);

	React.useEffect(() => {
		if (helpers.items.length) dispatch(storeHelpers(helpers.items));
	}, [helpers]);

	const resetReducers = React.useCallback(() => {
		updategames({ items: [], nextUrl: null });
		updatebookmarks({ items: [], nextUrl: null });
		updatearchives({ items: [], nextUrl: null });
		updatehelpers({ items: [], nextUrl: null });
		updatetemplates({ items: [], nextUrl: null });
		updatediceSets({ items: [], nextUrl: null });
	}, []);

	React.useEffect(() => {
		seterror(false);
		resetReducers();
	}, [pageUser.id]);

	const editBio = React.useCallback(() => {
		navigation.navigate("EditBio", {
			previousScreenName: route.name,
			inputFieldProps: [
				{
					key: "bio",
					label: _("Bio", "profile bio"),
					value: pageUser.profile.bio,
					multiline: true,
				},
			],
			focusedKey: "bio",
		});
	}, [pageUser.profile?.bio]);

	React.useEffect(() => {
		if (editedFieldProps) {
			const newBio = editedFieldProps[0].value;
			apiFetch(`profiles/${pageUser.id}`, "PATCH", { bio: newBio });
			dispatch(receiveUsers([{ ...pageUser, profile: { ...pageUser.profile, bio: newBio } }]));
			if (editedFieldProps) navigation.setParams({ editedFieldProps: null });
		}
	}, [editedFieldProps, pageUser]);

	const saveBio = React.useCallback(
		(newBio) => {
			apiFetch(`profiles/${pageUser.id}`, "PATCH", { bio: newBio });
			dispatch(receiveUsers([{ ...pageUser, profile: { ...pageUser.profile, bio: newBio } }]));
		},
		[pageUser]
	);

	const fetchNext = React.useCallback(() => {
		if (activeReducer.nextUrl) {
			apiFetchSimple(activeReducer.nextUrl).then(({ results, count, next }) => {
				updateActiveReducer({
					items: activeReducer.items.rg_overlapById(results, true),
					nextUrl: next,
				});
			});
		}
	}, [activeReducer, updateActiveReducer]);

	const fetchData = React.useCallback(() => {
		let url = "";
		let params = {};
		if (!isMine) params.public = true;
		if (!pageUser?.id) return;
		switch (selectedTabId) {
			case "games":
			case "bookmarks":
			case "archived":
				url = `users/${pageUser.id}/games`;
				params.ordering = "-last_update";
				params.bookmarked = selectedTabId === "bookmarks";
				params.archived = selectedTabId === "archived";
				break;
			case "helpers":
				url = `users/${pageUser.id}/helpers`;
				break;
			case "templates":
				url = `profiles/${pageUser.id}/sheet-templates`;
				break;
			case "dicesets":
				url = `users/${pageUser.id}/dice-sets`;
				break;
			default:
				break;
		}

		apiFetch(url, "GET", params).then((response) => {
			const { results, count, next } = response;
			updateActiveReducer({
				items: activeReducer.items.rg_overlapById(results, true),
				nextUrl: next,
			});
		});
	}, [selectedTabId, activeReducer, updateActiveReducer, pageUser, isMine]);

	const fetchDataRef = React.useRef(fetchData);
	fetchDataRef.current = fetchData;

	React.useEffect(() => {
		if (pageUser.username) {
			navigation.setOptions({
				title: _("%(username)s's profile | Role Gate", "", { username: pageUser.username }),
			});
		}
	}, [pageUser]);

	React.useEffect(() => {
		if (!activeReducer.items.length) {
			fetchDataRef.current();
		}
	}, [activeReducer.items.length, selectedTabId, pageUser]);

	const renderItem = React.useCallback(
		({ item }) => {
			let component = null;
			switch (selectedTabId) {
				case "helpers":
					component = <InteractiveHelperItem interactiveHelper={item} />;
					break;
				case "templates":
					component = <CharacterSheetItem sheet={item} />;
					break;
				case "dicesets":
					component = (
						<DiceSetItem
							diceSet={item}
							onPress={() =>
								navigation.navigate("DiceSetStack", {
									screen: "EditDiceSetScreen",
									params: { diceSet: item },
								})
							}
						/>
					);
					break;
				default:
					component = <ActiveGameItem game={item} isBookmarked={selectedTabId === "bookmarks" || !isMine} />;
					break;
			}

			return (
				<View style={ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })}>
					{component}
				</View>
			);
		},
		[selectedTabId]
	);

	const emptyMessage = React.useMemo(() => {
		switch (selectedTabId) {
			case "games":
				return _("%(username)s is not in any public games", "empty profile tab message", {
					username: pageUser.username,
				});
			case "bookmarks":
				return _("%(username)s is not reading any games", "empty profile tab message", {
					username: pageUser.username,
				});
			case "archived":
				return _("%(username)s has no archived games", "empty profile tab message", {
					username: pageUser.username,
				});
			case "helpers":
				return _("%(username)s has not created any helpers", "empty profile tab message", {
					username: pageUser.username,
				});
			case "templates":
				return _("%(username)s has not created any sheet templates", "empty profile tab message", {
					username: pageUser.username,
				});
			case "dicesets":
				return _("%(username)s has not created any dice sets", "empty profile tab message", {
					username: pageUser.username,
				});
			default:
				break;
		}
	}, [selectedTabId, pageUser.username]);

	const emptyIcon = React.useMemo(() => {
		switch (selectedTabId) {
			case "games":
				return { type: RGIcon, name: "die" };
			case "bookmarks":
				return { type: MaterialIcons, name: "bookmark" };
			case "archived":
				return { type: MaterialCommunityIcons, name: "archive" };
			case "helpers":
				return { type: MaterialCommunityIcons, name: "paperclip" };
			case "templates":
				return { type: MaterialCommunityIcons, name: "file-account" };
			case "dicesets":
				return { type: FontAwesome5, name: "dice" };
			default:
				break;
		}
	}, [selectedTabId, pageUser]);

	const { onLayout } = useLayout();

	if (error) {
		return <ErrorLoading />;
	}

	if (!pageUser?.profile) {
		return <FullScreenLoader visible />;
	}

	const topComponent = (
		<View style={[styles.container, ws({ paddingLeft: fullScreenLeftPadding() })]}>
			<View style={[globalStyles.rc]}>
				<CharacterAvatar character={lastCharacter} size={64} />
				<View style={{ flex: 1 }}>
					<AppText bold size="large">
						{pageUser.username}
					</AppText>
					<AppText
						textOnlyStyle={{
							color: global.colors.accountColors[levelToCoinFileName(rewardLevel).toLowerCase()],
						}}
					>
						{levelToRankName(rewardLevel)}
					</AppText>
				</View>
				{!isMine && <AddFriendButton otherUser={pageUser} />}
			</View>

			<View style={{ marginVertical: 16 }}>
				{isMine && <Purse user={pageUser} />}
				<Chest user={pageUser} />
			</View>

			<AppText
				onPress={isMine && !isWeb() ? editBio : null}
				editable={isMine && isWeb()}
				inputFieldProps={{ multiline: true }}
				onTextChanged={saveBio}
			>
				{pageUser.profile.bio}
			</AppText>
			<AppText
				hide={pageUser.profile.bio}
				centered
				color="hint"
				style={{ marginVertical: 16 }}
				italic
				editable={isMine && isWeb()}
				inputFieldProps={{ multiline: true }}
				onTextChanged={saveBio}
			>
				{_("No bio.", "user has no bio")}
			</AppText>
			<AppText
				centered
				color="hint"
				style={{ fontSize: 14 }}
			>
				{ _("Last Active " + getRelativeTimeAgo(pageUser.profile.last_seen)) }
			</AppText>
		</View>
	);

	const navigator = (
		<HorizontalTabs
			style={[
				{ marginBottom: 8, marginHorizontal: -8 },
				ws({ marginBottom: 0 }),
			]}
			tabs={displayedTabs}
			selectedId={selectedTabId}
			onTabSelected={setselectedTabId}
		/>
	);

	return (
		<SectionList
			onLayout={onLayout}
			ListHeaderComponent={topComponent}
			ListHeaderComponentStyle={{ marginHorizontal: -8 }}
			sections={[{ data: activeReducer.items }]}
			style={styles.sectionContainer}
			contentContainerStyle={styles.sectionContainer}
			renderSectionHeader={() => navigator}
			renderItem={renderItem}
			keyExtractor={(item, index) => String(item.id)}
			stickySectionHeadersEnabled={true}
			onEndReached={fetchNext}
			ListEmptyComponent={<View style={{ height: 164, width: 68 }} />}
			ListFooterComponent={
				activeReducer.items.length ? null : <EmptyScreenView messageBottom={emptyMessage} icon={emptyIcon} />
			}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	// When pressing the back arrow on the browser, this component gets drawn once more, only without any route params.
	let { username } = ownProps.route.params || {};
	if (username) {
		username = decodeURI(username);
		username = decodeURIComponent(username);
	}

	return {
		// username,
		pageUser: state.users[username?.toLowerCase()] || {},
		currentUser: state.user,
	};
};

export default connect(mapStateToProps)(ProfilePage);

const styles = StyleSheet.create({
	container: {
		marginTop: 8,
		marginBottom: 16,
		marginHorizontal: 8,
		...ws({
			maxWidth: fullScreenContentWidth(),
			marginTop: 16,
		}),
	},
	sectionContainer: {
		flexGrow: 1,
		...ws(null, { paddingHorizontal: 8 }),
	},
});
