import { FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { deleteBookmark, saveBookmark } from "../../store/slices/bookmarksSlice";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { setOpenedHelper } from "../../store/slices/interactiveHelpersSlice";
import { BuildStyleMethod } from "../../styles/theming";
import { isGM, isInGame, isPlayer } from "../../tools/games";
import { ScreenToURL } from "../../tools/navigationConfig";
import { markerTypeToString } from "../../tools/storyMarker";
import IconButton from "../generic/buttons/IconButton";
import ListButton from "../generic/buttons/ListButton";
import RGIcon from "../RGIcon";
import GamePasswordModal from "./GamePasswordModal";
import JoinGameHelpButton from "./JoinGameHelpButton";

function GameMenu({
	dispatch,
	game,
	parties: partiesProps,
	currentStoryMarker,
	currentCharacter,
	user,
	bookmarkedGames,
	bookmark,
}) {
	const [folded, setfolded] = React.useState(global.foldGameMenu);

	React.useEffect(() => {
		if (global.foldGameMenu === undefined) {
			const loadInfo = async () => {
				let shouldFold = (await AsyncStorage.getItem("foldGameMenu")) || false;
				shouldFold = JSON.parse(shouldFold);
				setfolded(shouldFold);
				global.foldGameMenu = shouldFold;
			};

			loadInfo();
		}
	}, []);

	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const bookmarked = bookmark || bookmarkedGames.some((g) => g.id === game.id);

	const parties = React.useMemo(() => partiesProps.filter((p) => p.on_going), [partiesProps]);

	const gm = isGM();
	const inGame = isInGame(user, game);

	const passwordModalRef = React.useRef();

	const foldMenu = React.useCallback(() => {
		setfolded(!folded);
		AsyncStorage.setItem("foldGameMenu", JSON.stringify(!folded));
		global.foldGameMenu = !folded;
	}, [folded]);

	const ref = React.useRef(null);
	const isHovered = useHover(ref);

	return (
		<ScrollView style={[styles.container, folded && styles.containerFolded]}>
			<View ref={ref} style={{ alignItems: "flex-end", opacity: isHovered ? 1 : 0.5 }}>
				<IconButton
					icon={{ type: MaterialCommunityIcons, name: folded ? "arrow-left-bold" : "arrow-right-bold" }}
					// icon={{ type: MaterialIcons, name: "menu" }}
					onPress={foldMenu}
					transparent
				/>
			</View>
			{inGame && (
				<>
					<ListButton
						hideTitle={folded}
						popoverArrowPosition="right"
						title={_("Roll", "roll dice menu option")}
						info={_("Pick dice to roll", "roll dice menu option info")}
						iconLeft={{ type: RGIcon, name: "die", color: global.colors.textLight }}
						onPress={() => navigation.navigate("SimpleRollModal")}
					/>
					<ListButton
						hideTitle={folded}
						popoverArrowPosition="right"
						title={_("Roll+", "roll dice menu option")}
						info={_("Roll an advanced command", "roll dice menu option info")}
						iconLeft={{ type: RGIcon, name: "die" }}
						subiconLeft={{ type: FontAwesome, name: "plus" }}
						onPress={() => navigation.navigate("AdvancedRollModal")}
					/>
					<ListButton
						hideTitle={folded}
						popoverArrowPosition="right"
						title={_("Macros", "roll dice menu option")}
						info={_("Quickly roll a saved command", "roll dice menu option info")}
						iconLeft={{ type: RGIcon, name: "die" }}
						subiconLeft={{ type: MaterialIcons, name: "play-arrow" }}
						onPress={() => navigation.navigate("SelectMacroModal")}
					/>
					{gm && (
						<ListButton
							hideTitle={folded}
							popoverArrowPosition="right"
							title={_("Request roll", "roll dice menu option")}
							info={_("Request a roll from players, with a timer", "roll dice menu option info")}
							iconLeft={{ type: RGIcon, name: "die" }}
							subiconLeft={{ type: MaterialIcons, name: "timer" }}
							onPress={() => navigation.navigate("AdvancedRollModal", { isRequest: true })}
						/>
					)}
					<View style={{ marginBottom: 20 }} />
				</>
			)}
			<ListButton
				hideTitle={folded}
				popoverArrowPosition="right"
				iconLeft={{ type: FontAwesome, name: "th-list" }}
				title={_("Index", "game index page")}
				to={"/" + game.slug + ScreenToURL("IndexScreen")}
			/>

			{inGame && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					title={_("Interactive helpers", "screen title")}
					iconLeft={{ type: MaterialCommunityIcons, name: "paperclip" }}
					onPress={() => dispatch(setOpenedHelper(undefined))}
				/>
			)}

			{inGame && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					title={gm ? _("List characters", "stage menu") : _("Characters", "stage menu")}
					iconLeft={{ type: FontAwesome5, name: "users", size: 18 }}
					subiconLeft={gm ? { type: MaterialCommunityIcons, name: "format-list-bulleted" } : null}
					onPress={() => navigation.navigate("SelectCharacter")}
				/>
			)}
			{gm && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					title={_("Manage characters", "stage menu")}
					iconLeft={{ type: FontAwesome5, name: "users" }}
					to={"/" + game.slug + ScreenToURL("ManageCharactersScreen")}
				/>
			)}

			{inGame && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					title={_("Notes", "stage menu")}
					iconLeft={{ type: MaterialCommunityIcons, name: "notebook" }}
					to={"/" + game.slug + ScreenToURL("NotesScreen")}
				/>
			)}
			{gm && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					title={parties.length ? _("Manage parties", "stage menu") : _("Split party", "stage menu")}
					iconLeft={{ type: MaterialCommunityIcons, name: "call-split" }}
					onPress={() => navigation.navigate("ManagePartiesModal")}
				/>
			)}

			{isPlayer() && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					title={_("My sheet", "stage menu")}
					iconLeft={{ type: MaterialCommunityIcons, name: "file-account" }}
					to={
						"/" +
						game.slug +
						ScreenToURL("CharacterSheetStackGame") +
						"/" +
						currentCharacter?.id +
						ScreenToURL("CharacterSheetScreen")
					}
				/>
			)}

			{gm && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					disabled={!currentStoryMarker}
					centerButton
					title={_("Edit %(story_marker_type)s", "edit story marker", {
						story_marker_type: markerTypeToString(currentStoryMarker).toLowerCase(),
					})}
					iconLeft={{ type: MaterialCommunityIcons, name: "book" }}
					onPress={() =>
						navigation.navigate("StoryMarkerModalStack", {
							screen: "EditStoryMarkerModal",
							params: { markerId: currentStoryMarker.id },
						})
					}
				/>
			)}

			{!inGame && !!user && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					iconLeft={{ type: MaterialIcons, name: "bookmark", color: global.colors.textLight }}
					title={
						bookmarked
							? _("Move bookmark", "move game bookmark to a new line")
							: _("Bookmark", "bookmark a game")
					}
					onPress={() => {
						if (!global.currentLine) return;
						dispatch(saveBookmark(game.id, global.currentLine));
					}}
				/>
			)}

			{!inGame && bookmarked && !!user && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					iconLeft={{ type: MaterialIcons, name: "bookmark-border", color: global.colors.textLight }}
					title={_("Remove bookmark")}
					onPress={() => {
						dispatch(deleteBookmark(game.id));
					}}
				/>
			)}

			{!inGame && !!user && (
				<ListButton
					hideTitle={folded}
					popoverArrowPosition="right"
					iconLeft={{ type: MaterialIcons, name: "chat-bubble" }}
					title={_("Talk with players", "stage menu")}
					onPress={() => navigation.navigate("PublicChatScreen")}
					disabled={!game.allow_public_chat}
				/>
			)}

			{!inGame && (
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<ListButton
						hideTitle={folded}
						popoverArrowPosition="right"
						disabled={game.archived || game.is_full}
						style={{ flex: 1 }}
						iconLeft={{ type: MaterialCommunityIcons, name: "login", color: global.colors.textLight }}
						title={_("Join game", "stage menu")}
						subtitle={
							game.archived
								? _("This game has been archived")
								: game.is_full
								? _("This game is full")
								: null
						}
						onPress={() => {
							if (!user) {
								navigation.navigate("Login");
							} else if (game.password) {
								passwordModalRef.current.show();
							} else {
								navigation.navigate("GameStack", { gameslug: game.slug, screen: "JoinGameScreen" });
							}
						}}
					/>
					<JoinGameHelpButton style={{ position: "absolute", right: 8 }} />
				</View>
			)}

			<GamePasswordModal ref={passwordModalRef} game={game} navigation={navigation} />
		</ScrollView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	const gameUI = state.gamesUI[game.id] || {};

	return {
		currentStoryMarker: gameUI.currentStoryMarker,
		game,
		user: state.user,
		parties: state.parties[game.id]?.all || Array.rg_empty,
		currentCharacter: getCurrentCharacter(state),
		bookmarkedGames: state.games.bookmarked || state.savedStore.games.bookmarked,
		bookmark: state.bookmarkForGames[game.id],
	};
};

export default connect(mapStateToProps)(GameMenu);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			width: 230,
			backgroundColor: colors.cardBackground,
			flexGrow: 0,
			flexShrink: 1,
			flexBasis: "auto",
		},
		containerFolded: {
			width: 50,
		},
	})
);
