import AsyncStorage from "@react-native-async-storage/async-storage";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useNavigationState } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import { useDimensions, useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { drawerWidth } from "../../styles/dynamicStyles";
import { isWeb, isWide, ws } from "../../tools/generic";
import LoggedNavigationScreens from "./LoggedNavigationScreens";
import MainDrawerPanel from "./MainDrawerPanel";
import MainStack from "./MainStack";
import PublicNavigationScreens from "./PublicNavigationScreens";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";

const Drawer = createDrawerNavigator();

function MainDrawerNavigator({ route, setonGameScreen, dispatch, user }) {
	const [initRender, setInitRender] = React.useState(true);
	const [userFolded, setuserFolded] = React.useState(false);
	const [drawerType, setdrawerType] = React.useState(isWeb() ? "permanent" : "front");

	React.useEffect(() => {
		// There is a bug on android where the panel will sometime flicker open when the app is opened.
		// The only known workaround is to not display something in the drawer immediately
		// https://github.com/react-navigation/react-navigation/issues/7561
		setInitRender(false);

		const foldIfNeeded = async () => {
			const shouldFold = (await AsyncStorage.getItem("drawerFolded")) || false;
			setuserFolded(JSON.parse(shouldFold));
		};

		foldIfNeeded();
	}, []);

	React.useEffect(() => {
		AsyncStorage.setItem("drawerFolded", JSON.stringify(userFolded));
	}, [userFolded]);

	const routeName = isWeb() ? getFocusedRouteNameFromRoute(route) || null : null;

	React.useEffect(() => {
		if (!isWeb()) return () => {};

		const drawerTypeOnGameScreen = "front"

		if (routeName === "GameStack" && drawerType !== drawerTypeOnGameScreen) {
			setdrawerType(drawerTypeOnGameScreen);
			setonGameScreen(true);
		} else if (routeName !== "GameStack" && drawerType != "permanent") {
			
			// Uncomment if using drawerTypeOnGameScreen = "back"
			// There is a bug where switching too fast from drawerType "back" => "permanent" => "back" will cause the drawer's overlay to stay on screen even if the drawer is closed
			// to avoid that, we don't change the drawer type on GameLoader, as we know it will most likely change back to GameStack after it is done loading
			// if (routeName === "GameLoader") return;
			
			// There is a bug where using "front" won't let you go back to "permanent" https://github.com/react-navigation/react-navigation/issues/10044
			// Therefore, we quickly switch to "back" before going to permanent.
			// It's ugly, it sucks, but what can I do? I hate relying on git packages T-T
			setdrawerType("back"); 
			setTimeout(() => {
				setdrawerType("permanent");
			}, 10);
			setonGameScreen(false);
		}
	}, [routeName, drawerType]);

	// This call will force the redraw of this component when the window is resized
	// Also good to keep it on mobile for a faster update of the default width
	useDimensions();

	let forceFolded = isWeb() && !isWide();
	let folded = userFolded || forceFolded;

	folded = folded && drawerType == "permanent";
	forceFolded = forceFolded && drawerType == "permanent";

	let style = null;
	if (isWeb()) {
		style = { width: folded ? 50 : drawerWidth() };
	} else {
		style = initRender ? { width: null } : null;
	}

	const { onLayout, width: navigatorWidth } = useLayout();
	global.navigatorWidth = navigatorWidth || 2000;
	global.windowWidth = useDimensions().window.width;

	return (
		<View onLayout={onLayout} style={{ flex: 1 }}>
			<Drawer.Navigator
				backBehavior="history"
				drawerContent={(props) => (
					<MainDrawerPanel
						{...props}
						drawerType={drawerType}
						route={route}
						folded={folded}
						forceFolded={forceFolded}
						setuserFolded={setuserFolded}
					/>
				)}
				screenOptions={{
					swipeEdgeWidth: 60,
					headerShown: false,
					drawerStyle: style,
					drawerType: drawerType,
					drawerPosition: "left",
				}}
			>
				{isWeb() ? (
					<>
						{LoggedNavigationScreens(Drawer, user)}
						{PublicNavigationScreens(Drawer)}
					</>
				) : (
					<Drawer.Screen name="MainStack" component={MainStack} />
				)}
			</Drawer.Navigator>
		</View>
	);
}
const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(MainDrawerNavigator);
