import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import TagItem from "../tags/TagItem";
import { idKeyExtractor, isWeb } from "../../tools/generic";
import { Hoverable } from "react-native-web-hooks";

function GameTagHorizontalList({ game, tagsToShow, large, tagSize, tagIdToHide, tagIcon, style }) {
	const flatListRef = React.useRef();
	let tags = React.useMemo(() => {
		if (game) {
			return (
				game.tags
					.filter((t) => game.tags.length === 1 || !t.id || t.id !== tagIdToHide)
					.sort((a, b) => a.searching_count - b.searching_count)
					.slice(0, 10) || []
			);
		} else {
			return tagsToShow;
		}
	}, [game?.tags, tagsToShow]);

	tags = large ? tags : tags.slice(0, 1);

	const scrollToEnd = React.useCallback(() => {
		if (!isWeb()) return;
		flatListRef.current.scrollToEnd({ animated: true });
	}, []);
	const scrollToStart = React.useCallback(() => {
		if (!isWeb()) return;
		flatListRef.current.scrollToOffset({ offset: 0 });
	}, []);

	return tags.length === 1 && !large ? (
		<View style={[{ flexDirection: "row", flex: 1 }, style]}>
			<TagItem tag={tags[0]} icon={tagIcon} />
		</View>
	) : (
		<Hoverable onHoverIn={scrollToEnd} onHoverOut={scrollToStart}>
			{(isHovered) => (
				<View style={style}>
					<FlatList
						ref={flatListRef}
						horizontal
						data={tags}
						keyExtractor={idKeyExtractor}
						renderItem={({ item }) => <TagItem tag={item} icon={tagIcon} tagSize={tagSize}/>}
						ItemSeparatorComponent={() => <View style={{ marginRight: 4 }} />}
						showsHorizontalScrollIndicator={!isWeb()}
					/>
				</View>
			)}
		</Hoverable>
	);
}

export default GameTagHorizontalList;
