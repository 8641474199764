import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { fetchStoryMarkers } from "../../../store/slices/storyMarkersSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { globalStylesMethod } from "../../../styles/global";
import { ws } from "../../../tools/generic";
import { openGameAtLine } from "../../../tools/lines";
import AppFlatList from "../../generic/AppFlatList";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import StoryMarkerItem from "../../storymarkers/StoryMarkerItem";

function GameQuickIndex({ game, style, dispatch, storyMarkers, usesSaved }) {
	const navigation = useNavigation();

	style = style || globalStylesMethod(global.theme).floatingSection; 

	storyMarkers = storyMarkers?.filter((sm) => !sm.hidden);

	React.useEffect(() => {
		if (usesSaved) dispatch(fetchStoryMarkers(game.id));
	}, [usesSaved, storyMarkers, game.id]);

	const goToMarker = React.useCallback(
		(marker) => {
			openGameAtLine(game, marker.start_line_id, marker.party, 0.5, dispatch, navigation);
		},
		[game, dispatch, navigation]
	);

	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={[style, {paddingLeft: fullScreenLeftPadding()}]}>
			<View style={ws({ maxWidth: fullScreenContentWidth() })}>
			<AppText color="primary">{_("Scenes", "game details")}</AppText>
			<AppFlatList
				ListEmptyComponent={
					<AppText color="hint">{_("This game doesn't have scenes yet", "game details")} </AppText>
				}
				scrollEnabled={false}
				data={storyMarkers?.slice(0, 3)}
				renderItem={({ item, separators }) => (
					<StoryMarkerItem key={item.id} marker={item} separators={separators} onSelected={goToMarker} />
				)}
			/>

			<AppButton
				style={{ marginTop: 16 }}
				hide={!storyMarkers?.length || storyMarkers.length <= 3}
				centered
				title={_("See all", "see all scenes in game")}
				onPress={() => navigation.navigate("GameStack", { gameslug: game.slug, screen: "IndexScreen"})}
			/>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		storyMarkers: state.storyMarkers[ownProps.game.id] || state.savedStore.storyMarkers[ownProps.game.id],
		usesSaved: !state.storyMarkers[ownProps.game.id],
	};
};

export default connect(mapStateToProps)(GameQuickIndex);


