import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../AppText";
import { colors } from "../../../styles/colors";
import IconButton from "../buttons/IconButton";
import { MaterialIcons } from "@expo/vector-icons";
import CondView from "../../meta/CondView";

import { BuildStyleMethod } from "../../../styles/theming";

function PopoverMessage({ title, message, parentPopover, noClose }) {
	const styles = stylesMethod(global.theme);
	return (
		<View style={styles.container}>
			<CondView style={styles.title} show={title}>
				<AppText color="textLight">
					{title}
				</AppText>
				<IconButton
					hide={!title || noClose}
					transparent
					icon={{ type: MaterialIcons, name: "close", color: global.colors.textLight }}
					style={styles.cross}
					onPress={() => parentPopover.hide()}
				/>
			</CondView>
			<AppText style={styles.message}>
				{message}
			</AppText>
		</View>
	);
}

export default PopoverMessage;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		borderWidth: 1,
		borderColor: colors.secondary,
	},
	title: {
		paddingVertical: 4,
		backgroundColor: colors.secondary,
		paddingHorizontal: 8,
	},
	message: {
		paddingVertical: 4,
		paddingHorizontal: 8,
	},
	cross: {
		position: "absolute",
		right: -10,
		top: -10,
	},
}));
