import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { colors } from "../../styles/colors";
import { usePrevious } from "../../tools/react";
import AppButton from "../generic/buttons/AppButton";
import InputField from "../generic/InputField";
import ListSwitch from "../generic/ListSwitch";
import CharacterSheetValueDropDown from "./CharacterSheetValueDropDown";

import { BuildStyleMethod } from "../../styles/theming";

function PoolOption({
	title,
	group,
	optionKey,
	setIsEnabled,
	disabled,
	parameterLabel,
	keyboardType,
	updateOptionValue,
	defaultValue,
	dispatch,
	currentCharacter,
}) {
	const styles = stylesMethod(global.theme);
	const isEnabled = group ? group[optionKey] : false;
	const optionValueKey = optionKey + "_value";
	const value = group ? group[optionValueKey] : null;
	const [useSheeValue, setuseSheeValue] = React.useState(group ? !!group[optionValueKey + "_sheetValue"] : false);

	const previousIsEnabled = usePrevious(isEnabled);

	React.useEffect(() => {
		if (!previousIsEnabled && isEnabled && !value) {
			updateOptionValue(optionValueKey, defaultValue);
		}
	}, [isEnabled, defaultValue, value, updateOptionValue]);

	const onSheeValuePicked = React.useCallback(
		(valuePicked) => {
			updateOptionValue(optionValueKey, valuePicked?.value || 0, valuePicked);
		},
		[optionValueKey, updateOptionValue]
	);

	return (
		<View style={styles.container}>
			<ListSwitch
				disabled={disabled}
				title={title}
				isEnabled={isEnabled}
				setIsEnabled={setIsEnabled.bind(this, optionKey)}
			/>

			{isEnabled && parameterLabel && (
				<View style={styles.parameterContainer}>
					{!useSheeValue && (
						<InputField
							style={styles.input}
							label={parameterLabel}
							keyboardType={keyboardType}
							value={value ? String(value) : ""}
							onChangeText={(text) => updateOptionValue(optionValueKey, text)}
							selectTextOnFocus
						/>
					)}

					{useSheeValue && (
						<CharacterSheetValueDropDown
							defaultValue={group[optionValueKey + "_sheetValue"]}
							sheetId={currentCharacter?.sheet}
							onSheeValuePicked={onSheeValuePicked}
						/>
					)}

					<AppButton
						title={useSheeValue ? _("Use number") : _("Use sheet")}
						size="small"
						onPress={() => setuseSheeValue(!useSheeValue)}
						transparent
						style={{ marginLeft: 8 }}
					/>
				</View>
			)}
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const currentCharacter = getCurrentCharacter(state);
	return {
		currentCharacter,
	};
};

export default connect(mapStateToProps)(PoolOption);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		paddingVertical: 4,
		borderBottomWidth: 1,
		borderBottomColor: colors.lightBorder,
	},
	input: {
		flex: 1,
	},
	parameterContainer: {
		marginTop: 8,
		flexDirection: "row",
		marginBottom: 8,
	},
}));
