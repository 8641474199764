import { _ } from '../i18n/i18n';

export const allTones = [
    {code: 'admiring'},
    {code: 'affectionate'},
    {code: 'alarmed'},
    {code: 'amused'},
    {code: 'angry'},
    {code: 'annoyed'},
    {code: 'anxious'},
    {code: 'apologetic'},
    {code: 'baffled'},
    {code: 'brooding'},
    {code: 'cheerful'},
    {code: 'confident'},
    {code: 'condescending'},
    {code: 'confused'},
    {code: 'curious'},
    {code: 'desperate'},
    {code: 'disappointed'},
    {code: 'disgusted'},
    {code: 'doubtful'},
    {code: 'detached'},
    {code: 'embarrassed'},
    {code: 'excited'},
    {code: 'fascinated'},
    {code: 'friendly'},
    {code: 'haughty'},
    {code: 'impressed'},
    {code: 'kind'},
    {code: 'mocking'},
    {code: 'nervous'},
    {code: 'panicked'},
    {code: 'passionate'},
    {code: 'panting'},
    {code: 'pensive'},
    {code: 'playful'},
    {code: 'pressing'},
    {code: 'proud'},
    {code: 'puzzled'},
    {code: 'remorseful'},
    {code: 'relieved'},
    {code: 'sad'},
    {code: 'sarcastic'},
    {code: 'scared'},
    {code: 'seductive'},
    {code: 'serious'},
    {code: 'shy'},
    {code: 'sleepy'},
    {code: 'suffering'},
    {code: 'surprised'},
    {code: 'suspicious'},
    {code: 'threatening'},
    {code: 'tired'},
    {code: 'tense'},
    {code: 'worried'},
];

export const translatedTones = () => {
    const tones = allTones.slice();

    for (let i = 0; i < tones.length; ++i) {
        tones[i].name = translateTone(tones[i].code);
    }
    tones.sort((a, b) => {
        if (a.code === 'neutral')
            return 1;
        if (b.code === 'neutral')
            return -1;
        return a.name.localeCompare(b.name);
    });
    return tones;
};

export const translateTone = (toneCode) => {
    if (!toneCode)
        return '';
    switch (toneCode) {
        case 'affectionate':
            return _("Affectionate", "line tone");
        case 'admiring':
            return _("Admiring", "line tone");
        case 'angry':
            return _("Angry", "line tone");
        case 'annoyed':
            return _("Annoyed", "line tone");
        case 'anxious':
            return _("Anxious", "line tone");
        case 'amused':
            return _("Amused", "line tone");
        case 'alarmed':
            return _("Alarmed", "line tone");
        case 'brooding':
            return _("Brooding", "line tone");
        case 'cheerful':
            return _("Cheerful", "line tone");
        case 'condescending':
            return _("Condescending", "line tone");
        case 'confident':
            return _("Confident", "line tone");
        case 'confused':
            return _("Confused", "line tone");
        case 'curious':
            return _("Curious", "line tone");
        case 'desperate':
            return _("Desperate", "line tone");
        case 'detached':
            return _("Detached", "line tone");
        case 'disappointed':
            return _("Disappointed", "line tone");
        case 'disgusted':
            return _("Disgusted", "line tone");
        case 'doubtful':
            return _("Doubtful", "line tone");
        case 'excited':
            return _("Excited", "line tone");
        case 'impressed':
            return _("Impressed", "line tone");
        case 'kind':
            return _("Kind", "line tone");
        case 'panicked':
            return _("Panicked", "line tone");
        case 'pensive':
            return _("Pensive", "line tone");
        case 'playful':
            return _("Playful", "line tone");
        case 'sad':
            return _("Sad", "line tone");
        case 'sarcastic':
            return _("Sarcastic", "line tone");
        case 'scared':
            return _("Scared", "line tone");
        case 'seductive':
            return _("Seductive", "line tone");
        case 'serious':
            return _("Serious", "line tone");
        case 'surprised':
            return _("Surprised", "line tone");
        case 'suspicious':
            return _("Suspicious", "line tone");
        case 'threatening':
            return _("Threatening", "line tone");
        case 'worried':
            return _("Worried", "line tone");
        case 'nervous':
            return _("Nervous", "line tone");
        case 'apologetic':
            return _("Apologetic", "line tone");
        case 'baffled':
            return _("Baffled", "line tone");
        case 'embarrassed':
            return _("Embarrassed", "line tone");
        case 'fascinated':
            return _("Fascinated", "line tone");
        case 'friendly':
            return _("Friendly", "line tone");
        case 'haughty':
            return _("Haughty", "line tone");
        case 'mocking':
            return _("Mocking", "line tone");
        case 'passionate':
            return _("Passionate", "line tone");
        case 'panting':
            return _("Panting", "line tone");
        case 'puzzled':
            return _("Puzzled", "line tone");
        case 'proud':
            return _("Proud", "line tone");
        case 'pressing':
            return _("Pressing", "line tone");
        case 'remorseful':
            return _("Remorseful", "line tone");
        case 'relieved':
            return _("Relieved", "line tone");
        case 'shy':
            return _("Shy", "line tone");
        case 'sleepy':
            return _("Sleepy", "line tone");
        case 'suffering':
            return _("Suffering", "line tone");
        case 'tense':
            return _("Tense", "line tone");
        case 'tired':
            return _("Tired", "line tone");
        case 'neutral':
            return _("Neutral", "line tone");
        default:
            return _(toneCode, 'custom tone');
    }
};
