import * as React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import AppText from "../generic/AppText";
import AppScreenView from "../AppScreenView";
import { Divider } from "react-native-paper";
import { fetchHelperPanelOptions, setOpenedPanelId, setOpenedHelper } from "../../store/slices/interactiveHelpersSlice";
import { connect } from "react-redux";
import AppButton from "../generic/buttons/AppButton";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/global";
import IconButton from "../generic/buttons/IconButton";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { isWeb, ws } from "../../tools/generic";

import { BuildStyleMethod } from "../../styles/theming";

function InteractiveHelperPanel({ interactiveHelper, panel, history, goBack, goToPanel, dispatch, options }) {
	const styles = stylesMethod(global.theme);
	const scrollRef = React.useRef();
	React.useEffect(() => {
		scrollRef.current?.scrollTo({ y: 0, animated: false });
	}, [panel?.id]);

	React.useEffect(() => {
		if (panel?.id && !options.length) {
			dispatch(fetchHelperPanelOptions(panel.id));
		}
	}, [panel?.id, options.length]);

	if (!panel) return null;

	const interactionButtons = (
		<View style={[globalStyles.rcsb, { flexDirection: "row-reverse" }]}>
			<IconButton
				transparent
				icon={{ type: MaterialCommunityIcons, name: "close-box" }}
				onPress={() => dispatch(setOpenedHelper(null))}
			/>
			{history.length > 0 && (
				<IconButton icon={{ type: MaterialIcons, name: "arrow-back" }} transparent onPress={goBack} />
			)}
		</View>
	);

	return (
		<AppScreenView useSafeArea style={styles.container} borderless>
			{isWeb() && interactionButtons}
			<View style={[globalStyles.rc, { padding: 8 }]}>
				<View style={{ flex: 1 }}>
					<AppText size="small">{interactiveHelper.name}</AppText>
					<AppText bold>{panel.name}</AppText>
				</View>
			</View>

			<ScrollView contentContainerStyle={[{ padding: 8 }, ws({ paddingBottom: 70 })]} ref={scrollRef}>
				<AppText selectable style={{ marginBottom: 16 }}>
					{panel.content}
				</AppText>
				{options
					?.filter((o) => o.title)
					.map((option) => (
						<AppButton
							key={option.id}
							title={option.title}
							disabled={!option.target}
							onPress={() => goToPanel(option.target?.id)}
							size="fullWidth"
							style={{ marginBottom: 8 }}
						/>
					))}
			</ScrollView>

			{!isWeb() && interactionButtons}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		options: state.interactiveHelpers.options[ownProps.panel?.id] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(InteractiveHelperPanel);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.cardBackground,
	},
}));
