import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import AppButton from "../generic/buttons/AppButton";

function FilterTagButton({ characterTags }) {
	const navigation = useNavigation();
	const route = useRoute();

	const { selectedTag } = route.params;

	return (
		<AppButton
			style={{ flex: 0 }}
			size="fullWidth"
			title={selectedTag ? selectedTag.name : _("Filter by tag")}
			onPress={() => {
				navigation.navigate("CharactersScreenModalStack", {
					screen: "SelectSingleTagModal",
					params: { tags: characterTags, selectedTag, previousScreen: route.name },
				});
			}}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		characterTags: state.characterTags[ownProps.gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(FilterTagButton);


