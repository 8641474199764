const defaultSizes = {
  small: 12,
  average: 15,
  large: 20,
  header: 25,
  header2: 20,
  header3: 17,
}

export const sizes = {
    ...defaultSizes,
  };


export const weight = {
  semibold: "700"
}
