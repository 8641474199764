import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useRoute } from "@react-navigation/native";
import moment from "moment";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { fetchBrowsedGames } from "../../../store/slices/gamesSlice";
import { fullScreenContentWidth } from "../../../styles/dynamicStyles";
import { formatJSDateToDjangoDate } from "../../../tools/dates";
import { isWeb, ws } from "../../../tools/generic";
import { paramToArray } from "../../../tools/webnavigation";
import AppScreenView from "../../AppScreenView";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import ShowResultsButton from "./ShowResultsButton";

function WizardScreen({ children, nextScreen, dispatch }) {
	const [searching, setsearching] = React.useState(false);

	const popoverRef = React.useRef();

	const route = useRoute();
	const navigation = useNavigation();

	const previousScreen = route.params?.previousScreen;

	const showHintIfNeeded = React.useCallback(async () => {
		const joinWizardDone = await AsyncStorage.getItem("joinWizardDone");
		// If it is not the first screen and we never did the wizard before;
		if (!joinWizardDone && previousScreen) {
			let timer = setTimeout(() => {
				if (popoverRef.current) {
					popoverRef.current.show();
					AsyncStorage.setItem("joinWizardDone", "true");
				}
			}, 1500);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [previousScreen]);

	React.useEffect(() => {
		showHintIfNeeded();
	}, []);

	const startSearch = React.useCallback(async () => {
		setsearching(true);
		await dispatch(fetchBrowsedGames(routeParamsToSearchParams(route.params)));
		setsearching(false);
	}, [route.params]);

	React.useEffect(() => {
		startSearch();
	}, [startSearch]);

	const buttonProps = [];
	const backParams = { ...route.params };
	delete backParams.previousScreen;
	previousScreen &&
		buttonProps.push({ title: _("Back"), onPress: () => navigation.navigate(previousScreen, backParams) });
	nextScreen &&
		buttonProps.push({
			title: _("More criteria", "find a game wizard"),
			onPress: () => navigation.navigate(nextScreen, { ...route.params, previousScreen: route.name }),
		});
	!nextScreen &&
		buttonProps.push({
			title: _("Show results", "find a game wizard"),
			onPress: () => navigation.navigate("BrowseGamesScreen", { ...route.params, fromWizard: true }),
		});

	return (
		<>
			<AppScreenView style={[styles.container, { maxWidth: fullScreenContentWidth() }]} scroll={isWeb()}>
				<View style={ws(null, { flex: 1 })}>{children}</View>

				<ShowResultsButton
					popoverRef={popoverRef}
					route={route}
					navigation={navigation}
					searching={searching}
				/>
			</AppScreenView>
			<ButtonFooter
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={buttonProps}
				noBack={!previousScreen}
				narrow={false}
			/>
		</>
	);
}

export default connect()(WizardScreen);

const styles = StyleSheet.create({
	container: ws({
		alignItems: "center",
		justifyContent: "center",
	}),
});

export const routeParamsToSearchParams = (routeParams) => {
	const params = { is_full: false, password: "", archived: false, deleted: false };

	if (!routeParams) {
		return params;
	}

	if (routeParams.allowLocked) {
		delete params.password;
	}

	if (routeParams.preferredTags) params.tags = paramToArray(routeParams.preferredTags);
	if (routeParams.avoidedTags) params["avoid-tags"] = paramToArray(routeParams.avoidedTags);

	if (routeParams.age) {
		let date = moment()
			.add(routeParams.age * -1, "day")
			.startOf("day");
		date = formatJSDateToDjangoDate(date);
		params.start_date__gte = date;
	}
	if (routeParams.numPlayers) {
		params.max_players__lte = routeParams.numPlayers;
	}
	if (routeParams.paces && routeParams.paces.length > 0) {
		params.expected_paces = routeParams.paces;
	}

	return params;
};
