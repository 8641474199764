import * as React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import StoryMarker from "../storymarkers/StoryMarker";
import PartySeparator from "../parties/PartySeparator";
import Bookmark from "../bookmark/Bookmark";
import { isStory } from "../../tools/lines";
import { isAndroid } from "../../tools/generic";
import AppText from "../generic/AppText";
import { _ } from '../../i18n/i18n';
import PartyMergedIndicator from "../parties/PartyMergedIndicator";

function LineSeparator({
	isAtTop,
	isAtBottom,
	newContentBookmark,
	lines,
	leadingItem,
	inverted,
	dispatch,
	lineAbove,
	lineBelow,
	gameId,
	storyMarkers,
	parties,
	openedParties,
	onLayout,
}) {
	if (lineAbove && !lineAbove.id) {
		return <View onLayout={onLayout} />;
	}
	let displayedStoryMarkers = React.useMemo(
		() =>
			storyMarkers
				.filter((sm) => {
					if (sm.hidden) return false;
					let startLineId = sm.start_line_id;
					if (sm.party && sm.start_line_id === 0) {
						startLineId = parties[sm.party].separation_line_id;
					}

					// There is a bug where a story marker has no party, even though its start line has one.
					// This can result in the start line not showing, while the story marker should.
					if(!sm.party && lineAbove?.id < startLineId && lineBelow?.id > startLineId){
						return true;
					}

					return startLineId == (lineAbove?.id || 0);
				})
				.filter((sm) => {
					if (sm.party && !openedParties.some((pa) => pa.id === sm.party)) return false;
					return true;
				}),
		[storyMarkers, lineAbove?.id, lineBelow?.id, parties, openedParties]
	);

	let party = parties[lineBelow?.party];

	// if there are already lines before me with the same party, don't show it
	if (
		party?.separation_line_id < lineAbove?.id &&
		lines.some((l) => l.party === party.id && (l.id < lineBelow.id || (l.id && !lineBelow.id)))
	) {
		party = null;
	}

	if (party?.separation_line_id && !lineAbove) {
		party = null;
	}

	// TEMPORARY DUE TO A BUG WITH UPDATING SEPARATOR PROPS IN REACT:
	// https://github.com/facebook/react-native/issues/29662
	if (!lineAbove && !isAtTop) {
		displayedStoryMarkers = [];
		party = null;
	}

	if (isAtBottom && !party && lineAbove?.id) {
		const validParties = Object.values(parties).filter((p) => {
			if (!p.on_going) return false;
			// if there are no line from after the party was created on screen.
			return !lines.some((l) => isStory(l) && l.id > p.separation_line_id);
		});
		// #TOFIX: There is a context in which you can reach this spot with openedParties being undefined. 
		party = validParties.find((p) => openedParties?.some((pa) => pa.id === p.id));
	}

	if (isAtBottom && !lineAbove?.id) {
		displayedStoryMarkers = [];
	}

	
	let justMerged = false;
	if(lineAbove?.party && lineBelow && !lineBelow.party){
		const aboveParty = parties[lineAbove.party];
		if(!aboveParty.on_going && lineBelow.id >= aboveParty.closing_line_id){
			justMerged = true;
		}
	}

	return (
		<View onLayout={onLayout} style={[inverted && isAndroid() && { scaleY: -1 }]}>
			{displayedStoryMarkers.map((sm) => (
				<StoryMarker key={sm.id} marker={sm} gameId={gameId} />
			))}
			<PartySeparator party={party} line={lineAbove} />
			{justMerged && <PartyMergedIndicator/>}
			<Bookmark isAtTop={isAtTop} line={lineBelow} gameId={gameId} newContentBookmark={newContentBookmark} />
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	let { leadingItem, trailingItem } = ownProps;

	let lineAbove = leadingItem;
	let lineBelow = trailingItem;
	if (ownProps.inverted) {
		// As we use an inverted list, leading is below and trailing is above
		lineAbove = trailingItem;
		lineBelow = leadingItem;
	}

	const gameId = ownProps.gameId || lineBelow?.game || lineAbove?.game;

	return {
		storyMarkers: state.storyMarkers[gameId] || Array.rg_empty,
		lineAbove,
		lineBelow,
		gameId,
		parties: state.parties[gameId]?.store || {},
		openedParties: state.parties[gameId]?.openedParties,
	};
};

export default connect(mapStateToProps)(React.memo(LineSeparator));
