import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import IconButton from "../generic/buttons/IconButton";

function JumpToBottomButton({ onPress }) {
	return (
		<View style={styles.container}>
			<IconButton icon={{ type: MaterialIcons, name: "arrow-downward" }} onPress={onPress} />
		</View>
	);
}

export default JumpToBottomButton;

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		bottom: 8,
		left: 0,
		right: 0,
		alignItems: "center",
	},
});
