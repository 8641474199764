import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { BuildStyleMethod } from "../../styles/theming";
import AppButton from "../generic/buttons/AppButton";
import { isWeb } from "../../tools/generic";
import AsyncStorage from '@react-native-async-storage/async-storage';

function CookiesWarning() {
	if (!isWeb()) return null;
	const styles = stylesMethod(global.theme);

	const [cookiesAccepted, setcookiesAccepted] = React.useState(true);

	React.useEffect(() => {
		const checkIfAccepted = async () => {
			let accepted = await AsyncStorage.getItem("cookiesAccepted");

			if (!JSON.parse(accepted)) setcookiesAccepted(false);
		};

		checkIfAccepted();
	}, []);

	const acceptCookies = React.useCallback(() => {
		AsyncStorage.setItem("cookiesAccepted", JSON.stringify(true));
		setcookiesAccepted(true);
	}, []);

	if (cookiesAccepted) {
		return null;
	}

	return (
		<View style={styles.container}>
			<AppText style={{ marginRight: 16 }}>
				<AppText>
					{_(
						"This website uses cookies to remember your login information and other settings. By continuing and using the site, you consent to the use of cookies. More info in our %(privacy_policy_link)s.",
						"",
						{
							privacy_policy_link: (
								<AppText
									bold
									color="success"
									accessibilityRole="link"
									href="https://www.rolegate.com/privacy/"
									target="_blank"
								>
									{_("privacy policy")}
								</AppText>
							),
						}
					)}
				</AppText>
			</AppText>
			<AppButton title={_("Got it!", "close info box")} size="small" style={{ minHeight: 24 }} onPress={acceptCookies} />
		</View>
	);
}

export default CookiesWarning;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flexDirection: "row",
			alignItems: "center",
			padding: 8,
			backgroundColor: colors.primary,
		},
	})
);
