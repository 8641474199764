import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";
import { saveStore } from "../../tools/storeTools";

export const bookmarkForGamesSlice = createSlice({
	name: "bookmarkForGameSlice",
	// {gameId: bookmarkObject}
	initialState: {},
	reducers: {
		receiveBookmarks: (state, { payload: bookmarks }) => {
			for (let i = 0; i < bookmarks.length; i++) {
				const bookmark = bookmarks[i];
				state[bookmark.game] = bookmark;
			}
		},
		receiveBookmark: (state, { payload: bookmark }) => {
			if(!bookmark) return;
			state[bookmark.game] = bookmark;
		},
		removeBookmark: (state, { payload: gameId }) => {
			delete state[gameId];
		},
	},
});

export const { receiveBookmarks, receiveBookmark, removeBookmark } = bookmarkForGamesSlice.actions;

export function fetchBookmarks(userId, gameIds) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/bookmarks`, "GET", { ["game-ids"]: gameIds }).then((results) => {
			dispatch(receiveBookmarks(results));
			saveStore(getState());
			return results;
		});
	};
}


export function fetchBookmark(gameId) {
	return async (dispatch, getState) => {
		const userId = getState().user?.id;
		return apiFetch(`users/${userId}/games/${gameId}/bookmarks`).then((results) => {
			dispatch(receiveBookmarks(results));
			saveStore(getState());
			return results.rg_first();
		});
	};
}

export function saveBookmark(gameId, line) {
	return async (dispatch, getState) => {
		const user = getState().user;
		const existingBookmark = getState().bookmarkForGames[gameId];

		if (existingBookmark) {
			dispatch(receiveBookmark({ ...existingBookmark, line }));
		}

		return apiFetch(`users/${user.id}/games/${gameId}/bookmark`, "POST", {
			line: line?.id,
		}).then((bk) => dispatch(receiveBookmark(bk)));
	};
}

export function deleteBookmark(gameId) {
	return async (dispatch, getState) => {
		const user = getState().user;
		dispatch(removeBookmark(gameId));
		return apiFetch(`users/${user.id}/games/${gameId}/remove-bookmark`, "POST");
	};
}
