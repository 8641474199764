import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";
import { _ } from "../../i18n/i18n";

export const interactiveHelpersSlice = createSlice({
	name: "interactiveHelpersSlice",
	initialState: {
		all: {
			// [helperId]: {}
		},
		public: [],
		publicNextUrl: null,
		userHelpers: [],
		favorites: [],
		openedHelper: null,
		openedPanelId: null,
		panels: {
			// [helperId]: {[panelId]: object}
		},
		options: {
			// [panelId]: []
		},
		// Watch out, game and homeScreen helpers are ActiveHelper objects, not InteractiveHelper objects
		// ActiveHelper => {user: int, game: int, helper: interactiveHelperObject, public: bool, date_added: string}
		homeScreen: [],
		byGames: {
			// [gameId]: [],
		},
	},
	reducers: {
		receiveGameHelpers: (state, { payload: { gameId, activeHelpers } }) => {
			state.byGames[gameId] = activeHelpers;
			activeHelpers.forEach(activeHelper => {
				state.all[activeHelper.helper.id] = activeHelper.helper;
			});
		},
		receiveHomeHelpers: (state, { payload: activeHelpers }) => {
			state.homeScreen = activeHelpers;
			activeHelpers.forEach(activeHelper => {
				state.all[activeHelper.helper.id] = activeHelper.helper;
			});
		},
		receivePublicHelpers: (state, { payload: { results, count, next } }) => {
			state.public = state.public.rg_overlapById(results, true);
			state.all = { ...state.all, ...results.rg_toStore() };
			state.publicNextUrl = next;
		},
		clearPublicHelpers: (state, action) => {
			state.public = [];
		},
		receiveFavoriteHelpers: (state, { payload: helpers }) => {
			state.favorites = helpers;
			state.all = { ...state.all, ...helpers.rg_toStore() };
		},
		receiveUserHelpers: (state, { payload: helpers }) => {
			state.userHelpers = helpers;
			state.all = { ...state.all, ...helpers.rg_toStore() };
		},
		setOpenedHelper: (state, { payload: helper }) => {
			state.openedHelper = helper;
		},
		setOpenedPanelId: (state, { payload: id }) => {
			state.openedPanelId = id;
		},
		receiveHelperPanels: (state, { payload: { helperId, panels } }) => {
			state.panels[helperId] = { ...state.panels[helperId], ...panels.rg_toStore() };
		},
		removeHelperPanel: (state, { payload: { helperId, panelId } }) => {
			const panels = { ...state.panels[helperId] };
			delete panels[panelId];
			state.panels[helperId] = panels;
		},
		receivePanelEdit: (state, { payload: panel }) => {
			state.panels[panel.helper][panel.id] = panel;
		},
		receivePanelOptionEdit: (state, { payload: option }) => {
			state.options[option.panel] = state.options[option.panel].rg_replaceById(option.id, option);
		},
		receiveHelperPanelOptions: (state, { payload: { panelId, options } }) => {
			state.options[panelId] = options;
		},
		addCreatedHelper: (state, { payload: helpers }) => {
			state.userHelpers = state.userHelpers.concat(helper);
		},
		removeHelper: (state, { payload: helper }) => {
			if (state.openedHelper?.id === helper.id) state.openedHelper = null;

			state.userHelpers = state.userHelpers.rg_removeElementByIdPure(helper.id);
			state.public = state.public.rg_removeElementByIdPure(helper.id);
			state.favorites = state.favorites.rg_removeElementByIdPure(helper.id);

			const findActiveHelper = (ah) => ah.helper.id === helper.id;

			state.homeScreen = state.homeScreen.rg_removeElementPure(findActiveHelper);

			const keys = Object.keys(state.byGames);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				state.byGames[key] = state.byGames[key].rg_removeElementPure(findActiveHelper);
			}
		},
		receiveEditedHelper: (state, { payload: helper }) => {
			if (state.openedHelper?.id === helper.id) state.openedHelper = helper;

			const findHelperMethod = (h) => h.id === helper.id;

			state.userHelpers = state.userHelpers.rg_replaceElement(findHelperMethod, helper, false, true);
			state.public = state.public.rg_replaceElement(findHelperMethod, helper, false, true);
			state.favorites = state.favorites.rg_replaceElement(findHelperMethod, helper, false, true);
			state.all[helper.id] = helper;

			const findActiveHelper = (ah) => ah.helper.id === helper.id;

			let index = state.homeScreen.findIndex(findActiveHelper);
			if (index > -1) state.homeScreen[index] = { ...state.homeScreen[index], helper };

			const keys = Object.keys(state.byGames);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				index = state.byGames[key].findIndex(findActiveHelper);
				if (index > -1) state.byGames[key][index] = { ...state.byGames[key][index], helper };
			}
		},
		receiveEditedActiveHelper: (state, { payload: activeHelper }) => {
			const findActiveHelper = (ah) => ah.id === activeHelper.id;

			let index = state.homeScreen.findIndex(findActiveHelper);
			if (index > -1) state.homeScreen[index] = activeHelper;

			const keys = Object.keys(state.byGames);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				index = state.byGames[key].findIndex(findActiveHelper);
				if (index > -1) state.byGames[key][index] = activeHelper;
			}
		},
		storeHelpers: (state, { payload: helpers }) => {
			state.all = { ...state.all, ...helpers.rg_toStore() };
		},
	},
});

export const {
	receivePublicHelpers,
	receiveGameHelpers,
	receiveFavoriteHelpers,
	receiveUserHelpers,
	clearPublicHelpers,
	setOpenedHelper,
	receiveHelperPanels,
	removeHelperPanel,
	receiveHelperPanelOptions,
	setOpenedPanelId,
	receiveHomeHelpers,
	addCreatedHelper,
	receiveEditedHelper,
	receiveEditedActiveHelper,
	receivePanelEdit,
	receivePanelOptionEdit,
	removeHelper,
	storeHelpers,
} = interactiveHelpersSlice.actions;

export function fetchGameHelpers(userId, gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/games/${gameId}/active-helpers`).then((response) =>
			dispatch(receiveGameHelpers({ gameId, activeHelpers: response }))
		);
	};
}

export function fetchHomeScreenHelpers() {
	return async (dispatch, getState) => {
		const userId = getState().user?.id;
		return apiFetch(`users/${userId}/active-helpers`).then((response) => dispatch(receiveHomeHelpers(response)));
	};
}

export function fetchPublicHelpers(params) {
	return async (dispatch, getState) => {
		return apiFetch(`helpers`, "GET", params).then((response) => dispatch(receivePublicHelpers(response)));
	};
}

export function fetchUserHelpers(userId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/helpers`).then(({ results }) => {
			dispatch(receiveUserHelpers(results));
		});
	};
}

export function fetchNextPublicHelpers(url) {
	if (!url) return () => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receivePublicHelpers(response));
		});
	};
}

export function fetchFavoriteHelpers(userId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/helpers`, "GET", { favorite: true }).then(({ results }) => {
			dispatch(receiveFavoriteHelpers(results));
		});
	};
}

export function favoriteHelper(helper) {
	return async (dispatch, getState) => {
		const { user, interactiveHelpers } = getState();
		const favoriteHelpers = interactiveHelpers.favorites;
		dispatch(receiveFavoriteHelpers(favoriteHelpers.rg_pushUniquePure(helper, (h) => h.id === helper.id)));
		return apiFetch(`users/${user.id}/helpers/${helper.id}/favorite`, "POST");
	};
}

export function unfavoriteHelper(helper) {
	return async (dispatch, getState) => {
		const { user, interactiveHelpers } = getState();
		const favoriteHelpers = interactiveHelpers.favorites;
		dispatch(receiveFavoriteHelpers(favoriteHelpers.rg_removeElementByIdPure(helper.id)));
		return apiFetch(`users/${user.id}/helpers/${helper.id}/unfavorite`, "POST");
	};
}

export function fetchHelperPanels(helperId) {
	return async (dispatch, getState) => {
		const { user } = getState();

		const addReceivedPanels = ({ results, next }) => {
			dispatch(receiveHelperPanels({ helperId, panels: results }));
			if (next) {
				return apiFetchSimple(next).then(({ results: newResults, next }) =>
					addReceivedPanels({ results: results.concat(newResults), next })
				);
			}
		};

		return apiFetch(`users/${user.id}/helpers/${helperId}/panels`, "GET", { "page-size": 100 }).then(
			addReceivedPanels
		);
	};
}

export function fetchHelperPanelOptions(panelId) {
	return async (dispatch, getState) => {
		const { user } = getState();
		return apiFetch(`users/${user.id}/helpers/irrelevant/panels/${panelId}/options`).then(({ results }) => {
			dispatch(receiveHelperPanelOptions({ panelId, options: results }));
		});
	};
}

export function attachHelperToGame(userId, gameId, helper, isPublic) {
	return async (dispatch, getState) => {
		isPublic = !!isPublic;
		const gameHelpers = getState().interactiveHelpers.byGames[gameId] || [];
		return apiFetch(`users/${userId}/games/${gameId}/active-helpers`, "POST", {
			game: gameId,
			helper: helper.id,
			user: userId,
			public: isPublic,
		}).then((activeHelper) => {
			dispatch(receiveGameHelpers({ gameId, activeHelpers: gameHelpers.concat(activeHelper) }));
		});
	};
}
export function detachHelperFromGame(userId, gameId, helper) {
	return async (dispatch, getState) => {
		const activeHelpers = getState().interactiveHelpers.byGames[gameId] || [];
		const activeHelper = activeHelpers.find((ah) => ah.helper.id === helper.id);
		dispatch(
			receiveGameHelpers({ gameId, activeHelpers: activeHelpers.rg_removeElementByIdPure(activeHelper.id) })
		);
		return apiFetch(`users/${userId}/games/${gameId}/active-helpers/${activeHelper.id}`, "DELETE");
	};
}

export function attachHelperToHome(userId, helper) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/active-helpers`, "POST", {
			helper: helper.id,
			user: userId,
			public: false,
		}).then((activeHelper) => {
			const homeHelpers = getState().interactiveHelpers.homeScreen;
			dispatch(receiveHomeHelpers(homeHelpers.concat(activeHelper)));
		});
	};
}
export function detachHelperFromHome(userId, helper) {
	return async (dispatch, getState) => {
		const activeHelpers = getState().interactiveHelpers.homeScreen;
		const activeHelper = activeHelpers.find((ah) => ah.helper.id === helper.id);
		dispatch(receiveHomeHelpers(activeHelpers.rg_removeElementByIdPure(activeHelper.id)));
		return apiFetch(`users/${userId}/active-helpers/${activeHelper.id}`, "DELETE");
	};
}

export function editActiveHelper(activeHelper, data) {
	return async (dispatch, getState) => {
		return apiFetch(
			`users/${activeHelper.user}/games/${activeHelper.game}/active-helpers/${activeHelper.id}`,
			"PATCH",
			data
		).then((editedActiveHelper) => {
			dispatch(receiveEditedActiveHelper(editedActiveHelper));
		});
	};
}

export function createInteractiveHelper() {
	return async (dispatch, getState) => {
		const user = getState().user;
		return apiFetch(`users/${user.id}/helpers`, "POST", {
			name: _("New helper", "interactive helper default name"),
		}).then((helper) => {
			dispatch(receiveUserHelpers([helper]));
			return helper;
		});
	};
}

export function createInteractiveHelperPanel(helperId) {
	return async (dispatch, getState) => {
		const user = getState().user;
		return apiFetch(`users/${user.id}/helpers/${helperId}/panels`, "POST", {
			name: _("New panel", "interactive helper PANEL default name"),
		}).then((panel) => {
			dispatch(receiveHelperPanels({ helperId, panels: [panel] }));
			return panel;
		});
	};
}

export function deleteInteractiveHelperPanel(panel) {
	return async (dispatch, getState) => {
		dispatch(removeHelperPanel({ helperId: panel.helper, panelId: panel.id }));
		const user = getState().user;
		return apiFetch(`users/${user.id}/helpers/${panel.helper}/panels/${panel.id}`, "DELETE");
	};
}

export function deleteInteractiveHelperPanelOption(helperId, option) {
	return async (dispatch, getState) => {
		const options = getState().interactiveHelpers.options[option.panel];
		dispatch(
			receiveHelperPanelOptions({ panelId: option.panel, options: options.rg_removeElementByIdPure(option.id) })
		);
		const user = getState().user;
		return apiFetch(`users/${user.id}/helpers/${helperId}/panels/${option.panel}/options/${option.id}`, "DELETE");
	};
}

export function createInteractiveHelperOption(helperId, panelId) {
	return async (dispatch, getState) => {
		const user = getState().user;
		return apiFetch(`users/${user.id}/helpers/${helperId}/panels/${panelId}/options`, "POST", {
			name: _("New option", "interactive helper option default name"),
		}).then((option) => {
			const options = getState().interactiveHelpers.options[panelId];
			dispatch(receiveHelperPanelOptions({ panelId, options: options.concat(option) }));
			return option;
		});
	};
}

export function deleteInteractiveHelper(helper) {
	return async (dispatch, getState) => {
		dispatch(removeHelper(helper));

		const user = getState().user;
		return apiFetch(`users/${user.id}/helpers/${helper.id}`, "DELETE");
	};
}

export function refreshInteractiveHelper(helperId) {
	return async (dispatch, getState) => {
		// const user = getState().user;
		return apiFetch(`helpers/${helperId}`).then((helper)=>{
			dispatch(receiveEditedHelper(helper));
		});
	};
}
