import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import {
	addTagToCharacter,
	createTagForCharacter,
	removeTagFromCharacter,
} from "../../store/slices/characterTagsSlice";
import { idKeyExtractor } from "../../tools/generic";
import AppFlatList from "../generic/AppFlatList";
import AppText from "../generic/AppText";
import SearchBar from "../generic/Searchbar";
import Cond from "../meta/Cond";
import TagItem from "./TagItem";

function TagSelector({
	tags,
	activeTags,
	fullScreenOnFocus,
	autoFocusSearch,
	character,
	onSearchUpdated,
	onEndReached,
	onTagAdded,
	onTagRemoved,
	searchOnly,
	limit,
	style,
}) {
	const [search, setSearch] = React.useState("");
	const [filteredTags, setFilteredTags] = React.useState(tags);
	const navigation = useNavigation();
	const flatListRef = React.useRef();
	const dispatch = useDispatch();

	const createTag = React.useCallback(() => {
		const tagName = search.trim();
		if (!tagName) return;
		const similar = tags.find((t) => t.name.toLowerCase() === tagName.toLowerCase());
		if (similar) {
			onTagSelected(similar);
			0;
			return;
		}

		character && dispatch(createTagForCharacter(tagName, character));

		addTag({ name: tagName });
	}, [search, character]);

	const addTag = React.useCallback(
		(tag) => {
			character && tag.id && dispatch(addTagToCharacter(tag, character));
			onTagAdded && onTagAdded(tag);
			flatListRef.current?.scrollToEnd();
		},
		[activeTags, character, onTagAdded]
	);

	const removeTag = React.useCallback(
		(tag) => {
			character && dispatch(removeTagFromCharacter(tag, character));
			onTagRemoved && onTagRemoved(tag);
		},
		[activeTags, character, onTagRemoved]
	);

	const onTagSelected = React.useCallback(
		(tag) => {
			if (!tag.id) return;
			if (activeTags.some((t) => t.name === tag.name)) {
				removeTag(tag);
			} else {
				addTag(tag);
			}
		},
		[addTag]
	);

	const goFullScreen = () => navigation.navigate("CreateTagScreen", { tags, activeTags, character });

	const OnFiltered = (result) => {
		setFilteredTags(result);
	};

	const limitReached = limit && activeTags.length >= limit

	return (
		<View style={[styles.container, style]}>
			<AppText hide={!limitReached} color="danger" italic>
				{_(
					"You cannot add more than %(amount)s tags",
					"messages indicating that the number of tags' limit  on a game has been reached",
					{amount: limit}
				)}
			</AppText>
			<Cond show={activeTags && activeTags.length}>
				<FlatList
					ref={flatListRef}
					style={{ flexShrink: 0 }}
					contentContainerStyle={{ paddingVertical: 8 }}
					keyExtractor={idKeyExtractor}
					horizontal
					data={activeTags}
					renderItem={({ item }) => (
						<TagItem key={item.id} tag={item} onPress={onTagSelected} style={{ marginHorizontal: 4 }} />
					)}
				/>
				<AppText bold style={{ margin: 8 }}>
					{_("Add tag")}
				</AppText>
			</Cond>
			<SearchBar
				disabled={fullScreenOnFocus}
				items={tags}
				onFilteredResultUpdated={OnFiltered}
				hideIcon
				placeholder={searchOnly ? _("Find a tag...") : _("Create or find a tag...")}
				onFocus={fullScreenOnFocus ? goFullScreen : null}
				autoFocus={autoFocusSearch}
				filterBy={(t) => t.name}
				onCreate={searchOnly ? null : createTag}
				onPress={fullScreenOnFocus ? goFullScreen : null}
				onSearchUpdated={(text) => {
					setSearch(text);
					onSearchUpdated && onSearchUpdated(text);
				}}
				style={{ marginHorizontal: 4 }}
			/>
			<AppFlatList
				contentContainerStyle={{ marginTop: 8, flexDirection: "row", flexWrap: "wrap" }}
				data={filteredTags}
				renderItem={({ item }) => (
					<TagItem
						key={item.id}
						style={{ margin: 4 }}
						tag={item}
						onPress={onTagSelected}
						disabled={limitReached || (activeTags && activeTags.some((t) => t.name === item.name))}
					/>
				)}
				onEndReached={onEndReached}
			/>
			<AppText centered hide={search} color="hint" size="small">
				{_("Start a search for more options", "Hint on how to have more tags to select from")}
			</AppText>
		</View>
	);
}

export default TagSelector;

const styles = StyleSheet.create({
	container: {
		// flex: 1,
	},
});
