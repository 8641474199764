import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const diceSlice = createSlice({
	name: "dice",
	initialState: {},
	reducers: {
		receiveDice: (state, { payload: dice }) => ({ ...state, ...dice.rg_toStore() }),
		receiveDie: (state, { payload: die }) => {
			state[die.id] = die;
			return state;
		},
		removeDie: (state, { payload: dieId }) => {
			delete state[dieId];
		},
	},
});

export const { receiveDice, receiveDie, removeDie } = diceSlice.actions;

export function fetchDice(diceSetIds) {
	if(diceSetIds.length == 0) return;
	return async (dispatch, getState) => {
		return apiFetch(`dice`, "GET", { dice_sets: diceSetIds }).then((response) => {
			dispatch(receiveDice(response.results));
		});
	};
}

export function fetchDie(dieId) {
	return async (dispatch, getState) => {
		return apiFetch(`dice/${dieId}`).then((response) => {
			dispatch(receiveDice([response]));
		});
	};
}

export function createDie(diceSetId) {
	return async (dispatch, getState) => {
		return apiFetch(`dice-sets/${diceSetId}/dice`, "POST", { name: "d0" }).then((die) => {
			dispatch(receiveDie(die));
			return die;
		});
	};
}

export function saveDie(die) {
	return async (dispatch, getState) => {
		const savedDie = getState().dice[die.id];
		const dataToSave = { name: die.name, color: die.color, icon: die.icon };
		dispatch(receiveDie({ ...savedDie, ...dataToSave }));
		return apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}`, "PATCH", dataToSave);
	};
}

export function deleteDie(die) {
	return async (dispatch, getState) => {
		dispatch(removeDie(die.id));
		return apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}`, "DELETE");
	};
}

export function updateDieFace(die, updatedFace) {
	return async (dispatch, getState) => {
		const faces = die.faces.rg_replaceById(updatedFace.id, updatedFace);
		dispatch(receiveDie({ ...die, faces }));
	};
}

export function saveFaceValue(die, face, newValue) {
	return async (dispatch, getState) => {
		return apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}`, "PATCH", {
			value: newValue,
		}).then((updatedFace) => dispatch(updateDieFace(die, updatedFace)));
	};
}

export function saveDieSymbol(die, face, symbol, newValue) {
	return async (dispatch, getState) => {
		const urlBase = `dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}`;

		if (symbol.name) {
			apiFetch(`${urlBase}/remove-text`, "POST", { text: symbol.name });
		}

		apiFetch(`${urlBase}/replace`, "POST", {
			"previous-value": symbol.value,
			"new-value": newValue,
		}).then((updatedFace) => dispatch(updateDieFace(die, updatedFace)));
	};
}

export function saveDieSymbolText(die, face, symbol, newValue) {
	return async (dispatch, getState) => {
		const urlBase = `dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}`;

		let previousValue = symbol.name;

		if (symbol.value) {
			apiFetch(`${urlBase}/remove`, "POST", { "symbol-value": symbol.value });
			previousValue = null;
		}

		apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}/replace-text`, "POST", {
			"previous-value": previousValue,
			"new-value": newValue,
		}).then((updatedFace) => {
			dispatch(updateDieFace(die, updatedFace));
		});
	};
}

export function deleteDieSymbol(die, face, symbol) {
	const params = {};
	let endPoint = "remove";
	if (symbol.value) {
		params["symbol-value"] = symbol.value;
	} else {
		params["text"] = symbol.name;
		endPoint = "remove-text";
	}

	return async (dispatch, getState) => {
		apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}/${endPoint}`, "POST", params).then(
			(updatedFace) => {
				dispatch(updateDieFace(die, updatedFace));
			}
		);
	};
}

export function addSymbolToFace(die, face) {
	return async (dispatch, getState) => {
		apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}/add`, "POST").then((updatedFace) => {
			dispatch(updateDieFace(die, updatedFace));
		});
	};
}

export function deleteFace(die, face) {
	return async (dispatch, getState) => {
		dispatch(receiveDie({ ...die, faces: die.faces.rg_removeElementByIdPure(face.id) }));
		apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}`, "DELETE");
	};
}

export function addFaceToDie(die, isNumber) {
	return async (dispatch, getState) => {
		apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/add`, "POST", { number: isNumber }).then((faces) => {
			dispatch(receiveDie({ ...die, faces }));
		});
	};
}

export function addNumberFaceToDie(die) {
	return async (dispatch, getState) => {
		dispatch(receiveDie({ ...die, faces: die.faces.rg_removeElementByIdPure(face.id) }));
		apiFetch(`dice-sets/${die.dice_set}/dice/${die.id}/faces/${face.id}`, "DELETE");
	};
}
