import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { BuildStyleMethod } from "../../styles/theming";
import BoxModal from "../generic/modal/BoxModal";
import { apiFetch } from "../../network/apiFetch";
import { connect } from "react-redux";
import AppButton from "../generic/buttons/AppButton";
import InputField from "../generic/InputField";

function OAIReclamation({ input, oairequest, modalRef, dispatch, userId }) {
	const styles = stylesMethod(global.theme);

	const [mode, setmode] = React.useState("start");
	const [feedback, setfeedback] = React.useState("");
	const [sending, setsending] = React.useState(false);

	const submitRequest = React.useCallback(async () => {
		setsending(true);
		await apiFetch(`users/${userId}/openairequest/${oairequest.id}/request-refund`, "POST");
		setsending(false);
		setmode("refund sent");
	}, [userId, oairequest.id]);

	const sendFeedback = React.useCallback(async () => {
		setsending(true);
		await apiFetch(`users/${userId}/openairequest/${oairequest.id}/send-feedback`, "POST", { feedback });
		setsending(false);
		setmode("feedback sent");
	}, [userId, oairequest.id, feedback]);

	const startContent = (
		<View>
			<AppText centered>
				{_(
					"Please choose an option below.",
					"choose between asking for a ai token refund or just send feedback."
				)}
			</AppText>
		</View>
	);

	const feedbackContent = (
		<View>
			<AppText color="secondary">{_("Your input:", "input sent to ai")}</AppText>
			<View style={styles.container}>
				<AppText>{input}</AppText>
			</View>

			<AppText style={{ marginTop: 8 }} color="secondary">
				{_("AI output:")}
			</AppText>
			<View style={styles.container}>
				<AppText>{oairequest.ai_output}</AppText>
			</View>

			<AppText style={{ marginTop: 8 }} color="secondary">
				{_("Your feedback:", "user's feedback regarding AI")}
			</AppText>
			<InputField value={feedback} onChangeText={setfeedback} multiline autoGrow />
		</View>
	);

	const refundContent = (
		<View>
			<AppText color="primary">
				{_(
					"Due to the AI's limitations, it is not possible to request a token refund for the following reasons:"
				)}
			</AppText>
			<AppText style={{ marginTop: 4 }} list>
				{_("The quality of the writing is below what I was expecting.")}
			</AppText>
			<AppText list>{_("The context of the output does not apply to my use case.")}</AppText>

			<AppText style={{ marginTop: 8 }}>
				{_(
					"You can improve the output of the AI by adjusting your input info."
				)}
			</AppText>

			<AppText style={{ marginTop: 16 }} color="primary">
				{_("Refund requests will be accepted if:")}
			</AppText>
			<AppText style={{ marginTop: 4 }} list>
				{_("The AI output is empty.")}
			</AppText>
			{/* <AppText list>{_("The AI output is the same as your input, almost verbatim.")}</AppText> */}
			<AppText list>{_("The AI output is unreadable (not actual sentences).")}</AppText>

			<AppText style={{ marginTop: 16 }}>
				{_(
					"Each refund request is analyzed by an real person and the AI output below will be sent along your request."
				)}
			</AppText>

			<AppText style={{ marginTop: 8 }} color="secondary">
				{_("AI output:")}
			</AppText>
			<View style={styles.container}>
				<AppText>{oairequest.ai_output}</AppText>
			</View>
		</View>
	);

	const refundSentContent = (
		<View>
			<AppText centered>{_("Your refund request has been received.")}</AppText>
		</View>
	);
	const feedbackSentContent = (
		<View>
			<AppText centered>{_("Your feedback has been sent.")}</AppText>
			<AppText centered>{_("Thank you!")}</AppText>
		</View>
	);

	let title = "";

	let options = [];

	if (mode === "refund") {
		options = [
			{ title: _("Back"), transparent: true, onPress: () => setmode("start"), keepOpen: true, disabled: sending },
			{
				title: _("Submit a refund request"),
				size: "large",
				onPress: submitRequest,
				keepOpen: true,
				disabled: sending,
			},
		];

		title = _("Token refund request", "request ai token refund");
	} else if (mode === "feedback") {
		options = [
			{ title: _("Back"), transparent: true, onPress: () => setmode("start"), keepOpen: true, disabled: sending },
			{ title: _("Send feedback"), size: "large", onPress: sendFeedback, keepOpen: true, disabled: sending },
		];
		title = _("Send feedback", "send feedback regarding an AI output");
	} else if (mode === "start") {
		options = [
			{
				title: _("Send feedback", "send feedback regarding an AI output"),
				onPress: () => setmode("feedback"),
				keepOpen: true,
				disabled: sending,
			},
			{ title: _("Ask for a token refund"), size: "large", onPress: () => setmode("refund"), keepOpen: true },
		];
		title = _("I have an issue with the AI output", "screen title");
	} else {
		options = [
			{
				title: _("Done"),
				onPress: () => setmode("start"),
			},
		];
		title = _("Sent!", "email sent");
	}

	return (
		<BoxModal ref={modalRef} title={title} options={options}>
			{mode === "refund" && refundContent}
			{mode === "feedback" && feedbackContent}
			{mode === "start" && startContent}
			{mode === "refund sent" && refundSentContent}
			{mode === "feedback sent" && feedbackSentContent}
		</BoxModal>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user.id,
	};
};

export default connect(mapStateToProps)(OAIReclamation);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.translucidBackground,
			padding: 16,
		},
	})
);
