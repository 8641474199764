import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import AppText from "../generic/AppText";
import { colors } from "../../styles/colors";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { isWeb } from "../../tools/generic";

function TagItem({ tag, disabled, onPress, tagSize="small", style, icon }) {
	const ContainerTag = onPress ? TouchableOpacity : View;

	icon = icon || "\u2B24";

	return (
		<View style={disabled ? styles.disabled : null}>
			<ContainerTag disabled={disabled} style={[globalStylesMethod(global.theme).tagPill, style]} onPress={() => onPress(tag)}>
				<AppText
					size={tagSize}
					color={isWeb() ? "secondary" : "textDefault"}
					numberOfLines={1}
					ellipsizeMode="tail"
					pointerEvents="none"
				>
					{icon} {tag.name}
				</AppText>
			</ContainerTag>
		</View>
	);
}

export default TagItem;

const styles = StyleSheet.create({
	disabled: {
		opacity: 0.5,
	},
});
