import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { socket } from "../../network/websocket";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import {
	setCommentMessage,
	setEditingLine,
	setEditMessage,
	setEnrichOutput,
	setMessage,
	setTalkToCharacterIds,
	setTone, setToneColor,
	setWhisperingTo,
	setWhisperToCharacterIds,
	setWhisperToPlayerIds,
} from "../../store/slices/gamesUISlice";
import { BuildStyleMethod } from "../../styles/theming";
import { isGM } from "../../tools/games";
import { isWeb, ws } from "../../tools/generic";
import IconButton from "../generic/buttons/IconButton";
import CondView from "../meta/CondView";
import ChangeLanguageButton from "./ChangeLanguageButton";
import ChangeToneButton from "./ChangeToneButton";

let stopTypingTimeout = null;

function EditMessageField({
	textInputRef,
	setselection,
	setfocused,
	isComments,
	messageType,
	message,
	setMessageMethod,
	handleKey,
	editingLine,
	dispatch,
	user,
	savedOutput,
	currentCharacter,
	languages,
	currentParty,
	gameId,
	selectedTone,
	talkToGameId,
}) {
	const [typing, settyping] = React.useState(false);
	const styles = stylesMethod(global.theme);
	
	//========================================
	const emitStartTyping = React.useCallback(() => {
		if (!user) return;
		socket.emit("typing_story", user.id, gameId);
	}, [user?.id, gameId]);

	const emitStopTyping = React.useCallback(() => {
		if (!user) return;
		socket.emit("stop_typing_story", user.id, gameId);
		settyping(false);
	}, [user?.id, gameId]);

	// Called here and not in handlechange, so that when the text is set from editing a line,
	// it immediately takes the proper size
	React.useEffect(() => {
		const target = textInputRef?.current;
		if (!target) return () => null;
		target.style.height = 0;
		const height = target.scrollHeight;
		target.style.height = `${height}px`;
	}, [message]);

	let placeholder = _("Write a message...", "chat input placeholder");

	if (currentParty && messageType != "chat") {
		placeholder = _("Write a message to %(party_name)s...", "chat input placeholder", {
			party_name: currentParty.name,
		});
	}

	// stop typing on unmount
	React.useEffect(() => emitStopTyping, [emitStopTyping]);

	const handleChange = React.useCallback(
		(e) => {
			if (!typing) {
				settyping(true);
				emitStartTyping();
			}
			clearTimeout(stopTypingTimeout);
			stopTypingTimeout = setTimeout(emitStopTyping, 2000);
		},
		[typing, emitStopTyping]
	);

	const lightBackground = messageType === "dialogue" || messageType === "chat";

	const selectTone = (tone) => {
		dispatch(setTone({ gameId, value: tone }));
		textInputRef.current.focus();
	};

	const selectToneColor = (tone) => {
		dispatch(setToneColor({ gameId, value: tone }));
	}

	const cancelEditLine = React.useCallback(() => {
		dispatch(setTalkToCharacterIds({ gameId: talkToGameId, value: [] }));
		dispatch(setWhisperToPlayerIds({ gameId: talkToGameId, value: [] }));
		dispatch(setWhisperingTo({ gameId: talkToGameId, value: [] }));
		dispatch(setEditingLine({ gameId, value: null }));
		dispatch(setMessageMethod({ gameId, value: "" }));
	}, [gameId, talkToGameId]);

	const inputBoxStyle = [styles.inputBox];
	selectedTone &&
		selectedTone !== "neutral" &&
		inputBoxStyle.push({ borderColor: global.colors.toneColors[selectedTone] });

	messageType === "description" && !currentCharacter && isGM() && inputBoxStyle.push(styles.inputBoxStorytelling);
	messageType === "description" && currentCharacter && inputBoxStyle.push(styles.inputBoxDescription);
	messageType === "dialogue" && inputBoxStyle.push(styles.inputBoxDialogue);
	messageType === "chat" && inputBoxStyle.push(styles.inputBoxChat);

	const gm = isGM();

	return (
		<View style={inputBoxStyle}>
			<CondView show={editingLine} style={styles.optionColumn}>
				<IconButton
					icon={{
						type: MaterialIcons,
						name: "clear",
						color: lightBackground ? global.colors.textDark : global.colors.textLight,
					}}
					transparent
					style={{ height: 24 }}
					onPress={cancelEditLine}
				/>
			</CondView>

			<TextInput
				autoFocus
				placeholderTextColor={lightBackground ? global.colors.hintDark : global.colors.placeholder}
				ref={textInputRef}
				value={message}
				onChange={handleChange}
				onChangeText={(text) => {
					dispatch(setMessageMethod({ gameId, value: text }));
					if (savedOutput) dispatch(setEnrichOutput({ gameId, value: null }));
				}}
				onKeyPress={handleKey}
				placeholder={placeholder}
				multiline
				style={[
					styles.inputStyle,
					{ color: lightBackground ? global.colors.textDark : global.colors.textLight },
				]}
				onSelectionChange={(event) => {
					// prettier-ignore
					const {nativeEvent: { selection: { start, end } }} = event;
					setselection({ start, end });
				}}
				onFocus={() => setfocused(true)}
				onBlur={() => setfocused(false)}
			/>

			<CondView show={!editingLine && !isComments} style={styles.optionColumn}>
				<ChangeToneButton selectTone={selectTone} setToneColor={selectToneColor} dark={lightBackground} />
				<CondView show={messageType === "dialogue"}>
					<ChangeLanguageButton disabled={!gm && !languages?.length} dark={lightBackground} />
				</CondView>
			</CondView>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	const gameUI = state.gamesUI[gameId];

	// Used on desktop so that we can separately manage the "talk to"
	// characters in new messages and in the currently edited line
	let talkToGameId = gameId;
	if (ownProps.inlineEditing) {
		talkToGameId += "inlineEdit";
	}

	return {
		currentCharacter: getCurrentCharacter(state),
		savedOutput: gameUI?.enrichOutput,
		languages: state.languages[gameId] || Array.rg_empty,
		user: state.user,
		currentParty: state.parties[gameId]?.current,
		gameId,
		selectedTone: gameUI?.tone,
		talkToGameId,
	};
};

export default connect(mapStateToProps)(EditMessageField);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		inputBox: {
			flex: 1,
			borderWidth: 1,
			borderRadius: 8,
			paddingVertical: 10,
			paddingHorizontal: 8,
			flexDirection: "row",
			marginRight: 12,
			minHeight: 42,
			alignItems: "flex-end",
		},
		inputBoxDescription: {
			backgroundColor: colors.descriptionBackground,
			borderColor: colors.descriptionBackground,
		},
		inputBoxStorytelling: {
			backgroundColor: colors.storyTellingBackground,
			borderColor: colors.storyTellingBackground,
		},
		inputBoxChat: {
			backgroundColor: colors.talkBackground,
			borderColor: colors.talkBackground,
		},
		inputBoxDialogue: {
			backgroundColor: colors.talkBackground,
			borderColor: colors.talkBackground,
		},

		optionColumn: {
			flexDirection: "row",
		},
		inputStyle: {
			flex: 1,
			marginLeft: 4,
			color: "white",
			// outlineWidth crashes on mobile
			...ws({ outlineWidth: 0, height: 24, minHeight: 24 }, null),
		},
	})
);
