// Using both Gateway and Paper, because Paper doesn't let you specify the Portal.Host you want to render to
// So anything that should not render in the root Portal.Host is done with Gateway
// Gateway is however less effecient and creates lag in the input field updates. So whenever possible, go with Paper's Portals.
// https://github.com/chardskarth/react-gateway (not the cloudfare one, cause it's outdated)
import GatewayDest from "../gateway/GatewayDest";
import GatewayProvider from "../gateway/GatewayProvider";
import { NavigationContainer } from "@react-navigation/native";
import * as ExpoLinking from "expo-linking";
import * as React from "react";
import { Linking, Platform, StatusBar, View, AppState } from "react-native";
import { Portal, Provider as PaperProvider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../i18n/i18n";
import { apiFetch } from "../network/apiFetch";
import { socket } from "../network/websocket";
import Sentry from "../reimports/Sentry";
import { fetchCurrentUser } from "../store/slices/usersSlice";
import { RGNavigationThemeDark, RGNavigationThemeDarkest } from "../styles/navigationtheme";
import { isWeb } from "../tools/generic";
import { linkingConfiguration } from "../tools/navigationConfig";
import { usePrevious } from "../tools/react";
import AppText from "./generic/AppText";
import TinyFeedbackModal from "./generic/modal/TinyFeedbackModal";
import AccountFlaggedWarning from "./home/AccountFlaggedWarning";
import CookiesWarning from "./home/CookiesWarning";
import DownloadAppSuggestion from "./home/DownloadAppSuggestion";
import MaintenanceBanner from "./maintenancewarning/MaintenanceBanner";
import UnderMaintenanceScreen from "./maintenancewarning/UnderMaintenanceScreen";
import MobileNotificationsHandler from "./mobilenotifications/MobileNotificationsHandler";
import AppNavigator from "./navigation/AppNavigator";
import moment from "moment";
import * as Updates from "expo-updates";
import { colors } from "../styles/colors";

function AppContainer({ containerRef, initialUrl, defaultMaintenance, dispatch, user, theme }) {
	const userId = user?.id;

	const [underMaintenance, setunderMaintenance] = React.useState(defaultMaintenance);
	const [forceMaintenanceTime, setforceMaintenanceTime] = React.useState(null);
	const verifiedRef = React.useRef();
	const previousUser = usePrevious(user);

	if (global.appleStoreReview && user?.profile.reward_level > 0) {
		global.appleStoreReview = false;
	}

	React.useEffect(() => {
		// Set information for crash reporting
		if (user) {
			const { id, username, email } = user;
			Sentry?.Native.setUser({ id, username, email });
		}
	}, [user]);

	React.useEffect(() => {
		if (userId && socket) {
			socket.joinRoom(`mobile_user_${userId}`);
			socket.joinRoom(`user${userId}`);
			socket.on("profile_updated", () => dispatch(fetchCurrentUser()));
		}
		return () => {
			socket.leaveRoom(`mobile_user_${userId}`);
			socket.leaveRoom(`user${userId}`);
		};
	}, [userId, socket]);

	React.useEffect(() => {
		if (socket) {
			socket.on("maintenance_started", () => {
				setforceMaintenanceTime(moment().add(5, "minutes"));
			});
			socket.on("maintenance_ended", () => {
				forceAppUpdate();
			});
			socket.on("mandatory_version_update", (newVersion) => (global.mandatoryVersion = newVersion));
		}
	}, [socket]);

	React.useEffect(() => {
		if (underMaintenance && forceMaintenanceTime) {
			setforceMaintenanceTime(null);
		}
	}, [underMaintenance, forceMaintenanceTime]);

	React.useEffect(() => {
		if (user && !user.profile.app_logged) {
			apiFetch(`profiles/${user.profile.id}`, "PATCH", { app_logged: true });
		}
	}, [user]);

	const forceAppUpdate = React.useCallback(async () => {
		if (isWeb()) {
			window.location.reload(false);
			return;
		}
		const update = await Updates.checkForUpdateAsync();
		if (update.isAvailable) {
			await Updates.fetchUpdateAsync();
			await Updates.reloadAsync();
		} else {
			setunderMaintenance(false);
		}
	}, []);

	const goToUrl = React.useCallback(() => {
		if (initialUrl && !isWeb()) {
			let { path, queryParams } = ExpoLinking.parse(initialUrl);
			if (path) {
				let redirectUrl = ExpoLinking.createURL(path);
				Linking.openURL(redirectUrl);
			}
		}
	}, [initialUrl]);

	const verifyEmailIfNeeded = React.useCallback((url) => {
		if (!url) return;
		let { path, queryParams } = ExpoLinking.parse(url);
		if (path && path.startsWith("verify/")) {
			const code = path.replace("verify/", "");
			apiFetch("api/code-verification/" + code, "GET", null, true);
		}
	}, []);

	React.useEffect(() => {
		verifyEmailIfNeeded(initialUrl);
	}, [initialUrl]);

	React.useEffect(() => {
		if (previousUser && !previousUser.profile.email_verified && user?.profile.email_verified) {
			verifiedRef.current.show();
		}
	}, [user?.profile.email_verified]);

	React.useEffect(() => {
		const callback = (response) => verifyEmailIfNeeded(response?.url);
		const sub = ExpoLinking.addEventListener("url", callback);
		return () => {
			sub.remove();
		};
	}, [verifyEmailIfNeeded]);

	React.useEffect(() => {
		global.isFocused = true;

		const onWindowFocus = () => {
			global.isFocused = true;
		};
		const onWindowBlur = () => {
			global.isFocused = false;
		};

		if (isWeb()) {
			window.addEventListener("focus", onWindowFocus);
			window.addEventListener("blur", onWindowBlur);
			return () => {
				window.removeEventListener("blur", onWindowFocus);
				window.removeEventListener("blur", onWindowBlur);
			};
		}

		const _handleAppStateChange = (nextAppState) => {
			global.isFocused = nextAppState === "active";
		};
		const removeChangeListener = AppState.addEventListener("change", _handleAppStateChange);
		return () => {
			removeChangeListener.remove();
		};
	}, []);

	if (underMaintenance) {
		return <UnderMaintenanceScreen forceSkip={() => setunderMaintenance(false)} />;
	}

	let BullshitComponent = null;

	if (isWeb() && !user) {
		// Since react navigation 6, there is a bug where the gamescreen will not fit into the window size on web.
		// That is unless there is another component with at least height 1px, sibling to the NavigationContainer. It only happens if the user is not loggeed in.
		// I don't know why, I am full of hatred and I can't do that anymore.
		BullshitComponent = <View style={{ height: 1, backgroundColor: colors.cardBackgroundLight }} />;
	} 

	return (
		<GatewayProvider>
			<PaperProvider>
				<Portal.Host>
					<View style={{ flex: 1 }}>
						{BullshitComponent}
						<CookiesWarning />
						{Platform.OS === "ios" && <StatusBar barStyle="light-content" />}
						<NavigationContainer
							ref={containerRef}
							theme={theme === "dark" ? RGNavigationThemeDark : RGNavigationThemeDarkest}
							linking={linkingConfiguration(!!user)}
							onReady={goToUrl}
						>
							<MaintenanceBanner
								forceMaintenanceTime={forceMaintenanceTime}
								onTimerEnded={() => setunderMaintenance(true)}
							/>
							<AppNavigator />
							<TinyFeedbackModal
								ref={verifiedRef}
								message={_("Email verified!", "confirmation that your email was verified")}
							/>
							<AccountFlaggedWarning />
							{isWeb() && <DownloadAppSuggestion />}
						</NavigationContainer>
						<GatewayDest
							name="modals"
							component={(props) => (
								<View
									pointerEvents="box-none"
									style={{
										position: "absolute",
										top: 0,
										bottom: 0,
										left: 0,
										right: 0,
									}}
									{...props}
								/>
							)}
						/>
						<MobileNotificationsHandler />
					</View>
				</Portal.Host>
			</PaperProvider>
		</GatewayProvider>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		theme: state.appsettings.theme,
	};
};

export default connect(mapStateToProps, null)(AppContainer);
