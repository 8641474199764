import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const rollRequestSlice = createSlice({
	name: "rollRequests",
	initialState: {},
	reducers: {
		// request can sometime be empty, so gameId must be sent along
		receiveRollRequests: (state, { payload: { gameId, requests } }) => {
			state[gameId] = requests;
			return state;
		},
		removeRollRequest: (state, { payload: request }) => {
			state[request.game] = state[request.game].rg_removeElementByIdPure(request.id);
			return state;
		},
	},
});

export const { receiveRollRequests, removeRollRequest } = rollRequestSlice.actions;

export function fetchRollRequests(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/roll-requests`).then(({ results }) => {
			const requests = results || [];
			dispatch(receiveRollRequests({ requests, gameId }));
		});
	};
}
