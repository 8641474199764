import * as React from "react";
import { ActivityIndicator, SectionList, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import {
	clearPublicSheets,
	fetchNextPublicSheets,
	fetchPublicSheets,
	fetchUserSheets,
	createCharacterSheet,
} from "../../store/slices/sheetsSlice";
import { colors } from "../../styles/colors";
import { fullScreenLeftPadding, fullScreenContentWidth } from "../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import SearchBar from "../generic/Searchbar";
import CharacterSheetItem from "./CharacterSheetItem";

import { BuildStyleMethod } from "../../styles/theming";

function BrowseSheetTemplatesScreen({ route, navigation, dispatch, user, nextUrl, privateSheets, publicSheets }) {
	const styles = stylesMethod(global.theme);
	const confirmCreateModalRef = React.useRef();

	const [creating, setcreating] = React.useState(false);
	const [searching, setsearching] = React.useState(false);
	const [currentSearch, setcurrentSearch] = React.useState(null);

	React.useEffect(() => {
		dispatch(fetchUserSheets(user.id));
	}, [user.id]);

	const fetchNext = React.useCallback(() => {
		if (!nextUrl) return;
		if (searching) return;
		dispatch(fetchNextPublicSheets(nextUrl));
	}, [nextUrl, searching]);

	const create = React.useCallback(async () => {
		setcreating(true);
		const newSheet = await dispatch(createCharacterSheet(user.id));
		setcreating(false);
		navigation.navigate("CharacterSheetTemplateScreen", { sheetId: newSheet.id, isTemplate: true });
	}, [user.id]);

	const performSearch = React.useCallback(async () => {
		const params = {};
		if (currentSearch) {
			params.search = currentSearch;
		}
		setsearching(true);
		await dispatch(clearPublicSheets());
		await dispatch(fetchPublicSheets(params));
		setsearching(false);
	}, [currentSearch]);

	React.useEffect(() => {
		if (!currentSearch) {
			performSearch();
		} else {
			const searchTimeout = setTimeout(performSearch, 1000);
			return () => clearTimeout(searchTimeout);
		}
	}, [currentSearch]);

	if (currentSearch) {
		privateSheets = privateSheets.filter(
			(s) => s.save_name && s.save_name.toLowerCase().includes(currentSearch.toLowerCase())
		);
	}

	const renderSheetItem = React.useCallback(
		({ item }) => <CharacterSheetItem sheet={item} selectingTemplateFor={route.params?.selectingTemplateFor} />,
		[]
	);

	publicSheets = publicSheets.filter((s) => s.save_name);
	const sectionsData = [
		{
			private: true,
			title: _("Your sheet templates", "show user's sheet templates"),
			data: privateSheets,
		},
		{
			title: _("Community templates", "show community sheet templates"),
			data: publicSheets,
		},
	];

	const { onLayout } = useLayout();

	return (
		<AppScreenView
			borderless
			onLayout={onLayout}
			style={[
				ws({
					paddingLeft: fullScreenLeftPadding(),
				}),
			]}
		>
			<SearchBar
				key="search_bar"
				onSearchUpdated={setcurrentSearch}
				style={[{ margin: 8 }, ws({ maxWidth: fullScreenContentWidth() })]}
			/>
			<SectionList
				onRefresh={() => {
					performSearch();
					dispatch(fetchUserSheets(user.id));
				}}
				refreshing={searching}
				keyExtractor={idKeyExtractor}
				sections={sectionsData}
				renderSectionHeader={({ section: { title } }) => (
					<AppText style={styles.title} bold>
						{title}
					</AppText>
				)}
				renderItem={renderSheetItem}
				onEndReached={fetchNext}
				onEndReachedThreshold={0.2}
				stickySectionHeadersEnabled={true}
				renderSectionFooter={({ section }) => {
					if (!section.data.length) {
						if (section.private) {
							return (
								<AppText color="hint" centered style={{ marginVertical: 16 }}>
									{_("Press + to create a new sheet template.", "empty sheet templates list message")}
								</AppText>
							);
						} else {
							return <ActivityIndicator color={global.colors.hint}/>;
						}
					}
					return null;
				}}
				stickyHeaderIndices={currentSearch ? [0] : []}
				// Workaround for crash regarding stickyHeaderIndices on android
				// https://github.com/facebook/react-native/issues/25157#issuecomment-500833326
				removeClippedSubviews={false}
				contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
			/>

			<FAB
				icon="plus"
				style={globalStylesMethod(global.theme).fab}
				onPress={() => confirmCreateModalRef.current.show()}
				disabled={creating}
			/>
			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create template")}
				message={_("Create a new character sheet template?")}
				onConfirm={create}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const user = state.user;
	return {
		user,
		privateSheets: state.sheets.users[user?.id] || Array.rg_empty,
		publicSheets: state.sheets.public,
		nextUrl: state.sheets.publicNextUrl,
	};
};

export default connect(mapStateToProps)(BrowseSheetTemplatesScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	title: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		backgroundColor: colors.cardBackground,
	},
}));
