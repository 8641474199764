import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-paper";
import { connect } from "react-redux";
import { globalStyles } from "../../styles/global";
import { levelToCoinColor, levelToRankName } from "../../tools/account";
import { getCoverUrl } from "../../tools/games";
import { isWeb, ws } from "../../tools/generic";
import CharacterAvatar from "../avatar/CharacterAvatar";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";

function HomeIcon({ folded, forceFolded, onFoldPressed, game, drawerType, dispatch, user }) {
	const navigation = useNavigation();

	if (!user) return (
		<View>
			{game && <Avatar.Image source={{ uri: getCoverUrl(game) }} size={48} style={{ marginLeft: 198 }} />}
		</View>
		
	)

	const lastCharacter = user?.profile.last_used_character;
	const rewardLevel = user.profile.reward_level;

	const username = user.username;
	const adjustsFontSizeToFit = username.length > 9;

	// Watch out:
	// Yet another bug in react navigation to work around. If there is a minimum height on an element that is outside of the DrawerContentScrollView, while the drawertype is "front"
	// then the element will be always rendered (but invisible) on top of everything, as if the drawer was open. This will stops pointer event from reaching elements
	// on screen that would be below.
	// For some reason though, it will only happen if the drawer is "folded". To investigate... but for now we automatically unfold the drawer when it is in "front" mode.
	return (
		<TouchableOpacity
			style={[{ flexDirection: "row", alignItems: "center", flex: 1 }, { minHeight: 50 }]}
			onPress={onFoldPressed}
		>
			{isWeb() && !forceFolded && (
				<IconButton
					icon={{ type: MaterialIcons, name: "menu" }}
					onPress={onFoldPressed}
					transparent
					style={folded ? { marginLeft: 6 } : null}
				/>
			)}
			{!folded && (
				<>
					<TouchableOpacity
						style={[globalStyles.rc, { flex: 1, width: 160 }]}
						onPress={() => navigation.navigate("MyGames")}
					>
						<CharacterAvatar character={lastCharacter} size={ws(42, 64)} />
						<View style={{ flex: 1 }}>
							<AppText
								bold
								size="large"
								textOnlyStyle={[adjustsFontSizeToFit && { fontSize: null }]}
								adjustsFontSizeToFit={adjustsFontSizeToFit}
								ellipsizeMode="tail"
								numberOfLines={1}
							>
								{username}
							</AppText>
							<AppText textOnlyStyle={{ color: levelToCoinColor(rewardLevel) }}>
								{levelToRankName(rewardLevel)}
							</AppText>
						</View>
					</TouchableOpacity>
				</>
			)}

			{game && <Avatar.Image source={{ uri: getCoverUrl(game) }} size={48} style={{ marginLeft: 4 }} />}
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(HomeIcon);

const styles = StyleSheet.create({
	container: {},
});
