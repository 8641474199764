import * as React from "react";
import { View, StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import CoinText from "./CoinText";

function CoinTransactionItem({ transaction }) {
	return (
		<View style={styles.container}>
			<AppText>{!!transaction.sender ? transaction.sender.username : _("[Deleted]", "deleted user")}</AppText>
			<CoinText level={transaction.coin_level} amount={transaction.amount} />
		</View>
	);
}

export default CoinTransactionItem;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	}
});
