import * as React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";
import { getCharName, idToCharacter, idToCharacterState } from "../../tools/characters";
import { isGM } from "../../tools/games";
import { ws } from "../../tools/generic";
import { getTimestamp } from "../../tools/time";
import CoinValue from "../coins/CoinValue";
import AppText from "../generic/AppText";
import RGIcon from "../RGIcon";
import DiceCommandResult from "./DiceCommandResult";
import { connect } from "react-redux";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { getPools } from "../../tools/diceRoll";
import { apiFetch } from "../../network/apiFetch";
import { removeLines } from "../../store/slices/linesSlice";
import { BuildStyleMethod } from "../../styles/theming";

function DiceRollLine({ line, onPress, onLayout, style, dispatch, author, sheetValuePairs, users, characters }) {

	const styles = stylesMethod(global.theme);

	const action = React.useMemo(() => JSON.parse(line.action), [line.action]);
	const gmOnly = !line.author;
	if (gmOnly && !isGM(line.game)) {
		return null;
	}

	const characterState = idToCharacterState(line.character_state);
	const charName = getCharName(author, characterState?.name);
	const timeStamp = getTimestamp(line.created);

	// If the roll is whispered (can only whisper to GM), we need to show that
	const isWhispered = line.whispered_to && line.whispered_to.length > 0;
	const lineBoxStyle = [styles.lineBox];
	isWhispered && lineBoxStyle.push(styles.lineBoxWhispered);

	const deleteLine = React.useCallback(() => {
		dispatch(removeLines({ gameId: line.game, lines: [line] }));
		apiFetch(`games/${line.game}/lines/${line.id}`, "DELETE");
	}, [line]);

	React.useEffect(() => {
		// If the line was thrown automatically while players were offline, it won't have any pools
		// and will need to generate some

		const lateExecuteRoll = async () => {
			if (!sheetValuePairs) {
				dispatch(fetchSheetValuePairsIfNeeded(author.sheet));
				return null;
			}

			const sheet_value_targets = action.sheet_value_targets ? JSON.parse(action.sheet_value_targets) : [];

			const dicePools = getPools(line.content, sheet_value_targets, author);

			if (!dicePools.length) {
				deleteLine();
				return null;
			}
			for (let i = 0; i < dicePools.length; ++i) {
				let pool = dicePools[i];
				if (pool.error) {
					deleteLine();
					return null;
				}
			}

			apiFetch(`games/${line.game}/lines/${line.id}/create-pool`, "POST", {
				"dice-pools": JSON.stringify(dicePools),
			});
		};

		if (action.type === "dice_action" && !line.pools?.length && !action.dice_pools?.length) {
			lateExecuteRoll();
		}
	}, [action, line, author, sheetValuePairs, deleteLine]);

	return (
		<TouchableOpacity style={[styles.line, style]} onPress={onPress} onLayout={onLayout}>
			<View style={[styles.avatarColumn]}>
				<RGIcon name="die" size={32} color={author ? colors.userColors[author.color] : colors.userColors[1]} />
			</View>
			<View style={[lineBoxStyle]}>
				<AppText
					hide={!line.whispered_to?.length}
					style={styles.lineTarget}
					color="textLight"
					size="small"
				>
					{_("to GM", "list characters being talked to")}
				</AppText>
				<AppText color="attentionSecondary" hide={!action.name}>
					{action.name}
				</AppText>
				<AppText color="hint" hide={!gmOnly} style={null} italic>
					{_("Only you can see", "dice roll seen only by the player")}
				</AppText>
				<AppText>
					<AppText>{charName}</AppText> <DiceCommandResult line={line} pools={action.dice_pools || line.pools} />
					<AppText size="small" style={{ color: "rgba(0,0,0,0)" }}>
						{ws("         ", "   ")}
						{timeStamp}
					</AppText>
				</AppText>
				<AppText color="hint" size="small" style={styles.date}>
					{timeStamp}
				</AppText>
				<CoinValue subject={line} style={styles.coinValue} subjectGetter={{ line_id: line.id }} hideIfNone />
			</View>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	const author = idToCharacter(ownProps.line.author);
	return {
		author,
		sheetValuePairs: state.sheetValuePairs[author?.sheet],
		users: state.users,
		characters: state.characters,
	};
};

export default connect(mapStateToProps)(DiceRollLine);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		line: {
			flexDirection: "row",
			marginVertical: 8,
			marginHorizontal: 8,
		},
		lineBox: {
			paddingBottom: 4,
			paddingHorizontal: 8,
			flex: 1,
			justifyContent: "center",
			...ws({
				flex: 0,
				flexGrow: 0,
				flexShrink: 1,
				flexBasis: "auto",
			}),
		},
		lineBoxWhispered: {
			borderWidth: 1,
			borderRadius: 8,
			borderStyle: "dashed",
			borderColor: colors.secondary,
			backgroundColor: "transparent",
		},
		lineTarget: {
			backgroundColor: colors.secondary,
			marginHorizontal: -8,
			padding: 4,
			paddingLeft: 8,
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
		},
		date: {
			position: "absolute",
			bottom: 0,
			right: 8,
		},
		avatarColumn: {
			width: 48,
			justifyContent: "center",
			alignItems: "center",
		},
		coinValue: { marginTop: -8, bottom: -8 },
	})
);
