import * as React from "react";
import { View, StyleSheet } from "react-native";
import ListSwitch from "../../generic/ListSwitch";
import { _ } from "../../../i18n/i18n";
import { simpleReducer, usePrevious } from "../../../tools/react";
import { Divider } from "react-native-paper";
import AppText from "../../generic/AppText";
import { globalStyles, globalStylesMethod } from "../../../styles/global";
import { apiFetch } from "../../../network/apiFetch";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../../styles/dynamicStyles";
import { ws } from "../../../tools/generic";

function GameSettingToggles({ game, style, onGameUpdated }) {
	const [editedGame, updateGame] = React.useReducer(simpleReducer, game);
	const previousGame = usePrevious(editedGame);

	const saveChanges = React.useCallback(() => {
		if (editedGame.id) {
			apiFetch(`games/${game.id}`, "PATCH", {
				beginner_friendly: editedGame.beginner_friendly,
				allow_spectators: editedGame.allow_spectators,
				allow_public_chat: editedGame.allow_public_chat,
				allow_export: editedGame.allow_export,
				hidden: editedGame.hidden,
			});
		}
	}, [editedGame]);

	React.useEffect(() => {
		onGameUpdated && onGameUpdated(editedGame);
	}, [editedGame]);

	React.useEffect(() => {
		if (!previousGame) return;
		const saveTimeout = setTimeout(saveChanges, 1000);
		return () => {
			clearTimeout(saveTimeout);
		};
	}, [saveChanges]);	

	return (
		<View style={[globalStylesMethod(global.theme).floatingSection, ws({ alignItems: "center"  }), style]}>
			<View style={ws(globalStylesMethod(global.theme).centeredBlock)}>
				<AppText color="primary">{_("Meta", "meta game settings")}</AppText>
				<ListSwitch
					title={_("Beginner friendly")}
					isEnabled={editedGame.beginner_friendly}
					setIsEnabled={(beginner_friendly) => updateGame({ beginner_friendly })}
				/>
				<AppText color="hint">
					{_(
						"Signals that your game welcomes people with no prior experience with either tabletop RPGs in general or the set of rules you are using.",
						"explain beginner friendly"
					)}
				</AppText>
				<Divider style={styles.divider} />
				<ListSwitch
					title={_("Allow readers")}
					isEnabled={editedGame.allow_spectators}
					setIsEnabled={(allow_spectators) => updateGame({ allow_spectators })}
				/>
				<AppText color="hint">
					{_(
						"Let people that are not in your game read its story and reward it with coins. They will not have access to the players' out of character chat.",
						"explain allowing readers"
					)}
				</AppText>
				<Divider style={styles.divider} />
				<ListSwitch
					title={_("Allow public chat")}
					isEnabled={editedGame.allow_public_chat}
					setIsEnabled={(allow_public_chat) => updateGame({ allow_public_chat })}
				/>
				<AppText color="hint">
					{_(
						"Let people that are not in your game talk to you and your players through a separated public chat.",
						"explain allowing public chat"
					)}
				</AppText>
				<Divider style={styles.divider} />
				<ListSwitch
					title={_("Allow export")}
					isEnabled={editedGame.allow_export}
					setIsEnabled={(allow_export) => updateGame({ allow_export })}
				/>
				<AppText color="hint">
					{_(
						"Let players in your game export its content to use outside of Role Gate.",
						"explain allowing export"
					)}
				</AppText>
				<Divider style={styles.divider} />
				<ListSwitch
					title={_("Hidden")}
					isEnabled={editedGame.hidden}
					setIsEnabled={(hidden) => updateGame({ hidden })}
				/>
				<AppText color="hint" style={{ marginBottom: 16 }}>
					{_(
						"Your game will only be accessible through direct url. Ideal if you just want to test things out, or for private games.",
						"explain hiding your game"
					)}
				</AppText>
			</View>
		</View>
	);
	ł;
}

export default GameSettingToggles;

const styles = StyleSheet.create({
	divider: {
		marginVertical: 16,
	},
});
