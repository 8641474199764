import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";
import { fetchDice } from "./diceSlice";
import { fetchSymbolInteractions } from "./symbolInteractionsSlice";
import { _ } from "../../i18n/i18n";

export const diceSetsSlice = createSlice({
	name: "diceSets",
	initialState: {
		all: {},
		// [gameId]: []
		users: {
			// [userId]: []
		},
		public: [],
		publicNextUrl: null,
		classic: null,
	},
	reducers: {
		receiveDiceSets: (state, { payload: { gameId, diceSets } }) => {
			// All is an object, where sets are stored by id
			let allSets = state.all;
			state.all = { ...allSets, ...diceSets.rg_toStore() };

			// [gameId] is an array;
			if (gameId) {
				let gameDiceSets = state[gameId] || [];
				gameDiceSets = gameDiceSets.rg_overlapById(diceSets);
				gameDiceSets = gameDiceSets.rg_sortById();
				state[gameId] = gameDiceSets;
			}

			return state;
		},
		receiveUserDiceSets: (state, { payload: { diceSets, replaceAll } }) => {
			if (!diceSets || !diceSets.length) return;
			const userId = diceSets[0].creator.id;
			if (replaceAll) {
				state.users[userId] = diceSets;
			} else {
				const array = state.users[userId] || [];
				state.users[userId] = array.rg_overlapById(diceSets, true);
			}
		},
		receivePublicDiceSets: (state, { payload: { results, count, next } }) => {
			state.public = state.public.rg_overlapById(results, true);
			state.publicNextUrl = next;
		},
		clearPublicDiceSets: (state, action) => {
			state.public = [];
		},
		addDiceSetToGame: (state, { payload: { gameId, diceSet } }) => {
			let gameDiceSets = state[gameId] || [];
			state[gameId] = gameDiceSets.concat(diceSet);
		},
		removeDiceSetFromGame: (state, { payload: { gameId, diceSet } }) => {
			let gameDiceSets = state[gameId] || [];
			state[gameId] = gameDiceSets.rg_removeElementByIdPure(diceSet.id);
		},
		receiveClassicSet: (state, { payload: diceSet }) => {
			state.classic = diceSet;
			state.all[diceSet.id] = diceSet;
		},
	},
});

export const {
	receiveDiceSets,
	receiveUserDiceSets,
	receivePublicDiceSets,
	clearPublicDiceSets,
	addDiceSetToGame,
	removeDiceSetFromGame,
	receiveClassicSet,
} = diceSetsSlice.actions;

export function fetchDiceSets(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/dice-sets`).then(async ({ results }) => {
			dispatch(receiveDiceSets({ diceSets: results, gameId }));
			const ids = results.map((d) => d.id);
			await dispatch(fetchDice(ids));
			dispatch(fetchSymbolInteractions(ids));
		});
	};
}

export function fetchClassicSet() {
	return async (dispatch, getState) => {
		return apiFetch(`dice-sets/classic-set`).then((diceSet) => {
			dispatch(receiveClassicSet(diceSet));
			return diceSet;
		});
	};
}

export function fetchUserSets(userId) {
	return async (dispatch, getState) => {
		return apiFetch(`users/${userId}/dice-sets`, "GET", { "page-size": 1000 }).then(({ results }) => {
			dispatch(receiveUserDiceSets({ diceSets: results, replaceAll: true }));
		});
	};
}

export function fetchPublicDiceSets(params) {
	return async (dispatch, getState) => {
		params = params || {};
		params.public = true;
		await apiFetch(`dice-sets`, "GET", params).then((response) => dispatch(receivePublicDiceSets(response)));
	};
}

export function fetchNextPublicDiceSets(url) {
	if (!url) return () => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receivePublicDiceSets(response));
		});
	};
}

export function saveDiceSet(diceSet) {
	return async (dispatch, getState) => {
		const user = getState().user;
		dispatch(receiveUserDiceSets({ diceSets: [diceSet] }));
		return apiFetch(`users/${user.id}/dice-sets/${diceSet.id}`, "PATCH", {
			name: diceSet.name,
			description: diceSet.description,
			public: diceSet.public,
		});
	};
}

export function createDiceSet() {
	return async (dispatch, getState) => {
		const user = getState().user;
		return apiFetch(`users/${user.id}/dice-sets`, "POST", {
			name: _("New dice set", "default dice set name"),
		}).then((diceSet) => {
			dispatch(receiveUserDiceSets({ diceSets: [diceSet] }));
			return diceSet;
		});
	};
}

export function deleteDiceSet(diceSet) {
	return async (dispatch, getState) => {
		const user = getState().user;
		return apiFetch(`users/${user.id}/dice-sets/${diceSet.id}`, "DELETE").then(() => {
			let diceSets = getState().diceSets.users[user.id];
			diceSets = diceSets.rg_removeElementByIdPure(diceSet.id);
			dispatch(receiveUserDiceSets({ diceSets, replaceAll: true }));
			return diceSet;
		});
	};
}
