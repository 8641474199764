import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import { idKeyExtractor, ws } from "../../tools/generic";
import Token from "./Token";

function CharacterTokens({
	character,
	line,
	horizontalList,
	listProps,
	onVisibleTokensChanged,
	selectedTokenId,
	tokensSize = 18,
	style,
	tokenStyle,
	onTokenPress,
	onTokenLongPress,
	hideEmpty,
	dispatch,
	tokens,
	user,
}) {
	const [visibleTokens, setvisibleTokens] = React.useState([]);

	React.useEffect(() => {
		onVisibleTokensChanged && onVisibleTokensChanged(visibleTokens);
	}, [visibleTokens, onVisibleTokensChanged]);

	React.useEffect(() => {
		const currentLine = line || global.currentLine;
		if (!currentLine || !tokens || !character) return () => null;

		const lineId = currentLine.id;

		setvisibleTokens(
			tokens.filter((t) => {
				if (t.creator_see_only && (!user || t.creator !== user?.id)) return false;
				return (
					t.character === character.id &&
					t.add_line_id <= lineId &&
					(!t.remove_line_id || t.remove_line_id > lineId)
				);
			})
		);
	}, [tokens, line, user, character]);


	if (!character) return null;
	if (!visibleTokens.length && hideEmpty) return null;

	if (!horizontalList) {		
		tokensSize = tokensSize - visibleTokens.length * ws(2, 1);
		tokensSize = Math.max(tokensSize, 6);

		return (
			<View style={[styles.noListContainer, visibleTokens.length > 3 && styles.noListContainerMultiline]}>
				{visibleTokens.map((item) => (
					<View key={item.id}>
						<Token
							style={tokenStyle}
							selected={selectedTokenId === item.id}
							color={item.color}
							shape={item.shape}
							size={tokensSize}
							title={item.content}
							onPress={onTokenPress ? () => onTokenPress(item) : null}
							onLongPress={onTokenLongPress ? () => onTokenLongPress(item) : null}
						/>
						{item.creator_see_only && (
							<MaterialCommunityIcons
								name="eye-off"
								size={12}
								style={styles.overlayIcon}
								color={global.colors.attention}
							/>
						)}
					</View>
				))}
			</View>
		);
	}

	return (
		<FlatList
			numColumns={2}
			style={[style]}
			inverted={true}
			inverted={false}
			numColumns={undefined} // never set numColumns to nul
			horizontal={true}
			data={visibleTokens}
			keyExtractor={idKeyExtractor}
			renderItem={({ item }) => (
				<View>
					<Token
						style={tokenStyle}
						selected={selectedTokenId === item.id}
						color={item.color}
						shape={item.shape}
						size={tokensSize}
						title={item.content}
						onPress={onTokenPress ? () => onTokenPress(item) : null}
						onLongPress={onTokenLongPress ? () => onTokenLongPress(item) : null}
					/>
					{item.creator_see_only && (
						<MaterialCommunityIcons
							name="eye-off"
							size={12}
							style={styles.overlayIcon}
							color={global.colors.attention}
						/>
					)}
				</View>
			)}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.character?.game;
	return {
		tokens: state.tokens[gameId] || Array.rg_empty,
		user: state.user,
	};
};

export default connect(mapStateToProps)(CharacterTokens);

const styles = StyleSheet.create({
	overlayIcon: {
		position: "absolute",
		bottom: 0,
		right: 0,
	},
	noListContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		flexWrap: "wrap-reverse",
		flexDirection: "row",
		justifyContent: "center",
		bottom: -8,
	},
	noListContainerMultiline: {
		
		flexWrap: "wrap",
		bottom: -16,
		marginHorizontal: -2
		
	},
});
