import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { removeNode } from "../../tools/sheets";
import BoxModal from "../generic/modal/BoxModal";
import ModalScreen from "../generic/modal/ModalScreen";
import { editNode } from "../../store/slices/sheetNodesSlice";

function SheetNodeOptionsModal({ route, navigation }) {
	const { node, parent } = route.params;

	const confirmDeleteModalRef = React.useRef();
	const dispatch = useDispatch();
	const options = [];

	node.type === "number_input" &&
		node.show_label &&
		options.push({
			title: _("Hide label", "hide character sheet input label"),
			info: _("NB: Hidden labels are still available in Roll+!"),
			icon: { type: MaterialCommunityIcons, name: "label-off" },
			onPress: () => {
				dispatch(editNode({ ...node, show_label: false }));
				navigation.goBack();
			},
		});

	node.type === "number_input" &&
		!node.show_label &&
		options.push({
			title: _("Show label", "show character sheet input label"),
			info: _("NB: Hidden labels are still available in Roll+!"),
			icon: { type: MaterialCommunityIcons, name: "label" },
			onPress: () => {
				dispatch(editNode({ ...node, show_label: true }));
				navigation.goBack();
			},
		});
	node.type === "section" && !node.user_list &&
		options.push({
			title: _("Make a dynamic list", "create a list in the character sheet template"),
			info: _(
				"Gives players a button to grow this list (by duplicating the first item)",
				"create a list in the character sheet template"
			),
			icon: { type: MaterialIcons, name: "list" },
			onPress: () => {
				dispatch(editNode({ ...node, user_list: true }));
				navigation.goBack();
			},
		});
	node.user_list &&
		options.push({
			title: _("Deactivate list", "undo creation of a list in the character sheet template"),
			info: _(
				"Players won't be able to grow this as a list anymore",
				"undo creation of a list in the character sheet template"
			),
			icon: { type: MaterialIcons, name: "list" },
			subicon: { type: MaterialIcons, name: "undo" },
			onPress: () => {
				dispatch(editNode({ ...node, user_list: false }));
				navigation.goBack();
			},
		});
	!node.items?.length &&
		options.push({
			title: _("Replace", "replace character sheet item with another one"),
			info: _("Replace this item with another", "replace character sheet item with another one"),
			icon: { type: MaterialIcons, name: "swap-horiz" },
			onPress: () => {
				navigation.navigate({
					name: "AddSheetNodeModal",
					params: { parent, nodeToReplace: node },
				});
			},
		});
	options.push({
		title: _("Delete"),
		info: _("Remove this item", "remove a character sheet section"),
		danger: true,
		icon: { type: MaterialIcons, name: "delete" },
		onPress: () => confirmDeleteModalRef.current.show(),
	});

	return (
		<ModalScreen title={_("More options", "more options to edit sheet section")} options={options}>
			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete item")}
				message={_("Are you sure you want to delete this item? This action cannot be undone.")}
				isDelete
				onConfirm={() => {
					removeNode(parent, node, dispatch);
					navigation.goBack();
				}}
			/>
		</ModalScreen>
	);
}

export default SheetNodeOptionsModal;
