import * as React from "react";
import { View, StyleSheet, FlatList, Dimensions } from "react-native";
import { connect, useDispatch } from "react-redux";
import { isGM } from "../../tools/games";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { idEqual } from "../../tools/generic";
import AppText from "../generic/AppText";
import RGIcon from "../RGIcon";
import { _ } from "../../i18n/i18n";
import { getCharName, idToCharacter } from "../../tools/characters";
import AppButton from "../generic/buttons/AppButton";
import moment from "moment";
import { apiFetch } from "../../network/apiFetch";
import { removeRollRequest } from "../../store/slices/rollRequestsSlice";
import { MaterialIcons } from "@expo/vector-icons";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { sendRoll } from "../../tools/diceRoll";
import { colors } from "../../styles/colors";

function PendingRequest({ currentCharacter, request, inList, partyId }) {
	const [countdown, setcountdown] = React.useState();
	const [acting, setacting] = React.useState();

	const forCurrentCharacter = request.targets.some((c) => idEqual(c, currentCharacter));

	const dispatch = useDispatch();

	React.useEffect(() => {
		const countdownInterval = setInterval(updateCoundown, 1000);

		return () => {
			clearInterval(countdownInterval);
		};
	}, []);

	const updateCoundown = React.useCallback(() => {
		if (moment(request.deadline).isBefore(moment())) {
			if (acting) return;

			setacting(true);
			dispatch(removeRollRequest(request));
			apiFetch(`games/${request.game}/roll-requests/${request.id}/execute`, "POST");

			return;
		}

		const currentCountdown = moment(request.deadline).countdown();

		const minutes = currentCountdown.minutes < 10 ? "0" + currentCountdown.minutes : currentCountdown.minutes;
		const seconds = currentCountdown.seconds < 10 ? "0" + currentCountdown.seconds : currentCountdown.seconds;

		let cdString = `${currentCountdown.hours}:${minutes}:${seconds}`;

		if(currentCountdown.days > 0){
			cdString = `${currentCountdown.days}${_("d", "symbol to represent days on a timer")} ${cdString}`;
		}

		setcountdown(cdString);
	}, [request]);

	const executeRequest = React.useCallback(async () => {
		setacting(true);
		await dispatch(fetchSheetValuePairsIfNeeded(currentCharacter?.id));

		const sheet_value_targets = request.sheet_value_targets ? JSON.parse(request.sheet_value_targets) : [];

		for (let i = 0; i < sheet_value_targets.length; ++i) {
			let target = sheet_value_targets[i];
			const character = idToCharacter(target.character);
			await dispatch(fetchSheetValuePairsIfNeeded(character.sheet));
		}

		await sendRoll(
			request.game,
			partyId,
			request.command,
			request.name,
			sheet_value_targets,
			currentCharacter,
			false,
			null,
			dispatch
		);

		await apiFetch(`games/${request.game}/roll-requests/${request.id}/remove`, "POST", {
			character: currentCharacter.id,
		});

		dispatch(removeRollRequest(request));
	}, [request, partyId, currentCharacter]);

	const text = request.command.replace(/(\[\[)|(]])/g, "");

	const request_targets = request.targets.reduce((acc, cur, idx, src) => {
		if (idx > 0 && idx == src.length - 1) acc += " & ";
		else if (idx > 0) acc += ", ";
		acc += getCharName(cur);
		return acc;
	}, "");

	const gm = isGM();

	const window = Dimensions.get("window");
	const totalMargin = styles.requestContainer.marginHorizontal * 2;

	const cancelRequest = React.useCallback(() => {
		setacting(true);
		apiFetch(`games/${request.game}/roll-requests/${request.id}`, "DELETE");
		dispatch(removeRollRequest(request));
	}, [request]);

	return (
		<View style={[styles.requestContainer, { width: window.width - totalMargin - (inList ? 16 : 0) }]}>
			<View style={{ flexDirection: "row", marginBottom: 8, justifyContent: "space-between" }}>
				<AppText bold>{_("Roll request")}</AppText>
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<MaterialIcons name="timer" size={15} color={global.colors.textDefault} />
					<AppText>{countdown}</AppText>
				</View>
			</View>

			<View style={styles.requestContent}>
				<View style={styles.requestIcon}>
					<RGIcon name="die" size={32} color={global.colors.textDefault}/>
				</View>
				<View>
					<AppText hide={!request.name} color="attentionSecondary">
						{request.name}
					</AppText>
					<AppText color="primary">{text}</AppText>
					<AppText color="hint" size="small">
						{_("Requested to %(request_targets)s", "roll request info", { request_targets })}
					</AppText>
				</View>
			</View>
			{gm && !forCurrentCharacter && <AppButton title={_("Cancel")} onPress={cancelRequest} disabled={acting} />}
			{forCurrentCharacter && (
				<AppButton
					title={_("Roll", "roll a pending roll request")}
					onPress={executeRequest}
					disabled={acting}
				/>
			)}
		</View>
	);
}

function PendingRequests({ rollRequests, currentCharacter, partyId }) {
	const visibleRequests = isGM()
		? rollRequests
		: rollRequests.filter((rq) => rq.targets.some((c) => idEqual(c, currentCharacter)));

	if (!visibleRequests.length) return null;

	if (visibleRequests.length === 1) {
		return <PendingRequest request={visibleRequests[0]} currentCharacter={currentCharacter} partyId={partyId} />;
	}

	const window = Dimensions.get("window");
	const totalMargin = styles.requestContainer.marginHorizontal * 2;
	const requestWidth = window.width - totalMargin - 16;

	return (
		<FlatList
			data={visibleRequests}
			keyExtractor={(item) => String(item.id)}
			renderItem={({ item }) => (
				<PendingRequest request={item} inList currentCharacter={currentCharacter} partyId={partyId} />
			)}
			horizontal
			snapToAlignment="start"
			snapToInterval={requestWidth}
			disableIntervalMomentum
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		rollRequests: state.rollRequests[ownProps.gameId] || Array.rg_empty,
		currentCharacter: getCurrentCharacter(state),
		partyId: state.parties[ownProps.gameId]?.current?.id,
	};
};

export default connect(mapStateToProps)(PendingRequests);

const styles = StyleSheet.create({
	container: {},
	requestContainer: {
		padding: 8,
		borderWidth: 1,
		borderRadius: 6,
		marginVertical: 8,
		marginHorizontal: 4,
	},
	requestContent: {
		flexDirection: "row",
		flex: 1,
	},
	requestIcon: {
		alignItems: "center",
		justifyContent: "center",
		marginRight: 8,
	},
});
