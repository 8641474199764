import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "./AppText";

function TwoLinesHeader({ title, subtitle }) {
	return (
		<View>
			<AppText>{title}</AppText>
			{!!subtitle && typeof subtitle === "string" ? <AppText size="small">{subtitle}</AppText> : subtitle}
		</View>
	);
}

export default TwoLinesHeader;


