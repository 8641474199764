import * as React from "react";
import { View, StyleSheet } from "react-native";
import ListButton from "../../generic/buttons/ListButton";
import { _ } from "../../../i18n/i18n";
import { MaterialIcons } from "@expo/vector-icons";
import BoxModal from "../../generic/modal/BoxModal";
import AppText from "../../generic/AppText";
import ListSwitch from "../../generic/ListSwitch";
import { apiFetch } from "../../../network/apiFetch";
import { connect } from "react-redux";
import { useNavigation } from "@react-navigation/native";

function ExportGameOption({ gameId, disabled, style, dispatch, user }) {
	const [exportStory, setexportStory] = React.useState(true);
	const [exportChat, setexportChat] = React.useState(false);
	const [canExport, setcanExport] = React.useState(false);
	const [requestSent, setrequestSent] = React.useState(false);
	const exportSettingsModal = React.useRef();

	const navigation = useNavigation();

	const queueExport = React.useCallback(() => {
		apiFetch(`games/${gameId}/exportgamerequest`, "POST", {
			export_ooc: exportChat,
			export_ic: exportStory,
			requester: user.id,
			game: gameId,
		}).then(() => {
			setcanExport(false);
			setrequestSent(true);
		});
	}, [exportChat, exportStory, user.id, gameId]);

	React.useEffect(() => {
		apiFetch(`games/${gameId}/exportgamerequest/can-export`).then(setcanExport);
	}, []);

	const firstScreen = (
		<>
			<AppText color="secondary" style={{ marginBottom: 8 }}>
				{_("Please set you export options.")}
			</AppText>
			<ListSwitch title={_("Story messages")} isEnabled={exportStory} setIsEnabled={setexportStory} />
			<ListSwitch title={_("Chat messages")} isEnabled={exportChat} setIsEnabled={setexportChat} />
			<AppText hide={!exportChat} color="danger">
				{_(
					"A consent request will be sent to all players in this game. The export will start once every player has accepted it. A new request is sent for each new export."
				)}
			</AppText>
		</>
	);

	const confirmScreen = (
		<>
			<AppText color="hint">
				{_(
					"Exporting a game takes some time. You will receive an email notification once your export is ready to download."
				)}
			</AppText>
		</>
	);

	return (
		<>
			<ListButton
				style={style}
				title={_("Export game")}
				requiredLevel={2}
				iconLeft={{ type: MaterialIcons, name: "file-download" }}
				noBorder
				onPress={() => exportSettingsModal.current.show()}
				disabled={disabled || !canExport}
				navigation={navigation}
				subtitle={
					!canExport
						? _("Maximum one export request per game per day")
						: disabled
						? _("Export is disabled for this game")
						: null
				}
			/>

			<BoxModal
				title={requestSent ?_("Export in progress") : _("Export game")}
				ref={exportSettingsModal}
				options={
					requestSent
						? [{title: _("Got it!", "close info box")}]
						: [
								{
									title: _("Start export"),
									disabled: !exportStory && !exportChat,
									onPress: queueExport,
								},
						  ]
				}
				addCancel={!requestSent}
				keepOpen={!requestSent}
			>
				{requestSent ? confirmScreen : firstScreen}
			</BoxModal>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(ExportGameOption);

const styles = StyleSheet.create({
	container: {},
});
