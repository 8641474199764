import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { idKeyExtractor } from "../../tools/generic";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import { setLanguage } from "../../store/slices/gamesUISlice";
import SearchBar from "../generic/Searchbar";
import { Divider } from "react-native-paper";
import { apiFetch } from "../../network/apiFetch";
import { receiveLines } from "../../store/slices/linesSlice";

function LanguageSelectionScreen({ navigation, route, dispatch, gameId, languages, currentCharacter }) {
	const [search, setSearch] = React.useState("");

	const { backToScreen, editingLine } = route.params || {};

	const selectLanguage = React.useCallback(
		(language) => {
			if (!editingLine) {
				dispatch(setLanguage({ gameId, value: language }));
			}

			if (editingLine) {
				apiFetch(`games/${editingLine.game}/lines/${editingLine.id}`, "PATCH", {
					language: language?.id || null,
				}).then((newLine) => {
					dispatch(receiveLines({ lines: [newLine], gameId: editingLine.game }));
				});
			}

			if (backToScreen) {
				navigation.navigate(backToScreen);
			} else {
				navigation.goBack();
			}
		},
		[gameId, editingLine]
	);

	let languageData = languages.filter((l) => l.name.toLowerCase().includes(search.toLowerCase()));

	return (
		<View style={styles.container}>
			<View style={{ margin: 4 }}>
				<SearchBar onSearchUpdated={setSearch} />
			</View>

			<FlatList
				data={languageData}
				keyExtractor={idKeyExtractor}
				initialNumToRender={50}
				ListHeaderComponent={() =>
					search ? null : (
						<>
							<AppText style={{ padding: 8 }} onPress={() => selectLanguage(null)}>
								{_("None", "default language")}
							</AppText>
							<Divider />
						</>
					)
				}
				renderItem={({ item }) => (
					<AppText style={{ padding: 8 }} onPress={() => selectLanguage(item)}>
						{item.name}
					</AppText>
				)}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		gameId,
		languages: state.languages[gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(LanguageSelectionScreen);

const styles = StyleSheet.create({
	container: {
		paddingTop: 8,
	},
});
