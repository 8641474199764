import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const exportGameRequestsSlice = createSlice({
	name: "exportGameRequests",
	initialState: {},
	reducers: {
		// request can sometime be empty, so gameId must be sent along
		receiveExportGameRequests: (state, { payload: { gameId, requests } }) => {
			state[gameId] = requests;
			return state;
		},
		removeExportGameRequest: (state, { payload: request }) => {
			state[request.game] = state[request.game].rg_removeElementByIdPure(request.id);
			return state;
		},
	},
});

export const { receiveExportGameRequests, removeExportGameRequest } = exportGameRequestsSlice.actions;

export function fetchExportGameRequests(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/exportgamerequest`).then(({ results }) => {
			let requests = results || [];

			const userId = getState().user.id;

			requests = requests.filter((r) => r.pending_consent_users.includes(userId));

			dispatch(receiveExportGameRequests({ requests, gameId }));
		});
	};
}
