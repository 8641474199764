import * as React from "react";
import { View, StyleSheet } from "react-native";
import ModalScreen from "../generic/modal/ModalScreen";
import { _ } from "../../i18n/i18n";
import ListSwitch from "../generic/ListSwitch";

function SearchGamesOptionsModal({ route, navigation }) {
	const { previousScreenName } = route.params;

	const [freeSlots, setfreeSlots] = React.useState(route.params.freeSlots);
	const [publicOnly, setpublicOnly] = React.useState(route.params.publicOnly);
	const [beginnerFriendly, setbeginnerFriendly] = React.useState(route.params.beginnerFriendly);

	return (
		<ModalScreen
			title={_("Search options")}
			goBackScreen={previousScreenName}
			goBackParams={{ freeSlots, publicOnly, beginnerFriendly }}
		>
			<ListSwitch title={_("Free slots only")} isEnabled={freeSlots} setIsEnabled={setfreeSlots} />
			<ListSwitch title={_("Public only")} isEnabled={publicOnly} setIsEnabled={setpublicOnly} />
			<ListSwitch
				title={_("Beginner friendly only")}
				isEnabled={beginnerFriendly}
				setIsEnabled={setbeginnerFriendly}
			/>
		</ModalScreen>
	);
}

export default SearchGamesOptionsModal;


