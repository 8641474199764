import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { clearGameTags, fetchGameTags, fetchNextGameTags } from "../../store/slices/gameTagsSlice";
import { isWeb } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import ModalScreen from "../generic/modal/ModalScreen";
import TagSelector from "../tags/TagSelector";

function SelectGameTagsScreen({ route, navigation, dispatch, tags }) {
	const { activeTags: activeTagsReceived, sendToScreen } = route.params;

	const [nextURL, setnextURL] = React.useState(null);
	const [activeTags, setactiveTags] = React.useState(activeTagsReceived);

	React.useEffect(() => {
		updateSearch();
	}, []);

	const fetchNext = React.useCallback(async () => {
		if (!nextURL) return null;
		const next = await dispatch(fetchNextGameTags(nextURL));
		setnextURL(next);
	}, [nextURL]);

	const updateSearch = React.useCallback(async (searchTearm) => {
		await dispatch(clearGameTags());
		const params = searchTearm ? { search: searchTearm } : null;
		const next = await dispatch(fetchGameTags(params));
		setnextURL(next);
	}, []);
	

	const selector = (
		<TagSelector
			searchOnly
			tags={tags}
			activeTags={activeTags}
			onEndReached={fetchNext}
			onSearchUpdated={(search) => updateSearch(search)}
			onTagAdded={(tag) => setactiveTags(activeTags.rg_pushUniquePure(tag, (t) => t.name === tag.name))}
			onTagRemoved={(tag) => setactiveTags(activeTags.rg_removeElementPure((t) => t.name === tag.name))}
		/>
	);

	if (isWeb()) {
		return (
			<ModalScreen wide>
				{selector}
				<ButtonFooter
					narrow
					buttonProps={[
						{ title: _("Cancel"), onPress: () => navigation.goBack() },
						{
							title: _("Done"),
							onPress: () =>
								navigation.navigate(sendToScreen, { selectedTags: activeTags.map((t) => t.name) }),
						},
					]}
				/>
			</ModalScreen>
		);
	}

	return (
		<>
			<AppScreenView borderless borderTop>
				{selector}
			</AppScreenView>
			<ButtonFooter
				buttonProps={[
					{ title: _("Cancel"), onPress: () => navigation.goBack() },
					{
						title: _("Done"),
						onPress: () =>
							navigation.navigate(sendToScreen, { selectedTags: activeTags.map((t) => t.name) }),
					},
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		tags: state.gameTags,
	};
};

export default connect(mapStateToProps)(SelectGameTagsScreen);


