import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";

export const sheetsSlice = createSlice({
	name: "sheets",
	initialState: {
		// [sheetid] : {},
		users: {
			// [userId]: []
		},
		public: [],
		publicNextUrl: null,
	},
	reducers: {
		receiveSheets: (state, { payload: sheets }) => {
			// There is a bug where modifying state.users[##] directly won't properly update the store.
			// So we must copy the value, edit the copy, and send it back.
			const users = { ...state.users };
			for (let i = 0; i < sheets.length; i++) {
				const sheet = sheets[i];
				if (sheet.saved_on_profile) {
					const userSheets = users[sheet.saved_on_profile.id] || [];
					users[sheet.saved_on_profile.id] = userSheets.rg_replaceById(sheet.id, sheet, true, true);
				}
			}
			return { ...state, ...sheets.rg_toStore(), users };
		},
		receiveUserSheets: (state, { payload: { userId, sheets } }) => ({
			...state,
			...sheets.rg_toStore(),
			users: {
				...state.users,
				[userId]: sheets,
			},
		}),
		receivePublicSheets: (state, { payload: { results, count, next } }) => {
			state.public = state.public.rg_overlapById(results, true);
			state.publicNextUrl = next;
			state = { ...state, ...results.rg_toStore() };
		},
		clearPublicSheets: (state, action) => {
			state.public = [];
		},
		removeSheet: (state, { payload: sheet }) => {
			delete state[sheet.id];
			state.public = state.public.rg_removeElementByIdPure(sheet.id);
			if (sheet.saved_on_profile) {
				const userId = sheet.saved_on_profile.id;
				state.users[userId] = state.users[userId]?.rg_removeElementByIdPure(sheet.id);
			}
		},
	},
});

export const sheetUISlice = createSlice({
	name: "sheetUI",
	initialState: { selectedNodeId: null, dirty: {} },
	reducers: {
		setSelectedNodeId: (state, { payload: nodeId }) => {
			state.selectedNodeId = nodeId;
		},
		setNodeDirty: (state, { payload: { nodeId, value } }) => {
			state.dirty[nodeId] = value;
		},
	},
});

export const {
	receiveSheets,
	receiveUserSheets,
	receivePublicSheets,
	clearPublicSheets,
	removeSheet,
} = sheetsSlice.actions;
export const { setSelectedNodeId, setNodeDirty } = sheetUISlice.actions;

export function fetchGameSheets(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/sheets`).then((response) => {
			dispatch(receiveSheets(response));
		});
	};
}

export function fetchSheet(sheetId) {
	return async (dispatch, getState) => {
		return apiFetch(`sheets/${sheetId}`)
			.then((response) => {
				if (response) {
					return dispatch(receiveSheets([response]));
				} else {
					return false;
				}
			})
			.catch(() => false);
	};
}

export function fetchPlayerSheets(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/sheets`, "GET", { npc: false }).then((response) => {
			dispatch(receiveSheets(response));
		});
	};
}

export function fetchPublicSheets(params = {}) {
	params.public = true;
	return async (dispatch, getState) => {
		return apiFetch(`sheets`, "GET", params).then((response) => dispatch(receivePublicSheets(response)));
	};
}

export function fetchUserSheets(userId) {
	return async (dispatch, getState) => {
		return apiFetch(`profiles/${userId}/sheet-templates`).then(({ results }) => {
			dispatch(receiveUserSheets({ userId, sheets: results }));
		});
	};
}

export function fetchNextPublicSheets(url) {
	if (!url) return () => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receivePublicSheets(response));
		});
	};
}

export function createCharacterSheet(savedOnProfileId) {
	return async (dispatch, getState) => {
		return apiFetch(`sheets`, "POST", { default: true, saved_on_profile: savedOnProfileId }).then((newSheet) => {
			if (savedOnProfileId) {
				const sheets = getState().sheets.users[savedOnProfileId] || [];
				dispatch(receiveUserSheets({ userId: savedOnProfileId, sheets: sheets.concat(newSheet) }));
			} else {
				dispatch(receiveSheets([newSheet]));
			}
			return newSheet;
		});
	};
}

export default sheetsSlice.reducer;
