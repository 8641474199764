import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from 'react-redux';
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchSubscriptionInfo } from "../../store/slices/subscriptionSlice";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";

function CancelSubscriptionModal({ reference, dispatch, userId }) {
	const cancelSub = React.useCallback(() => {
		apiFetch(`users/${userId}/cancel-subscription`, "POST").then(() => {
			dispatch(fetchSubscriptionInfo());
		});
	}, [userId]);

	return (
		<BoxModal
			title={_("Cancel subscription")}
			ref={reference}
			message={
				<>
					<AppText>{_("Do you really want to cancel your subscription?")}</AppText>
					<AppText style={{ marginTop: 8 }} color="hint">
						{_(
							"You will maintain your current subscription level until the end of the paid period, but you will have to pay for a full subscription if you subscribe again before that."
						)}
					</AppText>
				</>
			}
			options={[
				{ title: _("Nevermind", "cancel subscription cancelling"), transparent: true },
				{ title: _("Yes, cancel subscription"), danger: true, onPress: cancelSub },
			]}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(CancelSubscriptionModal);


