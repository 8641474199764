import { FontAwesome } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colors } from "../../styles/colors";
import AppText from "../generic/AppText";
import CondView from "../meta/CondView";
import Token from "../tokens/Token";

import { BuildStyleMethod } from "../../styles/theming";

function DieIcon({ die, onPress, negative, highlighted, onLongPress, delayLongPress, activeOpacity, disabled, usedInText }) {
	const styles = stylesMethod(global.theme);
	const containerStyle = [styles.container];
	highlighted && containerStyle.push(styles.highlighted);
	disabled && containerStyle.push(styles.disabled);

	const ContainerTag = onPress ? TouchableOpacity : View;

	return (
		<ContainerTag
			style={containerStyle}
			disabled={disabled}
			onPress={() => onPress && onPress(die)}
			onLongPress={onLongPress ? () => onLongPress(die) : null}
			delayLongPress={delayLongPress}
			activeOpacity={activeOpacity}
		>
			<Token shape={die.icon} color={die.color} usedInText={usedInText} />
			<AppText
				style={styles.name}
				numberOfLines={2}
				ellipsizeMode="tail"
				color={highlighted ? "textLight" : null}
			>
				{die.name}
			</AppText>

			<CondView show={negative} style={styles.negativeIcon}>
				<FontAwesome name="minus" color={global.colors.failure} size={32} />
			</CondView>
		</ContainerTag>
	);
}

export default DieIcon;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		alignItems: "center",
		padding: 4,
	},
	name: {
		width: 60,
		textAlign: "center",
	},
	negativeIcon: {
		position: "absolute",
		bottom: 16,
		left: 4,
	},
	highlighted: {
		backgroundColor: colors.primary,
	},
	disabled: {
		opacity: 0.2,
	},
}));
