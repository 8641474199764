import * as React from "react";
import { ScrollView } from "react-native";
import { View } from "react-native";

// A less performant Flastlist based on a ScrollView
// Allow some features that do not exists on Flatlist, like wrapping items
function AppFlatList({
	style,
	contentContainerStyle,
	data,
	renderItem,
	onEndReached,
	endThreshold = 0.9,
	keyboardShouldPersistTaps = "handled",
	scrollEnabled = true,
	ListEmptyComponent,
}) {
	const [endReached, setendReached] = React.useState(false);

	
	let content = data?.length ? data.map((item, index) => renderItem({ item, index })) : (ListEmptyComponent || null);

	const ContainerTag = scrollEnabled ? ScrollView : View;

	return (
		<ContainerTag
			style={style}
			keyboardShouldPersistTaps={keyboardShouldPersistTaps}
			contentContainerStyle={contentContainerStyle}
			onScroll={({ nativeEvent }) => {
				const { contentOffset, contentSize, layoutMeasurement } = nativeEvent;
				const bottomPosition = layoutMeasurement.height + contentOffset.y;
				if (bottomPosition > contentSize.height * endThreshold) {
					if (!endReached) {
						setendReached(true);
						onEndReached && onEndReached();
					}
				} else if (endReached) {
					setendReached(false);
				}
			}}
			scrollEnabled={scrollEnabled}
		>
			{content}
		</ContainerTag>
	);
}

export default AppFlatList;
