import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _, _n } from "../../i18n/i18n";
import { deleteDie } from "../../store/slices/diceSlice";
import { colors } from "../../styles/colors";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";
import RGTokenIcon from "../RGTokenIcon";

function DieTemplateItem({ die, onPress, dispatch, user }) {
	const confirmDeleteModalRef = React.useRef();

	const isMine = die.creator === user?.id;

	const options = [];

	isMine &&
		options.push({
			title: _("Delete"),
			danger: true,
			onPress: () => confirmDeleteModalRef.current.show(),
		});

	const onDelete = React.useCallback(() => {
		dispatch(deleteDie(die));
	}, [die]);

	return (
		<>
			<ListButton
				options={options}
				iconLeft={{ type: RGTokenIcon, name: die.icon, color: global.colors.userColors[die.color] }}
				title={die.name}
				subtitle={_n("%(number)s face", "%(number)s faces", "number of faces on a die", die.faces.length, {
					number: die.faces.length,
				})}
				onPress={onPress}
			/>

			{isMine && (
				<BoxModal
					ref={confirmDeleteModalRef}
					title={_("Delete die")}
					message={_("Are you sure you want to delete this die? This action cannot be reverted.")}
					onConfirm={onDelete}
					isDelete
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(DieTemplateItem);


