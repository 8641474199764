import * as React from "react";
import { View } from "react-native";
import { StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { _, _n } from "../../../i18n/i18n";
import { colors } from "../../../styles/colors";
import AppActivityIndicator from "../../generic/AppActivityIndicator";
import AppText from "../../generic/AppText";
import Popover from "../../generic/popover/Popover";
import PopoverMessage from "../../generic/popover/PopoverMessage";

import { BuildStyleMethod } from "../../../styles/theming";

function ShowResultsButton({ popoverRef, route, navigation, searching, dispatch, gamesFound }) {
	const styles = stylesMethod(global.theme);
	return (
		<View style={styles.container}>
			<Popover
				ref={popoverRef}
				popoverComponent={
					<PopoverMessage
						title={_("Skip the rest", "info to skip the rest of the join game wizard")}
						message={_("Click if you do not wish to filter your search further.", "join game wizard info")}
					/>
				}
				closeOnTap={false}
				arrowPosition="bottom"
				displacementY={-28}
			>
				<TouchableOpacity
					style={styles.button}
					onPress={() => navigation.navigate("BrowseGamesScreen", { ...route.params, fromWizard: true })}
				>
					<AppText bold size="large" color="attention" style={searching ? { opacity: 0 } : null}>
						{gamesFound || "0"}
					</AppText>
					<AppActivityIndicator show={searching} style={{ position: "absolute", top: 12 }} />
					<AppText>
						{_n(
							"open game looking for players",
							"open games looking for players",
							"indicates that there is/are open game(s)",
							gamesFound
						)}
					</AppText>
				</TouchableOpacity>
			</Popover>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		gamesFound: state.games.browsedTotal,
	};
};

export default connect(mapStateToProps)(ShowResultsButton);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		alignItems: "center",
	},
	button: {
		backgroundColor: colors.buttonBackgroundColor,
		alignItems: "center",
		justifyContent: "center",
		borderColor: colors.borderDefault,
		borderWidth: 1,
        paddingHorizontal: 32,
        paddingVertical: 8,
        marginTop: 32,
        borderRadius: 8,
	},
}));
