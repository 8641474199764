import { createSlice } from "@reduxjs/toolkit";

export const savedStoreSlice = createSlice({
	name: "savedStore",

	initialState: {
		// adding the initial states of the normal store as it becomes needed
		version: global.savedStoreVersion,
		storyMarkers: {},
		gameSamples: {},
		games: {
			current: null,
			byTagName: {},
			userGames: [],
			bookmarked: [],
			browsed: [],
			featured: [],
		},
		characters: {},
		charactersByGame: {},
		bookmarkForGames: {}
	},
	reducers: {
		receiveSavedStore: (state, { payload }) => payload,
	},
});

export const { receiveSavedStore } = savedStoreSlice.actions;
