import * as React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import StageBackgroundImage from "./StageBackgroundImage";
import CoinIcon from "../coins/CoinIcon";
import CondView from "../meta/CondView";

function StageBackgroundItem({ background, onSelected }) {
	const onPress = () => {
		onSelected && onSelected(background);
	};

	return (
		<TouchableOpacity onPress={onPress} activeOpacity={0.8}>
			<View>
				<StageBackgroundImage
					name={background.name}
					containerStyle={{ marginBottom: 16, height: 136, overflow: "hidden" }}
					height={206}
				/>
				<CondView show={background.rank > 0} style={[styles.coin]}>
					<CoinIcon level={background.rank} size={16} />
				</CondView>
			</View>
		</TouchableOpacity>
	);
}

export default StageBackgroundItem;

const styles = StyleSheet.create({
	coin: {
		position: "absolute",
		right: 16,
		bottom: 32,
	},
});
