import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { arrow } from "../../styles/arrows";
import { lineTalkPadding } from "../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../styles/theming";
import { getCharacterStateThen, getCharName } from "../../tools/characters";
import { enumerate, idEqual, isWeb, ws } from "../../tools/generic";
import { getLineUnderstanding } from "../../tools/languages";
import { findTargets, isOwn, isStory, shouldGroupLines, tagIdToName } from "../../tools/lines";
import { cStyle } from "../../tools/react";
import { getTimestamp } from "../../tools/time";
import CoinValue from "../coins/CoinValue";
import AppText from "../generic/AppText";
import GibberishText from "../languages/GibberishText";
import CondView from "../meta/CondView";
import DiceRollLine from "./DiceRollLine";
import EditingLine from "./EditingLine";
import ImageLine from "./ImageLine";
import LineAvatar from "./LineAvatar";
import LineInfo from "./LineInfo";
import LineTargetsAvatar from "./LineTargetsAvatar";

function Line({
	line,
	prevLine,
	nextLine,
	isPreview,
	onLayout,
	style,
	dispatch,
	author,
	game,
	user,
	users,
	characters,
	currentCharacter,
	editingLine,
}) {
	const styles = stylesMethod(global.theme);

	const [avatarBelowPosition, setavatarBelowPosition] = React.useState({ x: 0 });

	const onAvatarLayout = React.useCallback((e) => {
		const { x, y, width, height } = e.nativeEvent.layout;
		setavatarBelowPosition({ x, y, width, height });
	}, []);

	const navigation = useNavigation();

	const onPress = () => {
		navigation.navigate({ name: "LineOptionsModal", params: { line, isPreview }, merge: true });
	};

	const action = React.useMemo(
		() => (line.action && typeof line.action === "string" ? JSON.parse(line.action) : null),
		[line.action]
	);

	const timeStamp = getTimestamp(line.created);

	const isStorytelling = (line.is_storytelling && !line.author) || (!line.author && !line.user);
	const isNPC = author && author.is_npc;
	const ownsLine = isOwn(line);

	const tone = !isStorytelling && !line.is_description && line.tone && line.tone !== "neutral" ? line.tone : null;

	let targetIds = React.useMemo(
		() => findTargets(line.content, line.is_chat, line.game),
		[line.content, line.is_chat, line.game]
	);

	let targets = React.useMemo(() => {
		if (line.whispered_to?.length) return line.whispered_to.map((id) => characters[id]).filter((c) => c);
		if (line.is_chat) {
			return targetIds.map((id) => users[id]).filter((u) => u);
		} else {
			return targetIds.map((id) => characters[id]).filter((c) => c);
		}
	}, [line.whispered_to, line.is_chat, targetIds, characters, users]);

	const targetCharacterNames = React.useMemo(() => {
		if (line.is_chat) return [];
		return targets.map((c) => getCharName(c, getCharacterStateThen(line, c)?.name));
	}, [targets, line.is_chat]);

	const isWhispered = line.whispered_to?.length;

	const hasSameTargetsAs = React.useCallback(
		(otherLine) => {
			if (!otherLine) return false;
			if (isWhispered && !line.whispered_to.rg_hasSameContent(otherLine.whispered_to)) {
				return false;
			}
			const otherLineTargetIds = findTargets(otherLine.content, otherLine.is_chat, otherLine.game);
			if (!otherLineTargetIds.rg_hasSameContent(targetIds)) {
				return false;
			}
			return true;
		},
		[line, isWhispered, targetIds]
	);

	let groupWithPrevious = React.useMemo(() => {
		const shouldGroup = shouldGroupLines(prevLine, line);
		if (shouldGroup && !prevLine.is_chat && !isStorytelling && !hasSameTargetsAs(prevLine)) {
			return false;
		}

		return shouldGroup;
	}, [isStorytelling, prevLine, line, hasSameTargetsAs]);

	let groupWithNext = React.useMemo(() => {
		const shouldGroup = shouldGroupLines(nextLine, line);

		if (shouldGroup && !nextLine.is_chat && !isStorytelling && !hasSameTargetsAs(nextLine)) {
			return false;
		}

		return shouldGroup;
	}, [isStorytelling, nextLine, line, hasSameTargetsAs]);

	const isTagged = React.useMemo(() => {
		return (
			line.tagged_users.includes(user?.id) ||
			line.tagged_characters?.some((cId) => idEqual(characters[cId]?.player, user))
		);
	}, [user?.id, line.tagged_characters, line.tagged_users]);

	const first = !groupWithPrevious;
	const last = !groupWithNext;

	const showAvatar = !isStorytelling && !(!isStory(line) && ownsLine) && (!targets.length || last);
	const specialTalkToFormat = !line.is_chat && targets.length && !isStorytelling && !line.is_description;
	const showAvatarsBelow = last && specialTalkToFormat;

	const lineStyle = [styles.line];
	groupWithNext && lineStyle.push(styles.lineWithNext);
	isStorytelling && lineStyle.push(styles.lineStoryTelling);
	isStorytelling && first && lineStyle.push(styles.lineStoryTellingStart);
	isStorytelling && last && lineStyle.push(styles.lineStoryTellingEnd);
	ownsLine && lineStyle.push(styles.lineOwned);
	isNPC && lineStyle.push(styles.lineNPC);
	first && last && !isStorytelling && lineStyle.push(styles.lineCharacterSingle);
	first && lineStyle.push(styles.lineFirst);
	isTagged && lineStyle.push(styles.lineTagged);
	specialTalkToFormat && lineStyle.push(styles.lineTalkTo);

	const lineBoxStyle = [styles.lineBox];
	line.is_description && lineBoxStyle.push(styles.lineBoxDescription);
	isStorytelling && lineBoxStyle.push(styles.lineBoxStoryTelling);
	isWhispered && lineBoxStyle.push(styles.lineBoxWhispered);
	if(!!tone) {
		const toneColor = !!line.toneColorOverride ? line.toneColorOverride : global.colors.toneColors[tone];
		lineBoxStyle.push({ borderColor: toneColor, borderWidth: 3 })
	}

	const lineTextStyle = [styles.lineText];
	!isStory(line) && lineTextStyle.push(styles.lineTextChat);
	isStorytelling && lineTextStyle.push(styles.lineTextStoryTelling);
	specialTalkToFormat && lineTextStyle.push(styles.lineTextTalkTo);

	let lineText = React.useMemo(() => {
		if (!line.content) return null;
		const taggedIds = line.tagged_characters.length ? line.tagged_characters : line.tagged_users;

		let content = line.content.replace(/^(@.*?#\d+\s*)*/g, "");
		for (let i = 0; i < taggedIds.length; ++i) {
			let tagId = taggedIds[i];

			let replacement = tagIdToName(tagId, line, users);
			replacement = `**${replacement}**`;

			const regex = new RegExp("@[^#]+?#" + tagId, "g");

			content = content.replace(regex, replacement);
		}
		return content;
	}, [line.content, targetIds]);

	const textColor = line.is_description || isStorytelling || isWhispered ? "textDefault" : "textReversed";

	if (!line.is_description && !line.is_storytelling && !line.is_chat && line.language && currentCharacter) {
		let understanding = getLineUnderstanding(currentCharacter, line);
		lineText = <GibberishText color={textColor} text={lineText} understandingLevel={understanding} />;
	}

	let lineBoxArrowStyle = arrow(ownsLine ? "right" : "left", {
		color: tone ? global.colors.toneColors[tone] : global.colors.boldBorder,
		top: null,
		bottom: 4,
	});

	if (showAvatarsBelow) {
		lineBoxArrowStyle = arrow("bottom", {
			color: tone ? global.colors.toneColors[tone] : global.colors.boldBorder,
			left: avatarBelowPosition.x + 6,
		});

		if (isWeb()) {
			lineBoxArrowStyle.left = avatarBelowPosition.x - lineTalkPadding() + 12;
		}
	}

	if (action) {
		// Maps not currently supported
		if (action.type === "open_map") {
			return (
				<View style={[styles.line, styles.lineBox, styles.noMap, style]} onLayout={onLayout}>
					<AppText color="hint">{_("Unfortunately, maps are no longer supported")}</AppText>
				</View>
			);
		} else if (action.type === "image") {
			return <ImageLine onPress={onPress} line={line} onLayout={onLayout} isPreview={isPreview} style={style} />;
		} else {
			return <DiceRollLine onPress={onPress} line={line} onLayout={onLayout} style={style} />;
		}
	}

	if (isWeb() && editingLine && editingLine?.id === line.id) {
		return <EditingLine line={line} />;
	}

	return (
		<TouchableOpacity
			activeOpacity={0.7}
			onPress={onPress}
			style={[{ overflow: "visible" }, style]}
			onLayout={onLayout}
		>
			<View style={[lineStyle]}>
				<LineAvatar
					show={showAvatar && !showAvatarsBelow}
					avatarStyle={{ position: "absolute" }}
					line={line}
					hideAvatar={!last}
					isPreview={isPreview}
				/>

				<View style={lineTextStyle}>
					<LineInfo line={line} prevLine={prevLine} />

					<View style={lineBoxStyle}>
						<CondView
							show={last && !line.is_description && !line.is_storytelling}
							style={lineBoxArrowStyle}
						/>
						<AppText
							hide={!targets.length || (hasSameTargetsAs(prevLine) && !isStorytelling)}
							style={styles.lineTarget}
							color="textLight"
							size="small"
						>
							{_("to %(targets_list)s", "list characters being talked to", {
								targets_list: line.is_chat
									? enumerate(targets.map((u) => u.username))
									: enumerate(targetCharacterNames),
							})}
						</AppText>
						<AppText
							hide={!line.language || line.is_description || line.is_storytelling}
							color="primary"
							size="small"
						>
							{line.language?.name}
						</AppText>
						<AppText>
							<AppText color={textColor} selectable>
								{lineText}
							</AppText>
							<AppText size="small" style={{ opacity: 0, color: "rgba(0,0,0,0)" }}>
								{"   "}
								{timeStamp}
							</AppText>
						</AppText>
						<AppText color="hint" size="small" style={styles.date}>
							{timeStamp}
						</AppText>
						<CoinValue
							subject={line}
							style={{ marginTop: 4 }}
							subjectGetter={{ line_id: line.id }}
							hideIfNone
							blockGive={ownsLine}
						/>
					</View>
				</View>
			</View>
			<CondView
				show={showAvatarsBelow}
				style={cStyle([styles.underAvatars, [ownsLine, styles.underAvatarsOwned]])}
			>
				<LineAvatar noTokens line={line} hideAvatar={!last} isPreview={isPreview} onLayout={onAvatarLayout} />
				<LineTargetsAvatar targets={targets} isPreview={isPreview} line={line} />
			</CondView>
		</TouchableOpacity>
	);
}
const mapStateToProps = (state, ownProps) => {
	const user = state.user;
	const author = state.characters[ownProps.line.author];
	return {
		user,
		users: state.users,
		author,
		game: state.games[ownProps.line.game],
		characterStates: state.characterStates, // to force refresh when states are updated
		characters: state.characters,
		currentCharacter: getCurrentCharacter(state),
		editingLine: ws(state.gamesUI[ownProps.line.game]?.editingLine, null),
	};
};

export default connect(mapStateToProps)(React.memo(Line));

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		line: {
			flexDirection: "row",
			marginBottom: 8,
			marginHorizontal: ws(0, 8),
			overflow: "visible",
		},
		lineOwned: {
			flexDirection: "row-reverse",
		},
		lineTagged: {
			borderColor: colors.attention,
			backgroundColor: colors.attentionOpacity,
			borderWidth: 1,
			borderRadius: 4,
			padding: 8,
		},
		lineStoryTelling: {
			minHeight: 0,
		},
		lineFirst: {
			marginTop: 12,
		},
		lineStoryTellingStart: {
			marginTop: 12,
		},
		lineStoryTellingEnd: {
			marginBottom: 12,
		},
		lineCharacterSingle: {
			minHeight: 56,
		},
		lineWithNext: {
			marginBottom: 2,
		},
		lineTalkTo: {
			...ws({
				alignItems: "center",
				paddingHorizontal: lineTalkPadding(),
			}),
		},
		lineBackground: {
			opacity: 0.3,
		},
		lineText: {
			flex: 1,
			...ws(
				{
					flex: 0,
					flexGrow: 0,
					flexShrink: 1,
					flexBasis: "auto",
					maxWidth: "90%",
				},
				{
					flexGrow: 1, // Must be precised, because switching from lineTextChat to lineText won't refresh those values
					flexShrink: 1, // Must be precised, because switching from lineTextChat to lineText won't refresh those values
					justifyContent: "flex-end",
				}
			),
		},
		lineTextStoryTelling: {
			...ws({ maxWidth: null }),
		},
		lineTextChat: {
			flex: 0,
			flexGrow: 0,
			flexShrink: 1,
			flexBasis: "auto",
		},
		lineTextTalkTo: {
			...ws({
				maxWidth: null,
				flex: 1,
				flexGrow: 1,
				flexShrink: 1,
			}),
		},
		lineBox: {
			borderColor: colors.lightBorder,
			backgroundColor: colors.talkBackground,
			borderWidth: 1,
			borderRadius: 8,
			padding: 10,
		},
		lineBoxWhispered: {
			borderStyle: "dashed",
			borderColor: colors.secondary,
			backgroundColor: "transparent",
		},
		lineBoxStoryTelling: {
			backgroundColor: colors.storyTellingBackground,
			borderColor: colors.storyTellingBackground,
		},
		lineBoxDescription: {
			backgroundColor: colors.descriptionBackground,
			borderColor: colors.descriptionBackground,
		},
		date: {
			position: "absolute",
			bottom: 4,
			right: 10,
		},
		noMap: {
			borderColor: colors.descriptionBackground,
			backgroundColor: "transparent",
		},
		lineTarget: {
			backgroundColor: colors.secondary,
			marginHorizontal: -10,
			marginTop: -10,
			padding: 4,
			paddingLeft: 8,
			borderTopLeftRadius: 4,
			borderTopRightRadius: 4,
		},
		underAvatars: {
			flexDirection: "row",
			justifyContent: "space-around",
			...ws({ marginBottom: 8 }),
		},
		underAvatarsOwned: {
			flexDirection: "row-reverse",
		},
	})
);
