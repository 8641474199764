import * as React from 'react';
import { StyleSheet } from 'react-native';
import { _ } from '../../../i18n/i18n';
import AppScreenView from '../../AppScreenView';
import AppText from '../../generic/AppText';
function AvatarRequestTermsAndConditions() {

    const titleProps = {
        color: "primary",
        size: "large",
        style: {marginTop: 16}
    }

    return (
        <AppScreenView style={styles.container} scroll>
            <AppText {...titleProps}>{_("Avatar requests - Terms & conditions", "avatar terms and conditions")}</AppText>
            <AppText {...titleProps}>{_("Conditions", "avatar terms and conditions")}</AppText>
            <AppText list>{_("Players with a Legendary subscription can request one Avatar per month.", "avatar terms and conditions")}</AppText>
            <AppText list bold>{_("Requests are not available if you are benefiting from a temporary referral upgrade.", "avatar terms and conditions")}</AppText>
            <AppText list>{_("Permanent upgrades are eligible for an Avatar request.", "avatar terms and conditions")}</AppText>
            <AppText list>{_("You must have an active Legendary subscription to use a Legendary level Avatar, even if it is the result of your own request.", "avatar terms and conditions")}</AppText>
            <AppText {...titleProps}>{_("Deadlines", "avatar terms and conditions")}</AppText>
            <AppText list>{_("Requests are batched together the 1st and 15th of each month.", "avatar terms and conditions")}</AppText>
            <AppText list>{_("The content of a request batch becomes available around the time the following batch is created.", "avatar terms and conditions")}</AppText>
            <AppText {...titleProps}>{_("Feedback and Revisions", "avatar terms and conditions")}</AppText>
            <AppText bold style={styles.paragraph}>{_("Once your request is sent, you will not be able to edit it.", "avatar terms and conditions")}</AppText>
            <AppText style={styles.paragraph}>{_("A few days before it is released, a first version of the Avatar will be unveiled to you. At this point, you will be able to send feedback, if something doesn't quite match what you had in mind.", "avatar terms and conditions")}</AppText>
            <AppText style={styles.paragraph}>{_("We reserve the right to ignore a feedback that we judge unreasonable. A feedback can be judged unreasonable if:", "avatar terms and conditions")}</AppText>
            <AppText list>{_("It doesn't match the original description of the Avatar.", "avatar terms and conditions")}</AppText>
            <AppText list>{_("It contains enough changes to require restarting the design process from scratch.", "avatar terms and conditions")}</AppText>
            <AppText list>{_("It contains demands unrelated to the Avatar being designed.", "avatar terms and conditions")}</AppText>
            <AppText {...titleProps}>{_("Unsatisfactory results", "avatar terms and conditions")}</AppText>
            <AppText style={styles.paragraph}>{_("Our artist and the development team will try their best to satisfy your request. Due to the amount of players on the platform however, we cannot offer additional revisions if you are still unsatisfied after the first one.", "avatar terms and conditions")}</AppText>            
            <AppText style={styles.paragraph}>{_("In the rare case where you would not be happy with your Avatar, a refund of your Legendary subscription will NOT be issued. Avatar requests are only one of the perks of the Legendary subscription and cannot be used to justify a refund.", "avatar terms and conditions")}</AppText>
        </AppScreenView>
    );
}

export default AvatarRequestTermsAndConditions;

const styles = StyleSheet.create({
    paragraph:{
        marginBottom: 16
    }
});