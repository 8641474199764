import * as React from "react";
import SVG from "react-inlinesvg";
import { Platform, TouchableOpacity, View } from "react-native";
import { SvgCss } from "react-native-svg/css";
import { SvgXml } from "react-native-svg";
import { connect } from "react-redux";
import AvatarStrings from "../../avatars/avatarStrings";
import BackgroundStrings from "../../avatars/backgroundStrings";
import { fetchAvatars } from "../../store/slices/avatarsSlice";
import { colors } from "../../styles/colors";
import { pathToAvatarBackground, pathToPortrait } from "../../tools/avatars";
import { getCharName } from "../../tools/characters";
import { isWeb, ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppText from "../generic/AppText";
import CharacterTokens from "../tokens/CharacterTokens";

var tinycolor = require("tinycolor2");

function CharacterAvatar({
	size = 48,
	character,
	color = null,
	bgColor = null,
	hide,
	style,
	opacity = 1,
	onPress,
	noAvatar,
	loadName,
	showName,
	showTokens,
	line,
	dispatch,
	avatarsLoaded,
	avatarName,
	sheets,
}) {
	const [loading, setLoading] = React.useState(!avatarsLoaded);
	color = color || global.colors.userColors[character?.color];
	bgColor = bgColor || global.colors.userColors[character?.background_color];

	const showAvatar = !noAvatar; // just to make it easier to understand in code.

	const previousAvatarsLoaded = usePrevious(avatarsLoaded);

	React.useEffect(() => {
		if (!avatarsLoaded) {
			dispatch(fetchAvatars());
		}
		if (!previousAvatarsLoaded && avatarsLoaded) {
			setLoading(false);
		}
	}, [avatarsLoaded]);

	if (hide) return null;

	const path = pathToPortrait(avatarName);
	const backgroundPath = pathToAvatarBackground(character?.background_rank, character?.background_key);

	const web = Platform.OS === "web";
	const ContainerTag = onPress ? TouchableOpacity : View;

	const backgroundName = `ico_${character?.background_rank}_shield_${character?.background_key}`;
	const avatarString = AvatarStrings[avatarName];
	const backgroundString = BackgroundStrings[backgroundName];

	if (character?.background_key && character?.background_rank != "no" && !backgroundString) {
		console.log(`BACKGROUND ${backgroundName} HAS NO BACKGROUND STRING LOADED`);
	}

	if (!avatarString) {
		console.log(`AVATAR ${avatarName} HAS NO AVATAR STRING LOADED`);
		return null;
	}

	const coloredAvatar = React.useMemo(
		() => colorAvatar(avatarString, color, opacity),
		[avatarString, opacity, color]
	);
	const coloredBackground = React.useMemo(
		() => colorAvatar(backgroundString, bgColor, opacity, 30, 40),
		[backgroundString, bgColor, opacity]
	);

	const name = React.useMemo(() => {
		const longName = getCharName(character);
		if(!longName) return longName;
		return longName.split(" ")[0];
	}, [sheets]);

	return (
		<ContainerTag style={[style]} onPress={onPress}>
			{loadName && ws(true, showName) && (
				<AppText
					style={{
						width: size - 1,
						marginBottom: 2,
						backgroundColor: global.colors.cardBackground,
						borderRadius: 6,
						opacity: showName ? 1 : 0,
						...ws({ transition: "opacity 250ms" }),
					}}
					color="secondary"
					size="small"
					centered
					numberOfLines={1}
					ellipsizeMode="tail"
					className="test"
				>
					{name}
				</AppText>
			)}
			<View style={{ width: size, height: size }}>
				{avatarsLoaded && (bgColor || isWeb()) && backgroundPath && (
					<View style={{ position: "absolute", top: 0, left: 0 }}>
						{isWeb() ? (
							<div
								className={"avatar_background color_" + character.background_color}
								style={{ width: size, height: size, textAlign: "center" }}
							>
								<SVG src={backgroundPath} alt="" uniquifyIDs={false} />
							</div>
						) : (
							<SvgXml width={size} height={size} xml={coloredBackground} />
						)}
					</View>
				)}

				{showAvatar && avatarsLoaded && (color || isWeb()) && (
					<View style={{ position: "absolute", top: 0, left: 0 }}>
						{isWeb() ? (
							<div
								className={"avatar_icon color_" + character?.color}
								style={{ width: size, height: size, textAlign: "center" }}
							>
								<SVG src={path} alt="" uniquifyIDs={false} />
							</div>
						) : (
							<SvgXml width={size} height={size} xml={coloredAvatar} />
						)}
					</View>
				)}

				{/* load the avatar and/or background as a simple image if we don't need to apply a color to it*/}
				{/* SvgCssUri doesn't work on web in DEV, as static files are either served through the server and the uri points to a page, instead of a svg*/}
				{/* or they are server directly as files, and CORS rules won't let the web dev port to access the file*/}
				{!web && !!backgroundPath && !bgColor && <SvgCss width={size} height={size} xml={backgroundString} />}
				{!web && !!path && !color && showAvatar && <SvgCss width={size} height={size} xml={avatarString} />}
			</View>
			{showTokens && (
				<CharacterTokens character={character} line={line} tokensSize={ws(size * 0.45, size * 0.35)} />
			)}
			<AppActivityIndicator
				show={loading}
				color={colors.primary}
				style={{ width: size, height: size, position: "absolute" }}
			/>
		</ContainerTag>
	);
}

const mapStateToProps = (state, ownProps) => {
	const avatars = state.avatars || state.savedStore.avatars;
	const avatar = avatars ? avatars[Number(ownProps.character?.portrait_avatar || -1)] : null;
	const avatarName = ownProps.avatarName || avatar?.file_name || "ico_no_1";
	const sheets = ownProps.loadName ? state.sheets : null;
	return {
		avatarsLoaded: !state.avatars.unloaded,
		avatarName,
		sheets, // trigger refresh when sheets update
	};
};

export default connect(mapStateToProps)(CharacterAvatar);

const colorAvatar = (svgString, color, opacity, darken2 = 15, darken3 = 20) => {
	if (!svgString) return null;
	if (!color) {
		svgString = svgString.replace(/id=["']shade1["']/, `id="shade1" style="opacity:${opacity};"`);
		svgString = svgString.replace(/id=["']shade2["']/, `id="shade2" style="opacity:${opacity};"`);
		svgString = svgString.replace(/id=["']shade3["']/, `id="shade3" style="opacity:${opacity};"`);
		return svgString;
	}

	svgString = svgString.replace(/style=".*?"/g, "");
	const color2 = tinycolor(color).darken(darken2).toString();
	const color3 = tinycolor(color).darken(darken3).toString();

	svgString = svgString.replace(/id=["']shade1["']/, `id="shade1" style="fill:${color}; opacity:${opacity};"`);
	svgString = svgString.replace(/id=["']shade2["']/, `id="shade2" style="fill:${color2}; opacity:${opacity};"`);
	svgString = svgString.replace(/id=["']shade3["']/, `id="shade3" style="fill:${color3}; opacity:${opacity};"`);

	return svgString;
};
