import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { modalStackNavigatorScreenOptions, modalScreenOptions } from "../../tools/navigationOptions";
import SheetNodeOptionsModal from "./SheetNodeOptionsModal";
import AddSheetNodeModal from "./AddSheetNodeModal";

const Stack = createStackNavigator();

function CharacterSheetModalStack(Stack) {
	return (
		<Stack.Group screenOptions={modalStackNavigatorScreenOptions}>
			<Stack.Screen
				name="SheetNodeOptionsModal"
				component={SheetNodeOptionsModal}
				options={modalScreenOptions}
			/>
			<Stack.Screen
				name="AddSheetNodeModal"
				component={AddSheetNodeModal}
				options={modalScreenOptions}
			/>
		</Stack.Group>
	);
}

export default CharacterSheetModalStack;
