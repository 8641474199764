import * as Notifications from "expo-notifications";
import * as React from "react";
import { Dimensions, Keyboard, Platform, UIManager } from "react-native";
import { DarkTheme, Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider as ReduxProvider } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';

import "./config";
import AppContainer from "./src/components/AppContainer";
import AppLoader from "./src/components/AppLoader";
import store from "./src/store/store";
import "./src/tools/array_extensions";
import "./src/tools/moment_extensions";
import "./src/tools/string_extensions";
import "./src/reimports/appInit";

import * as Updates from "expo-updates";
import TestComp from "./src/debug/testComponent";
import Sentry from "./src/reimports/Sentry";
import { isWeb } from "./src/tools/generic";
import { apiFetch } from "./src/network/apiFetch";

import 'expo-dev-client';

Notifications.addNotificationResponseReceivedListener((response) => {
	console.log({ response });
});

Notifications.addNotificationReceivedListener((response) => {
	console.log("NotificationReceived");
	console.log({ response });
});

export default function App(props) {
	// return <TestComp />;
	const [isLoadingComplete, setLoadingComplete] = React.useState(false);
	const [initialUrl, setinitialUrl] = React.useState(null);
	const containerRef = React.useRef();
	const notificationListener = React.useRef();
	const responseListener = React.useRef();
	const [maintenanceChecked, setmaintenanceChecked] = React.useState(false);
	const [defaultMaintenance, setdefaultMaintenance] = React.useState(false);

	if (Platform.OS === "android") {
		if (UIManager.setLayoutAnimationEnabledExperimental) {
			UIManager.setLayoutAnimationEnabledExperimental(true);
		}
	}

	React.useEffect(() => {
		Sentry?.Native.setContext("appInfo", { version: global.appVersionString, web: isWeb() });
		const kEventListener = Keyboard.addListener("keyboardDidShow", updateWindowHeightInfo);

		if (isWeb()) {
			window.Paddle.Setup({
				vendor: 33729,
				debug: __DEV__,
			});
		}

		apiFetch(`api/`, "POST", { command: "get_under_maintenance" }, true).then((data) => {
			if (data && data.maintenance) {
				setdefaultMaintenance(true);
			}
			setmaintenanceChecked(true);
		});

		return () => {
			kEventListener.remove();
		};
	}, []);

	React.useEffect(() => {
		notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
			console.log("Notification received", notification);
		});

		responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
			console.log("Notification interacted with", response);
			// alert("notification interacted with");
		});

		const onAppUpdateEvent = (updateEvent) => {
			if (updateEvent.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
				global.updateAvailable = true;
			}
		};

		const updateListener = Updates.addListener(onAppUpdateEvent);

		return () => {
			Notifications.removeNotificationSubscription(notificationListener);
			Notifications.removeNotificationSubscription(responseListener);
			updateListener.remove();
		};
	}, []);

	const updateWindowHeightInfo = (e) => {
		global.keyboardHeight = e.endCoordinates.height;
		global.visibleWindowHeight = Dimensions.get("window").height - e.endCoordinates.height;
	};

	const onLoadingCompleted = React.useCallback(
		(url) => {
			setinitialUrl(url);
			setLoadingComplete(true);

			const match = url?.match(/ref\/([^\/]+)/);
			if (match) {
				const ref = match[1];
				AsyncStorage.setItem("referral", ref);
			}
		},
		[initialUrl]
	);

	return (
		<ReduxProvider store={store}>
			<PaperProvider theme={DarkTheme}>
				<SafeAreaProvider>
					{!isLoadingComplete || !maintenanceChecked ? (
						<AppLoader completeLoading={onLoadingCompleted} />
					) : (
						<AppContainer
							defaultMaintenance={defaultMaintenance}
							initialUrl={initialUrl}
							containerRef={containerRef}
						/>
					)}
				</SafeAreaProvider>
			</PaperProvider>
		</ReduxProvider>
	);
}
