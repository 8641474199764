import * as React from "react";
import { View, StyleSheet, Linking } from "react-native";
import { ws } from "../../tools/generic";
import AppText from "./AppText";

function escape(content) {
	content = content.replace(/\\\*/g, "$ASTERIX$");
	content = content.replace(/\\{/g, "$CURLY$");
	content = content.replace(/\\#/g, "$TITLE$");
	return content;
}

function restore(content) {
	// replaceAll works in Dev but causes crash in Production (expo SDK 40)
	content = content.replace(/\$ASTERIX\$/g, "*");
	content = content.replace(/\$CURLY\$/g, "{");
	content = content.replace(/\$TITLE\$/g, "#");
	return content;
}

function MarkdownText({ children, textProps }) {
	if (typeof children !== "string") return children;

	// Negative lookbehind works in Dev but causes crash in Production (expo SDK 38)
	// so here is a workaround where we replace the escaped character by some code
	children = escape(children);

	const separator = /(\*{1,3}|{|}|_{2}|http[^\s<>]+\w\/?|#{1,3} |^-\s|\n)/gm;
	const rules = /^(\*{1,3}|{|}|_{2}|http[^\s<>]+\w\/?|#{1,3} |^-\s)/m;

	let sections = children.split(separator);

	if (!sections.length || sections.length === 1) return restore(children);

	// remove undefine
	sections = sections.filter((s) => s);
	const elements = [];

	// Declare i outside of the loop if you want to edit it inside. Otherwise it won't work in PRODUCTION.
	let i = 0;
	for (i = 0; i < sections.length; i++) {
		let sect = sections[i];

		// common props
		let cm = { ...textProps, selectable: true, allowMarkdown: false, key: i };

		if (sect.match(rules)) {
			let endMatch = sect;
			let endMatchRegex = null;
			let removeEndMatch = true;
			if (sect === "{") endMatch = "}";
			else if (sect.match(/http/)) endMatch = null;
			else if (sect.match(/#|-/)) {
				endMatchRegex = /\n/;
				removeEndMatch = false;
			}

			if (endMatch || endMatchRegex) {
				let endIndex = endMatchRegex
					? sections.findIndex((s, index) => index > i && s.match(endMatchRegex))
					: sections.indexOf(endMatch, i + 1);

				if (endIndex < 0 && sect.match(/#|-/)) {
					endIndex = sections.length;
				}

				if (endIndex < 0) {
					sect = restore(sect);
					elements.push(<AppText {...cm}>{sect}</AppText>);
				} else {
					let content = sections.slice(i + 1, removeEndMatch ? endIndex : endIndex + 1).join("");
					content = restore(content);
					let newElement = null;

					cm.allowMarkdown = true;

					// prettier-ignore
					if(sect === "***") newElement = (<AppText {...cm} bold italic>{content}</AppText>);
                    else if(sect === "**") newElement = (<AppText {...cm} bold>{content}</AppText>);
                    else if(sect === "*") newElement = (<AppText {...cm} italic>{content}</AppText>);
                    else if(sect === "{") newElement = (<AppText {...cm} size="small">{content}</AppText>);
                    else if(sect === "# ") newElement = (<AppText {...cm} size="header">{content}</AppText>);
                    else if(sect === "## ") newElement = (<AppText {...cm} size="header2">{content}</AppText>);
                    else if(sect === "### ") newElement = (<AppText {...cm} size="header3">{content}</AppText>);
                    else if(sect === "__") newElement = (<AppText {...cm} underline>{content}</AppText>);
                    else if(sect.match(/-/)) {
                        newElement = (
                            <AppText {...cm} style={{flexDirection:"column"}}>
                                <AppText allowMarkdown={false}>    {'\u2022' + " "} </AppText>
                                <AppText>{content}</AppText>
                            </AppText>
                        );
                    }

					elements.push(newElement);
					i = endIndex;
				}
			} else if (sect.match(/http/)) {
				// prettier-ignore
				sect = restore(sect);
				elements.push(
					<AppText
						{...cm}
						color="url"
						onPress={ws(null, () => Linking.openURL(sect))}
						avoidTouchable
						target="_blank"
						href={sect}
						accessibilityRole="link"
					>
						{sect}
					</AppText>
				);
			}
		} else {
			sect = restore(sect);
			elements.push(
				<AppText {...textProps} {...cm}>
					{sect}
				</AppText>
			);
		}
	}

	return elements;
}

export default MarkdownText;
