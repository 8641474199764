import { Entypo, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect, useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { deleteCharacter, receiveCharacters } from "../../store/slices/charactersSlice";
import { colors } from "../../styles/colors";
import { fadeLayout } from "../../tools/animation";
import { getCharName } from "../../tools/characters";
import CharacterAvatar from "../avatar/CharacterAvatar";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";
import CharacterTokens from "../tokens/CharacterTokens";
import { isGM } from "../../tools/games";
import { ws } from "../../tools/generic";

import { BuildStyleMethod } from "../../styles/theming";

function CharacterItem({
	character,
	index,
	separators,
	showOptions,
	reverseColor,
	onPress,
	to,
	iconRightData,
	isSelected,
	showArrowsText,
	dispatch,
	game,
}) {
	const styles = stylesMethod(global.theme);
	const confirmDeleteModalRef = React.useRef();
	const confirmKickModalRef = React.useRef();

	const navigation = useNavigation();
	const route = useRoute();

	React.useEffect(() => {
		if (separators) separators.updateProps("leading", { trailingItem: character });
	}, []);

	const toggleVisibility = React.useCallback(() => {
		const hidden = !character.hidden;
		fadeLayout();
		dispatch(receiveCharacters({ gameId: character.game, characters: [{ ...character, hidden }] }));
		apiFetch(`games/${game.id}/characters/${character.id}`, "PATCH", { hidden });
	}, [character]);

	const options = [];

	if (showOptions && character) {
		options.push({
			title: _("Tags", "character option"),
			icon: { type: Ionicons, name: "md-pricetags" },
			onPress: () => {
				navigation.navigate("CharactersScreenModalStack", {
					screen: "CharacterTagsModal",
					params: { character },
				});
			},
		});

		character.is_npc &&
			options.push({
				title: character.hidden ? _("Reveal", "character option") : _("Hide", "character option"),
				icon: { type: MaterialCommunityIcons, name: character.hidden ? "eye" : "eye-off" },
				onPress: toggleVisibility,
			});

		!character.is_npc &&
			options.push({
				title: _("Make NPC", "character option"),
				icon: { type: MaterialIcons, name: "person" },
				onPress: () => {
					dispatch(
						receiveCharacters({
							gameId: character.game,
							characters: [{ ...character, player: game.game_master, is_npc: true }],
						})
					);
					apiFetch(`games/${game.id}/characters/${character.id}/make-npc`, "POST");
				},
			});

		!character.is_npc &&
			options.push({
				title: _("Kick player", "character option"),
				icon: { type: MaterialCommunityIcons, name: "logout" },
				danger: true,
				onPress: () => confirmKickModalRef.current.show(),
			});

		character.is_npc &&
			options.push({
				title: _("Assign to", "character option"),
				icon: { type: Entypo, name: "login" },
				onPress: () => {
					navigation.navigate("SelectPlayerModal", {
						gameId: character.game,
						characterSelectedId: character.id,
						backToScreen: route.name,
					});
				},
			});

		character.is_npc &&
			options.push({
				title: _("Delete", "character option"),
				icon: { type: MaterialIcons, name: "delete" },
				danger: true,
				onPress: () => confirmDeleteModalRef.current.show(),
			});
	}

	const useNarrator = !character && isGM(game);

	// Template litterals inside a tag break GNU gettext
	// https://savannah.gnu.org/bugs/?50920#comment21
	const leaveURL = `users/${character?.player?.id}/games/${game.id}/leave`;

	return (
		<>
			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete character")}
				message={_("Are you sure you want to delete this character? This action cannot be reverted.")}
				isDelete
				onConfirm={() => {
					dispatch(deleteCharacter(character));
				}}
			/>
			<BoxModal
				ref={confirmKickModalRef}
				title={_("Kick player", "character option")}
				message={_("Are you sure you want to kick this player? This action cannot be reverted.")}
				danger
				onConfirm={() => {
					apiFetch(leaveURL, "POST");
				}}
			/>
			<ListButton
				index={index}
				iconLeft={
					<>
						{useNarrator && (
							<View style={{ height: 48, width: 48, justifyContent: "center", alignItems: "center" }}>
								<Entypo name="open-book" size={32} color={global.colors.textDefault} />
							</View>
						)}
						{!useNarrator && (
							<View style={{ height: 48, width: 48, justifyContent: "center", alignItems: "center" }}>
								<CharacterAvatar character={character} size={ws(32, 48)} />
							</View>
						)}
						{character?.hidden && (
							<View style={styles.hiddenIcon}>
								<MaterialCommunityIcons name="eye-off" size={16} color={global.colors.textDefault} />
							</View>
						)}
					</>
				}
				title={useNarrator ? _("Narrator", "default character for GM") : getCharName(character)}
				subtitle={
					useNarrator
						? showArrowsText
							? ws(_("Click to change character"), _("Tap the arrows to change character"))
							: null
						: character?.is_npc
						? _("NPC", "non player character")
						: character?.player?.username
				}
				subtitle2={<CharacterTokens character={character} horizontalList />}
				options={options}
				reverseColor={reverseColor}
				onPress={onPress}
				to={to}
				iconRightData={iconRightData}
				isSelected={isSelected}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		game: state.games[state.games.currentId],
	};
};

export default connect(mapStateToProps)(CharacterItem);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	hiddenIcon: {
		position: "absolute",
		bottom: 4,
		right: 4,
		borderRadius: 64,
		backgroundColor: colors.cardBackground,
	},
}));
