import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { setFocusedType } from "../../store/slices/gamesUISlice";
import { BuildStyleMethod } from "../../styles/theming";
import { fadeLayout } from "../../tools/animation";
import { isAndroid } from "../../tools/generic";
import { isStory } from "../../tools/lines";
import Line from "./Line";


function LineWithOverlay({ line, index, separators, inverted, isPreview, onLayout, dispatch, focusedType, lines }) {
	const styles = stylesMethod(global.theme);
	React.useEffect(() => {
		if (separators) {
			separators.updateProps("leading", { trailingItem: line });
		}
	}, [line, separators, index]);

	const foregroundType = isStory(line) ? "story" : "chat";

	const isBackground = focusedType !== foregroundType && !line.is_comment;

	const onPress = () => {
		if (isBackground) {
			const foregroundType = isStory(line) ? "story" : "chat";
			fadeLayout();
			dispatch(setFocusedType({ gameId: line.game, value: foregroundType }));
		}
	};

	// reverse the index so that it will match lines
	if (inverted) index = lines.length - 1 - index;

	return (
		<>
			<Line
				style={[inverted && isAndroid() && { scaleY: -1 }]}
				line={line}
				prevLine={lines.rg_prev(index)}
				nextLine={lines.rg_next(index)}
				isPreview={isPreview}
				onLayout={onLayout}
			/>
			{isBackground && (
				<TouchableOpacity onPress={onPress} style={[styles.backgroundOverlay]} activeOpacity={0.7} />
			)}
		</>
	);
}

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		backgroundOverlay: {
			height: "100%",
			width: "100%",
			position: "absolute",
			backgroundColor: colors.cardBackground,
			opacity: 0.7,
		},
	})
);

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.line.game;
	const gameUI = state.gamesUI[gameId] || {};

	return {
		focusedType: gameUI.focusedType,
	};
};

export default connect(mapStateToProps)(React.memo(LineWithOverlay));
