import * as React from "react";
import { View, StyleSheet } from "react-native";
import Autocomplete from "../generic/Autocomplete";
import { connect } from "react-redux";
import { filterByVisibility, getCharName } from "../../tools/characters";
import { _ } from "../../i18n/i18n";
import {
	getCurrentGameUI,
	setTalkToCharacterIds,
	setWhisperToCharacterIds,
	setFocusedType,
	setTalkToPlayerIds,
} from "../../store/slices/gamesUISlice";
import { findTargets } from "../../tools/lines";

function AtAutocomplete({
	text,
	selection,
	setselection,
	setMessageMethod,
	focused,
	dispatch,
	game,
	talkToGameId,
	focusedType,
	gameCharacters,
	characterTags,
	whisperToCharacterIds,
	whisperToPlayerIds,
	talkToCharacterIds,
	talkToPlayerIds,
	autocompleteRef,
	textInputReference,
	showOnTop,
	messageType,
	users
}) {
	const visibleCharacters = React.useMemo(
		() =>
			filterByVisibility(
				gameCharacters.filter((c) => !c.deleted && getCharName(c) !== _("A person", "default character name")),
				characterTags
			),
		[gameCharacters, characterTags]
	);

	const isStory = focusedType === "story";
	const targetingUsers = messageType == "chat" || talkToPlayerIds?.length || whisperToPlayerIds?.length;
	const targetingCharacters = messageType == "dialogue" || messageType == "description" || whisperToCharacterIds?.length || talkToCharacterIds?.length;

	let autocompleteItemPool;
	const assistants = game.assistants.map((id) => users[id]);
	if (targetingUsers) {
		autocompleteItemPool = game.players.concat(game.game_master, assistants);
	} else if (targetingCharacters) {
		autocompleteItemPool = visibleCharacters;
	} else {
		autocompleteItemPool = isStory
			? visibleCharacters.concat(game.players).concat(game.game_master, assistants)
			: game.players.concat(game.game_master, assistants).concat(visibleCharacters);
	}

	const targets = React.useMemo(() => findTargets(text, !isStory, game.id), [text, isStory, game.id]);

	const whispering = whisperToPlayerIds?.length;

	React.useEffect(() => {
		let alreadyTargettedIds = null;

		if (whispering) {
			alreadyTargettedIds = whisperToPlayerIds?.length ? whisperToPlayerIds : whisperToCharacterIds;
		} else {
			alreadyTargettedIds = talkToPlayerIds?.length ? talkToPlayerIds : talkToCharacterIds;
		}

		alreadyTargettedIds = alreadyTargettedIds || [];

		const newTargetIds = [];
		for (let i = 0; i < targets.length; i++) {
			const targetId = targets[i];
			if (!alreadyTargettedIds.includes(targetId)) {
				newTargetIds.push(targetId);
			}
		}

		if (newTargetIds.length) {
			let dispatchMethod = null;
			if (isStory) {
				dispatchMethod = whispering ? setWhisperToCharacterIds : setTalkToCharacterIds;
			} else {
				dispatchMethod = setTalkToPlayerIds;
			}

			dispatch(dispatchMethod({ gameId: talkToGameId, value: alreadyTargettedIds.concat(newTargetIds) }));
			const content = text.replace(/^(@.*?#\d+\s*)*/g, "");

			dispatch(setMessageMethod({ gameId: game.id, value: content }));
		}
	}, [
		text,
		targets,
		whispering,
		whisperToCharacterIds,
		whisperToPlayerIds,
		talkToCharacterIds,
		talkToPlayerIds,
		game.id,
		isStory,
		talkToGameId,
	]);

	return (
		<Autocomplete
			showOnTop={showOnTop}
			ref={autocompleteRef}
			textInput={textInputReference?.current}
			reverseList
			marker="@"
			itemsPool={autocompleteItemPool}
			text={text}
			selection={selection}
			setselection={setselection}
			changeText={(text) => dispatch(setMessageMethod({ gameId: game.id, value: text }))}
			focused={focused}
			itemToStringMap={autocompleteItemPool.map((item) => ({
				item,
				string: item.username || getCharName(item),
			}))}
			extractAutocompleteWord={(item) => `@${item.username || getCharName(item)}#${item.id}`}
			onItemSelected={(item) => {
				if (item.username && isStory) {
					dispatch(setFocusedType({ gameId: game.id, value: "chat" }));
				} else if (!item.username && !isStory) {
					dispatch(setFocusedType({ gameId: game.id, value: "story" }));
				}
			}}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameUI = getCurrentGameUI(state);
	const game = state.games[state.games.currentId];

	// Used on desktop so that we can separately manage the "talk to" 
	// characters in new messages and in the currently edited line
	let talkToGameId = game.id;
	if(ownProps.inlineEditing){
		talkToGameId += "inlineEdit"
	}
	const talkToGameUI = state.gamesUI[talkToGameId]

	return {
		game,
		focusedType: gameUI.focusedType,
		gameCharacters: state.charactersByGame[game.id],
		characterTags: state.characterTags[game.id] || Array.rg_empty,
		whisperToCharacterIds: talkToGameUI?.whisperToCharacterIds,
		whisperToPlayerIds: talkToGameUI?.whisperToPlayerIds,
		talkToCharacterIds: talkToGameUI?.talkToCharacterIds,
		talkToPlayerIds: talkToGameUI?.talkToPlayerIds,
		talkToGameId,
		users: state.users
	};
};

export default connect(mapStateToProps)(AtAutocomplete);


