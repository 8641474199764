import * as React from "react";
import { BackHandler, Dimensions, Linking, StyleSheet } from "react-native";
import { Portal } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";
import { connect } from "react-redux";
import { fetchSubscriptionInfo } from "../../store/slices/subscriptionSlice";
import { fetchCurrentUser } from "../../store/slices/usersSlice";
import AppText from "../generic/AppText";

class PaddleWebView extends React.PureComponent {
	constructor(props) {
		super(props);

		this.webRef = React.createRef();
		this.state = {
			onCheckout: false,
			uri: `${global.root_url}/s/paddle/`,
		};
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.state.onCheckout) {
			this.closeCheckout = BackHandler.addEventListener("hardwareBackPress", () => {
				this.setState({ onCheckout: false });
				return true;
			});
		} else if (this.closeCheckout) {
			this.closeCheckout.remove();
		}
	}

	componentWillUnmount() {
		this.closeCheckout && this.closeCheckout.remove();
	}

	openCheckout(params) {
		const javascript = `(function() {
		    Paddle.Checkout.open(${JSON.stringify(params)});
		})();`;
		this.webRef.current.injectJavaScript(javascript);
		this.setState({ onCheckout: true });
	}

	handleNavigationStateChange = (event) => {
		if (event.url && event.url !== this.state.uri) {
			this.webRef.current.stopLoading();
			Linking.openURL(event.url);
		}
	};

	render() {
		const windowDim = Dimensions.get("window");
		return (
			<Portal visible pointerEvents={this.state.onCheckout ? undefined : "none"} transparent>
				<SafeAreaView
					style={[
						this.state.onCheckout ? styles.visible : styles.hidden,
						this.state.onCheckout
							? {
									width: windowDim.width,
									height: windowDim.height,
							  }
							: undefined,
					]}
				>
					<WebView
						style={{
							backgroundColor: "transparent",
							// resizeMode: "cover",
						}}
						scalesPageToFit={false}
						scrollEnabled={false}
						ref={this.webRef}
						source={{ uri: this.state.uri }}
						renderError={() => <AppText>WEBVIEW LOADING ERROR</AppText>}
						thirdPartyCookiesEnabled={true}
						onMessage={(event) => {
							const data = JSON.parse(event.nativeEvent.data);
							// console.log("WEBVIEW LOG:", JSON.parse(event.nativeEvent.data));
							if (data === "Checkout.Close" || data === "Checkout.Complete") {
								this.setState({ onCheckout: false });
							}

							if (data === "Checkout.Complete") {
								this.props.dispatch(fetchCurrentUser())
								this.props.dispatch(fetchSubscriptionInfo());
							}
						}}
						onHttpError={(syntheticEvent) => {
							const { nativeEvent } = syntheticEvent;
							console.warn("WebView received error status code: ", nativeEvent.statusCode);
						}}
						onNavigationStateChange={this.handleNavigationStateChange}
					/>
				</SafeAreaView>
			</Portal>
		);
	}
}


export default PaddleWebView;

const styles = StyleSheet.create({
	container: {},
	hidden: {},
	// visible: { position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }
	visible: {
		// backgroundColor: "red",
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
});
