import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";

export const featuresSlice = createSlice({
	name: "featuresSlice",
	initialState: [],
	reducers: {
		receiveFeatures: (state, { payload: features }) => {
			return state.rg_overlapById(features, true);
		},
		setFeatures: (state, { payload: features }) => {
			return features;
		},
		clearFeatures: () => {
			return [];
		},
	},
});

export const { setFeatures, receiveFeatures, clearFeatures } = featuresSlice.actions;

export function fetchFeatures() {
	return async (dispatch, getState) => {
		return apiFetch(`features`, "GET", { "page-size": 30, done: false, over_budget: false }).then(
			({ results, next }) => {
				dispatch(setFeatures(results));
				return next;
			}
		);
	};
}
export function fetchNextFeatures(url) {
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then(({ results, next }) => {
			dispatch(receiveFeatures(results));
			return next;
		});
	};
}
