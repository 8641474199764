import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import CoinValue from "../coins/CoinValue";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import { _ } from "../../i18n/i18n";
import { globalStyles } from "../../styles/global";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import AppButton from "../generic/buttons/AppButton";
import { useNavigation, useRoute } from "@react-navigation/native";

function VoteFeatureItem({ feature }) {
	const modalRef = React.useRef();
	const navigation = useNavigation();
	const route = useRoute();

	return (
		<TouchableOpacity style={styles.container} onPress={() => modalRef.current.show()}>
			<View style={globalStyles.rc}>
				{/* <MaterialCommunityIcons name="vote" size={32} color={global.colors.textDefault} style={{ marginRight: 8 }} /> */}
				<View style={{ flex: 1 }}>
					<AppText>{feature.name}</AppText>
					<CoinValue subject={feature} subjectGetter={{ feature_id: feature.id }} />
				</View>
				<AppButton
					title={_("Vote")}
                    size="small"
                    style={{marginLeft: 16}}
					onPress={() => {
						navigation.navigate({
							name: "GiveCoin",
							params: {
								previousScreenName: route.name,
								subjectGetter: { feature_id: feature.id },
							},
						});
					}}
				/>
			</View>
			<BoxModal
				ref={modalRef}
				title={feature.name}
				message={feature.description}
				options={[{ title: _("Got it!", "close info box") }]}
				wide
			/>
		</TouchableOpacity>
	);
}

export default VoteFeatureItem;

const styles = StyleSheet.create({
	container: {
		paddingVertical: 8,
	},
});
