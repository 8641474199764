import * as React from "react";
import { View, StyleSheet } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import OAIDescriber from "./OAIDescriber";
import { _ } from '../../i18n/i18n';

function OpenAIGeneratorScreen() {
	const { onLayout } = useLayout();

	return (
		<AppScreenView
			scroll
			onLayout={onLayout}
			style={[ws({ width: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })]}
		>
			
			<OAIDescriber />
		</AppScreenView>
	);
}

export default OpenAIGeneratorScreen;

const styles = StyleSheet.create({
	container: {},
});
