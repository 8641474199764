import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { ws } from "../../tools/generic";
import { getLineAvatar, isStory } from "../../tools/lines";
import CharacterAvatar from "../avatar/CharacterAvatar";
import UserAvatar from "../users/UserAvatar";

function LineAvatar({
	line,
	show = true,
	containerStyle,
	avatarStyle,
	hideAvatar,
	isPreview,
	onLayout,
	noTokens,
	dispatch,
	gameId,
}) {
	if (!show) {
		return null;
	}

	const navigation = useNavigation();

	const isStorytelling = (line.is_storytelling && !line.author) || (!line.author && !line.user);

	let component = null;

	if (!isStorytelling && isStory(line)) {
		const character = getLineAvatar(line);
		component = (
			<CharacterAvatar
				style={avatarStyle}
				showTokens={!isPreview && !noTokens}
				character={character}
				onPress={() => navigation.navigate("CharacterOptionsModal", { characterId: character.id, line, isPreview })}
				line={line}
			/>
		);
	} else if (line.user) {
		component = (
			<TouchableOpacity
				onPress={() => navigation.navigate("UserOptionsModal", { user: line.user, gameId })}
				style={avatarStyle}
			>
				<UserAvatar user={line.user} />
			</TouchableOpacity>
		);
	}

	if (!component) {
		return null;
	}

	if (hideAvatar) {
		return <View style={[styles.avatarColumn, containerStyle]} onLayout={onLayout} />;
	}

	return (
		<View pointerEvents="box-none" style={[styles.avatarColumn, containerStyle]} onLayout={onLayout}>
			{component}
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		gameId: state.games.currentId,
	};
};

export default connect(mapStateToProps)(React.memo(LineAvatar));

const styles = StyleSheet.create({
	avatarColumn: {
		width: ws(72, 48),
		// height: 48,
		justifyContent: "flex-end",
		alignItems: "center",
		overflow: "visible",
	},
});
