import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Token from "../tokens/Token";
import BoxModal from "../generic/modal/BoxModal";
import { Portal } from "react-native-paper";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";

function DieFaceSymbol({ symbol, size, color, cancelled, detailsOnPress, usedInText }) {
	const detailsModalRef = React.useRef();

	if(!symbol.value){
		size = Math.max(size, 46);
	}

	const Tag = detailsOnPress ? TouchableOpacity : View;

	return (
		<>
			{symbol.value && (
				<Token
					style={cancelled ? styles.cancelled : null}
					onPress={detailsOnPress ? () => detailsModalRef.current.show() : null}
					shape={symbol.value}
					color={cancelled ? global.colors.failureOpacity : color}
					usedInText={usedInText}
					size={size}
				/>
			)}
			{!symbol.value && (
				<Tag
					onPress={detailsOnPress ? () => detailsModalRef.current.show() : null}
					style={[styles.textBox, { height: size, width: size }, cancelled && {opacity: 0.3}]}
				>
					<AppText size="small" centered numberOfLines={3} ellipsizeMode="tail" color={cancelled ? "danger" : null}>
						{symbol.name}
					</AppText>
				</Tag>
			)}

			{detailsOnPress && (
				<Portal>
					<BoxModal ref={detailsModalRef} options={[{ title: _("Got it!", "close info box") }]}>
						<Token shape={symbol.value} color={color} />
						<AppText centered>{symbol.name}</AppText>
					</BoxModal>
				</Portal>
			)}
		</>
	);
}

const styles = StyleSheet.create({
	cancelled: {
		opacity: 0.5,
	},
	textBox: {
		borderWidth: 1,
		alignItems: "center",
		justifyContent: "center",
		padding: 2,
		textAlignVertical: "center",
	},
});

export default DieFaceSymbol;
