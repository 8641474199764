import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { saveDiceSet } from "../../store/slices/dicesetsSlice";
import { fetchDice, createDie } from "../../store/slices/diceSlice";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { idKeyExtractor, ws } from "../../tools/generic";
import { simpleReducer, usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import InputField from "../generic/InputField";
import BoxModal from "../generic/modal/BoxModal";
import DieTemplateItem from "./DieTemplateItem";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import Popover from "../generic/popover/Popover";
import PopoverMessage from "../generic/popover/PopoverMessage";
import ListSwitch from "../generic/ListSwitch";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";

function EditDiceSetScreen({ route, navigation, dispatch, user, allDice }) {
	const { diceSet, selectingSetFor } = route.params;
	const [editedSet, updateSet] = React.useReducer(simpleReducer, diceSet);
	const [creating, setcreating] = React.useState();

	const popoverRef = React.useRef();
	const confirmCreateModalRef = React.useRef();
	const editedSetRef = React.useRef(editedSet);
	editedSetRef.current = editedSet;
	const prevEditedSet = usePrevious(editedSet);

	const isMine = diceSet.creator && diceSet.creator.id === user?.id;

	const saveTimeout = React.useRef(null);

	const dice = Object.values(allDice).filter((d) => d.dice_set === diceSet.id);

	const symbols = dice
		.reduce((acc, d) => acc.concat(d.faces), [])
		.reduce((acc, f) => acc.concat(f.symbol_links), [])
		.filter((sl) => sl.symbol.value)
		.rg_mapDistinct(
			(sl) => sl.symbol,
			(element, other) => element.id === other.id
		);

	const unnamedSymbolsCount = symbols.filter((s) => !s.name).length;
	const unnamedCountRef = React.useRef(unnamedSymbolsCount);
	const lastUnnamed = usePrevious(unnamedSymbolsCount);

	unnamedCountRef.current = unnamedSymbolsCount;

	const save = React.useCallback(() => {
		if (!isMine) return;
		let isPublic = editedSetRef.current.public;
		if (unnamedCountRef.current > 0) {
			isPublic = false;
		}
		dispatch(
			saveDiceSet({
				...editedSetRef.current,
				name: editedSetRef.current.name || _("New dice set", "default dice set name"),
				public: isPublic,
			})
		);
	}, [isMine]);

	React.useEffect(() => {
		if (isMine) {
			navigation.setOptions({ headerTitle: _("Edit dice set", "screen title") });
		} else {
			navigation.setOptions({ headerTitle: _("Explore dice set", "screen title") });
		}
	}, [isMine]);

	// mount dismount
	React.useEffect(() => {
		dispatch(fetchDice([diceSet.id]));
		return () => {
			saveTimeout.current && save();
		};
	}, [diceSet.id, save]);

	// Condition to trigger save
	React.useEffect(() => {
		if (!prevEditedSet) return () => null;
		saveTimeout.current = setTimeout(save, 3000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [save, editedSet.name, editedSet.description, editedSet.public, unnamedSymbolsCount > 0 != lastUnnamed > 0]);

	const create = React.useCallback(async () => {
		setcreating(true);
		saveTimeout.current && save();
		const die = await dispatch(createDie(diceSet.id));
		setcreating(false);
		navigation.navigate("EditDieScreen", { die });
	}, [save, diceSet.id]);

	React.useEffect(() => {
		const unsubscribeFocus = navigation.addListener("focus", () => {
			if (unnamedSymbolsCount > 0) {
				popoverRef.current?.show();
			}
		});
		const unsubscribeBlur = navigation.addListener("blur", () => {
			popoverRef.current?.hide();
		});
		return () => {
			unsubscribeFocus();
			unsubscribeBlur();
		};
	}, [navigation, unnamedSymbolsCount]);

	const title = (
		<AppText bold style={{ marginVertical: 8 }}>
			{_("Dice")}
		</AppText>
	);

	const topComponent = isMine ? (
		<View style={styles.container}>
			<InputField
				value={editedSet.name}
				onChangeText={(t) => updateSet({ name: t })}
				label={_("Dice set name", "input field label")}
			/>
			<InputField
				value={editedSet.description}
				onChangeText={(t) => updateSet({ description: t })}
				multiline
				label={_("Description", "dice set description")}
			/>

			<ListSwitch
				style={{ marginTop: 16 }}
				title={_("Share to community", "share dice set")}
				isEnabled={editedSet.public}
				setIsEnabled={() => updateSet({ public: !editedSet.public })}
				disabled={unnamedSymbolsCount > 0}
			/>
			<AppText hide={!editedSet.public || unnamedSymbolsCount} color="hint" style={{ marginBottom: 16 }}>
				{_("Other Game masters can now use this dice set in their games.")}
			</AppText>
			<AppText hide={!unnamedSymbolsCount} color="danger" style={{ marginBottom: 16 }}>
				{_("This set has unnamed symbols and cannot be used or shared.")}
			</AppText>

			{title}
		</View>
	) : (
		<View style={styles.container}>
			<AppText bold>{diceSet.name}</AppText>
			<AppText>{diceSet.description}</AppText>
			{title}
		</View>
	);

	return (
		<>
			<AppScreenView
				borderless
				style={ws({
					paddingLeft: fullScreenLeftPadding(),
				})}
			>
				<FlatList
					contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
					keyExtractor={idKeyExtractor}
					data={dice}
					ListHeaderComponent={topComponent}
					renderItem={({ item }) => (
						<DieTemplateItem
							die={item}
							onPress={() => navigation.navigate("EditDieScreen", { die: item })}
						/>
					)}
					ListEmptyComponent={() => (
						<AppText color="hint" centered style={{ marginVertical: 16 }}>
							{_("Press + to add dice to this set.", "empty dice list message")}
						</AppText>
					)}
				/>
				{isMine && (
					<FAB
						icon="plus"
						style={globalStylesMethod(global.theme).fab}
						onPress={() => confirmCreateModalRef.current.show()}
						disabled={creating}
					/>
				)}
				<BoxModal
					ref={confirmCreateModalRef}
					title={_("Create die")}
					message={_("Add a new die to this set?")}
					onConfirm={create}
				/>
			</AppScreenView>
			{selectingSetFor && (
				<ButtonFooter
					narrow={false}
					style={ws({ maxWidth: fullScreenContentWidth() })}
					buttonProps={[
						{
							title: _("Select this set"),
							onPress: () => navigation.navigate(selectingSetFor, { selectedDiceSet: diceSet }),
						},
					]}
				/>
			)}
			{isMine && !!unnamedSymbolsCount && (
				<Popover
					visible={!!unnamedSymbolsCount && navigation.isFocused()}
					ref={popoverRef}
					popoverComponent={
						<PopoverMessage
							title={_("One more step", "manage dice set symbols")}
							message={_("Name your symbols to validate this dice set.", "manage dice set symbols")}
						/>
					}
					closeOnTap={false}
					arrowPosition="bottom"
				>
					<ButtonFooter
						noBack
						narrow={false}
						style={ws({ maxWidth: fullScreenContentWidth() })}
						buttonProps={[
							{
								title: _("Name symbols", "set dice set rules"),
								badgeValue: unnamedSymbolsCount,
								onPress: () => {
									popoverRef.current?.hide();
									navigation.navigate("NameSymbolsScreen", { symbols, diceSet });
								},
							},
						]}
					/>
				</Popover>
			)}
			{isMine && !!symbols.length && !unnamedSymbolsCount && (
				<ButtonFooter
					narrow={false}
					style={ws({ maxWidth: fullScreenContentWidth() })}
					buttonProps={[
						{
							title: _("Name symbols", "set dice set rules"),
							onPress: () => navigation.navigate("NameSymbolsScreen", { symbols, diceSet }),
						},
						{
							title: _("Set rules", "set dice set rules"),
							onPress: () => navigation.navigate("SetDiceRulesScreen", { symbols, diceSet }),
						},
					]}
				/>
			)}
			{!isMine && !!symbols.length && (
				<ButtonFooter
					narrow={false}
					style={ws({ maxWidth: fullScreenContentWidth() })}
					buttonProps={[
						{
							title: _("View rules", "view dice set rules"),
							onPress: () => navigation.navigate("SetDiceRulesScreen", { symbols, diceSet }),
						},
					]}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		allDice: state.dice,
	};
};

export default connect(mapStateToProps)(EditDiceSetScreen);

const styles = StyleSheet.create({
	container: {
		marginTop: 8,
		marginHorizontal: 16,
	},
});
