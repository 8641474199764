import { createSlice } from "@reduxjs/toolkit";
import { gamesSlice } from "./gamesSlice";

export const partiesSlice = createSlice({
	name: "parties",
	// format:
	// {gameId: {current, povCharacterId, openedParties, all, store}}
	initialState: {},
	reducers: {
		setCurrentParty: (state, { payload: { gameId, party } }) => {
			state[gameId] = state[gameId] || {};
			state[gameId].current = party;
		},

		internalSetPovCharacterId: (state, { payload: { gameId, characterId } }) => {
			state[gameId] = state[gameId] || {};
			state[gameId].povCharacterId = characterId;

			if (!characterId) return;

            const openedParties = state[gameId].all?.filter((p) => p.characters.includes(characterId)) || [];
            state[gameId].openedParties = openedParties;

		},
	},

	extraReducers: {
		[gamesSlice.actions.receiveGames]: (state, { payload: games }) => {
			for (let i = 0; i < games.length; i++) {
				const game = games[i];
				if (!game.parties) continue;

				state[game.id] = state[game.id] || {};
				state[game.id].all = game.parties;
				state[game.id].store = game.parties.rg_toStore();
			}
		},
		[gamesSlice.actions.updateStoredGame]: (state, { payload: game }) => {
			if (!game.parties) return;

			state[game.id] = state[game.id] || {};
			state[game.id].all = game.parties;
			state[game.id].store = game.parties.rg_toStore();
		},
	},
});

export const { setCurrentParty, internalSetPovCharacterId } = partiesSlice.actions;


export function setPovCharacterId({ gameId, characterId } ) {
	return async (dispatch, getState) => {		
		await dispatch(internalSetPovCharacterId({ gameId, characterId }));
		if (!characterId) return;
		await dispatch(refreshCurrentParty(gameId));
	};
}

export function refreshCurrentParty(gameId) {
	return async (dispatch, getState) => {		
		
		const parties = getState().parties[gameId];		

		if(!parties) return;		
		const characterId = parties.povCharacterId;
		const openedParties = parties.all?.filter((p) => p.characters.includes(characterId)) || [];

		const updatedCurrent = parties[parties.current];

		if (!updatedCurrent?.on_going || !openedParties.some((p) => p.id === updatedCurrent.id)) {
			const party = parties.all?.find((p) => p.on_going && p.characters.includes(characterId));			
			await dispatch(setCurrentParty({gameId, party}));
		}
	};
}
