import moment from "moment";
import { _ } from '../i18n/i18n'
export function getTimestamp(dateString){
    const now = moment();
    const date = moment.utc(dateString);
    let display = "";

    const diff = now.diff(date, 'day');

    if (now.isSame(date, 'day')) {
        display = date.fromNow();
    } else if (diff < 2) {
        display = date.local().calendar()
    } else {
        display = date.local().format("Do MMM YYYY");
    }

    return display;
};


export function getTimestampShort(dateString){
    const now = moment();
    const date = moment.utc(dateString);
    let display = "";

    const diff = now.diff(date, 'day');

    if (now.isSame(date, 'day')) {
        display = date.local().format("LT");
    } else if (diff < 2) {
        display = date.local().calendar(null,{
            lastDay: _('[Yesterday]', "last message timestamp. Keep the [ ]."),
        });
    } else {
        display = date.local().format("L");
    }

    return display;
};