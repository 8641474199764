import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Avatar } from "react-native-paper";
import { colors } from "../../styles/colors";

function UserAvatar({ user, size = 32, style }) {
	return (
		<View style={[{ height: size, justifyContent: "center" }, style]}>
			<Avatar.Text size={size} label={user?.username.substr(0, 2) || "-"} style={{ backgroundColor: global.colors.primary }} />
		</View>
	);
}

export default UserAvatar;


