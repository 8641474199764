import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { getRandomLetter } from "../../tools/languages";
import { Clamp } from "../../tools/generic";
import { colors } from "../../styles/colors";
var tinycolor = require("tinycolor2");

function GibberishText(props) {
	const { understandingLevel, color, text } = props;

	if (understandingLevel >= 3) return <AppText color={color}>{text.replace(/\\(\w)/, "$1")}</AppText>;

	const words = text.split(/(\s+|[!¡¿?.,]+)/);

	let percentage = 1;

	switch (understandingLevel) {
		case 1:
			percentage = 0.4;
			break;
		case 2:
			percentage = 0.8;
			break;
		default:
			percentage = 0;
			break;
	}

	const sorted = words
		.rg_mapDistinct((w) => w)
		.sort((a, b) => {
			return a.length - b.length;
        });
        
	const numberToTranslate = Math.floor(sorted.length * percentage);

	const translatedWords = sorted.slice(0, numberToTranslate);

	const components = words.map((word, index) => {
		if (translatedWords.includes(word) || word.match(/[!¡¿?.,]/) || word.startsWith("\\") || !word.trim()) {
			if(word.startsWith("\\")) word = word.substr(1);
			return <AppText key={index} color={props.colorKey || "textDark"}>{word}</AppText>;
		}

		const gibberishLength = Math.round(
			Clamp(word.length, word.length - Math.random() * 3, word.length + Math.random() * 3)
		);

		let content = "";
		for (let i = 0; i < gibberishLength; i++) {
			content += getRandomLetter();
		}

		// use tiny color for the alpha, because you cannot change the opacity of a nested text on Android
		// https://github.com/facebook/react-native/issues/1314#issuecomment-102763655
		return (
			<AppText
				fontFamily="rellanic"
				style={{ color: tinycolor(props.colorKey ? global.colors[props.colorKey] : global.colors.textDark).setAlpha(0.5).toString()}}
				key={index}
			>
				{content}
			</AppText>
		);
	});

	return components;
}

export default React.memo(GibberishText);


