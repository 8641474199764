import * as React from "react";
import { View, StyleSheet } from "react-native";
import ListButton from "../generic/buttons/ListButton";
import CharacterAvatar from "../avatar/CharacterAvatar";
import { _ } from "../../i18n/i18n";
import { getCharName } from "../../tools/characters";
import { connect } from "react-redux";
import UserAvatar from "./UserAvatar";
import { isGM, isMainGM } from "../../tools/games";
import { apiFetch } from "../../network/apiFetch";
import BoxModal from "../generic/modal/BoxModal";
import { FontAwesome, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import AppText from "../generic/AppText";
import { ws } from "../../tools/generic";

function UserItem({
	user,
	index,
	isSelected,
	iconRightData,
	onPress,
	reverseColor,
	game,
	dispatch,
	characters,
	currentUser,
}) {
	const confirmKickModalRef = React.useRef();
	const confirmMakeGMModalRef = React.useRef();

	let character = user.profile?.last_used_character;

	if (game && characters) {
		character = characters.find((c) => c.player?.id === user.id && !c.is_npc);
	}

	const options = [];

	if (isGM(game, currentUser)) {
		if (isMainGM(game, currentUser)) {
			options.push({
				title: _("Make GM", "character option"),
				icon: { type: FontAwesome5, name: "book-reader" },
				danger: true,
				onPress: () => confirmMakeGMModalRef.current.show(),
			});
		}

		if (isGM(game, user)) {
			options.push({
				title: _("Remove assistant GM", "character option"),
				icon: { type: FontAwesome5, name: "address-book" },
				subicon: { type: FontAwesome, name: "remove" },
				onPress: () =>
					apiFetch(`users/${currentUser.id}/games/${game.id}/remove-assistant`, "POST", {
						"user-id": user.id,
					}),
			});
		} else {
			options.push({
				title: _("Make assistant GM", "character option"),
				icon: { type: FontAwesome5, name: "address-book" },
				onPress: () =>
					apiFetch(`users/${currentUser.id}/games/${game.id}/make-assistant`, "POST", { "user-id": user.id }),
			});
		}

		if (currentUser.id != user.id)
			options.push({
				title: _("Kick player", "character option"),
				icon: { type: MaterialCommunityIcons, name: "logout" },
				danger: true,
				onPress: () => confirmKickModalRef.current.show(),
			});
	}

	return (
		<>
			<ListButton
				index={index}
				iconLeft={
					character ? (
						<View style={{ height: ws(32, 48), width: ws(32, 48) }}>
							<CharacterAvatar character={character} size={ws(32, 48)} />
						</View>
					) : (
						<UserAvatar user={user} />
					)
				}
				title={user.username}
				subtitle={
					character
						? _("playing as %(character_name)s", "player info", {
								character_name: getCharName(character),
						  })
						: null
				}
				options={options}
				reverseColor={reverseColor}
				onPress={onPress}
				iconRightData={iconRightData}
				isSelected={isSelected}
			/>

			<BoxModal
				ref={confirmMakeGMModalRef}
				title={_("Make GM", "character option")}
				danger
				onConfirm={() => {
					apiFetch(`users/${currentUser.id}/games/${game.id}/change-gamemaster`, "POST", {
						"user-id": user.id,
					});
				}}
			>
				<AppText color="danger">{_("Warning: You will no longer be the Game Master of this game")}</AppText>

				<AppText style={{ marginTop: 8 }}>
					{_("Are you sure you want to give the game away to this user?")}
				</AppText>
				<AppText style={{ marginTop: 8 }} color="secondary">
					{_("You will have to rejoin the game as a player if you want to be a part of it. Consider making the user an assistant GM if you do not wish to lose control of the game.")}
				</AppText>
			</BoxModal>

			<BoxModal
				ref={confirmKickModalRef}
				title={_("Kick player", "character option")}
				message={_("Are you sure you want to kick this player? This action cannot be reverted.")}
				danger
				onConfirm={() => {
					apiFetch(`users/${user.id}/games/${game.id}/leave`, "POST");
				}}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = ownProps.game || state.games[state.games.currentId];
	return {
		game,
		characters: state.charactersByGame[game?.id] || state.savedStore.charactersByGame[game?.id],
		sheets: state.sheets,
		currentUser: state.user,
	};
};

export default connect(mapStateToProps)(UserItem);
