import moment from "moment";
import * as React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { fetchAvatarRequest } from "../../../store/slices/avatarRequestSlice";
import AppScreenView from "../../AppScreenView";
import AppText from "../../generic/AppText";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import Cond from "../../meta/Cond";
import CondView from "../../meta/CondView";
import CharacterAvatar from "../CharacterAvatar";
import AvatarRequestSendFeedback from "./AvatarRequestSendFeedback";
import { colors } from "../../../styles/colors";
import { ws } from "../../../tools/generic";
import { fullScreenContentWidth } from "../../../styles/dynamicStyles";

function WizardAvatarRequestDeadline({ route, navigation, dispatch, avatars, user, avatarRequest }) {
	const [nextRequestDate, setnextRequestDate] = React.useState(moment());
	const [nextBatch, setnextBatch] = React.useState(moment());
	const [nextAvailable, setnextAvailable] = React.useState(moment());
	const [inProgressAvailableDate, setinProgressAvailableDate] = React.useState(moment());

	React.useEffect(() => {
		const now = moment();

		dispatch(fetchAvatarRequest());

		if (now.date() < 15) {
			setnextBatch((currentValue) => currentValue.date(15));
			setnextAvailable((currentValue) => currentValue.add(1, "month").startOf("month"));
		} else {
			setnextBatch((currentValue) => currentValue.add(1, "month").startOf("month"));
			setnextAvailable((currentValue) => currentValue.add(1, "month").date(15));
		}
	}, []);

	React.useEffect(() => {
		if (avatarRequest) {
			const nextRequestDate = moment(avatarRequest.request_date);
			nextRequestDate.add(30, "days");
			setnextRequestDate(nextRequestDate);

			const requestDate = moment(avatarRequest.request_date);
			if (requestDate.date() < 15) {
				requestDate.add(1, "month").startOf("month");
			} else {
				requestDate.add(1, "month").date(15);
			}
			setinProgressAvailableDate(requestDate);
		}
	}, [avatarRequest]);

	const hasAvailableRequest = React.useMemo(() => {
		if (user.profile.reward_level < 5) {
			return false;
		}
		if (user.profile.reward_level < 5 && user.profile.temporary_upgrade_deadline) {
			return false;
		}
		if (!avatarRequest) {
			return true;
		}
		const request_date = moment(avatarRequest.request_date);
		if (moment().diff(request_date, "days") >= 30) {
			return true;
		}
		return false;
	}, [user, avatarRequest]);

	const hasRequestInProgress = React.useMemo(() => {
		if (!avatarRequest) {
			return false;
		}

		if (!avatarRequest.is_done) {
			return true;
		}
		return false;
	}, [avatarRequest]);

	const hasSpentRequest = React.useMemo(() => {
		return !hasAvailableRequest && avatarRequest && user.profile.reward_level >= 5;
	}, [hasAvailableRequest, avatarRequest, user.profile.reward_level]);

	if (avatarRequest && avatarRequest.pending_feedback)
		return <AvatarRequestSendFeedback avatarRequest={avatarRequest} />;

	const firstRevisionAvatar = avatars[avatarRequest?.avatar];

	return (
		<>
			<AppScreenView borderTop scroll style={ws({ alignItems: "center" })}>
				<AppText hide={!firstRevisionAvatar} centered color="primary" size="large">
					{hasRequestInProgress
						? _("Your current request", "avatar request")
						: _("Your last request", "avatar request")}
				</AppText>
				<AppText
					hide={!firstRevisionAvatar || avatarRequest?.is_done}
					color="primary"
					centered
					style={{ marginTop: 4 }}
				>
					{_("Estimated delivery: %(available_date)s", "avatar request", {
						available_date: inProgressAvailableDate.format("LL"),
					})}
				</AppText>
				<View style={{ alignItems: "center" }}>
					<CharacterAvatar
						avatarName={firstRevisionAvatar ? firstRevisionAvatar.name : `ico_le_16`}
						size={256}
					/>
				</View>

				<CondView show={hasSpentRequest} style={{ marginBottom: 16 }}>
					<Cond show={!firstRevisionAvatar && hasRequestInProgress}>
						<AppText centered bold color="primary">
							{_("Request sent", "avatar request, request already sent")}
						</AppText>
						<AppText centered bold>
							{_(
								"You will receive an email notification when your avatar is ready for your feedback.",
								"avatar request"
							)}
						</AppText>
					</Cond>

					<AppText hide={hasRequestInProgress} style={{ marginTop: 16 }} centered color="attention">
						{_("You can find your new avatar in the 'Change Avatar' menu of your character's sheet.")}
					</AppText>

					<AppText color="hint" centered style={{ marginTop: 16 }}>
						{_("Next request available on %(available_date)s", "avatar request", {
							available_date: nextRequestDate.format("LL"),
						})}
					</AppText>

					<CondView
						show={!avatarRequest?.is_done}
						style={[
							{ backgroundColor: global.colors.descriptionBackground, marginTop: 32, padding: 16 },
							ws({ maxWidth: fullScreenContentWidth(), alignItems: "center" }),
						]}
					>
						<AppText centered bold>
							{_("Your request")}
						</AppText>
						<AppText style={{ marginTop: 16 }} centered color="hintLight">
							{_("Description and main features", "avatar request summary")}
						</AppText>

						<AppText style={{ marginTop: 16 }}>{avatarRequest?.description}</AppText>
						<AppText hide={avatarRequest?.description} centered color="hintLight" style={{ marginTop: 16 }}>
							{_("No description", "no avatar description for request")}
						</AppText>
						<AppText style={{ marginTop: 16 }} centered color="hintLight">
							{_("Image references", "avatar request summary")}
						</AppText>
						<AppText style={{ marginTop: 16 }}>{avatarRequest?.references}</AppText>
						<AppText hide={avatarRequest?.references} centered color="hintLight" style={{ marginTop: 16 }}>
							{_("No references", "no avatar visual references for request")}
						</AppText>

						<Cond show={avatarRequest?.question}>
							<AppText centered color="hintLight" style={{ marginTop: 16 }}>
								{_("Comment from the artist", "avatar request")}
							</AppText>
							<AppText style={{ marginTop: 16 }}>{avatarRequest?.question}</AppText>
						</Cond>

						<Cond show={avatarRequest?.feedback}>
							<AppText centered color="hintLight" style={{ marginTop: 16 }}>
								{_("Your feedback", "avatar request")}
							</AppText>
							<AppText style={{ marginTop: 16 }}>{avatarRequest?.feedback}</AppText>
						</Cond>
					</CondView>
				</CondView>

				<AppText hide={hasSpentRequest} style={{ marginTop: 16 }}>
					{_("A Legendary subscription lets you request one Avatar per month.")}
				</AppText>
				<AppText hide={hasSpentRequest} style={{ marginTop: 16 }}>
					{_(
						"If you request an Avatar today, it will part of the request batch of %(batch_date)s and be available around %(availability_date)s.",
						"",
						{
							batch_date: (
								<AppText bold key="v0">
									{nextBatch.format("LL")}
								</AppText>
							),
							availability_date: (
								<AppText bold key="v1">
									{nextAvailable.format("LL")}
								</AppText>
							),
						}
					)}
				</AppText>

				<View style={{ alignItems: "center", marginTop: 64, marginBottom: 16 }}>
					<AppText size="large" navigateTo="AvatarRequestTermsAndConditions" color="url" centered>
						{_("Terms & conditions")}
					</AppText>
				</View>
			</AppScreenView>
			{hasAvailableRequest && (
				<ButtonFooter
					buttonProps={[
						{
							title: _("Start request", "start avatar request wizard"),
							onPress: () => navigation.navigate("WizardAvatarRequestDescription", route.params),
						},
					]}
					noBack
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		avatars: state.avatars,
		avatarRequest: state.avatarRequest,
	};
};

export default connect(mapStateToProps)(WizardAvatarRequestDeadline);
