import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _, _n } from "../../i18n/i18n";
import { fetchBrowsedGames, fetchNexBrowsedGames } from "../../store/slices/gamesSlice";
import { fetchGameTagsByName } from "../../store/slices/gameTagsSlice";
import { colors } from "../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { idKeyExtractor, isIos, isWeb, ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppPicker from "../generic/AppPicker";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import IconButton from "../generic/buttons/IconButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import SearchBar from "../generic/Searchbar";
import RGIcon from "../RGIcon";
import TagItem from "../tags/TagItem";
import { routeParamsToSearchParams } from "./findgamewizard/WizardScreen";
import GameCard from "./GameCard";

function BrowseGamesScreen({ route, navigation, dispatch, games, gamesFound, nextfetchurl, tags }) {
	const [searchParams, setsearchParams] = React.useState({
		freeSlots: false,
		publicOnly: false,
		beginnerFriendly: false,
		search: "",
	});

	const [currentSearch, setcurrentSearch] = React.useState("");
	const [searchOpen, setsearchOpen] = React.useState(!!route.params?.selectedTags);
	const [sortMode, setSortMode] = React.useState("DEFAULT");
	const [searching, setsearching] = React.useState(false);

	const selectedTags = React.useMemo(() => {
		const tagNames = route.params?.selectedTags || [];
		return tagNames.map((n) => tags[n]).filter((t) => t);
	}, [route.params?.selectedTags, tags]);

	const fromWizard = route.params?.fromWizard;

	const previousParams = usePrevious(searchParams);

	const flatlistRef = React.useRef();

	const previousAllowLocked = usePrevious(route.params?.allowLocked);

	const searchOptionsButton = React.useMemo(
		() => (
			<IconButton
				icon={{ type: Ionicons, name: isIos() ? "ios-options" : "md-options" }}
				onPress={() =>
					navigation.navigate("SearchGamesOptionsModal", {
						...searchParams,
						previousScreenName: route.name,
					})
				}
				transparent
			/>
		),
		[searchParams]
	);

	const fetchNext = React.useCallback(async () => {
		if (!nextfetchurl) return;
		dispatch(fetchNexBrowsedGames(nextfetchurl));
	}, [nextfetchurl, dispatch]);

	const fetchClean = React.useCallback(async () => {
		let params = {};

		if (searchParams.beginnerFriendly) params.beginner_friendly = true;
		if (searchParams.freeSlots) params.is_full = false;
		if (searchParams.publicOnly) params.password = "";
		if (searchParams.search) params.search = searchParams.search;
		if (searchParams.ordering) params.ordering = searchParams.ordering;
		if (searchParams.selectedTags?.filter((t) => t).length) params.tags = searchParams.selectedTags;

		setsearching(true);
		await dispatch(fetchBrowsedGames(params));
		setsearching(false);
	}, [searchParams, dispatch]);

	const startSearch = React.useCallback(() => {
		setsearchOpen(true);
		navigation.setParams({ searching: false });
		navigation.setOptions({
			headerTitle: () => (
				<SearchBar
					autoFocus
					onSearchUpdated={(text) => setcurrentSearch(text)}
					placeholder={_("Search for game or game master...")}
				/>
			),
			headerTitleContainerStyle: [{left: 0}, !isIos() && { left: 48, right: 48 }, { flexGrow: 1 }],
			headerBackTitle: "",
		});
	}, []);

	React.useEffect(() => {
		if (route.params?.searching && !searchOpen) {
			startSearch();
		}
	}, [route.params?.searching, searchOpen]);

	React.useEffect(() => {
		const tagToSearch = route.params?.selectedTags?.filter((n) => n);
		if (tagToSearch && selectedTags.length < tagToSearch.length) {
			const searchTagsMethod = async () => {
				const result = await dispatch(fetchGameTagsByName(route.params.selectedTags));
				if (result.length < tagToSearch.length) {
					navigation.setParams({ selectedTags: result.map((t) => t.name) });
				}
			};

			searchTagsMethod();
		}
	}, [route.params?.selectedTags, selectedTags]);

	React.useEffect(() => {
		if (!route.params) return () => null;
		if (fromWizard && !games.length) {
			const processSearch = async () => {
				setsearching(true);
				await dispatch(fetchBrowsedGames(routeParamsToSearchParams(route.params)));
				setsearching(false);
			};
			processSearch();
		} else {
			setsearchParams((current) => ({ ...current, ...route.params }));
		}
	}, [route.params, fromWizard, games]);

	React.useEffect(() => {
		const updateTimeout = setTimeout(
			() => setsearchParams((current) => ({ ...current, search: currentSearch })),
			1000
		);
		return () => clearTimeout(updateTimeout);
	}, [currentSearch]);

	React.useEffect(() => {
		if (fromWizard) return;
		if (JSON.stringify(previousParams) === JSON.stringify(searchParams)) return;
		fetchClean();
	}, [searchParams, fromWizard, tags]);

	React.useEffect(() => {
		if (fromWizard) return;
		navigation.setOptions({
			headerRight: () => (
				<View style={{ flexDirection: "row" }}>
					{!searchOpen && (
						<IconButton icon={{ type: MaterialIcons, name: "search" }} onPress={startSearch} transparent />
					)}
					{searchOptionsButton}
				</View>
			),
		});
	}, [searchOptionsButton, searchOpen, fromWizard]);

	React.useEffect(() => {
		if (previousAllowLocked != route.params?.allowLocked) {
			dispatch(fetchBrowsedGames(routeParamsToSearchParams(route.params)));
			flatlistRef.current?.scrollToOffset({ offset: 0 });
		}
	}, [route.params?.allowLocked]);

	React.useEffect(() => {
		let ordering;
		switch (sortMode) {
			case "RECENT":
				ordering = "-start_date";
				break;
			case "RATINGS":
				ordering = "-total_value";
				break;
			case "READERS":
				ordering = "-all_time_spectators";
				break;
			case "BOOKMARKS":
				ordering = "-total_bookmarks";
				break;
			default:
				setsearchParams((params) => {
					if (!params.ordering) return params;
					const newParams = params;
					delete newParams.ordering;
					return newParams;
				});
				return () => null;
		}
		setsearchParams((params) => ({ ...params, ordering }));
	}, [sortMode]);

	const gamesFoundComp = (
		<AppText color="primary" key="number" bold>
			{gamesFound || "0"}
		</AppText>
	);

	const listHeader = fromWizard ? (
		<AppText centered size="large" style={{ marginVertical: 16 }}>
			{route.params?.allowLocked
				? _n(
						"We found %(x)s game matching your criteria",
						"We found %(x)s games matching your criteria",
						"join game wizard",
						gamesFound,
						{ x: gamesFoundComp }
				  )
				: _n(
						"We found %(x)s open game matching your criteria",
						"We found %(x)s open games matching your criteria",
						"join game wizard",
						gamesFound,
						{ x: gamesFoundComp }
				  )}
		</AppText>
	) : null;

	const searchTopComponent = (
		<View>
			{isWeb() && (
				<SearchBar
					style={{ marginTop: 16, maxWidth: fullScreenContentWidth() }}
					autoFocus
					onSearchUpdated={(text) => setcurrentSearch(text)}
					placeholder={_("Search for game or game master...")}
				/>
			)}
			<View style={[styles.searchTopContainer, ws({ maxWidth: fullScreenContentWidth(), marginBottom: 16 })]}>
				<AppPicker
					selectedValue={sortMode}
					onValueChange={(itemValue, itemIndex) => setSortMode(itemValue)}
					mode="dropdown"
					pickerStyle={{ flex: 1, color: "white", overflow: "hidden" }}
					itemStyle={{ color: "white" }}
					items={[
						{ label: _("Default", "sort by"), value: "DEFAULT" },
						{ label: _("Highest ratings", "sort by"), value: "RATINGS" },
						{ label: _("Recent first", "sort by"), value: "RECENT" },
						{ label: _("Most bookmarked", "sort by"), value: "BOOKMARKS" },
						{ label: _("Most readers", "sort by"), value: "READERS" },
					]}
				/>

				<TouchableOpacity
					style={{ flexDirection: "row" }}
					onPress={() =>
						navigation.navigate("SelectGameTagsScreen", {
							activeTags: selectedTags,
							sendToScreen: route.name,
						})
					}
				>
					<Ionicons
						name="md-pricetag"
						size={24}
						style={{ marginRight: 8 }}
						color={global.colors.textDefault}
					/>
					<AppText>{_("filter by tags")}</AppText>
				</TouchableOpacity>
			</View>
			{!!selectedTags.length && (
				<FlatList
					contentContainerStyle={{ marginTop: 8 }}
					style={{ marginBottom: 8 }}
					keyExtractor={idKeyExtractor}
					horizontal
					data={selectedTags}
					renderItem={({ item }) => (
						<TagItem
							tag={item}
							onPress={(tag) =>
								navigation.setParams({
									selectedTags: selectedTags
										.rg_removeElementPure((t) => t.id === tag.id)
										.map((t) => t.name),
								})
							}
							style={{ margin: 4 }}
						/>
					)}
				/>
			)}
			<AppActivityIndicator show={searching} style={{ marginBottom: 16 }} />
		</View>
	);

	const { onLayout } = useLayout();

	return (
		<>
			<AppScreenView borderless>
				<FlatList
					onLayout={onLayout}
					ref={flatlistRef}
					contentContainerStyle={ws({ paddingLeft: fullScreenLeftPadding() })}
					data={games}
					ListHeaderComponent={
						<>
							{!fromWizard && searchTopComponent}
							{listHeader}
						</>
					}
					keyExtractor={idKeyExtractor}
					renderItem={({ item }) => <GameCard game={item} large />}
					onEndReached={fetchNext}
					ListFooterComponent={
						fromWizard && !route.params?.allowLocked
							? () => (
									<View style={{ margin: 16 }}>
										<AppText centered>
											{_("There might be more results if you include password protected games")}
										</AppText>
										<AppButton
											title={_("Include locked games")}
											onPress={() => {
												navigation.setParams({ allowLocked: true });
											}}
											size={ws("wide")}
										/>
									</View>
							  )
							: null
					}
					ListEmptyComponent={
						searching ? null : (
							<EmptyScreenView
								messageBottom={_("No results", "no game match the search terms")}
								icon={{ type: RGIcon, name: "die" }}
								style={{ maxWidth: fullScreenContentWidth() }}
							/>
						)
					}
				/>
			</AppScreenView>
			{fromWizard && (
				<ButtonFooter
					buttonProps={[
						{ title: _("Change criteria", "join game wizard"), onPress: () => navigation.goBack() },
					]}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		games: state.games.browsed,
		user: state.user,
		gamesFound: state.games.browsedTotal,
		nextfetchurl: state.games.browsedGamesNextUrl,
		tags: state.gameTagsStore || {},
	};
};

export default connect(mapStateToProps)(BrowseGamesScreen);

const styles = StyleSheet.create({
	searchTopContainer: {
		padding: 8,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
});
