import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { setCommentMessage, setLanguage, setMessage, setTone } from "../../store/slices/gamesUISlice";
import { gameScreenPadding } from "../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../styles/theming";
import { translateTone } from "../../tools/tones";
import AppText from "../generic/AppText";
import AtAutocomplete from "./AtAutocomplete";
import EditMessageField from "./EditMessageField";
import SendMessageButton from "./SendMessageButton";
import TargetsBox from "./TargetsBox";

function ChatInput({
	show,
	isComments,
	style,
	messageType,
	dispatch,
	selectedTone,
	toneColorOverride,
	gameId,
	message,
	whisperToCharacterIds,
	whisperToPlayerIds,
	talkToCharacterIds,
	talkToPlayerIds,
	language,
	user,
}) {
	const styles = stylesMethod(global.theme);
	const [openedSubmenu, _setOpenedSubmenu] = React.useState(null);
	const [selection, setselection] = React.useState({ start: 0, end: 0 });
	const [focused, setfocused] = React.useState(false);

	const textInputRef = React.useRef();
	const sendButtonRef = React.useRef();
	const navigation = useNavigation();

	const autocompleteRef = React.useRef();

	const setMessageMethod = isComments ? setCommentMessage : setMessage;

	const { onLayout } = useLayout();

	// Event listener are not updated and must use refs to get the latest values
	const stateRef = React.useRef({
		showingToneSelection: openedSubmenu,
		whisperToCharacterIds,
		whisperToPlayerIds,
		talkToCharacterIds,
		talkToPlayerIds,
	});

	React.useEffect(() => {
		stateRef.current.showingToneSelection = openedSubmenu;
		stateRef.current.whisperToCharacterIds = whisperToCharacterIds;
		stateRef.current.whisperToPlayerIds = whisperToPlayerIds;
		stateRef.current.talkToCharacterIds = talkToCharacterIds;
		stateRef.current.talkToPlayerIds = talkToPlayerIds;
	}, [openedSubmenu, whisperToCharacterIds, whisperToPlayerIds, talkToCharacterIds, talkToPlayerIds]);

	React.useEffect(() => {
		const removeScreenFocusListener = navigation.addListener("focus", onScreenFocus);
		return () => {
			removeScreenFocusListener();
		};
	}, []);


	React.useEffect(() => {
		textInputRef.current?.focus();
	}, [messageType]);

	// Focus and Blur listeners =============
	const onScreenFocus = (e) => {
		// Timeout to go around the navigation bug where you cannot focus on an input immediately after focusing on a screen.
		setTimeout(() => {
			focusOnInputIfNeeded();
		}, 500);
	};

	const focusOnInputIfNeeded = () => {
		if (
			stateRef.current.whisperToCharacterIds?.length ||
			stateRef.current.whisperToPlayerIds?.length ||
			stateRef.current.talkToCharacterIds?.length ||
			stateRef.current.talkToPlayerIds?.length
		) {
			textInputRef.current?.focus();
		}
	};

	const handleKey = React.useCallback(
		(e) => {
			const {
				nativeEvent: { key: keyValue },
			} = e;

			autocompleteRef?.current?.handleKey(e);
			if (e.defaultPrevented) return;
			if (keyValue == "Enter") {
				if (e.shiftKey) return;
				e.preventDefault();
				sendButtonRef.current.sendMessage(messageType);
			}
		},
		[messageType]
	);

	if (!show) return null;

	const toneColor = !!toneColorOverride ? toneColorOverride : global.colors.toneColors[selectedTone];

	return (
		<View
			onLayout={onLayout}
			style={[
				styles.container,
				{
					left: gameScreenPadding() + 10,
					right: gameScreenPadding() + 30,
				},
				isComments && styles.containerComments,
				isComments && { paddingHorizontal: gameScreenPadding() },
				style,
			]}
		>
			{!isComments && (
				<>
					<TargetsBox gameId={gameId} />
					<AtAutocomplete
						autocompleteRef={autocompleteRef}
						setMessageMethod={setMessageMethod}
						text={message}
						focused={focused}
						selection={selection}
						setselection={setselection}
						textInputReference={textInputRef}
						showOnTop
						messageType={messageType}
					/>
				</>
			)}
			<View style={{ flexDirection: "row-reverse", justifyContent: "flex-end" }}>
				<AppText
					hide={!selectedTone || selectedTone === "neutral"}
					size="small"
					color={toneColor}
					style={[styles.inputInfoStyle, { color: toneColor }]}
					onPress={() => dispatch(setTone({ gameId, value: "neutral" }))}
				>
					{translateTone(selectedTone)}
				</AppText>

				<AppText
					hide={!language}
					size="small"
					color="primary"
					style={[styles.inputInfoStyle]}
					onPress={() => dispatch(setLanguage({ gameId, value: null }))}
				>
					{language?.name}
				</AppText>
			</View>
			<View style={styles.inputContainer}>
				<EditMessageField
					handleKey={handleKey}
					message={message}
					setMessageMethod={setMessageMethod}
					messageType={messageType}
					textInputRef={textInputRef}
					setselection={setselection}
					setfocused={setfocused}
					isComments={isComments}
				/>
				{/* {user && (user.is_staff || user.username === "OpenAI") && (
					<EnrichTextButton
						style={{ bottom: 6, marginRight: 12 }}
						navigation={navigation}
						messageType={messageType}
					/>
				)} */}
				<SendMessageButton
					ref={sendButtonRef}
					isComments={isComments}
					inputRef={textInputRef}
					messageType={messageType}
				/>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	const gameUI = state.gamesUI[gameId];

	if (!gameUI) {
		return { gameId };
	}

	return {
		currentCharacter: getCurrentCharacter(state),
		message: (ownProps.isComments ? gameUI.commentMessage : gameUI.message) || "",
		gameId,
		selectedTone: gameUI.tone,
		toneColorOverride: gameUI.toneColorOverride,
		whisperToCharacterIds: gameUI.whisperToCharacterIds,
		whisperToPlayerIds: gameUI.whisperToPlayerIds,
		talkToCharacterIds: gameUI.talkToCharacterIds,
		talkToPlayerIds: gameUI.talkToPlayerIds,
		languages: state.languages[gameId] || Array.rg_empty,
		language: gameUI.language,
		user: state.user,
		savedOutput: gameUI.enrichOutput,
		currentParty: state.parties[gameId]?.current,
	};
};

export default connect(mapStateToProps)(ChatInput);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flex: 1,
			position: "absolute",
			bottom: 52,
		},
		containerComments: {
			position: "relative",
			left: null,
			right: null,
			bottom: null,
			marginBottom: 24,
		},
		inputContainer: {
			flexDirection: "row",
			alignItems: "flex-end",
		},

		inputInfoStyle: {
			marginLeft: 8,
		},
	})
);
