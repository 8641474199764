import * as React from "react";
import { connect } from "react-redux";
import { fetchBookmark } from "../../store/slices/bookmarksSlice";
import { fetchGame, setCurrentGameId } from "../../store/slices/gamesSlice";
import { setPovCharacterId } from "../../store/slices/partiesSlice";
import { fetchStoryMarker } from "../../store/slices/storyMarkersSlice";
import { isInGame } from "../../tools/games";
import { openGameAtLine } from "../../tools/lines";
import { navReplace } from "../../tools/webnavigation";
import AppScreenView from "../AppScreenView";
import ErrorLoading from "../errors/ErrorLoading";
import FullScreenLoader from "../generic/FullScreenLoader";

function GameLoader(props) {
	if(!props.route.params) return null;
	let { route, navigation, dispatch, user, game, sendToGame = true } = props;	
	const [error, seterror] = React.useState(false);
	

	const { gameslug, sendToGame: sendToGameReceived } = route.params;

	if(sendToGameReceived) sendToGame = true;

	React.useEffect(() => {
		const getGame = async () => {
			const gameReceived = await dispatch(fetchGame(gameslug));
			if (!gameReceived) {
				seterror(true);
				return;
			}

			return gameReceived;
		};

		const openGame = async () => {
			let gameToOpen = game;
			if (gameslug && !gameToOpen) {
				gameToOpen = await getGame();
			}

			if(!gameToOpen) {
				seterror(true);
				return;
			}

			dispatch(setCurrentGameId(gameToOpen.id));

			const { scene } = route.params || {};

			if (scene) {
				const marker = await dispatch(fetchStoryMarker(gameToOpen.id, scene));
				if (marker.party) {
					const party = gameToOpen.parties.find((p) => p.id === marker.party);
					if (party) {
						await dispatch(setPovCharacterId({ gameId: marker.game, characterId: party.characters[0] }));
					}
				}
				openGameAtLine(gameToOpen, marker.start_line_id, marker.party, 0.5, dispatch, navigation, true);
				return;
			}


			if(!user && sendToGame){
				navReplace(navigation, "GameStack", { gameslug: gameToOpen.slug, screen: "Game" });
			}
			else if (sendToGame) {
				const bookmark = await dispatch(fetchBookmark(gameToOpen.id));

				if (bookmark && bookmark.line && !isInGame(user, gameToOpen)) {
					openGameAtLine(gameToOpen, bookmark.line?.id || 0, bookmark.line?.party, 0.2, dispatch, navigation, true);
				} else {
					const unreadLines = user.unread_lines.filter((l) => l.game === gameToOpen.id);
					const unreadGameLines = unreadLines.filter((l) => !l.is_comments);
					if (unreadGameLines?.length) {
						openGameAtLine(
							gameToOpen,
							unreadGameLines[0].id,
							unreadGameLines[0].party?.id || unreadGameLines[0].party,
							0.2,
							dispatch,
							navigation,
							true
						);
					} else {
						navReplace(navigation, "GameStack", { gameslug: gameToOpen.slug, screen: "Game" });
					}
				}
			}
		};

		openGame();
	}, [gameslug, game, user]);

	if (error) return <ErrorLoading />;

	return (
		<AppScreenView>
			<FullScreenLoader visible />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		game: state.games[ownProps.gameslug],
		user: state.user,
	};
};

export default connect(mapStateToProps)(GameLoader);
