import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { _ } from "../../../i18n/i18n";
import WizardScreen from "./WizardScreen";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import { ws } from "../../../tools/generic";

function WizardExpectedPace({ route, navigation }) {
	const paces = route.params?.paces || [];

	const data = [
		{ value: 0, title: _("Slow"), info: _("A message once a day or less", "pace description") },
		{ value: 1, title: _("Average"), info: _("Chat a few times throughout the day", "pace description") },
		{ value: 2, title: _("Fast"), info: _("Frequent 1+ hours of continuous chatting", "pace description") },
	];

	const togglePlace = React.useCallback(
		(value) => {
			if (paces.includes(value)) {
				navigation.setParams({ paces: paces.rg_removeSimple(value) });
			} else {
				navigation.setParams({ paces: paces.concat(value) });
			}
		},
		[paces]
	);

	return (
		<WizardScreen nextScreen="WizardAvoidTagsScreen">
			<AppText bold centered style={ws({}, { marginTop: 16 })}>
				{_("The expected paces I am looking for are")}
			</AppText>
			<AppText color="hint" centered style={{ marginTop: 16, marginBottom: 32 }}>
				{_("(You can select several options)")}
			</AppText>
			<FlatList
				style={{ flex: 1 }}
				contentContainerStyle={{ justifyContent: "center" }}
				keyExtractor={(item, index) => String(index)}
				data={data}
				renderItem={({ item }) => (
					<View style={{ flexDirection: "row", alignItems: "center", flex: 1, marginBottom: 16 }}>
						<AppButton
							style={{ flex: 1 }}
							title={item.title}
							isToggle
							toggled={paces.includes(item.value)}
							style={{ marginRight: 8 }}
							onPress={() => togglePlace(item.value)}
						/>
						<AppText style={{ flex: 1 }}>{item.info}</AppText>
					</View>
				)}
			/>
			<AppText color="hint" centered style={{ marginTop: 16, marginBottom: 32 }}>
				{_("N.B: The expected pace of a game can differ from its actual pace, showed in the game details.")}
			</AppText>
		</WizardScreen>
	);
}

export default WizardExpectedPace;


