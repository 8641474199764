import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchLookedForTags } from "../../store/slices/gameTagsSlice";
import AppText from "../generic/AppText";
import GameTagHorizontalList from "./GameTagHorizontalList";

function PopularTagsSection({ dispatch, lookedForTags }) {

	React.useEffect(() => {
		dispatch(fetchLookedForTags());
	}, []);

	const validTags = lookedForTags.filter((t) => t.alert_count > 0);

	if (!validTags.length) return null;

	return (
		<>
			<Divider theme={{ dark: true }} style={{ marginVertical: 32 }} />
			<View style={styles.container}>
				<AppText bold size="large" style={{ marginBottom: 8 }}>
					<MaterialCommunityIcons name="tag" size={18} />{" "}
					{_("People are actively looking for games with theses tags:")}
				</AppText>

				<View style={{ marginBottom: 16 }}>
					<GameTagHorizontalList tagsToShow={validTags} large tagSize="average" />
				</View>

				<AppText color="secondary" size="small">
					{_("This information is based on the number of players who asked to be notified when games using these tags are looking for players.")}
				</AppText>
			</View>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		lookedForTags: state.lookedForTags,
	};
};

export default connect(mapStateToProps)(PopularTagsSection);

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: 8,
		marginBottom: 16
	},
});
