import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import WizardAgeSelectionScreen from "./WizardAgeSelectionScreen";
import WizardTagSelectionScreen from "./WizardTagSelectionScreen";
import WizardNumPlayersScreen from "./WizardNumPlayersScreen";
import WizardExpectedPace from "./WizardExpectedPace";
import WizardAvoidTagsScreen from "./WizardAvoidTagsScreen";
import BrowseGamesScreen from "../BrowseGamesScreen";
import SelectGameTagsScreen from "../../gametags/SelectGameTagsScreen";
import { modalScreenOptions } from "../../../tools/navigationOptions";
import { _ } from "../../../i18n/i18n";
import { isWeb } from "../../../tools/generic";
const Stack = createStackNavigator();

function FindGameWizardStack() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen
				name="WizardTagSelectionScreen"
				component={WizardTagSelectionScreen}
				options={{ title: _("Find game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardAgeSelectionScreen"
				component={WizardAgeSelectionScreen}
				options={{ title: _("Find game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardNumPlayersScreen"
				component={WizardNumPlayersScreen}
				options={{ title: _("Find game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardExpectedPace"
				component={WizardExpectedPace}
				options={{ title: _("Find game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardAvoidTagsScreen"
				component={WizardAvoidTagsScreen}
				options={{ title: _("Find game | Role Gate") }}
			/>
			<Stack.Screen
				name="BrowseGamesScreen"
				component={BrowseGamesScreen}
				options={{ title: _("Find game | Role Gate") }}
			/>
			<Stack.Screen
				name="SelectGameTagsScreen"
				component={SelectGameTagsScreen}
				options={
					isWeb()
						? modalScreenOptions
						: ({ route, navigation }) => ({
								headerTitle: _("Select tags", "select game tags to filter"),
								title: "Role Gate",
						  })
				}
			/>
		</Stack.Navigator>
	);
}

export default FindGameWizardStack;
