import * as React from "react";
import { View, StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import { connect } from "react-redux";
import { HasActiveLegendaryShare } from "../../tools/account";

function UnlockedByShareInfo({ style, dispatch, user }) {
	const [shared] = React.useState(HasActiveLegendaryShare());

	if (!shared) return null;

	if (user.profile.reward_level >= 5) {
		return (
			<View style={[styles.container, style]}>
				<AppText size="small" style={{ color: global.colors.accountColors.legendaryDark }}>
					{_("All players in this game have access to this feature thanks to your Legendary subscription.")}
				</AppText>
			</View>
		);
	}
	if (user.profile.reward_level >= 2) return null;

	return (
		<View style={[styles.container, style]}>
			<AppText size="small" style={{ color: global.colors.accountColors.legendaryDark }}>
				{_("You have access to this feature because a player in your game has a Legendary account.")}
			</AppText>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(UnlockedByShareInfo);

const styles = StyleSheet.create({
	container: {},
});
