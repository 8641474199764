import * as React from "react";
import { View, ActivityIndicator } from "react-native";
import { colors } from "../../styles/colors";

function AppActivityIndicator(props) {
	const { show = true } = props;
	if (!show) {
		return false;
	}
	return <ActivityIndicator color={global.colors.hint} {...props}/>;
}

export default AppActivityIndicator;
