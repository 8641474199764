import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { connect } from "react-redux";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { setCurrentCharacterId } from "../../store/slices/gamesUISlice";
import { setPovCharacterId } from "../../store/slices/partiesSlice";
import { isGM, isPlayer } from "../../tools/games";
import { idEqual } from "../../tools/generic";
import { navPush } from "../../tools/webnavigation";
import CharacterItem from "./CharacterItem";
import { SELECT_CHARACTER_CHANGE_ACTIVE } from "./FilteredCharacterList";

function ChangeCurrentCharacterOption({ sendToScreen, dispatch, userId, gameId, currentCharacter, characters }) {
	const navigation = useNavigation();
	const route = useRoute();
	const characterSelected = React.useMemo(() => characters.rg_findById(route.params?.characterSelectedId), [
		route.params?.characterSelectedId,
		characters,
	]);

	React.useEffect(() => {
		const code = route.params?.characterSelectedCodeSent;

		if (code === SELECT_CHARACTER_CHANGE_ACTIVE && !idEqual(characterSelected, currentCharacter)) {
			navigation.setParams({ characterSelectedCodeSent: null });
			dispatch(setCurrentCharacterId({ gameId, value: characterSelected?.id }));
			if (isPlayer() && characterSelected) {
				dispatch(setPovCharacterId({ gameId, characterId: characterSelected.id }));
			}
		}
	}, [route.params, currentCharacter, gameId, characterSelected]);

	const props = {};

	const gm = isGM(gameId);
	let charactersAvailable = null;
	if (!gm) {
		charactersAvailable = characters.filter((c) => !c.deleted && c.player?.id === userId);
	}

	if (gm || charactersAvailable?.length > 1) {
		props.iconRightData = { type: MaterialIcons, name: "swap-horiz" };
		props.onPress = () => {
			navPush(navigation)("QuickCharacterSearchScreen", {
				sendToScreen: sendToScreen || route.name,
				charactersAvailable,
				showNarrator: gm,
				code: SELECT_CHARACTER_CHANGE_ACTIVE,
			});
		};
	}

	return <CharacterItem character={currentCharacter} {...props} showArrowsText />;
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		gameId,
		userId: state.user?.id,
		currentCharacter: getCurrentCharacter(state),
		characters: state.charactersByGame[gameId],
	};
};
export default connect(mapStateToProps)(ChangeCurrentCharacterOption);
