import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import { _ } from "../../i18n/i18n";

class CoinHelpModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.modalRef = React.createRef();
	}

	show() {
		this.modalRef.current.show();
	}

	render() {
		return (
			<BoxModal
				ref={this.modalRef}
				title={_("Coins", "title of the explanation regarding coins")}
				message={
					<>
						<AppText>
							{_(
								"Coins are a way for you to show appreciation towards other players and reward their content."
							)}
						</AppText>
						<AppText size="header3" color="primary" bold style={{ marginTop: 8 }}>
							{_("The Purse", "explaining what is the Purse")}
						</AppText>
						<AppText>
							{_("Your Purse represents the coins that are available for you to spend on other players.")}
						</AppText>
						<AppText style={{ marginTop: 8 }}>
							{_(
								"Make sure to spend it all: the Purse is refilled every month, when your subscription renews or the 1st of the month if you have a basic account!"
							)}
						</AppText>
						<AppText size="header3" color="primary" bold style={{ marginTop: 8 }}>
							{_("The Chest", "explaining what is the Chest")}
						</AppText>
						<AppText>
							{_(
								"Your Chest, on the other hand, represents the coins you received from other players. Those coins cannot be spent and are used to keep track of your reward history."
							)}
						</AppText>
						<AppText style={{ marginTop: 8 }}>
							{_(
								"In the future, you will be able to do something more with the coins from your chest, but this is still being worked on."
							)}
						</AppText>
					</>
				}
				options={[{ title: _("Got it!", "close info box") }]}
				wide
			/>
		);
	}
}

export default CoinHelpModal;
