import * as React from "react";
import { FlatList, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { clearNewLanguages, fetchLanguages } from "../../store/slices/languagesSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import EmptyScreenView from "../generic/EmptyScreenView";
import BoxModal from "../generic/modal/BoxModal";
import RGIcon from "../RGIcon";
import LanguageItem from "./LanguageItem";

function ManageLanguagesScreen({ route, navigation, dispatch, gameId, languages }) {
	const [loading, setloading] = React.useState(true);

	React.useEffect(() => {
		const removeScreenFocusListener = navigation.addListener("focus", () => dispatch(clearNewLanguages(gameId)));
		return () => {
			removeScreenFocusListener();
		};
	}, []);

	React.useEffect(() => {
		setloading(true);
		dispatch(fetchLanguages(gameId)).then(() => setloading(false));
	}, [gameId]);

	const createLanguage = React.useCallback(() => {
		const newLanguage = {
			name: null,
			game: gameId,
			fluents: [],
			intermediates: [],
			beginners: [],
		};

		navigation.navigate("EditLanguageScreen", { language: newLanguage });
	}, []);

	return (
		<AppScreenView borderless style={ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() })}>
			<FlatList
				contentContainerStyle={[{ paddingBottom: 64 }]}
				data={languages}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => <LanguageItem language={item} />}
				ListEmptyComponent={() => (
					<EmptyScreenView
						loading={loading}
						messageTop={_("This game doesn't have any fictional language yet.")}
						messageBottom={_(
							"Create fictional languages to have players only understand parts of messages based on the proficiency you define."
						)}
						icon={{ type: RGIcon, name: "language" }}
					/>
				)}
			/>
			<FAB icon="plus" style={globalStylesMethod(global.theme).fab} onPress={createLanguage} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.route.params.game.id;
	return {
		gameId,
		languages: state.languages[gameId] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(ManageLanguagesScreen);
