import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { _ } from "../../i18n/i18n";
import { getCharName } from "../../tools/characters";
import { cStyle } from "../../tools/react";
import AppText from "../generic/AppText";

function InteractiveSheetLabel({ labels = [], label, selected, updateLabel, onLabelClicked, character, id }) {
	const usedTargets = labels.rg_mapDistinct((l) => l.targetId).sort();

	const onPress = React.useCallback(() => {
		if (onLabelClicked) {
			onLabelClicked(label);
		}

		if (!updateLabel) {
			return;
		}

		const currentTargetId = label.targetId;
		const index = usedTargets.indexOf(currentTargetId);
		let newTargetId = 0;
		if (index > -1) {
			if (index === usedTargets.length - 1) {
				// If I cycle through all the index and I am not the only one using my current index,
				// Or if there are not label using a target
				// create a new one
				if (
					!usedTargets.some((id) => id !== 0) ||
					labels.filter((l) => l.targetId === label.targetId).length > 1
				) {
					newTargetId = usedTargets.rg_last(0) + 1;
				}
				// If I am the only one targeting this id, cycle back to 0
				else {
					newTargetId = 0;
				}
			} else {
				newTargetId = usedTargets[index + 1];
			}
		}
		updateLabel({ ...label, targetId: newTargetId });
	}, [usedTargets, labels, label, updateLabel, onLabelClicked]);

	let targetName =
		usedTargets.filter((id) => id !== 0).length > 1
			? _("Target %(target_number)s", "dice macro target", { target_number: label.targetId })
			: _("Target", "dice macro target");

	if (character) {
		targetName = getCharName(character);
	}

	if (selected) {
		targetName = null;
	}

	const hasTarget = label.targetId <= 0 || !!character;

	return (
		<TouchableOpacity
			onPress={onPress}
			avoidTouchable
			style={{ flexDirection: "row", flexWrap: "wrap", paddingHorizontal: 4 }}
		>
			<AppText
				size={hasTarget || updateLabel? null : "large"}
				color={hasTarget ? "attention" : "attentionImportant"}
				style={cStyle([[!hasTarget && !updateLabel, { marginTop: -4 }]])}
			>
				{label.value}
			</AppText>
			<AppText color={hasTarget ? "attention" : "attentionImportant"} hide={label.targetId <= 0 || !targetName}>
				{" "}
				({targetName})
			</AppText>
		</TouchableOpacity>
	);
}

export default InteractiveSheetLabel;


