import * as React from "react";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import DieFaceSymbol from "./DieFaceSymbol";

function DiceRollResults({ pool, details }) {
	let result = pool.final_result;

	if (!result) {
		result = pool.rolls[0].final_result;
	}

	const finalResult = typeof result === "string" ? JSON.parse(result) : result;

	if (typeof finalResult === "number") {
		return <AppText>{finalResult}</AppText>;
	}

	const resultComps = finalResult.map((res, index) => {
		if (typeof res === "object") {
			return [
				index > 0 ? <AppText key={index + "c"}>, </AppText> : null,
				<DieFaceSymbol key={index} symbol={res} hideName={details < 1} usedInText size={28} detailsOnPress />,
			];
		} else {
			if (res === "EMPTY") {
				res = <AppText>{_("Empty", "Die empty face")}</AppText>;
			}
			return [
				index > 0 ? <AppText key={index + "c"}>, </AppText> : null,
				<AppText key={index} color="attention" bold>
					{res}
				</AppText>,
			];
		}
	});
	return <AppText>{resultComps}</AppText>;
}

export default DiceRollResults;
