import * as React from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { connect } from "react-redux";
import { registerForPushNotificationsAsync } from "../../network/mobileNotifications";

function MobileNotificationsHandler({ dispatch, user }) {

	const registerToken = React.useCallback(async () => {
		const expoToken = await AsyncStorage.getItem("expoPushToken" + global.expoTokenVersion);
		if (!expoToken && global.ALLOW_NOTIFICATIONS) {
			registerForPushNotificationsAsync(user.id);
		}
	}, [user?.id]);


	React.useEffect(() => {
		if (user?.id) {
			registerToken();
		}
	}, [user?.id]);

	return null;
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(MobileNotificationsHandler);
