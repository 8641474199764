import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { idKeyExtractor } from "../../tools/generic";
import GameCard from "./GameCard";
import { _ } from "../../i18n/i18n";
import { connect } from 'react-redux';
import AppText from "../generic/AppText";
import HorizontalScroller from "../generic/HorizontalScroller";

function FeatureGamesList({ games }) {

	games = games.slice(1);
    
	return (
		<View style={{marginBottom: 16}}>
			<AppText bold style={{margin: 8, marginTop: 0}}>{_("More featured games")}</AppText>
			<HorizontalScroller
				data={games}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => <GameCard game={item} small />}
				ItemSeparatorComponent={() => <View style={{ marginRight: 8 }} />}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		games: state.games.featured || state.savedStore.games.featured,
	};
};

export default connect(mapStateToProps)(FeatureGamesList);


