import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCharName, idToCharacterState } from "../../tools/characters";
import { isGM } from "../../tools/games";
import { isOwn, isStory, shouldGroupLines } from "../../tools/lines";
import { translateTone } from "../../tools/tones";
import AppText from "../generic/AppText";

function LineInfo({ line, prevLine, isolated, dispatch, author, game, characters, characterStates }) {
	const characterState = idToCharacterState(line.character_state);
	let charName = getCharName(author, characterState?.name);

	const GM = isGM(game, line.user);
	if (line.user && !isStory(line)) {
		if (GM) {
			charName = _("GM", "indicates the game master");
		} else {
			const character = characters.find((c) => c.player?.id === line.user.id);
			charName = getCharName(character);
		}
	}

	const isStorytelling = (line.is_storytelling && !line.author) || (!line.author && !line.user);
	const tone = !isStorytelling && !line.is_description && line.tone && line.tone !== "neutral" ? line.tone : null;
	const toneColor = !!line.toneColorOverride ? line.toneColorOverride : global.colors.toneColors[tone];
	const isNPC = author && author.is_npc;
	const ownsLine = isOwn(line);	

	const groupWithPrevious = shouldGroupLines(prevLine, line);
	const shareToneWithPrevious = groupWithPrevious && prevLine.tone === tone && prevLine.is_chat === line.is_chat;

	const first = !groupWithPrevious;

	const components = [];

	if (line.user && !isStory(line) && first && !ownsLine) {
		components.push(
			<AppText key="username" size="small" color="secondary">
				{line.user.username}
				{!line.is_comment || charName ? " | " : ""}
			</AppText>
		);
	}

	if (charName && first && !(!isStory(line) && ownsLine)) {
		components.push(
			<AppText
				key="char"
				size={!isStory(line) ? "small" : "average"}
				hide={!charName}
				color={isNPC ? "textSecondary" : "secondary"}
				bold={GM &&  !isStory(line)}
			>
				{charName}{" "}
			</AppText>
		);
	}

	if (tone && !shareToneWithPrevious) {
		components.push(
			<AppText key="tone" size="small" style={{ color: toneColor }}>
				{translateTone(tone)}
			</AppText>
		);
	}

	if (!components.length) {
		return null;
	}

	return <AppText textOnlyStyle={[ownsLine && !isolated && { textAlign: "right" }]}>{components}</AppText>;
}

const mapStateToProps = (state, ownProps) => {
	const author = state.characters[ownProps.line.author];
	const characters = state.charactersByGame[ownProps.line.game] || Array.rg_empty;
	return {
		author,
		characters,
		game: state.games[ownProps.line.game] || state.savedStore.games[ownProps.line.game],
		characterStates: state.characterStates, // reference to sheet to rerender the line when the state is updated
		sheets: state.sheets // reference to sheet to rerender the line when the sheet is updated
	};
};

export default connect(mapStateToProps)(LineInfo);

const styles = StyleSheet.create({
});
