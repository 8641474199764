import * as React from "react";
import { StyleSheet, View } from "react-native";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import HelpButton from "../tutorials/HelpButton";

function ListCommandsHelpButton() {
	const helpMessage = (
		<View>
			<AppText bold>{_("How to write a valid command")}</AppText>
			<AppText style={{ marginBottom: 4 }}>
				{_("Type a simple text and surround your dice rolls with [[ ]]. For example:")}
			</AppText>
			<AppText italic>
				{_("attacks for [[1d20+STR]] and deals [[1d10+2d6]] damage", "example of a roll+ command")}
			</AppText>
			<AppText color="hint" style={{ marginTop: 4 }}>
				{_(
					"To use a value like STR, you must make sure you have a number field with such label on your character sheet.",
					"example of a roll+ command"
				)}
			</AppText>
			<AppText bold style={{ marginTop: 16 }}>
				{_("Available syntax", "roll+ list of valid syntax")}
			</AppText>

			<AppText bold>{_("Dice pool", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10+10d6]], [[1dDieName+5dDieName]]</AppText>
			<AppText bold>{_("Exploding dice", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[10d6!]]</AppText>
			<AppText bold>{_("Count successes above/below/equal X", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>{"[[10d6>x]] [[10d6<x]] [[10d6=x]]"}</AppText>
			<AppText bold>{_("Keep X highest", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10kx]]</AppText>
			<AppText bold>{_("Keep X lowest", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10klx]]</AppText>
			<AppText bold>{_("Drop X lowest", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10Dx]]</AppText>
			<AppText bold>{_("Drop X highest", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10Dhx]]</AppText>
			<AppText bold>{_("Reroll under/above X", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>{"[[2d10r<x]] [[2d10r>x]]"}</AppText>
			<AppText bold>{_("Reroll X", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10rx]]</AppText>
			<AppText bold>{_("Reroll under/above X once", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>{"[[2d10ro<x]] [[2d10ro>x]]"}</AppText>
			<AppText bold>{_("Reroll X once", "roll+ command example")}</AppText>
			<AppText style={styles.commandExample}>[[2d10rox]] [[2d10rox]]</AppText>
		</View>
	);

	return <HelpButton transparent message={helpMessage} />;
}

export default ListCommandsHelpButton;

const styles = StyleSheet.create({
	commandExample: {
		marginBottom: 8,
	},
});
