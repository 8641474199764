import * as React from "react";
import { View, StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import { isGM, isInGame } from "../../tools/games";
import { isWeb } from "../../tools/generic";
import Cond from "../meta/Cond";
import SendAlertButton from "../gamealerts/SendAlertButton";

function EmptyGameScreen({ game }) {
	if (isGM()) {
		return (
			<View style={styles.container}>
				<AppText style={{ marginBottom: 64 }} centered>
					{_("Welcome to your new game")}
				</AppText>
				<AppText color="hint" centered>
					{_("To start playing, simply write a message to describe the current situation to your players.")}
				</AppText>
				<AppText hide={isWeb()} color="hint" centered style={{ marginTop: 24 }}>
					{_("You can also talk with them out of character by tapping the send button, and picking 'Chat'.")}
				</AppText>
				<AppText hide={!isWeb()} color="hint" centered style={{ marginTop: 24 }}>
					{_(
						"You can also talk with the other players out of character by selecting the 'Chat' option below."
					)}
				</AppText>

				<SendAlertButton style={{marginTop: 64}} game={game}/>
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<AppText style={{ marginBottom: 64 }} centered>
				{_("Welcome to %(game_name)s", "", {
					game_name: (
						<AppText key="t" bold>
							{game.name}
						</AppText>
					),
				})}
			</AppText>
			<AppText color="hint" centered>
				{_("The game will start once the Narrator describes the current situation to players.")}
			</AppText>
			<AppText hide={!isInGame()} color="hint" centered style={{ marginTop: 24 }}>
				{_(
					"You will then write what your character does in reaction, using the field at the bottom of the screen."
				)}
			</AppText>
			<Cond show={isInGame()}>
				<AppText hide={isWeb()} color="hint" centered style={{ marginTop: 24 }}>
					{_(
						"You can also talk with the other players out of character by tapping the send button, and picking 'Chat'."
					)}
				</AppText>
				<AppText hide={!isWeb()} color="hint" centered style={{ marginTop: 24 }}>
					{_(
						"You can also talk with the other players out of character by selecting the 'Chat' option below."
					)}
				</AppText>
			</Cond>
		</View>
	);
}

export default EmptyGameScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		padding: 16,
	},
});
