import * as React from "react";
import { View, StyleSheet } from "react-native";
import ModalScreen from "../generic/modal/ModalScreen";
import FilteredCharacterList from "./FilteredCharacterList";

function SelectCharacterModal({ route, navigation }) {
	return (
		<ModalScreen borderless>
			<FilteredCharacterList />
		</ModalScreen>
	);
}

export default SelectCharacterModal;


