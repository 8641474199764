import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const avatarRequestSlice = createSlice({
	name: "avatarRequest",
	initialState: null,
	reducers: {
		receiveAvatarRequest: (state, { payload }) => payload,
	},
});

export const { receiveAvatarRequest } = avatarRequestSlice.actions;

export const fetchAvatarRequest = () => {
	return (dispatch, getState) =>
		apiFetch(`users/${getState().user.id}/avatar-requests/last`).then((data) =>
			dispatch(receiveAvatarRequest(data))
		);
};
