import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchTagGames } from "../../store/slices/gamesSlice";
import { BuildStyleMethod } from "../../styles/theming";
import { idKeyExtractor, isWeb, ws } from "../../tools/generic";
import GameCard from "../games/GameCard";
import AppText from "../generic/AppText";
import HorizontalScroller from "../generic/HorizontalScroller";

function GameTagPreview({ tag, autoLoad, onGamesLoaded, dispatch, games }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();

	const loadGames = React.useCallback(async () => {
		await dispatch(fetchTagGames(tag));
		onGamesLoaded();
	}, [tag]);

	React.useEffect(() => {
		if (autoLoad) {
			loadGames();
		}
	}, [loadGames, autoLoad]);

	let topBar = tag ? (
		<>
			<AppText bold>{tag.name}</AppText>
			<AppText
				style={{ textTransform: "uppercase", marginLeft: 32 }}
				color="url"
				bold
				onPress={() => navigation.navigate("BrowseGamesScreen", { selectedTags: [tag.name] })}
			>
				{_("View all", "view all games in a category")}
			</AppText>
		</>
	) : (
		<View style={{ height: 18, width: 100, backgroundColor: global.colors.secondary, opacity: 0.2 }} />
	);

	return (
		<View style={styles.container}>
			<View
				style={{
					flexDirection: "row",
					justifyContent: ws(null, "space-between"),
					marginLeft: 8,
					marginRight: 8,
					marginBottom: 8,
				}}
			>
				{topBar}
			</View>
			<HorizontalScroller
				style={{ paddingLeft: 8 }}
				horizontal
				data={games}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => <GameCard game={item} tagIdToHide={tag.id} />}
				ItemSeparatorComponent={() => <View style={{ marginRight: 8 }} />}
				showsHorizontalScrollIndicator={!isWeb()}
				ListEmptyComponent={
					<ScrollView horizontal>
						{[0, 1, 2, 3, 4, 5, 6].map((item) => (
							<View style={styles.temporaryGameCard} key={item} />
						))}
					</ScrollView>
				}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		games: state.games.byTagName[ownProps.tag?.name] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(GameTagPreview);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {},
		temporaryGameCard: {
			marginRight: 4,
			height: 180,
			width: 180,
			backgroundColor: colors.secondary,
			opacity: 0.2,
		},
	})
);
