import { _ } from "../i18n/i18n";
import store from "../store/store";
import { internalMatchAll, isStory } from "./lines";

export const gameHasNews = (game, user) => {
	if (!user || user.anonymous) return false;

	if (user.unread_lines.findIndex((l) => l.game === game.id) > -1) {
		return true;
	}
	return false;
};

export const getCoverUrl = (game) => {
	const bgName = game?.cover_background ? game.cover_background.name : "neutral_cover_1";
	return `${global.root_url}/static/images/stagebg/bg_${bgName}.png`;
};

export const isMainGM = (game, user) => {
	const state = store.getState();
	user = user || state.user;
	game = game || state.games[state.games.currentId];
	if (!user) return false;

	if (typeof game === "number") {
		game = state.games[game];
	}

	if (!game) {
		return false;
	}

	return game.game_master.id === user.id;
};


export const isAssistantGM = (game, user) => {
	const state = store.getState();
	user = user || state.user;
	game = game || state.games[state.games.currentId];
	if (!user) return false;

	if (typeof game === "number") {
		game = state.games[game];
	}

	if (!game) {
		return false;
	}

	return game.assistants.includes(user.id);
};


export const isGM = (game, user) => {
	const state = store.getState();
	user = user || state.user;
	game = game || state.games[state.games.currentId];
	if (!user) return false;

	if (typeof game === "number") {
		game = state.games[game];
	}

	if (!game) {
		return false;
	}

	return game.game_master.id === user.id || game.assistants.includes(user.id);
};

export const isInGame = (user, game) => {
	const state = store.getState();
	user = user || state.user;

	if (game && typeof game === "number") {
		game = state.games[game];
	}

	game = game || state.games[state.games.currentId];

	if (!user) return false;
	if (!game) return false;
	if (!game.players.length) return isGM(game, user);

	if (typeof game.players[0] === "number") {
		return game.players.some((playerId) => playerId === user.id) || isGM(game, user);
	}
	return game.players.some((player) => player.id === user.id) || isGM(game, user);
};

export const isPlayer = (game, user) => {
	const state = store.getState();
	user = user || state.user;
	game = game || state.games[state.games.currentId];
	return isInGame(user, game) && !isGM(game, user);
};

export const getPaceText = (paceInteger, noTranslation) => {
	switch (paceInteger) {
		case 1:
			return noTranslation ? "Average" : _("Average", "game settings - pace");
		case 2:
			return noTranslation ? "Fast" : _("Fast", "game settings - pace");
		default:
			return noTranslation ? "Slow" : _("Slow", "game settings - pace");
	}
};

export const gameHasUnloadedNext = (state, gameId) => {
	const boundaries = state.gameLinesBoundaries[gameId];
	let lastLineId = boundaries?.lastLineId;
	const lines = state.lines[gameId];

	return lastLineId && lines?.rg_last()?.id < lastLineId;
};

// Only chat for now, because doing it with story will be more costly
export const hasUnreadTaggedChat = (game, user) => {
	const unreadInGame = user.unread_lines.filter((l) => l.game === game.id);
	return unreadInGame.some((line) => {
		if (!line.content || typeof line.content !== "string") return false;

		const matches = internalMatchAll(line.content, RegExp("@.*?#(\\d+)", "g"));
		let taggedIds = matches.map((match) => Number(match[1]));

		if (!isStory(line)) {
			for (let i = 0; i < taggedIds.length; ++i) {
				let id = taggedIds[i];
				if (id === user.id) {
					return true;
				}
			}
		}
		return false;
	});
};