import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const languagesSlice = createSlice({
	name: "languagesSlice",
	// { gameId: []}
	initialState: {},
	reducers: {
		receiveLanguages: (state, { payload: { gameId, languages } }) => {
			state[gameId] = (state[gameId] || []).rg_overlapById(languages);
		},
		removeLanguage: (state, { payload: language }) => {
			state[language.game] = state[language.game].filter((l) => l.id !== language.id);
		},
		clearNewLanguages: (state, { payload: gameId }) => {
			if (state[gameId]) {
				state[gameId] = state[gameId].filter((l) => !!l.id);
			}
		},
	},
});

export const { receiveLanguages, removeLanguage, clearNewLanguages } = languagesSlice.actions;

export function fetchLanguages(gameId, characterId) {
	return async (dispatch, getState) => {
		let url = `games/${gameId}/languages`;
		if (characterId) {
			url = `games/${gameId}/characters/${characterId}/languages`;
		}

		return apiFetch(url).then(({ results }) => {
			dispatch(receiveLanguages({ languages: results, gameId }));
		});
	};
}
