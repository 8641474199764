import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import {
	attachHelperToGame,
	attachHelperToHome,
	detachHelperFromGame,
	detachHelperFromHome,
	editActiveHelper,
	favoriteHelper,
	setOpenedHelper,
	unfavoriteHelper,
	deleteInteractiveHelper,
	receiveEditedHelper,
} from "../../store/slices/interactiveHelpersSlice";
import CoinValue from "../coins/CoinValue";
import ListButton from "../generic/buttons/ListButton";
import { useRoute, useNavigation } from "@react-navigation/native";
import BoxModal from "../generic/modal/BoxModal";
import AppText from "../generic/AppText";
import { apiFetch } from "../../network/apiFetch";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function InteractiveHelperItem({
	navigationState,
	navigation,
	interactiveHelper,
	wide,
	narrow,
	dispatch,
	user,
	currentGame,
	favorite,
	activeHelpers,
}) {
	navigation = navigation || useNavigation();
	const route = navigationState ? null : useRoute();

	const confirmDeleteModalRef = React.useRef();
	const descriptionModalRef = React.useRef();

	const madeVisibleRef = React.useRef();
	const madePrivatelRef = React.useRef();

	const options = [];

	const isMine = user?.id === interactiveHelper.creator.id;

	const activeHelperData = activeHelpers.find((ah) => ah.helper.id === interactiveHelper.id);

	const isAttached = !!activeHelperData;
	const isPrivate = !activeHelperData?.public;

	const deleteHelper = React.useCallback(() => {
		dispatch(deleteInteractiveHelper(interactiveHelper));
	}, [interactiveHelper]);

	const toggleShare = React.useCallback(() => {
		apiFetch(`users/${user.id}/helpers/${interactiveHelper.id}`, "PATCH", {
			is_public: !interactiveHelper.is_public,
		}).then((helper) => {
			if (helper.is_public) madeVisibleRef.current.show();
			else madePrivatelRef.current.show();

			dispatch(receiveEditedHelper(helper));
		});
	}, [user.id, interactiveHelper]);

	if (user) {
		isMine &&
			options.push({
				title: _("Edit"),
				onPress: () =>
					navigation.navigate("InteractiveHelpersStack", {
						screen: "EditInteractiveHelperScreen",
						params: { helperId: interactiveHelper.id },
					}),
			});

		isMine &&
			options.push({
				title: interactiveHelper.is_public
					? _("Make private", "stop sharing interactive helper")
					: _("Share to community", "share interactive helper"),
				onPress: toggleShare,
			});

		!isAttached &&
			!currentGame &&
			options.push({
				title: _("Add to home screen", "add interactive helper to home screen"),
				onPress: () => dispatch(attachHelperToHome(user.id, interactiveHelper)),
			});

		isAttached &&
			!currentGame &&
			options.push({
				title: _("Remove from home screen", "remove interactive helper to home screen"),
				onPress: () => dispatch(detachHelperFromHome(user.id, interactiveHelper)),
			});

		!isAttached &&
			currentGame &&
			options.push({
				title: _("Add to game (for me only)", "add interactive helper to game"),
				onPress: () => dispatch(attachHelperToGame(user.id, currentGame.id, interactiveHelper, false)),
			});

		!isAttached &&
			currentGame &&
			options.push({
				title: _("Add to game (for everyone)", "add interactive helper to game"),
				onPress: () => dispatch(attachHelperToGame(user.id, currentGame.id, interactiveHelper, true)),
			});

		isAttached &&
			currentGame &&
			isPrivate &&
			options.push({
				title: _("Make visible to all in game", "make interactive helper public in game"),
				onPress: () => dispatch(editActiveHelper(activeHelperData, { public: true })),
			});

		isAttached &&
			currentGame &&
			!isPrivate &&
			activeHelperData.user === user.id &&
			options.push({
				title: _("Make only visible to me", "make interactive helper private in game"),
				onPress: () => dispatch(editActiveHelper(activeHelperData, { public: false })),
			});

		isAttached &&
			currentGame &&
			options.push({
				title: _("Remove from game", "remove interactive helper from game"),
				onPress: () => dispatch(detachHelperFromGame(user.id, currentGame.id, interactiveHelper)),
			});

		!isMine &&
			!favorite &&
			options.push({
				title: _("Favorite", "option to favorite an interactive helper"),
				onPress: () => dispatch(favoriteHelper(interactiveHelper)),
			});

		!isMine &&
			favorite &&
			options.push({
				title: _("Un-favorite", "option to unfavorite an interactive helper"),
				onPress: () => dispatch(unfavoriteHelper(interactiveHelper)),
			});

		!isMine &&
			options.push({
				title: _("Give coin"),
				onPress: () => {
					navigation.navigate({
						name: "GiveCoin",
						params: {
							previousScreenName: navigationState?.routes[navigationState.index].name || route?.name,
							subjectGetter: { helper_id: interactiveHelper.id },
						},
					});
				},
			});

		isMine &&
			options.push({
				danger: true,
				title: _("Delete"),
				onPress: () => confirmDeleteModalRef.current.show(),
			});
	}

	return (
		<>
			<ListButton
				wide={wide}
				narrow={narrow}
				options={options}
				title={interactiveHelper.name}
				iconLeft={{ type: MaterialCommunityIcons, name: "paperclip" }}
				subiconLeft={isMine && interactiveHelper.is_public && { type: MaterialIcons, name: "share" }}
				subtitle={_("by %(username)s", "interactive helper creator", {
					username: interactiveHelper.creator.username,
				})}
				subtitle2={
					<CoinValue
						subject={interactiveHelper}
						subjectGetter={{ helper_id: interactiveHelper.id }}
						navigation={navigation}
					/>
				}
				onPress={async () => {
					await dispatch(setOpenedHelper(null)); // set it to null first to trigger an opening of the drawer;
					dispatch(setOpenedHelper(interactiveHelper));
				}}
				onLongPress={() => descriptionModalRef.current?.show()}
			/>

			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete panel")}
				message={
					<AppText>
						{_(
							"Delete the interactive helper %(helper_name)s? You cannot revert this action and will lose all of its content.",
							"delete interactive helper",
							{
								helper_name: (
									<AppText key="helper_name" bold>
										{interactiveHelper.name}
									</AppText>
								),
							}
						)}
					</AppText>
				}
				isDelete
				onConfirm={deleteHelper}
			/>

			<BoxModal
				ref={descriptionModalRef}
				title={interactiveHelper.name}
				message={
					<AppText color={interactiveHelper.description ? null : "secondary"}>
						{interactiveHelper.description || _("No description")}
					</AppText>
				}
				options={[{ title: _("Got it!", "close info box") }]}
			/>

			<TinyFeedbackModal ref={madeVisibleRef} message={_("Now visible to community!", "confirm shared")} />
			<TinyFeedbackModal ref={madePrivatelRef} message={_("Removed from community", "confirm un-shared")} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const currentGame = state.games[state.games.currentId];
	return {
		user: state.user,
		currentGame,
		favorite: state.interactiveHelpers.favorites.some((h) => h.id === ownProps.interactiveHelper.id),
		activeHelpers: currentGame
			? state.interactiveHelpers.byGames[currentGame?.id] || Array.rg_empty
			: state.interactiveHelpers.homeScreen,
	};
};

export default connect(mapStateToProps)(InteractiveHelperItem);
