import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _, _n } from "../../i18n/i18n";
import { fetchSheetValuePairsIfNeeded } from "../../store/slices/sheetValuePairsSlice";
import { getCharacterSheetValuePairs, idToCharacter } from "../../tools/characters";
import { getCancelledResults, getPools, isChildOf } from "../../tools/diceRoll";
import AppText from "../generic/AppText";
import DiceRoll from "./DiceRoll";
import DiceRollResults from "./DiceRollResults";
import RollModifiers from "./RollModifiers";

function DiceCommandResult({
	command,
	pools: poolsReceived,
	targets = [],
	author = null,
	details,
	parentPool,
	line,
	characterName,
	dispatch,
	dice,
}) {
	command = command || line.content;
	author = author || (line && line.author ? idToCharacter(line.author) : null);

	if (author && !getCharacterSheetValuePairs(author) && author.sheet) {
		dispatch(fetchSheetValuePairsIfNeeded(author.sheet));
	}

	const pools = React.useMemo(
		() => poolsReceived || line?.pools || getPools(command, targets, author),
		[poolsReceived, command, targets, author]
	);

	let actionDesArray;

	if (!command) {
		actionDesArray = [_("rolls ", "[character] rolls [dice pool]")];
		for (let p = 0; p < pools.length; ++p) {
			let pool = pools[p];
			const innerPools = pools.filter((p) => isChildOf(p, pool));
			actionDesArray.push(<DicePool key={p} pool={pool} details={details} innerPools={innerPools} line={line} />);
		}
	} else {
		actionDesArray = command.split(/\[\[|]]/g).filter((part) => !!part);
		for (let p = 0; p < pools.length; ++p) {
			for (let i = 0; i < actionDesArray.length; ++i) {
				let pool = pools[p];

				if (pool.parent_pool && !isChildOf(pool, parentPool)) {
					continue;
				}

				let part = actionDesArray[i];
				if (!(typeof part === "string")) {
					continue;
				}

				if (!pool.command_match || pool.command_match.startsWith("[[")) {
					part = `[[${part}]]`;
				}

				if (part.trim() === pool.command_match.trim() || part.trim() === "[[DICE_THROW_MATCH]]") {
					const innerPools = pools.filter((p) => isChildOf(p, pool));
					actionDesArray[i] = (
						<DicePool key={p} pool={pool} details={details} innerPools={innerPools} line={line} />
					);
					break;
				}
			}
		}
	}

	for (let i = 0; i < actionDesArray.length; ++i) {
		if (typeof actionDesArray[i] === "string") {
			// const des = applyMarkdowns(actionDesArray[i]);
			const des = actionDesArray[i];
			actionDesArray[i] = <AppText key={"des" + i}>{des}</AppText>;
		}
	}

	if (parentPool) return actionDesArray;

	return actionDesArray;
}

const mapStateToProps = (state, ownProps) => {
	return {
		dice: state.dice, // added to refresh when dice are refreshed
	};
};

DiceCommandResult = connect(mapStateToProps)(DiceCommandResult);

export default DiceCommandResult;

const dicePoolstyles = StyleSheet.create({
	success: {},

	failure: {},
});

// Put the component in the same folder, to avoid a "Require cycle" issue with DiceCommandResult importing DicePools that imports DiceCommandResult
function DicePool({ pool, details, line, innerPools }) {
	if (innerPools.length) {
		let command = pool.command_match;

		// Replace inner pool commands with [[command]] starting with the longest ones
		for (let i = 0, nextIndex = 0; i < innerPools.length; ++i) {
			let innerPool = innerPools[i];
			const index = command.indexOf(innerPool.command_match, nextIndex);
			command = command.rg_replaceFrom(innerPool.command_match, `[[${innerPool.command_match}]]`, index);
			nextIndex = index + innerPool.command_match.length + 4;
		}

		let isSuccess = false;
		if (pool.final_result) {
			const results = typeof pool.final_result === "string" ? JSON.parse(pool.final_result) : pool.final_result;
			isSuccess = results[0] === "success";
		}

		return (
			<AppText>
				<DiceCommandResult
					command={command}
					pools={innerPools}
					details={details}
					parentPool={pool}
					line={line}
				/>
				<AppText
					styles={isSuccess ? dicePoolstyles.success : dicePoolstyles.failure}
					color={isSuccess ? "success" : "failure"}
				>
					{" "}
					[{isSuccess ? _("Success", "dice roll successes") : _("Failure", "dice roll successes")}]
				</AppText>
			</AppText>
		);
	}

	if (!pool.rolls || pool.error) {
		return <AppText color="danger">[[{pool.error}]]</AppText>;
	}

	let successes = null;

	pool.rolls.forEach((r) => {
		if (r.successes !== null) {
			successes = successes || 0;
			successes += r.successes;
		}
	});

	if ((!pool.final_result || pool.final_result === "0") && pool.rolls.length === 1) {
		pool = { ...pool };
		pool.final_result = pool.rolls[0].final_result;
	}

	const showResult = successes === null || details > 1;

	const cancelledResults = getCancelledResults(pool.rolls, pool.final_result);

	const positiveRolls = pool.rolls.filter((r) => !r.is_subtract);
	const negativeRolls = pool.rolls.filter((r) => r.is_subtract);

	return (
		<AppText>
			{positiveRolls.map((r, index) => (
				<DiceRoll details={details} cancelledResults={cancelledResults} roll={r} key={index} index={index} />
			))}
			<RollModifiers isFirstInLine={!positiveRolls.length} pool={pool} line={line} details={details} />			
			{negativeRolls.map((r, index) => (
				<DiceRoll
					details={details}
					cancelledResults={cancelledResults}
					roll={r}
					key={index}
					index={index}
				/>
			))}
			<AppText hide={!showResult}>
				<AppText> = </AppText>
				<AppText>
					<DiceRollResults pool={pool} details={details} />
				</AppText>
			</AppText>
			{successes !== null && (
				<AppText color={successes > 0 ? "success" : "danger"}>
					[
					{_n("%(count)s Success", "%(count)s Successes", "dice roll successes", successes, {
						count: successes,
					})}
					]
				</AppText>
			)}
		</AppText>
	);
}
