import moment from "moment";
import { apiFetch } from "../network/apiFetch";
import { clearLines, fetchLines } from "../store/slices/linesSlice";
import store from "../store/store";
import { idToCharacter, idToCharacterState, getCharacterStateThen } from "./characters";
import { isGM, isInGame } from "./games";
import { idEqual } from "./generic";
import { navReplace } from "./webnavigation";

export function isStory(line) {
	return !line.is_chat && !line.is_comment;
}

export function shouldGroupLines(lineA, lineB) {
	if (!lineA) {
		return false;
	}

	if ((lineA.is_chat && lineB.is_chat) || (lineA.is_comment && lineB.is_comment)) {
		return idEqual(lineA.user, lineB.user);
	}

	if (!!lineA.is_chat !== !!lineB.is_chat) {
		return false;
	}

	// make sure to use ==, so that undefined is equal to null. Otherwise it won't work
	return lineA.author == lineB.author && lineA.action == lineB.action;
}

export const getLineAvatar = (line) => {
	if (!line.author) {
		return null;
	}

	return {
		...idToCharacter(line.author),
		...idToCharacterState(line.character_state),
		id: line.author,
	};
};

export const findTaggedIds = (content, isChat, gameId) => {
	if (!content) return [];
	const matches = internalMatchAll(content, RegExp("@.*?#(\\d+)", "g"));

	let taggedIds = matches.map((match) => Number(match[1]));

	const state = store.getState();

	const game = isChat ? state.games[gameId] : null;

	taggedIds = taggedIds.filter((taggedId) => {
		if (isChat) {
			return game.game_master.id === taggedId || game.players.some((p) => p.id === Number(taggedId));
		} else {
			return state.charactersByGame[gameId]?.some((c) => c.id === Number(taggedId));
		}
	});
	return taggedIds;
};

export const findTargets = (content, isChat, gameId) => {
	if (!content) return [];
	const match = content.match(/^(@.*?#\d+\s*)*/);
	if (match) {
		return findTaggedIds(match[0], isChat, gameId);
	}

	return [];
};

export const tagIdToName = (tagId, line, users) => {
	if (isStory(line)) {
		const character = idToCharacter(tagId, line.game);
		if (!character) {
			return "";
		}

		const state = getCharacterStateThen(line, character);
		return state.name;
	} else {
		const player = users[tagId];
		if (!player) {
			return "";
		}
		return player.username;
	}
};

export const internalMatchAll = (string, regExp) => {
	const matches = [];
	let array1;
	while ((array1 = regExp.exec(string)) !== null) {
		matches.push(array1);
	}
	return matches;
};

export const isOwn = (line) => {
	const state = store.getState();
	const user = state.user;
	const author = state.characters[line.author];
	const isNPC = author && author.is_npc;
	const GM = isGM(line.game, user);
	return (
		(user && (idEqual(user, line.user) || (author && author.player?.id === user.id))) ||
		(isNPC && GM) ||
		(line.is_storytelling && GM) ||
		(!line.author && !line.user && GM)
	);
};

export const hasUnsavedLines = (gameId) => {
	const state = store.getState();
	const lines = state.lines[gameId];

	return lines.some((l) => !l.id);
};

export function getLinesDisplayed(state, gameId) {
	const gameUI = state.gamesUI[gameId] || {};
	const linesDisplayed = gameUI.linesDisplayed;

	let lines = [];
	if (linesDisplayed === "story") {
		lines = state.storyLines[gameId];
	} else if (linesDisplayed === "chat") {
		lines = state.chatLines[gameId];
	} else {
		lines = state.lines[gameId];
	}

	lines = lines || Array.rg_empty;

	return lines;
}

let lastKeyCreated = 0;

export function GenerateDefaultLine(gameId) {
	return {
		key: `userCreated_${lastKeyCreated++}`,
		game: gameId,
		content: null,
		tone: "neutral",
		toneColorOverride: null,
		created: moment().format(),
		beginner_speakers: [],
		intermediate_speakers: [],
		fluent_speakers: [],
		user: null,
		author: null,
		action: null,
		tagged_users: [],
		tagged_characters: [],
	};
}

export async function openGameAtLine(game, lineId, partyId, linePosition, dispatch, navigation, doReplace) {
	await dispatch(clearLines(game.id));

	const state = store.getState();
	let openedParties = state.parties[game.id]?.openedParties;
	// If the party we are looking for is not already opened...
	if (partyId && (!openedParties || !openedParties.some((p) => p.id === partyId))) {
		openedParties = [partyId];
	} else {
		openedParties = openedParties?.map((p) => p.id) || [];
	}

	const params = { "opened-parties": openedParties };
	if (!lineId) params.from = 0;
	else params.around = lineId;

	if (!isInGame(null, game)) {
		params.is_chat = false;
		apiFetch(`games/${game.id}/open`);
	}

	await dispatch(fetchLines(game.id, params));

	// Send the game, in case we are not coming from the Game screen (i.e: coming from the game details panel, notifications)
	const navigateParams = { lineToReach: lineId, party: partyId, linePosition };

	if (doReplace) {
		navReplace(navigation, "GameStack", { gameslug: game.slug, screen: "Game", params: navigateParams });
	} else {
		navigation.navigate("GameStack", { gameslug: game.slug, screen: "Game", params: navigateParams });
	}
}

export function findIndexOfIdClosestTo(idToReach, orderedLinesToCheck) {
	let smallestDelta = Math.abs(orderedLinesToCheck[0].id - idToReach);

	for (let i = 0; i < orderedLinesToCheck.length; i++) {
		let newDelta = Math.abs(orderedLinesToCheck[i].id - idToReach);
		if (newDelta <= smallestDelta) {
			smallestDelta = newDelta;
		} else {
			return i;
		}
	}

	return -1;
}
