import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import AppScreenView from "../AppScreenView";
import CharacterItem from "./CharacterItem";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import FilterTagButton from "../tags/FilterTagButton";
import { _ } from "../../i18n/i18n";
import { createCharacter, fetchCharacters } from "../../store/slices/charactersSlice";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { isGM } from "../../tools/games";
import { colors } from "../../styles/colors";
import { ws } from "../../tools/generic";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { ScreenToURL } from "../../tools/navigationConfig";
import { fetchCharacterStates } from "../../store/slices/characterStatesSlice";
import { fetchGameUsers } from "../../store/slices/usersSlice";
import { fetchGameSheets } from "../../store/slices/sheetsSlice";

function ManageCharactersScreen({ route, navigation, dispatch, game, gameId, charactersReceived, characterTags }) {
	const { selectedTag: receivedSelectedTag } = route.params;

	const [fabGroupOpened, setFabGroupedOpened] = React.useState(false);

	const confirmCreateModalRef = React.useRef();

	React.useEffect(() => {
		const doFetch = async () => {
			if (!charactersReceived) {
				await dispatch(fetchGameSheets(gameId));
				dispatch(fetchCharacters(gameId));
			}
		};

		doFetch();
	}, [charactersReceived, gameId]);

	const characters = React.useMemo(() => {
		if (!charactersReceived) return [];
		let result = charactersReceived.filter((c) => !c.deleted);

		let selectedTag = null;
		if (receivedSelectedTag) selectedTag = characterTags.find((t) => t.id === receivedSelectedTag.id);
		if (selectedTag) {
			result = chararesultcters.filter((c) => selectedTag.characters.includes(c.id));
		}

		result.sort((a, b) => {
			if (a.is_npc === b.is_npc) return 0;
			if (!a.is_npc && b.is_npc) return -1;
			if (a.is_npc && !b.is_npc) return 1;
		});

		return result;
	}, [charactersReceived]);

	const gm = isGM(gameId);

	return (
		<AppScreenView borderless style={ws({ paddingLeft: fullScreenLeftPadding() })}>
			{!!characterTags.length && gm && <FilterTagButton gameId={gameId} />}
			<FlatList
				contentContainerStyle={ws({ maxWidth: fullScreenContentWidth() })}
				data={characters}
				// Weird key needed to force redraw for everyone when any of the character item moves.
				// Important to update the itemseparatorcomponent
				keyExtractor={(item, index) => String(item.id + "" + index)}
				renderItem={({ item, index, separators }) => (
					<CharacterItem
						character={item}
						index={index}
						separators={separators}
						showOptions
						onPress={() =>
							navigation.navigate("CharacterSheetStackGame", {
								screen: "CharacterSheetScreen",
								params: { characterId: item?.id },
							})
						}
						to={ws(
							"/" +
								game.slug +
								ScreenToURL("CharacterSheetStackGame") +
								"/" +
								item?.id +
								ScreenToURL("CharacterSheetScreen")
						)}
					/>
				)}
				ItemSeparatorComponent={({ leadingItem, trailingItem }) => {
					if (!trailingItem) {
						return null;
					}

					if (leadingItem.is_npc && !trailingItem.is_npc) {
						return <AppText style={styles.title}>{_("Players", "manage characters screen")}</AppText>;
					}
					if (!leadingItem.is_npc && trailingItem.is_npc) {
						return (
							<AppText style={styles.title}>
								{_("Non-player characters", "manage characters screen")}
							</AppText>
						);
					}
					return null;
				}}
				ListHeaderComponent={() => {
					if (characters.rg_first()?.is_npc)
						return (
							<AppText style={styles.title}>
								{_("Non-player characters", "manage characters screen")}
							</AppText>
						);
					return <AppText style={styles.title}>{_("Players", "manage characters screen")}</AppText>;
				}}
				ListFooterComponent={() => {
					// Add something at the bottom to make sure we can scroll past the filter button and the add character FAB at the bottom
					if (gm) {
						return <View style={{ height: 64 }} />;
					}
					return null;
				}}
			/>

			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create NPC")}
				message={_("Create a new Non-player character?")}
				onConfirm={() => {
					dispatch(createCharacter(gameId));
				}}
			/>

			{gm && !characterTags.length && (
				<FAB
					icon="plus"
					style={globalStylesMethod(global.theme).fab}
					onPress={() => confirmCreateModalRef.current.show()}
				/>
			)}
			{gm && !!characterTags.length && (
				<FAB.Group
					style={{ paddingBottom: 64 }}
					fabStyle={{ backgroundColor: global.colors.primary }}
					open={fabGroupOpened}
					icon={fabGroupOpened ? "dots-vertical" : "dots-horizontal"}
					actions={[
						{
							icon: "plus",
							label: _("Create NPC"),
							onPress: () => confirmCreateModalRef.current.show(),
						},
						{
							icon: "eye-off",
							label: _("Select tags to hide..."),
							onPress: () =>
								navigation.navigate("CharactersScreenModalStack", {
									screen: "ManageTagVisibilityModal",
									params: { gameId },
								}),
						},
					]}
					onStateChange={({ open }) => setFabGroupedOpened(open)}
				/>
			)}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[ownProps.route.params.gameslug];

	return {
		game,
		gameId: game.id,
		charactersReceived: state.charactersByGame[game.id],
		characterTags: state.characterTags[game.id] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(ManageCharactersScreen);

const styles = StyleSheet.create({
	container: {},
	title: { marginLeft: 8, marginVertical: 8 },
});
