import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { colors } from "../../styles/colors";

import { isWeb, ws } from "../../tools/generic";
import IconButton from "./buttons/IconButton";

function HorizontalScroller({ data, keyExtractor, renderItem, ItemSeparatorComponent, ListEmptyComponent, style, containerStyle }) {
	const [hasOverflow, sethasOverflow] = React.useState(false);
	const flastList = React.useRef();

	const [currentOffset, setcurrentOffset] = React.useState(0);
	const [maxOffset, setmaxOffset] = React.useState(0);
	const [itemWidth, setitemWidth] = React.useState(0);
	const [contentWidth, setcontentWidth] = React.useState();
	const [layoutWidth, setlayoutWidth] = React.useState();

	const flatListLayout = (e) => {
		const { x, y, width, height } = e.nativeEvent.layout;
		setlayoutWidth(width);
	};

	const onContentSizeChange = React.useCallback(
		(contentWidth, contentHeight) => {
			setcontentWidth(contentWidth);
		},
		[data.length]
	);

	React.useEffect(() => {
		const maxOffset = contentWidth - layoutWidth || 0;
		setitemWidth(contentWidth / data.length);
		setmaxOffset(maxOffset);
	}, [contentWidth, layoutWidth]);

	React.useEffect(() => {
		if (contentWidth > layoutWidth && !hasOverflow) {
			sethasOverflow(true);
		} else if (contentWidth < layoutWidth && hasOverflow) {
			sethasOverflow(false);
		}
	}, [contentWidth, layoutWidth, hasOverflow]);

	const goNext = React.useCallback(() => {
		const newOffset = Math.min(maxOffset, currentOffset + itemWidth);
		flastList.current.scrollToOffset({ animated: true, offset: newOffset });
		setcurrentOffset(newOffset);
	}, [currentOffset, itemWidth, maxOffset]);

	const goPrevious = React.useCallback(() => {
		const newOffset = Math.max(0, currentOffset - itemWidth);
		flastList.current.scrollToOffset({ animated: true, offset: newOffset });
		setcurrentOffset(newOffset);
	}, [currentOffset, itemWidth, maxOffset]);

	const flatListComponent = (
		<FlatList
			ref={flastList}
			onLayout={flatListLayout}
			style={[style, ws(null, { paddingLeft: 8 })]}
			horizontal
			data={data}
			keyExtractor={keyExtractor}
			showsHorizontalScrollIndicator={false}
			renderItem={renderItem}
			ItemSeparatorComponent={ItemSeparatorComponent}
			ListEmptyComponent={ListEmptyComponent}
			onContentSizeChange={onContentSizeChange}
		/>
	);

	if (!isWeb()) return flatListComponent;

		

	const showArrowLeft = currentOffset > 0 && hasOverflow;
	const showArrowRight = currentOffset < maxOffset && hasOverflow;

	return (
		<View style={[containerStyle, styles.container]}>
			<View style={[styles.arrowContainer, { marginRight: -16 }]} pointerEvents={showArrowLeft ? "auto" : "none"}>
				<IconButton
					icon={{ type: MaterialIcons, name: "chevron-left", color: global.colors.textLight }}
					onPress={goPrevious}
					style={[{ opacity: showArrowLeft ? 1 : 0, elevation: 1 }, styles.arrow, { marginLeft: -24 }]}
				/>
			</View>
			{flatListComponent}
			<View
				style={[styles.arrowContainer, , { marginLeft: -16 }]}
				pointerEvents={showArrowRight ? "auto" : "none"}
			>
				<IconButton
					icon={{ type: MaterialIcons, name: "chevron-right", color: global.colors.textLight }}
					onPress={goNext}
					style={[{ opacity: showArrowRight ? 1 : 0 }, styles.arrow, { marginRight: -24 }]}
				/>
			</View>
		</View>
	);
}

export default HorizontalScroller;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
	},
	arrowContainer: {
		justifyContent: "center",
		alignItems: "flex-start",
		zIndex: 1,
	},
	arrow: {},
});
