import * as React from "react";
import { View, StyleSheet } from "react-native";
import IconButton from "../generic/buttons/IconButton";
import Popover from "../generic/popover/Popover";
import RGIcon from "../RGIcon";
import LanguageSelection from "./LanguageSelection";
import { _ } from "../../i18n/i18n";
import { colors } from "../../styles/colors";

function ChangeLanguageButton({ disabled, dark }) {
	const popoverRef = React.useRef();

	return (
		<Popover
			title={_("Select language")}
			ref={popoverRef}
			arrowPosition="bottom"
			popoverComponent={<LanguageSelection show onLanguageSelected={() => popoverRef.current?.hide()} />}
		>
			<IconButton
				disabled={disabled}
				icon={{
					type: RGIcon,
					name: "language",
					color: dark ? global.colors.textDark : global.colors.textLight,
				}}
				transparent
				style={{ height: 24 }}
				onPress={() => popoverRef.current?.toggle()}
			/>
		</Popover>
	);
}

export default ChangeLanguageButton;


