import { Entypo, FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import {
	setCurrentCharacterId,
	setTalkToCharacterIds,
	setWhisperToCharacterIds,
} from "../../store/slices/gamesUISlice";
import { setPovCharacterId } from "../../store/slices/partiesSlice";
import { fetchSheet } from "../../store/slices/sheetsSlice";
import { isGM, isInGame, isPlayer } from "../../tools/games";
import { idEqual } from "../../tools/generic";
import ModalScreen from "../generic/modal/ModalScreen";
import RGTokenIcon from "../RGTokenIcon";
import ChangeCurrentCharacterOption from "./ChangeCurrentCharacterOption";
import CharacterItem from "./CharacterItem";

function CharacterOptionsModal({
	route,
	navigation,
	dispatch,
	gameId,
	user,
	currentCharacter,
	characters,
	character,
	sheet,
}) {
	let { line, isPreview } = route.params;

	React.useEffect(() => {
		if (!sheet && character?.sheet) {
			dispatch(fetchSheet(character.sheet));
		}
	}, [sheet, character?.sheet]);

	const gm = !isPreview && isGM();
	const inGame = !isPreview && isInGame();

	const isCurrent = idEqual(currentCharacter, character);

	const options = [];
	!isPreview &&
		inGame &&
		character &&
		(gm || character.player?.id === user.id) &&
		currentCharacter !== character &&
		options.push({
			title: _("Act as", "change the current character to this one"),
			icon: { type: MaterialIcons, name: "swap-horiz" },
			onPress: async () => {
				// On firefox, the app will get unmounted if you navigate while some components are getting updated
				// through the redux store. So make sure to await for the end of the update
				await dispatch(setCurrentCharacterId({ gameId: character.game, value: [character.id] }));
				if (isPlayer()) {
					await dispatch(setPovCharacterId({ gameId, characterId: character.id }));
				}
				navigation.navigate("Game");
			},
		});
	!isPreview &&
		inGame &&
		!isCurrent &&
		character &&
		options.push({
			title: _("Talk to", "talk to character"),
			icon: { type: Entypo, name: "chat" },
			onPress: () => {
				dispatch(setTalkToCharacterIds({ gameId: character.game, value: [character.id] }));
				navigation.navigate("Game");
			},
		});
	!isPreview &&
		inGame &&
		character &&
		options.push({
			title: _("Whisper to", "whisper to character"),
			icon: { type: FontAwesome, name: "user-secret" },
			onPress: () => {
				dispatch(setWhisperToCharacterIds({ gameId: character.game, value: [character.id] }));
				navigation.navigate("Game");
			},
		});
	!isPreview &&
		character &&
		inGame &&
		options.push({
			title: _("Tokens", "manage tokens on character"),
			icon: { type: RGTokenIcon, name: "tk_roc" },
			onPress: () => {
				navigation.goBack();
				setTimeout(() => {
					navigation.navigate("ManageTokensScreen", { character, line });
				}, 100);
			},
		});

	(gm || !character?.is_npc) &&
		character &&
		options.push({
			title: _("Open character sheet"),
			icon: { type: MaterialCommunityIcons, name: "file-account" },
			disabled: !sheet,
			onPress: () => {
				// There is a bug on React navigation 6. If you navigate to a different stack from a modal
				// when you go back, the modal will "crash" and you won't be able to open any modal.
				// To work around that, we close the modal before navigation.
				navigation.goBack();
				setTimeout(() => {
					navigation.navigate("CharacterSheetStackGame", {
						screen: "CharacterSheetScreen",
						params: { characterId: character.id, gameId: character.game },
					});
				}, 100);
			},
		});

	character &&
		options.push({
			title: _("%(username)s's profile", "open a user profile", { username: character.player?.username || _("[deleted]", "delete username") }),
			icon: { type: MaterialIcons, name: "person" },
			onPress: () => character.player ? navigation.navigate("ProfilePage", { username: character.player?.username }) : null,
			to: `/me/${character.player?.username}`,
		});

	return (
		<ModalScreen options={options} borderless>
			{(((gm && !character) || idEqual(character, currentCharacter)) && (
				<ChangeCurrentCharacterOption sendToScreen="Game" />
			)) || <CharacterItem character={character} />}
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const characters = state.characters;
	let { characterId, characterSelectedId } = ownProps.route.params;
	const character = characters[characterId] || characters[characterSelectedId];
	return {
		gameId: state.games.currentId,
		currentCharacter: getCurrentCharacter(state),
		characters,
		character,
		sheet: state.sheets[character?.sheet],
		user: state.user,
	};
};

export default connect(mapStateToProps)(CharacterOptionsModal);
