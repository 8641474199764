import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
// @react-native-community/clipboard getString doesn't work.
// Returns Cannot read property "getString" of undefined
//import Clipboard from "@react-native-community/clipboard";
// import { Clipboard } from "react-native";
import * as Clipboard from 'expo-clipboard';
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import {
	getCurrentGameUI,
	setEditingLine,
	setEditMessage,
	setLinesDisplayed,
	setTalkToCharacterIds,
	setWhisperingTo,
	setWhisperToPlayerIds,
} from "../../store/slices/gamesUISlice";
import { fetchGameBoundaries, fetchPublicChatBoundaries, removeLines } from "../../store/slices/linesSlice";
import { addTemporaryStoryMarker } from "../../store/slices/storyMarkersSlice";
import { isGM, isInGame } from "../../tools/games";
import { isWeb } from "../../tools/generic";
import { isOwn, isStory } from "../../tools/lines";
import { SELECT_CHARACTERS_WHISPER } from "../characters/SelectCharactersModal";
import CoinGivenFeedback from "../coins/CoinGivenFeedback";
import CoinIcon from "../coins/CoinIcon";
import BoxModal from "../generic/modal/BoxModal";
import ModalScreen from "../generic/modal/ModalScreen";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import IsolatedLine from "./IsolatedLine";

function LineOptionsModal({ route, navigation, dispatch, user, currentStoryMarker, linesDisplayed, characters }) {
	const { line, isPreview, charactersSelectedCodeSent, charactersSelected } = route.params;

	const action = React.useMemo(() => JSON.parse(line.action), [line.action]);

	const copiedModalRef = React.useRef(null);
	const confirmDeleteModalRef = React.useRef(null);

	const gm = isGM(line.game, user);
	const inGame = isInGame(user, line.game);
	const owned = isOwn(line);

	React.useEffect(() => {
		if (charactersSelectedCodeSent === SELECT_CHARACTERS_WHISPER) {
			navigation.setParams({ charactersSelected: null, charactersSelectedCodeSent: null });

			apiFetch(`games/${line.game}/lines/${line.id}/update-whispers`, "PATCH", {
				targets: charactersSelected.map((c) => c.id),
			});

			navigation.navigate("Game");
		}
	}, [line, charactersSelected, charactersSelectedCodeSent]);

	const changeTo = React.useCallback(
		(newType) => {
			apiFetch(`games/${line.game}/lines/${line.id}`, "PATCH", { type: newType }).then((lineReceived) => {
				dispatch(receiveLines({ lines: [lineReceived], gameId: line.game }));
			});
			navigation.goBack();
		},
		[line]
	);

	const options = [];

	!owned &&
		user &&
		isStory(line) &&
		options.push({
			title: _("Give coin"),
			iconComponent: <CoinIcon level={1} />,
			onPress: () => {
				navigation.navigate({
					name: "GiveCoin",
					params: { previousScreenName: route.name, subjectGetter: { line_id: line.id } },
					merge: true,
				});
			},
		});

	line.content &&
		options.push({
			title: _("Copy", "copy to clipboard"),
			icon: { type: MaterialCommunityIcons, name: "content-copy" },
			onPress: () => {
				Clipboard.setStringAsync(line.content);
				copiedModalRef.current.show();
			},
		});

	!isPreview &&
		!line.action &&
		((gm && isStory(line)) || owned) &&
		options.push({
			title: _("Edit content", "edit line"),
			icon: { type: MaterialCommunityIcons, name: "pencil" },
			onPress: () => {
				if (!isWeb()) {
					dispatch(setTalkToCharacterIds({ gameId: line.game, value: [] }));
					dispatch(setWhisperToPlayerIds({ gameId: line.game, value: [] }));
					dispatch(setWhisperingTo({ gameId: line.game, value: [] }));
				}
				dispatch(setEditingLine({ gameId: line.game, value: line }));
				dispatch(setEditMessage({ gameId: line.game, value: line.content }));
				navigation.navigate(line.is_comment ? "PublicChatScreen" : "Game");
			},
		});

	!isPreview &&
		!line.action &&
		((gm && isStory(line)) || owned) &&
		options.push({
			title: _("Edit other...", "edit line (everything but content)"),
			icon: { type: MaterialCommunityIcons, name: "pencil" },
			onPress: () => {
				navigation.navigate("LineEditModal", { line });
			},
		});

	const whisperedTarget = line.whispered_to.map((id) => characters[id]).filter((c) => c);

	!isPreview &&
		((gm && isStory(line)) || owned) &&
		options.push({
			title: _("Whisper to...", "edit line"),
			icon: { type: FontAwesome, name: "user-secret" },
			onPress: () => {
				navigation.navigate({
					name: "SelectTargets",
					params: {
						gameId: line.game,
						charactersSelected: whisperedTarget,
						goToScreen: "LineOptionsModal",
						allowEmpty: true,
						code: SELECT_CHARACTERS_WHISPER,
					},
					merge: true,
				});
			},
		});

	!isPreview &&
		gm &&
		isStory(line) &&
		options.push({
			title: _("Add story marker"),
			icon: { type: MaterialCommunityIcons, name: "book" },
			onPress: () => {
				const newMarker = {
					id: "new",
					game: line.game,
					start_line_id: line.id,
					party: line.party,
				};
				dispatch(addTemporaryStoryMarker(newMarker));
				navigation.navigate("StoryMarkerModalStack", {
					screen: "EditStoryMarkerModal",
					params: { markerId: newMarker.id, backToScreen: "Game" },
				});
			},
		});

	!isPreview &&
		isStory(line) &&
		(gm || owned) &&
		action?.type == "image" &&
		options.push({
			title: _("Move to out of character chat", "move a line from story to OOC"),
			icon: { type: MaterialCommunityIcons, name: "arrow-right-bold" },
			onPress: () => changeTo("chat"),
		});

	!isPreview &&
		owned &&
		!isStory(line) &&
		action?.type == "image" &&
		options.push({
			title: _("Move to story", "move a line from OOC to story"),
			icon: { type: MaterialCommunityIcons, name: "arrow-left-bold" },
			onPress: () => changeTo("description"),
		});

	(gm || (owned && (!action || action.type == "image"))) &&
		options.push({
			title: _("Delete"),
			onPress: () => {
				confirmDeleteModalRef.current.show();
			},
			icon: { type: MaterialIcons, name: "delete" },
		});

	if (!isPreview && inGame) {
		if (linesDisplayed == "mixed") {
			if (isStory(line)) {
				options.push({
					title: _("Hide story messages"),
					icon: { type: MaterialCommunityIcons, name: "eye-off" },
					onPress: () => {
						dispatch(setLinesDisplayed({ gameId: line.game, value: "chat" }));
						navigation.goBack();
					},
				});
			} else if (line.is_chat) {
				options.push({
					title: _("Hide out of character messages"),
					icon: { type: MaterialCommunityIcons, name: "eye-off" },
					onPress: () => {
						dispatch(setLinesDisplayed({ gameId: line.game, value: "story" }));
						navigation.goBack();
					},
				});
			}
		} else {
			options.push({
				title: isStory(line) ? _("Unhide chat messages") : _("Unhide story messages"),
				icon: { type: MaterialCommunityIcons, name: "eye" },
				onPress: () => {
					dispatch(setLinesDisplayed({ gameId: line.game, value: "mixed" }));
					navigation.goBack();
				},
			});
		}
	}

	return (
		<ModalScreen options={options}>
			<IsolatedLine line={line} isPreview={isPreview} />
			<TinyFeedbackModal ref={copiedModalRef} message={_("Copied to clipboard!")} />
			<CoinGivenFeedback />
			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete Line")}
				message={_("Are you sure you want to delete this line?")}
				isDelete
				onConfirm={() => {
					const sceneId = currentStoryMarker?.id || null;
					dispatch(removeLines({ gameId: line.game, lines: [line] }));
					navigation.goBack();

					apiFetch(`games/${line.game}/lines/${line.id}`, "DELETE", {
						scene: sceneId,
					}).then(() => {
						const storyOnly = !isInGame();
						dispatch(
							line.is_comment
								? fetchPublicChatBoundaries(line.game)
								: fetchGameBoundaries(line.game, storyOnly)
						);
					});
				}}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameUI = getCurrentGameUI(state);
	return {
		user: state.user,
		currentStoryMarker: gameUI.currentStoryMarker,
		linesDisplayed: gameUI.linesDisplayed || "mixed",
		characters: state.characters,
	};
};

export default connect(mapStateToProps)(LineOptionsModal);
