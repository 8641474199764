import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { apiFetch } from "../../network/apiFetch";
import { formatJSDateToDjangoDate } from "../../tools/dates";
import { receiveCharacters } from "./charactersSlice";
import { setLoadingMoreLines } from "./gamesUISlice";

function prepareForGame({ lines, gameId }) {
	lines = lines.rg_sortById();
	const mixed = [];
	const storyLines = [];
	const chatLines = [];
	const commentLines = [];
	for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		if (line.is_comment) {
			commentLines.push(line);
		} else if (line.is_chat) {
			chatLines.push(line);
			mixed.push(line);
		} else {
			storyLines.push(line);
			mixed.push(line);
		}
	}
	return { payload: { gameId, lines, mixed, storyLines, chatLines, commentLines } };
}

function getStoreLinesFunction(type) {
	return (state, { payload: { gameId, lines, mixed, storyLines, chatLines, commentLines } }) => {
		let gameLines = state[gameId] || [];
		let linesToStore = lines;
		if (type === "mixed") linesToStore = mixed;
		else if (type === "story") linesToStore = storyLines;
		else if (type === "chat") linesToStore = chatLines;
		else if (type === "comments") linesToStore = commentLines;

		state[gameId] = gameLines.rg_overlapById(linesToStore, true).rg_sortById();
	};
}

function replaceLineFunction(state, { payload: { gameId, lineToReplace, newLine } }) {
	let gameLines = state[gameId] || [];
	const lineAsString = JSON.stringify(lineToReplace);
	const index = gameLines.findIndex((l) => lineAsString === JSON.stringify(l));

	if (index >= 0) {
		if (!lineToReplace.id && gameLines.some((l) => l.id === newLine.id)) {
			gameLines = gameLines.rg_removeAtPure(index);
		} else {
			const line = { ...newLine, key: gameLines[index].key };
			gameLines[index] = line;
		}
		state[gameId] = gameLines;
	}
}

function removeLinesFunction(state, { payload: { gameId, lines } }) {
	let gameLines = state[gameId] || [];
	const ids = lines.map((l) => l.id);
	state[gameId] = gameLines.rg_removeAllElements((el) => ids.includes(el.id));
}

export const linesSlice = createSlice({
	name: "lines",
	// {gameId: [lines], ...}
	initialState: {},
	reducers: {
		receiveLines: {
			prepare: prepareForGame,
			reducer: getStoreLinesFunction("mixed"),
		},
		removeLines: {
			prepare: prepareForGame,
			reducer: removeLinesFunction,
		},
		replaceLine: {
			prepare(lineToReplace, newLine) {
				const gameId = lineToReplace.game;
				return { payload: { gameId, lineToReplace, newLine } };
			},
			reducer: replaceLineFunction,
		},
		clearLines: (state, { payload: gameId }) => {
			state[gameId] = [];
		},
	},
});

export const { receiveLines, replaceLine, removeLines, clearLines } = linesSlice.actions;

export const storyLinesSlice = createSlice({
	name: "storyLines",
	initialState: {},
	extraReducers: {
		[linesSlice.actions.receiveLines]: getStoreLinesFunction("story"),
		[linesSlice.actions.removeLines]: removeLinesFunction,
		[linesSlice.actions.replaceLine]: replaceLineFunction,
		[linesSlice.actions.clearLines]: (state, { payload: gameId }) => {
			state[gameId] = [];
		},
	},
});

export const chatLinesSlice = createSlice({
	name: "chatLines",
	initialState: {},
	extraReducers: {
		[linesSlice.actions.receiveLines]: getStoreLinesFunction("chat"),
		[linesSlice.actions.removeLines]: removeLinesFunction,
		[linesSlice.actions.replaceLine]: replaceLineFunction,
		[linesSlice.actions.clearLines]: (state, { payload: gameId }) => {
			state[gameId] = [];
		},
	},
});

export const commentLinesSlice = createSlice({
	name: "commentLines",
	initialState: {},
	extraReducers: {
		[linesSlice.actions.receiveLines]: getStoreLinesFunction("comments"),
		[linesSlice.actions.removeLines]: removeLinesFunction,
		[linesSlice.actions.replaceLine]: replaceLineFunction,
		[linesSlice.actions.clearLines]: (state, { payload: gameId }) => {
			state[gameId] = [];
		},
	},
});

export const gameLinesBoundaries = createSlice({
	name: "gameLinesBoundaries",
	// { gameId: {firstLineId, lastLineId, firstCommentId, lastCommentId}}
	initialState: {},
	reducers: {
		setBoundaries: (state, { payload: { gameId, firstLineId, lastLineId, firstCommentId, lastCommentId } }) => {
			state[gameId] = state[gameId] || {};
			if (firstLineId !== undefined) state[gameId].firstLineId = firstLineId;
			if (lastLineId !== undefined) state[gameId].lastLineId = lastLineId;
			if (firstCommentId !== undefined) state[gameId].firstCommentId = firstCommentId;
			if (lastCommentId !== undefined) state[gameId].lastCommentId = lastCommentId;
			state[gameId].shouldReload = false;
		},
	},
	extraReducers: {
		[linesSlice.actions.receiveLines]: (state, { payload: { gameId, lines, storyLines, chatLines } }) => {
			const first = lines.rg_first();
			const last = lines.rg_last();
			const newState = state[gameId] || {};

			if (first?.is_comment) {
				if (first && newState?.firstCommentId > first.id) {
					newState.firstCommentId = first.id;
				}
				if (last && newState?.lastCommentId < last?.id) {
					newState.lastCommentId = last.id;
				}
			} else {
				if (first && newState?.firstLineId > first.id) {
					newState.firstLineId = first.id;
				}
				if (last && newState?.lastLineId < last?.id) {
					newState.lastLineId = last.id;
				}
			}
			state[gameId] = newState;
		},
		[linesSlice.actions.removeLines]: (state, { payload: { gameId, lines } }) => {
			if (!state[gameId]) return state;

			const gameState = state[gameId];

			if (lines.length && lines[0].is_comment) {
				if (lines.some((l) => l.id <= gameState.firstCommentId || l.id >= gameState.lastCommentId)) {
					state[gameId] = { firstCommentId: null, lastCommentId: null };
				}
			} else {
				if (lines.some((l) => l.id <= gameState.firstLineId || l.id >= gameState.lastLineId)) {
					state[gameId] = { ...state[gameId], shouldReload: true };
				}
			}
		},
	},
});

export const { setBoundaries } = gameLinesBoundaries.actions;

export function fetchPreviousLines(gameId, storyOnly, publicChat) {
	return async (dispatch, getState) => {
		const state = getState();
		const lines = state.lines[gameId];
		const openedParties = state.parties[gameId]?.openedParties;
		const params = { to: lines.rg_first()?.id || "last", "opened-parties": (openedParties || []).map((p) => p.id) };
		if (storyOnly) params.is_chat = false;

		return dispatch(fetchLines(gameId, params, publicChat)).then(() =>
			dispatch(setLoadingMoreLines({ gameId, value: false }))
		);
	};
}

export function fetchNextLines(gameId, storyOnly, publicChat) {
	return async (dispatch, getState) => {
		const state = getState();
		const lines = state.lines[gameId];
		const openedParties = state.parties[gameId]?.openedParties;

		let from = 0;
		if (lines.length > 0) {
			from = lines.filter((l) => !!l.id).rg_last()?.id || 0;
		}

		const params = { from, "opened-parties": (openedParties || []).map((p) => p.id) };
		if (storyOnly) params.is_chat = false;

		return dispatch(fetchLines(gameId, params, publicChat)).then(() =>
			dispatch(setLoadingMoreLines({ gameId, value: false }))
		);
	};
}

export function fetchLines(gameId, opts, publicChat) {
	let { from, to, around, pageSize } = opts;
	opts.is_comment = !!publicChat;
	pageSize = pageSize || 30;

	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/lines`, "GET", { ...opts, "page-size": pageSize }).then(({ results }) => {
			return dispatch(receiveLines({ lines: results, gameId }));
		});
	};
}

export function fetchLastLines(gameId, publicChat) {
	return async (dispatch, getState) => {
		const state = getState();
		const openedParties = state.parties[gameId]?.openedParties;
		const params = { to: "last", "opened-parties": (openedParties || []).map((p) => p.id) };

		return dispatch(fetchLines(gameId, params, publicChat)).then(() =>
			dispatch(setLoadingMoreLines({ gameId, value: false }))
		);
	};
}

export function fetchLine(gameId, lineId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/lines/${lineId}`)
			.then((line) => {
				dispatch(receiveLines({ lines: [line], gameId }));
			})
			.catch(() => {});
	};
}

export function sendLine(line) {
	return async (dispatch, getState) => {
		dispatch(receiveLines({ lines: [line], gameId: line.game }));

		// Convert from actual line to post data
		let linePostData = {
			...line,
			character_state: null,
			user: line.user ? line.user.id : null,
		};

		return apiFetch(`games/${line.game}/lines`, "POST", linePostData).then((receivedLine) => {
			dispatch(replaceLine(line, receivedLine));

			// Watch out: send line is usually immediately followed but a navigation.navigate call.
			// Make sure the below code is not called while navigating, as it will cause a unmounting of the app on Firefox
			// this is why this code is currently within the apiFetch response
			if (line.author) {
				let character = getState().characters[line.author];
				character = { ...character, last_used: formatJSDateToDjangoDate(moment()) };
				dispatch(receiveCharacters({ gameId: line.game, characters: [character] }));
			}
		});
	};
}

export function saveLineEdit(line, newData) {
	return async (dispatch, getState) => {
		dispatch(replaceLine(line, { ...line, ...newData }));
		return apiFetch(`games/${line.game}/lines/${line.id}`, "PATCH", newData);
	};
}

export function fetchGameBoundaries(gameId, storyOnly, filteredParties) {
	return async (dispatch, getState) => {
		const params = {};
		if (filteredParties) params["filtered-parties"] = filteredParties;
		if (storyOnly) params.story = true;

		return apiFetch(`games/${gameId}/lines/game-boundaries`, "GET", params).then((response) => {
			dispatch(setBoundaries({ gameId, firstLineId: response.first_id, lastLineId: response.last_id }));
		});
	};
}

export function fetchPublicChatBoundaries(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/lines/public-chat-boundaries`).then((response) => {
			dispatch(setBoundaries({ gameId, firstCommentId: response.first_id, lastCommentId: response.last_id }));
		});
	};
}
