import * as React from "react";
import { View } from "react-native";
import { isWeb, ws } from "../../tools/generic";
import AppText from "./AppText";

function AppIcon({ type, name, size, color, text, style }) {
	size = size || ws(22, 32);
	if (!text && (!type || !name)) {
		return null;
	}

	if (text) {
		return (
			<View style={style}>
				<AppText style={{ fontSize: size, color }}>{text}</AppText>
			</View>
		);
	}

	const IconTag = type;
	return <IconTag name={name} size={size} color={color} style={style} />;
}

export default AppIcon;
