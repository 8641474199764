import * as React from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import AppScreenView from "../AppScreenView";
import HorizontalTabs from "../generic/HorizontalTabs";
import { _ } from "../../i18n/i18n";
import CharacterAvatar from "./CharacterAvatar";
import ColorSelector from "../generic/ColorSelector";
import AppText from "../generic/AppText";
import CoinIcon from "../coins/CoinIcon";
import { colors } from "../../styles/colors";
import { connect } from "react-redux";
import { HasLevelOrShare, levelToRankName, levelToRankShort } from "../../tools/account";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import BoxModal from "../generic/modal/BoxModal";
import AppButton from "../generic/buttons/AppButton";
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { apiFetch } from "../../network/apiFetch";
import { fetchCharacterStates } from "../../store/slices/characterStatesSlice";
import { receiveCharacters } from "../../store/slices/charactersSlice";
import CondView from "../meta/CondView";
import { globalStyles } from "../../styles/global";
import { fetchAvatarRequest } from "../../store/slices/avatarRequestSlice";
import { updateAvatar } from "../../store/slices/avatarsSlice";
import LockedFeatureModal from "../profile/LockedFeatureModal";
import IconButton from "../generic/buttons/IconButton";
import { isWeb, ws } from "../../tools/generic";
import { ActivityIndicator } from "react-native";
import { fullScreenContentWidth, sheetPadding } from "../../styles/dynamicStyles";
import UnlockedByShareInfo from "../account/UnlockedByShareInfo";
import Cond from "../meta/Cond";

function ChangeAvatarScreen({ route, navigation, dispatch, user, avatars, avatarRequest }) {
	const { character, previousScreenName } = route.params;

	const [selectedTabId, setselectedTabId] = React.useState("avatar");
	const [avatarColor, setavatarColor] = React.useState(character.color);
	const [shieldColor, setshieldColor] = React.useState(character.background_color);
	const [coinSelected, setcoinSelected] = React.useState(1);
	const [avatarIdSelected, setavatarIdSelected] = React.useState(character.portrait_avatar);
	const [backgroundKeySelected, setbackgroundKeySelected] = React.useState(character.background_key);
	const [backgroundRankSelected, setbackgroundRankSelected] = React.useState(character.background_rank);
	const [optimizationHide, setoptimizationHide] = React.useState(!isWeb());

	const lockedRef = React.useRef();
	const confirmAvatarChangeRef = React.useRef();
	const scrollViewRef = React.useRef();

	const displayedTabs = [
		{ id: "avatar", title: _("Avatar", "edit avatar screen") },
		!global.appleStoreReview && { id: "shield", title: _("Shield", "edit avatar screen") },
	];

	const avatarSize = 128;

	let cointTabs = [];

	if (!global.appleStoreReview || HasLevelOrShare(user.profile.reward_level, 1)) {
		cointTabs = [
			{ id: 1, content: <CoinIcon level={1} /> },
			{ id: 2, content: <CoinIcon level={2} /> },
			{ id: 3, content: <CoinIcon level={3} /> },
			{ id: 4, content: <CoinIcon level={4} /> },
			{ id: 5, content: <CoinIcon level={5} /> },
		];
	}

	const avatarsDisplayed = Object.values(avatars).filter((a) => a.rank === coinSelected && a.is_available);
	const shieldsDisplayed = [1, 2, 3];

	React.useEffect(() => {
		if (coinSelected === 5 && !avatarRequest) {
			dispatch(fetchAvatarRequest());
		}
	}, [coinSelected, avatarRequest]);

	let avatarPendingUnlock = avatars[avatarRequest?.avatar];
	if (avatarPendingUnlock && !avatarPendingUnlock.is_available) avatarPendingUnlock = avatarPendingUnlock;

	const saveChanges = React.useCallback(() => {
		const newData = {
			portrait_avatar: avatarIdSelected,
			background_rank: backgroundRankSelected || "no",
			background_key: backgroundKeySelected || 0,
			background_color: shieldColor,
			color: avatarColor,
		};

		if (character.id) {
			dispatch(receiveCharacters({ gameId: character.game, characters: [{ ...character, ...newData }] }));
			apiFetch(`games/${character.game}/characters/${character.id}`, "PATCH", newData);
			navigation.goBack();
		} else {
			navigation.navigate(previousScreenName, { avatarData: newData });
		}
	}, [character, avatarIdSelected, backgroundRankSelected, backgroundKeySelected, shieldColor, avatarColor]);

	const unlockAvatar = React.useCallback(() => {
		dispatch(updateAvatar({ ...avatarPendingUnlock, is_available: true }));
		scrollViewRef.current.scrollToEnd();
		apiFetch(`avatars/${avatarPendingUnlock.id}`, "PATCH", {
			is_available: true,
		});
	}, [avatars, avatarPendingUnlock]);

	const changeCoinSelected = React.useCallback((coin) => {
		if (!isWeb()) setoptimizationHide(true);
		setcoinSelected(coin);
	}, []);

	React.useEffect(() => {
		let revealTimer = null;
		if (optimizationHide) {
			revealTimer = setTimeout(() => setoptimizationHide(false), 250);
		}

		return () => {
			clearTimeout(revealTimer);
		};
	}, [optimizationHide]);

	return (
		<>
			<AppScreenView borderless style={ws({ paddingHorizontal: sheetPadding() })}>
				<HorizontalTabs tabs={displayedTabs} selectedId={selectedTabId} onTabSelected={setselectedTabId} />

				<View style={{ flexDirection: "row", justifyContent: "space-around", marginVertical: 16 }}>
					<View style={{ height: avatarSize, width: avatarSize }}>
						<CharacterAvatar
							character={{
								portrait_avatar: avatarIdSelected,
								color: avatarColor,
								background_key: backgroundKeySelected,
								background_rank: backgroundRankSelected,
								background_color: shieldColor,
							}}
							size={avatarSize}
						/>
					</View>
					<View style={{ alignItems: "center", justifyContent: "center" }}>
						<AppText>
							{selectedTabId === "avatar"
								? _("Avatar color", "edit avatar")
								: _("Shield color", "edit avatar")}
						</AppText>
						<ColorSelector
							currentColor={selectedTabId === "avatar" ? avatarColor : shieldColor}
							setColor={selectedTabId === "avatar" ? setavatarColor : setshieldColor}
						/>
					</View>
				</View>

				<HorizontalTabs tabs={cointTabs} selectedId={coinSelected} onTabSelected={changeCoinSelected} />

				<ScrollView ref={scrollViewRef}>
					<Cond show={!global.appleStoreReview || HasLevelOrShare(user.profile.reward_level, 1)}>
						{coinSelected <= 2 && <UnlockedByShareInfo style={{ padding: 8 }} />}
						<CondView
							show={
								false && // deactivated until avatar requests come back
								coinSelected === 5 &&
								selectedTabId === "avatar" &&
								(!avatarPendingUnlock || avatarPendingUnlock.is_available)
							}
							style={[globalStyles.rcsb, { marginHorizontal: 8 }]}
						>
							<AppText>{_("Request a custom avatar", "legendary avatars tab")}</AppText>
							<AppButton
								title={_("Request")}
								size="small"
								onPress={() => navigation.navigate("AvatarRequestWizardStack")}
							/>
						</CondView>
						<CondView
							show={
								coinSelected === 5 &&
								selectedTabId === "avatar" &&
								avatarPendingUnlock &&
								!avatarPendingUnlock.is_available
							}
							style={[{ alignItems: "center", marginHorizontal: 8 }]}
						>
							<AppText>{_("Your new avatar is ready!")}</AppText>
							<CharacterAvatar avatarName={avatarPendingUnlock?.name} size={128} />
							<AppButton title={_("Unlock", "unlock new legendary avatar")} onPress={unlockAvatar} />
						</CondView>

						{optimizationHide && (
							<ActivityIndicator color={global.colors.hint} style={{ marginVertical: 64 }} />
						)}
						{!optimizationHide && (
							<View style={styles.list}>
								{selectedTabId === "avatar" &&
									avatarsDisplayed.map((item) => (
										<TouchableOpacity
											key={item.name}
											style={styles.avatarListed}
											onPress={() => {
												if (HasLevelOrShare(user.profile.reward_level, coinSelected)) {
													setavatarIdSelected(item.id);
												} else {
													lockedRef.current.show();
												}
											}}
										>
											<CharacterAvatar avatarName={item.file_name} />
										</TouchableOpacity>
									))}

								{selectedTabId === "shield" &&
									shieldsDisplayed.map((item) => (
										<TouchableOpacity
											key={item}
											style={styles.avatarListed}
											onPress={() => {
												if (HasLevelOrShare(user.profile.reward_level, coinSelected)) {
													setbackgroundRankSelected(levelToRankShort(coinSelected));
													setbackgroundKeySelected(item);
												} else {
													lockedRef.current.show();
												}
											}}
										>
											<CharacterAvatar
												noAvatar
												character={{
													background_rank: levelToRankShort(coinSelected),
													background_key: item,
												}}
											/>
										</TouchableOpacity>
									))}
							</View>
						)}
					</Cond>
					<AppButton
						size="small"
						title={_("Reset")}
						style={{ marginTop: 32 }}
						onPress={() => {
							if (selectedTabId === "avatar") {
								setavatarIdSelected(null);
							} else {
								setbackgroundKeySelected(null);
								setbackgroundRankSelected(null);
							}
						}}
					/>
				</ScrollView>
				<BoxModal
					ref={confirmAvatarChangeRef}
					message={
						<View>
							<AppText style={{ marginBottom: 16 }}>
								{_("Do you want to replicate the avatar change everywhere in the story?")}
							</AppText>
							<View style={{ justifyContent: "center", alignItems: "center", marginBottom: 16 }}>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<CharacterAvatar character={character} size={64} />

									<MaterialCommunityIcons
										name="arrow-right-bold"
										size={24}
										color={global.colors.textDefault}
									/>

									<CharacterAvatar
										character={{
											portrait_avatar: avatarIdSelected,
											color: avatarColor,
											background_key: backgroundKeySelected,
											background_rank: backgroundRankSelected,
											background_color: shieldColor,
										}}
										size={64}
									/>
								</View>
							</View>

							<AppButton
								style={{ marginBottom: 8 }}
								list
								title={_("Only use this Avatar from now on")}
								info={_(
									"Useful to change your Avatar temporarily (for example, if you are reusing an NPC sheet for a different character)."
								)}
								onPress={() => {
									confirmAvatarChangeRef.current.hide();
									saveChanges();
								}}
							/>

							<AppButton
								list
								danger
								title={_("Replace the Avatar everywhere in the story")}
								info={_(
									"Every existing instances of the previous Avatar will be replaced by the new one.",
									"change avatar"
								)}
								onPress={() => {
									replicateAvatarChanges(
										character,
										character,
										{
											color: avatarColor,
											portraitAvatar: avatarIdSelected,
											backgroundRank: backgroundRankSelected,
											backgroundKey: backgroundKeySelected,
											backgroundColor: shieldColor,
										},
										dispatch
									);
									saveChanges();
									confirmAvatarChangeRef.current.hide();
									navigation.goBack();
								}}
							/>
						</View>
					}
					options={[]}
				/>
			</AppScreenView>
			<ButtonFooter
				style={ws({ paddingHorizontal: sheetPadding() })}
				narrow={false}
				buttonProps={[
					{ title: _("Cancel"), onPress: () => navigation.goBack() },
					{
						title: _("Apply", "apply changes"),
						onPress: () => (character.id ? confirmAvatarChangeRef.current.show() : saveChanges()),
					},
				]}
			/>
			<LockedFeatureModal ref={lockedRef} requiredLevel={coinSelected} navigation={navigation} />
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		avatars: state.avatars,
		avatarRequest: state.avatarRequest,
		user: state.user,
	};
};

export default connect(mapStateToProps)(ChangeAvatarScreen);

const styles = StyleSheet.create({
	container: {},
	list: {
		marginTop: 8,
		flexDirection: "row",
		flexWrap: "wrap",
		...ws({ alignItems: "center", justifyContent: "center" }),
	},
	avatarListed: {
		height: 56,
		width: 56,
	},
});

const replicateAvatarChanges = (character, previousData, newData, dispatch) => {
	return apiFetch(`games/${character.game}/characters/${character.id}/states/replicate-avatar`, "POST", {
		previous_color: previousData.color,
		previous_portrait_avatar: previousData.portrait_avatar,
		previous_background_rank: previousData.background_rank || "no",
		previous_background_key: previousData.background_key || 0,
		previous_background_color: previousData.background_color,

		new_color: newData.color,
		new_portrait_avatar: newData.portraitAvatar,
		new_background_rank: newData.backgroundRank || "no",
		new_background_key: newData.backgroundKey || 0,
		new_background_color: newData.backgroundColor,
	}).then(() => dispatch(fetchCharacterStates(character.game)));
};
