import { FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { Checkbox, Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { colors } from "../../styles/colors";
import { fadeLayout } from "../../tools/animation";
import { getRuleGroup, getRuleGroupIndex, rollPool } from "../../tools/diceRoll";
import { isIos, isWeb, optimalColumnNum } from "../../tools/generic";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import InputField from "../generic/InputField";
import ModalScreen from "../generic/modal/ModalScreen";
import CondView from "../meta/CondView";
import DiceGroup from "./DiceGroup";
import DieIcon from "./DieIcon";
import Cond from "../meta/Cond";
import { useKeyboard } from "@react-native-community/hooks";
import { isGM } from "../../tools/games";

let lastDieId = 0;

function SimpleRollModal({ dice, route, navigation, dispatch, currentCharacter, gameId, partyId, gmInfo }) {
	const params = route.params || {};
	const {
		isSecondPool,
		ruleGroupsSet,
		comparedPool,
		comparedPoolRules,
		comparedPoolModifier,
		selectedDice: selectedDiceReceived,
		modifier: modifierReceived,
		compareType,
	} = params;

	const [modifier, setModifier] = React.useState(modifierReceived || 0);
	const [negativeMode, setNegativeMode] = React.useState(false);
	const [selectedDice, setSelectedDice] = React.useState(selectedDiceReceived || []);
	const [rollName, setrollName] = React.useState();
	const [nameFocused, setnameFocused] = React.useState(false);
	const keyboard = useKeyboard();

	const [toGMOnly, setToGMOnly] = React.useState(false);

	const gm = isGM(gameId);

	const options = [
		{
			title: _("Back"),
			type: "shifted_back_button", // Type is checked in ButtonFooter. Allows style to match back buttons even when not first in list.
			onPress: () => {
				isSecondPool || isWeb() ? navigation.goBack() : navigation.navigate("SelectRollTypeModal");
			},
		},
	];

	// If not the GM, add the option to whisper to the GM
	!gm && options.unshift(
		{
			title: _("To GM only"),
			type: "checkbox", // Type is checked in ButtonFooter.
			onChange: () => { setToGMOnly(!toGMOnly); },
			checked: { toGMOnly },
		}
	);

	isSecondPool &&
		options.push({
			title: _("Done"),
			onPress: () =>
				navigation.navigate("DiceRollPoolOptionsScreen", {
					comparedPoolRules: ruleGroupsSet,
					comparedPool: selectedDice,
					comparedPoolModifier: modifier,
					compareType,
				}),
		});

	!isSecondPool &&
		options.push({
			title: _("Roll"),
			onPress: () => {
				rollPool(
					gameId,
					partyId,
					currentCharacter,
					toGMOnly,
					gmInfo,
					rollName,
					selectedDice,
					ruleGroupsSet,
					modifier,
					comparedPool,
					comparedPoolRules,
					comparedPoolModifier,
					compareType,
					dispatch
				);
				navigation.navigate("Game");
			},
		});

	// Make it so the number of column is made to have as close to maxColums possible on the last row
	let numColumns = optimalColumnNum(dice.length, 5);

	React.useEffect(() => {
		if (negativeMode && !selectedDice.length) {
			setNegativeMode(false);
		}
		if (selectedDice.length && !selectedDice.some((d) => !d.negative)) {
			const copy = selectedDice.slice();
			copy[0] = { ...selectedDice[0], negative: false };
			setSelectedDice(copy);
		}
	}, [selectedDice, negativeMode]);

	const addDie = React.useCallback(
		(die) => {
			if (!selectedDice.length) {
				fadeLayout();
			}
			setSelectedDice(
				selectedDice.concat([
					{
						die,
						negative: negativeMode,
						id: lastDieId++,
					},
				])
			);
		},
		[negativeMode, selectedDice]
	);

	const removeDie = React.useCallback(
		(id) => {
			if (selectedDice.length === 1) fadeLayout();
			setSelectedDice(selectedDice.rg_removeElementByIdPure(id));

			if (ruleGroupsSet) {
				for (let i = 0; i < ruleGroupsSet.length; i++) {
					const optionGroup = ruleGroupsSet[i];
					if (optionGroup.dice.includes(id)) {
						navigation.setParams({ ruleGroupsSet: ruleGroupsSet.rg_removeAtPure(i) });
						break;
					}
				}
			}
		},
		[selectedDice, ruleGroupsSet]
	);

	let sortedDice = selectedDice.slice();

	if (ruleGroupsSet) {
		sortedDice = sortedDice.filter((d) => {
			const rules = ruleGroupsSet.find((o) => o.dice.includes(d.id));
			return !rules || rules.dice[0] === d.id;
		});
	}

	const goToMore = React.useCallback(
		(selectedGroupIndex = null) => {
			const presetGroups = isSecondPool ? comparedPoolRules : ruleGroupsSet;
			if (selectedGroupIndex === null && presetGroups) {
				const numDiceInGroup = presetGroups.reduce((acc, cur) => acc + cur.dice.length, 0);
				if (numDiceInGroup === selectedDice.length) {
					selectedGroupIndex = 0;
				}
			}
			if (isSecondPool)
				navigation.navigate("ComparedDiceRollPoolOptionsScreen", {
					pool: selectedDice,
					isSecondPool: true,
					presetGroups,
					selectedGroupIndex,
				});
			else
				navigation.navigate("DiceRollPoolOptionsScreen", {
					pool: selectedDice,
					presetGroups,
					selectedGroupIndex,
					comparedPool,
					comparedPoolRules,
					comparedPoolModifier,
					compareType,
				});
		},
		[isSecondPool, selectedDice, ruleGroupsSet, comparedPool, comparedPoolRules, comparedPoolModifier, compareType]
	);

	const rollTitleComponent = !isSecondPool && (
		<InputField
			value={rollName}
			onChangeText={setrollName}
			label={_("Roll title (optional)")}
			onFocus={() => setnameFocused(true)}
			onBlur={() => setnameFocused(false)}
		/>
	);

	const selectedDiceBoxComponent = (
		<CondView style={styles.poolPanel} show={selectedDice.length || isSecondPool}>
			<View style={styles.optionColumn}>
				<View />
				<IconButton
					icon={{
						type: FontAwesome,
						name: "minus",
						color: negativeMode ? global.colors.danger : global.colors.textDefault,
					}}
					transparent
					title={_("Neg.", "option to add a negative dice to the pool")}
					onPress={() => setNegativeMode(!negativeMode)}
				/>
				<IconButton
					icon={{
						type: Ionicons,
						name: isIos() ? "ios-options" : "md-options",
						color: global.colors.textDefault,
						size: 32,
					}}
					subicon={
						ruleGroupsSet && ruleGroupsSet.length
							? {
								type: MaterialCommunityIcons,
								name: "alert-circle",
								color: global.colors.danger,
							}
							: null
					}
					transparent
					title={_("More", "access more dice options")}
					disabled={!selectedDice.length}
					onPress={() => goToMore()}
				/>
			</View>

			<View style={styles.centerColumn}>
				<AppText centered style={{ marginBottom: 4 }}>
					{_("Selected dice")}
				</AppText>
				<View style={styles.currentPool}>
					<FlatList
						key={selectedDice.length}
						data={sortedDice}
						keyExtractor={(item, index) => String(index)}
						renderItem={({ item }) => {
							const rules = getRuleGroup(item.id, ruleGroupsSet, selectedDice);
							if (rules) {
								return (
									<DiceGroup
										appliedRules={rules}
										onPress={() => goToMore(getRuleGroupIndex(item.id, ruleGroupsSet))}
										onLongPress={() => setSelectedDice([])}
									/>
								);
							}

							return (
								<DieIcon
									die={item.die}
									onPress={() => removeDie(item.id)}
									negative={item.negative}
									onLongPress={() => setSelectedDice([])}
									delayLongPress={global.delayLongPress}
								/>
							);
						}}
						numColumns={optimalColumnNum(selectedDice.length, 5)}
						columnWrapperStyle={styles.diceList}
					/>
				</View>
			</View>

			<View style={styles.optionColumn}>
				<AppText>{_("Mod.", "add dice pool modifier")}</AppText>
				<IconButton
					transparent
					icon={{ type: FontAwesome, name: "plus", color: global.colors.textDefault }}
					onPress={() => setModifier(modifier + 1)}
				/>
				<TouchableOpacity onLongPress={() => setModifier(0)} delayLongPress={global.delayLongPress}>
					<AppText style={{ textAlign: "center" }}>{modifier}</AppText>
				</TouchableOpacity>
				<IconButton
					transparent
					icon={{ type: FontAwesome, name: "minus", color: global.colors.textDefault }}
					onPress={() => setModifier(modifier - 1)}
				/>
			</View>
		</CondView>
	);

	return (
		<ModalScreen
			wide
			useButtonFooter
			options={options}
			borderless
			title={keyboard.keyboardShown ? null : _("Pick dice to roll")}
		>
			{isWeb() && rollTitleComponent}
			{!isWeb() && (
				<>
					{selectedDiceBoxComponent}
					<Divider />
				</>
			)}

			<FlatList
				style={{ flexGrow: 1 }}
				columnWrapperStyle={styles.diceList}
				key={numColumns}
				data={dice}
				keyExtractor={(item) => String(item.id)}
				renderItem={({ item }) => <DieIcon die={item} onPress={addDie} negative={negativeMode} />}
				numColumns={numColumns}
			/>

			{!isWeb() && rollTitleComponent}

			{isWeb() && (
				<>
					<Divider />
					{selectedDiceBoxComponent}
				</>
			)}
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	const diceSets = state.diceSets[gameId];
	const dice = Object.values(state.dice).filter((d) => diceSets.some((ds) => d.dice_set === ds.id));
	const gmInfo = state.games[gameId]?.game_master;

	return {
		gameId,
		dice,
		currentCharacter: getCurrentCharacter(state),
		partyId: state.parties[gameId]?.current?.id,
		gmInfo,
	};
};

export default connect(mapStateToProps)(SimpleRollModal);

const styles = StyleSheet.create({
	diceList: {
		flex: 1,
		justifyContent: "space-evenly",
		paddingVertical: 8,
	},
	poolPanel: {
		flexDirection: "row",
		flexGrow: 1,
		marginVertical: 8,
		marginHorizontal: 16,
	},
	optionColumn: {
		justifyContent: "space-between",
		alignItems: "center",
	},
	currentPool: {
		borderWidth: 1,
		borderColor: "black",
		marginHorizontal: 4,
		flex: 1,
	},
	centerColumn: {
		height: 150,
		flex: 1,
	},
});
