import * as React from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { pathToBackground } from "../../tools/storyMarker";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import { isWeb, ws } from "../../tools/generic";

function StageBackgroundImage({
	marker,
	name,
	text,
	onPress,
	containerStyle,
	height,
	additionalImageStyle,
	resizeMode,
	blurRadius,
	imageClass,
}) {
	const pathToBg = name ? `${global.root_url}/static/images/stagebg/bg_${name}.png` : pathToBackground(marker);

	const imageStyle = [styles.image];
	if (height !== undefined) {
		imageStyle.push({ height: height });
	}

	if(!marker?.stage?.background){
		imageStyle.push({backgroundColor: null});
	}


	let imageComponent = (
		<Image
			blurRadius={blurRadius}
			source={{ uri: pathToBg }}
			style={[imageStyle, additionalImageStyle]}
			resizeMode={resizeMode || ws("contain", "cover")}
		/>
	);

	if (isWeb()) {
		imageComponent = <div className={imageClass}>{imageComponent}</div>;
	}

	const component = (
		<View style={containerStyle}>
			{imageComponent}
			<AppText hide={!text} size="small" color="textLight" style={styles.text}>
				{text}
			</AppText>
		</View>
	);
	if (onPress) {
		return (
			<TouchableOpacity onPress={onPress} activeOpacity={0.8}>
				{component}
			</TouchableOpacity>
		);
	}

	return component;
}

export default StageBackgroundImage;

const styles = StyleSheet.create({
	image: {
		height: 136,
		marginHorizontal: -16,
	},
	text: {
		position: "absolute",
		bottom: 8,
		right: 0,
	},
});
