import * as React from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import AppButton from "../generic/buttons/AppButton";
import { apiFetch } from "../../network/apiFetch";
import AppText from "../generic/AppText";
import AppScreenView from "../AppScreenView";
import { logout } from "../../tools/account";
import { isWeb, ws } from "../../tools/generic";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function VerifyEmailScreen({ style, route, navigation, dispatch, user }) {
	const [sending, setsending] = React.useState(false);

	const verifiedRef = React.useRef();

	const sendEmail = React.useCallback(async () => {
		setsending(true);
		apiFetch(`profiles/${user.profile.id}/send-verification`, "POST").then(() => {
			verifiedRef.current.show();
			setTimeout(() => setsending(false), 2000);
		});
	}, []);

	const size = 256;

	const winDim = useWindowDimensions();

	return (
		<AppScreenView style={[styles.container, style]} useSafeArea>
			{isWeb() && (
				<View style={{ alignItems: "center" }}>
					<Image
						style={{ width: size, height: size, marginBottom: 32 }}
						source={require("../../../assets/images/Logo.png")}
					/>
				</View>
			)}
			<Image
				style={{
					width: winDim.width,
					height: winDim.height,
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: -1,
				}}
				source={require("../../../assets/images/splash_screen_background.png")}
			/>
			<AppText centered>{_("Please verify your email address")}</AppText>
			<AppText bold centered style={{ marginBottom: 64 }}>
				{user.email}
			</AppText>
			<AppText centered style={{ marginBottom: 32 }}>
				{ws(
					_("If you did not receive the verification email, click the button below to have it sent again."),
					_("If you did not receive the verification email, tap the button below to have it sent again.")
				)}
			</AppText>
			<AppButton title={_("Send verification email")} disabled={sending} size="large" onPress={sendEmail} />
			<AppButton
				title={_("Log out")}
				size="large"
				onPress={() => logout(user.id, dispatch)}
				style={{ marginTop: 32 }}
			/>
			<TinyFeedbackModal
				ref={verifiedRef}
				message={_("Email sent", "confirmation that an email has been sent")}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(VerifyEmailScreen);

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
	},
});
