import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import StageBackgroundSelectionScreen from "../../stagebackgrounds/StageBackgroundSelectionScreen";
import WizardCreateGameDetails from "./WizardCreateGameDetails";
import WizardCreateGameDiceSets from "./WizardCreateGameDiceSets";
import WizardCreateGameMetaData from "./WizardCreateGameMetaData";
import WizardCreateGamePassword from "./WizardCreateGamePassword";
import WizardCreateGameSheet from "./WizardCreateGameSheet";
import { _ } from "../../../i18n/i18n";
import CharacterSheetStack from "../../sheets/CharacterSheetStack";
import DiceSetStack from "../../dicesets/DiceSetStack";
import CreateTagScreen from "../../tags/CreateTagScreen";
import { isWeb } from "../../../tools/generic";

const Stack = createStackNavigator();

function CreateGameWizardStack() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen
				name="WizardCreateGameDetails"
				component={WizardCreateGameDetails}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="CharacterSheetStackWizard"
				component={CharacterSheetStack}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardCreateGameSheet"
				component={WizardCreateGameSheet}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardCreateGameDiceSets"
				component={WizardCreateGameDiceSets}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardCreateGameMetaData"
				component={WizardCreateGameMetaData}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="WizardCreateGamePassword"
				component={WizardCreateGamePassword}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="SelectCover"
				children={(props) => (
					<StageBackgroundSelectionScreen {...props} previousScreenName="WizardCreateGameDetails" />
				)}
				options={{ headerShown: false, title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="DiceSetStack"
				component={DiceSetStack}
				options={{ title: _("Create game | Role Gate") }}
			/>
			<Stack.Screen
				name="CreateGameTagsScreen"
				component={CreateTagScreen}
				options={({ route, navigation }) => ({
					headerTitle: _("Select or create tags", "select game tags"),
					title: _("Create game | Role Gate"),
					unmountOnBlur: isWeb(),
				})}
			/>
		</Stack.Navigator>
	);
}

export default CreateGameWizardStack;
