import * as React from "react";
import { View } from "react-native";
import { _ } from "../../i18n/i18n";
import { levelToCoinName } from "../../tools/account";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";
import CoinIcon from "./CoinIcon";
import Purse from "./Purse";
import { apiFetch } from "../../network/apiFetch";
import { connect } from "react-redux";
import { refreshInteractiveHelper } from "../../store/slices/interactiveHelpersSlice";
import { fetchGame } from "../../store/slices/gamesSlice";

function ConfirmGiveCoinBoxModal({ level, modalRef, subjectGetter, onTransactionPerformed, onConfirm, dispatch, userId }) {

	const updateSubject = React.useCallback(() => {
		
		if(subjectGetter.game_id){
			dispatch(fetchGame(subjectGetter.game_id));
		}else if(subjectGetter.helper_id){
			dispatch(refreshInteractiveHelper(subjectGetter.helper_id));
		}

	}, [subjectGetter]);

	return (
		<BoxModal
			ref={modalRef}
			title={_("Give coin")}
			message={
				<View style={{ alignItems: "center" }}>
					<CoinIcon level={level} size={64} style={{ marginBottom: 16 }} />
					<AppText style={{ marginBottom: 16 }}>
						{_("Give a %(coin_type)s coin? This action cannot be undone.", "confirm give coin message", {
							coin_type: levelToCoinName(level),
						})}
					</AppText>

					<Purse />
				</View>
			}
			onConfirm={async () => {
				await apiFetch(`users/${userId}/coin-transactions`, "post", {
					subject: subjectGetter,
					coin_level: level,
				}).then(() => {
					onTransactionPerformed && onTransactionPerformed(level);
					updateSubject();
				});
				onConfirm && onConfirm();
			}}
		/>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		userId: state.user.id,
	};
};

export default connect(mapStateToProps)(ConfirmGiveCoinBoxModal);
