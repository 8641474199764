import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import GameDescription from "./descriptionpanel/GameDescription";
import GamePlayers from "./descriptionpanel/GamePlayers";
import GameQuickIndex from "./descriptionpanel/GameQuickIndex";
import GameStats from "./descriptionpanel/GameStats";

import { BuildStyleMethod } from "../../styles/theming";

function GameDescriptionPanel({ game, dispatch }) {
	const styles = stylesMethod(global.theme);
	return (
		<View style={styles.container}>
			<GameStats game={game} />
			<GameDescription game={game} noEdit />
			{game.allow_specators && <GameQuickIndex game={game} />}
			<GamePlayers game={game} noEdit />
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default connect(mapStateToProps)(GameDescriptionPanel);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		backgroundColor: colors.backgroundSecondary,
		flex: 1,
	},
}));
