import * as React from "react";
import { View, StyleSheet } from "react-native";
import BoxModal from "../generic/modal/BoxModal";
import { _ } from "../../i18n/i18n";
import { connect } from "react-redux";
import AppText from "../generic/AppText";
import { apiFetch } from "../../network/apiFetch";
import { removeExportGameRequest } from "../../store/slices/exportGameRequestsSlice";

function ExportGameConsent({ exportRequest, dispatch, requester }) {
	const modalRef = React.useRef();

	React.useEffect(() => {
		modalRef.current.show();
	}, [exportRequest]);

	const consent = React.useCallback(
		(flag) => {
			apiFetch(`games/${exportRequest.game}/exportgamerequest/${exportRequest.id}/set-consent`, "POST", {
				consent: flag,
			});

			dispatch(removeExportGameRequest(exportRequest));
		},
		[exportRequest]
	);

	return (
		<BoxModal
			ref={modalRef}
			style={styles.container}
			title={_("Export chat messages consent")}
			options={[
				{
					title: _("Do not consent", "do not consent to exporting OOC chat messages"),
					danger: true,
					onPress: () => consent(false),
				},
				{ title: _("Consent", "consent to exporting OOC chat messages"), onPress: () => consent(true) },
			]}
		>
			<AppText>
				{_(
					"%(username)s would like to export the content of this game. This export will also contain out-of-character messages that are not normally visible to people outside of your game.",
					"",
					{ username: <AppText bold>{requester.username}</AppText> }
				)}
			</AppText>
			<AppText color="attention" style={{ marginTop: 8 }}>
				{_("Do you consent to having your messages exported?")}
			</AppText>
			<AppText color="hint">
				{_("Refusing will remove your out-of-character chat messages from the exported file.")}
			</AppText>
		</BoxModal>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		requester: state.users[ownProps.exportRequest.requester],
	};
};

export default connect(mapStateToProps)(ExportGameConsent);

const styles = StyleSheet.create({
	container: {},
});
