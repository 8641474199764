import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";
import { saveStore } from "../../tools/storeTools";

export const gameTagsSlice = createSlice({
	name: "gameTags",
	initialState: [],
	reducers: {
		receiveGameTags: (state, { payload: tags }) => state.rg_overlapById(tags, true),
		clearGameTags: (state, { payload: tags }) => [],
	},
});

export const recommendedGameTagsSlice = createSlice({
	name: "recommendedGameTags",
	initialState: null,
	reducers: {
		receiveRecommendedGameTags: (state, { payload: tags }) => tags,
	},
});

export const lookedForTagsSlice = createSlice({
	name: "lookedForTags",
	initialState: [],
	reducers: {
		receivelookedFordGameTags: (state, { payload: tags }) => tags,
	},
});

export const gameTagsStoreSlice = createSlice({
	name: "gameTagsStore",
	initialState: {},
	reducers: {
		storeGameTags: (state, { payload: tags }) => ({ ...state, ...tags.rg_toStore("name") }),
	},
	extraReducers: {
		[gameTagsSlice.actions.receiveGameTags]: (state, { payload: tags }) => ({
			...state,
			...tags.rg_toStore("name"),
		}),
		[recommendedGameTagsSlice.actions.receiveRecommendedGameTags]: (state, { payload: tags }) => ({
			...state,
			...tags.rg_toStore("name"),
		}),
	},
});

export const { receiveGameTags, clearGameTags } = gameTagsSlice.actions;
export const { storeGameTags } = gameTagsStoreSlice.actions;
export const { receiveRecommendedGameTags } = recommendedGameTagsSlice.actions;
export const { receivelookedFordGameTags } = lookedForTagsSlice.actions;

export function fetchDefaultTagRecommendations() {
	return async (dispatch, getState) => {
		return apiFetch(`tags/recommended-tags`).then((results) => {
			dispatch(receiveRecommendedGameTags(results));
			saveStore(getState());
		});
	};
}

export function fetchTagRecommendations(profileId) {
	return async (dispatch, getState) => {
		return apiFetch(`profiles/${profileId}/tags/recommended-tags`).then((results) => {
			dispatch(receiveRecommendedGameTags(results));
			saveStore(getState());
		});
	};
}

export function fetchLookedForTags(profileId) {
	return async (dispatch, getState) => {
		return apiFetch(`tags/get-looked-for`).then((results) => {
			dispatch(receivelookedFordGameTags(results));
			saveStore(getState());
		});
	};
}

export function searchTags(searchQuery){
	return async (dispatch, getState) => {
		return apiFetch(`tags`, "GET", {
			search: searchQuery,
		}).then((response) => {
			dispatch(storeGameTags(response.results));
			dispatch(receiveGameTags(response.results));
			return response.results;
		});
	};
}

export function fetchGameTagsByName(names) {
	return async (dispatch, getState) => {
		return apiFetch(`tags`, "GET", {
			name: names,
		}).then((response) => {
			dispatch(storeGameTags(response.results));
			return response.results;
		});
	};
}
export function fetchGameTagsByIds(ids) {
	return async (dispatch, getState) => {
		return apiFetch(`tags`, "GET", {
			ids,
		}).then((response) => {
			dispatch(receiveGameTags(response.results));
			return response.results;
		});
	};
}

export function fetchGameTags(params) {
	return async (dispatch, getState) => {
		params = params || {};
		params["page-size"] = params["page-size"] || 100;
		return apiFetch(`tags`, "GET", {
			...params,
		}).then((response) => {
			dispatch(receiveGameTags(response.results));
			return response.next;
		});
	};
}

export function fetchNextGameTags(url) {
	if (!url) return (dispatch, getState) => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receiveGameTags(response.results));
			return response.next;
		});
	};
}
