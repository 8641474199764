import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import InteractiveSheetLabel from "./InteractiveSheetLabel";

// Replaces something like [[1d20k1+10d10]] with something more pleasing to the eye like 1d20k1+10d(AC)
function ReadablePoolCommand({
	command,
	labels,
	poolLabels,
	selectedLabel,
	updateLabel,
	onLabelClicked,
	ignoreTarget0,
	targets,
}) {
	let readable = command.replace(/\[\[|]]/g, "");

	const components = [];
	let indexEnd = 0;
	for (let i = 0; i < poolLabels.length; ++i) {
		let label = poolLabels[i];
		const stringUpToHere = readable.substr(indexEnd, label.index - indexEnd);
		const cut = stringUpToHere.split(/([+><-])/g).filter((s) => s);
		for (let s = 0; s < cut.length; ++s) {
			let sub = cut[s];
			components.push(<AppText key={`${i}_${s}`} color="primary">{sub}</AppText>);
		}

		indexEnd = label.index + label.value.length;

		if (label.targetId === 0 && ignoreTarget0) {
			components.push(<AppText color="primary" key={components.length}>{label.value}</AppText>);
		} else {
			let target = targets ? targets.find((t) => t.label.targetId === label.targetId) : null;
			components.push(
				<InteractiveSheetLabel
					key={label.poolIndex + "-" + label.index}
					labels={labels}
					id={"label-" + label.poolIndex + "-" + label.index}
					label={label}
					selected={selectedLabel && selectedLabel.targetId === label.targetId}
					updateLabel={updateLabel}
					onLabelClicked={onLabelClicked}
					character={target ? target.character : null}
				/>
			);
		}
	}

	components.push(<AppText key={components.length} color="primary">{readable.substr(indexEnd)}</AppText>);

	return components;
}

export default ReadablePoolCommand;


