import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import { cStyle } from "../../tools/react";
import AppText from "../generic/AppText";

function PartyTab({ party, povCharacterId }) {
	const isOpened = party.characters.includes(povCharacterId);

	const characterIconArray = party.characters.map((characterId, index) => (
		<MaterialIcons
			key={index}
			name="person"
			size={12}
			color={isOpened ? (index === 0 ? global.colors.primary : global.colors.textDefault) : global.colors.secondary}
			style={cStyle([[index > 0, { marginLeft: -6 }]])}
		/>
	));

	return (
		<View style={cStyle([styles.partyButton, [isOpened, styles.openedPartyButton]])}>
			{characterIconArray}
			<AppText color={isOpened ? null : "secondary"}>{party.name}</AppText>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { party } = ownProps;
	return {
		povCharacterId: state.parties[party.game]?.povCharacterId,
	};
};

export default connect(mapStateToProps)(PartyTab);

const styles = StyleSheet.create({
	partyButton: {
		flexDirection: "row",
		alignItems: "center",
	},
	openedPartyButton: {},
});
