import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { receiveLevelPriceInfo } from "../../store/slices/subscriptionSlice";
import { colors } from "../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles } from "../../styles/global";
import { getPlanPaddleId, getRankPrice, levelToCoinColor, levelToRankName } from "../../tools/account";
import { isWeb, ws } from "../../tools/generic";
import CoinHelpModal from "../coins/CoinHelpModal";
import CoinIcon from "../coins/CoinIcon";
import AppText from "../generic/AppText";
import HorizontalTabs from "../generic/HorizontalTabs";
import BoxModal from "../generic/modal/BoxModal";
import AccountLevelText from "./AccountLevelText";
import CurrentSubscriptionInfo from "./CurrentSubscriptionInfo";
import ReferralUpgradeTracker from "./ReferralUpgradeTracker";
import SubscribeButton from "./SubscribeButton";

const getFeatureDescription = (code) => {
	switch (code) {
		case "use_macro":
			return _(
				"Macros let you to roll pre-registered dice pools with a single action, instead of typing them manually every time.",
				"explains the use macros feature"
			);
		case "create_macro":
			return _(
				"At this level, you can create your own macros instead of being limited to those created by the community",
				"explains the create and edit macros feature"
			);
		case "upload_images":
			return _(
				"Once unlocked, you will be able to upload and send images in games, for better immersion.",
				"explains the upload images feature"
			);
		case "avatar":
			return (
				<>
					<AppText>
						{_(
							"Avatars are a representation of your character in the chat. The higher the Avatar level, the more detailed and complex looking it is.",
							"explains the Avatars feature"
						)}
					</AppText>
					<AppText style={{ marginTop: 8 }}>
						{_(
							"Legendary avatars are custom made for Legendary subscribers.",
							"explains the Avatars feature"
						)}
					</AppText>
				</>
			);
		case "avatar_request":
			return _(
				"At this level, you can request a custom Avatar per month. This custom Avatar becomes available for you and all other Legendary subscribers.",
				"explains the Avatars request feature"
			);
		case "discord_channel":
			return (
				<>
					<AppText>
						{_(
							"By joining the Role Gate server and linking your Role Gate account to Discord, you will get access to a subscribers only channel.",
							"explains the subscribers only discord channel"
						)}
					</AppText>
					<AppText style={{ marginTop: 8 }}>
						{_(
							"On this channel, the dev regularly discusses upcoming features and asks for feedback from the community. The channel is the best way to have an impact on the direction that Role Gate takes in the future.",
							"explains the subscribers only discord channel"
						)}
					</AppText>
				</>
			);
		case "all_players":
			return (
				<>
					<AppText>
						{_(
							"Just by being a player or Game master of a game, you will unlock the afford mentionned feature for every other players.",
							"explains the 'all players in your games' feature unlock"
						)}
					</AppText>
					<AppText style={{ marginTop: 8 }}>
						{_(
							"This guarantees a certain level of game quality for every player with a Legendary subscription.",
							"explains the 'all players in your games' feature unlock"
						)}
					</AppText>
					<AppText style={{ marginTop: 8 }}>
						{_(
							"It also opens up the possibility of several players sharing a single subscription.",
							"explains the 'all players in your games' feature unlock"
						)}
					</AppText>
				</>
			);
		case "export_game":
			return (
				<>
					<AppText>
						{_(
							"Once unlocked, you will be able to export the content of your games as a .JSON file, for keepsake or to use outside of Role Gate.",
							"explains the 'export game' feature unlock"
						)}
					</AppText>
				</>
			);
		case "ai_tokens":
			return (
				<>
					<AppText>
						{_(
							"Using the AI generation tools costs tokens. This is the amount of tokens you will be granted each month at this subscription level.",
							"explains the 'ai tokens' feature unlock"
						)}
					</AppText>
				</>
			);

		default:
			return "";
	}
};

import { BuildStyleMethod } from "../../styles/theming";

function UnlockedFeature({ name, unlocked, number, showCoin, code }) {
	const styles = stylesMethod(global.theme);
	const ref = React.useRef();

	const ModalTag = code === "coin" ? CoinHelpModal : BoxModal;

	return (
		<>
			<TouchableOpacity
				style={[globalStyles.rc, styles.feature, ws({ paddingLeft: fullScreenLeftPadding() })]}
				onPress={() => ref.current?.show()}
			>
				{!!showCoin && <CoinIcon level={showCoin} style={{ marginRight: 8 }} />}
				<AppText style={{ flex: 1 }}>{name}</AppText>
				{unlocked && <MaterialCommunityIcons name="check-bold" size={24} color={global.colors.textDefault} />}
				{!!number && <AppText>{number}</AppText>}
			</TouchableOpacity>
			<ModalTag
				ref={ref}
				title={name}
				message={getFeatureDescription(code)}
				options={[{ title: _("Got it!", "close info box") }]}
			/>
		</>
	);
}

function UpgradeAccountScreen({ route, navigation, dispatch, levelPricesInfo }) {
	const styles = stylesMethod(global.theme);
	const [tabSelected, setTabSelected] = React.useState(1);

	const cointTabs = [
		{
			id: 1,
			level: 1,
			content: <CoinIcon level={1} />,
			title: ws(<AppText style={{ color: levelToCoinColor(1) }}>{levelToRankName(1)}</AppText>),
		},
		{
			id: 2,
			level: 2,
			content: <CoinIcon level={2} />,
			title: ws(<AppText style={{ color: levelToCoinColor(2) }}>{levelToRankName(2)}</AppText>),
		},
		{
			id: 3,
			level: 3,
			content: <CoinIcon level={3} />,
			title: ws(<AppText style={{ color: levelToCoinColor(3) }}>{levelToRankName(3)}</AppText>),
		},
		// {
		// 	id: 4,
		// level: 4,
		// 	content: <CoinIcon level={4} />,
		// 	title: ws(<AppText style={{ color: levelToCoinColor(4) }}>{levelToRankName(4)}</AppText>),
		// },
		{
			id: 4,
			level: 5,
			content: <CoinIcon level={5} />,
			title: ws(<AppText style={{ color: levelToCoinColor(5) }}>{levelToRankName(5)}</AppText>),
		},
	];

	const levelSelected = cointTabs[tabSelected - 1].level;

	const getPriceInfo = React.useCallback(async (level) => {
		const priceInfo = await new Promise((resolve, reject) => getPrice(level, resolve, reject));
		dispatch(receiveLevelPriceInfo({ level, info: priceInfo }));
	}, []);

	React.useEffect(() => {
		if (!levelPricesInfo[levelSelected]) {
			getPriceInfo(levelSelected);
		}
	}, [levelSelected, levelPricesInfo]);

	const [price, currency] = levelPricesInfo[levelSelected] || [];

	const purseValues = {
		wood: 0,
		copper: 0,
		silver: 0,
		gold: 0,
		platinum: 0,
		legendary: 0,
	};

	let aiTokenCounts = "-";

	if (levelSelected === 0) {
		purseValues.wood = 10;
	}
	if (levelSelected >= 1) {
		purseValues.copper = 25;
	}
	if (levelSelected >= 2) {
		purseValues.copper = 50;
		purseValues.silver = 10;
	}
	if (levelSelected >= 3) {
		purseValues.copper = 100;
		purseValues.silver = 25;
		purseValues.gold = 10;
		aiTokenCounts = 8000;
	}
	if (levelSelected >= 4) {
		purseValues.copper = 250;
		purseValues.silver = 50;
		purseValues.gold = 25;
		purseValues.platinum = 4;
		aiTokenCounts = 16000;
	}
	if (levelSelected >= 5) {
		purseValues.copper = 500;
		purseValues.silver = 100;
		purseValues.gold = 50;
		purseValues.platinum = 8;
		purseValues.legendary = 1;
		aiTokenCounts = 32000;
	}

	const { onLayout } = useLayout();

	return (
		<ScrollView onLayout={onLayout} stickyHeaderIndices={[1]} contentContainerStyle={ws({ alignItems: null })}>
			<View View style={[ws({ marginBottom: 16, paddingLeft: fullScreenLeftPadding() })]}>
				<CurrentSubscriptionInfo />
				<ReferralUpgradeTracker />
			</View>

			<View
				style={[
					{ backgroundColor: global.colors.buttonBackgroundColor },
					ws({ paddingLeft: fullScreenLeftPadding() }),
				]}
			>
				<HorizontalTabs
					tabs={cointTabs}
					selectedId={tabSelected}
					onTabSelected={setTabSelected}
					style={ws({ maxWidth: fullScreenContentWidth(), height: 58 })}
					tabStyle={{ height: 58 }}
				/>
			</View>

			<View style={[styles.titleContainer, ws({ paddingLeft: fullScreenLeftPadding() })]}>
				<View style={{ flex: 1 }}>
					<AccountLevelText bold level={levelSelected} />
					<AppText>
						{ws(_("Click any element to get more info"), _("Tap any element to get more info"))}
					</AppText>
				</View>
				{price ? (
					<AppText size="small">
						{_("%(currency)s %(price)s/month", "upgrade account price", {
							price: (
								<AppText key="p" size="header" bold>
									{price}
								</AppText>
							),
							currency: (
								<AppText key="c" size="average">
									{currency}
								</AppText>
							),
						})}
					</AppText>
				) : (
					<AppText>{_("--/month", "price indicated while real price is loading")}</AppText>
				)}
			</View>

			<AppText color="textLight" style={[styles.sectionTitle, ws({ marginLeft: fullScreenLeftPadding() })]} bold>
				{_("Features", "upgrade account list of unlocked features")}
			</AppText>
			<UnlockedFeature
				code="use_macro"
				name={_("Use existing macros", "upgrade account feature")}
				unlocked={true}
			/>
			<UnlockedFeature
				code="create_macro"
				name={_("Create and edit macros", "upgrade account feature")}
				unlocked={levelSelected > 1}
			/>
			<UnlockedFeature
				code="upload_images"
				name={_("Upload images", "upgrade account feature")}
				unlocked={levelSelected > 1}
			/>
			<UnlockedFeature
				code="export_game"
				name={_("Export games", "upgrade account feature")}
				unlocked={levelSelected > 1}
			/>

			{/* <UnlockedFeature
				code="ai_tokens"
				name={_("AI tokens", "granted ai tokens")}
				number={aiTokenCounts}
			/> */}

			<AppText color="textLight" style={[styles.sectionTitle, ws({ marginLeft: fullScreenLeftPadding() })]} bold>
				{_("Avatars", "upgrade account list of unlocked avatars")}
			</AppText>
			<UnlockedFeature code="avatar" name={_("Copper avatars", "upgrade account feature")} unlocked={true} />
			<UnlockedFeature
				code="avatar"
				name={_("Silver avatars", "upgrade account feature")}
				unlocked={levelSelected > 1}
			/>
			<UnlockedFeature
				code="avatar"
				name={_("Gold avatars", "upgrade account feature")}
				unlocked={levelSelected > 2}
			/>
			<UnlockedFeature
				code="avatar"
				name={_("Platinum avatars", "upgrade account feature")}
				unlocked={levelSelected > 3}
			/>
			<UnlockedFeature
				code="avatar"
				name={_("Legendary avatars", "upgrade account feature")}
				unlocked={levelSelected > 4}
			/>

			<AppText color="textLight" style={[styles.sectionTitle, ws({ marginLeft: fullScreenLeftPadding() })]} bold>
				{_("Legendary features", "upgrade account list of unlocked features")}
			</AppText>
			{/* <UnlockedFeature
				code="avatar_request"
				name={_("Monthly avatar request", "upgrade account feature")}
				unlocked={levelSelected > 4}
			/> */}
			<UnlockedFeature
				code="all_players"
				name={_("Use existing macros (all players in your game)", "upgrade account feature")}
				unlocked={levelSelected > 4}
			/>
			<UnlockedFeature
				code="all_players"
				name={_("Copper avatars (all players in your game)", "upgrade account feature")}
				unlocked={levelSelected > 4}
			/>
			<UnlockedFeature
				code="all_players"
				name={_("Silver avatars (all players in your game)", "upgrade account feature")}
				unlocked={levelSelected > 4}
			/>
			<UnlockedFeature
				code="all_players"
				name={_("Upload images (all players in your game)", "upgrade account feature")}
				unlocked={levelSelected > 4}
			/>
			<AppText color="textLight" style={[styles.sectionTitle, ws({ marginLeft: fullScreenLeftPadding() })]} bold>
				{_("Purse (monthly reset)", "upgrade account list of unlocked features")}
			</AppText>
			{/* prettier-ignore */}
			<>
			<UnlockedFeature code="coin" showCoin={1} name={_("Copper coins", "upgrade account feature")} number={purseValues.copper} />
			<UnlockedFeature code="coin" showCoin={2} name={_("Silver coins", "upgrade account feature")} number={levelSelected > 1 ? purseValues.silver : "-"}/>
			<UnlockedFeature code="coin" showCoin={3} name={_("Gold coins", "upgrade account feature")} number={levelSelected > 2 ? purseValues.gold : "-"} />
			<UnlockedFeature code="coin" showCoin={4} name={_("Platinum coins", "upgrade account feature")} number={levelSelected > 3 ? purseValues.platinum : "-"} />
			<UnlockedFeature code="coin" showCoin={5} name={_("Legendary coins", "upgrade account feature")} number={levelSelected > 4 ? purseValues.legendary : "-"} />
            </>
			<AppText color="textLight" style={[styles.sectionTitle, ws({ marginLeft: fullScreenLeftPadding() })]} bold>
				{_("Development", "upgrade account list of unlocked development features")}
			</AppText>
			<UnlockedFeature
				code="discord_channel"
				name={_("Discord development discussion channel", "upgrade account feature")}
				unlocked={true}
			/>

			<View style={[styles.footer, ws({ paddingLeft: fullScreenLeftPadding() })]}>
				{price ? (
					<AppText size="large" centered>
						{_("%(currency)s %(price)s/month", "upgrade account price", {
							price: (
								<AppText key="p" style={{ fontSize: 64 }} bold>
									{price}
								</AppText>
							),
							currency: (
								<AppText key="c" size="average">
									{currency}
								</AppText>
							),
						})}
					</AppText>
				) : (
					<AppText size="large" centered>
						{_("--/month", "price indicated while real price is loading")}
					</AppText>
				)}
				<AppText centered>{_("Cancel or downgrade anytime")}</AppText>

				<SubscribeButton levelSelected={levelSelected} />
			</View>
		</ScrollView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		levelPricesInfo: state.subscriptionInfo.levelPricesInfo,
	};
};

export default connect(mapStateToProps)(UpgradeAccountScreen);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		titleContainer: {
			padding: 8,
			flexDirection: "row",
			...ws({
				maxWidth: fullScreenContentWidth(),
			}),
		},
		sectionTitle: {
			backgroundColor: colors.secondary,
			padding: 8,
			...ws({
				maxWidth: fullScreenContentWidth(),
			}),
		},
		feature: {
			padding: 8,
			borderBottomWidth: 1,
			borderBottomColor: colors.lightBorder,
			minHeight: 48,
			...ws(
				{
					maxWidth: fullScreenContentWidth(),
					paddingRight: 0,
				},
				{ paddingRight: 16 }
			),
		},
		footer: {
			paddingVertical: 32,
			...ws({
				maxWidth: fullScreenContentWidth(),
			}),
		},
	})
);

async function getPrice(level, resolve) {
	const ipAddress = __DEV__ ? "89.74.84.61" : await apiFetch("users/ip-address");
	const defaultReturn = [getRankPrice(level), "$"];

	if (!ipAddress) {
		// cannot get ip
		return resolve(defaultReturn);
	}

	const planPaddleId = getPlanPaddleId(level).toString();
	const url = `https://checkout.paddle.com/api/2.0/prices?customer_ip=${ipAddress}&product_ids=${planPaddleId}`;

	if (isWeb()) {
		getPriceWeb(url, resolve);
	} else {
		getPriceNative(url, resolve);
	}
}

async function getPriceNative(url, resolve) {
	const paddleResponse = await fetch(url).then((response) => {
		return response.json();
	});
	handlePaddleResponse(paddleResponse, resolve);
}

function getPriceWeb(url, resolve) {
	var callbackMethod = "callback_" + new Date().getTime();

	var script = document.createElement("script");
	script.src = `${url}&callback=${callbackMethod}`;

	document.body.appendChild(script);

	window[callbackMethod] = function (paddleResponse) {
		delete window[callbackMethod];
		document.body.removeChild(script);
		handlePaddleResponse(paddleResponse, resolve);
	};
}

function handlePaddleResponse(paddleResponse, resolve) {
	if (!paddleResponse) return defaultReturn;

	if (paddleResponse.success) {
		const info = paddleResponse.response.products[0];
		let currency = "$";
		switch (info.currency) {
			case "EUR":
				currency = "€";
				break;
			case "PLN":
				currency = "zł";
				break;
			case "GBP":
				currency = "£";
				break;
			default:
				currency = "$";
				break;
		}

		let price = info.price.gross;
		resolve([price, currency]);
	} else {
		console.error("Paddle response error", paddleResponse.error.message);
		resolve(defaultReturn);
	}
}
