import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import WizardScreen from "./WizardScreen";
import AppText from "../../generic/AppText";
import { _, _n } from "../../../i18n/i18n";
import AppButton from "../../generic/buttons/AppButton";
import { ws } from "../../../tools/generic";

function WizardNumPlayersScreen({ route, navigation }) {
    const numPlayers = route.params?.numPlayers;
    
	React.useEffect(() => {
		if (numPlayers === undefined) navigation.setParams({ numPlayers: null });
	}, [numPlayers]);
    
	const data = [
		{ value: 5, title: _n("%(x)s player", "%(x)s players", "maximum players I want in a game", 5, { x: 5 }) },
		{ value: 4, title: _n("%(x)s player", "%(x)s players", "maximum players I want in a game", 4, { x: 4 }) },
		{ value: 3, title: _n("%(x)s player", "%(x)s players", "maximum players I want in a game", 3, { x: 3 }) },
		{ value: 2, title: _n("%(x)s player", "%(x)s players", "maximum players I want in a game", 2, { x: 2 }) },
		{ value: 1, title: _("Solo game", "number of players I am looking for") },
		{ value: null, title: _("Any", "number of players I am looking for") },
	];

	return (
		<WizardScreen nextScreen="WizardExpectedPace">
			<AppText bold centered style={ws({ marginBottom: 16 }, { marginTop: 16 })}>
				{_("I want maximum (including me)", "join game wizard (I want maximum X players)")}
			</AppText>
            <FlatList
				style={{ flex: 1 }}
				contentContainerStyle={{ justifyContent: "center", alignItems: "center" }}
				columnWrapperStyle={{ margin: 8 }}
				keyExtractor={(item, index) => String(index)}
				data={data}
				renderItem={({ item }) => (
					<AppButton
						title={item.title}
						isToggle
						toggled={numPlayers === item.value}
						style={{ marginHorizontal: 8 }}
						onPress={() => navigation.setParams({ numPlayers: item.value })}
					/>
				)}
				numColumns={2}
			/>
		</WizardScreen>
	);
}

export default WizardNumPlayersScreen;


