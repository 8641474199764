import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { fetchBookmarks } from "../../store/slices/bookmarksSlice";
import { fetchGame } from "../../store/slices/gamesSlice";
import { clearLines } from "../../store/slices/linesSlice";
import { previewSampleSize, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { gameHasUnloadedNext, isInGame } from "../../tools/games";
import { ws } from "../../tools/generic";
import { openGameAtLine } from "../../tools/lines";
import AppScreenView from "../AppScreenView";
import HorizontalTabs from "../generic/HorizontalTabs";
import GameDetailsHeader from "./descriptionpanel/GameDetailsHeader";
import GameDescriptionPanel from "./GameDescriptionPanel";
import GameLoader from "./GameLoader";
import GamePreviewSample from "./GamePreviewSample";

function GameDetailsPanelScreen({
	route,
	navigation,
	dispatch,
	game,

	user,
	hasNext,
	bookmark,
}) {
	if (!game) {
		return <GameLoader route={route} navigation={navigation} sendToGame={false} />;
	}

	const [tabselected, settabselected] = React.useState("description");

	const inGame = isInGame(user, game);

	React.useEffect(() => {
		dispatch(fetchGame(game.id));
	}, [game.archived, game.id]);

	React.useEffect(() => {
		if (user) dispatch(fetchBookmarks(user.id, [game.id]));
	}, [user?.id, game.id]);

	React.useEffect(() => {
		navigation.setOptions({ title: game.name });
	}, [game.name]);

	const unreadGameLines = user?.unread_lines?.filter((l) => l.game === game.id && !l.is_comments);
	const openGame = React.useCallback(() => {
		if (unreadGameLines?.length && unreadGameLines.length > 10) {
			openGameAtLine(
				game,
				unreadGameLines[0].id,
				unreadGameLines[0].party?.id || unreadGameLines[0].party,
				0.2,
				dispatch,
				navigation
			);
			return;
		} else if (!inGame && bookmark) {
			openGameAtLine(game, bookmark.line?.id || 0, bookmark.line?.party, 0.2, dispatch, navigation);
			return;
		}
		// if the last time the game was open, it wasn't at the bottom, unloaded everything to reload the bottom
		if (hasNext) {
			dispatch(clearLines(game.id));
		}
		navigation.navigate("GameStack", { screen: "Game", gameslug: game.slug });
	}, [game, hasNext, bookmark, inGame, unreadGameLines]);

	const { onLayout } = useLayout();

	return (
		<AppScreenView borderless scroll onLayout={onLayout}>
			<GameDetailsHeader game={game} openGame={openGame} />

			<View style={ws({ backgroundColor: global.colors.buttonBackgroundColor, alignItems: "center" })}>
				<HorizontalTabs
					tabs={[
						{ id: "description", title: _("Description", "game details") },
						game.allow_spectators && {
							id: "preview",
							title: _("Preview", "game details"),
							disabled: !game.allow_spectators,
						},
					]}
					selectedId={tabselected}
					onTabSelected={settabselected}
					noScroll
					style={ws(globalStylesMethod(global.theme).centeredBlock)}
				/>
			</View>

			{tabselected === "description" ? (
				<GameDescriptionPanel game={game} />
			) : (
				<View style={ws({ alignItems: "center" })}>
					<View style={ws({ widht: "100%", maxWidth: previewSampleSize(), paddingTop: 16 })}>
						<GamePreviewSample game={game} />
					</View>
				</View>
			)}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	// When pressing the back arrow on the browser, this component gets drawn once more, only without any route params.
	const { gameslug } = ownProps.route.params || {};
	const game = state.games[gameslug];
	return {
		game,
		user: state.user,
		hasNext: gameHasUnloadedNext(state, game?.id),
		bookmark: state.bookmarkForGames[game?.id],
	};
};

export default connect(mapStateToProps)(GameDetailsPanelScreen);

const styles = StyleSheet.create({});
