import Gateway from "../../../gateway/Gateway";
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect, useDispatch } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { apiFetch } from "../../../network/apiFetch";
import { editNode } from "../../../store/slices/sheetNodesSlice";
import { fetchSheet, setNodeDirty, setSelectedNodeId } from "../../../store/slices/sheetsSlice";
import { BuildStyleMethod } from "../../../styles/theming";
import { usePrevious } from "../../../tools/react";
import { addPlayerItem, removeNode, sheetItemHasElement } from "../../../tools/sheets";
import AppText from "../../generic/AppText";
import IconButton from "../../generic/buttons/IconButton";
import CondView from "../../meta/CondView";
import SheetNodeOptionsBar from "../SheetNodeOptionsBar";
import SheetNodeNumber from "./SheetNodeNumber";
import SheetNodePicture from "./SheetNodePicture";
import SheetNodeText from "./SheetNodeText";

function SheetNode_NOT_CONNECTED(props) {
	const styles = stylesMethod(global.theme);
	let {
		node,
		index,
		gameId,
		currentTabId,
		editingTemplate,
		allowSaveChanges,
		selected,
		dispatch,
		isRoot,
		horizontal,
		parent,
		screenNativeNode,
		duplicable = false,
		invalidUserListErrorInParent = false,
		isInList,
		dirty,
		deleteMode,
	} = props;

	const previousNode = usePrevious(node);
	const saveTimer = React.useRef(null);
	const navigation = useNavigation();

	const [measure, setmeasure] = React.useState({});
	const [invalidUserListError, setinvalidUserListError] = React.useState(invalidUserListErrorInParent);

	const saveChanges = React.useCallback(async () => {
		if (!allowSaveChanges) return;

		clearTimeout(saveTimer.current);
		saveTimer.current = null;

		const url = gameId
			? `games/${gameId}/sheets/${node.sheet}/tabs/${currentTabId}/nodes/${node.id}`
			: `sheets/${node.sheet}/tabs/${currentTabId}/nodes/${node.id}`;

		const updateData = { ...node };

		delete updateData.id;
		delete updateData.items;
		delete updateData._order;
		delete updateData.parent;
		delete updateData.template_node;

		await apiFetch(url, "PATCH", updateData);
		await dispatch(fetchSheet(node.sheet)); // fetchSheet to update sheet.edited
	}, [node, gameId, currentTabId, editingTemplate, allowSaveChanges]);

	const saveChangesRef = React.useRef(saveChanges);

	saveChangesRef.current = saveChanges;

	React.useEffect(() => {
		return () => {
			saveTimer.current && saveChangesRef.current();
		};
	}, []);

	React.useEffect(() => {
		if (!previousNode) return;
		if (JSON.stringify(previousNode) === JSON.stringify(node)) return;

		clearTimeout(saveTimer.current);
		saveTimer.current = setTimeout(saveChanges, 5000);
	}, [node, saveChanges]);

	React.useEffect(() => {
		if (!isInList) {
			return;
		}
		// Force parent re-render
		dispatch(setNodeDirty({ nodeId: parent.id, value: true }));
	}, [node?.items, isInList, parent?.id]);

	React.useEffect(() => {
		if (dirty) {
			dispatch(setNodeDirty({ nodeId: node.id, value: false }));
		}
	}, [dirty, node?.id]);

	const changeTitle = React.useCallback(
		(text) => {
			const edit = { ...node, title: text };
			dispatch(editNode(edit));
		},
		[node, dispatch]
	);

	const nodeComponentRef = React.useRef();

	const saveMeasure = React.useCallback(
		(x, y, width, height) => {
			// if (!selected) return;
			setmeasure({ x, y, width, height });
		},
		[selected]
	);

	React.useEffect(() => {
		if (!node) return;
		if (!editingTemplate || !node.user_list) {
			setinvalidUserListError(null);
		} else if (!node.items || node.items.length === 0) {
			setinvalidUserListError(
				_("Create the item players will be able to duplicate", "character sheet editor hint")
			);
		} else if (node.items.length > 1) {
			setinvalidUserListError(
				_(
					"A dynamic list can only have one item. Create a horizontal or vertical section if you want them to duplicate a group of items",
					"character sheet editor hint"
				)
			);
		} else if (!sheetItemHasElement(node.id)) {
			setinvalidUserListError(_("Add at least one item to your section", "character sheet editor hint"));
		} else {
			setinvalidUserListError(null);
		}
	}, [node, editingTemplate, invalidUserListErrorInParent, dirty]);

	const listMessage = React.useCallback(() => {
		if (invalidUserListError) return <AppText color="danger">{invalidUserListError}</AppText>;
		return (
			<AppText color={selected ? "secondary" : "attention"}>
				{_("players can duplicate this item", "character sheet editor hint")}
			</AppText>
		);
	}, [invalidUserListError, selected]);

	if (!node) return null;

	let component = null;

	switch (node.type) {
		case "section":
			component = <SheetSection {...props} invalidUserListError={invalidUserListError} />;
			break;
		case "number_input":
			component = <SheetNodeNumber {...props} />;
			break;
		case "text_area":
			component = <SheetNodeText {...props} />;
			break;
		case "picture":
			component = <SheetNodePicture {...props} />;
			break;
		default:
			break;
	}

	const ContainerTag = isRoot || !allowSaveChanges ? View : TouchableOpacity;
	return (
		<ContainerTag
			accessible={false} // set to false to allow tab jump from one field to the next
			onPress={() => {
				if (deleteMode) {
					return;
				}
				if (nodeComponentRef.current && screenNativeNode) {
					nodeComponentRef.current.measureLayout(screenNativeNode, saveMeasure);
				}
				if (editingTemplate) dispatch(setSelectedNodeId(selected ? null : node.id));
			}}
			style={[
				styles.nodeContainer,
				horizontal && { flexBasis: "0%" },
				node.size && node.size !== 1 && { flexGrow: node.size },
				duplicable && styles.duplicable,
				duplicable &&
					(!!invalidUserListError || !!invalidUserListErrorInParent) && { borderColor: global.colors.danger },
				,
				selected && styles.selected,
			]}
			ref={nodeComponentRef}
			onLayout={() =>
				nodeComponentRef.current &&
				screenNativeNode &&
				nodeComponentRef.current.measureLayout(screenNativeNode, saveMeasure)
			}
		>
			<View style={{ flexDirection: "row", alignItems: "center" }}>
				{editingTemplate && node.user_list && (
					<MaterialIcons name="list" size={14} color={global.colors.textDefault} />
				)}
				{editingTemplate && !isRoot && !node.user_list && node.type === "section" && (
					<MaterialCommunityIcons
						name={node.direction === "horizontal" ? "border-vertical" : "border-horizontal"}
						color={global.colors.textDefault}
						size={14}
					/>
				)}
				{/* <AppText>[{node.id}]</AppText> */}
				<AppText
					bold
					style={[styles.title, editingTemplate && { minWidth: 0, marginRight: 24 }]}
					hide={isRoot || (!node.title?.trim() && !editingTemplate)}
					editable={editingTemplate && node.type !== "number_input"}
					onTextChanged={changeTitle}
					placeholder={
						node.type === "number_input" ? null : _("title", "default title for character sheet section")
					}
					debug={selected}
					placeholderColor={selected ? "textLight" : null}
				>
					{node.title?.trim()}
				</AppText>
			</View>
			{node.user_list && editingTemplate && listMessage()}
			{component}

			{deleteMode && (
				<IconButton
					icon={{ type: FontAwesome, name: "remove" }}
					style={{ position: "absolute", right: 0 }}
					onPress={() => removeNode(parent, node, dispatch, gameId)}
				/>
			)}

			{selected && (
				<Gateway into="sheetOptionBar">
					<SheetNodeOptionsBar
						show={selected}
						node={node}
						parent={parent}
						dispatch={dispatch}
						top={measure?.y ? measure.y - 38 : null}
						index={index}
						horizontal={horizontal}
					/>
				</Gateway>
			)}
		</ContainerTag>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		node: state.sheetNodes[ownProps.nodeId],
		gameId: state.games.currentId,
		selected: state.sheetUI.selectedNodeId === ownProps.nodeId,
		dirty: state.sheetUI.dirty[ownProps.nodeId],
	};
};

const SheetNode = connect(mapStateToProps)(SheetNode_NOT_CONNECTED);

export default React.memo(SheetNode);

function SheetSection({
	node,
	style,
	allowSaveChanges,
	editingTemplate,
	currentTabId,
	selected,
	isRoot,
	screenNativeNode,
	invalidUserListError,
	isInList,
	parent,
}) {
	const [template, settemplate] = React.useState(null);
	const [addingUserItem, setaddingUserItem] = React.useState(false);
	const [deleteMode, setdeleteMode] = React.useState(false);

	if (!node.items) return null;
	const styles = stylesMethod(global.theme);

	const horizontal = node.direction === "horizontal";

	const dispatch = useDispatch();
	const navigation = useNavigation();

	React.useEffect(() => {
		if (!editingTemplate && node.user_list && allowSaveChanges && !node.items.length) {
			// fetch the template from the db
			apiFetch(`sheets/${node.sheet}/nodes/${node.template_node}`).then((response) => {
				settemplate(response);
			});
		}
	}, [editingTemplate, node, allowSaveChanges]);

	return (
		<View style={[styles.section, horizontal && styles.sectionHorizontal, style]}>
			{node.items.map((id, index) => (
				<SheetNode
					key={id}
					parent={node}
					nodeId={id}
					index={index}
					horizontal={horizontal}
					style={[index > 0 && !horizontal && styles.nodeFollowVertical]}
					currentTabId={currentTabId}
					allowSaveChanges={allowSaveChanges}
					editingTemplate={editingTemplate}
					screenNativeNode={screenNativeNode}
					duplicable={index === 0 && node.user_list && editingTemplate}
					isInList={node.user_list || isInList}
					invalidUserListErrorInParent={invalidUserListError}
					deleteMode={deleteMode}
				/>
			))}
			<CondView show={isRoot} style={{ marginBottom: 24 }} />

			{editingTemplate && (selected || !node.items.length || isRoot) && (
				<IconButton
					icon={{
						type: FontAwesome,
						name: "plus",
						color: invalidUserListError ? global.colors.danger : null,
					}}
					onPress={() =>
						navigation.navigate({
							name: "AddSheetNodeModal",
							params: { parent: node },
						})
					}
				/>
			)}

			<CondView
				show={!editingTemplate && node.user_list && allowSaveChanges}
				style={{ margin: 8, flexDirection: "row", justifyContent: "space-around" }}
			>
				<IconButton
					hide={deleteMode}
					icon={{ type: FontAwesome, name: "plus" }}
					disabled={addingUserItem}
					transparent
					onPress={() => {
						setaddingUserItem(true);
						addPlayerItem(template || node, node.id, dispatch).then(() => setaddingUserItem(false));
					}}
				/>
				<IconButton
					icon={{ type: FontAwesome, name: "remove" }}
					subicon={deleteMode ? { type: MaterialIcons, name: "done" } : null}
					disabled={addingUserItem}
					transparent
					onPress={() => {
						setdeleteMode((active) => !active);
					}}
				/>
			</CondView>
		</View>
	);
}

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		section: {
			flexGrow: 1,
			flexShrink: 0,
			flexBasis: "auto",
			marginTop: 4,
		},
		sectionHorizontal: {
			flexDirection: "row",
		},
		nodeFollowVertical: {
			marginTop: 4,
		},
		selected: {
			backgroundColor: colors.attention,
			padding: 8,
		},
		title: {
			alignSelf: "flex-start",
			minWidth: 64,
		},
		duplicable: {
			padding: 4,
			borderWidth: 1,
			borderColor: colors.attention,
			marginBottom: 8,
		},
		nodeContainer: {
			flexGrow: 1,
			flexShrink: 0,
			flexBasis: "auto",
		},
	})
);
