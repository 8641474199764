import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { deleteDieSymbol, saveDieSymbol, saveDieSymbolText } from "../../store/slices/diceSlice";
import { globalStyles } from "../../styles/global";
import { ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import InputField from "../generic/InputField";
import ModalScreen from "../generic/modal/ModalScreen";
import TokenSelector from "../generic/TokenSelector";
import CondView from "../meta/CondView";
import RGTokenIcon from "../RGTokenIcon";

function DieSymbolOptionsModal({ route, navigation, dispatch, die }) {
	const { face, symbol } = route.params;

	const [isText, setisText] = React.useState(symbol.name && !symbol.value);
	const [name, setname] = React.useState(symbol.name);

	const nameRef = React.useRef(name);
	const isTextRef = React.useRef(isText);
	nameRef.current = name;
	isTextRef.current = isText;

	const changeSymbol = React.useCallback(
		(newIcon) => {
			dispatch(saveDieSymbol(die, face, symbol, newIcon));
			navigation.setParams({ symbol: { ...symbol, value: newIcon } });
		},
		[die, face, symbol]
    );   

	React.useEffect(() => {
		return () => {
			if (isTextRef.current && symbol.name !== nameRef.current) {
				dispatch(saveDieSymbolText(die, face, symbol, nameRef.current.trim()));
			}
		};
	}, [die, face, symbol.name]);

	const toggleText = React.useCallback(() => {
		if (isText) {
			changeSymbol("tk_triumph");
		} else {
            // dispatch(saveDieSymbolText(die, face, symbol, ""));
            navigation.setParams({ symbol: { ...symbol, name: "" } });
            setname("");
        }
		setisText(!isText);
        
	}, [isText, die, face, symbol]);

	const onDelete = React.useCallback(() => {
		dispatch(deleteDieSymbol(die, face, symbol));
		navigation.goBack();
	}, [die, face, symbol]);

	const options = [];

	!isText &&
		options.push({
			title: _("Change to text", "edit die face content"),
			icon: { type: MaterialCommunityIcons, name: "format-text" },
			onPress: toggleText,
		});

	isText &&
		options.push({
			title: _("Change to symbol", "edit die face content"),
			icon: { type: RGTokenIcon, name: "tk_d20" },
			onPress: toggleText,
		});

	options.push({
		title: _("Delete"),
		icon: { type: MaterialCommunityIcons, name: "delete" },
		danger: true,
		onPress: onDelete,
	});

	return (
		<ModalScreen options={options}>
			<CondView show={!isText} style={globalStyles.jac}>
				<TokenSelector currentToken={symbol.value} setToken={changeSymbol} />
				<AppText color="hint">{ws(_("Click to edit symbol"), _("Tap to edit symbol"))}</AppText>
			</CondView>
			<CondView show={isText}>
				<InputField value={name} onChangeText={setname} autoFocus />
			</CondView>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { die } = ownProps.route.params;
	return {
		die: state.dice[die.id],
	};
};

export default connect(mapStateToProps)(DieSymbolOptionsModal);


