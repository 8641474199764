import * as React from "react";
import { _ } from "../../i18n/i18n";
import { isWeb } from "../../tools/generic";
import { modalScreenOptions } from "../../tools/navigationOptions";
import ReferralUpgradeScreen from "../account/ReferralUpgradeScreen";
import CharacterOptionsModal from "../characters/CharacterOptionsModal";
import SelectCharactersModal from "../characters/SelectCharactersModal";
import CoinsModalStack from "../coins/CoinsModalStack";
import MessageOptionsModal from "../directmessages/MessageOptionsModal";
import SearchGamesOptionsModal from "../games/SearchGamesOptionsModal";
import SelectGameTagsScreen from "../gametags/SelectGameTagsScreen";
import UserOptionsModal from "../profile/UserOptionsModal";
import LineOptionsModalStack from "./LineOptionsModalStack";

export default function ModalNavigationScreens(Stack) {
	
	return (
		<>
			<Stack.Screen name="MessageOptionsModal" component={MessageOptionsModal} options={modalScreenOptions} />
			{CoinsModalStack(Stack)}

			<Stack.Screen
				name="SearchGamesOptionsModal"
				component={SearchGamesOptionsModal}
				options={modalScreenOptions}
			/>
			<Stack.Screen name="UserOptionsModal" component={UserOptionsModal} options={modalScreenOptions} />
			

			<Stack.Screen
				name="ReferralUpgradeScreen"
				options={isWeb() ? modalScreenOptions : { headerTitle: _("Referral upgrade", "screen title") }}
				component={ReferralUpgradeScreen}
			/>

			<Stack.Screen
				name="SelectGameTagsScreen"
				component={SelectGameTagsScreen}
				options={
					isWeb()
						? modalScreenOptions
						: ({ route, navigation }) => ({ headerTitle: _("Select tags", "select game tags to filter") })
				}
			/>
			{LineOptionsModalStack(Stack)}
			<Stack.Screen name="SelectTargets" component={SelectCharactersModal}  options={modalScreenOptions}/>
			<Stack.Screen name="CharacterOptionsModal" component={CharacterOptionsModal} options={modalScreenOptions} />
		</>
	);
}
