import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";
import { updateMessageGroup } from "./messageGroupsSlice";

export const directMessagesSlice = createSlice({
	name: "directMessages",
	// {groupId: []}
	initialState: {},
	reducers: {
		receiveMessages: (state, { payload: { groupId, messages } }) => {
			state[groupId] = (state[groupId] || [])
				.rg_overlap(
					messages,
					(newEl, oldEl) => oldEl.id == newEl.id || (oldEl.key && newEl.key && oldEl.key === newEl.key),
					true
				)
				.rg_sortById();
		},
		removeMessage: (state, { payload: message }) => {
			state[message.group] = state[message.group].filter((m) => m.id !== message.id);
		},
	},
});
export const { receiveMessages, removeMessage } = directMessagesSlice.actions;

export function fetchMessages(groupId, params) {
	return async (dispatch, getState) => {
		const { user } = getState();
		return apiFetch(`users/${user.id}/message-groups/${groupId}/messages`, "GET", {
			"page-size": 50,
			...params,
		}).then(({ results }) => {
			dispatch(receiveMessages({ groupId, messages: results }));
		});
	};
}

export function editMessage(message) {
	return async (dispatch, getState) => {
		dispatch(receiveMessages({ groupId: message.group, messages: [message] }));
		return apiFetch(`users/${message.user}/message-groups/${message.group}/messages/${message.id}`, "PATCH", {
			content: message.content,
		});
	};
}
export function deleteMessage(message) {
	return async (dispatch, getState) => {
		dispatch(removeMessage(message));
		return apiFetch(`users/${message.user}/message-groups/${message.group}/messages/${message.id}`, "DELETE");
	};
}

export function sendDirectMessage(message) {
	return async (dispatch, getState) => {
		dispatch(receiveMessages({ groupId: message.group, messages: [message] }));

		return apiFetch(`users/${message.user}/message-groups/${message.group}/messages`, "POST", message).then(
			(serverMessage) => {
				serverMessage.key = message.key;
				dispatch(receiveMessages({ groupId: serverMessage.group, messages: [serverMessage] }));
				const state = getState();
				const group = state.messageGroups.all.find((g) => g.id === serverMessage.group);
				if (group) {
					const updated_group = { ...group, last_message: serverMessage, edited: serverMessage.created };
					dispatch(updateMessageGroup(updated_group));
				}
			}
		);
	};
}
