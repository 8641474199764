import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const symbolInteractionsSlice = createSlice({
	name: "symbolInteractions",
	initialState: [],
	reducers: {
		receiveSymbolInteractions: (state, { payload: symbolInteractions }) => state.rg_overlapById(symbolInteractions),
		cleanSymbolInteractions: (state, { payload: diceSetIds }) => {
			const array = state.rg_removeAllElements((interaction) => diceSetIds.includes(interaction.dice_set));
			return array;
		},
	},
});

export const { receiveSymbolInteractions, cleanSymbolInteractions } = symbolInteractionsSlice.actions;

export function fetchSymbolInteractions(diceSetIds) {
	return async (dispatch, getState) => {
		return apiFetch(`die-symbol-interactions`, "GET", { dice_sets: diceSetIds }).then((results) => {
			dispatch(cleanSymbolInteractions(diceSetIds));
			dispatch(receiveSymbolInteractions(results));
		});
	};
}
