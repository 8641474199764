import * as React from 'react';
import { View } from 'react-native';
import InputField from '../generic/InputField';
import { _ } from '../../i18n/i18n';
import AppText from '../generic/AppText';
import AppButton from '../generic/buttons/AppButton';
import AppScreenView from '../AppScreenView';
import { apiFetch } from '../../network/apiFetch';
import CondView from '../meta/CondView';
import AppLoading from 'expo-splash-screen';
import { errorCodeToString } from './LoginScreen'

function ForgotPasswordScreen() {
    const [email, setEmail] = React.useState("");
    const [sending, setSending] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState("");

    const sendEmail = React.useCallback(async () => {
        setError(false);
        setSending(true);
        await apiFetch("account/resetpassword/", "POST", { email }, true).then((response) => {
            const { error_mail, error_username } = response ? response : {};

            if (error_mail || error_username) {
                setError(errorCodeToString(error_mail || error_username));
            } else {
                setSuccess(true);
            }
        });
        setSending(false);
    }, [email]);

    React.useEffect(() => {
        setSending(false);
    }, [email])

    return (
        <AppScreenView style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                <AppText style={{marginBottom: 16}}>
                    {_("Please enter your e-mail address to receive instructions on how to reset your password")}
                </AppText>
                <InputField label={_("Email address")} value={email} editable={!sending}
                    onChangeText={setEmail} autoCompleteType="email" keyboardType="email-address" textContentType="emailAddress" />
            </View>

            <CondView show={error} style={{ marginTop: 16, alignItems: "center" }}>
                <AppText color="danger">{error}</AppText>
            </CondView>


            <CondView show={success} style={{ marginTop: 16, alignItems: "center" }}>
                <AppText color="primary">{_("Email sent, please check your inbox!")}</AppText>
            </CondView>

            <CondView show={sending}>
                <AppLoading />
            </CondView>
            <View style={{ flex: 1 }}>
                <AppButton title={_("Send email").toUpperCase()} disabled={sending || success} onPress={sendEmail} />
            </View>
        </AppScreenView>
    );
}

export default ForgotPasswordScreen;