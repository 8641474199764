import { useBackHandler, useKeyboard } from "@react-native-community/hooks";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import {
	KeyboardAvoidingView,
	SafeAreaView,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	TouchableWithoutFeedback,
	View,
} from "react-native";
import { Divider } from "react-native-paper";
import { modalWidthNarrow, modalWidthWide } from "../../../styles/dynamicStyles";
import { BuildStyleMethod } from "../../../styles/theming";
import { isIos, isWeb, ws } from "../../../tools/generic";
import { cStyle } from "../../../tools/react";
import CondView from "../../meta/CondView";
import AppText from "../AppText";
import AppButton from "../buttons/AppButton";
import ButtonFooter from "../buttons/ButtonFooter";
import OverflowMenu from "../OverflowMenu";

function ModalScreen({
	title,
	options,
	component,
	children,
	useButtonFooter,
	header,
	overflowOptions,
	helpButton,
	borderless,
	goBackScreen,
	goBackParams,
	style,
	wide,
	containerStyle,
}) {
	const navigation = useNavigation();
	const styles = stylesMethod(global.theme);

	if (!isWeb()) {
		useBackHandler(() => {
			if (goBackParams && goBackScreen) {
				// handle it
				navigation.navigate(goBackScreen, goBackParams);
				return true;
			}
			// let the default thing happen
			return false;
		});
	}

	const close = React.useCallback(() => {
		if (!goBackScreen) {
			navigation.goBack();
		} else {
			navigation.navigate(goBackScreen, goBackParams);
		}
	}, [goBackScreen, goBackParams]);

	const contentStyle = [styles.content, style];
	borderless && contentStyle.push(styles.noMargin);

	const keyboard = useKeyboard();

	const ViewTag = isIos() ? KeyboardAvoidingView : View;

	const ModalArea = (
		<ViewTag
			style={cStyle([
				styles.container,
				[isIos() && keyboard.keyboardShown, styles.containerWithKeyboard],
				[!!wide && isWeb(), styles.containerWide],
				containerStyle,
			])}
			behavior="padding"
			pointerEvents="auto" // necessary on web has parent has pointerEvents none
		>
			{header}
			<View style={styles.titleContainer}>
				{typeof title === "string" ? (
					<AppText bold style={{ marginHorizontal: 16, marginVertical: 8 }}>
						{title}
					</AppText>
				) : (
					title
				)}
				<CondView show={!!(helpButton || overflowOptions) && !title} style={{ flex: 1 }} />
				<View style={{ flexDirection: "row" }}>
					{!!overflowOptions && <OverflowMenu options={overflowOptions} />}
					{helpButton}
				</View>
			</View>

			{title && <Divider />}

			<CondView show={component || children} style={contentStyle}>
				{component || children}
			</CondView>
			<SafeAreaView style={{ backgroundColor: global.colors.buttonBackgroundColor }}>
				{!!options && !!options.length && !useButtonFooter && (
					<>
						<Divider />

						<View>
							{options.map((opt, index) => (
								<AppButton key={index} {...opt} list />
							))}
						</View>
					</>
				)}

				{!!options && !!options.length && useButtonFooter && (
					<ButtonFooter style={ws({ marginRight: 8 })} buttonProps={options} />
				)}
			</SafeAreaView>
		</ViewTag>
	);

	if (isWeb()) {
		return (
			<ScrollView contentContainerStyle={styles.screenContainer}>
				<TouchableOpacity onPress={close} style={styles.backdrop} />
				{ModalArea}
			</ScrollView>
		);
	}

	return (
		<>
			<TouchableWithoutFeedback onPress={close}>
				<View style={[styles.backdrop]} />
			</TouchableWithoutFeedback>
			{ModalArea}
		</>
	);
}

export default ModalScreen;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create(
		ws(
			{
				screenContainer: {
					flexGrow: 1,
					justifyContent: "center",
					alignItems: "center",
					paddingVertical: 30,
				},
				container: {
					backgroundColor: colors.cardBackground,
					width: modalWidthNarrow(),
				},
				containerWide: {
					width: modalWidthWide(),
				},
				content: {
					marginHorizontal: 16,
					marginVertical: 8,
					flexGrow: 1,
					flexShrink: 1,
					flexBasis: "auto",
				},
				backdrop: {
					justifyContent: "center",
					alignItems: "center",
					position: "absolute",
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				},
				titleContainer: {
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				},
			},
			{
				container: {
					backgroundColor: colors.cardBackground,
					maxHeight: "60%",
				},
				containerWithKeyboard: {
					maxHeight: "85%",
				},
				content: {
					marginHorizontal: 16,
					marginVertical: 8,
					flexGrow: 1,
					flexShrink: 1,
					flexBasis: "auto",
				},
				noMargin: { marginHorizontal: 0, marginBottom: 0 },
				backdrop: { flex: 1 },
				titleContainer: {
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				},
			}
		)
	)
);
