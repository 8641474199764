import * as React from "react";
import { FlatList, StyleSheet } from "react-native";
import { _ } from "../../../i18n/i18n";
import { ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import WizardScreen from "./WizardScreen";

function WizardAgeSelectionScreen({ route, navigation }) {
	const days = route.params?.age;

	React.useEffect(() => {
		if (days === undefined) navigation.setParams({ age: null });
	}, [days]);

	const data = [
		{ value: 1, title: _("A day ago") },
		{ value: 7, title: _("A week ago") },
		{ value: 31, title: _("A month ago") },
		{ value: 183, title: _("6 months ago") },
		{ value: 365, title: _("1 year ago") },
		{ value: null, title: _("Any") },
	];

	return (
		<WizardScreen  nextScreen="WizardNumPlayersScreen">
			<AppText bold centered style={ws({ marginBottom: 16 }, { marginTop: 16 })}>
				{_("I am looking for games created less than")}
			</AppText>

			<FlatList
				style={{ flex: 1 }}
				contentContainerStyle={{ justifyContent: "center", alignItems: "center" }}
				columnWrapperStyle={{ margin: 8 }}
				keyExtractor={(item, index) => String(index)}
				data={data}
				renderItem={({ item }) => (
					<AppButton
						title={item.title}
						isToggle
						toggled={days === item.value}
						style={{ marginHorizontal: 8 }}
						onPress={() => navigation.setParams({ age: item.value })}
					/>
				)}
				numColumns={2}
			/>
		</WizardScreen>
	);
}

export default WizardAgeSelectionScreen;


