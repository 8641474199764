export function getMaxToken(user) {
	const reward_level = user.profile.reward_level;
	if (reward_level >= 5) return 32000;
	else if (reward_level == 4) return 16000;
	else if (reward_level == 3) return 8000;
	else if (reward_level == 2) return 4000;
	else if (reward_level == 1) return 2000;
}

export function getUsageLeft(user) {
	return getMaxToken(user) - user.profile.openai_token_used;
}