import * as React from "react";
import { FlatList } from "react-native";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";

function StreamedList(props) {
	const { fetchURL, fetchParams } = props;

	const [nextURL, setNextURL] = React.useState(null);
	const [items, setItems] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		loadData();
	}, []);

	const loadData = React.useCallback(() => {
		setLoading(true);
		apiFetch(fetchURL, "GET", fetchParams).then((response) => {
			if (response) {
				const { next, results } = response;
				setItems(results);
				setNextURL(next);
			}
			setLoading(false);
		});
	}, []);

	const loadMoreData = React.useCallback(() => {
		if (loading || !nextURL) {
			return false;
		}
		setLoading(true);
		apiFetchSimple(nextURL).then((response) => {
			if (response) {
				const { next, previous, results } = response;
				const allItems = items.rg_overlapById(results);
				setItems(allItems);
				setNextURL(next);
			}
			setLoading(false);
		});
	}, [loading, nextURL, items]);
	
	return (
		<FlatList
			{...props}
			data={items}
			keyExtractor={(item) => String(item.id)}
			onEndReached={loadMoreData}
			onRefresh={loadData}
			refreshing={loading}
		/>
	);
}

export default StreamedList;
