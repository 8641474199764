import * as React from "react";
import { Modal, StyleSheet, View } from "react-native";
import { colors } from "../../../styles/colors";
import { BuildStyleMethod } from "../../../styles/theming";
import AppText from "../AppText";
import AppModal from "./AppModal";

class TinyFeedbackModal extends React.PureComponent {
	state = {
		visible: false,
	};

	componentWillUnmount() {
		clearTimeout(this.hideTimeout);
	}

	show() {
		this.setState({ visible: true });
		clearTimeout(this.hideTimeout);
		this.hideTimeout = setTimeout(this.hide, 1500);
	}

	hide = () => {
		this.setState({ visible: false });
	};

	render() {
		const { message } = this.props;
		const { visible } = this.state;
		const styles = stylesMethod(global.theme);

		return (
			<AppModal
				visible={visible}
				onRequestClose={() => this.setState({ visible: false })}
				animationType="fade"
				transparent
				statusBarTranslucent
				allowTapThrough
			>
				<View style={styles.screen} >
					<View style={styles.box}>
						<AppText size="small">{message}</AppText>
					</View>
				</View>
			</AppModal>
		);
	}
}

export default TinyFeedbackModal;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	screen: {
		position: "absolute",
		bottom: 32,
		width: "100%",
		alignItems: "center",
	},
	box: {
		borderRadius: 32,
		backgroundColor: colors.inputFieldBackground,
		padding: 16,
	},
}));
