import { MaterialIcons } from "@expo/vector-icons";
import { useLinkProps } from "@react-navigation/native";
import * as React from "react";
import { Linking, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { BuildStyleMethod } from "../../../styles/theming";
import { HasLevelOrShare } from "../../../tools/account";
import { isWeb, ws } from "../../../tools/generic";
import CondView from "../../meta/CondView";
import LockedFeatureModal from "../../profile/LockedFeatureModal";
import AppIcon from "../AppIcon";
import AppText from "../AppText";
import Popover from "../popover/Popover";
import PopoverMessage from "../popover/PopoverMessage";
import PopoverOptionsList from "../popover/PopoverOptionsList";
import UnreadBadge from "../UnreadBadge";
import IconButton from "./IconButton";

let showPopoverTimeout = null;

function ListButton({
	iconLeft,
	title,
	subtitle,
	subtitle2,
	onPress,
	onLongPress,
	options = [],
	reverseColor,
	requiredLevel,
	allowLegendaryShare,
	style,
	iconRightData,
	isSelected,
	subtitleTextProps,
	subtitle2TextProps,
	subiconLeft,
	noBorder,
	danger,
	hide,
	badgeValue,
	disabled,
	hideTitle,
	wide,
	narrow,
	to,
	action,
	hoverStrongBackground,
	popoverArrowPosition = "left",
	navigation,
	dispatch,
	currentLevel
}) {
	const styles = stylesMethod(global.theme);
	if (hide) return null;

	const moreOptionsPopoverRef = React.useRef();
	const titlePopoverRef = React.useRef();
	const lockedModalRef = React.useRef();

	let { onPress: navOnPress, ...linkProps } = to ? useLinkProps({ to, action }) : {onPress: null};

	const containerStyle = [styles.container];
	noBorder && containerStyle.push(styles.noBorder);
	containerStyle.push(style);
	isSelected && containerStyle.push(styles.selected);
	disabled && containerStyle.push(styles.disabled);

	// Close the modal when any of the option is pressed
	const usedOptions = React.useMemo(() =>
		options.map(
			(o) => ({
				...o,
				onPress: () => {
					moreOptionsPopoverRef.current.hide();
					o.onPress && o.onPress();
				},
			}),
			[options]
		)
	);

	const iconRight = iconRightData && <AppIcon color={global.colors.textDefault} {...iconRightData} />;

	if (iconLeft && iconLeft.type && iconLeft.name) {
		iconLeft = <AppIcon color={danger ? global.colors.danger : global.colors.textDefault} {...iconLeft} />;
	}

	const subiconSize = subiconLeft?.size || ws(12, 16);
	let subiconComponent = subiconLeft && (
		<AppIcon size={subiconSize} color={global.colors.textDefault} {...subiconLeft} />
	);

	let Container = onPress ? TouchableOpacity : View;
	const useExternalURL = to && to.startsWith("http");
	if (useExternalURL) {
		navOnPress = null;
		onPress = null;
	}
	if (to) {
		if (isWeb()) {
			Container = View;
		} else {
			Container = TouchableOpacity;
			onPress = useExternalURL ? () => Linking.openURL(to) : navOnPress;
		}
	}

	let hasInteraction = onPress || to;

	const internalOnPress = React.useCallback(
		(e) => {
			clearTimeout(showPopoverTimeout);
			titlePopoverRef.current?.hide();

			if (
				requiredLevel &&
				((allowLegendaryShare && !HasLevelOrShare(currentLevel, requiredLevel)) ||
					(!allowLegendaryShare && currentLevel < requiredLevel))
			) {
				lockedModalRef.current && lockedModalRef.current.show();
				return;
			}

			onPress && onPress(e);
		},
		[onPress, showPopoverTimeout]
	);

	const internalNavOnPress = React.useCallback(
		(e) => {
			clearTimeout(showPopoverTimeout);
			titlePopoverRef.current?.hide();
			navOnPress && navOnPress(e);
		},
		[navOnPress, showPopoverTimeout]
	);

	const subtitle2Container = (<CondView
		show={!!subtitle2}
		style={ws({ flexDirection: "row" }, { flexShrink: 1, justifyContent: "center" })}
	>
		{!!subtitle2 && typeof subtitle2 === "string" && (
			<AppText color="hintLight" size="small" {...subtitle2TextProps}>
				{/* There is a bug where adding a new line to the first non-truncated line will break the numberOfLines property 
				https://github.com/facebook/react-native/issues/21612 */}
				{subtitle2.replace(/[\n\t\r]/g, " ")}
			</AppText>
		)}
		{!!subtitle2 && typeof subtitle2 !== "string" && subtitle2}
	</CondView>)

	const Button = (
		<Hoverable
			onHoverIn={() => (showPopoverTimeout = setTimeout(() => titlePopoverRef.current?.show(), 250))}
			onHoverOut={() => {
				clearTimeout(showPopoverTimeout);
				titlePopoverRef.current?.hide();
			}}
		>
			{(isHovered) => (
				<Container
					style={[
						containerStyle,
						subtitle2 && ws(narrow, true) && styles.containerHigh,
						isHovered && hasInteraction && styles.hover,
						isHovered && hasInteraction && hoverStrongBackground && styles.hoverStrong,
						isHovered && hasInteraction && !!isSelected && styles.hoverSelected,
					]}
					onPress={internalOnPress}
					onLongPress={onLongPress}
					disabled={disabled}
					onClick={internalNavOnPress}
					{...linkProps}
					target={useExternalURL ? "_blank" : null}
				>
					<CondView show={iconLeft} style={styles.icon}>
						{iconLeft}
						<CondView show={subiconComponent} style={[styles.subicon, { bottom: 0, right: 0 }]}>
							{subiconComponent}
						</CondView>
					</CondView>
					<CondView
						show={!hideTitle}
						style={[
							styles.content,
							!narrow &&
								ws({
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									overflow: "hidden",
								}),
						]}
					>
						<View style={{ flex: 1, justifyContent: "center" }}>
							<AppText
								color={danger ? "danger" : reverseColor ? "textReversed" : "textDefault"}
								numberOfLines={1}
								ellipsizeMode="tail"
							>
								{title}
							</AppText>

							<AppText
								color="hintLight"
								size="small"
								numberOfLines={1}
								ellipsizeMode="tail"
								{...subtitleTextProps}
							>
								{subtitle?.replace(/[\n\t\r]/g, " ")}
							</AppText>
							{ws(null, subtitle2Container)}						
						</View>
						{ws(subtitle2Container, null)}
					</CondView>

					{!!badgeValue && <UnreadBadge value={badgeValue} important />}

					{(!!usedOptions && !!usedOptions.length && (
						<Popover
							ref={moreOptionsPopoverRef}
							popoverComponent={<PopoverOptionsList options={usedOptions} />}
							arrowPosition={ws("top")}
						>
							<IconButton
								icon={{
									type: MaterialIcons,
									name: "more-vert",
									color: global.colors.textDefault,
								}}
								transparent
								onPress={() => moreOptionsPopoverRef.current.show()}
								style={ws({ marginLeft: 4 })}
								stopPropagation
							/>
						</Popover>
					)) || <CondView show={isWeb() && wide} style={{ width: 32 }} />}
					{/* The CondView above is a placeholder so that the subtitle2s will be aligned in a list where some button have a "more" options and others don't */}
					<CondView show={iconRight} style={styles.icon}>
						{iconRight}
					</CondView>
					{requiredLevel && (
						<LockedFeatureModal
							ref={lockedModalRef}
							requiredLevel={requiredLevel}
							navigation={navigation}
						/>
					)}
				</Container>
			)}
		</Hoverable>
	);

	if (hideTitle) {
		return (
			<Popover
				ref={titlePopoverRef}
				arrowPosition={popoverArrowPosition}
				popoverComponent={<PopoverMessage message={title} />}
				noClose
				closeOnTap={false}
			>
				{Button}
			</Popover>
		);
	}

	return Button;
}

const mapStateToProps = (state, ownProps) => {
	let users = [];
	if (ownProps.allowLegendaryShare) {
		// refresh when users are updated
		users = state.users;
	}
	return {
		currentLevel: state.user?.profile.reward_level,
		users,
	};
};

export default connect(mapStateToProps)(ListButton);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		containerFirst: {
			borderTopColor: colors.lightBorder,
			borderTopWidth: 1,
		},
		container: {
			...ws({ height: 48, borderWidth: 1, borderColor: "transparent" }, { height: 64 }),
			flexDirection: "row",
			borderBottomColor: colors.lightBorder,
			borderBottomWidth: 1,
			paddingHorizontal: 8,
			alignItems: "center",
			// transitionDuration: "150ms",
		},
		containerHigh: ws({
			height: 64
		}, {height: 64}),
		noBorder: {
			borderBottomWidth: 0,
		},
		icon: {
			...Platform.select({
				web: { width: 24, height: 24 },
				native: { width: 48, height: 48 },
			}),
			alignItems: "center",
			justifyContent: "center",
			marginRight: ws(16, 8),
		},
		content: {
			justifyContent: "center",
			flex: 1,
		},

		selected: {
			backgroundColor: colors.selectedItemBackground,
		},
		hover: {
			borderColor: colors.lightBorder,
			backgroundColor: colors.hoverItemLightBackground,
		},
		hoverStrong: {
			backgroundColor: colors.hoverItemBackground,
		},
		hoverSelected: {
			backgroundColor: colors.hoverSelectedItemBackground,
		},
		subicon: {
			position: "absolute",
			borderRadius: 100,
			backgroundColor: colors.cardBackground,
			padding: 1,
		},
		disabled: {
			opacity: 0.5,
		},
	})
);
