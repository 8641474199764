import * as React from "react";
import { StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { receiveMacroGroups, receiveSheetMacroGroups } from "../../store/slices/macroGroupsSlice";
import { fetchSheetMacros, fetchUserMacros } from "../../store/slices/macrosSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import BoxModal from "../generic/modal/BoxModal";
import LockedFeatureModal from "../profile/LockedFeatureModal";
import MacroGroupItem from "./MacroGroupItem";

function ManageMacrosScreen({ user, game, sheet, sheets, gameSheet, macroGroups, route, navigation, dispatch, gameSheetMacroGroups, sheetMacroGroups}) {	
	if(!route.params) return null;

	const lockedModalRef = React.useRef();
	const confirmCreateModalRef = React.useRef();

	React.useEffect(() => {
		if (!global.userMacroFetched) {
			dispatch(fetchUserMacros(user.id));
		} 
		
		if (sheet && (!sheetMacroGroups || sheetMacroGroups.some((gId) => !macroGroups[gId]))) {
			dispatch(fetchSheetMacros(sheet.id));
		} 
		
		if (gameSheet) {
			if (!gameSheetMacroGroups || gameSheetMacroGroups.some((gId) => !macroGroups[gId])) {
				dispatch(fetchSheetMacros(gameSheet.id));
			}
		}
	}, [macroGroups, user.id, dispatch, sheet, sheetMacroGroups, gameSheetMacroGroups, gameSheet]);

	const isSelected = React.useCallback(
		(group) => {
			return (game && group.games.includes(game.id)) || (sheetMacroGroups?.includes(group.id));
		},
		[game, sheetMacroGroups]
	);

	const addMacroGroup = React.useCallback(
		async (group) => {
			if (sheet) {
				dispatch(receiveSheetMacroGroups({sheetId: sheet.id, groups: sheetMacroGroups.concat(group.id)}))
				apiFetch(`sheets/${sheet.id}/attach-macro-group`, "PATCH", { "macro-group": group.id });
			} else if (game) {				
				// You cannot add macros already associated with the sheet
				

				const editedGroup = { ...group, games: group.games.concat(game.id) };
				dispatch(receiveMacroGroups([editedGroup]));
				apiFetch(`users/${user.id}/dice-macro-groups/${group.id}`, "PATCH", editedGroup);
			}
		},
		[sheet, game, user, dispatch, navigation, sheetMacroGroups, gameSheet, gameSheetMacroGroups]
	);

	const removeMacroGroup = React.useCallback(
		(group) => {
			if (sheet) {
				dispatch(receiveSheetMacroGroups({sheetId: sheet.id, groups: sheetMacroGroups.rg_removeSimple(group.id)}))
				apiFetch(`sheets/${sheet.id}/remove-macro-group`, "PATCH", { "macro-group": group.id });
			} else if (game) {
				// You cannot remove macro associated with the sheet
				if (gameSheet && gameSheetMacroGroups.some((gId) => gId === group.id)) {
					return;
				}

				const editedGroup = { ...group, games: group.games.filter((gid) => gid !== game.id) };
				dispatch(receiveMacroGroups([editedGroup]));
				apiFetch(`users/${user.id}/dice-macro-groups/${group.id}`, "PATCH", editedGroup);
			}
		},
		[sheet, sheetMacroGroups, gameSheetMacroGroups, game, user, dispatch, gameSheet]
	);

	const toggle = React.useCallback(
		(group) => {
			if (isSelected(group)) {
				removeMacroGroup(group);
			} else {
				addMacroGroup(group);
			}
		},
		[isSelected, addMacroGroup, removeMacroGroup, user]
	);

	const createMacroGroup = React.useCallback(() => {
		const name = _("My macros", "default macros group name");
		apiFetch(`users/${user.id}/dice-macro-groups`, "POST", {
			user: user.id,
			name,
			description: "",
		}).then((group) => {
			dispatch(receiveMacroGroups([{ ...group }]));
			navigation.navigate("MacroGroupScreen", { macroGroup: group, sheetId: sheet?.id });
		});
	}, [user.id, sheet?.id]);

	const mainSheet = game ? gameSheet : sheet;
	// Used when we are copying a sheet in game creation, to show the macros even if we don't own them
	const templateSheet = mainSheet && mainSheet.public_template ? sheets[game.public_template] : null;

	const sheetMacroGroupsItems = Object.values(macroGroups).filter(
		(g) =>
			(mainSheet &&  macroGroups.bySheet[mainSheet.id]?.includes(g.id)) ||
			(templateSheet && macroGroups.bySheet[templateSheet.id]?.includes(g.id))
	);

	let availableMacros = Object.values(macroGroups).filter(
		(g) => g.user === user.id || sheetMacroGroupsItems.some((g2) => g2.id === g.id)
	);

	availableMacros
		.sort((a, b) => a.name.localeCompare(b.name))
		.sort((a, b) => {
			const aSelected = isSelected(a);
			return aSelected === isSelected(b) ? 0 : aSelected ? -1 : 1;
		});

	const getOptions = React.useCallback(
		(group) => {
			const options = [];

			if (gameSheet && gameSheetMacroGroups?.some((gId) => gId === group.id)) {
				return;
			}

			sheet &&
				options.push({
					title: isSelected(group)
						? _("Detach from sheet", "remove macro group from sheet")
						: _("Attach to sheet", "add macro group to sheet"),
					onPress: () => toggle(group),
				});

			game &&
				options.push({
					title: isSelected(group)
						? _("Remove from game", "remove macro group from game")
						: _("Add to game", "add macro group to game"),
					onPress: () => toggle(group),
				});

			return options;
		},
		[isSelected, game, sheet, toggle, gameSheet, gameSheetMacroGroups]
	);

	const createComponent = (
		<>
			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create group")}
				message={_("Create a new macros group?")}
				onConfirm={createMacroGroup}
			/>
			<LockedFeatureModal ref={lockedModalRef} requiredLevel={2} navigation={navigation} />
			<FAB
				icon="plus"
				style={globalStylesMethod(global.theme).fab}
				onPress={() => {
					if (user.profile.reward_level >= 2) {
						confirmCreateModalRef.current.show();
					} else {
						lockedModalRef.current.show();
					}
				}}
			/>
		</>
	);

	if (sheet) {
		return (
			<>
				<AppScreenView
					scroll
					borderless
					style={ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() })}
				>
					<View style={styles.sectionHeaders}>
						<AppText bold>{_("Sheet macros")}</AppText>
						<AppText color="hint">
							{_("Attach macros to this sheet and anyone using it will have access to them.")}
						</AppText>
						<AppText color="attention" hide={!availableMacros?.length}>
							{_("Use the overflow menu to the right to attach a macro.")}
						</AppText>
					</View>
					{availableMacros.map((item, index) => (
						<MacroGroupItem
							key={item.id}
							macroGroup={item}
							index={index}
							options={getOptions(item)}
							isSelected={isSelected(item)}
							sheetId={mainSheet.id}
						/>
					))}
					{createComponent}
				</AppScreenView>
				<ButtonFooter
					narrow={false}
					style={ws({width: fullScreenContentWidth()})}
					buttonProps={[
						{
							title: _("Done"),
							onPress: () => navigation.goBack(),
						},
					]}
				/>
			</>
		);
	}

	return (
		<AppScreenView
			scroll
			borderless
			style={ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() })}
		>
			<View style={styles.sectionHeaders}>
				<AppText bold>{_("Sheet macros")}</AppText>
				<AppText color="hint">
					{_("Macros imported from the game's character sheet template.")}{" "}
					<AppText color="hint">{_("Head to the sheet template to add or remove.")}</AppText>
				</AppText>
			</View>

			{sheetMacroGroupsItems.map((item, index) => (
				<MacroGroupItem
					key={item.id}
					macroGroup={item}
					index={index}
					isSelected={isSelected(item)}
					sheetId={mainSheet.id}
				/>
			))}

			<View style={styles.sectionHeaders}>
				<AppText bold>{_("Personal macros")}</AppText>
				<AppText color="hint">{_("Add a macro group to the game for yourself only.")}</AppText>
			</View>

			{availableMacros.map((item, index) => (
				<MacroGroupItem
					key={item.id}
					macroGroup={item}
					index={index}
					options={getOptions(item)}
					isSelected={isSelected(item)}
					sheetId={mainSheet.id}
				/>
			))}

			{createComponent}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	if(!ownProps.route.params) return {}
	const { gameId, sheetId, gameslug } = ownProps.route.params;
	let game = state.games[gameId || gameslug];

	return {
		macroGroups: state.macroGroups,
		sheet: state.sheets[sheetId],
		sheetMacroGroups: state.macroGroups.bySheet[sheetId],
		sheets: state.sheets,
		user: state.user,
		game,
		gameSheet: game ? state.sheets[game.sheet_template] : null,
		gameSheetMacroGroups: game ? state.macroGroups.bySheet[game.sheet_template] : null,
	};
};

export default connect(mapStateToProps)(ManageMacrosScreen);

const styles = StyleSheet.create({
	sectionHeaders: {
		marginHorizontal: 8,
		marginVertical: 16,
	},
});
