import background_ico_co_shield_1 from "../../assets/images/avatars/co/bg/ico_co_shield_1.svg";
import background_ico_co_shield_2 from "../../assets/images/avatars/co/bg/ico_co_shield_2.svg";
import background_ico_co_shield_3 from "../../assets/images/avatars/co/bg/ico_co_shield_3.svg";
import background_ico_go_shield_1 from "../../assets/images/avatars/go/bg/ico_go_shield_1.svg";
import background_ico_go_shield_2 from "../../assets/images/avatars/go/bg/ico_go_shield_2.svg";
import background_ico_go_shield_3 from "../../assets/images/avatars/go/bg/ico_go_shield_3.svg";
import background_ico_le_shield_1 from "../../assets/images/avatars/le/bg/ico_le_shield_1.svg";
import background_ico_le_shield_2 from "../../assets/images/avatars/le/bg/ico_le_shield_2.svg";
import background_ico_le_shield_3 from "../../assets/images/avatars/le/bg/ico_le_shield_3.svg";
import background_ico_si_shield_1 from "../../assets/images/avatars/si/bg/ico_si_shield_1.svg";
import background_ico_si_shield_2 from "../../assets/images/avatars/si/bg/ico_si_shield_2.svg";
import background_ico_si_shield_3 from "../../assets/images/avatars/si/bg/ico_si_shield_3.svg";
import background_ico_pl_shield_1 from "../../assets/images/avatars/pl/bg/ico_pl_shield_1.svg";
import background_ico_pl_shield_2 from "../../assets/images/avatars/pl/bg/ico_pl_shield_2.svg";
import background_ico_pl_shield_3 from "../../assets/images/avatars/pl/bg/ico_pl_shield_3.svg";

const BackgroundStrings = {
	ico_co_shield_1: background_ico_co_shield_1,
	ico_co_shield_2: background_ico_co_shield_2,
	ico_co_shield_3: background_ico_co_shield_3,
	ico_go_shield_1: background_ico_go_shield_1,
	ico_go_shield_2: background_ico_go_shield_2,
	ico_go_shield_3: background_ico_go_shield_3,
	ico_le_shield_1: background_ico_le_shield_1,
	ico_le_shield_2: background_ico_le_shield_2,
	ico_le_shield_3: background_ico_le_shield_3,
	ico_si_shield_1: background_ico_si_shield_1,
	ico_si_shield_2: background_ico_si_shield_2,
	ico_si_shield_3: background_ico_si_shield_3,
	ico_pl_shield_1: background_ico_pl_shield_1,
	ico_pl_shield_2: background_ico_pl_shield_2,
	ico_pl_shield_3: background_ico_pl_shield_3,
};

export default BackgroundStrings;
