import * as React from "react";
import { _ } from "../../../i18n/i18n";
import { fullScreenContentWidth } from "../../../styles/dynamicStyles";
import { ws } from "../../../tools/generic";
import { simpleReducer } from "../../../tools/react";
import AppScreenView from "../../AppScreenView";
import ButtonFooter from "../../generic/buttons/ButtonFooter";
import GameSettingsPace from "../settings/GameSettingsPace";
import GameSettingTags from "../settings/GameSettingTags";
import GameSettingToggles from "../settings/GameSettingToggles";

function WizardCreateGameMetaData({ route, navigation }) {
	const { game: gameReceived } = route.params;

	const defaultHidden = new RegExp(/test/i).test(gameReceived.name);

	const [game, updateGame] = React.useReducer(simpleReducer, {
		tags: [],
		expected_pace: 0,
		hidden: defaultHidden,
		allow_spectators: true,
		allow_public_chat: true,
		...gameReceived,
	});

	React.useEffect(() => {
		if (route.params?.game) {
			updateGame(route.params?.game);
		}
	}, [route.params?.game]);

	return (
		<>
			<AppScreenView
				scroll
				style={{ backgroundColor: global.colors.backgroundSecondary, marginTop: 32 }}
				borderless
			>
				<GameSettingsPace game={game} onChangePace={(expected_pace) => updateGame({ expected_pace })} />
				<GameSettingTags game={game} onTagsUpdated={(tags) => updateGame({ tags })} />
				<GameSettingToggles
					game={game}
					onGameUpdated={(editedGame) => {
						updateGame({
							beginner_friendly: editedGame.beginner_friendly,
							allow_spectators: editedGame.allow_spectators,
							allow_public_chat: editedGame.allow_public_chat,
							allow_export: editedGame.allow_export,
							hidden: editedGame.hidden,
						});
					}}
				/>
			</AppScreenView>
			<ButtonFooter
				narrow={false}
				style={ws({ maxWidth: fullScreenContentWidth() })}
				buttonProps={[
					{ title: _("Back"), onPress: () => navigation.navigate("WizardCreateGameDiceSets", { game }) },
					{ title: _("Next"), onPress: () => navigation.navigate("WizardCreateGamePassword", { game }) },
				]}
			/>
		</>
	);
}

export default WizardCreateGameMetaData;
