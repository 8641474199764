import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import {
	createInteractiveHelperPanel,
	fetchHelperPanels,
	receiveEditedHelper,
	setOpenedHelper,
	setOpenedPanelId
} from "../../store/slices/interactiveHelpersSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { isWeb, ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import InputField from "../generic/InputField";
import BoxModal from "../generic/modal/BoxModal";
import OverflowMenu from "../generic/OverflowMenu";
import InteractiveHelperPanelItem from "./InteractiveHelperPanelItem";

function EditInteractiveHelperScreen({ route, navigation, dispatch, helper, panels, userId }) {
	const confirmCreateModalRef = React.useRef();
	const [creating, setcreating] = React.useState(false);
	const [name, setname] = React.useState(helper.name);
	const [description, setdescription] = React.useState(helper.description);

	const saveTimeout = React.useRef(null);

	const previousName = usePrevious(name);
	const previousDescription = usePrevious(description);

	const panelsArray = React.useMemo(() => Object.values(panels), [panels]);

	React.useEffect(() => {
		dispatch(fetchHelperPanels(helper.id));
	}, [helper.id]);

	const createPanel = React.useCallback(async () => {
		setcreating(true);
		const panel = await dispatch(createInteractiveHelperPanel(helper.id));
		setcreating(false);
		navigation.navigate("EditInteractiveHelperPanelScreen", { panelId: panel.id, helperId: helper.id });
	}, [helper]);

	const save = React.useCallback(() => {
		if (!saveTimeout.current) return; // If there isn't anything to save, don't save

		clearTimeout(saveTimeout.current);
		dispatch(receiveEditedHelper({ ...helper, name, description }));
		apiFetch(`users/${userId}/helpers/${helper.id}`, "PATCH", { name, description });
	}, [name, description, helper, userId]);

	React.useEffect(() => {
		// Check to not save on component mount
		if ((previousName === name || !previousName) && (previousDescription === description || !previousDescription)) {
			return () => null;
		}
		saveTimeout.current = setTimeout(() => save(), 3000);
		return () => {
			clearTimeout(saveTimeout.current);
		};
	}, [name, description]);

	React.useEffect(() => {
		const removeScreenBlurListener = navigation.addListener("blur", save);
		return () => {
			removeScreenBlurListener();
		};
	}, [save]);

	// Save on unmount
	const saveRef = React.useRef(save);
	saveRef.current = save;
	React.useEffect(() => {
		return () => {
			saveRef.current();
		};
	}, []);

	const openPanel = React.useCallback(async () => {
		await dispatch(setOpenedHelper(null));
		await dispatch(setOpenedHelper(helper));
		dispatch(setOpenedPanelId(helper.default_panel.id));
	}, [helper]);

	React.useEffect(() => {
		const options = [];

		options.push({
			title: _("Preview panel"),
			icon: { type: MaterialCommunityIcons, name: "eye" },
			onPress: openPanel,
		});

		navigation.setOptions({
			headerRight: () => <OverflowMenu options={options} />,
		});
	}, [openPanel]);
	return (
		<AppScreenView
			style={[ws({ maxWidth: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })]}
			borderless={!isWeb()}
			scroll
		>
			<InputField value={name} onChangeText={setname} label={_("Name", "interactive helper input field")} />
			<InputField
				value={description}
				onChangeText={setdescription}
				label={_("Description", "interactive helper input field")}
				multiline
				autoGrow
			/>

			<AppText bold style={{ margin: 8 }}>
				{_("Panels", "list panels in interactive helper")}
			</AppText>

			{panelsArray.map((p) => (
				<InteractiveHelperPanelItem key={p.id} panel={p} helper={helper} />
			))}

			{!panelsArray.length && (
				<AppText centered color="hint" style={{ marginTop: 64 }}>
					{_("Press the + button to add panels to your helper.")}
				</AppText>
			)}

			<FAB
				icon="plus"
				style={globalStylesMethod(global.theme).fab}
				onPress={() => confirmCreateModalRef.current.show()}
				disabled={creating}
			/>
			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create panel")}
				message={_("Add a new panel to this interactive helper?")}
				onConfirm={createPanel}
			/>
		</AppScreenView>
	);
}
const mapStateToProps = (state, ownProps) => {
	const { helperId } = ownProps.route.params;
	return {
		helper: state.interactiveHelpers.all[helperId],
		userId: state.user.id,
		panels: state.interactiveHelpers.panels[helperId] || {},
	};
};

export default connect(mapStateToProps)(EditInteractiveHelperScreen);


