String.prototype.rg_replaceFrom = function (lookup, newStr, fromIndex) {
	const index = this.indexOf(lookup, fromIndex);
	return this.substr(0, index) + newStr + this.substr(index + lookup.length);
};

String.prototype.rg_lastMatch = function (regex) {
	let match;
	let lastMatch;
	while ((match = regex.exec(this)) !== null) {
		// If regex can match a zero-length string (ie: ^ or $), a safety needs to be added
		if (match.index === regex.lastIndex && match[0] === "") {
			regex.lastIndex++;
		}
		lastMatch = match;
	}
	return lastMatch;
};

String.prototype.rg_regexIndexOf = function (regex, startpos) {
	const indexOf = this.substr(startpos || 0).search(regex);
	return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
};
