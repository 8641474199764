import { MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { _ } from "../../i18n/i18n";
import AppButton from "../generic/buttons/AppButton";

function InternalSendMessageButton({ sendMessage, isComments, messageType }) {
	return (
		<View style={styles.container}>
			<AppButton
				transparentHighlight
				style={{
					flexDirection: "row-reverse",
					width: null,
					minHeight: 24,
					paddingHorizontal: 8,
					marginVertical: 0,
				}}
				iconContainerStyle={{ marginRight: 0, marginLeft: 4, height: 24, width: 24 }}
				title={_("Send", "send message")}
				icon={{ type: MaterialIcons, name: "send", color: global.colors.textLight }}
				onPress={() => (isComments ? sendMessage("comment") : sendMessage(messageType))}
			/>
		</View>
	);
}

export default InternalSendMessageButton;

const styles = StyleSheet.create({
	container: {
		margin: 0,
	},
});
