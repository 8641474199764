import * as React from "react";
import { View, StyleSheet, Animated, TextInput, LayoutAnimation } from "react-native";
import AppText from "./AppText";
import { colors } from "../../styles/colors";
import { sizes } from "../../styles/font";
import { getInterpolatedState } from "../../tools/animation";
import { TextInput as PaperTextInput } from "react-native-paper";
import Autocomplete from "./Autocomplete";
import { cStyle, usePrevious } from "../../tools/react";
import { isWeb, ws } from "../../tools/generic";

import { BuildStyleMethod } from "../../styles/theming";
import { TouchableWithoutFeedback } from "react-native";

function InputField(props) {
	const styles = stylesMethod(global.theme);
	const { label, placeholder, value, usePaper, style, inputStyle, autocompleteProps, inputRef } = props;

	const [showingLabelOverhead, setShowingLabelOverhead] = React.useState(!!value || !!placeholder);
	const [selection, setselection] = React.useState({ start: 0, end: 0 });
	const [selectionProp, setselectionProp] = React.useState(null);
	const [text, settext] = React.useState(value || "");
	const [focused, setfocused] = React.useState(false);

	const [labelStyle, setLabelStyle] = React.useState(
		showingLabelOverhead ? labelStyles.overhead : labelStyles.placeholder
	);
	const [placeholderToOverhead] = React.useState(new Animated.Value(showingLabelOverhead ? 1 : 0));

	const textInputReference = inputRef || React.useRef(null);
	const autocompleteRef = React.useRef();

	const setShowLabelOverhead = (flag, animated) => {
		if (animated)
			LayoutAnimation.configureNext(LayoutAnimation.create(150, LayoutAnimation.Types.linear, "scaleXY"));
		if (flag) {
			setLabelStyle(labelStyles.overhead);
			Animated.timing(placeholderToOverhead, { toValue: 1, duration: 150, useNativeDriver: false }).start();
		} else {
			setLabelStyle(labelStyles.placeholder);
			Animated.timing(placeholderToOverhead, { toValue: 0, duration: 150, useNativeDriver: false }).start();
		}
		setShowingLabelOverhead(flag);
	};

	const onFocus = React.useCallback(() => {
		if (label) setShowLabelOverhead(true);
		props.onFocus && props.onFocus();
		setfocused(true);
	}, [props.onFocus, label]);

	const onBlur = React.useCallback(() => {
		if (!value && label) {
			setShowLabelOverhead(false);
		}
		props.onBlur && props.onBlur();
		setfocused(false);
	}, [value, props.onBlur, label]);

	React.useEffect(() => {
		if (props.autoGrow && isWeb()) {
			const target = textInputReference?.current;
			target.style.height = 0;
			const height = target.scrollHeight;
			target.style.height = `${height}px`;
		}
	}, [props.autoGrow]);

	const previousValue = usePrevious(value);

	React.useEffect(() => {
		if (previousValue != value && value != text) {
			settext(value);
		}
	}, [value, text]);

	React.useEffect(() => {
		if (textInputReference.current?.isFocused() || !label) {
			return () => null;
		}

		const shouldShowLabelOverhead = !!value || placeholder;

		if (shouldShowLabelOverhead !== showingLabelOverhead) {
			setShowLabelOverhead(shouldShowLabelOverhead, false);
		}
	}, [value, placeholder, showingLabelOverhead, label]);

	const handleChange = React.useCallback((e) => {
		e.target.style.height = 0;
		const height = e.target.scrollHeight;
		e.target.style.height = `${height}px`;
	}, []);

	const handleKey = React.useCallback((e) => {
		autocompleteRef.current?.handleKey(e);
	}, []);

	if (usePaper) {
		return <PaperTextInput {...props} />;
	}

	const fontStyle = getInterpolatedState(fontStyles.placeholderFont, fontStyles.overheadFont, placeholderToOverhead);

	return (
		<TouchableWithoutFeedback
			accessible={false}
			onPress={() => (textInputReference.current?.focused ? null : textInputReference.current?.focus())}
		>
			<View style={cStyle([styles.container, [!label, styles.noLabel], style])}>
				<AppText
					style={[styles.common, labelStyle, fontStyle]}
					hide={!label}
					pointerEvents={showingLabelOverhead ? null : "none"}
				>
					{label}
				</AppText>
				<TextInput
					ref={textInputReference}
					placeholderTextColor={global.colors.placeholder}
					multiline={props.autoGrow}
					{...props}
					value={text}
					onKeyPress={handleKey}
					style={[{ color: global.colors.textDefault, ...ws({ outlineWidth: 0 }, null) }, inputStyle]}
					onChange={props.autoGrow && isWeb() ? handleChange : null}
					onChangeText={(text) => {
						props.onChangeText && props.onChangeText(text);
						settext(text);
					}}
					onFocus={onFocus}
					onBlur={onBlur}
					selection={selectionProp}
					onSelectionChange={
						autocompleteProps
							? (event) => {
									// prettier-ignore
									const {nativeEvent: { selection: { start, end } }} = event;
									setselection({ start, end });
									setselectionProp(null);
									props.onSelectionChange && props.onSelectionChange(event);
							  }
							: props.onSelectionChange
					}
				/>
				{!!autocompleteProps && (
					<Autocomplete
						ref={autocompleteRef}
						textInput={textInputReference.current}
						selection={selection}
						text={text}
						focused={focused}
						{...autocompleteProps}
						setselection={setselectionProp}
						changeText={(newText) => {
							settext(newText);
							props.onChangeText && props.onChangeText(newText);
						}}
					/>
				)}
			</View>
		</TouchableWithoutFeedback>
	);
}

export default React.memo(InputField);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.inputFieldBackground,
			borderBottomColor: colors.boldBorder,
			borderBottomWidth: 1,
			paddingTop: 28,
			paddingHorizontal: 8,
			paddingBottom: 8,
		},
		noLabel: {
			paddingTop: 8,
		},
		common: {
			position: "absolute",
		},
	})
);

const labelStyles = StyleSheet.create({
	placeholder: { top: 28, left: 10 },
	overhead: { top: 8, left: 10 },
});

const fontStyles = {
	placeholderFont: {
		color: colors.placeholder,
		fontSize: sizes.average,
	},
	overheadFont: {
		color: colors.primary,
		fontSize: sizes.small,
	},
};
