import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const macroGroupsSlice = createSlice({
	name: "macroGroups",
	initialState: {
		// [sheetId]: []
		bySheet: {},
	},
	reducers: {
		receiveMacroGroups: (state, { payload: groups }) => ({ ...state, ...groups.rg_toStore() }),
		receiveSheetMacroGroups: (state, { payload: { sheetId, groups } }) => {
			state.bySheet[sheetId] = groups.map((g) => g.id || g);
		},
		removeMacroGroups: (state, { payload: groupsToRemove }) => {
			const allGroup = Object.values(state).filter(
				(existingGroup) => !groupsToRemove.some((g) => g.id === existingGroup.id)
			);
			return allGroup.rg_toStore();
		},
	},
});

export const { receiveMacroGroups, removeMacroGroups, receiveSheetMacroGroups } = macroGroupsSlice.actions;

export const saveMacroGroupChanges = (userId, groupId, groupData) => {
	return (dispatch, getState) => {
		const { macroGroups } = getState();
		const group = macroGroups[groupId];
		if (group) {
			dispatch(receiveMacroGroups([{ ...group, ...groupData }]));
			apiFetch(`users/${userId}/dice-macro-groups/${groupId}`, "PATCH", groupData);
		}
	};
};
