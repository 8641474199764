import { Feather, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { deleteGameAlert, updateAlert } from "../../store/slices/gamealertsSlice";
import { fetchGame } from "../../store/slices/gamesSlice";
import { fetchGameTagsByIds } from "../../store/slices/gameTagsSlice";
import { BuildStyleMethod } from "../../styles/theming";
import { ws } from "../../tools/generic";
import GameCard from "../games/GameCard";
import GameTagHorizontalList from "../gametags/GameTagHorizontalList";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import IconButton from "../generic/buttons/IconButton";
import BoxModal from "../generic/modal/BoxModal";

function GameAlertItem({ gameAlert, tags, dispatch, user, game }) {
	const styles = stylesMethod(global.theme);

	const confirmDeleteModalRef = React.useRef();

	const selectedTags = React.useMemo(() => {
		return tags.filter((t) => gameAlert.must_have_tags.includes(t.id));
	}, [tags]);

	const avoidedTags = React.useMemo(() => {
		return tags.filter((t) => gameAlert.tags_to_avoid.includes(t.id));
	}, [tags]);

	const deleteAlert = React.useCallback(() => {
		dispatch(deleteGameAlert(gameAlert));
	}, [user, gameAlert]);

	const ignoreGame = React.useCallback(() => {
		dispatch(updateAlert({ ...gameAlert, last_game_triggering: null }));
		apiFetch(`users/${user.id}/game-alerts/${gameAlert.id}/ignore`, "POST");
	}, [gameAlert.id, user.id]);

	const blockGame = React.useCallback(() => {
		dispatch(updateAlert({ ...gameAlert, last_game_triggering: null }));
		apiFetch(`users/${user.id}/game-alerts/${gameAlert.id}/block`, "POST");
	}, [gameAlert.id, user.id]);

	React.useEffect(() => {
		if (gameAlert.last_game_triggering && !game) {
			dispatch(fetchGame(gameAlert.last_game_triggering));
		}
	}, [gameAlert.last_game_triggering, game]);

	React.useEffect(() => {
		const missingTags = gameAlert.must_have_tags.filter((gat) => !tags.some((t) => t.id == gat));
		const missingAvoidTags = gameAlert.tags_to_avoid.filter((gat) => !tags.some((t) => t.id == gat));
		if (missingTags.length || missingAvoidTags.length) {
			dispatch(fetchGameTagsByIds([...missingTags, ...missingAvoidTags]));
		}
	}, [gameAlert, tags]);

	const ButtonContainerTag = ws(ButtonsRow, View)

	const LastGameComponent = (
		<View>
			<Divider theme={{ dark: true }} style={{ marginVertical: 8 }} />
			<AppText bold color="primary" style={{ marginBottom: 8 }}>
				{_("Last notification:")}
			</AppText>
			<GameCard game={game} large />

			<ButtonContainerTag>
				<AppButton size="large" title={_("Ignore this game for now")} onPress={ignoreGame} />
				<AppButton size="large" title={_("Do not show me further alerts for this game")} onPress={blockGame} />
			</ButtonContainerTag>
		</View>
	);

	const specialConditions = [];
	if (gameAlert.open_games_only)
		specialConditions.push(_("Open games only", "set game alert to only triggers for open games"));
	if (gameAlert.low_requests)
		specialConditions.push(_("Low-demand games only", "set an alarm to only trigger for games with a low demade"));

	return (
		<View style={styles.container}>
			<View style={styles.topArea}>
				<View style={{flex: 1}}>
					<AppText bold color="secondary" style={{ marginBottom: 8 }}>
						{_("Notifier")}{" "}
						<AppText hide={!specialConditions.length}>({specialConditions.join("/")})</AppText>
					</AppText>
					<GameTagHorizontalList
						tagsToShow={selectedTags}
						large
						tagIcon={<MaterialIcons name="check-circle" color={global.colors.primary} />}
					/>
					{!selectedTags.length && (
						<AppText style={{marginBottom: 4}}>
							{_("Any tags", "alerts will trigger for any tags")}
						</AppText>
					)}

					<GameTagHorizontalList
						tagsToShow={avoidedTags}
						large
						tagIcon={<MaterialIcons name="cancel" color={global.colors.danger} />}
					/>
				</View>

				<IconButton
					transparent
					icon={{ type: Feather, name: "delete" }}
					onPress={game ? () => confirmDeleteModalRef.current.show() : deleteAlert}
				/>
			</View>
			{game && LastGameComponent}

			<BoxModal
				ref={confirmDeleteModalRef}
				title={_("Delete notifier")}
				message={_(
					"This notifier has been triggered. If you delete it, you will lose the information it contains."
				)}
				isDelete
				onConfirm={deleteAlert}
			/>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		tags: state.gameTags || Array.rg_empty,
		game: state.games[ownProps.gameAlert.last_game_triggering],
	};
};

export default connect(mapStateToProps)(GameAlertItem);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			padding: 16,
			borderColor: colors.lightBorder,
			borderWidth: 1,
		},
		topArea: {
			flexDirection: "row",
			alignItems: "center",
		},
	})
);
