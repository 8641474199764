import * as React from "react";
import { StyleSheet } from "react-native";
import { useLayout } from "react-native-web-hooks";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import OAIDescriber from "./OAIDescriber";
import OAIQuestStarters from "./OAIQuestStarters";

function OpenAIPlaygroundScreen() {
	const { onLayout } = useLayout();

	return (
		<AppScreenView
			scroll
			onLayout={onLayout}
			style={[ws({ width: fullScreenContentWidth(), paddingLeft: fullScreenLeftPadding() })]}
		>
			{/* <OAIGenericRequest />
			<Divider theme={{ dark: true }} style={{ marginVertical: 16 }} /> */}
			<OAIQuestStarters />
		</AppScreenView>
	);
}

export default OpenAIPlaygroundScreen;

const styles = StyleSheet.create({
	container: {},
});
