import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppScreenView from "../AppScreenView";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import BoxModal from "../generic/modal/BoxModal";
import { apiFetch } from "../../network/apiFetch";
import { connect } from "react-redux";
import { logout } from "../../tools/account";

function DeleteAccountScreen({ dispatch, user }) {
	const modalRef = React.useRef();

	const deleteAccount = React.useCallback(() => {
		apiFetch(`profiles/${user.id}`, "DELETE");
		logout(user.id, dispatch);
	}, [user.id]);

	const options = [
		{ title: _("Cancel", "do not delete account"), transparent: true },
		{ title: _("DELETE", "delete account last confirmation"), onPress: deleteAccount, danger: true },
	];

	return (
		<AppScreenView style={styles.container}>
			<AppText bold>{_("You are about to PERMANENTLY delete your account.")}</AppText>

			<AppText style={{ marginVertical: 16 }}>
				{_(
					"Please note that even though your direct messages will be deleted, the texts you wrote as a character will stay visible in games. Those characters will become Non-player characters."
				)}
			</AppText>

			<AppText style={{ marginVertical: 16 }} bold color="danger">
				{_(
					"This action is irreversible and you won't be able to recover the data lost from deleting your account."
				)}
			</AppText>

			<View style={{ height: 64, marginTop: 128 }}>
				<AppButton
					title={_("I understand, delete my account.")}
					danger
					size="fullWidth"
					onPress={() => modalRef.current.show()}
				/>
			</View>

			<BoxModal ref={modalRef} title={_("Delete account", "app settings")} options={options}>
				<AppText>
					{_("This is your last chance. Do you really want to PERMANENTLY delete your account?")}
				</AppText>
			</BoxModal>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(DeleteAccountScreen);

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
	},
});
