import * as React from "react";
import { ActivityIndicator, FlatList, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { fetchGameTags } from "../../../store/slices/gameTagsSlice";
import { colors } from "../../../styles/colors";
import { idKeyExtractor, isWeb, ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import WizardScreen from "./WizardScreen";

function WizardAvoidTagsScreen({ route, navigation, dispatch, tags: tagProps }) {
	const preferredTags = React.useMemo(() => {
		const tagNames = route.params?.preferredTags || [];
		return tagProps.filter((t) => tagNames.includes(t.name));
	}, [route.params?.preferredTags, tagProps]);

	const avoidedTags = React.useMemo(() => {
		const tagNames = route.params?.avoidedTags || [];
		return tagProps.filter((t) => tagNames.includes(t.name));
	}, [route.params?.avoidedTags, tagProps]);

	// selectedTags is the data received from SelectGameTagsScreen
	// transfer it to avoidedTags
	React.useEffect(() => {
		const selectedTags = route.params?.selectedTags;
		if (selectedTags) {
			const call = () =>
				navigation.setParams({ selectedTags: null, avoidedTags: selectedTags.map((t) => t.name) });
			// On web, calling setParams immediately on focus won't update the url. So add a small timeout.
			isWeb() ? setTimeout(call, 100) : call();
		}
	}, [route.params?.selectedTags]);

	let tags = React.useMemo(() => tagProps.filter((t1) => !preferredTags.some((t2) => t1.id === t2.id)), [
		tagProps,
		preferredTags,
	]);

	tags.sort((a, b) => {
		const aIn = avoidedTags.some((t) => t.id === a.id);
		const bIn = avoidedTags.some((t) => t.id === b.id);

		if (aIn && !bIn) return -1;
		if (!aIn && bIn) return 1;
		return 0;
	});

	React.useEffect(() => {
		if (!tags.length) {
			dispatch(fetchGameTags());
		}
	}, [tags]);

	const toggleTag = React.useCallback(
		(tag) => {
			let tags = avoidedTags.map((t) => t.name);
			if (avoidedTags.some((t) => t.id === tag.id)) {
				tags = tags.rg_removeSimple(tag.name);
			} else {
				tags.push(tag.name);
			}
			navigation.setParams({ avoidedTags: tags });
		},
		[avoidedTags]
	);

	return (
		<WizardScreen>
			<AppText bold centered style={ws({ marginBottom: 16 }, { marginTop: 16 })}>
				{_("I want to avoid the tags")}
			</AppText>

			<FlatList
				contentContainerStyle={{ justifyContent: "center", alignItems: "center", flex: 1 }}
				columnWrapperStyle={{ margin: 8 }}
				keyExtractor={idKeyExtractor}
				data={tags.slice(0, 10)}
				renderItem={({ item }) => (
					<AppButton
						title={item.name}
						isToggle
						toggled={avoidedTags.some((t) => t.id === item.id)}
						style={{ marginHorizontal: 8 }}
						onPress={() => toggleTag(item)}
					/>
				)}
				ListEmptyComponent={<ActivityIndicator color={global.colors.hint}/>}
				numColumns={2}
			/>
			<AppText
				size="large"
				bold
				textOnlyStyle={{ textAlign: "right", flex: 1 }}
				onPress={() =>
					navigation.navigate("SelectGameTagsScreen", {
						activeTags: avoidedTags || [],
						sendToScreen: route.name,
					})
				}
			>
				{_("More tags", "find a game wizard")}
			</AppText>
		</WizardScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		tags: state.gameTags || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(WizardAvoidTagsScreen);


