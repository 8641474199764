import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Divider, FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { receiveTokens } from "../../store/slices/tokensSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { BuildStyleMethod } from "../../styles/theming";
import { getCharName } from "../../tools/characters";
import { isGM } from "../../tools/games";
import { idEqual, ws } from "../../tools/generic";
import { usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import CharacterAvatar from "../avatar/CharacterAvatar";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import ColorSelector from "../generic/ColorSelector";
import TokenSelector from "../generic/TokenSelector";
import CondView from "../meta/CondView";
import CharacterTokens from "./CharacterTokens";


function ManageTokensScreen({ route, navigation, dispatch, user }) {
	const styles = stylesMethod(global.theme);
	let { character, line: lineReceived, selectedToken: selectedTokenReceived } = route.params;

	
	const prevSelectedTokenReceived = usePrevious(selectedTokenReceived);

	const [line, setline] = React.useState(lineReceived || global.currentLine);
	const [selectedToken, setselectedToken] = React.useState(null);
	const [visibleTokens, setvisibleTokens] = React.useState([]);
	const [content, setcontent] = React.useState();

	React.useEffect(() => {
		if (selectedToken) {
			const refreshedToken = visibleTokens.find((t) => t.id === selectedToken.id);
			if (refreshedToken) setselectedToken(refreshedToken);
		}
	}, [visibleTokens]);

	React.useEffect(() => {
		setcontent(selectedToken?.content);
		// makes sure the params match, so that if the option modal screen set it to null, it will trigger change
		navigation.setParams({ selectedToken });
	}, [selectedToken]);
	idEqual;

	React.useEffect(() => {
		if (!idEqual(selectedTokenReceived, prevSelectedTokenReceived)) {
			setselectedToken(selectedTokenReceived);
		}
	}, [selectedTokenReceived]);

	const addToken = React.useCallback(() => {
		const lineId = line?.id || 0;
		return apiFetch(`games/${character.game}/tokens`, "POST", {
			add_line_id: lineId,
			game: character.game,
			character: character.id,
			creator_see_only: false,
		}).then((data) => {
			dispatch(receiveTokens({ gameId: character.game, tokens: [data] }));
		});
	}, [line]);

	const setIcon = React.useCallback(
		(shape) => {
			const token = { ...selectedToken, shape };
			dispatch(receiveTokens({ gameId: token.game, tokens: [token] }));
			setselectedToken(token);
			apiFetch(`games/${token.game}/tokens/${token.id}`, "PATCH", token);
		},
		[selectedToken]
	);

	const setColor = React.useCallback(
		(color) => {
			const token = { ...selectedToken, color };
			dispatch(receiveTokens({ gameId: token.game, tokens: [token] }));
			setselectedToken(token);
			apiFetch(`games/${token.game}/tokens/${token.id}`, "PATCH", token);
		},
		[selectedToken]
	);

	const changeContent = React.useCallback(
		(content) => {
			const token = { ...selectedToken, content };
			setcontent(content);
			dispatch(receiveTokens({ gameId: token.game, tokens: [token] }));
			apiFetch(`games/${token.game}/tokens/${token.id}`, "PATCH", token);
		},
		[selectedToken]
	);

	const gm = isGM();
	let editableToken = visibleTokens;
	if (gm) {
		editableToken = visibleTokens.map((t) => t.creator === user.id);
	}

	return (
		<AppScreenView style={[styles.container, ws({paddingLeft: fullScreenLeftPadding(), width: fullScreenContentWidth()})]} borderTop>
			<AppText bold>
				{_("%(character_name)s's tokens at line:", "indicated the line for which tokens will be changed", {
					character_name: getCharName(character),
				})}
			</AppText>
			<AppText style={styles.line} numberOfLines={2} ellipsizeMode="tail">
				{line?.content}
			</AppText>

			<Divider style={{ marginTop: 16 }} />

			<View style={{ flexDirection: "row", alignItems: "center", marginBottom: 4 }}>
				<CharacterAvatar character={character} />
				<AppText>{getCharName(character)}</AppText>
			</View>

			<AppText hide={!visibleTokens.length} color="hint" style={{ marginBottom: 4 }}>
				{_("Select a token to edit")}
			</AppText>

			<CharacterTokens
				character={character}
				line={line}
				horizontalList
				selectedTokenId={selectedToken?.id}
				onVisibleTokensChanged={setvisibleTokens}
				style={{ flexGrow: 0, marginBottom: 16 }}
				tokenStyle={{ marginRight: 8 }}
				onTokenPress={setselectedToken}
				listProps={{
					contentContainerStyle: { alignItems: "center" },
				}}
				tokensSize={36}
			/>

			<CondView show={!!selectedToken && !gm && selectedToken.creator !== user.id}>
				<AppText color="hint">{_("You cannot edit a token created by the Game Master")}</AppText>
			</CondView>

			<CondView show={!!selectedToken && (gm || selectedToken.creator === user.id)} style={styles.editArea}>
				<AppText
					hide={!selectedToken?.creator_see_only}
					onPress={() => navigation.navigate("TokenOptionsModal", { token: selectedToken, line })}
				>
					<MaterialCommunityIcons
						name="eye-off"
						size={12}
						style={{ marginRight: 4 }}
						color={global.colors.attention}
					/>{" "}
					{_("Only you can see this token ")}
				</AppText>
				<AppText style={{ marginRight: 8, marginTop: 8 }} bold>
					{_("Label", "token label")}
				</AppText>
				<AppText placeholder={_("Add value to token")} editable onTextChanged={changeContent}>
					{content}
				</AppText>
				<View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 16 }}>
					<View style={{ alignItems: "center" }}>
						<AppText>{_("Icon", "roll macro icon")}</AppText>
						<TokenSelector currentToken={selectedToken?.shape} setToken={setIcon} />
					</View>
					<View style={{ alignItems: "center" }}>
						<AppText>{_("Color", "roll macro icon color")}</AppText>
						<ColorSelector currentColor={selectedToken?.color} setColor={setColor} />
					</View>
				</View>

				<ButtonsRow style={{ marginTop: 32 }}>
					<AppButton
						title={_("More options")}
						callToAction
						small
						onPress={() => navigation.navigate("TokenOptionsModal", { token: selectedToken, line })}
					/>
				</ButtonsRow>
			</CondView>
			<FAB icon="plus" style={globalStylesMethod(global.theme).fab} onPress={addToken} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(ManageTokensScreen);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {},
	line: {
		backgroundColor: colors.descriptionBackground,
		padding: 8,
		borderRadius: 6,
		marginTop: 6,
	},
	editArea: {
		backgroundColor: colors.descriptionBackground,
		marginBottom: 16,
		padding: 16,
	},
}));
