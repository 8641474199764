import * as React from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { BuildStyleMethod } from "../../../styles/theming";
import AppModal from "./AppModal";

function SimpleOverlayModal({ visible, onRequestClose, children }) {
	const styles = stylesMethod(global.theme);
	return (
		<AppModal animationType="fade" visible={visible} onRequestClose={onRequestClose}>
			<ScrollView contentContainerStyle={styles.screen} keyboardShouldPersistTaps="handled">
				<TouchableOpacity style={styles.backdrop} activeOpacity={1} onPress={onRequestClose} />
				{children}
			</ScrollView>
		</AppModal>
	);
}

export default SimpleOverlayModal;

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		screen: {
			flexGrow: 1,
			flexShrink: 0,
			flexBasis: "auto",
			alignItems: "center",
			justifyContent: "center",
			paddingVertical: 32,
		},
		backdrop: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: colors.overlayBackground,
		},
	})
);
