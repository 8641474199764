import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import Zoom from "react-medium-image-zoom";
import { Image, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { Hoverable, useLayout } from "react-native-web-hooks";
import { connect } from "react-redux";
import { _ } from "../../../i18n/i18n";
import { BuildStyleMethod } from "../../../styles/theming";
import { HasLevelOrShare } from "../../../tools/account";
import { isWeb, ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";
import AppButton from "../../generic/buttons/AppButton";
import IconButton from "../../generic/buttons/IconButton";
import SimpleOverlayModal from "../../generic/modal/SimpleOverlayModal";
import Cond from "../../meta/Cond";
import CondView from "../../meta/CondView";
import LockedFeatureModal from "../../profile/LockedFeatureModal";
function GetMatchingHeight(height) {
	return ws(32, 16) * height;
}

function SheetNodePicture({ node, editingTemplate, allowSaveChanges, dispatch, user }) {
	const styles = stylesMethod(global.theme);

	const [containerHeight, setcontainerHeight] = React.useState(GetMatchingHeight(node.height));
	const [zoom, setzoom] = React.useState(false);
	const [imageSize, setimageSize] = React.useState({ width: containerHeight, height: containerHeight });

	const navigation = useNavigation();
	const lockedModalRef = React.useRef();
	const { width } = useWindowDimensions();
	const { onLayout: onInnerLayout, width: availableWidth } = useLayout();

	React.useEffect(() => {
		if (!node.value) return () => null;
		const success = (width, height) => {
			setimageSize({ width, height });
		};

		Image.getSize(node.value, success);
	}, [node.value]);

	React.useEffect(() => {
		setcontainerHeight(GetMatchingHeight(node.height));
	}, [node.height]);

	const startUpload = React.useCallback(() => {
		if (HasLevelOrShare(user.profile.reward_level, 2)) {
			navigation.navigate({
				name: "UploadSheetImageModal",
				params: { nodeId: node.id, noTitle: true },
			});
		} else {
			lockedModalRef.current.show();
		}
	}, []);

	const finalImageSize = React.useMemo(() => {
		if (!availableWidth) return { height: GetMatchingHeight(node.height), width: 0 };

		const ratio = imageSize.width / imageSize.height;

		const maxHeight = containerHeight;
		const maxWidth = availableWidth * 0.9;

		let height = Math.min(imageSize.height, maxHeight);
		let width = height * ratio;

		if (width > maxWidth) {
			width = maxWidth;
			height = width / ratio;
		}
		return { height, width };
	}, [availableWidth, imageSize, node.height, containerHeight]);

	let imageComponent = (
		<Image source={{ uri: node.value }} style={finalImageSize} resizeMethod="resize" resizeMode="contain" />
	);

	if (isWeb()) {
		imageComponent = (
			<Zoom
				overlayBgColorStart={"transparent"}
				overlayBgColorEnd={"transparent"}
				zoomMargin={64}
				closeText={_("Click to unzoom", "zoom on picture")}
				openText={_("Click to zoom", "unzoom from picture")}
			>
				{imageComponent}
			</Zoom>
		);
	} else {
		imageComponent = <TouchableOpacity onPress={() => setzoom(true)}>{imageComponent}</TouchableOpacity>;
	}

	const ContainerTag = allowSaveChanges && !editingTemplate ? TouchableOpacity : View;

	return (
		<>
			<ContainerTag
				style={[styles.container, { height: containerHeight }]}
				onPress={!node.value || !isWeb() ? startUpload : null}
			>
				<Cond show={!node.value}>
					<MaterialCommunityIcons
						name="image"
						size={Math.min(containerHeight * 0.75, 128)}
						color={global.colors.textDefault}
					/>
					<AppText hide={editingTemplate || containerHeight < 124} color="secondary">
						{_("Upload picture", "character sheet picture node")}
					</AppText>
				</Cond>

				<Hoverable>
					{(isHovered) => (
						<CondView show={node.value} onLayout={onInnerLayout} style={styles.imageContainer}>
							{imageComponent}
							<View
								style={[
									{
										position: "absolute",
										bottom: 0,
										// width: "100%",
										// backgroundColor: global.colors.buttonBackgroundColor,
										// height: 38,
										justifyContent: "center",
										alignItems: "center",
									},
								]}
							>
								{containerHeight < 124 ? (
									<IconButton
										hide={!isHovered || !isWeb()}
										onPress={startUpload}
										icon={{
											type: MaterialIcons,
											name: "edit",
										}}
									/>
								) : (
									<AppButton
										hide={!isHovered || !isWeb()}
										title={_("Change")}
										onPress={startUpload}
										size="small"
									/>
								)}
							</View>
						</CondView>
					)}
				</Hoverable>

				<LockedFeatureModal ref={lockedModalRef} requiredLevel={2} navigation={navigation} />
			</ContainerTag>
			{!isWeb() && (
				<SimpleOverlayModal visible={zoom} onRequestClose={() => setzoom(false)}>
					<TouchableOpacity onPress={() => setzoom(false)}>
						<Image source={{ uri: node.value }} style={{ width, height: width }} resizeMode="contain" />
					</TouchableOpacity>
				</SimpleOverlayModal>
			)}
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(SheetNodePicture);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			justifyContent: "center",
			alignItems: "center",
			// backgroundColor: colors.translucidBackground,
		},
		imageContainer: {
			height: "100%",
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);
