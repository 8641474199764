import { LayoutAnimation } from "react-native";

export function getInterpolatedState(startStyle, endStyle, animatedValue, params) {
	const keys = Object.keys(startStyle);
	const currentTransitionState = {};
	keys.forEach((key) => {
		currentTransitionState[key] = animatedValue.interpolate({
			inputRange: [0, 1],
			outputRange: [startStyle[key], endStyle[key]],
			...params,
		});
	});

	return currentTransitionState;
}

export function fadeLayout(delay = 75) {
	LayoutAnimation.configureNext(LayoutAnimation.create(delay, LayoutAnimation.Types.linear, "opacity"));
}
