import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import * as React from "react";
import { FlatList } from "react-native";
import { FAB } from "react-native-paper";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { socket } from "../../network/websocket";
import { fetchBookmarks } from "../../store/slices/bookmarksSlice";
import {
	fetchBookmarkedGames,
	fetchNextBookmaredGames,
	fetchNextUserGames,
	fetchUserGames,
} from "../../store/slices/gamesSlice";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStylesMethod } from "../../styles/global";
import { gameHasNews, hasUnreadTaggedChat } from "../../tools/games";
import { ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import GameAlertsList from "../gamealerts/GameAlertsList";
import AppButton from "../generic/buttons/AppButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import BoxModal from "../generic/modal/BoxModal";
import RGIcon from "../RGIcon";
import ActiveGameItem from "./ActiveGameItem";

function MyGamesScreen({ isBookmarks, navigation, dispatch, user, games, bookmarkForGames, nextUrl }) {
	const [refreshing, setRefreshing] = React.useState(false);
	const [localeCode, setlocaleCode] = React.useState();

	React.useEffect(() => {
		// only used to force a refresh
		const removeFocusListener = navigation.addListener("focus", () => setlocaleCode(global.LOCALE_CODE));
		return () => {
			removeFocusListener();
		};
	}, []);

	const confirmCreateModalRef = React.useRef();

	const refeshGames = React.useCallback(() => {
		if (isBookmarks) {
			return dispatch(fetchBookmarkedGames(user.id));
		} else {
			return dispatch(fetchUserGames());
		}
	}, [isBookmarks, user.id]);

	useFocusEffect(
		React.useCallback(() => {
			socket.on("last_line_updated", refeshGames);
			refeshGames();
			return function cleanup() {
				socket.off("last_line_updated", refeshGames);
				games.forEach((element) => {
					socket.emit("leave_room", `outside_observe_game_${element.id}`);
				});
			};
		}, [refeshGames])
	);

	React.useEffect(() => {
		setRefreshing(false);

		games.forEach((element) => {
			socket.joinRoom(`outside_observe_game_${element.id}`);
		});

		if (isBookmarks) {
			const noBookmarkGames = games.filter((g) => !bookmarkForGames[g.id]);
			if (noBookmarkGames.length) {
				dispatch(
					fetchBookmarks(
						user.id,
						noBookmarkGames.map((g) => g.id)
					)
				);
			}
		}
	}, [games, isBookmarks, user.id]);

	const fetchNext = React.useCallback(() => {
		if (nextUrl) {
			if (isBookmarks) {
				dispatch(fetchNextBookmaredGames(nextUrl));
			} else {
				dispatch(fetchNextUserGames(nextUrl));
			}
		}
	}, [nextUrl]);

	const sortedGames = React.useMemo(() =>
		games.slice().sort(
			(a, b) => {
				const aHasNews = gameHasNews(a, user);
				const bHasNews = gameHasNews(b, user);

				const aTagged = hasUnreadTaggedChat(a, user);
				const bTagged = hasUnreadTaggedChat(b, user);

				if (aTagged && !bTagged) {
					return -1;
				}
				if (!aTagged && bTagged) {
					return 1;
				}

				if (aHasNews && !bHasNews) {
					return -1;
				}
				if (!aHasNews && bHasNews) {
					return 1;
				}

				return 0;
			},
			[games, user]
		)
	);

	return (
		<AppScreenView borderless>
			<FlatList
				style={{ paddingHorizontal: 8, paddingTop: 16 }}
				contentContainerStyle={[
					{ flexGrow: 1 },
					ws({
						paddingTop: 16,
						paddingLeft: fullScreenLeftPadding(),
						maxWidth: fullScreenContentWidth(),
					}),
				]}
				data={sortedGames}
				renderItem={({ item }) => <ActiveGameItem game={item} isBookmarked={isBookmarks} />}
				keyExtractor={(item, index) => String(item.id)}
				onRefresh={refeshGames}
				refreshing={refreshing}
				onEndReached={fetchNext}
				ListEmptyComponent={
					isBookmarks ? (
						<EmptyScreenView
							loading={refreshing}
							messageTop={_("You haven't bookmarked any games yet")}
							icon={{ type: MaterialIcons, name: "bookmark" }}
							options={[
								{
									title: _("Explore games"),
									onPress: () => navigation.navigate("Explore"),
									size: "large",
								},
							]}
						/>
					) : (
						<EmptyScreenView
							loading={refreshing}
							messageTop={_("You are not playing any games yet")}
							icon={{ type: RGIcon, name: "die" }}
							style={{ paddingBottom: 24 }}
							options={[
								{
									title: _("Start playing", "option to start the wizard to find/create a game"),
									onPress: () => navigation.navigate("PlayGameWizardStack"),
									size: "large",
								},
							]}
						/>
					)
				}
				ListFooterComponent={
					!refreshing && !isBookmarks
						? () => (
								<>
									{!!sortedGames.length && (
										<AppButton
											style={{ marginTop: 32, marginBottom: 4, justifyContent: "center" }}
											size="large"
											title={_(
												"Join or start game",
												"option to start the wizard to find/create a game"
											)}
											onPress={() => navigation.navigate("PlayGameWizardStack")}
											icon={{ type: RGIcon, name: "die" }}
											transparentHighlight
										/>
									)}
									<AppButton
										style={{ marginBottom: 32 }}
										size="large"
										title={_(
											"Create a notifier",
											"create an notifier to be warned about new games"
										)}
										onPress={() => navigation.navigate("CreateAlertScreen")}
										icon={{ type: MaterialCommunityIcons, name: "bell-alert" }}
										transparentHighlight
										info={_("Notifies you if a game with your criteria opens")}
									/>
									<GameAlertsList style={{ marginBottom: 32 }} />
								</>
						  )
						: null
				}
			/>
			{sortedGames.length > 15 && (
				<FAB
					icon="plus"
					style={globalStylesMethod(global.theme).fab}
					onPress={() => confirmCreateModalRef.current.show()}
				/>
			)}
			<BoxModal
				ref={confirmCreateModalRef}
				title={_("Create game")}
				message={_("Create a new game?")}
				onConfirm={() => navigation.navigate("PlayGameWizardStack")}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { isBookmarks } = ownProps;
	return {
		games: isBookmarks
			? state.games.bookmarked || state.savedStore.games.bookmarked
			: state.games.userGames || state.savedStore.games.userGames,
		user: state.user,
		bookmarkForGames: state.bookmarkForGames,
		nextUrl: isBookmarks ? state.games.browsedGamesNextUrl : state.games.userNextUrl,
	};
};

export default connect(mapStateToProps)(MyGamesScreen);
