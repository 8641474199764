import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { socket } from "../../network/websocket";
import { fetchPublicChatBoundaries, setBoundaries } from "../../store/slices/linesSlice";
import { isWeb, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import ChatInput from "../chat/ChatInput";
import ErrorLoading from "../errors/ErrorLoading";
import EmptyScreenView from "../generic/EmptyScreenView";
import LinesList from "../lines/LinesList";
import CondView from "../meta/CondView";

function PublicChatScreen({ route, navigation, dispatch, user, boundaries, game }) {
	React.useEffect(() => {
		dispatch(fetchPublicChatBoundaries(game.id));
	}, [dispatch, game.id]);

	if(!game.allow_public_chat){		
		navigation.navigate("Game");
		return <ErrorLoading/>
	}

	const isEmpty = boundaries?.firstCommentId !== undefined && !boundaries?.firstCommentId;

	const receiveLine = React.useCallback(
		(line) => {
			if (line.game !== game.id) return;
			if(isEmpty){				
				dispatch(setBoundaries({ gameId: game.id, firstCommentId: line.id, lastCommentId: line.id }));
				dispatch(fetchPublicChatBoundaries(game.id));
			}
		},
		[game.id, isEmpty]
	);	

	React.useEffect(() => {
		socket.on("new_line", receiveLine);
		return () => {
			socket.off("new_line", receiveLine);
		};
	}, [receiveLine, isEmpty]);

	return (
		<AppScreenView
			borderless
			style={styles.container}
			// style={{ paddingTop: 0, justifyContent: "flex-end", flex: 1 }}
		>
			{(isEmpty && <EmptyScreenView messageTop={_("This game has no public messages yet.")} />) || (
				<LinesList game={game} isComments />
			)}

			<CondView show={user} style={ws(undefined, styles.buttonsRow)}>
				<ChatInput minimumMode={!isWeb()} isComments show messageType="comment" />
			</CondView>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { gameslug } = ownProps.route.params;
	const game = state.games[gameslug]
	const boundaries = state.gameLinesBoundaries[game.id];
	return {
		game,
		boundaries,
		user: state.user,
	};
};

export default connect(mapStateToProps)(PublicChatScreen);

const styles = StyleSheet.create({
	container: {},
	buttonsRow: {
		alignSelf: "flex-end",
		flexDirection: "row",
		alignItems: "flex-end",
		marginBottom: 4,
	},
});
