export function fullAddressToApiFetchURL(fullUrl) {
	const index = fullUrl.indexOf("api/v2/");
	if(!index) return fullUrl;
	return fullUrl.substr(index + 7);
}

function objToQueryString(obj) {
	const keys = Object.keys(obj);
	const values = Object.values(obj);

	const keyValuePairs = [];
	for (let i = 0; i < keys.length; i += 1) {
		const value = values[i];

		if (Array.isArray(value)) {
			for (let ar = 0; ar < value.length; ar++) {
				const newValue = value[ar];
				// %5B%5D === [] . Open and close bracket are necessary for compatibility with ajax query on the website
				keyValuePairs.push(`${encodeURIComponent(keys[i])}%5B%5D=${encodeURIComponent(newValue)}`);
			}
		} else {
			keyValuePairs.push(`${encodeURIComponent(keys[i])}=${encodeURIComponent(value)}`);
		}
	}
	return keyValuePairs.join("&");
}

const activeQueries = [];

export const isQueryInProgress = (queryId) => {
	return activeQueries.includes(queryId);
};

export async function apiFetchSimple(url) {
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	const token = global.authToken;
	if (token) {
		headers.Authorization = `Token ${token}`;
	}
	return fetch(url, {
		method: "GET",
		headers,
	})
		.then((response) => {
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.includes("application/json")) {
				return response.json();
			}
			return response.json();
		})
		.catch((error) => {
			console.error(`${url}, error: ${error}`);
		});
}

export function apiFetch(url, method, body, usingFullURL) {
	method = method || "GET";

	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	const token = global.authToken;
	if (token) {
		headers.Authorization = `Token ${token}`;
	}

	if (usingFullURL) {
		url = `${global.root_url}/${url}`;
	} else {
		url = `${global.root_url}/api/v2/${url}`;
	}

	let data = null;

	if (method === "GET" && body) {
		url += `?${objToQueryString(body)}`;
		body = null;
	}

	const bodyAsString = body ? JSON.stringify(body) : null;
	const queryId = url + (bodyAsString ? bodyAsString : "");

	if (isQueryInProgress(queryId)) {
		if(__DEV__)console.log("Cancelling query", queryId);
		return Promise.reject("");
	}

	activeQueries.push(queryId);

	return fetch(url, {
		method,
		headers,
		body: bodyAsString,
		data,
	})
		.then((response) => {
			activeQueries.splice(activeQueries.indexOf(queryId), 1);

			const contentType = response.headers.get("content-type");
			if (contentType && contentType.includes("application/json")) {
				return response.json();
			}
			return null;
		})
		.catch((error) => {
			activeQueries.splice(activeQueries.indexOf(queryId), 1);

			console.error(error);
		});
}
