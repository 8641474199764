import * as React from "react";
import { View, StyleSheet, BackHandler } from "react-native";
import { Portal } from "react-native-paper";

function AppModal({ visible, onRequestClose, children, style, allowTapThrough }) {
	React.useEffect(() => {
		let hideOnBack = null;
		if (visible) {
			hideOnBack = BackHandler.addEventListener("hardwareBackPress", () => {
				onRequestClose && onRequestClose();
				return true;
			});
		}
		return () => {
			hideOnBack && hideOnBack.remove();
		};
	}, [visible]);

	if (!visible) return null;

	return (
		<Portal>
			<View style={[styles.container, style]} 
			pointerEvents={allowTapThrough ? "box-none" : null}
			>{children}</View>
		</Portal>
	);
}

export default AppModal;

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
});
