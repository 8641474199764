import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { levelToRankName, levelToCoinFileName } from "../../tools/account";
import { connect } from 'react-redux';
import { colors } from "../../styles/colors";

function AccountLevelText(props) {
    const { level, user } = props;
    
	return (
		<AppText
			style={{
				color: global.colors.accountColors[levelToCoinFileName(level).toLowerCase()],
			}}
			{...props}
		>
			{levelToRankName(level)}
		</AppText>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(AccountLevelText);


