import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { colors } from "../../styles/colors";
import { ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import CondView from "../meta/CondView";
import UserAvatar from "../users/UserAvatar";

import { BuildStyleMethod } from "../../styles/theming";

function Message({ message, prevMessage, nextMessage, index, dispatch, users, user }) {
	const styles = stylesMethod(global.theme);
	const navigation = useNavigation();
	const groupWithPrevious = prevMessage?.user === message.user;
	const groupWithNext = nextMessage?.user === message.user;

	const author = users[message.user];
	const isOwn = message.user === user.id;

	return (
		<View style={[styles.container, isOwn && styles.containerOwn, !groupWithNext && styles.containerLast]}>
			<CondView show={!isOwn} style={styles.avatarContainer}>
				{!groupWithNext && <UserAvatar user={author} style={styles.avatar} />}
			</CondView>
			<TouchableOpacity
				onPress={() => navigation.navigate("MessageOptionsModal", { message })}
				style={[
					styles.messageContentContainer,
					isOwn && styles.messageContentContainerOwn,
					!groupWithPrevious && !isOwn && styles.messageFirst,
					!groupWithNext && !isOwn && styles.messageLast,
					!groupWithPrevious && isOwn && styles.ownMessageFirst,
					!groupWithNext && isOwn && styles.ownNessageLast,
				]}
			>
				<AppText>{message.content}</AppText>
			</TouchableOpacity>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		users: state.users,
		user: state.user,
	};
};

export default connect(mapStateToProps)(Message);

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flexDirection: "row",
		marginHorizontal: 8,
		marginBottom: 4,
	},

	containerOwn: {
		flexDirection: "row-reverse",
	},

	messageContentContainer: {
		backgroundColor: colors.messageBackground,
		paddingHorizontal: 12,
		paddingVertical: 8,
		borderRadius: 16,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		flex: 0,
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: "auto",
		...ws({ maxWidth: 500 }),
	},
	messageContentContainerOwn: {
		backgroundColor: colors.messageOwnBackground,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		borderTopLeftRadius: 16,
		borderBottomLeftRadius: 16,
	},
	avatarContainer: {
		width: 40,
		justifyContent: "center",
	},
	avatar: {
		position: "absolute",
	},

	messageFirst: {
		borderTopLeftRadius: 16,
	},

	messageLast: {
		borderBottomLeftRadius: 16,
	},

	ownMessageFirst: {
		borderTopRightRadius: 16,
	},

	ownNessageLast: {
		borderBottomRightRadius: 16,
	},

	containerLast: {
		marginBottom: 16,
	},
}));
