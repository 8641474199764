import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import { isWeb, ws } from "../../tools/generic";
import { SafeAreaView } from "react-native-safe-area-context";
import moment from "moment";
import { usePrevious } from "../../tools/react";

let updateTimerInterval;

function MaintenanceBanner({ forceMaintenanceTime, onTimerEnded }) {
	const [timeleft, settimeleft] = React.useState();

	const previousTime = usePrevious(forceMaintenanceTime);

	const updateTimer = React.useCallback(() => {
		const now = moment();
		if (now.isAfter(forceMaintenanceTime)) {
			onTimerEnded();
            clearInterval(updateTimerInterval);
			return;
		}

		const timeLeft = moment(forceMaintenanceTime).countdown();

		const minutes = timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes;
		const seconds = timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds;

		let cdString = `${minutes}:${seconds}`;

		settimeleft(cdString);
	}, [forceMaintenanceTime]);

	React.useEffect(() => {
		if (!previousTime && forceMaintenanceTime) {
			updateTimerInterval = setInterval(updateTimer, 500);
			return () => {
				clearInterval(updateTimerInterval);
			};
		}
	}, [forceMaintenanceTime, updateTimer]);

	if (!forceMaintenanceTime) return null;

	return (
		<SafeAreaView
			style={{
				top: 0,
				left: 0,
				right: 0,
				padding: ws(24, 12),
				backgroundColor: "yellow",
			}}
		>
			<AppText color="textDark" centered={isWeb()}>
				{ws(
					_(
						"A server update is about to start. The website will automatically lock up in %(timer)s minute(s).",
						"",
						{
							timer: (
								<AppText key="timer" bold>
									{timeleft}
								</AppText>
							),
						}
					),
					_(
						"A server update is about to start. The app will automatically lock up in %(timer)s minute(s).",
						"",
						{
							timer: (
								<AppText key="timer" bold>
									{timeleft}
								</AppText>
							),
						}
					)
				)}
			</AppText>
		</SafeAreaView>
	);
}

export default MaintenanceBanner;

const styles = StyleSheet.create({
	container: {},
});
