import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCharacterStateThen, getCharName } from "../../tools/characters";
import { isPlayer } from "../../tools/games";
import { enumerate, ws } from "../../tools/generic";
import { cStyle } from "../../tools/react";
import AppText from "../generic/AppText";
import VerticalDivier from "../generic/VerticalDivider";
import PartyTab from "./PartyTab";

function PartySeparator({ small, dispatch, party, line, parties, characters, povCharacterId }) {
	if (!party) return null;

	const navigation = useNavigation();

	if (isPlayer()) {
		const otherCharactersId = party.characters.filter((c) => c != povCharacterId);

		if (!otherCharactersId.length) {
			return (
				<View style={cStyle([styles.container, [!!small, styles.containerSmall]])}>
					<AppText color="secondary" size={small ? "small" : null}>
						{" "}
						{_("You are by yourself, in the party %(party_name)s", "current party header", {
							party_name: (
								<AppText bold key="var">
									{party.name}
								</AppText>
							),
						})}
					</AppText>
				</View>
			);
		}
		return (
			<View style={cStyle([styles.container, [!!small, styles.containerSmall]])}>
				<AppText color="secondary" size={small ? "small" : null}>
					{_("You are in the party %(party_name)s with %(others)s", "current party header", {
						party_name: (
							<AppText bold key="var">
								{party.name}
							</AppText>
						),
						others: (
							<AppText key="var2">
								{enumerate(otherCharactersId.map((c) => getCharName(characters[c])))}
							</AppText>
						),
					})}
				</AppText>
			</View>
		);
	}

	const siblings = parties
		.filter((p) => p.separation_line_id === party.separation_line_id)
		.sort((a, b) => {
			if (a.id === party.id) return -1;
			if (b.id === party.id) return 1;
			return 0;
		});

	const characterNames = party.characters.map((charid, idx, src) => {
		let text = "";
		if (idx > 0 && idx == src.length - 1) text += " & ";
		else if (idx > 0) text += ", ";
		const charState = getCharacterStateThen(line, characters[charid]);
		text += charState.name;
		return (
			<AppText size="small" key={idx} color={charid === povCharacterId ? null : "primary"}>
				{text}
			</AppText>
		);
	});

	return (
		<TouchableOpacity
			style={cStyle([styles.container, [!!small, styles.containerSmall]])}
			onPress={() => {
				navigation.navigate("ChangePOVModal");
			}}
		>
			<View>
				<AppText color="primary" hide={small}>
					{_("Party split")}{" "}
					<AppText size="small" color="hint">
						| {ws(_("Click to change point of view"), _("Tap to change point of view"))}
					</AppText>
				</AppText>

				<FlatList
					horizontal
					data={siblings}
					keyExtractor={(item) => String(item.id)}
					renderItem={({ item }) => <PartyTab party={item} />}
					ItemSeparatorComponent={VerticalDivier}
				/>
				<AppText size="small">{characterNames}</AppText>
			</View>
		</TouchableOpacity>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { party } = ownProps;
	if (!party) return {};
	return {
		parties: state.parties[party.game]?.all,
		characters: state.characters,
		povCharacterId: state.parties[party.game]?.povCharacterId,
	};
};

export default connect(mapStateToProps)(PartySeparator);

const styles = StyleSheet.create({
	container: {
		marginVertical: 16,
		marginHorizontal: 16,
		flexDirection: "row",
	},
	containerSmall: {
		marginVertical: 0,
		marginHorizontal: 0,
	},
});
