import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import AppScreenView from "../AppScreenView";
import { idKeyExtractor, ws } from "../../tools/generic";
import NotificationItem from "./NotificationItem";
import EmptyScreenView from "../generic/EmptyScreenView";
import { FontAwesome5 } from "@expo/vector-icons";
import { _ } from "../../i18n/i18n";
import { fetchNextNotifications } from "../../store/slices/notificationsSlice";
import { apiFetch } from "../../network/apiFetch";
import FullScreenLoader from "../generic/FullScreenLoader";
import Chest from "./Chest";
import { fullScreenLeftPadding, fullScreenContentWidth } from "../../styles/dynamicStyles";
import { useFocusEffect } from "@react-navigation/native";
import { useLayout } from "react-native-web-hooks";

function NotificationsScreen({ route, navigation, dispatch, user, notifications, nextUrl }) {
	const [navigating, setnavigating] = React.useState(false);
	const fetchNext = React.useCallback(() => {
		if (nextUrl) {
			dispatch(fetchNextNotifications(nextUrl));
		}
	}, [nextUrl]);

	useFocusEffect(
		React.useCallback(() => {
			return () => {
				setnavigating(false);
				apiFetch(`users/${user.id}/coin-transactions/mark-all-seen`, "POST");
			};
		}, [user.id])
	);

	const onStartNavigation = React.useCallback(() => {
		setnavigating(true);
	}, []);

	const {onLayout} = useLayout();

	return (
		<AppScreenView onLayout={onLayout} borderless>
			<FlatList
				data={notifications}
				contentContainerStyle={[
					{ flexGrow: 1 },
					ws({ paddingLeft: fullScreenLeftPadding(), maxWidth: fullScreenContentWidth() }),
				]}
				keyExtractor={idKeyExtractor}
				renderItem={({ item, index }) => (
					<NotificationItem coinTransaction={item} onStartNavigation={onStartNavigation} />
				)}
				ListEmptyComponent={() => (
					<EmptyScreenView
						style={{ marginHorizontal: 16 }}
						messageTop={_("You haven't earned any coins yet")}
						icon={{ type: FontAwesome5, name: "coins" }}
						messageBottom={_(
							"You can receive coins as rewards from fellow players or people reading your games."
						)}
					/>
				)}
				onEndReached={fetchNext}
				ListHeaderComponent={() => <Chest style={{ marginHorizontal: 8, marginVertical: 16, width: null }} />}
			/>
			<FullScreenLoader visible={navigating} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		notifications: state.notifications.all,
		nextUrl: state.notifications.nextUrl,
	};
};

export default connect(mapStateToProps)(NotificationsScreen);


