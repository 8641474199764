import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { _ } from "../../i18n/i18n";
import FullScreenTextEdit from "../generic/FullScreenTextEdit";
import EditStoryMarkerModal from "../storymarkers/EditStoryMarkerModal";
import StoryMarkerModal from "../storymarkers/StoryMarkerModal";
import StageBackgroundSelectionScreen from "../stagebackgrounds/StageBackgroundSelectionScreen";
import { modalStackNavigatorScreenOptions } from "../../tools/navigationOptions";
import { connect } from 'react-redux';
import { fetchStoryMarkers } from "../../store/slices/storyMarkersSlice";

const Stack = createStackNavigator();

function StoryMarkerModalStack({route, dispatch, currentGameId, marker, params}) {
		

	React.useEffect(()=>{
		if(!marker && currentGameId){
			dispatch(fetchStoryMarkers(currentGameId));
		}
	}, [marker, currentGameId]);

	if(!marker){
		return null;
	}

	return (
		<Stack.Navigator screenOptions={modalStackNavigatorScreenOptions}>
			<Stack.Screen name="StoryMarkerModal" component={StoryMarkerModal} initialParams={params} />
			<Stack.Screen name="EditStoryMarkerModal" component={EditStoryMarkerModal} initialParams={params}/>
			<Stack.Screen
				name="EditData"
				component={FullScreenTextEdit}
				options={{ headerShown: true, headerTitle: _("Edit story marker"), cardStyle: {} }}
				initialParams={params}
			/>
			<Stack.Screen
				name="SelectBackground"
				children={(props)=><StageBackgroundSelectionScreen {...props} previousScreenName="EditStoryMarkerModal"/>}
				options={{ headerShown: true, headerTitle: _("Select background"), cardStyle: {} }}
				initialParams={params}
			/>
		</Stack.Navigator>
	);
}

const mapStateToProps = (state, ownProps) => {
	const params = ownProps.route.params.params || ownProps.route.params;
	return {
		currentGameId: state.games.currentId,
		marker: state.storyMarkers.byId[params.markerId],
		params,
	};
};

export default connect(mapStateToProps)(StoryMarkerModalStack);
