import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { modalScreenOptions } from "../../../tools/navigationOptions";
import WizardAvatarRequestConfirmSendModal from "./WizardAvatarRequestConfirmSendModal";
import WizardAvatarRequestDeadline from "./WizardAvatarRequestDeadline";
import WizardAvatarRequestDescription from "./WizardAvatarRequestDescription";
import WizardAvatarRequestSummary from "./WizardAvatarRequestSummary";

const Stack = createStackNavigator();

function AvatarRequestWizardStack() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false, presentation: 'transparentModal' }}>
			<Stack.Screen name="WizardAvatarRequestDeadline" component={WizardAvatarRequestDeadline} />
			<Stack.Screen name="WizardAvatarRequestDescription" component={WizardAvatarRequestDescription} />
			<Stack.Screen name="WizardAvatarRequestSummary" component={WizardAvatarRequestSummary} />
			<Stack.Screen
				name="WizardAvatarRequestConfirmSendModal"
				component={WizardAvatarRequestConfirmSendModal}
				options={modalScreenOptions}
			/>            
		</Stack.Navigator>
	);
}

export default AvatarRequestWizardStack;
