import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import { ws } from "../../tools/generic";
import IconButton from "./buttons/IconButton";

import { BuildStyleMethod } from "../../styles/theming";


function SearchBar({
	onSearchUpdated,
	onFilteredResultUpdated,
	filterBy,
	items,
	autoFocus,
	hideIcon,
	placeholder,
	onFocus,
	onPress,
	onCreate,
	style,
}) {
	const styles = stylesMethod(global.theme);
	const [currentSearch, setCurrentSearch] = React.useState("");

	const inputRef = React.useRef();

	const filterMethod = React.useCallback(
		(t) => {
			if (!currentSearch) return true;
			const name = filterBy(t);
			return name.toLowerCase().includes(currentSearch.toLowerCase());
		},
		[filterBy, currentSearch]
	);

	const updateSearch = React.useCallback(
		(text) => {
			onSearchUpdated && onSearchUpdated(text);
			setCurrentSearch(text);
		},
		[onSearchUpdated]
	);

	React.useEffect(() => {
		filterBy && onFilteredResultUpdated && onFilteredResultUpdated(items.filter(filterMethod));
	}, [items]);

	React.useEffect(() => {
		filterBy && onFilteredResultUpdated && onFilteredResultUpdated(items.filter(filterMethod));
	}, [currentSearch]);

	const ContainerTag = onPress ? TouchableOpacity : View;

	return (
		<View style={[{ flexDirection: "row", textAlign: "center", justifyContent: "center" }, style]}>
			<ContainerTag style={styles.container} onPress={onPress} activeOpacity={0.9}>
				{!hideIcon && <MaterialIcons name="search" size={24} color={global.colors.textDefault} />}

				<View pointerEvents={onPress ? "none" : null} style={styles.input}>
					<TextInput
						ref={inputRef}
						inlineImageLeft=""
						onChangeText={updateSearch}
						style={styles.input}
						autoFocus={autoFocus}
						placeholder={placeholder}
						onFocus={onFocus}
						placeholderTextColor={global.colors.placeholder}
					/>
				</View>

				{!!currentSearch && (
					<TouchableOpacity
						style={styles.clearIcon}
						onPress={() => {
							inputRef.current.clear();
							updateSearch("");
						}}
					>
						<MaterialIcons name="clear" size={24} color={global.colors.textDefault} />
					</TouchableOpacity>
				)}
			</ContainerTag>
			{onCreate && !!currentSearch && (
				<View style={styles.barRight}>
					<IconButton
						transparent
						icon={{ type: MaterialCommunityIcons, name: "plus", color: global.colors.textDefault }}
						onPress={() => {
							inputRef.current.clear();
							updateSearch("");
							onCreate();
						}}
					/>
				</View>
			)}
		</View>
	);
}

export default SearchBar;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	container: {
		flexDirection: "row",
		borderColor: colors.lightBorder,
		borderRadius: 16,
		backgroundColor: colors.inputFieldBackground,
		paddingHorizontal: 8,
		paddingVertical: 2,
		flex: 1,
	},
	input: {
		flex: 1,
		height: 26,
		color: colors.textDefault,
		...ws({ outlineWidth: 0 }, null),
	},
	clearIcon: {},
	barRight: {
		height: 26,
		alignItems: "center",
		justifyContent: "center",
	},
}));
