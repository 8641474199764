import { _ } from "../i18n/i18n";
import store from "../store/store";
import { numberToRoman } from "./generic";

export const getMarkerTitle = (marker, storyMarkers) => {
	if (!marker) return "";

	const number = getStoryMarkerNumber(storyMarkers, marker);
	return markerTypeToString(marker.type) + " " + (marker.type === 1 ? numberToRoman(number) : number);
};

export const markerTypeToString = (type) => {
	switch (type) {
		case 1:
			return _("Act", "Story marker name");
		case 2:
			return _("Chapter", "Story marker name");
		default:
			return _("Scene", "Story marker name");
	}
};

export const pathToBackground = (marker) => {
	let backgroundName = "neutral_1";
	if (marker && marker.stage && marker.stage.background) {
		backgroundName = marker.stage.background.name;
	}
	return `${global.root_url}/static/images/stagebg/bg_${backgroundName}.png`;
};

export function getStoryMarkerNumber(allMarkers, markerToFind) {
	if (!markerToFind) return null;
	let number = 0;

	allMarkers = sortMarkers(allMarkers);

	for (let i = 0; i < allMarkers.length; ++i) {
		let marker = allMarkers[i];

		if (marker.hidden) {
			continue;
		}

		if (marker.type < markerToFind.type || (markerToFind.type > 1 && marker.party !== markerToFind.party)) {
			number = 0;
		} else if (marker.type === markerToFind.type) {
			number++;
		}

		if (marker.id === markerToFind.id) {
			break;
		}
	}

	return number;
}

export function sortMarkers(markers) {
	if (!markers.length) return [];
	const gameId = markers[0].game;
	const sorted = markers.filter((sm) => !sm.hidden);
	const parties = store.getState().parties[gameId]?.store;
	sorted.sort((a, b) => {
		let a_start_line_id = getMarkerStartLineId(a, parties);
		let b_start_line_id = getMarkerStartLineId(b, parties);

		if (a.party && b.party && b.party !== a.party) {
			return a.party - b.party;
		}

		return a_start_line_id - b_start_line_id;
	});
	return sorted;
}

export function getMarkerStartLineId(marker, parties){
	if (marker.party && marker.start_line_id === 0) {
		if(!parties) return 0;
		return parties[marker.party].separation_line_id;
	}
	return marker.start_line_id;
}

export const getLastScene = (gameId) => {
	let storyMarkers = store.getState().storyMarkers[gameId];

	if(!storyMarkers) return null;

	const partiesSlice = store.getState().parties[gameId];
	const parties = partiesSlice?.store;
	const openedParties = partiesSlice?.openedParties;

	const markerStart = (marker) => {
		return marker.start_line_id || parties[marker.party]?.separation_line_id || 0;
	};

	storyMarkers = storyMarkers.filter((sm) => !sm.party || openedParties?.map((p) => p.id).includes(sm.party));
	storyMarkers.sort((a, b) => markerStart(a) - markerStart(b));

	return storyMarkers.rg_last();
};

export const getMostRecentScene = (line) => {
	if (!line) return null;
	const storyMarkers = store.getState().storyMarkers[line.game];

	if (!storyMarkers) {
		return null;
	}

	const gameId = line.game;
	const partiesSlice = store.getState().parties[gameId];
	const parties = partiesSlice?.store;
	const openedParties = partiesSlice?.openedParties;

	const markerStart = (marker) => {
		return marker.start_line_id || parties[marker.party]?.separation_line_id || 0;
	};

	let previousScenes = [];
	// if it is a fake line (meaning not in the database yet)
	if (typeof line.id === "string" || !line.id) {
		previousScenes = storyMarkers.slice();
	} else {
		previousScenes = storyMarkers.filter((sm) => markerStart(sm) < line.id);
	}

	previousScenes = previousScenes.filter((sm) => !sm.party || openedParties?.map((p) => p.id).includes(sm.party));

	previousScenes.sort((a, b) => markerStart(a) - markerStart(b));

	return previousScenes.rg_last();
};

export const findMarkerParent = (marker, storyMarkers) => {
	const index = storyMarkers.findIndex((sm) => sm.id === marker.id);
	return storyMarkers.slice(0, index).rg_findLast((sm) => sm.type < marker.type && sm.party === marker.party);
};
