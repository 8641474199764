import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";
import { connect, useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { fetchSymbolInteractions } from "../../store/slices/symbolInteractionsSlice";
import { colors } from "../../styles/colors";
import { fullScreenContentWidth, fullScreenLeftPadding } from "../../styles/dynamicStyles";
import { globalStyles, globalStylesMethod } from "../../styles/global";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import IconButton from "../generic/buttons/IconButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import DieFaceSymbol from "../lines/DieFaceSymbol";

function SymbolInteractionItem({ interaction, gameId, diceSet, isMine }) {
	const dispatch = useDispatch();

	const deleteInteraction = React.useCallback(async () => {
		let url = gameId ? `games/${gameId}/` : "";
		url += `dice-sets/${diceSet.id}/delete-interaction`;

		await apiFetch(url, "POST", {
			a: interaction.symbol_a.id,
			b: interaction.symbol_b.id,
			rule: interaction.rule,
		});

		dispatch(fetchSymbolInteractions([diceSet.id]));
	}, [gameId, diceSet.id]);

	return (
		<View style={[globalStyles.rc, { marginBottom: 8 }]}>
			<View style={[globalStyles.rcsb, { flex: 1 }, ws({maxWidth: 300})]}>
				<DieFaceSymbol symbol={interaction.symbol_a} detailsOnPress color={global.colors.textDefault} />
				<AppText bold>{_("cancels", "dice set rule, ie: Symbola A 'cancels' symbol B")}</AppText>
				<DieFaceSymbol symbol={interaction.symbol_b} detailsOnPress color={global.colors.textDefault} />
			</View>
			<IconButton
				hide={!isMine}
				style={{ marginLeft: 16 }}
				icon={{ type: Feather, name: "delete" }}
				onPress={deleteInteraction}
				transparent
			/>
		</View>
	);
}

function SetDiceRulesScreen({ route, navigation, dispatch, userId, symbolInteractions, gameId }) {
	const { diceSet, symbols } = route.params;

	const setInteractions = React.useMemo(
		() =>
			symbolInteractions
				.filter((si) => si.dice_set === diceSet.id)
				.rg_mapDistinct(
					(si) => si,
					(element, other) =>
						element.symbol_a.id === other.symbol_b.id && other.symbol_a.id === element.symbol_b.id
				),
		[symbolInteractions, diceSet.id]
	);

	React.useEffect(() => {
		dispatch(fetchSymbolInteractions([diceSet.id]));
	}, [diceSet.id]);

	const isMine = diceSet.creator && diceSet.creator.id === userId;

	React.useEffect(() => {
		if (!isMine) {
			navigation.setOptions({ headerTitle: _("Dice rules", "dice set rules") });
		}
	}, [isMine]);

	return (
		<AppScreenView
			borderless
			style={ws({
				paddingLeft: fullScreenLeftPadding(),
			})}
		>
			<FlatList
				contentContainerStyle={[
					{ paddingHorizontal: 16, marginTop: 8 },
					ws({ maxWidth: fullScreenContentWidth() }),
				]}
				ListHeaderComponent={
					<>
						<AppText bold>{_("Rules")}</AppText>
						<AppText color="hint" style={{ marginBottom: 16 }}>
							{isMine
								? _(
										"Specify if a symbol must cancel another one when both are rolled.",
										"name symbols screen"
								  )
								: _(
										"List of symbols that cancel each other when both are rolled.",
										"name symbols screen"
								  )}
						</AppText>
					</>
				}
				keyExtractor={idKeyExtractor}
				ListEmptyComponent={
					<EmptyScreenView
						messageTop={_("No rules set.")}
						icon={{ type: MaterialCommunityIcons, name: "cancel" }}
						messageBottom={isMine ? _("Press the + button to add a rule to this dice set.") : null}
					/>
				}
				data={setInteractions}
				renderItem={({ item }) => (
					<SymbolInteractionItem interaction={item} gameId={gameId} diceSet={diceSet} isMine={isMine} />
				)}
			/>
			{isMine && (
				<FAB
					icon="plus"
					style={globalStylesMethod(global.theme).fab}
					onPress={() => navigation.navigate("CreateSymbolInteractionModal", { diceSet, symbols })}
				/>
			)}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { diceSet } = ownProps.route.params;
	return {
		symbolInteractions: state.symbolInteractions,
		gameId: state.games.currentId,
		userId: state.user?.id,
	};
};

export default connect(mapStateToProps)(SetDiceRulesScreen);


