import * as React from "react";
import {Image, View} from "react-native";
import {Divider} from "react-native-paper";
import AppText from "../components/generic/AppText";
import {ws} from "../tools/generic";
import AppButton from "../components/generic/buttons/AppButton";

export const newsStore = [
    {
        appVersionMajor: 1,
        appVersionMinor: 191,
        title: "Project Hedron & The Future",
        content: (
            <>
                <View style={{alignItems: "center", marginBottom: 8, marginTop: 0}}>
                    <Image
                        style={{height: 300 * 0.8, width: 800 * 0.8, maxWidth: "100%"}}
                        source={require("../../assets/images/news/role-gate-ttm-banner.png")}
                        resizeMode="contain"
                    />
                </View>
                <AppText style={{marginVertical: 4}}>
                    Hi,
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    Varun here again, with a new update for all of you -- as well as a map of the road ahead.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    So What's In This Update?
                </AppText>
                <AppText list>
                    <AppText bold>Full Game Searching!</AppText>: Search the entirety of your game using the new game
                    search functionalities! And yes, it's on iOS and Android!
                </AppText>
                <AppText list>
                    <AppText bold>Custom Tone Fixes</AppText>: Custom Tone Colorations no longer "lock you" into the
                    color chosen.
                </AppText>
                <AppText list>
                    <AppText bold>GM-only Rolls</AppText>: When you roll, you can now specify that only you and the GM
                    should be able to see it. I.e. you can have "secret" rolls now!
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    This update turned out to be <AppText bold>tough.</AppText> We wanted to have even more stuff, but
                    we ran into technical hurdle after technical hurdle. So for that, you have my apologies.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    But you also have my promise: we have charted a road ahead that will make each update AT LEAST 5
                    times bigger, and WEEKLY instead of near-monthly. And that road ahead is called "Project Hedron".
                </AppText>
                <AppText bold centered size="large" style={{marginBottom: 4, marginTop: 12}}>
                    Project Hedron
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    So what is it? In the coming few weeks, we're launching all of the functionalities of Role Gate
                    through Tabletop Mirror. This will mean a completely revised and much more polished approach to Play
                    By Post experience.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    And the best part? You can be a part of it.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    Learn more and sign-up to be a part of the future at
                    &nbsp;
                    <AppText color="url" accessibilityRole="link" href="https://tabletopmirror.com/hedron"
                             target="_blank">
                        The Official Project Hedron Page
                    </AppText>.
                </AppText>
                <AppButton size="large"
                           style={{marginVertical: 12}}
                           callToAction
                           title="SHOW YOUR SUPPORT"
                           to="https://www.backerkit.com/c/projects/tabletop-mirror-llc/tabletop-mirror-your-personal-vtt?ref=role-gate-update-2"></AppButton>
                <AppText style={{marginVertical: 4}}>
                    Now, of course, endeavors like Project Hedron aren't easy. And while our vision for the future
                    is <AppText bold>ambitious</AppText>, it's attainable.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    How? Through support of folks like you. You all have shown us an OVERWHELMING amount of support
                    already, and that support is going straight into Project Hedron. Without the support so far, we
                    wouldn't even be able to DREAM of Project Hedron. Let alone bring it to fruition.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    Whether you've pledged or not, we're in this to make an amazing platform unlike any other. So
                    whether a pledge or a share, every bit brings Project Hedron (and all the Projects that will follow
                    it) a bit closer!
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    So thank you. Thank you for being a part of our future.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    Let's change the game, together.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    &nbsp;- Varun
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 190,
        title: "The Start of a New Chapter",
        content: (
            <>
                <View style={{alignItems: "center", marginBottom: 8, marginTop: 0}}>
                    <Image
                        style={{height: 300 * 0.8, width: 800 * 0.8, maxWidth: "100%"}}
                        source={require("../../assets/images/news/role-gate-ttm-banner.png")}
                        resizeMode="contain"
                    />
                </View>
                <AppText style={{marginVertical: 4}}>
                    Hi,
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    I'm Varun, the new owner of Role Gate.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    We just released our <AppText bold>FIRST</AppText> update for Role Gate. It was tough, but our new
                    dev team is tougher. This
                    update was based on requests and feedback from all of you -- especially those of you on our
                    &nbsp;
                    <AppText color="url" accessibilityRole="link" href="https://discordapp.com/invite/3E37sQ7"
                             target="_blank">
                        Role Gate Discord
                    </AppText>.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    It's got (among other stuff):
                </AppText>
                <AppText list>
                    <AppText bold>Macros for All!</AppText>: No more needing to pay to use Macros. Everyone now has
                    access to Macros. The first feature of many...
                </AppText>
                <AppText list>
                    <AppText bold>Custom Tones</AppText>: Fully own your tones in-game with 100% customizable tones
                    (both the tone AND color are yours to use)
                </AppText>
                <AppText list>
                    <AppText bold>Search Recent Messages</AppText>: You can now search the recent messages in your
                    game! Web-only for now...
                </AppText>
                <AppText list>
                    <AppText bold>Multiple Bug Fixes</AppText>: We fixed quite a few smaller bugs, but there's more
                    work to be done. Don't worry, addressing the infamous iOS Character Sheet Freezing issue is on
                    our radar.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    And this is just the start -- our "trial run" if you will. We're preparing an <AppText bold>EVEN
                    BIGGER</AppText> update with <AppText bold>EVEN MORE STUFF</AppText> for 2 weeks from now. Along
                    with complete patch notes for this and that update.
                </AppText>
                <AppText bold centered size="large" style={{marginBottom: 4, marginTop: 12}}>
                    BUT WE NEED YOUR HELP
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    Now that we all fly under the banner of Tabletop Mirror, our upcoming Crowdfunding Campaign is
                    for <AppText bold>ALL OF US</AppText>.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    Every dollar we raise is a bug squashed. Every pledge is a new feature added. Every share is another
                    piece of our vision realized, together.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    So if you can help us with a follow, spare a share, or even back us yourself, it would honestly mean
                    the world.
                </AppText>
                <AppButton size="large"
                           style={{marginVertical: 12}}
                           callToAction
                           title="FOLLOW OUR CROWDFUNDING CAMPAIGN"
                           to="https://www.backerkit.com/call_to_action/492f8283-00b6-405f-8af9-5fc1496bd056/landing?ref=role-gate-update-1"></AppButton>
                <AppText style={{marginVertical: 4}}>
                    I know we haven't earned your trust yet, but we're here to stay. And whether it takes 1 update or
                    100 or 1000, we're going to build the best platform out there.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    See you again in 2 weeks for our next update.
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    And hopefully at the Crowdfunding launch next week,
                </AppText>
                <AppText style={{marginVertical: 4}}>
                    &nbsp;- Varun
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 189,
        title: "A New Chapter for Role Gate: Welcoming Varun from Tabletop Mirror",
        content: (
            <>
                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 1000 * 0.2, width: 1000 * 0.2, maxWidth: "100%"}}
                        source={require("../../assets/images/news/role gate ttm square.png")}
                        resizeMode="contain"
                    />
                </View>
                <AppText style={{marginVertical: 16}} color="attention">
                    A message from Ryan
                </AppText>
                <AppText>
                    After developing Role Gate for the last 6 years, it is time for me to pass the torch.
                    {"\n\n"}As life's demands have increased, the time I could dedicate to growing the platform has
                    become increasingly limited. The recent birth of my daughter has made it nearly impossible for me to
                    give Role Gate the attention it deserves.
                    {"\n\n"}I am delighted to announce that I have found someone to give Role Gate the attention it
                    needs and make it stronger than ever: <AppText bold>Varun from Tabletop Mirror</AppText>.{"\n\n"}The
                    transition will be seamless for you, as I step away from Role Gate as a developer (though definitely
                    not as a player). On your side, you will gain an active development team dedicated to fixing
                    longstanding bugs and introducing exciting new features to expand the platform's functionality.
                    {"\n\n"}Although I was increasingly worried about not being able to meet the demands of the task, I
                    am happy to know that Role Gate will get a new breath of life and thrive beyond what I could offer.
                    {"\n\n"}Please join me in welcoming Varun, Tabletop Mirror, and all they bring to the future of Role
                    Gate.
                </AppText>
                <AppText style={{marginVertical: 16}} color="attention">
                    A message from Varun
                </AppText>
                <AppText>
                    Varun, creator of Tabletop Mirror, here. I built a platform dedicated to making Tabletop Gaming more
                    accessible for everyone. With the ability to create homebrew for popular systems, create entirely
                    customized systems from scratch, and even do all your worldbuilding from one place, Tabletop Mirror,
                    though new, has certainly quickly become a favorite platform for making your TTRPG experience yours.
                    {"\n\n"}When we heard Ryan wanted to step away, we stepped in to help make sure that the legacy he
                    created with all of your help is preserved. In our vision, we believe we can use the powerful
                    functions of TTM to give Role Gate the love it deserves.
                    {"\n\n"}In time, we’ll not only preserve and protect the functionalities of Role Gate, but we’ll
                    bring even more new features to level up your experience:
                </AppText>
                <AppText list style={{marginTop: 16}}>
                    <AppText bold>Built-in Rule System</AppText> support for every system – even your original ones
                </AppText>
                <AppText list>
                    <AppText bold>Auto-Archiving</AppText> of old posts/chats and advanced search functionalities
                </AppText>
                <AppText list>
                    <AppText bold>Tools for custom character sheets</AppText> for any and every system on the app
                </AppText>
                <AppText list>
                    <AppText bold>Desktop</AppText> Support
                </AppText>
                <AppText list>
                    <AppText bold>Worldbuilding Tools</AppText> to directly use in your campaigns
                </AppText>
                <AppText style={{marginTop: 16}}>
                    And more.
                    {"\n\n"}We know you probably have questions about the change. I am known in my community for my
                    transparency and willingness to communicate, so expect no different here. In addition to upcoming
                    dedicated live chats, Q&As, and AMAs, I will be on the Role Gate Discord on{" "}
                    <AppText color="primary" bold>
                        July 17 and July 18 from 6:30 PM to 7:30 PM US Pacific Time
                    </AppText>{" "}
                    (and longer if there is more interest). {"\n\n"}Please come by, and ask me literally anything. If
                    you can’t
                    make it, don’t worry there’ll be even more opportunities in the future.
                    {"\n\n"}
                    Let’s build a better platform together.
                </AppText>
                <AppText textOnlyStyle={{textAlign: "right", marginTop: 16}}>From both of us,</AppText>
                <AppText textOnlyStyle={{textAlign: "right"}} italic allowMarkdown={false}>
                    - Ryan and Varun
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 177,
        title: "May update - Character sheets image upload",
        content: (
            <>
                <AppText>A new option is now available when adding a field to a character sheet template: </AppText>

                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 91 * 1, width: 372 * 1, maxWidth: "100%"}}
                        source={require("../../assets/images/news/Image_upload_field.png")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>
                    The Game Master can add as many image fields as they want, and resize them to all sort of sizes and
                    ratios.
                </AppText>
                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 938 * 0.5, width: 758 * 0.5, maxWidth: "100%"}}
                        source={require("../../assets/images/news/portrait_template.png")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 16}}>
                    This field lets players upload pictures to their character sheets.
                </AppText>
                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 781 * 0.5, width: 757 * 0.5, maxWidth: "100%"}}
                        source={require("../../assets/images/news/portrait_sheet.png")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 16}}>
                    Character sheet image uploads are available to{" "}
                    <AppText style={{color: global.colors.accountColors.silver}} bold>
                        Silver
                    </AppText>{" "}
                    and above.
                </AppText>

                <AppText style={{marginTop: 24}}>
                    As always, you are welcome to join{" "}
                    <AppText
                        color="url"
                        accessibilityRole="link"
                        href="https://discordapp.com/invite/3E37sQ7"
                        target="_blank"
                    >
                        the discord server
                    </AppText>{" "}
                    to give feedback and ask questions.
                </AppText>

                <AppText style={{marginTop: 8, textAlign: "right"}} color="hint">
                    If you enjoy Role Gate, please consider upgrading your account!
                </AppText>

                <AppText textOnlyStyle={{textAlign: "right"}}>Enjoy!</AppText>
                <AppText textOnlyStyle={{textAlign: "right"}} italic allowMarkdown={false}>
                    - Div
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 144,
        title: "October update - Notifiers and Looking-for-players alerts",
        content: (
            <>
                <AppText color="primary" bold size="large">
                    Notifiers
                </AppText>

                <AppText>
                    A new button is now available on the My games screen:{" "}
                    <AppText bold color="attention">
                        Create a notifier
                    </AppText>
                </AppText>

                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 149 * 1, width: 391 * 1, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/New_option.jpg")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>
                    By creating a notifier, you will be able to set a list of{" "}
                    <AppText bold color="attention">
                        tags that you are interested in
                    </AppText>
                    , as well as{" "}
                    <AppText bold color="attention">
                        tags that you wish to avoid
                    </AppText>
                    .
                </AppText>
                <AppText style={{marginTop: 8}}>
                    As soon as a game that matches your criteria signals that it is{" "}
                    <AppText bold color="attention">
                        looking for players
                    </AppText>
                    , you will receive a mobile and/or email notification.
                </AppText>

                <View style={{alignItems: "center", marginVertical: 0}}>
                    <Image
                        style={{height: 443 * ws(0.8, 0.5), width: 869 * 0.8, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/Triggered_alert.jpg")}
                        resizeMode="contain"
                    />
                </View>
                <AppText style={{marginTop: 8}}>
                    You can also create a notifier to find out about{" "}
                    <AppText bold color="attention">
                        less popular styles of games
                    </AppText>
                    , if you are looking for your next favorite system.
                </AppText>

                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 123 * 1, width: 573 * 1, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/low_demand.jpg")}
                        resizeMode="contain"
                    />
                </View>

                <Divider theme={{dark: true}} style={{marginVertical: 24}}/>
                <AppText color="primary" bold size="large">
                    Looking-for-players alerts
                </AppText>
                <AppText>
                    A new{" "}
                    <AppText bold color="attention">
                        Send looking-for-players alert
                    </AppText>{" "}
                    button is now available for Game masters, in the game settings of any game they run.
                </AppText>

                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 81, width: 399, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/send_alert.jpg")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>
                    Game masters can use this functionality once per week. It will send a notification to every player
                    who created a Notifier that matches their game.
                </AppText>

                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 363 * ws(1, 0.5), width: 639, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/send_alert_modal.jpg")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}} color="secondary">
                    Notifiers and looking-for-players alerts are available to everyone, for free.
                </AppText>

                <Divider theme={{dark: true}} style={{marginVertical: 24}}/>
                <AppText color="primary" bold size="large">
                    Requested tags section
                </AppText>
                <AppText>Finally, a new section is now showing on the Explore screen.</AppText>

                <View style={{alignItems: "center", marginVertical: 16}}>
                    <Image
                        style={{height: 105, width: 591, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/new_section.jpg")}
                        resizeMode="contain"
                    />
                </View>
                <AppText>
                    This sections shows the tags people created the most Notifiers for. If you are looking for ideas on
                    what to run next, this section should be a great start!
                </AppText>

                <Divider theme={{dark: true}} style={{marginTop: 16, marginBottom: 16}}/>
                <AppText color="primary" bold size="large">
                    Final note
                </AppText>

                <AppText>
                    People have been wanting a way to find active games for a long time, with an option to filter
                    specific tags in or out. These new features will help games that are actively looking for players
                    come directly to the players.
                </AppText>
                <AppText style={{marginTop: 8}}>
                    For Game masters, it is the opportunity to give your game visibility, even if it has been running
                    for a while and got lost in the library of available games.
                </AppText>

                <AppText style={{marginTop: 24}}>
                    As always, you are welcome to join{" "}
                    <AppText
                        color="url"
                        accessibilityRole="link"
                        href="https://discordapp.com/invite/3E37sQ7"
                        target="_blank"
                    >
                        the discord server
                    </AppText>{" "}
                    to give feedback and ask questions.
                </AppText>

                <AppText style={{marginTop: 8, textAlign: "right"}} color="hint">
                    If you enjoy Role Gate, please consider upgrading your account!
                </AppText>

                <AppText textOnlyStyle={{textAlign: "right"}}>Enjoy!</AppText>
                <AppText textOnlyStyle={{textAlign: "right"}} italic allowMarkdown={false}>
                    - Div
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 112,
        title: "July update - New features!",
        content: (
            <>
                <AppText color="primary" bold size="large">
                    AI Generation
                </AppText>

                <AppText color="secondary">Very excited to share this one!</AppText>
                <AppText>
                    A new menu is now available on the side bar:{" "}
                    <AppText bold color="attention">
                        AI Generator
                    </AppText>
                </AppText>

                <AppText style={{marginTop: 8}}>
                    The generator will use the power of <AppText bold>GPT-3</AppText> (the most powerful text-generation
                    AI currently available) to write original descriptions for you.
                </AppText>
                <AppText style={{marginTop: 8}}>You will be able to generate:</AppText>
                <AppText list>Character descriptions</AppText>
                <AppText list>Location descriptions</AppText>
                <AppText list>Magic item descriptions</AppText>

                <AppText style={{marginTop: 8}}>Check the demonstration below:</AppText>

                <View style={{alignItems: "center", marginBottom: 8}}>
                    <Image
                        style={{height: 669 * 0.8, width: 820 * 0.8, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/Description_generator.gif")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>
                    Requesting brand new generations is currently limited to{" "}
                    <AppText style={{color: global.colors.accountColors.gold}} bold>
                        Gold
                    </AppText>{" "}
                    subscribers and above. But anyone is able to browse through the content generated by other
                    users{" "}
                    <AppText bold color="attention">
                        for free!
                    </AppText>
                </AppText>
                <Divider theme={{dark: true}} style={{marginVertical: 24}}/>
                <AppText color="primary" bold size="large">
                    Export games
                </AppText>
                <AppText>
                    A new{" "}
                    <AppText bold color="attention">
                        Export game
                    </AppText>{" "}
                    button is now available in the game settings of any game you play.
                </AppText>

                <View style={{alignItems: "center", marginBottom: 8}}>
                    <Image
                        style={{height: 78, width: 163, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/Export_game.png")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>
                    This will let you request a download link to a JSON file that contains the whole game's story.
                </AppText>
                <AppText style={{marginTop: 8}}>
                    There is also an option to export out-of-character game chat, which will ask each player's consent
                    before the export begins.
                </AppText>

                <AppText style={{marginTop: 8}}>
                    The ability to export a specific game can also be turned off in the game's settings.
                </AppText>

                <View style={{alignItems: "center", marginBottom: 8}}>
                    <Image
                        style={{height: 103 * 0.8, width: 685 * 0.8, maxWidth: "100%"}}
                        // source={require("../../assets/images/news/Allow_export.png")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>You must be part of a game to request an export.</AppText>

                <AppText style={{marginTop: 8}}>
                    Game export is available to{" "}
                    <AppText style={{color: global.colors.accountColors.silver}} bold>
                        Silver
                    </AppText>{" "}
                    and above.
                </AppText>

                <Divider theme={{dark: true}} style={{marginTop: 16, marginBottom: 8}}/>

                <AppText style={{marginTop: 24}}>
                    As always, you are welcome to join{" "}
                    <AppText
                        color="url"
                        accessibilityRole="link"
                        href="https://discordapp.com/invite/3E37sQ7"
                        target="_blank"
                    >
                        the discord server
                    </AppText>{" "}
                    to give feedback and ask questions.
                </AppText>

                <AppText style={{marginTop: 8, textAlign: "right"}} color="hint">
                    If you enjoy Role Gate, please consider upgrading your account!
                </AppText>

                <AppText textOnlyStyle={{textAlign: "right"}}>Enjoy!</AppText>
                <AppText textOnlyStyle={{textAlign: "right"}} italic allowMarkdown={false}>
                    - Div
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 85,
        title: "May update - New features!",
        content: (
            <>
                <AppText color="primary" bold size="large">
                    Assistant Game Masters
                </AppText>

                <AppText>As a Game Master, you can now make some players "Assistant GM".</AppText>
                <AppText style={{marginTop: 8}}>Assistant GMs can do everything the Game Master can, except:</AppText>
                <AppText list>Changing the main Game Master</AppText>
                <AppText list>Deleting or archiving the game</AppText>
                <AppText list>Editing the character sheet template</AppText>
                <AppText style={{marginTop: 8}}>They also do not own a specific character like players do.</AppText>

                <AppText color="primary" bold size="large" style={{marginTop: 32}}>
                    Transfer game ownership
                </AppText>
                <AppText>
                    You are feeling burned out, or would like someone else to take over as the Game master?
                </AppText>
                <AppText style={{marginTop: 8}}>
                    You can now give the ownership of a game you created away to any player already in the game.
                </AppText>

                <View style={{alignItems: "center", marginBottom: 8}}>
                    <Image
                        style={{height: 619 * 0.5, width: 864 * 0.5, maxWidth: "90%"}}
                        // source={require("../../assets/images/news/AssistantGM.png")}
                        resizeMode="contain"
                    />
                </View>

                <Divider theme={{dark: true}} style={{marginVertical: 8}}/>

                <AppText color="primary" bold size="large" style={{marginTop: 32}}>
                    Legendary sharing
                </AppText>
                <AppText>The Legendary subscription has been upgraded.</AppText>
                <AppText style={{marginTop: 8}}>
                    By simply being part of a game,{" "}
                    <AppText style={{color: global.colors.accountColors.legendary}} bold>
                        Legendary
                    </AppText>{" "}
                    players will now unlock the following{" "}
                    <AppText style={{color: global.colors.accountColors.silver}} bold>
                        Silver
                    </AppText>{" "}
                    features for every other players:
                </AppText>
                <AppText list>Copper and Silver avatars</AppText>
                <AppText list>Use Macros</AppText>
                <AppText list>Upload images</AppText>
                <AppText list>Use Copper and Silver stage backgrounds</AppText>

                <View style={{alignItems: "center", marginBottom: 8, marginTop: 28}}>
                    <Image
                        style={{height: 480 * 0.5, width: 864 * 0.5, maxWidth: "95%"}}
                        // source={require("../../assets/images/news/LegendarySharing.png")}
                        resizeMode="contain"
                    />
                </View>

                <AppText style={{marginTop: 8}}>
                    This guarantees a certain level of game quality for every player with a Legendary subscription.
                </AppText>
                <AppText style={{marginTop: 8}}>
                    It also opens up the possibility of several players sharing a single subscription.
                </AppText>

                <Divider theme={{dark: true}} style={{marginVertical: 8}}/>
                <AppText style={{marginTop: 8, textAlign: "right"}} color="hint">
                    If you enjoy Role Gate, please consider upgrading your account!
                </AppText>
                <AppText textOnlyStyle={{textAlign: "right"}}>Have fun</AppText>
                <AppText textOnlyStyle={{textAlign: "right"}} italic allowMarkdown={false}>
                    - Div
                </AppText>
            </>
        ),
    },
    {
        appVersionMajor: 1,
        appVersionMinor: 70,
        title: "New website online",
        webOnly: true,
        content: (
            <>
                <View style={{alignItems: "center", marginBottom: 8}}>
                    {/* <Image style={{ width: 128, height: 128 }} source={require("../../assets/images/Logo.png")} /> */}
                </View>
                <AppText>
                    <AppText color="primary" bold>
                        New horizon
                    </AppText>
                </AppText>
                <AppText>
                    There we are! The conclusion of more than a year of development for Role Gate to become a duo
                    website / mobile app.
                </AppText>
                <AppText style={{marginTop: 8}}>
                    Now that this huge milestone has been reached, I will finally be able to resume pushing Role Gate
                    forward with exciting new features that are already in the work.
                </AppText>

                <AppText color="primary" bold style={{marginTop: 24}}>
                    What's new
                </AppText>
                <AppText style={{marginBottom: 8}}>
                    This version of the website comes with a new, more consistent UX/UI and a set of new features.
                </AppText>

                <AppText list>Image uploads starting from rank silver</AppText>
                <AppText list>Direct messages</AppText>
                <AppText list>More complete dice roll interface, without having to rely on Roll+ commands</AppText>
                <AppText list>Renaming character sheets.</AppText>
                <AppText list>Themes: default and darkest for now but more to come later on.</AppText>
                <AppText list>Interactive helpers outside of games.</AppText>
                <AppText list>Wizard with brand new filters to find games to join</AppText>
                <AppText list>Explore page showing categories that you selected</AppText>

                <AppText color="primary" bold style={{marginTop: 24}}>
                    Why a new website?
                </AppText>
                <AppText style={{marginBottom: 8}}>
                    There are several reason that led to the existence of this revamp.
                </AppText>
                <AppText list bold color="secondary">
                    A more welcoming experience for new comers.
                </AppText>
                <AppText style={{paddingLeft: 24}}>
                    The new interface is meant to answer some of the most frequent questions asked by new comers.
                </AppText>
                <AppText list bold color="secondary">
                    The website and mobile app now share the same code base.
                </AppText>
                <AppText style={{paddingLeft: 24}}>
                    This change is critical. As a solo developer, this means I will be able to push both platforms
                    forward simultaneously. Which wasn't possible before.
                </AppText>
                <AppText list bold color="secondary">
                    Cleaner code
                </AppText>
                <AppText style={{paddingLeft: 24}}>
                    I started Role Gate as a tiny platform to play with my friends. Needless to say that the code wasn't
                    ready to support all the features that are currently available.
                </AppText>
                <AppText style={{paddingLeft: 24}}>
                    The revamp comes with a much cleaner code, making it way easier to build new features upon.
                </AppText>

                <AppText style={{marginTop: 24}}>
                    As always, you are welcome to join{" "}
                    <AppText
                        color="url"
                        accessibilityRole="link"
                        href="https://discordapp.com/invite/3E37sQ7"
                        target="_blank"
                    >
                        the discord server
                    </AppText>{" "}
                    to give feedback and ask questions.
                </AppText>

                <Divider theme={{dark: true}} style={{marginVertical: 8}}/>

                <AppText textOnlyStyle={{textAlign: "right"}}>Enjoy!</AppText>
                <AppText textOnlyStyle={{textAlign: "right"}} italic allowMarkdown={false}>
                    - Div
                </AppText>
            </>
        ),
    },
];
