import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { globalStyles } from "../../styles/global";
import AppText from "../generic/AppText";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";
import RGTokenIcon from "../RGTokenIcon";
import { apiFetch } from "../../network/apiFetch";
import { removeSheet, receiveSheets } from "../../store/slices/sheetsSlice";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";
import { colors } from "../../styles/colors";
import { ws } from "../../tools/generic";

async function canDelete(userId, sheetId) {
	if (!userId) return false;
	return apiFetch(`profiles/${userId}/sheet-templates/${sheetId}/can-delete`);
}

function CharacterSheetItem({ sheet, selectingTemplateFor, dispatch, user }) {
	const [allowDelete, setallowDelete] = React.useState(null);
	const deleteModalRef = React.useRef();
	const noDeleteModalRef = React.useRef();
	const sharedModalRef = React.useRef();
	const unsharedModalRef = React.useRef();

	const navigation = useNavigation();

	const macrosComp = (
		<AppText hide={!sheet.has_macros} color="hint" size="small" style={{ marginRight: 8 }}>
			<RGTokenIcon name="tk_d20" /> {_("macros included", "indicated sheet template uses macros")}
		</AppText>
	);

	const subtitle2 = (
		<View style={globalStyles.rc}>
			{ws(macrosComp)}
			<View style={{ minWidth: 50 }}>
				<AppText color="hint" size="small">
					<FontAwesome name="user" color={global.colors.textDefault} /> {sheet.usages}
				</AppText>
			</View>
			{ws(null, macrosComp)}
		</View>
	);

	const onDeletePressed = React.useCallback(async () => {
		let deletePermitted = allowDelete;
		if (allowDelete === null) {
			deletePermitted = await canDelete(user?.id, sheet.id);
			setallowDelete(deletePermitted);
		}

		if (deletePermitted) {
			deleteModalRef.current.show();
		} else {
			noDeleteModalRef.current.show();
		}
	}, [allowDelete, user?.id, sheet.id]);

	const deleteItem = React.useCallback(() => {
		apiFetch(`profiles/${user.id}/sheet-templates/${sheet.id}`, "DELETE");
		dispatch(removeSheet(sheet));
	}, [user?.id, sheet]);

	const togglePublic = React.useCallback(async () => {
		await apiFetch(`sheets/${sheet.id}`, "PATCH", { public: !sheet.public });
		dispatch(receiveSheets([{ ...sheet, public: !sheet.public }]));
		if (sheet.public) {
			unsharedModalRef.current.show();
		} else {
			sharedModalRef.current.show();
		}
	}, [sheet]);

	const isMine = user && sheet.saved_on_profile?.id === user.id;

	const options = [];
	isMine &&
		options.push({
			title: sheet.public
				? _("Make private", "unshare sheet template")
				: _("Share to community", "share sheet template"),
			onPress: togglePublic,
		});
	isMine &&
		options.push({
			title: _("Delete"),
			onPress: onDeletePressed,
			danger: true,
		});

	return (
		<>
			<ListButton
				wide
				options={options}
				iconLeft={{ type: MaterialCommunityIcons, name: "file-account" }}
				title={sheet.save_name || <AppText italic>{_("Unnamed sheet", "character sheet has no name")}</AppText>}
				subtitle={
					sheet.public
						? _("By %(username)s", "sheet template creator", { username: sheet.saved_on_profile.name })
						: null
				}
				onPress={() =>
					{
						navigation.navigate("CharacterSheetTemplateScreen", {
							sheetId: sheet.id,
							isTemplate: true,
							selectingTemplateFor,
						});
					}
				}
				subtitle2={sheet.public ? subtitle2 : null}
			/>
			<TinyFeedbackModal
				ref={sharedModalRef}
				message={_("Shared to community", "confirm sheet template shared")}
			/>
			<TinyFeedbackModal
				ref={unsharedModalRef}
				message={_("Removed from community", "confirm sheet template un-shared")}
			/>
			<BoxModal
				ref={deleteModalRef}
				message={
					<>
						<AppText>
							{_(
								"Are you sure you want to delete the template %(sheet_name)s? This action cannot be reverted.",
								"delete sheet template",
								{
									sheet_name: (
										<AppText bold key="1">
											{sheet.save_name}
										</AppText>
									),
								}
							)}
						</AppText>
						<AppText color="hint" style={{ marginTop: 16 }}>
							{_(
								"Other players' games using this template will use a copy of it.",
								"delete sheet template"
							)}
						</AppText>
					</>
				}
				isDelete
				onConfirm={deleteItem}
			/>
			<BoxModal
				ref={noDeleteModalRef}
				message={_("You use this template in one or more games and cannot delete it.")}
				options={[{ title: _("Got it!", "close info box") }]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(CharacterSheetItem);
