import { createSlice } from "@reduxjs/toolkit";
import { apiFetch, apiFetchSimple } from "../../network/apiFetch";

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState: {
		all: [],
		nextUrl: null,
	},
	reducers: {
		receiveNotifications: (state, { payload: { results, count, next } }) => {
			state.all = state.all.rg_overlapById(results, true);
			state.nextUrl = next;
		},
	},
});

export const { receiveNotifications } = notificationsSlice.actions;

export function fetchNotifications() {
	return async (dispatch, getState) => {
		const { user } = getState();
		return apiFetch(`users/${user.id}/coin-transactions`, "GET", {
            ordering: "-date",
            "page-size": 50,
		}).then((response) => dispatch(receiveNotifications(response)));
	};
}

export function fetchNextNotifications(url) {
	if (!url) return (dispatch, getState) => null;
	return async (dispatch, getState) => {
		return apiFetchSimple(url).then((response) => {
			dispatch(receiveNotifications(response));
		});
	};
}
