import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Divider, Portal } from "react-native-paper";
import { _ } from "../../i18n/i18n";
import Modal from "../../reimports/Modal";
import { sizes, weight } from "../../styles/font";
import { BuildStyleMethod } from "../../styles/theming";
import AppButton from "./buttons/AppButton";
import ButtonsRow from "./buttons/ButtonsRow";
import InputField from "./InputField";

class EditTextModal extends React.PureComponent {
	state = {
		text: "",
		visible: false,
	};

	show() {
		this.setState({ visible: true, text: String(this.props.initialValue || "") });
	}

	hide = () => {
		this.setState({ visible: false });
	};

	render() {
		const styles = stylesMethod(global.theme);

		const { text, visible } = this.state;
		let { onDone, inputFieldProps, title } = this.props;

		return (
			<Portal>
				<Modal animationType="fade" visible={visible} onRequestClose={this.hide} transparent>
					<View style={styles.screen}>
						<TouchableOpacity style={styles.backdrop} activeOpacity={1} onPress={this.hide} />
						<View style={styles.box}>
							<View style={{ paddingTop: 16, paddingHorizontal: 16 }}>
								<Text style={styles.title}>{title || _("Edit text")}</Text>
								<Divider />

								<InputField
									value={text}
									onChangeText={(t) => this.setState({ text: t })}
									autoFocus
									selectTextOnFocus
									autoGrow={inputFieldProps?.multiline}
									{...inputFieldProps}
								/>
							</View>

							<Divider />

							<ButtonsRow style={styles.footer}>
								<AppButton size="small" title={_("Cancel")} transparent onPress={this.hide} />
								<AppButton
									size="small"
									title={_("Confirm")}
									onPress={() => {
										onDone(this.state.text);
										this.hide();
									}}
								/>
							</ButtonsRow>
						</View>
					</View>
				</Modal>
			</Portal>
		);
	}
}

export default EditTextModal;

const stylesMethod = BuildStyleMethod((colors)=>StyleSheet.create({
	screen: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		paddingVertical: 64,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: colors.overlayBackground,
	},
	box: {
		minHeight: 150,
		width: 350,
		maxWidth: "70%",
		backgroundColor: colors.cardBackground,
	},
	title: {
		fontSize: sizes.large,
		fontWeight: weight.semibold,
		color: colors.textDefault,
	},
	footer: {
		padding: 16,
	},
}));
