import * as React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import Token from "../tokens/Token";
import { DieRuleSummary } from "./DieRuleSummary";
import { optimalColumnNum } from "../../tools/generic";

function DiceGroup({ onPress, onLongPress, delayLongPress, activeOpacity, appliedRules }) {
	const totalSize = 32;

	const numDice = appliedRules.dice.length;

	let numColumns;
	let numRows;

	// find a ratio with the smalled delta between numColumns and numRows;
	let smallestDelta = 1000;
	for (let i = 2; i < 5; i++) {
		const tempNumColumns = optimalColumnNum(numDice, i);
		const tempNumRows = Math.ceil(numDice / tempNumColumns);

		const delta = Math.abs(tempNumColumns - tempNumRows);
		if (delta < smallestDelta) {
			smallestDelta = delta;
			numColumns = tempNumColumns;
			numRows = tempNumRows;
		}
	}

	const size = Math.floor(totalSize / Math.max(numColumns, numRows));

	return (
		<TouchableOpacity onPress={onPress} activeOpacity={activeOpacity} style={styles.container}>
			<View style={styles.wrapContainer}>
				<View style={styles.diceContainer}>
					{appliedRules.dice.map((d, index) => {
						return <Token key={d.id} shape={d.die.icon} color={d.die.color} size={size} />;
					})}
				</View>
			</View>
			<DieRuleSummary rules={appliedRules} />
		</TouchableOpacity>
	);
}

export default DiceGroup;

const styles = StyleSheet.create({
	container: {
		alignItems: "center",
        maxWidth: 82,
        flex: 1
	},
	wrapContainer: {
		justifyContent: "center",
		alignItems: "center",
		height: 36,
		width: 32,
        overflow: "hidden"
	},
	diceContainer: {
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap",
		flexDirection: "row",
	},
	overlappingDie: {},
});
