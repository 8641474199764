import * as React from "react";
import { _ } from "../../i18n/i18n";
import { modalScreenOptions } from "../../tools/navigationOptions";
import SelectCharactersModal from "../characters/SelectCharactersModal";
import FullScreenTextEdit from "../generic/FullScreenTextEdit";
import RollMacroModal from "../macros/RollMacroModal";
import SelectMacroModal from "../macros/SelectMacroModal";
import AdvancedRollModal from "./AdvancedRollModal";
import SelectRollTypeModal from "./SelectRollTypeModal";
import SetTimerModal from "./SetTimerModal";
import SimpleRollModal from "./SimpleRollModal";


function RollDiceStack(Stack) {
	return (
		<Stack.Group screenOptions={modalScreenOptions}>
			<Stack.Screen name="SelectRollTypeModal" component={SelectRollTypeModal} />
			<Stack.Screen name="SimpleRollModal" component={SimpleRollModal} />
			<Stack.Screen name="AdvancedRollModal" component={AdvancedRollModal}  />
			<Stack.Screen name="SelectMacroModal" component={SelectMacroModal}  />
			{/* Must use transparentModal even if there is an opaque background, because there is a crash when going back from a non transparentModal to a transparentModal */}
			<Stack.Screen name="EditRollCommand"  component={FullScreenTextEdit} options={{ headerShown: true, headerTitle: _("Roll command"), presentation: "transparentModal" }}/> 
			<Stack.Screen name="SelectRequestTargets" component={SelectCharactersModal}  />
			<Stack.Screen name="SetTimerModal" component={SetTimerModal}  />
			<Stack.Screen name="RollMacroModal" component={RollMacroModal}  />
		</Stack.Group>
	);
}

export default RollDiceStack;
