import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { globalStyles } from "../../styles/global";
import AppText from "../generic/AppText";
import ModalScreen from "../generic/modal/ModalScreen";
import RGTokenIcon from "../RGTokenIcon";
import { _ } from "../../i18n/i18n";
import ButtonFooter from "../generic/buttons/ButtonFooter";
import BoxModal from "../generic/modal/BoxModal";
import { apiFetch } from "../../network/apiFetch";
import { connect } from "react-redux";
import { fetchSymbolInteractions } from "../../store/slices/symbolInteractionsSlice";

function Symbol({ symbol, allSymbols, setSymbol }) {
	const modalRef = React.useRef();

	return (
		<TouchableOpacity style={globalStyles.jac} onPress={() => modalRef.current.show()}>
			<RGTokenIcon name={symbol.value} size={64} />
			<AppText>{symbol.name}</AppText>
			<BoxModal ref={modalRef} wide options={[{ title: _("Cancel") }]}>
				<View style={styles.list}>
					{allSymbols.map((item) => (
						<TouchableOpacity
							key={item.value}
							style={styles.itemListed}
							onPress={() => {
								setSymbol(item);
								modalRef.current.hide();
							}}
						>
							<RGTokenIcon name={item.value} size={32} />
							<AppText size="small">{item.name}</AppText>
						</TouchableOpacity>
					))}
				</View>
			</BoxModal>
		</TouchableOpacity>
	);
}

function CreateSymbolInteractionModal({ route, navigation, dispatch, gameId }) {
	const { symbols, diceSet } = route.params;

	const [symbolA, setsymbolA] = React.useState(symbols[0]);
	const [symbolB, setsymbolB] = React.useState(symbols[0]);

	const createInteraction = React.useCallback(async () => {
		let url = gameId ? `games/${gameId}/` : "";
		url += `dice-sets/${diceSet.id}/create-interaction`;
		await apiFetch(url, "POST", {
			a: symbolA.id,
			b: symbolB.id,
			rule: "cancel",
		});
		dispatch(fetchSymbolInteractions([diceSet.id]));
		navigation.navigate("SetDiceRulesScreen", { createdInteraction: null });
	}, [gameId, diceSet.id, symbolA.id, symbolB.id]);

	return (
		<>
			<ModalScreen title={_("Add rule", "add rule to dice set")}>
				<AppText centered style={{ marginBottom: 16 }}>
					{_("Select two symbols that should cancel each others.")}
				</AppText>
				<View style={[globalStyles.rc, globalStyles.sa, { marginBottom: 16 }]}>
					<Symbol symbol={symbolA} allSymbols={symbols} setSymbol={setsymbolA} />
					<Symbol symbol={symbolB} allSymbols={symbols} setSymbol={setsymbolB} />
				</View>
			</ModalScreen>

			<ButtonFooter
				buttonProps={[
					{ title: _("Cancel"), onPress: () => navigation.goBack() },
					{ title: _("Add rule", "add rule to dice set"), onPress: createInteraction },
				]}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		gameId: state.games.currentId,
	};
};

export default connect(mapStateToProps)(CreateSymbolInteractionModal);

const styles = StyleSheet.create({
	container: {},
	list: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
	},
	itemListed: {
		margin: 8,
		alignItems: "center",
		width: 52,
	},
});
