import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { editNode } from "../../../store/slices/sheetNodesSlice";
import { BuildStyleMethod } from "../../../styles/theming";
import { isWeb, ws } from "../../../tools/generic";
import AppText from "../../generic/AppText";
import InputField from "../../generic/InputField";

function SheetNodeText({ node, index, horizontal, editingTemplate, allowSaveChanges }) {
	const styles = stylesMethod(global.theme);
	const dispatch = useDispatch();

	// Character sheets should not open with dozens of text inputs or it will cause:
	// https://github.com/facebook/react-native/issues/17530#issuecomment-886483750
	// Therefore, we open simple text fields that switch to inputs when tapped
	const [inputing, setinputing] = React.useState(isWeb());

	const changeValue = React.useCallback(
		(text) => {
			const edit = { ...node, value: text };
			if (editingTemplate) {
				edit.placeholder = edit.value;
			}
			dispatch(editNode(edit));
		},
		[node, dispatch]
	);

	const startInput = React.useCallback(() => {
		setinputing(true);
	}, []);

	const stopInput = React.useCallback(() => {
		setinputing(false);
	}, []);

	return (
		<View style={[styles.container]}>
			{(!allowSaveChanges || !inputing) && (
				<AppText style={styles.uneditableText} hideEmpty={false} onPress={allowSaveChanges && startInput}>
					{node.value}
				</AppText>
			)}
			{!!allowSaveChanges && inputing && (
				<InputField
					autoFocus={!isWeb()}
					value={node.value || ""}
					placeholder={node.placeholder}
					multiline={true}
					onChangeText={changeValue}
					autoGrow
					onBlur={ws(null, stopInput)}
				/>
			)}
		</View>
	);
}

export default React.memo(SheetNodeText);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flex: 1,
			alignItems: "stretch",
			paddingRight: 4,
		},
		uneditableText: {
			padding: 8,
			backgroundColor: colors.inputFieldBackground,
			...ws({ minHeight: 36, flex: 1 }),
		},
	})
);
