import { FontAwesome } from "@expo/vector-icons";
import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useHover } from "react-native-web-hooks";
import { useDispatch } from "react-redux";
import { colors } from "../../styles/colors";
import IconButton from "../generic/buttons/IconButton";

function InteractiveHelperSwitch({ onPress }) {
	const dispatch = useDispatch();

	const ref = React.useRef();
	const isHover = useHover(ref);

	return (
		<TouchableOpacity
			ref={ref}
			style={[{ justifyContent: "flex-end", flex: 1, marginBottom: 10 }, isHover && { opacity: 0.5 }]}
			onPress={onPress}
		>
			<IconButton
				style={{ marginLeft: -20, backgroundColor: global.colors.cardBackground }}
				icon={{ type: FontAwesome, name: "backward" }}
				onPress={onPress}
			/>
		</TouchableOpacity>
	);
}

export default InteractiveHelperSwitch;


