import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { _ } from "../../i18n/i18n";
import EditDiceSetScreen from "./EditDiceSetScreen";
import EditDieScreen from "./EditDieScreen";
import NameSymbolsScreen from "./NameSymbolsScreen";
import SetDiceRulesScreen from "./SetDiceRulesScreen";
import BrowseDiceSetsScreen from "./BrowseDiceSetsScreen";
import DieSymbolOptionsModal from "./DieSymbolOptionsModal";
import { modalScreenOptions } from "../../tools/navigationOptions";
import CreateSymbolInteractionModal from "./CreateSymbolInteractionModal";
import { colors } from "../../styles/colors";

const Stack = createStackNavigator();
function DiceSetStack() {
	return (
		<Stack.Navigator screenOptions={{ headerTintColor: global.colors.textDefault}}>
			<Stack.Screen
				name="BrowseDiceSetsScreen"
				component={BrowseDiceSetsScreen}
				options={{ headerTitle: _("Dice sets", "screen title"), title: _("Browse dice sets | Role Gate") }}
			/>
			<Stack.Screen name="EditDiceSetScreen" component={EditDiceSetScreen} options={{ title: "Role Gate" }} />
			<Stack.Screen
				name="EditDieScreen"
				component={EditDieScreen}
				options={{ headerTitle: _("Die", "screen title"), title: _("Die | Role Gate") }}
			/>
			<Stack.Screen
				name="NameSymbolsScreen"
				component={NameSymbolsScreen}
				options={{ headerTitle: _("Name die symbols", "screen title"), title: "Role Gate" }}
			/>
			<Stack.Screen
				name="SetDiceRulesScreen"
				component={SetDiceRulesScreen}
				options={{ headerTitle: _("Set rules", "dice set screen title"), title: "Role Gate" }}
			/>
			<Stack.Screen name="DieSymbolOptionsModal" component={DieSymbolOptionsModal} options={modalScreenOptions} />
			<Stack.Screen
				name="CreateSymbolInteractionModal"
				component={CreateSymbolInteractionModal}
				options={modalScreenOptions}
			/>
		</Stack.Navigator>
	);
}

export default DiceSetStack;
