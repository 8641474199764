import * as React from "react";
import { LogBox, StyleSheet } from "react-native";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import AppText from "../generic/AppText";
import BoxModal from "../generic/modal/BoxModal";

class SplitMessageModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lineType: null,
		};
		this.modalRef = React.createRef();
	}

	show(lineType) {
		this.modalRef.current.show();
		this.setState({ lineType });
	}

	onNever = () => {
		this.simpleSend();
		apiFetch(`profiles/${this.props.user.profile.id}`, "PATCH", { ask_auto_split: false });
	};

	simpleSend = () => {
		this.modalRef.current.hide();
		this.props.sendMessage(this.state.lineType, true);
	};

	onSplit = async () => {
		this.modalRef.current.hide();

		const { sendMessage, message } = this.props;

		let messages = [];

		const modeSplits = message
			.trim()
			.split(/"([^"]+)"/)
			.filter((m) => !!m);

		let currentMode = message.trim().startsWith('"') ? "dialogue" : "description";
		for (let i = 0; i < modeSplits.length; i++) {
			const element = modeSplits[i];

			messages.push({
				content: element.trim(),
				mode: currentMode,
			});

			currentMode = currentMode === "description" ? "dialogue" : "description";
		}

		for (let i = 0; i < messages.length; i++) {
			const element = messages[i];

			const linesSplit = element.content.split(/\n\n/);
			const newMessages = linesSplit
				.filter((el) => el.trim())
				.map((content) => ({ content: content.trim(), mode: element.mode }));

			if (newMessages.length > 1) {
				messages.splice(i, 1);
				messages = [...messages.slice(0, i), ...newMessages, ...messages.slice(i)];
			}
		}

		const hasBoth = messages.some((m) => m.mode === "dialogue") && messages.some((m) => m.mode === "description");

		if (!hasBoth) {
			// convert all messages to the current mode if there is no only one type
			messages = messages.map((m) => ({ ...m, mode: this.state.lineType }));
		}

		let chain = Promise.resolve();

		for (let i = 0; i < messages.length; ++i) {
			let messageInfo = messages[i];
			chain = chain.then(() => sendMessage(messageInfo.mode, true, messageInfo.content));
		}

		return chain;
	};

	render() {
		const options = [
			{ title: _("Never", "never split text"), transparent: true, onPress: this.onNever },
			{ title: _("Not now", "don't split the text this time"), transparent: true, onPress: this.simpleSend },
			{ title: _("Split", "split a message into several smaller ones"), onPress: this.onSplit },
		];
		return (
			<BoxModal
				ref={this.modalRef}
				title={_("Split text", "split a message into several smaller ones")}
				options={options}
			>
				<AppText>
					{_('Your text has quotes (" ") or blank lines. Do you want to split it automatically?')}
				</AppText>
				<AppText style={{ marginTop: 8 }}>
					{_("Text between quotes becomes dialogue and blank lines start a new message.")}
				</AppText>
			</BoxModal>
		);
	}
}

export default SplitMessageModal;
