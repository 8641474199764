import * as React from "react";
import { View, StyleSheet } from "react-native";
import AppButton from "../generic/buttons/AppButton";
import { _ } from "../../i18n/i18n";
import AppText from "../generic/AppText";
import HelpButton from "../tutorials/HelpButton";
import { connect } from "react-redux";
import { apiFetch } from "../../network/apiFetch";

function OAIUsePregenerated({
	disabled,
	requestType,
	searchQuery,
	onPregeneratedSet,
	setwaitingForResponse,
	containerStyle,
	buttonStyle,
	dispatch,
	user,
}) {
	const [index, setindex] = React.useState(-1);
	const [searching, setsearching] = React.useState(false);
	const [similarRequests, setsimilarRequests] = React.useState([]);

	React.useEffect(() => {
		setsimilarRequests([]);
		setindex(-1);
	}, [searchQuery]);

	const findSimilarRequests = React.useCallback(async () => {
		setsearching(true);

		const requests = await apiFetch(`users/${user.id}/openairequest/similar-requests`, "GET", {
			request_type: requestType,
			search: searchQuery,
		});

		setsimilarRequests(requests);

		if (!requests.length) {
			onPregeneratedSet({ no_result: true });
		} else {
			setindex(0);
		}

		setsearching(false);
	}, [user.id, index, searchQuery, requestType, onPregeneratedSet]);

	const showNextPregenerated = React.useCallback(() => {
		setindex(similarRequests.rg_nextIndex(index, true));
	}, [similarRequests, index]);

	React.useEffect(() => {
		setwaitingForResponse(!!searching);
	}, [searching, setwaitingForResponse]);

	React.useEffect(() => {
		if (index >= 0 && index < similarRequests.length) {
			onPregeneratedSet(similarRequests[index]);
		}
		setsearching(false);
	}, [index, onPregeneratedSet]);

	const helpMessage = (
		<>
			<AppText>
				{_("When players use their tokens to generate AI content, it is saved for others to access.")}
			</AppText>
			<AppText style={{ marginTop: 8 }}>
				{_("This option will try to find pregenerated content that matches the information you have entered.")}
			</AppText>
			<AppText style={{ marginTop: 8 }}>
				{_("You can browse those without having to spend your own tokens.")}
			</AppText>
		</>
	);

	return (
		<View style={[styles.container, containerStyle]}>
			<AppButton
				title={
					similarRequests.length
						? _("Next pregenerated option", "use pregenerated AI content")
						: _("Browse pregenerated", "use pregenerated AI content")
				}
				disabled={disabled || searching}
				onPress={similarRequests.length ? showNextPregenerated : findSimilarRequests}				
				style={buttonStyle}
			/>
			<HelpButton title={_("Pregenerated AI content")} message={helpMessage} style={{ marginLeft: 8 }} />
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(OAIUsePregenerated);

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignItems: "center",
	},
});
