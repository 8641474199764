
export const hasCoin = (transactionSubject) => {
    if (transactionSubject.wood || transactionSubject.copper || transactionSubject.silver
        || transactionSubject.gold || transactionSubject.platinum || transactionSubject.legendary) {
        return true;
    }

    return false;
};


export const allowGiveCoinTo = (userId, subject)=>{
    if (subject.game_master) {
        return subject.game_master.id !== userId;
    }
    if (subject.creator) {
        return subject.creator.id !== userId;
    }
    return true;
}