import * as React from "react";
import { ActivityIndicator, Linking, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { updateUserToken } from "../../store/slices/authtokenSlice";
import { fetchCurrentUser } from "../../store/slices/usersSlice";
import { colors } from "../../styles/colors";
import { fullScreenContentWidth } from "../../styles/dynamicStyles";
import { ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ButtonsRow from "../generic/buttons/ButtonsRow";
import InputField from "../generic/InputField";
import SimpleCheckbox from "../generic/SimpleCheckbox";
import CondView from "../meta/CondView";
import { errorCodeToString, isEmailError, isPassword2Error, isPasswordError, isUsernameError } from "./LoginScreen";
import AsyncStorage from '@react-native-async-storage/async-storage';

const styles = StyleSheet.create({
	screen: {
		flex: 1,
		justifyContent: "space-around",
	},
});

function CreateAccountScreen() {
	const dispatch = useDispatch();

	const [creating, setCreating] = React.useState(false);
	const [username, setUsername] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [passwordRepeat, setPasswordRepeat] = React.useState("");
	const [error, setError] = React.useState(_(null));
	const [errorCode, setErrorCode] = React.useState(_(null));

	const [privacyAccepted, setprivacyAccepted] = React.useState(false);
	const [above13, setabove13] = React.useState(false);

	React.useEffect(() => {
		setCreating(false);
		setError(null);
		setErrorCode(null);
	}, []);

	const onCreate = React.useCallback(async () => {
		setCreating(true);
		const referral = await AsyncStorage.getItem("referral");
		const { error_mail, error_password, error_username } = await createAccount(
			username,
			email,
			password,
			passwordRepeat,
			referral
		);

		const errorCode = error_username || error_mail || error_password;

		if (errorCode) {
			setErrorCode(errorCode);
			setError(errorCodeToString(errorCode));
			setCreating(false);
			return;
		}

		await dispatch(updateUserToken(username, password, true));
		await dispatch(fetchCurrentUser());
	}, [username, email, password, passwordRepeat]);

	return (
		<AppScreenView style={[styles.screen, ws({ alignItems: "center" })]}>
			<View style={ws({ maxWidth: fullScreenContentWidth(), width: "100%" })}>
				<InputField
					label={_("Username")}
					value={username}
					onChangeText={setUsername}
					editable={!creating}
					textContentType="username"
					autoCompleteType="username"
					autoCapitalize="none"
					error={isUsernameError(errorCode)}
				/>
				<InputField
					label={_("Email address")}
					value={email}
					editable={!creating}
					onChangeText={setEmail}
					autoCompleteType="email"
					keyboardType="email-address"
					textContentType="emailAddress"
					autoCapitalize="none"
					error={isEmailError(errorCode)}
				/>
				<InputField
					label={_("Password")}
					value={password}
					onChangeText={setPassword}
					editable={!creating}
					textContentType="password"
					autoCompleteType="password"
					secureTextEntry
					autoCapitalize="none"
					error={isPasswordError(errorCode)}
				/>
				<InputField
					label={_("Password (repeat)")}
					value={passwordRepeat}
					onChangeText={setPasswordRepeat}
					editable={!creating}
					textContentType="password"
					autoCompleteType="password"
					secureTextEntry
					autoCapitalize="none"
					error={isPassword2Error(errorCode)}
				/>
				<CondView show={error} style={{ marginTop: 16, alignItems: "center" }}>
					<AppText color="danger">{error}</AppText>
				</CondView>

				<View style={{marginTop: 24}}>
					<SimpleCheckbox
						label={_(
							"I have read and agreed to the %(terms)s and %(privacy_policy)s",
							"create account check",
							{
								terms: (
									<AppText
										key="tos"
										navigateTo="TermsScreen"
										color="url"
										accessibilityRole="link"
										href="https://www.rolegate.com/tos/"
                                        target="_blank"
                                        avoidTouchable
									>
										terms of service
									</AppText>
								),
								privacy_policy: (
									<AppText
										key="privacy"
										navigateTo="PrivacyPolicyScreen"
										color="url"
										accessibilityRole="link"
										href="https://www.rolegate.com/privacy/"
                                        target="_blank"
                                        avoidTouchable
									>
										privacy policy
									</AppText>
								),
							}
						)}
						checked={privacyAccepted}
						setValue={setprivacyAccepted}
					/>
					<SimpleCheckbox style={{marginTop: 8}} label={_("I am above 13 years old")} checked={above13} setValue={setabove13} />
				</View>

				<CondView show={creating} style={{ marginTop: 32 }}>
					<ActivityIndicator color={colors.hint} />
				</CondView>
			</View>

			<ButtonsRow style={ws({ maxWidth: fullScreenContentWidth(), width: "100%" })}>
				<AppButton
					title={_("Login").toUpperCase()}
					transparent
					centerButton={false}
					disabled={creating}
					navigateTo="Login"
				/>
				<AppButton
					title={_("Create").toUpperCase()}
					centerButton={false}
					disabled={
						creating || !username || !password || !passwordRepeat || !email || !above13 || !privacyAccepted
					}
					onPress={onCreate}
				/>
			</ButtonsRow>
		</AppScreenView>
	);
}

export default CreateAccountScreen;

async function createAccount(username, email, password, passwordRepeat, referral) {
	return await apiFetch(
		"account/signup/",
		"POST",
		{ username, password, password2: passwordRepeat, email, referral, noLogin: true, referral },
		true
	);
}
