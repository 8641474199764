import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { idKeyExtractor } from "../../tools/generic";
import UnlockedByShareInfo from "../account/UnlockedByShareInfo";
import AppPicker from "../generic/AppPicker";
import AppButton from "../generic/buttons/AppButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import ModalScreen from "../generic/modal/ModalScreen";
import RGIcon from "../RGIcon";
import MacroItem from "./MacroItem";

function SelectMacroModal({
	route,
	macros,
	macroGroups,
	user,
	gameSheetTemplate,
	gameId,
	macrosHistory,
	navigation,
	game,
}) {
	const [sortMode, setSortMode] = React.useState("RECENT");

	const isRequest = route.params?.isRequest;

	let availableMacros = Object.values(macros).filter((m) => {
		const group = macroGroups[m.group];
		if (macroGroups.bySheet[gameSheetTemplate.id].includes(group.id)) {
			return true;
		}
		if (group.user === user.id && group.games.includes(gameId)) {
			return true;
		}
		return false;
	});

	availableMacros.sort((a, b) => a.name.localeCompare(b.name));

	switch (sortMode) {
		case "RECENT":
			if (macrosHistory)
				availableMacros.sort((a, b) => {
					const historyA = macrosHistory[a.id];
					const historyB = macrosHistory[b.id];
					if (historyA && !historyB) return -1;
					if (!historyA && historyB) return 1;
					if (!historyA && !historyB) return 0;
					return historyB.localeCompare(historyA);
				});
			break;
		default:
			break;
	}

	return (
		<ModalScreen title={_("Select macro")} borderless wide>
			<UnlockedByShareInfo style={{marginHorizontal: 8}}/>

			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					marginHorizontal: 8,
				}}
			>
				<AppPicker
					selectedValue={sortMode}
					onValueChange={(itemValue, itemIndex) => setSortMode(itemValue)}
					mode="dropdown"
					pickerStyle={{ flex: 1, color: "white" }}
					itemStyle={{ color: "white" }}
					items={[
						{ label: _("Name", "sort by"), value: "NAME" },
						{ label: _("Last used", "sort by"), value: "RECENT" },
					]}
				/>

				<AppButton
					title={_("Manage macros")}
					size="small"
					onPress={() => navigation.navigate("ManageMacrosScreen", { gameId: game.id })}
				/>
			</View>

			<FlatList
				data={availableMacros}
				keyExtractor={idKeyExtractor}
				renderItem={({ item }) => (
					<MacroItem
						macroGroup={macroGroups[item.group]}
						macro={item}
						onSelected={(macro) => navigation.navigate("RollMacroModal", { macro, isRequest })}
						rolling
					/>
				)}
				ListEmptyComponent={() => (
					<EmptyScreenView
						messageBottom={_("You are not using any macro in this game")}
						icon={{ type: RGIcon, name: "die" }}
					/>
				)}
			/>
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const game = state.games[state.games.currentId];
	return {
		user: state.user,
		macros: state.macros,
		macroGroups: state.macroGroups,
		gameSheetTemplate: state.sheets[game.sheet_template],
		gameId: game.id,
		game,
		macrosHistory: state.gamesUI[game.id]?.macrosHistory,
	};
};

export default connect(mapStateToProps)(SelectMacroModal);
