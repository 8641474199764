import * as React from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import InputField from "../InputField";


function InputFieldButton(props) {    
	return (
		<TouchableWithoutFeedback onPress={props.onPress}>
			<ViewPassingProps inputProps={props} />
		</TouchableWithoutFeedback>
	);
}

function ViewPassingProps(props) {
    // TouchableWithoutOpacity needs to pass down its props to its child component
    // https://reactnative.dev/docs/touchablewithoutfeedback#usage-example
	return (
		// pointerEvents="box-only" to work around TouchableWithout feedback not working over text input
		// https://github.com/facebook/react-native/issues/14958#issuecomment-355230733
		<View {...props} pointerEvents="box-only" >
			<InputField {...props.inputProps} editable={false}/>
		</View>
	);
}

export default InputFieldButton;
