import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import moment from "moment";
import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _, _n } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { udpateAlertedPlayersCountForGame } from "../../store/slices/gamealertsSlice";
import { isAndroid, ws } from "../../tools/generic";
import { ToLargeNumberString } from "../../tools/math";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import BoxModal from "../generic/modal/BoxModal";
import TinyFeedbackModal from "../generic/modal/TinyFeedbackModal";

function SendAlertButton({ game, useInverted, style, disabled, dispatch, alertedPlayersCount, user }) {
	const modalRef = React.useRef();
	const alertSentRef = React.useRef();

	const [sending, setsending] = React.useState(false);

	const navigation = useNavigation();

	React.useEffect(() => {
		dispatch(udpateAlertedPlayersCountForGame(game.id));
	}, [alertedPlayersCount, game]);

	const sendAlert = React.useCallback(async () => {
		setsending(true);
		await apiFetch(`games/${game.id}/send-game-alert`, "POST");
		modalRef.current.hide();
		alertSentRef.current.show();
		setsending(false);
	}, []);

	const isAvalaible = React.useMemo(() => {
		if (game.players.length >= game.max_players) return false;
		let date = moment();

		if (date.diff(game.last_alert_sent, "days") < 7) {
			return false;
		} else if (date.diff(user.profile.last_alert_sent, "days") < 7) {
			return false;
		}
		return true;
	}, [game, user]);

	const disabledReason = React.useMemo(() => {
		if (game.players.length >= game.max_players) return _("Game is full");
		let date = moment();

		if (date.diff(game.last_alert_sent, "days") < 7 || date.diff(user.profile.last_alert_sent, "days") < 7) {
			return _("Limited to one alert per week");
		}
		return null;
	}, [game, user]);

	const NoPlayersContent = (
		<>
			<AppText>
				{_(
					"Some players are actively looking for a game. You can send an alert to those interested in the tags your game is using."
				)}
			</AppText>
			<AppText color="hint" centered style={{ marginTop: 16 }}>
				{_(
					"Unfortunately, there are no players currently looking for the tags that your game is using. Please try again later."
				)}
			</AppText>
		</>
	);

	const PlayersContent = (
		<>
			<AppText>
				{_(
					"Some players asked to be notified when a game with the tags your game is using is looking for players. You can send them a notification, letting them know that your game is open."
				)}
			</AppText>
			<AppText color="attention" style={{ marginTop: 8 }}>
				{_("To avoid spamming, your profile can only send one looking-for-players alert per week.")}
			</AppText>
			<AppText size="large" style={{ marginTop: 8 }} centered>
				{_n(
					"%(count)s interested player will be notified.",
					"%(count)s interested players will be notified.",
					"indicates how many players will be notified by a looking-for-players alert",
					alertedPlayersCount,
					{ count: ToLargeNumberString(alertedPlayersCount) }
				)}
			</AppText>
		</>
	);

	const options = [];

	if (!alertedPlayersCount) {
		options.push({
			title: _("Edit tags in settings"),
			onPress: () => {
				modalRef.current?.hide();
				navigation.navigate("GameSettingScreen");
			},
			size: "large",
		});
	} else {
		options.push({
			title: sending
				? _("Sending...")
				: ws(_("Send looking-for-players alert"), _("Send", "send looking-for-players alert")),
			onPress: sendAlert,
			disabled: sending,
			size: ws("large", "average"),
		});
	}

	return (
		<>
			<BoxModal
				title={_("Send looking-for-players alert")}
				ref={modalRef}
				message={<>{(!alertedPlayersCount && NoPlayersContent) || PlayersContent}</>}
				addCancel
				keepOpen
				options={options}
			/>
			<AppButton
				disabled={disabled || !isAvalaible}
				style={[useInverted && isAndroid() && { scaleY: -1 }, style]}
				transparentHighlight
				size="large"
				title={_("Send looking-for-players alert")}
				info={!isAvalaible && disabledReason ? disabledReason : _("Click for more details")}
				icon={{ type: MaterialCommunityIcons, name: "bell-alert" }}
				onPress={() => modalRef.current?.show()}
			/>
			<TinyFeedbackModal
				ref={alertSentRef}
				message={_("Alert sent", "confirmation that an new game alert has been sent")}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		alertedPlayersCount: state.gameAlerts.countPerGame[ownProps.game.id],
	};
};

export default connect(mapStateToProps)(SendAlertButton);

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
	},
});
