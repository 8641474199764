export const getCurrentWordBoundaries = (cursorStart, inputValue, wordBoundaryRegex) => {
	wordBoundaryRegex = wordBoundaryRegex || new RegExp(/\s/, "gm");

	let wordStartMatch = inputValue.substr(0, cursorStart).rg_lastMatch(wordBoundaryRegex);
	let wordStart = wordStartMatch ? wordStartMatch.index + wordStartMatch[0].length : 0;
	let wordEnd = inputValue.rg_regexIndexOf(wordBoundaryRegex, cursorStart);
	if (wordEnd < 0) {
		wordEnd = inputValue.length;
	}
	return { start: wordStart, end: wordEnd };
};

export const getCurrentWord = (cursorStart, inputValue, wordBoundaryRegex) => {
	const { start, end } = getCurrentWordBoundaries(cursorStart, inputValue, wordBoundaryRegex);
	return inputValue.substr(start, end - start);
};
