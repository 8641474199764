import * as React from "react";
import { View, StyleSheet } from "react-native";
import ModalScreen from "../generic/modal/ModalScreen";
import { _ } from "../../i18n/i18n";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { addNode } from "../../tools/sheets";
import { useDispatch } from "react-redux";
import { editNode } from "../../store/slices/sheetNodesSlice";

function AddSheetNodeModal({ route, navigation }) {
	const { parent, nodeToReplace } = route.params;

	const dispatch = useDispatch();

	const createNode = React.useCallback(
		(type, direction, isList) => {
			if (nodeToReplace) {
				dispatch(editNode({ ...nodeToReplace, type, direction, user_list: isList }));
			} else {
				addNode(parent, type, direction || "vertical", isList, dispatch);
			}
			navigation.navigate({name: "CharacterSheetTemplateScreen", merge: true});
		},
		[parent, navigation]
	);

	const options = [];

	options.push({
		title: _("Text input", "character sheet item type"),
		info: _("A field where players can write anything"),
		icon: { type: MaterialIcons, name: "text-fields" },
		onPress: () => createNode("text_area"),
	});

	options.push({
		title: _("Number input", "character sheet item type"),
		info: _("Numbers with labels can be used when rolling dice"),
		icon: { type: MaterialCommunityIcons, name: "numeric" },
		onPress: () => createNode("number_input"),
	});

	

	options.push({
		title: _("Image", "character sheet item type"),
		info: _("A field where players can upload a picture. The player will need a silver account."),
		icon: { type: MaterialCommunityIcons, name: "image" },
		onPress: () => createNode("picture"),
	});


	options.push({
		title: _("Horizontal section", "character sheet item type"),
		info: _("Add items next to each other"),
		icon: { type: MaterialCommunityIcons, name: "border-vertical" },
		onPress: () => createNode("section", "horizontal"),
	});

	options.push({
		title: _("Vertical section", "character sheet item type"),
		info: _("Useful to create parallel lists in a horizontal section"),
		icon: { type: MaterialCommunityIcons, name: "border-horizontal" },
		onPress: () => createNode("section", "vertical"),
	});

	options.push({
		title: _("Dynamic list", "character sheet item type"),
		info: _(
			"Vertical section with a button for players to duplicate the first item",
			"create a list in the character sheet template"
		),
		icon: { type: MaterialIcons, name: "list" },
		onPress: () => createNode("section", "vertical", true),
	});

	return (
		<ModalScreen
			options={options}
			title={
				nodeToReplace
					? _("Replace with", "replace character sheet item with one of the options")
					: _("Add item", "add item to character sheet")
			}
		></ModalScreen>
	);
}

export default AddSheetNodeModal;
