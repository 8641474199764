import * as React from "react";
import { View, StyleSheet } from "react-native";
import ButtonsRow from "./ButtonsRow";
import AppButton from "./AppButton";
import SimpleCheckbox from "../SimpleCheckbox";
import { gameScreenPadding } from "../../../styles/dynamicStyles";
import { cStyle } from "../../../tools/react";
import { isWeb, isWide } from "../../../tools/generic";
import { useLayout } from "react-native-web-hooks";

function ButtonFooter({ buttonProps, noBack, narrow, style }) {
	if (isWeb() && (narrow === undefined || !isWide())) {
		narrow = true;
	}

	const { onLayout } = useLayout();

	return (
		<View onLayout={onLayout} style={[styles.container, narrow && styles.containerNarrow, style]}>
			{buttonProps
				.filter((p) => !!p)
				.map(
					(props, index) =>
						props.component || (
							(props.type === "checkbox") ?
								// Allows passing in checkboxes into the button footer. Currently used in SimpleRollModal and AdvancedRollModal.
								<SimpleCheckbox
									key={index}
									label={props.title}
									setValue={props.onChange}
									checked={props.checked.toGMOnly}
									style={{ marginLeft: 16 }}
								/> :
								<AppButton
									key={index}
									{...props}
									style={{ marginLeft: 16 }}
									footerBack={(index === 0 && !noBack) || props.type === "shifted_back_button"} // Allows the back button to be formatted correctly even when not the first item in the list
									footerForward={index !== 0 || noBack}
									size={narrow ? "average" : "large"}
								/>
						)
				)}
		</View>
	);
}

export default ButtonFooter;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "flex-end",
		marginBottom: 32,
	},
	containerNarrow: {
		paddingHorizontal: 0,
	},
});
