import { Entypo, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/pt-br";
import "moment/locale/it";
// import "moment/locale/hr";
// import "moment/locale/nl";
import * as React from "react";
import { Linking } from "react-native";
import { useDispatch } from "react-redux";
import { connectToWebSocket } from "../network/websocket";
import { receiveAuthToken } from "../store/slices/authtokenSlice";
import { fetchPending } from "../store/slices/friendsSlice";
import { fetchUserGames } from "../store/slices/gamesSlice";
import { receiveSavedStore } from "../store/slices/savedStore";
import { fetchCurrentUser } from "../store/slices/usersSlice";
import { updateTheme } from "../tools/theme";
import RoleGateLoadingScreen from "./loading/RoleGateLoadingScreen";

const supportedLocales = ["fr", "en", "pt-br", "it"];

function AppLoader({ completeLoading }) {
	const dispatch = useDispatch();

	// Load any resources or data that we need prior to rendering the app
	React.useEffect(() => {
		connectToWebSocket();

		async function loadResourcesAndDataAsync() {
			const initialUrl = await Linking.getInitialURL();

			// Lots of issues with preventing Splashscreen hiding. Notably on Ios.
			// Putting it in its own try, to not prevent the loading of the rest of the assets.
			// try {
			// 	await SplashScreen.preventAutoHideAsync();
			// } catch (e) {
			// 	console.warn(e);
			// }

			try {
				await Asset.loadAsync([
					require("../../assets/images/splash_screen_background.png"),
					require("../../assets/images/Logo.png"),
				]);

				// await SplashScreen.hideAsync();

				// Load fonts
				await Font.loadAsync({
					...Ionicons.font,
					...MaterialCommunityIcons.font,
					...Entypo.font,
					...FontAwesome.font,
					...FontAwesome5.font,
					"rg-icons": require("../../assets/fonts/RGIcons.ttf"),
					"rg-token-font": require("../../assets/fonts/RGTokens.ttf"),
					rellanic: require("../../assets/fonts/PS_Rellanic/Rellanic.otf"),
					"rellanic-bold": require("../../assets/fonts/PS_Rellanic/Rellanic_bold.otf"),
					"rellanic-italic": require("../../assets/fonts/PS_Rellanic/Rellanic_italic.otf"),
					"rellanic-bold-italic": require("../../assets/fonts/PS_Rellanic/Rellanic_bold_italic.otf"),
				});

				let theme = await AsyncStorage.getItem("theme");
				if (theme) {
					updateTheme(theme, dispatch);
				}

				let authToken = await AsyncStorage.getItem("authToken");

				if (authToken) {
					global.authToken = authToken;
					await dispatch(receiveAuthToken(authToken));
					const user = await dispatch(fetchCurrentUser());
					await dispatch(fetchUserGames());
					dispatch(fetchPending(user.id));
				}

				let localeCode = await AsyncStorage.getItem("localeCode");
				if (!supportedLocales.includes(localeCode)) localeCode = "en";
				global.LOCALE_CODE = localeCode;
				moment.locale(localeCode || "en");

				let allowNotifications = await AsyncStorage.getItem("allowNotifications");
				allowNotifications = JSON.parse(allowNotifications);

				if (allowNotifications !== null && allowNotifications !== undefined) {
					global.ALLOW_NOTIFICATIONS = allowNotifications;
				} else {
					global.ALLOW_NOTIFICATIONS = true;
					AsyncStorage.setItem("allowNotifications", "true");
				}

				const savedStoreState = await AsyncStorage.getItem("storeState");
				if (savedStoreState) {
					const savedStore = JSON.parse(savedStoreState);
					if (savedStore.version === global.savedStoreVersion) {
						dispatch(receiveSavedStore(savedStore));
					}
				}

				// This might cause an error, as expo is having issues with it on iOs
				// https://github.com/expo/expo/issues/9286
				// The catch should allow the app to keep working
				await SplashScreen.hideAsync();
			} catch (e) {
				// We might want to provide this error information to an error reporting service
				console.log(e);
			} finally {
				completeLoading(initialUrl);
			}
		}

		loadResourcesAndDataAsync();
	}, []);

	return <RoleGateLoadingScreen />;
}

export default AppLoader;
