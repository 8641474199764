import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { getLineUnderstanding } from "../../tools/languages";
import AppText from "../generic/AppText";
import GibberishText from "../languages/GibberishText";
import LineAvatar from "./LineAvatar";
import LineInfo from "./LineInfo";

function IsolatedLine({ line, isPreview, dispatch, currentCharacter }) {
	let lineText = line.content;
	if (!line.is_description && !line.is_storytelling && line.language && currentCharacter) {
		let understanding = getLineUnderstanding(currentCharacter, line.language);

		lineText = <GibberishText colorKey="textDefault" text={line.content} understandingLevel={understanding} />;
	}
	return (
		<View style={styles.containerStyle}>
			<LineAvatar line={line} containerStyle={{ marginRight: 8 }} isPreview={isPreview} />
			<View style={{ flex: 1 }}>
				<LineInfo line={line} isolated />
				<AppText numberOfLines={3} ellipsizeMode="tail" selectable>
					{lineText}
				</AppText>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		currentCharacter: getCurrentCharacter(state),
	};
};

export default connect(mapStateToProps)(IsolatedLine);

const styles = StyleSheet.create({
	containerStyle: {
		flexDirection: "row",
	},
	contentStyle: {},
});
