import * as React from "react";
import { View, StyleSheet, Switch } from "react-native";
import { colors } from "../../styles/colors";
import AppText from "./AppText";
import { cStyle } from "../../tools/react";

function ListSwitch({ title, isEnabled, setIsEnabled, disabled, style, titleProps }) {
	const toggleSwitch = React.useCallback(() => {
		setIsEnabled(!isEnabled);
	}, [isEnabled, setIsEnabled]);

	return (
		<View style={cStyle([styles.container, [!!disabled, styles.disabledSwitch], style])}>
			<AppText style={{flex: 1}} {...titleProps}>{title}</AppText>
			<Switch
				style={cStyle([[!!disabled, styles.disabledSwitch]])}
				disabled={disabled}
				trackColor={{ false: global.colors.secondary, true: global.colors.attention }}
				thumbColor={isEnabled ? "white" : "white"}
				// ios_backgroundColor="#3e3e3e"
				onValueChange={toggleSwitch}
				value={isEnabled}
			/>
		</View>
	);
}

export default ListSwitch;

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",		
		paddingVertical: 4
	},
	disabledSwitch: {
		opacity: 0.3,
	},
});
