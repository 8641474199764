export function gameScreenPadding() {
	const rightPanelWidth = global.foldGameMenu ? 50 : 230;
	if (global.windowWidth - rightPanelWidth > 2200) {
		return 700;
	}
	if (global.windowWidth - rightPanelWidth > 2000) {
		return 600;
	}
	if (global.windowWidth - rightPanelWidth > 1800) {
		return 500;
	}
	if (global.windowWidth - rightPanelWidth > 1600) {
		return 400;
	}
	if (global.windowWidth - rightPanelWidth > 1500) {
		return 300;
	}
	if (global.windowWidth - rightPanelWidth > 1400) {
		return 250;
	}
	if (global.windowWidth - rightPanelWidth > 1300) {
		return 200;
	}
	
	if (global.windowWidth - rightPanelWidth > 1100) {
		return 100;
	}
	return 8;
}

export function lineTalkPadding() {
	return 100;
}

export function modalWidthNarrow() {
	return 350;
}

export function modalWidthWide() {
	return 600;
}

export function fullScreenLeftPadding() {
	if (global.navigatorWidth > 1300) {
		return 70;
	}
	if (global.navigatorWidth > 1200) {
		return 30;
	}
	if (global.navigatorWidth > 1000) {
		return 8;
	}
	return 4;
}

export function fullScreenContentWidth() {
	return 900;
}

export function drawerWidth() {
	return 250;
}

export function previewSampleSize() {
	return 900;
}

export function sheetPadding() {	
	if (global.navigatorWidth > 2100) {
		return 400;
	}
	if (global.navigatorWidth > 2000) {
		return 350;
	}
	if (global.navigatorWidth > 1900) {
		return 300;
	}
	if (global.navigatorWidth > 1600) {
		return 200;
	}
	if (global.navigatorWidth > 1200) {
		return 100;
	}
	return 0;
}
