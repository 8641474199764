import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { connect } from "react-redux";
import InteractiveHelperItem from "./InteractiveHelperItem";
import { idKeyExtractor, ws } from "../../tools/generic";
import AppText from "../generic/AppText";
import { _ } from "../../i18n/i18n";
import AppScreenView from "../AppScreenView";
import AppButton from "../generic/buttons/AppButton";
import EmptyScreenView from "../generic/EmptyScreenView";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { isInGame } from "../../tools/games";
import HelpButton from "../tutorials/HelpButton";
import Cond from "../meta/Cond";
import ErrorLoading from "../errors/ErrorLoading";

function ActiveHelperItem({ item, separators, navigationState, navigation }) {
	React.useEffect(() => {
		separators.updateProps("leading", { trailingItem: item });
	});

	return (
		<InteractiveHelperItem
			interactiveHelper={item.helper}
			navigation={navigation}
			navigationState={navigationState}
			separators={separators}
			narrow
		/>
	);
}

function ActiveHelpersList({ navigationState, navigation, dispatch, user, users, currentGame, activeHelpers }) {
	if (!activeHelpers) return <ErrorLoading />;

	const renderHelper = React.useCallback(
		(props) => <ActiveHelperItem {...props} navigationState={navigationState} navigation={navigation} />,
		[navigationState, navigation]
	);

	const data = activeHelpers.slice().sort((a, b) => {
		if (!a.public && b.public) return -1;
		if (a.public && !b.public) return 1;
		if (a.user && b.user && a.user !== b.user) {
			return String(a.user).localeCompare(String(b.user));
		}
		return a.helper.name.localeCompare(b.helper.name);
	});

	const hasPublic = activeHelpers.some((ah) => ah.public);
	const hasBothTypes = hasPublic && activeHelpers.some((ah) => !ah.public);

	const separatorProps = {
		color: "hint",
		style: { marginVertical: 4 },
		size: "small",
	};

	const topComponent = (
		<View>
			<AppText bold size="small">
				{currentGame?.name || _("Home screen")}
			</AppText>
			<AppText bold>{_("Interactive helpers")}</AppText>
			<AppText hide={!data.length} color="hint" size="small" style={{ marginBottom: 8 }}>
				{currentGame
					? _("Interactive helpers attached to this game.")
					: _("Interactive helpers that you have attached to the home screen.")}
			</AppText>
			<Cond show={!!currentGame}>
				<AppText hide={!data.length || !hasBothTypes} {...separatorProps}>
					{_("Private", "those interactive helpers are private")}
				</AppText>
				<AppText hide={!data.length || hasBothTypes || !hasPublic} {...separatorProps}>
					{_("Those interactive helpers are shared")}
				</AppText>
				<AppText hide={!data.length || hasBothTypes || hasPublic} {...separatorProps}>
					{_("Those interactive helpers are only visible to you")}
				</AppText>
			</Cond>
		</View>
	);

	const addButtonProps = {
		title: _("Add", "add interactive helpers"),
		onPress: () => navigation.navigate("InteractiveHelpersStack"),
	};

	const canAdd = !currentGame || isInGame();

	const bottomComponent = canAdd && (
		<AppButton style={{ marginVertical: 16 }} centered transparent {...addButtonProps} />
	);
	return (
		<AppScreenView useSafeArea>
			<FlatList
				contentContainerStyle={styles.container}
				ListHeaderComponent={topComponent}
				keyExtractor={idKeyExtractor}
				data={data}
				ListEmptyComponent={
					<EmptyScreenView
						style={{ marginTop: 16, paddingBottom: 0 }}
						messageTop={
							currentGame
								? _("There are no interactive helpers attached to this game.")
								: _("You have not added any interactive helpers to the home screen.")
						}
						helpButtonProps={{
							title: _("Interactive helpers"),
							message: (
								<>
									<AppText>
										{_("Interactive helpers are mini wikis created by the community.")}
									</AppText>
									<AppText style={{ marginTop: 8 }}>
										{_(
											"You can open them at anytime by sliding open the panel on the right side of your screen."
										)}
									</AppText>
									<AppText style={{ marginTop: 8 }}>
										{_(
											"There are helpers to help you run your game, use the platform and about everything else!"
										)}
									</AppText>
								</>
							),
						}}
						options={canAdd ? [addButtonProps] : null}
					/>
				}
				renderItem={renderHelper}
				ListFooterComponent={data?.length ? bottomComponent : null}
				ItemSeparatorComponent={({ leadingItem, trailingItem }) => {
					if (!trailingItem) return null;
					if (trailingItem.public !== leadingItem.public) {
						return (
							<AppText {...separatorProps}>
								{trailingItem.user === user.id
									? _("Shared by you", "interactive helper is accessible in game thanks to user")
									: _(
											"Shared by %(username)s",
											"interactive helper is accessible in game thanks to user",
											{ username: users[trailingItem.user]?.username }
									  )}
							</AppText>
						);
					}
					return null;
				}}
			/>
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const currentGame = state.games[state.games.currentId];
	return {
		currentGame,
		activeHelpers: currentGame
			? state.interactiveHelpers.byGames[currentGame?.id] || Array.rg_empty
			: state.interactiveHelpers.homeScreen,
		user: state.user,
		users: state.users,
	};
};

export default connect(mapStateToProps)(ActiveHelpersList);

const styles = StyleSheet.create({
	container: { flex: 1, paddingBottom: ws(80) },
});
