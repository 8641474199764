import { createSlice } from "@reduxjs/toolkit";
import { apiFetch } from "../../network/apiFetch";

export const characterStatesSlice = createSlice({
	name: "characterStates",
	initialState: [],
	reducers: {
		receiveCharacterStates: (state, { payload: characterStates }) => state.rg_overlapById(characterStates),
	},
});

export const { receiveCharacterStates } = characterStatesSlice.actions;

export function fetchCharacterStates(gameId) {
	return async (dispatch, getState) => {
		return apiFetch(`games/${gameId}/states`).then((response) =>
			dispatch(receiveCharacterStates(response.results))
		).catch(()=>null);
	};
}

export default characterStatesSlice.reducer;
