import { DefaultTheme } from "@react-navigation/native";
import { colors } from "./colors";

export const RGNavigationThemeDark = {
	...DefaultTheme,
	dark: true,
	colors: {
		...DefaultTheme.colors,
		primary: colors.darkTheme.cardBackground,
		background: colors.darkTheme.cardBackground,
        card: colors.darkTheme.cardBackgroundLight,
		text: colors.darkTheme.textDefault,
		border: colors.darkTheme.nearTransparentBorder,
	},
	
};

export const RGNavigationThemeDarkest = {
	...DefaultTheme,
	dark: true,
	colors: {
		...DefaultTheme.colors,
		primary: colors.darkestTheme.cardBackground,
		background: colors.darkestTheme.cardBackground,
        card: colors.darkestTheme.cardBackgroundLight,
		text: colors.darkestTheme.textDefault,
		border: colors.darkestTheme.nearTransparentBorder,
	},
	
};
