import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { setEditingLine, setEditMessage, setTalkToCharacterIds, setWhisperingTo, setWhisperToPlayerIds } from "../../store/slices/gamesUISlice";
import { saveLineEdit } from "../../store/slices/linesSlice";
import { getCharName } from "../../tools/characters";
import { findTaggedIds, isStory } from "../../tools/lines";
import AtAutocomplete from "../chat/AtAutocomplete";
import EditMessageField from "../chat/EditMessageField";
import TargetsBox from "../chat/TargetsBox";

function EditingLine({
	line,
	dispatch,
	message,
	whisperToCharacterIds,
	whisperToPlayerIds,
	talkToCharacterIds,
	talkToPlayerIds,
	users,
	characters,
	talkToGameId,
}) {
	const [selection, setselection] = React.useState({ start: 0, end: 0 });
	const [focused, setfocused] = React.useState(false);
	const textInputRef = React.useRef();

	const autocompleteRef = React.useRef();

	const isComments = line.is_comment;

	const saveEdit = React.useCallback(() => {
		const gameId = line.game;

		let tagged_characters = [];
		let tagged_users = [];

		let content = message;
		for (let i = 0; i < talkToCharacterIds?.length; i++) {
			const characterId = talkToCharacterIds[i];
			content = `@${getCharName(characters[characterId])}#${characterId} ` + content;
		}
		for (let i = 0; i < talkToPlayerIds?.length; i++) {
			const targetId = talkToPlayerIds[i];
			content = `@${users[targetId].username}#${targetId} ` + content;
		}

		if (isStory(line)) {
			tagged_characters = findTaggedIds(content, false, gameId);
		} else {
			tagged_users = findTaggedIds(content, true, gameId);
		}

		dispatch(saveLineEdit(line, { content, tagged_characters, tagged_users }));
		dispatch(setEditingLine({ gameId, value: null }));

		dispatch(setTalkToCharacterIds({ gameId: talkToGameId, value: [] }));
		dispatch(setWhisperToPlayerIds({ gameId: talkToGameId, value: [] }));
		dispatch(setWhisperingTo({ gameId: talkToGameId, value: [] }));

	}, [dispatch, message, line, talkToCharacterIds, talkToPlayerIds, characters, users, talkToGameId]);

	const handleKey = React.useCallback(
		(e) => {
			const {
				nativeEvent: { key: keyValue },
			} = e;

			autocompleteRef?.current?.handleKey(e);

			if (e.defaultPrevented) return;

			if (keyValue == "Enter") {
				if (e.shiftKey) return;
				e.preventDefault();
				saveEdit();
			}
		},
		[saveEdit]
	);

	const messageType = line.is_chat ? "chat" : line.is_description ? "description" : "dialogue";

	return (
		<>
			{!isComments && (
				<>
					<TargetsBox gameId={line.game} inlineEditing />
					<AtAutocomplete
						autocompleteRef={autocompleteRef}
						setMessageMethod={setEditMessage}
						text={message}
						focused={focused}
						selection={selection}
						setselection={setselection}
						textInputReference={textInputRef}
						showOnTop
						messageType={messageType}
						inlineEditing
					/>
				</>
			)}
			<View style={styles.container}>
				<EditMessageField
					editingLine={line}
					handleKey={handleKey}
					message={message}
					setMessageMethod={setEditMessage}
					messageType={messageType}
					textInputRef={textInputRef}
					setselection={setselection}
					setfocused={setfocused}
					isComments={line.is_comment}
					inlineEditing
				/>
			</View>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.line.game;
	const gameUI = state.gamesUI[gameId];

	const talkToGameId = gameId + "inlineEdit";
	const talkToGameUI = state.gamesUI[talkToGameId];

	return {
		message: gameUI.editMessage,
		whisperToCharacterIds: talkToGameUI?.whisperToCharacterIds,
		whisperToPlayerIds: talkToGameUI?.whisperToPlayerIds,
		talkToCharacterIds: talkToGameUI?.talkToCharacterIds,
		talkToPlayerIds: talkToGameUI?.talkToPlayerIds,
		users: state.users,
		characters: state.characters,
		talkToGameId,
	};
};

export default connect(mapStateToProps)(EditingLine);

const styles = StyleSheet.create({
	container: {},
});
