import * as React from "react";
import { useDispatch } from "react-redux";
import { _ } from "../../i18n/i18n";
import ModalScreen from "../generic/modal/ModalScreen";
import { setEditingLine, setTalkToCharacterIds, setWhisperingTo, setWhisperToPlayerIds } from "../../store/slices/gamesUISlice";
import { isStory } from "../../tools/lines";
import { SELECT_CHARACTER_CHANGE_AUTHOR } from "../characters/FilteredCharacterList";
import { apiFetch } from "../../network/apiFetch";
import { receiveLines } from "../../store/slices/linesSlice";
import { isGM } from "../../tools/games";
import { connect } from "react-redux";

function LineEditModal({ navigation, route, dispatch, user, characters, characterSelected }) {
	const { line, characterSelectedCodeSent, languageSelected } = route.params;

	const gm = isGM(line.game);

	let charactersAvailable = React.useMemo(()=>{
		if (!gm) {
			return characters.filter((c) => !c.deleted && c.player?.id === user?.id);
		}
		return null;
	}, [gm, characters, user]);
	

	React.useEffect(() => {
		if (characterSelectedCodeSent === SELECT_CHARACTER_CHANGE_AUTHOR) {
			navigation.setParams({ characterSelectedId: null, characterSelectedCodeSent: null });

			apiFetch(`games/${line.game}/lines/${line.id}`, "PATCH", {
				author: characterSelected ? characterSelected.id : null,
			}).then((newLine) => {
				dispatch(receiveLines({ lines: [newLine], gameId: line.game }));
			});
			navigation.navigate("Game");
		}
	}, [line, characterSelected, characterSelectedCodeSent]);

	const options = [];

	isStory(line) &&
		(gm || charactersAvailable.length > 1) &&
		options.push({
			title: _("Change author", "edit line"),
			hide: !isStory(line),
			onPress: () =>
				navigation.navigate("SelectCharacter", {
					sendToScreen: "LineEditModal",
					code: SELECT_CHARACTER_CHANGE_AUTHOR,
					showNarrator: gm,
					charactersAvailable,
				}),
		});

	options.push({
		title: _("Change tone", "edit line"),
		onPress: () => {
			navigation.navigate("ToneSelectionScreen", {
				editingLine: line,
				backToScreen: line.is_comment ? "PublicChatScreen" : "Game",
			});
		},
	});
	!line.is_comment &&
		options.push({
			title: _("Change type", "edit line"),
			onPress: () => {
				navigation.navigate("LineChangeTypeModal", {
					editingLine: line,
					backToScreen: line.is_comment ? "PublicChatScreen" : "Game",
				});
			},
		});
	isStory(line) &&
		options.push({
			title: _("Change language", "edit line"),
			hide: !isStory(line),
			onPress: () => {
				navigation.navigate("LanguageSelectionScreen", {
					editingLine: line,
					backToScreen: "Game",
				});
			},
		});

	return <ModalScreen options={options} />;
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	return {
		characterSelected: state.characters[ownProps.route.params?.characterSelectedId],
		characters: state.charactersByGame[gameId],
		user: state.user,
	};
};

export default connect(mapStateToProps)(LineEditModal);
