import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ExpoLinking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import * as React from "react";
import { Image, StyleSheet, View, Linking } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { globalStyles } from "../../styles/global";
import { isIos, isWeb, ws } from "../../tools/generic";
import AppScreenView from "../AppScreenView";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppText from "../generic/AppText";
import AppButton from "../generic/buttons/AppButton";
import ListButton from "../generic/buttons/ListButton";

const discordUrl = () => {
	const endpoint = "https://discordapp.com/api/oauth2/authorize";
	const clientID = "473433331208552449";
	const scope = "identify";
	const redirectEndpoint = ws("/s/dis/exchange", "/s/dis/exchange/redirect");
	const redirect = encodeURIComponent(global.root_url + redirectEndpoint);

	return endpoint + "?response_type=code&client_id=" + clientID + "&scope=" + scope + "&redirect_uri=" + redirect;
};

function DiscordSettingsScreen({ navigation, dispatch, user }) {
	const [discordUser, setdiscordUser] = React.useState();
	const [connecting, setconnecting] = React.useState(false);

	const loadDiscordUser = React.useCallback(async () => {
		const dUser = JSON.parse(await AsyncStorage.getItem("discordUser"));
		if (!dUser) {
			await apiFetch(`profiles/${user.id}/discord-user`).then((data) => {
				setdiscordUser(data);
				AsyncStorage.setItem("discordUser", JSON.stringify(data));
			});
		} else {
			setdiscordUser(dUser);
		}
		setconnecting(false);
	}, [user.id]);

	React.useEffect(() => {
		const removeScreenFocusListener = navigation.addListener("focus", loadDiscordUser);

		return () => {
			removeScreenFocusListener();
		};
	}, [loadDiscordUser]);

	React.useEffect(() => {
		if (!isWeb()) return () => {};

		const handleDiscordRedirect = async () => {
			const initialUrl = await Linking.getInitialURL();

			let { queryParams } = ExpoLinking.parse(initialUrl);

			if (queryParams.code) {
				apiFetch(`s/dis/exchange/${user.id}`, "GET", { code: queryParams.code, from_app: false }, true).then(
					loadDiscordUser
				);
			}
		};

		handleDiscordRedirect();
	}, [loadDiscordUser, user.id]);

	const openBrowserAsync = React.useCallback(async () => {
		let redirectListener = null;
		// https://github.com/expo/examples/tree/master/with-webbrowser-redirect
		const handleRedirect = (event) => {
			setconnecting(true);
			if (isIos()) {
				WebBrowser.dismissBrowser();
			} else {
				// ExpoLinking.removeEventListener("url", handleRedirect);
				redirectListener.remove()
			}
			let { queryParams } = ExpoLinking.parse(event.url);

			if (queryParams.code) {
				apiFetch(`s/dis/exchange/${user.id}`, "GET", { code: queryParams.code, from_app: true }, true).then(
					loadDiscordUser
				);
			}
		};

		try {
			redirectListener = ExpoLinking.addEventListener("url", handleRedirect);
			let result = await WebBrowser.openBrowserAsync(discordUrl());

			if (isIos()) {
				// ExpoLinking.removeEventListener("url", handleRedirect);
				redirectListener.remove();
			}
		} catch (error) {
			alert(error);
			console.log(error);
		}
	}, [user.id]);

	const disconnectUser = React.useCallback(() => {
		apiFetch(`profiles/${user.id}/disconnect-discord-user`, "POST");
		setdiscordUser(null);
		AsyncStorage.removeItem("discordUser");
	}, []);

	const imageURL = React.useMemo(
		() => "https://cdn.discordapp.com/avatars/" + discordUser?.id + "/" + discordUser?.avatar + ".png?size=64",
		[discordUser]
	);

	if (discordUser) {
		return (
			<AppScreenView style={globalStyles.jac}>
				<View style={globalStyles.jac}>
					<AppText color="secondary">{_("Account connected", "discord settings")}</AppText>
					<Image source={{ uri: imageURL }} style={{ height: 64, width: 64, marginVertical: 8 }} />
					<AppText>{discordUser.username}</AppText>
				</View>
				<AppButton
					// transparent
					style={{ marginTop: 64 }}
					onPress={disconnectUser}
					title={_("Disconnect account", "disconnect Discord account")}
					size="large"
				/>
			</AppScreenView>
		);
	}
	return (
		<AppScreenView>
			<ListButton
				hide={!!discordUser || connecting}
				title={_("Link Discord account")}
				onPress={ws(() => Linking.openURL(discordUrl()), openBrowserAsync)}
			/>
			<AppActivityIndicator show={connecting} />
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(DiscordSettingsScreen);
