import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { connect } from "react-redux";
import VerifyEmailScreen from "../account/VerifyEmailScreen";
import InteractiveHelperNavigator from "../interactivehelpers/InteractiveHelperNavigator";
import LoggedOffStack from "./LoggedOffStack";
import ModalNavigationScreens from "./ModalNavigationScreens";

const Stack = createStackNavigator();

function AppNavigator({ user }) {
	
	if (!user) return <LoggedOffStack />;
	if (!user.profile.email_verified) return <VerifyEmailScreen />;

	// Uses a stack above the drawer on web, to allow modals to show
	// https://stackoverflow.com/a/63523840/3904557
	return (
		<Stack.Navigator screenOptions={{headerShown: false}}>
			<Stack.Screen name="Main" component={InteractiveHelperNavigator} />
			{ModalNavigationScreens(Stack)}
		</Stack.Navigator>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(AppNavigator);


