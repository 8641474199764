import * as React from "react";
import { View, StyleSheet } from "react-native";
import ModalScreen from "../generic/modal/ModalScreen";
import { isInGame } from "../../tools/games";
import { setTalkToPlayerIds } from "../../store/slices/gamesUISlice";
import { _ } from "../../i18n/i18n";
import UserItem from "../users/UserItem";
import { useDispatch } from "react-redux";

function UserOptionsModal({ route, navigation }) {
	const { gameId, user } = route.params;

	const dispatch = useDispatch();

	const inGame = isInGame(user, gameId) && isInGame(null, gameId);
	const options = [];
	inGame &&
		options.push({
			title: _("Talk to", "talk to character"),
			onPress: () => {
				dispatch(setTalkToPlayerIds({ gameId, value: [user.id] }));
				navigation.navigate("Game");
			},
		});

	options.push({
		title: _("%(username)s's profile", "open a user profile", { username: user.username }),
		onPress: () => navigation.navigate("ProfilePage", { username: user.username }),
	});

	return (
		<ModalScreen options={options} borderless>
			<UserItem user={user} />
		</ModalScreen>
	);
}

export default UserOptionsModal;


