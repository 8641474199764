import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import ModalScreen from "../generic/modal/ModalScreen";
import TagSelector from "../tags/TagSelector";
import CharacterItem from "./CharacterItem";

function CharacterTagsModal({ characterTags, route, navigation, dispatch }) {
	const { character } = route.params;
	const activeTags = characterTags.filter((tag) => tag.characters.includes(character.id));
	return (
		<ModalScreen
			header={<CharacterItem character={character} />}
			title={activeTags.length ? _("Tags") : _("Add tags")}
		>
			<TagSelector tags={characterTags} activeTags={activeTags} fullScreenOnFocus character={character} />
		</ModalScreen>
	);
}

const mapStateToProps = (state, ownProps) => {
	const { character } = ownProps.route.params;
	return {
		characterTags: state.characterTags[character.game] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(CharacterTagsModal);


